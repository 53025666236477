
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { deleteProcessCenter, fetchProcessCenter} from '../../../redux-tookit/slices/processCenterSlice';
import { fetchServiceCenter } from '../../../redux-tookit/slices/serviceCenterSlice';
import AddProcessCenter from './AddProcessCenter';
import AGTable from '../../../components/AGTable1';
import {  toast } from 'react-toastify';
import ExcelDownloadButton from '../../../components/ExcelDownloadButton';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';



const ProcessCenter = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const permission = useState(location.state?.permission);

  const columns = useSelector((state) => state.processCenter.columns);
  const data = useSelector((state) => state.processCenter.labeledData);
  
  const [editData,setEditData]=useState([]);
  const loading =useSelector((state)=>state.processCenter.loading);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const exceldata = data.map(row => {
    let newRow = {};
    Object.keys(row).map(column=>{
      column ==='name'?
      newRow = {...newRow, serviceCenterName: row[column]}:
      newRow = {...newRow, [column]: row[column]}
    })
    return newRow
  })

console.log(data);

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
    
  };

  const handleAddButtonClick = () => {
    setIsAdding(true);
  };
  const handleEditButtonClick = () => {
		if (selectedRowIndex !== null&&selectedRowIndex!==undefined) { 
			setIsEditing(true); 
			setEditData(data[selectedRowIndex]);
		  }else toast.error("No rows selected, Please select a row and try again");setSelectedRowIndex(null);
	};


  
  const handleDeleteButtonClick=async()=>{
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      const id=data[selectedRowIndex].processCenterId;
      console.log(id);
      dispatch(deleteProcessCenter(id));
    }
    else toast.error("No rows selected, Please select a row and try again");
		setSelectedRowIndex(null);
    
  };
  useEffect(() => {
    setTimeout(() => {
            dispatch(fetchProcessCenter());
            dispatch(fetchServiceCenter());
    }, 200)


    
  }, [dispatch,isAdding,isEditing]);


  return (
    <div className='sdbr'>
      <div className='head' style={{width:'60%'}}>Process Center
        <div className="top-button-container">
          {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
          <img src={edit} alt="edit" onClick={handleEditButtonClick}/>
          <img src={del} alt="delete" onClick={handleDeleteButtonClick}/> */}
          <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]}/>

          <ExcelDownloadButton csvData={exceldata} fileName='ProcessCenterData' />

        </div>
      </div>
      <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} width='60%'/>

      <AddProcessCenter isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData}/>
    </div>
  );
};

export default ProcessCenter;
