import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../assets/styles/NewPlan.css'
import 'react-toastify/dist/ReactToastify.css'
import Button from '../../../utils/Button';
import AGTable from '../../../components/AGTable';
import Loader from '../../../components/Loader/Loader';
import { fetchOrder } from '../../../redux-tookit/slices/orderSlice';
import { runPresales } from '../../../redux-tookit/slices/presalesSlice';
import ShowResult from './ShowResult';


const Presales = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState('fulfilled')
  const orderColumns = useSelector((state) => state.order.columns);
  const orderData = useSelector((state) => state.order.labeledData);

  const [result, setResult] = useState({})

  const [selectedRowIndex, setSelectedRowIndex] = useState([]);

  const [filteredData, setFilteredData] = useState(orderData.filter(item => item.totalTags !== 0))

  const [showResult, setShowResult] = useState(false)

  const handleRowSelect = (rowIndex) => {
    if (rowIndex !== undefined) {
      setSelectedRowIndex(rowIndex);
    } else {
      setSelectedRowIndex(null);
    }
  };


  const handlePresales = async () => {

    // let orderIdValues = []

    // selectedRowIndex.map((rowIndex) => {
    //   orderIdValues = [...orderIdValues, filteredData[rowIndex].orderId]
    // })

    const newRow = {
      "orderId": filteredData[selectedRowIndex].orderId,
    }

    console.log(newRow);
    setLoading('pending')

    dispatch(runPresales(newRow)).then((res) => {
      setLoading('fulfilled')
      if (res.payload !== undefined) {
        console.log(res.payload);
        setResult(res.payload.preSalesPricing)
        setShowResult(true)
        // dispatch(setNavOptions({expOpt:'Reports',selOpt:'Presales'}))
        // navigate('/presales', { state: { planId: res.payload.orderDeltaId } });
      }
    })
    setSelectedRowIndex(null);
  }

  useEffect(() => {
    setFilteredData(orderData.filter(item => item.totalNoOfTags * 1 !== 0))
  }, [orderData])

  useEffect(() => {
    dispatch(fetchOrder());
  }, [dispatch]);

  return (
    <div className='sdbr'>
      {loading === 'pending' ? <Loader /> :
        <>
          <div className="head">Presales</div>

          <div className="normal-head"></div>
          <AGTable data={filteredData} columns={orderColumns} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />

          <div className='bottom-button-container'>
            <Button className='blue' label='Pre Sales' onClick={() => { handlePresales(selectedRowIndex) }} disabled={selectedRowIndex} />
          </div>

        </>}
        {showResult===true?<ShowResult result={result} setShowResult={setShowResult}/>:""}
    </div>
  )
}

export default Presales