import React, { useEffect } from 'react'
import Button from '../../../utils/Button'
import { useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPartItems } from '../../../redux-tookit/slices/partItemsSlice'
import GenericTableShow from '../../../components/GenericTableShow'

const ElementShow = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const location = useLocation();
	const elementName = location?.state?.elementName
	// const columns = useSelector(state => state.partItems.columns2)
	const data = useSelector(state => state.partItems.labeledData).filter(item => `${item.orderItemId}` === id)
	useEffect(() => {
		dispatch(fetchPartItems())
	}, [dispatch])
	const columns = {
		shapeId: { label: "Shape Id", type: 'number', isRequired: true, editable: true },
		image: { label: "Shape", type: 'number', isRequired: true, editable: true },
		orderItemName: { label: "Element Name", type: 'text', isRequired: true, editable: true },
		tagId: { label: "Tag Id", type: 'number', isRequired: true, editable: true },
		bmRow: { label: "BM_Row", type: 'number', isRequired: true, editable: true },
		gradeName: { label: "Grade", type: 'text', isRequired: true, editable: true },
		diameter: { label: "Diameter (mm)", type: 'number', isRequired: true, editable: true },
		pinDia: { label: "Pin Dia", type: 'number', isRequired: true, editable: true },
		noOfPieces: { label: "No. of Pieces", type: 'number', isRequired: true, editable: true },
		totalNoOfPieces: { label: "Total No. of Pieces ", type: 'number', isRequired: true, editable: true },
		inventoryLength: { label: "Inv Len (mm)", type: 'number', isRequired: true, editable: true },
		productionLength: { label: "Prod Len (mm)", type: 'number', isRequired: true, editable: true },
		inventoryWeight: { label: "Inv Wt (T)", type: 'number', isRequired: true, editable: true },
		productionWeight: { label: "Prod Wt (T)", type: 'number', isRequired: true, editable: true },
		barMarkId: { label: "Bar Mark Id", type: 'number', isRequired: true, editable: false },
		bundleQuantity: { label: "Bundle Qty", type: 'number', isRequired: true, editable: true },
		threads: { label: "threads" },
		tagDescription: { label: "Tag description" },
		combId: {label: 'Order Id'},
	}
	return (
		<div className='sdbr'>
			<div className='head' style={{ width: '800px' }}>
				Element: {elementName}
			</div>

			<GenericTableShow columns={columns} data={data} />

			<div className="bottom-button-container" style={{ justifyContent:'space-around' }}>

				<Button label="Exit" className="blue" link='order details' />

			</div>
		</div>
	)
}

export default ElementShow
//End of File
