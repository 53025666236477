import { useState, useEffect } from "react";
import Button from "../../utils/Button";
import "../../assets/styles/RouteLoad.css";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataFromApi } from "../../redux-tookit/slices/sdbrSlice";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  ArcElement,
  Tooltip,
  Legend,
  LineController,
  LineElement,
  PointElement,
  Filler,
  Title
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import "./RouteLoad.css"

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
  Legend,
  Filler,
  Title,
  ChartDataLabels
);

const RouteLoad = () => {
  const dispatch = useDispatch();
  const originalData = useSelector((state) => state.sdbr.plantCapacity.data);
  const PlantloadData = useSelector((state) => state.sdbr.PlantLoad.data);

  // const getCurrentDate = () => {
  //   const today = new Date();
  //   const year = today.getFullYear();
  //   const month = (today.getMonth() + 1).toString().padStart(2, '0');
  //   const day = today.getDate().toString().padStart(2, '0');
  //   return `${year}-${month}-${day}`;
  // };

  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [coilDateFilter, setCoilDateFilter] = useState([]);
  const [coiltoDateFilter, setCoilToDateFilter] = useState([]);
  const [coiltonormalDateFilter, setCoiltonormalDateFilter] = useState([]);
  const [coiltorubeDateFilter, setCoiltorubeDateFilter] = useState([]);
  const [rebartoDateFilter, setRebarToDateFilter] = useState([]);
  const [rebartonormalDateFilter, setrebartonormalDateFilter] = useState([]);
  const [rebartorubeDateFilter, setrebartorubeDateFilter] = useState([]);
  const [plugIn, setPlugIn] = useState(false);

  useEffect(() => {
    dispatch(fetchDataFromApi("plantCapacity"));
    dispatch(fetchDataFromApi("PlantLoad"));
  }, [dispatch, originalData, PlantloadData]);
  console.log(originalData)

  const applyFilters = () => {
    const currentfilteredData = originalData.filter((item) => {
      const dateString =
        item.date && typeof item.date === "string" ? item.date : "";
      const itemDate = new Date(dateString.split("-").reverse().join("-"));
      const fromDate = fromDateFilter ? new Date(fromDateFilter) : null;
      const toDate = toDateFilter ? new Date(toDateFilter) : null;

      return (
        (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate)
      );
    });

    if (currentfilteredData.length === 0) {
      console.log("no data");
      toast.error("No data for the selected date range");
    } else {
      setPlugIn(true);
      setCoilDateFilter(currentfilteredData);

      const coilFilteredData = currentfilteredData.filter(
        (item) => item.route === "coil"
      );
      // setCoilDateFilter(coilFilteredData)

      const extractData = (data, property) => {
        return data.map((item) => item[property]);
      };

      const graphCoil = extractData(coilFilteredData, "date");
      setCoilToDateFilter(graphCoil);

      const normalgraphCoil = extractData(coilFilteredData, "normalorderhrs");
      setCoiltonormalDateFilter(normalgraphCoil);

      const rushgraphCoil = extractData(coilFilteredData, "rushorderhrs");
      setCoiltorubeDateFilter(rushgraphCoil);

      const rebarFilteredData = currentfilteredData.filter(
        (item) => item.route === "rebar"
      );

      const rebargraphCoil = extractData(rebarFilteredData, "date");
      setRebarToDateFilter(rebargraphCoil);

      const rebarnormalGraphCoil = extractData(
        rebarFilteredData,
        "normalorderhrs"
      );
      setrebartonormalDateFilter(rebarnormalGraphCoil);

      const rebarRushgraphCoil = extractData(rebarFilteredData, "rushorderhrs");
      setrebartorubeDateFilter(rebarRushgraphCoil);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedCurrentDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    setFromDateFilter(formattedCurrentDate);
    setToDateFilter(formattedCurrentDate);
    // applyFilters();
  }, []);

  const arbitraryStackKey = "stack1";
  const dataForcoil = {
    labels: coiltoDateFilter,
    datasets: [
      {
        label: "Normal Booked Hours",
        stack: arbitraryStackKey,
        data: coiltonormalDateFilter,
        borderColor: "#8884d8",
        backgroundColor: "#8884d8",
        pointBorderColor: "red",
        borderWidth: 1.4,
        pointBackgroundColor: "red",
        barThickness: 30
      },
      {
        label: "Booked Rush Hours",
        stack: arbitraryStackKey,
        data: coiltorubeDateFilter,
        borderColor: "#82ca9d",
        backgroundColor: "#82ca9d",
        pointBorderColor: "red",
        borderWidth: 1.4,
        pointBackgroundColor: "red",
        barThickness: 30
      },
    ],
  };

  const optionsForcoil = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      // title: {
      //   fontSize: 50,
      //   display: true,
      //   text: "OverAll Average Feedback",
      //   font: { size: 20 },
      // },
      datalabels: {
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 12,
        },
      },
      legend: {
        display: true,
        position: "bottom",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "Date (DD-MM-Y)",
          color: "grey",
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
      y: {
        // suggestedMin: 445,
        // suggestedMax : 465,
        grid: { display: true },
        title: {
          display: true,
          text: "Duration (In hrs)",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
    },
  };


  const dataForRebar = {
    labels: rebartoDateFilter,
    datasets: [
      {
        label: "Normal Booked Hours",
        stack: arbitraryStackKey,
        data: rebartonormalDateFilter,
        borderColor: "#8884d8",
        backgroundColor: "#8884d8",
        pointBorderColor: "red",
        borderWidth: 1.4,
        pointBackgroundColor: "red",
        barThickness: 30,
      },
      {
        label: "Booked Rush Hours",
        stack: arbitraryStackKey,
        data: rebartorubeDateFilter,
        borderColor: "#82ca9d",
        backgroundColor: "#82ca9d",
        pointBorderColor: "red",
        borderWidth: 1.4,
        pointBackgroundColor: "red",
        barThickness: 30,
      },
    ],
  };

  const optionsForRebar = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      datalabels: {
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 10,
        },
      },
      legend: {
        display: true,
        position: "bottom",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "Date (DD-MM-Y)",
          color: "grey",
          font: { size: 12, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
      y: {
        // suggestedMin: 445,
        // suggestedMax : 465,
        grid: { display: true },
        title: {
          display: true,
          text: "Duration (In hrs)",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <div className="sdbr">
      <div className="filter-card">
        <div className="filter-OSH">
          <div className="DateCont">
            <div className="DateFilt">
              <div className="filterD">
                <label htmlFor="fromDateFilter">From </label>
                <input
                  id="fromDateFilter"
                  type="date"
                  value={fromDateFilter}
                  onChange={(e) => setFromDateFilter(e.target.value)}
                  onBlur={() => applyFilters()}
                />
              </div>
              <div className="filterD">
                <label htmlFor="toDateFilter">To</label>
                <input
                  id="toDateFilter"
                  type="date"
                  value={toDateFilter}
                  onChange={(e) => setToDateFilter(e.target.value)}
                  onBlur={() => applyFilters()}
                />
              </div>
            </div>
          </div>
          <div className="YM-button-container">
            {/* <Button className="blue" label="Go" onClick={applyFilters} /> */}
          </div>
        </div>
      </div>
      <div>
        {plugIn === true && (
          <div className="charts1">
            <div className="chart-box1">
              <p style={{ display: "flex", textAlign: "center" }}>
                Route - COIL Routes
              </p>
              <Bar
                // className={hmiCss.formGroup2}
                data={dataForcoil}
                options={optionsForcoil}
                height={250}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              />
            </div>
            <div className="chart-box1">
              <p style={{ display: "flex", textAlign: "center" }}>
                Route - Rebar Routes
              </p>
              <Bar
                // className={hmiCss.formGroup2}
                data={dataForRebar}
                options={optionsForRebar}
                height={250}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              />
            </div>
          </div>
        )}
        {/* {plugIn === true && (
          <div className="displayMsg-card" style={{ float: "right" }}>
            <div className="displayMsg-layout">
              <p className="displayMsg">This plug-in isn’t supported</p>
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default RouteLoad;
