// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opt-sum{
    display: flex;
    flex-direction: column;
    white-space: nowrap;
}
.filter-sum{
    display: flex;
    flex-direction: row;
    gap: 2vw;
} 

.filter-sum .filter {
    display: flex;
    flex-direction: column;
    
}

.print-Button{
    width: 4vw;
    align-self: flex-end;
}

.filter-layout-Sum{
    display: flex;
}

.opt-sum .filterRadio{
    margin-top: 1vh;
}

/* ProductionHistory */

.filterPH{
    display: flex;
    flex-direction: row;
align-items: flex-end;
gap: 4vw;
}



.filterPH .filter{
    display: flex;
    flex-direction: column;
    width: 25%;
}.charts-container {
    width: 100%;
    height: auto;
    overflow: hidden; /* Prevent content from overflowing */
  }
  
  .chart-wrapper {
    width: 100%;
    height: auto;
  }
  `, "",{"version":3,"sources":["webpack://./src/assets/styles/Summary.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,aAAa;IACb,sBAAsB;;AAE1B;;AAEA;IACI,UAAU;IACV,oBAAoB;AACxB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,eAAe;AACnB;;AAEA,sBAAsB;;AAEtB;IACI,aAAa;IACb,mBAAmB;AACvB,qBAAqB;AACrB,QAAQ;AACR;;;;AAIA;IACI,aAAa;IACb,sBAAsB;IACtB,UAAU;AACd,CAAC;IACG,WAAW;IACX,YAAY;IACZ,gBAAgB,EAAE,qCAAqC;EACzD;;EAEA;IACE,WAAW;IACX,YAAY;EACd","sourcesContent":[".opt-sum{\r\n    display: flex;\r\n    flex-direction: column;\r\n    white-space: nowrap;\r\n}\r\n.filter-sum{\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 2vw;\r\n} \r\n\r\n.filter-sum .filter {\r\n    display: flex;\r\n    flex-direction: column;\r\n    \r\n}\r\n\r\n.print-Button{\r\n    width: 4vw;\r\n    align-self: flex-end;\r\n}\r\n\r\n.filter-layout-Sum{\r\n    display: flex;\r\n}\r\n\r\n.opt-sum .filterRadio{\r\n    margin-top: 1vh;\r\n}\r\n\r\n/* ProductionHistory */\r\n\r\n.filterPH{\r\n    display: flex;\r\n    flex-direction: row;\r\nalign-items: flex-end;\r\ngap: 4vw;\r\n}\r\n\r\n\r\n\r\n.filterPH .filter{\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 25%;\r\n}.charts-container {\r\n    width: 100%;\r\n    height: auto;\r\n    overflow: hidden; /* Prevent content from overflowing */\r\n  }\r\n  \r\n  .chart-wrapper {\r\n    width: 100%;\r\n    height: auto;\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
