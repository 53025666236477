import React, { useEffect, useState } from 'react'
import { ReactComponent as CheckBlank } from '../../../assets/images/icons/Table/checkmark.square.svg'
import { ReactComponent as CheckFill } from '../../../assets/images/icons/Table/checkmark.square.fill.svg'
import { useDispatch } from 'react-redux'
import {  toast } from 'react-toastify'

const AccessTable = (props) => {
    const dispatch = useDispatch();

    const { accessData, editData, setEditData } = props

    const [data, setData] = useState(accessData);
    console.log(data);
    console.log(editData);

    useEffect(() => {
        setData(accessData)
    }, [accessData])

    const permissionOrder = [
        "readPermission",
        "writePermission",
        "updatePermission",
        "deletePermission"
    ]

  

    const storeUpdates = (i1, i2, permissionName, value) => {

        let newData = JSON.parse(JSON.stringify(editData));
        let edited = false;

        

        if (editData.length !== 0) {
            editData.forEach((item, index) => {
                
                if (
                    item.permissionId * 1 === data[i1].Pages[i2].Permissions[0].permissionId * 1 &&
                    item.pageId * 1 === data[i1].Pages[i2].pageId * 1
                ) {
                    console.log('there');
                    edited = true;
                    newData[index] = { ...newData[index], [permissionName]: !value, pageId: data[i1].Pages[i2].pageId };

                }

            })
            if (!edited) {
                newData = [...newData, { ...data[i1].Pages[i2].Permissions[0], [permissionName]: !value, pageId: data[i1].Pages[i2].pageId }]

            }

        } else {
            {
                newData = [...newData, { ...data[i1].Pages[i2].Permissions[0], [permissionName]: !value, pageId: data[i1].Pages[i2].pageId }]

            }
        }

        setEditData(newData)
    }


    const handleCheck = (i1, i2, permissionName, value) => {
        console.log(data[i1].Pages[i2].Permissions[0][permissionName]);
        if(!value === true){
            if(permissionName!=='readPermission'){
                if(data[i1].Pages[i2].Permissions[0].readPermission!==true){
                    toast.error('Cannot enable this permission without read access')
                    return;
                }
            }
        }
        setData(prevData => {
            const newData = JSON.parse(JSON.stringify(prevData));
            const permissions = [...newData[i1].Pages[i2].Permissions];
            permissions[0] = {
                ...permissions[0],
                [permissionName]: !value
            };
            newData[i1].Pages[i2].Permissions = permissions;
            return newData;
        });
        storeUpdates(i1, i2, permissionName, value)
        console.log(i1, i2);
    }

    return (
        <div className="access-table-container">
            <table >
                <thead>
                    <tr >
                        <th key='pages'>Pages</th>
                        <th key='read'>Read</th>
                        <th key='write'>Write</th>
                        <th key='update'>Update</th>
                        <th key='delete'>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((module, i1) => (
                        <>
                            <tr key={i1}>
                                <th colSpan={5} key={module.moduleName}>{module.moduleName}</th>
                            </tr>
                            {module.Pages.map((page, i2) => (
                                <tr key={module.moduleName+i2}>
                                    <td key={page.pageName}>{page.pageName}</td>
                                    {page.Permissions.map((permissions) => (
                                        <>
                                            {permissionOrder.map(permissionName => (
                                                <td onClick={() => handleCheck(i1, i2, permissionName, permissions[permissionName])} key={page.pageName+permissionName}>{permissions[permissionName] ? <CheckFill /> : <CheckBlank />}</td>
                                            ))}
                                        </>
                                    ))}
                                </tr>
                            ))}
                        </>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default AccessTable
