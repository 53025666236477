
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { dateChanger } from '../../components/reusableFunctions';

const initialState = {
	columns: {},
	columns2: {},
	columns3: {},
	columns4: {},
	labeledData: [],
	labeledData2: [],
	labeledData3: [],
	labeledData4: [],
	loading: 'idle',
	fetcher: true,
	error: null,
};



export const fetchTempRecord = createAsyncThunk('data/fetchTempRecord', async (params) => {
	try {
		const response = await api.listTempRecord(params)
		const json = response.data;
		console.log(json)
		const columnLabels = {
			planId: { label: 'Pl ID' },
			orderId: { label: 'Id' },
			combId: { label: 'Order ID' },
			planCombId: {label: 'Plan Id'},
			orderItemId: { label: 'Order Item Id' },
			partItemId: { label: 'Part Item Id' },
			//customerName: { label: 'Customer Name'},
			planDate: { label: 'Plan Date' },
			productionWeight: { label: 'Production Weight (T)' },
			scrapWithRebar: { label: 'Scrap (T)' },
			offcutWithRebar: { label: 'Offcut (T)' },
			scrapPercentWithRebar: { label: 'Scrap %' },
			offcutPercentWithRebar: { label: 'Offcut %' },
			totalTags: { label: 'Total Tags' },
			productionStatus: { label: "Production Status" }
			// totalCycles: { label: 'Total Cycle'},
			// totalCuts: { label: 'Total Cut'},



		};

		const selectedColumns = [
			'planId',
			'planCombId',
			'combId',
			'orderId',
			'planDate',
			'orderItemId',
			'partItemId',
			'productionWeight',
			'scrapWithRebar',
			'offcutWithRebar',
			'scrapPercentWithRebar',
			'offcutPercentWithRebar',
			'productionStatus'
		];

		const showColumns = [
			'planId',
			'planCombId',
			'combId',
			'planDate',
			'productionWeight',
			'scrapWithRebar',
			'offcutWithRebar',
			'scrapPercentWithRebar',
			'offcutPercentWithRebar'
		];

		const columns = {};

		showColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});


		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				if (columnName === 'planDate') {
					let [dat] = row[columnName].split('T');
					rowData[columnName] = dateChanger(dat);
				} else
					rowData[columnName] = row[columnName];
			});
			return rowData;
		});
		console.log(labeledData);
		return { columns, labeledData };
	} catch (error) {
		throw error;
	}

});


export const fetchTempRecordOrder = createAsyncThunk('data/fetchTempRecordOrder', async () => {
	try {

		const response = await api.listOrder('');
		const json = response.data;

		const columnLabels = {
			orderId: { label: "Id", type: 'number', isRequired: true, editable: false },
			combId: { label: "Order Id", type: 'number', isRequired: true, editable: false },
			orderRemarks: { label: "Order Remarks", type: 'number', isRequired: true, editable: false },
			sapId: { label: "SAP Id", type: 'text', isRequired: true, editable: true },
			soldToParty: { label: "Customer", type: 'text', isRequired: true, editable: true },
			productionWeight: { label: "Production Weight (T)", type: 'text', isRequired: true, editable: true },
			poDate: { label: "PO Date", type: 'text', isRequired: true, editable: true },
			// poDate: { label: "PO Date", type: 'date', isRequired: true, editable: true },

			orderType: { label: "Order Type", type: 'text', isRequired: true, editable: true },
			totalNoOfTags: { label: "No of Tags", type: 'text', isRequired: true, editable: true },

		};

		const selectedColumns = [
			'orderId',
			'combId',
			'sapId',
			'soldToParty',
			'totalNoOfTags',
			'orderRemarks',
			'productionWeight',
			'poDate',
			'orderType',


		];

		const columns2 = {};
		selectedColumns.forEach((columnName) => {
			columns2[columnName] = columnLabels[columnName];
		});

		const labeledData2 = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				if (columnName === 'createdTs') {
					let [dat] = row[columnName].split('T');
					rowData[columnName] = dat;
				} else
					rowData[columnName] = row[columnName];
			});
			return rowData;
		});

		return { columns2, labeledData2 };
	} catch (error) {
		throw error;
	}
});


export const fetchTempRecordOrderItem = createAsyncThunk('data/fetchTempRecordOrderItem', async (id = null) => {
	try {
		let response = {}
		if (id === null) {
			response = await api.listOrderItems();
		} else response = await api.listOrderItems2(id);

		const json = response.data;
		const columnLabels = {
			orderItemId: { label: "Element Id", type: 'number', isRequired: true, editable: false },
			orderItemName: { label: "Element Name", type: 'text', isRequired: true, editable: true },
			noOfSets: { label: "No of Set", type: 'number', isRequired: true, editable: true },
			noOfTags: { label: "No of Tags", type: 'text', isRequired: true, editable: true },
			productionWeight: { label: "Total Weight (T)", type: 'text', isRequired: true, editable: true },
			tagPrintStatus: { label: "Tag Print Status", type: 'text', isRequired: true, editable: false },
			orderItemStatus: { label: "Element Status", type: 'text', isRequired: true, editable: false },
			orderId: { label: "Id", type: 'text', isRequired: true, editable: false },
			combId: { label: "Order Id", type: 'text', isRequired: true, editable: false },

			// Add more as needed
		};

		const selectedColumns = ['orderItemId', 'orderItemName', 'noOfSets', 'tagPrintStatus', 'orderItemStatus', 'orderId', 'combId','noOfTags', 'productionWeight'];
		const selectedColumns2 = ['orderItemName', 'noOfSets', 'orderItemId', 'noOfTags', 'combId']

		const columns = {}, columns2 = {};
		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		selectedColumns2.forEach((columnName) => {
			columns2[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});

		return { columns, labeledData, columns2 };
	} catch (error) {
		throw error;
	}
});


export const fetchTempRecordPartItem = createAsyncThunk('data/fetchTempRecordPartItem', async (id = null) => {
	try {

		let response = {}

		response = await api.listProdPartItems();

		const json = response.data;
		const columnLabels = {
			partItemId: { label: "Part Item Id", type: 'number', isRequired: true, editable: false },
			orderItemId: { label: "Element Id", type: 'number', isRequired: true, editable: false },
			barMarkId: { label: "Bar Mark Id", type: 'number', isRequired: true, editable: false },
			diameter: { label: "Diameter (mm)", type: 'number', isRequired: true, editable: true },
			noOfPieces: { label: "No. of Pieces", type: 'number', isRequired: true, editable: true },
			totalNoOfPieces: { label: "Total No. of Pieces ", type: 'number', isRequired: true, editable: true },
			shapeId: { label: "Shape Id", type: 'number', isRequired: true, editable: true },
			image: { label: "Shape", type: 'number', isRequired: true, editable: true },
			orderItemName: { label: "Element Name", type: 'text', isRequired: true, editable: true },
			gradeName: { label: "Grade", type: 'text', isRequired: true, editable: true },
			bundleQuantity: { label: "Bundle Qty", type: 'number', isRequired: true, editable: true },
			inventoryLength: { label: "Inv Len (mm)", type: 'number', isRequired: true, editable: true },
			productionLength: { label: "Prod Len (mm)", type: 'number', isRequired: true, editable: true },
			productionWeight: { label: "Prod Wt (Kg)", type: 'number', isRequired: true, editable: true },
			inventoryWeight: { label: "Inv Wt (Kg)", type: 'number', isRequired: true, editable: true },
			bmRow: { label: "BM_Row", type: 'number', isRequired: true, editable: true },
			tagId: { label: "Tag Id", type: 'number', isRequired: true, editable: true },
			route: { label: "Route Desc", type: 'text', isRequired: true, editable: true },
			pinDia: { label: "Pin Dia", type: 'number', isRequired: true, editable: true },
			orderId: { label: "Id", type: 'number' },
			combId: { label: "Order Id", type: 'number' },
			id: { label: "id", type: 'number' },
			partItemStatus: { label: "Status" },


			// "dispatchStatus": 1,
			// "vehicleFitment": 1,
			// "vehicleFitmentDetails": "smncbwajkcweucwecbdgweudgie",
			// "driverFitment": 1,
			// "driverFitmentDetails": "cwyxbet2iej23ehbwdqwi7d632",

			// noOfTags:{label: "No of Tags", type: 'text', isRequired: true, editable: true },
			// totalWeight:{label: "Total Weight (Kg.)", type: 'text', isRequired: true, editable: true }

		};

		const selectedColumns = ['partItemId', 'orderItemId', 'barMarkId', 'diameter', 'noOfPieces', 'totalNoOfPieces', 'shapeId', 'image', 'orderItemName', 'gradeName', 'bundleQuantity', 'inventoryLength', 'productionLength', 'inventoryWeight', 'productionWeight', 'bmRow', 'tagId', 'route', 'pinDia', 'orderId','combId', 'id'];

		const columns = {};

		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});


		const labeledData = json.rows.map((row) => {
			const rowData = {};

			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		}).filter((data) => data.partItemStatus !== 'FG')


		return { columns, labeledData };
	} catch (error) {
		throw error;
	}
});

export const postConfirmProduction = createAsyncThunk('data/postTempRecordConfirmProduction', async (newRow) => {
	try {
		console.log(newRow);
		const response = await api.confirmProduction(newRow);
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
		throw error;
	}
});


const tempProdRecordSlice = createSlice({
	name: 'tempPropRecord',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchTempRecord.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns = action.payload.columns;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchTempRecord.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})

			.addCase(fetchTempRecordOrder.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns2 = action.payload.columns2;
				state.labeledData2 = action.payload.labeledData2;
			})
			.addCase(fetchTempRecordOrder.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(fetchTempRecordOrderItem.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns3 = action.payload.columns;
				state.labeledData3 = action.payload.labeledData;
			})
			.addCase(fetchTempRecordOrderItem.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(fetchTempRecordPartItem.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns4 = action.payload.columns;
				state.labeledData4 = action.payload.labeledData;
			})
			.addCase(fetchTempRecordPartItem.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(postConfirmProduction.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postConfirmProduction.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				toast.success('Confirmed successfully!');
			})
			.addCase(postConfirmProduction.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})


	}
});

export default tempProdRecordSlice.reducer;

//End of File