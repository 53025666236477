import { Document, PDFViewer } from '@react-pdf/renderer'
import React, { useContext, useEffect } from 'react'
import CreatePass from './CreatePass'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchOrder, fetchOrderSF } from '../../../../redux-tookit/slices/orderSlice';
import { UserAccessContext } from '../../../../App';
import Button from '../../../../utils/Button';

const GatePassPrint = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const productName = useContext(UserAccessContext).productName

    const data = location.state?.data;

    const orderData = useSelector(state => state.order.labeledData)

    useEffect(() => {

        if (productName === 'smartFab') {
            dispatch(fetchOrderSF())
        } else {
            dispatch(fetchOrder())
        }

    }, [])

    const handleBackButtonClick = () => {
        window.history.go(-1);
    }

    return (
        <div style={{ height: '90%' }}>
            <PDFViewer style={{ height: '100%', width: '100%' }}>
                <Document onRender={() => {}}>
                    <CreatePass data={data} orderData={orderData} productName={productName} />
                </Document>
            </PDFViewer>

            <Button label='Back' className='blue' onClick={() => handleBackButtonClick()} />

        </div>
    )
}

export default GatePassPrint
