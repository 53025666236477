import React, { useCallback, useContext, useEffect, useState } from 'react'
import '../../../assets/styles/Shapes.css'
import Button from '../../../utils/Button';
import ShapeCardGenerator from './ShapeCardGenerator';
import { useLocation, useNavigate } from 'react-router-dom';
import BlackDrop from '../../../assets/images/icons/black-dropdown.svg'
import Upload from '../../../assets/images/button-icons/upload.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteShapes, fetchShapes, searchShapes } from '../../../redux-tookit/slices/shapeSlice';
import { toast } from 'react-toastify';
import { deleteFavShapes, fetchFavShapes, postFavShapes } from '../../../redux-tookit/slices/favShapeSlice';
import { fetchUserAccess } from '../../../redux-tookit/slices/userAccessSlice';
import { UserAccessContext } from '../../../App';

const Shapes = (props) => {

    const location = useLocation()
    const permissions = useContext(UserAccessContext).permission.Master?.Shapes[0] || 0;



    const { bbs = false, setShDaBBS, orderId, setShapeId, shapeId, partiesId, setBbsShapeId, setMg } = props;
    const [shapeRadio, setShapeRadio] = useState('2D');
    const shapeD = ['2D', '3D'];
    const navigate = useNavigate();
    // const [shapes, setShapes] = useState(null)
    const shapes = useSelector((state) => state.shape.shapes);
    const loading = useSelector(state => state.shape.loading);

    const favShapes = useSelector((state) => state.favShape.shapes);
    const [shapeHead, setShapeHead] = useState('0 Bend Without Arc')
    const [selectedShape, uShape] = useState(null);
    const [selectShapeType, setSelectedShapeType] = useState('0 Bend');
    const [shapeData, setShapeData] = useState([]);
    const [categoryShape, setCategoryShape] = useState([])
    const [id, setId] = useState(null)
    const [selFavId, setSelFavId] = useState(null);
    const [mag, setMag] = useState('')
    const [lengthDeduction, setLengthDeduction] = useState(true)


    const [permission, setPermission] = useState(permissions);

    useEffect(() => {
        if (bbs || !permissions) {
            dispatch(fetchUserAccess())
                .then(res => {
                    if (res.payload) {
                        if (res.payload.navbarNames.Master["Shapes"][0]) {
                            setPermission(res.payload.navbarNames.Master["Shapes"][0])
                        }
                    }
                })
        }

    }, [])

    const shapeTypes = [

        { name: '0 Bend', hasDropdown: true },
        { name: '1 Bend', hasDropdown: true },
        { name: '2 Bend', hasDropdown: true },
        { name: '3 Bend', hasDropdown: true },
        { name: '4 Bend', hasDropdown: true },
        { name: 'Multi Bend', hasDropdown: true },
        { name: '3D', hasDropdown: false },
        { name: 'Coupler', hasDropdown: false },
        { name: 'Special', hasDropdown: false },
        { name: 'Favourite', hasDropdown: false }

    ];

    const dispatch = useDispatch();

    const handleDelete = async () => {
        const deleteResponse = window.confirm("Are you sure you want to delete the selected shape?")
        if (deleteResponse) {
            categoryShape.map((el) => {
                if (el.shapeId === selectedShape)
                    setId(el.id);
            })
            dispatch(deleteShapes(id)).then((res) => {
                if (res.payload !== undefined) {
                    dispatch(fetchShapes(`?category=${shapeHead}`))
                }
            })
        }
    }

    // console.log(shapes);

    const setSelectedShape = (shId, datas = []) => {
        try {
            let foundShape = false;
            let ogId = null;
            let shapeDatas = [];
            let shapeId = shId;


            if (datas.length !== 0) {
                shapeDatas = datas
            } else {
                shapeDatas = categoryShape
            }

            shapeDatas.map((el) => {
                // console.log(el.shapeId, shapeId);
                if (el.shapeId === shapeId)
                    foundShape = true
            })

            let shapeDA = [];

            if (shapeHead.toLowerCase() === 'favourite') {
                favShapes.map((el) => {
                    if (el.shapeId === shapeId) {
                        foundShape = true;
                        shapeId = el.shapeId;
                        setMag(el.magnifications)
                        shapeDA = el.imageProperties
                    }
                })
            }

            if (foundShape === false) {
                toast.error('No shape matches the Id')
                uShape(null);
                return
            }

            uShape(shapeId);
            if (bbs === true) {
                setShapeId(shapeId);
            }


            shapeDatas.map((el) => {
                if (el.shapeId === shapeId) {
                    setId(el.id);
                    ogId = el.id;
                    setMag(el.magnifications)
                    if (bbs)
                        setMg(el.magnifications)
                }
            })

            shapeDatas.map((data) => {
                if (data.shapeId === shapeId) {
                    shapeDA = data.imageProperties
                }
            })

            setShapeData(shapeDA);

            if (bbs === true) {
                setShDaBBS(shapeDA);
                setBbsShapeId(ogId);
            };
        } catch (error) {
            console.log(error)
        }


    }

    const pdfPrint = (type) => {
        navigate(`/download bbs tag/`, { state: { orderId, type, partiesId, lengthDeduction } })
    }

    const handleUpdate = () => {

        navigate(`/shape update/${selectedShape}`, { state: { selectedShape, shapeData, id, mag } })
    }

    const handleCategoryChange = (value) => {
        setShapeHead(value)
        handleHeaderChange(value)
        uShape(null)

    }

    const handleFavAdd = () => {
        const favItem = { id: id, serviceCenterId: 2 }
        dispatch(postFavShapes(favItem)).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchFavShapes())
            }
        })
    }

    const handleFavDel = () => {
        // console.log({ selFavId });
        dispatch(deleteFavShapes(selFavId)).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchFavShapes())
            }
        })
    }

    const handleShapeId = () => {
        if (bbs === true && shapeId !== null) {
            handleShapeSearch(shapeId)
        }
    }

    const capitalizeEachWord = (sentence) => {
        const words = sentence.split(' ');
        const capitalizedWords = words.map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        });

        return capitalizedWords.join(' ');
    }

    const handleShapeSearch = async (searchId) => {

        // categoryShape.map()
        try {
            dispatch(searchShapes(`?shapeId=${searchId}`))
                .then((res) => {
                    if (res.payload) {
                        // console.log('shapeSetter', res.payload.shapes);
                        // console.log(res.payload);
                        if (res.payload.shapes.length === 0) {
                            toast.error('No shape found')
                            return
                        }
                        // console.log(res.payload.shapes[0]);
                        const shape = res.payload.shapes[0];

                        uShape(shape.shapeId)

                        if (shapeHead?.toLowerCase() === shape.category) {
                            // setCategoryShape([...resp.payload.shapes])
                            // console.log(shapeHead, shape.category);
                            setTimeout(() => {
                                setSelectedShape(searchId)
                            }, 100);
                        } else dispatch(fetchShapes(`?category=${shape.category}`))
                            .then((resp) => {
                                if (resp.payload) {
                                    setCategoryShape([...resp.payload.shapes])
                                    handleCategoryChange(capitalizeEachWord(shape.category))
                                    setSelectedShapeType('')
                                    setTimeout(() => {
                                        setSelectedShape(searchId, resp.payload.shapes)
                                    }, 100);
                                    // handleCategoryChange(shape.category)
                                }
                            })
                    }
                })
        } catch (error) {
            console.log(error)
        }


    }

    // console.log(selectedShape, shapeId)

    useEffect(() => {

        handleShapeId(shapeId)
        // console.log('shape search', shapeId);

    }, [shapeId]);



    const handleHeaderChange = (shapeHead) => {
        if (shapeHead !== 'Favourite') {
            dispatch(fetchShapes(`?category=${shapeHead}`));
        } else {
            dispatch(fetchFavShapes()).then((res) => {
                setCategoryShape(favShapes)
            })
        }
    }



    useEffect(() => {

        if (shapeHead !== 'Favourite') {
            dispatch(fetchShapes(`?category=${shapeHead}`))
        } else {
            dispatch(fetchFavShapes())
        }

    }, [dispatch,
        //  shapeHead
    ]);



    useEffect(() => {
        if (shapeHead !== 'Favourite')
            setCategoryShape(shapes);

    }, [
        shapes,
        shapeHead
    ])

    // console.log(permission);
    // console.log(shapes);
    return (
        <div className='shape-page'>
            <div className="shape">

                <div className="shape-type">
                    <div className="head">Shapes Type</div>
                    <div className="shape-list">
                        {shapeTypes.map((shape) => (
                            <div key={shape.name} className={shape.hasDropdown ? 'has-dropdown' : ''} onClick={() => { if (!shape.hasDropdown) handleCategoryChange(shape.name) }}>
                                <div className={`vertical-line ${shape.name === selectShapeType || shapeHead.includes(shape.name) ? 'active' : ''}`}></div>
                                <div className={`shape-item ${shape.name === selectShapeType || shapeHead.includes(shape.name) ? 'active' : ''}`} onClick={() => {
                                    setSelectedShapeType(shape.name);
                                    // if (shape.hasDropdown === false) {
                                    //     setShapeHead(shape.name)
                                    // };
                                }}>{shape.name}
                                    {shape.hasDropdown && (
                                        <div >
                                            <div className="dropdown">
                                                <div value="Without Arc" onClick={() => handleCategoryChange(shape.name + " Without Arc")}>Without Arc</div>
                                                <div value="With Arc" onClick={() => handleCategoryChange(shape.name + " With Arc")}>With Arc</div>
                                            </div>
                                            <img className="dropArrow" src={BlackDrop} alt="drop"></img>
                                        </div>
                                    )}</div>
                            </div>
                        ))}
                    </div>

                    {/* <div className="bbsSelectAll" style={{ display: `${bbs === true ? `flex` : `none`}` }}>
            <input name='selectAll' type='radio' id='selectAll' />
            <label htmlFor='selectAll'>Select All</label>
          </div> */}

                </div>

                <div className="shape-images">
                    <div className="headS">{shapeHead}</div>
                    <div className="image-grid" style={{ height: `${bbs === true ? `385px` : `400px`}` }}>
                        {loading === 'pending' ? <div className='circle-loader3'></div> :
                            (categoryShape.length !== 0 ?
                                categoryShape.map(data => (
                                    <ShapeCardGenerator shapeId={data.shapeId} shapeURL={data.image} selectedShape={selectedShape} setSelectShape={setSelectedShape} />
                                ))
                                : shapeHead.toLowerCase() === 'favourite' ?
                                    favShapes.length !== 0 ? favShapes.map(data => (
                                        <ShapeCardGenerator shapeId={data.shapeId} shapeURL={data.image} selectedShape={selectedShape} setSelectShape={setSelectedShape} favId={data.favShapesId} setSelFavId={setSelFavId} />
                                    ))
                                        : "No shapes in Favourites"
                                    : "No Shapes in the selected Category"
                            )}
                    </div>
                    <div className="bbsSetting" style={{ display: `${bbs === true ? `flex` : `none`}` }}>

                        <div style={{ padding: '10px' }}>
                            <label htmlFor="LnDed">Length Deduction:</label>
                            <input type='radio' checked={lengthDeduction} name='LnDed' id='Yes' onClick={() => setLengthDeduction(true)} />
                            <label htmlFor="Yes">Yes</label>
                            <input type="radio" checked={!lengthDeduction} name='LnDed' id='No' onClick={() => setLengthDeduction(false)} />
                            <label htmlFor="No">No</label>
                        </div>
                        {/* <div>
              <label htmlFor="Scale">Scale:</label>
              <input type='radio' name='Scale' id='Yes' />
              <label htmlFor="Yes">Yes</label>
              <input type="radio" name='Scale' id='No' />
              <label htmlFor="No">No</label>
            </div> */}

                        <div className='printDrop'>
                            <Button label="Print" className="blue" />
                            <div className="dropdown-1">
                                <ul style={{ margin: '0' }}>
                                    {/* <li>
                    BBS
                    <ul>
                      <li onClick={() => pdfPrint('bbs')}>View</li>
                      <li>Download</li>
                    </ul>
                  </li>*/}
                                    <li style={{ padding: '5px' }}>
                                        BBS Tabular
                                        <ul >
                                            <li onClick={() => pdfPrint('bbs tabular')}>View</li>
                                        </ul>
                                    </li>
                                    <li style={{ padding: '5px' }}>
                                        Tag List
                                        <ul >
                                            <li onClick={() => pdfPrint('tag')}>View</li>
                                        </ul>
                                    </li>
                                    {/* <li>
                    Tag List Rollwise
                    <ul>
                      <li onClick={() => pdfPrint('tag rollwise')}>View</li>
                      <li>Download</li>
                    </ul>
                  </li> */}
                                </ul>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div className="bottom-buttonsw" style={{ display: `${bbs === false ? `flex` : `none`}` }}>
                <div className="Input-shapeid">
                    <div className='shapeCustomInput'>
                        <label htmlFor='shapeid'>Shape Id</label>
                        <input type='text' id='shapeid' onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleShapeSearch(e.target.value)
                            }
                        }}
                            onBlur={
                                (e) => {

                                    handleShapeSearch(e.target.value)
                                    // setSelectedShape(e.target.value)

                                }
                            }
                        ></input>
                    </div>
                    <div className='shapeRadioButtons'>

                        {shapeD.map((v, key) => (
                            <div>
                                <input
                                    type="radio"
                                    name="D-select"
                                    value={v}
                                    checked={v === shapeRadio}
                                    onChange={(e) => { setShapeRadio(e.target.value) }}
                                    id={v}
                                />
                                <label htmlFor={v} >{v}</label>
                            </div>
                        ))}

                    </div>
                </div>
                {/* <Button label="Upload" className='blue' src={Upload} /> */}
                {!bbs &&
                    <>
                        {permission?.writePermission && <Button label="Add New" className='blue' onClick={() => navigate(`/add new shape`)} />}

                        {permission?.updatePermission && <Button label="Edit Shape" className='blue' onClick={() => handleUpdate()} disabled={selectedShape} message='No shape selected' />}

                        {selectShapeType === 'Favourite' ?
                            <Button label="Delete from Fav" className='red' onClick={() => handleFavDel()} disabled={selectedShape} message='No shape selected' /> :
                            <Button label="Add to Fav" className='blue' onClick={() => handleFavAdd()} disabled={selectedShape} message='No shape selected' />}
                        {selectShapeType === 'Favourite' ? '' :
                            permission?.deletePermission && <Button label="Delete" className='red' onClick={() => handleDelete()} disabled={selectedShape} message='No shape selected' />}
                    </>}
            </div>
        </div>
    )
}

export default Shapes