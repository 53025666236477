import React, { useEffect, useState } from "react";
import "../../../assets/styles/AddNew.css";
import Button from "../../../utils/Button";
import * as api from "../../../services/API";
import { useNavigate } from "react-router-dom";
import { fetchCubeIssues } from "../../../redux-tookit/slices/userFeedBackSlice";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

function AddUserFeedback() {
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const initialValues = {
    issueName: "",
    pageName: "",
    issueType: "",
    userId: "",
    serviceCenterId: "",
    description: "",
    imageUrl: [], // Keep this empty initially
  };

  const history = useNavigate();
  const [values, setValues] = useState(initialValues);
  const issueTypes = ["Critical", "Major", "Minor"];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const payload = {
        ...values,
        imageUrl: files.map((file) => ({
          string: file.string,
          type: file.type,
          filename: file.filename,
        })),
      };
      const result = await api.addCubeIssue(payload);
      setValues(initialValues);
      setFiles([]);
      dispatch(fetchCubeIssues());
      if (result) {
        history("/feedback");
        toast.success("Feedback Added Successfully");
      }
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  useEffect(() => {
    dispatch(fetchCubeIssues());
  }, [dispatch]);

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 3) {
      toast.error("Max 3 file selection allowed!");
      return;
    }

    const newFiles = await Promise.all(
      Array.from(selectedFiles).map(async (file) => {
        const base64 = await convertBase64(file);
        return {
          string: base64,
          type: file.type,
          filename: file.name,
        };
      })
    );

    setFiles(newFiles);

    setValues((prevValues) => ({
      ...prevValues,
      imageUrl: newFiles.length > 0 ? newFiles[0].string : "",
    }));
  };

  return (
    <div className="sdbr">
      <div className="head">Add Feedback</div>
      <form onSubmit={handleSubmit}>
        <div className="add-new-order">
          <div className="order-details">
            <div className="row-1">
              <div className="order-fields one">
                <label htmlFor="issueName">
                  Issue Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="issueName"
                  name="issueName"
                  value={values.issueName}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="order-fields one">
                <label htmlFor="pageName">
                  Page Name<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="text"
                  id="pageName"
                  name="pageName"
                  value={values.pageName}
                  required
                  onChange={handleInputChange}
                />
              </div>
              <div className="order-fields one">
                <label htmlFor="issueType">
                  Issue Type <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  id="issueType"
                  name="issueType"
                  onChange={handleInputChange}
                  value={values.issueType}
                  required
                >
                  <option value="" hidden>
                    -Select-
                  </option>
                  {issueTypes.map((issue, index) => (
                    <option key={index} value={issue}>
                      {issue}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row-2" style={{ alignItems: "flex-start" }}>
              <div className="order-fields one">
                <label htmlFor="description">Description</label>
                <textarea
                  style={{ height: "80px" }}
                  id="description"
                  name="description"
                  value={values.description}
                  onChange={handleInputChange}
                />
              </div>
              <div className="order-fields one">
                <label htmlFor="imageUrl" style={{ marginBottom: "15px" }}>
                  Image Upload
                </label>
                <input
                  type="file"
                  id="imageUrl"
                  name="imageUrl"
                  multiple // Allow multiple files
                  onChange={handleFileChange}
                />
              </div>
            </div>

            <div className="bottom-button-container">
              <Button className="blue" type="submit" label="Save" />
              <Button className="blue" label="Close" link="feedback" />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default AddUserFeedback;
