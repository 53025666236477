import React, { useEffect } from 'react'
import AGTable from '../../../components/AGTable';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { deleteCustomerParties, fetchCustomerParties, postCustomerParties, updateCustomerParties } from '../../../redux-tookit/slices/customerPartiesSlice';
import Button from '../../../utils/Button';
import { fetchCustomers } from '../../../redux-tookit/slices/customerSlice';
import {  toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';

const CustomerParties = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const permission = useState(location.state?.permission);
  const columns = useSelector((state) => state.customerParties.columns);
  const data = useSelector((state) => state.customerParties.labeledData);
  const columnKeys = Object.keys(columns);
  const customerData = useSelector((state) => state.data.labeledData);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [editData, setEditData] = useState([]);
  const loading = useSelector((state) => state.organization.loading);
  const [newRow, setNewRow] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);

  };

  console.log(customerData);

  const handleAddButtonClick = () => {
    let id = customerData[0].customerId
    // setNewRow({'soldToParty': id,
    // 'billToParty': id,
    // 'shipToParty': id});
    setIsAdding(true);

  };

  const handleEditButtonClick = () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      setIsEditing(true);
      setEditData(data[selectedRowIndex]);
    }
    else toast.error("No rows selected, Please select a row and try again");
    setSelectedRowIndex(null);
  };

  const handleUpdate = async (e) => {

    e.preventDefault();

    const id = editData.customerPartiesId;
    let parties = [];
    customerData.map((el) => {
      const c = el.customerName, id=el.customerId;
      if ( c=== editData.soldToParty)
        parties[0] = id;
      if ( c=== editData.billToParty)
        parties[1] = id;
      if ( c=== editData.shipToParty)
        parties[2] = id;
    })
    // soldToParty = (customerData.filter((item) => item.customerName === editData.soldToParty)[0].customerId);

    // console.log(soldToParty)
    const type = {
      'soldToParty': parties[0],
      'billToParty': parties[1],
      'shipToParty': parties[2]
    };
    console.log(id);

    console.log(type);
    dispatch(updateCustomerParties({ id, type }))
    setIsEditing(false);
  }

  const handleDeleteButtonClick = async () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      const id = data[selectedRowIndex].customerPartiesId;
      dispatch(deleteCustomerParties(id));
    }
    else toast.error("No rows selected, Please select a row and try again");
    setSelectedRowIndex(null);

  };

  const handleInputChange = (header, e) => {
    const updatedData = JSON.parse(JSON.stringify(editData));
    updatedData[header] = e.target.value;
    setEditData(updatedData);
  }

  const handleSaveButtonClick = async (e) => {
    e.preventDefault();
    // let soldToParty = null;
    // soldToParty=(customerData.filter((item)=>item.customerName===newRow.soldToParty)[0].customerId);
    // console.log('@@',soldToParty);
    // const type = {
    //   'soldToParty': (customerData.filter((item) => item.customerName === newRow.soldToParty)[0].customerId),
    //   'billToParty': (customerData.filter((item) => item.customerName === newRow.billToParty)[0].customerId),
    //   'shipToParty': (customerData.filter((item) => item.customerName === newRow.shipToParty)[0].customerId)
    // };

    console.log('@@@', newRow);
    dispatch(postCustomerParties({ ...newRow }))
    .then((res) => {
      if (res.payload) {
        toast.success('Customer Parties added successfully!');
      }
    })

    console.log(newRow);
    setIsAdding(false);
    setNewRow({});
  };

  useEffect(() => {
    setTimeout(() => {
      dispatch(fetchCustomerParties());
      dispatch(fetchCustomers());
    }, 200)

  }, [dispatch, isAdding, isEditing]);

  return (
    <div>{loading === 'pending' ?
      (<div>Loading...</div>)
      : (<div className='sdbr'>
      
        <div className='head'>Customer Parties
          <div className="top-button-container">
            {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
            <img src={edit} alt="edit" onClick={handleEditButtonClick} />
            <img src={del} alt="delete" onClick={handleDeleteButtonClick} /> */}
            <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]}/>

          </div>
        </div>
        <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />

        <form onSubmit={handleSaveButtonClick}>{isAdding &&
          <div>
            <div className="AddContainer" >
              <div className="form-container"><div className="head">Add Customer Parties</div>
                <div className="table-container" style={{ flexDirection: "column", width: "350px" }}>

                  {columnKeys.map((column) => (column !== 'customerPartiesId'&&column !== 'soldToPartyId' ?

                    (
                      (<div className="filter">
                        <label>{columns[column].label}<span style={{ color: 'red' }}>*</span></label>
                        <select
                          key={column}
                          onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value*1 })}
                          style={{ width: "300px" }}
                          required={true}
                        >
                          <option value="">-Select-</option>
                          {/* [...new Set(customerData.map(item => item.customerName&&item.customerId))] */}
                          {customerData.map((value, index) => (
                            <option key={index} value={value.customerId}>
                              {value.customerName}
                            </option>
                          ))}
                        </select></div>)) : ""))}
                </div>
                <div className="bottom-button-container">
                  <Button type='submit' label='Save' className='blue' />
                  <Button onClick={() => setIsAdding(false)} label='Cancel' className='blue' />
                </div>
              </div>
            </div>
            <div className="bg-dark"></div>
          </div>
        }
        </form>
        <form onSubmit={handleUpdate}>{isEditing &&
          <div>
            <div className="AddContainer" >
              <div className="form-container"><div className="head">Edit Customer Parties</div>
                <div className="table-container" style={{ flexDirection: "column", width: "350px" }}>
                  {columnKeys.map((column) => (

                    (columns[column].editable === true ?
                      (<div className="filter">
                        <label>{columns[column].label}<span style={{ color: 'red' }}>*</span></label>
                        <select
                          key={column}
                          value={editData[column]}
                          onChange={(e) => setEditData({ ...editData, [column]: e.target.value })}
                          style={{ width: "300px" }}
                        >


                          {customerData.map((value, index) => (
                            <option key={index} value={value.customerName}>
                              {value.customerName}
                            </option>
                          ))}
                        </select></div>) : "")))}
                  {/* <table className="generic-table">
                    <thead>
                      <tr>
                        {Object.keys(columns).map((key) => (
                          (columns[key].editable === true ? <th key={key} className="table-header">
                            {columns[key].label}
                          </th> : "")

                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        {Object.keys(columns).map((key) => (
                          columns[key].editable === true && selectedRowIndex !== null && columns[key] !== null ?
                            (<td key={key}>
                              <input
                                type={columns[key].type}
                                className='table-input'
                                value={editData[key]}
                                onChange={(e) => handleInputChange(key, e)}
                              />
                            </td>) :
                            ""
                        ))}
                      </tr>

                    </tbody>
                  </table> */}
                </div>
                <div className="bottom-button-container">
                  <Button type='submit' label='Save' className='blue' />
                  <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
                </div>
              </div>
            </div>
            <div className="bg-dark"></div>
          </div>
        }
        </form>
      </div>)}</div>
  )
}

export default CustomerParties

