import React, { useContext, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../../../assets/styles/GradeDetails.css';
import refresh from '../../../assets/images/button-icons/Refresh.svg';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import Button from '../../../utils/Button';
import { deleteGrade, deleteGradeSF, fetchGrades, fetchGradesSF, postGrades } from '../../../redux-tookit/slices/gradeMasterSlice';
import AGTable from '../../../components/AGTable';
import IsAddingGradeDetails from './IsAddingGradeDetails';
import ExcelDownloadButton from '../../../components/ExcelDownloadButton';
import { toast } from 'react-toastify';
import { fetchProducts, fetchProductType } from '../../../redux-tookit/slices/productMasterSlice';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
import { useLocation } from 'react-router-dom';
import { UserAccessContext } from '../../../App';


const GradeDetails = () => {
	const dispatch = useDispatch();
	const location = useLocation()
	const productName = useContext(UserAccessContext).productName;
	const permission = useState(location.state?.permission);
	const columns = useSelector((state) => state.gradeMaster.columns);
	const originalData = useSelector((state) => state.gradeMaster.labeledData);
	const fetcher = useSelector((state) => state.gradeMaster.fetcher);
	const [data, setData] = useState(originalData);
	const [editData, setEditData] = useState([]);
	const [isAdding, setIsAdding] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [selectedRowIndex, setSelectedRowIndex] = useState(null);
	//Filters
	const [selectRmfgrem, setSelectRmfgrem] = useState('')
	const [materialFilter, setmaterialFilter] = useState('');
	const [mattypeFilter, setmattypeFilter] = useState('');
	const [gradeFilter, setgradeFilter] = useState('');
	const [diameterFilter, setdiameterFilter] = useState('');
	const [rmfgremnantFilter, setrmfgremnantFilter] = useState('');

	const [materialValues, setMaterialValues] = useState([]);
	const [mattypeValues, setMattypeValues] = useState([]);
	const [gradeValues, setGradeValues] = useState([]);
	const [diameterValues, setDiameterValues] = useState([]);
	const [rmfgremnantValues, setRmfgremnantValues] = useState([]);

	const handleRowSelect = (rowIndex) => {
		setSelectedRowIndex(rowIndex);
		console.log(rowIndex);
	};
	console.log(originalData);
	const applyFilters = () => {
		let filteredData = originalData;
		if (materialFilter) {
			filteredData = filteredData.filter((item) =>
				item.material.includes(materialFilter)
			);
		}
		if (mattypeFilter) {
			filteredData = filteredData.filter((item) =>
				item.materialType.includes(mattypeFilter)
			);
		}
		if (gradeFilter) {
			filteredData = filteredData.filter((item) =>
				item.gradeName.includes(gradeFilter)
			);
		}
		if (diameterFilter) {
			filteredData = filteredData.filter((item) =>
				item.diameter == diameterFilter
			);
		}
		if (rmfgremnantFilter) {
			filteredData = filteredData.filter((item) =>
				item.RM_FG_Remnant.includes(rmfgremnantFilter)
			);
		}
		setData(filteredData);
	}
	const handleAddButtonClick = () => {
		setIsAdding(true);
	};
	const handleEditButtonClick = () => {
		if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
			setIsEditing(true);
			setEditData(data[selectedRowIndex]);
		} else toast.error("No rows selected, Please select a row and try again");
		setSelectedRowIndex(null);
	};

	const handleDeleteButtonClick = async () => {
		if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
			const id = data[selectedRowIndex].gradeId;
			console.log(id);

			if (productName) {
				dispatch(deleteGradeSF(id));
			} else {
				dispatch(deleteGrade(id));
			}
		}
		else toast.error("No rows selected, Please select a row and try again");
		setSelectedRowIndex(null);
	};

	//Set filters to null
	const handleRefresh = () => {
		setmaterialFilter('');
		setmattypeFilter('');
		setgradeFilter('');
		setdiameterFilter('');
		setrmfgremnantFilter('');
	}

	useEffect(() => {

		if (productName === 'smartFab') {
			dispatch(fetchGradesSF()).then(dispatch(fetchProductType()));
		} else {
			dispatch(fetchGrades()).then(dispatch(fetchProducts()));
		}

		handleRefresh()
	}, [dispatch, isAdding, fetcher, isEditing, productName]);


	useEffect(() => {
		setMaterialValues([...new Set(originalData.map(item => item.material))]);
		setMattypeValues([...new Set(originalData.map(item => item.materialType))]);
		setGradeValues([...new Set(originalData.map(item => item.gradeName))]);
		const uniqueDiameters = [...new Set(originalData.map(item => item.diameter))];
		uniqueDiameters.sort((a, b) => a - b);
		setDiameterValues(uniqueDiameters);
		setRmfgremnantValues([...new Set(originalData.map(item => item.RM_FG_Remnant))]);
		setData(originalData);
	}, [originalData]);

	useEffect(() => {
		applyFilters();
	}, [materialFilter, mattypeFilter, gradeFilter, diameterFilter, rmfgremnantFilter]);



	console.log(mattypeValues);

	return (
		<div className='sdbr'>
			<div className='filter-card'>
				<div className="filter-layout3" style={{ position: 'relative' }}>
					<div className="filter">
						<label htmlFor="materialFilter">Material</label>
						<select
							id="materialFilter"
							value={materialFilter}
							onChange={(e) => setmaterialFilter(e.target.value)}
						>
							<option value="">All</option>
							{materialValues.map((value, index) => (
								<option key={index} value={value}>
									{value}
								</option>
							))}
						</select>
					</div>
					<div className="filter">
						<label htmlFor="mattypeFilter">Material Type</label>
						<select
							id="mattypeFilter"
							value={mattypeFilter}
							onChange={(e) => setmattypeFilter(e.target.value)}
						>
							<option value="">All</option>
							{mattypeValues.map((value, index) => (
								<option key={index} value={value}>
									{value}
								</option>
							))}
						</select>
					</div>
					<div className="filter">
						<label htmlFor="gradeFilter">Grade</label>
						<select
							id="gradeFilter"
							value={gradeFilter}
							onChange={(e) => setgradeFilter(e.target.value)}
						>
							<option value="">All</option>
							{gradeValues.map((value, index) => (
								<option key={index} value={value}>
									{value}
								</option>
							))}
						</select>
					</div>
					<div className="filter">
						<label htmlFor="diameterFilter">Diameter</label>
						<select
							id="diameterFilter"
							value={diameterFilter}
							onChange={(e) => setdiameterFilter(e.target.value)}
						>
							<option value="">All</option>
							{diameterValues.map((value, index) => (
								<option key={index} value={value}>
									{value}
								</option>
							))}
						</select>
					</div>
					<div className="filter">
						<label htmlFor="rmfgremnantFilter">RM/FG/Remnant</label>
						<select
							id="rmfgremnantFilter"
							value={rmfgremnantFilter}
							onChange={(e) => setrmfgremnantFilter(e.target.value)}
						>
							<option value="">All</option>
							{rmfgremnantValues.map((value, index) => (
								<option key={index} value={value}>
									{value}
								</option>
							))}
						</select>
					</div>
					<div className="filter" >
						<img src={refresh} alt="refresh" onClick={handleRefresh} style={{ width: '20px', position: 'absolute', bottom: '0', right: '0' }} />
					</div>
				</div>
			</div>
			{/* <div className="filter-card">
				<div className="GraDet2">
					<div className="filter">
						<label htmlFor="customeridFilter">Material Number</label>
						<input type="text" />
					</div>
					<div className="filter">
						<label htmlFor="customeridFilter">Select RM/FG/Remnant</label>
						<select
							id="customeridFilter"
							value={selectRmfgrem}
							onChange={(e) => setSelectRmfgrem(e.target.value)}
						>
							<option value="">Select</option>
							<option value="FG">FG</option>
							<option value="RM">RM</option>
							<option value="RT">RT</option>
						</select>
					</div>
					<Button className='blue' label='Download From SAP' />
				</div>
			</div> */}
			<div className='head'>Grade Details
				<div className="top-button-container">

					<TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]} />
					<ExcelDownloadButton csvData={data} fileName='MaterialList' />

				</div>
			</div>
			<AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
			<IsAddingGradeDetails isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} productName={productName}/>

		</div>
	);
};

export default GradeDetails;