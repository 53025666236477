import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi} from '../../redux-tookit/slices/sdbrSlice';
import GenericTable from '../../components/GenericTable';
import Button from '../../utils/Button';
import '../../assets/styles/TpmRate.css'


const TpmRate = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.tpmRate.columns);
  const data = useSelector((state) => state.sdbr.tpmRate.data);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState('coil'); 
  const [column,setColumns] = useState(columns);
  


  const handleRowSelect = (rowIndex) => {
    console.log(data);
    setSelectedRowIndex(rowIndex);
    
  };

  
  const filteredData = data.filter((item) => item.route === selectedRoute);
  const filteredColumns = columns.filter((column) => column.key !== 'route');


  useEffect(() => {
    
    dispatch(fetchDataFromApi('tpmRate')); 
  }, [dispatch]);


  return (
    <div className='sdbr'>
      <div className='stick'>
      <div className='head'>TPM Rate</div>
      <div className="sub-data">
        <label htmlFor="route" style={{ paddingRight: "10px" }}>Route</label>
        <select
          name="route"
          id="route"
          onChange={(e) => {setSelectedRoute(e.target.value);setSelectedRowIndex(null)}}
          value={selectedRoute}
        >
          <option value="coil">Coil Route</option>
          <option value="rebar">Rebar Route</option>
        </select>
        
      </div>
      </div>
      <div>
        <GenericTable columns={filteredColumns} data={filteredData} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
      </div>
      {selectedRowIndex!==null?<div className='Buttons'><Button label='Save' className='blue'/><Button label="Cancel" className='blue' onClick={()=>setSelectedRowIndex(null)}/></div>:""} 
    </div>
  );
};

export default TpmRate;
