import React, { useContext, useEffect, useRef } from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import ReadyBuildLogo from '../../../../assets/images/icons/readybuild.png';
import SmartFabLogo from '../../../../assets/images/productSelectionPage/smartFabLogo.png'
import BottomLogo from '../../../../assets/images/icons/pdf/tataLogoForPDF.png';
import { useDispatch, useSelector } from 'react-redux';
import { logout, resetState } from '../../../../redux-tookit/slices/loginSlice'
import font from "../../../../assets/fonts/Roboto-Bold.ttf"
import { UserAccessContext } from '../../../../App';

Font.register({
  family: "Roboto-Bold", format: "truetype", src: font
});

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    padding: '1%'
  },
  content: {
    flexDirection: 'column',
    backgroundColor: 'white',
    border: '1px solid black',
    width: '100%',
    height: '40%',
    boxSizing: 'border-box',
    padding: '1%',
    margin: '2.5%'
  },
  headerBlock: {
    flexDirection: 'row',
    border: '1px solid black',
    height: '25%',

  },
  headerRight: {
    width: '30%',
    border: '1px solid black',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: '10px'
  },
  body: {
    width: '100%',
    height: '100%',
    marginTop: '2%',
    fontSize: '13',
    marginLeft: '15'
  }
});


const getCurrentDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0');
  const day = String(today.getDate()).padStart(2, '0');
  return `${day}-${month}-${year}`
}
const getCurrentTime = () => {
  const date = new Date();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  return `${hours}-${minutes}-${seconds}`
}

const HeaderBlock = ({productName}) => {
  const scName = sessionStorage.getItem('serviceCenterName');
  const email = sessionStorage.getItem('email');


  return (
    <View style={styles.headerBlock}>

      <View style={{ border: '1px solid black' }}>

        {productName === 'smartFab' ? <Image src={SmartFabLogo} style={{ height: '40px' }} /> :
          <Image src={ReadyBuildLogo} style={{ height: '40px' }} />
        }

      </View>

      <View style={{ width: '70%', border: '1px solid black', flexDirection: 'column' }}>
        <View style={{ width: '100%', height: '20%' }}>
        </View>

        <View style={{ width: '100%', height: '80%', marginTop: '-8', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Text style={{ fontSize: '10', color: 'blue', fontFamily: 'Helvetica-Bold', textAlign: 'center' }}>
            {scName}
          </Text>
          <Text style={{ fontSize: '10', color: 'blue', fontFamily: 'Helvetica-Bold', textAlign: 'center' }}>
            {email}

          </Text>
          <Text style={{ fontSize: '12', color: 'blue', fontFamily: 'Roboto-Bold', textAlign: 'center' }}>
            GATE PASS (Out Going)
          </Text>


        </View>
      </View>

      <View style={styles.headerRight}>
        <Text style={{ width: '100%', fontSize: '10', color: 'blue' }}>
          Sl. No: {getCurrentDate().replaceAll("-", "") + getCurrentTime().replaceAll("-", "")}
        </Text>
        <Text style={{ width: '100%', fontSize: '10', color: 'blue' }}>
          Date: {getCurrentDate()}
        </Text>
      </View>

    </View>
  )
}

const DataColumnFlex = (props) => {
  const { orderData, id } = props;
  let remark = '';
  let orderId = '';

  orderData.map(data => {
    if (data.orderId * 1 === id * 1) {
      remark = data.orderRemarks;
      orderId = data.combId;

    }
  })

  return (
    <View style={{ flexDirection: 'row', width: '100%', marginBottom: '7px', gap: '3px', padding: '5px', boxSizing: 'border-box' }}>
      <View style={{ width: '40%' }}>
        <Text style={{ width: '50%', fontSize: '10', marginLeft: '-5' }}>
          {orderId}
        </Text>
      </View>
      <View style={{ justifyContent: 'center', width: '30%' }}>
        <Text style={{ width: '80%', fontSize: '9', marginLeft: '-5' }}>
          {remark}
        </Text>
      </View>
      <View style={{ justifyContent: 'center', width: '30%' }}>
        <Text style={{ width: '50%', fontSize: '9' }}>
          {/* order wise tonnage */}
        </Text>
      </View>
    </View>
  )
}

const DataTable = (props) => {
  const { label, value, width = '20%' } = props
  return (
    <View style={{ flexDirection: 'row', width: '100%', marginBottom: '7px' }}>

      <View style={{ width: width }}>
        <Text style={{ width: '100%', fontSize: '10', fontFamily: 'Roboto-Bold', }}>
          {label} :
        </Text>
      </View>

      <View style={{ justifyContent: 'center' }}>
        <Text style={{ width: '70%', fontSize: '9', marginLeft: '20', marginBottom: '10' }}>
          {value}
        </Text>
      </View>

    </View>
  )
}


const ContentBody = (props) => {
  const { data, orderData, productName } = props
  console.log(data);
  console.log(orderData);

  let orderIds = []

  const idValues = data.orderId.toString()

  if (idValues.includes(',')) {
    orderIds = data?.orderId.split(',');
  } else {
    orderIds = [data?.orderId]
  }


  let customerName = []
  let address = [];
  let shipToAddress = []
  orderData.map(data => {
    if (orderIds.includes(data.orderId)) {
      customerName.push(data.soldToParty);
      address.push(data.soldToPartAddress);
      shipToAddress.push(data.address);
    }
  })

  return (
    <View style={styles.content}>

      <HeaderBlock orderId={orderIds} productName={productName}/>

      <View style={styles.body}>
        <DataTable label='Customer' value={customerName.toString()} />
        <DataTable label='Vehicle No.' value={data.trailerNo} />
        <DataTable label='Loading List Name' value={data.loadingListName} />
        <DataTable label='Address' value={address.toString()} />
        <DataTable label='Ship-to-Address' value={shipToAddress.toString()} />
        <DataTable label='Material to be loaded ' value='' />

        <View style={{ flexDirection: 'column', marginTop: '10px' }}>
          <View style={{ flexDirection: 'row', marginLeft: '-5', width: '100%', marginBottom: '7px', gap: '2px', padding: '5px', boxSizing: 'border-box' }}>
            <View style={{ width: '40%', alignItems: 'flex-start' }}>
              <Text style={{ width: '50%', fontSize: '10', fontFamily: 'Roboto-Bold', }}>
                Order Id
              </Text>
            </View>
            <View style={{ justifyContent: 'center', width: '30%' }}>
              <Text style={{ width: '80%', fontSize: '9', fontFamily: 'Roboto-Bold', }}>
                Remarks
              </Text>
            </View>

            {/* <View style={{ justifyContent: 'center', width: '30%' }}>
              <Text style={{ width: '80%', fontSize: '9' }}>
                Order Wise Tonnage
              </Text>
            </View> */}
          </View>

          {orderIds.map(id => (
            <DataColumnFlex id={id} orderData={orderData} />
          ))}
        </View>

        <View style={{ marginTop: '25px' }}>
          <DataTable label='Total Weighbridge Tonnage to be loaded' value={data.weightBridgeWeight} width='25%' />
        </View>
        <View style={{ width: '100%', height: '30%', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
          <View style={{ width: '20%' }}>
            <Text style={{ color: 'blue', fontSize: '9' }}>
              Authorised Signatory
            </Text>
          </View>

        </View>
      </View>
    </View>
  )
}


const CreatePass = (props) => {
  const { data, orderData , productName} = props
  return (
   
      <Page size="A4" style={styles.page}>
        <ContentBody data={data} orderData={orderData} productName={productName}/>
      </Page>
    
  )
}

export default CreatePass

