import React, { useState, useEffect } from "react";
import Products from "./../../../assets/images/dashboard/products.png";
import { toast } from "react-toastify";

import * as api from "../../../services/API";
import { useSelector, useDispatch } from "react-redux";
import { fetchInventory } from "../../../redux-tookit/slices/graphinventorySlice";

import { Line, Bar } from "react-chartjs-2";
import "./Dashboard.css";
import {
  fetchProdHistDiaWise,
  fetchProdRecordOrderWise,
} from "../../../redux-tookit/slices/dashboardSlice";
import { fetchDiaWiseOrderQty } from "../../../redux-tookit/slices/diaWiseQuantitySlice";
import { fetchdailyproductionqty } from "../../../redux-tookit/slices/dailyproductionquantitySlice";
import AGResizeTables from "../../../components/Tables/AGResizeTables";

const DashBoard = () => {
  const [customerWiseOrders, setCustomerWiseOrders] = useState([]);
  const [
    regionWiseSeviceCenterDataForApp,
    setRegionWiseSeviceCenterDataForApp,
  ] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [
    regionWiseSeviceCenterDataForCustomer,
    setRegionWiseSeviceCenterDataForCustomer,
  ] = useState([]);
  const [
    regionWiseSeviceCenterDataForDailyWise,
    setRegionWiseSeviceCenterDataForDailyWise,
  ] = useState([]);
  const [regionWiseSeviceCenterData, setRegionWiseSeviceCenterData] = useState(
    []
  );
  const [diaWiseData, setDiaWiseData] = useState([]);
  const [dailyWiseData, setDailyWiseData] = useState([]);
  const [diaWiseName, setDiaWiseName] = useState("");
  const [regionForAppMetrics, setRegionForAppMetrics] = useState("");
  const [dailyWiseName, setDailyWiseName] = useState("");
  const [regionForCustomer, setRegionForCustomer] = useState("");
  const [
    serviceCenterNameForCustomerWise,
    setServiceCenterNameForCustomerWise,
  ] = useState("");
  const [region, setRegion] = useState("All");
  const [regionForDiawise, setRegionForDiawise] = useState("All");
  const [serviceCenterData, setServiceCenterData] = useState([]);
  const [serviceCenterListData, setServiceCenterListData] = useState([]);
  const [name, setServiceCenterName] = useState([]);

  const [customOrderGraphData, setCustomOrderGraphData] = useState({});
  const dispatch = useDispatch();
  const [gradeFilter, setGradeFilter] = useState("IS:1786 - FE550D");
  const [customerOrderWiseFilter, setCustomerOrderWiseFilter] = useState("");
  const originalData = useSelector((state) => state.graphinventory.labeledData);
  // const regionName = useSelector((state) => state.login.region);
  const dailyproductiongraph = useSelector(
    (state) => state.dailyproductionqty.labeledData
  );
  const prodDiawiseorderqty = useSelector(
    (state) => state.diawiseQuantity.labeledData
  );

  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [coiltoDateFilter, setCoilToDateFilter] = useState([]);
  const [xaxisdiameter, setxaxisdiameter] = useState([]);
  const [yaxistotalpieces, setyaxistotalpieces] = useState([]);
  const [dataitemlength, setDataitemlength] = useState([]);
  const [dataitemweigth, setDataitemweigth] = useState([]);
  const [totalcount, setTotalcount] = useState([]);
  const [xaxisproductiondate, setxaxisproductiondate] = useState([]);
  const [yaxisnoOfPieces, setyaxisnoOfPieces] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [coilDateFilter, setCoilDateFilter] = useState([]);
  const [coiltonormalDateFilter, setCoiltonormalDateFilter] = useState([]);
  const [coiltorubeDateFilter, setCoiltorubeDateFilter] = useState([]);

  const [xaxisDiawiseDiameter, setxaxisDiawiseDiameter] = useState([]);
  const [yaxisDiawiseorderQuantity, setyaxisDiawiseorderQuantity] = useState(
    []
  );
  const [yaxisDiawisedeliveredQuantity, setyaxisDiawisedeliveredQuantity] =
    useState([]);
  const [yaxisDiawisebalanceQuantity, setyaxisDiawisebalanceQuantity] =
    useState([]);
  const [maxDate, setMaxDate] = useState(getTodayDate());
  const [date, setDate] = useState(maxDate);
  const [startDate, setStartDate] = useState("");
  const [startDateForDiaWise, setStartDateForDiaWise] = useState("");
  const [endDateForDiawise, setEndDateForDiaWise] = useState("");
  const [endDate, setEndDate] = useState("");

  const regions = ["North", "South", "East", "West"];
  const roleName = localStorage.getItem("roleName");
  const regionName = localStorage.getItem("region");

  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }
  ////Tables
  const prodHistDiaWiseData = useSelector(
    (state) => state.dashboard.labeledData
  );
  const prodHistDiaWiseColumns = useSelector(
    (state) => state.dashboard.columns
  );

  const prodRcdOrdWiseData = useSelector(
    (state) => state.dashboard.labeledData2
  );
  const prodRcdOrdWiseColumns = useSelector(
    (state) => state.dashboard.columns2
  );

  ////

  useEffect(() => {
    dispatch(fetchInventory()).then(() => setIsLoading(false));
    dispatch(fetchProdHistDiaWise()); ///for table
    dispatch(fetchProdRecordOrderWise()); ///for table
    dispatch(fetchdailyproductionqty());
    dispatch(fetchDiaWiseOrderQty());
  }, [dispatch]);

  useEffect(() => {
    let filterData = originalData;
    let diaWiseInv = [];
    if (gradeFilter !== "") {
      filterData = filterData.filter((item) => item.gradeName === gradeFilter);
      const dias = [...new Set(filterData.map((item) => item.diameter))].sort(
        (a, b) => a - b
      );
      dias.forEach((dia) => {
        diaWiseInv.push({
          diameter: dia,
          weight: 0,
          totalPieces: 0,
          length: 0,
        });
      });

      dias.forEach((dia, index) => {
        filterData.forEach((item) => {
          if (item.diameter * 1 === dia * 1) {
            diaWiseInv[index] = {
              diameter: dia,
              weight: diaWiseInv[index].weight * 1 + item.weight * 1,
              totalPieces:
                diaWiseInv[index].totalPieces * 1 + item.totalPieces * 1,
              length: (diaWiseInv[index].length * 1 + item.length * 1) / 2,
            };
          }
        });
      });
      console.log(diaWiseInv);
    }

    const diameterdata = diaWiseInv.map((item) => item.diameter);
    const totalPieces = diaWiseInv.map((item) => item.totalPieces);
    const itemlength = diaWiseInv.map((item) => item.length);
    const itemweight = diaWiseInv.map((item) => item.weight);

    setTotalcount(diaWiseInv.length);
    setxaxisdiameter(diameterdata);
    setyaxistotalpieces(totalPieces);
    setDataitemlength(itemlength);
    setDataitemweigth(itemweight);

    const productionDates =
      region !== "All"
        ? dailyWiseData.map((item) => item.productionDate)
        : dailyproductiongraph.map((item) => item.productionDate);
    setxaxisproductiondate(productionDates);
    const noOfPieces =
      region !== "All"
        ? dailyWiseData.map((item) => item.noOfPieces)
        : dailyproductiongraph.map((item) => item.noOfPieces);
    setyaxisnoOfPieces(noOfPieces);

    const diameter =
      regionForDiawise !== "All"
        ? diaWiseData.map((item) => item.diameter)
        : prodDiawiseorderqty.map((item) => item.diameter);

    const orderQuantity =
      regionForDiawise !== "All"
        ? diaWiseData.map((item) => item.orderQuantity)
        : prodDiawiseorderqty.map((item) => item.orderQuantity);

    const deliveredQuantity =
      regionForDiawise !== "All"
        ? diaWiseData.map((item) => item.deliveredQuantity)
        : prodDiawiseorderqty.map((item) => item.deliveredQuantity);

    const balanceQuantity =
      regionForDiawise !== "All"
        ? diaWiseData.map((item) => item.balanceQuantity)
        : prodDiawiseorderqty.map((item) => item.balanceQuantity);

    setxaxisDiawiseDiameter(diameter);
    setyaxisDiawiseorderQuantity(orderQuantity);
    setyaxisDiawisedeliveredQuantity(deliveredQuantity);
    setyaxisDiawisebalanceQuantity(balanceQuantity);
  }, [
    originalData,
    dailyproductiongraph,
    prodDiawiseorderqty,
    diaWiseData,
    dailyWiseData,
    gradeFilter,
    region,
    regionForDiawise,
  ]);

  const applyFilters = () => {
    const currentfilteredData = originalData.filter((item) => {
      const dateString =
        item.date && typeof item.date === "string" ? item.date : "";
      const itemDate = new Date(dateString.split("-").reverse().join("-"));
      const fromDate = fromDateFilter ? new Date(fromDateFilter) : null;
      const toDate = toDateFilter ? new Date(toDateFilter) : null;

      return (
        (!fromDate || itemDate >= fromDate) && (!toDate || itemDate <= toDate)
      );
    });

    if (currentfilteredData.length === 0) {
      console.log("no data");
      toast.error("No data for the selected date range");
    } else {
      setCoilDateFilter(currentfilteredData);

      const coilFilteredData = currentfilteredData.filter(
        (item) => item.route === "coil"
      );

      const extractData = (data, property) => {
        return data.map((item) => item[property]);
      };

      const graphCoil = extractData(coilFilteredData, "date");
      setCoilToDateFilter(graphCoil);

      const normalgraphCoil = extractData(coilFilteredData, "normalorderhrs");
      setCoiltonormalDateFilter(normalgraphCoil);

      const rushgraphCoil = extractData(coilFilteredData, "rushorderhrs");
      setCoiltorubeDateFilter(rushgraphCoil);

      const rebarFilteredData = currentfilteredData.filter(
        (item) => item.route === "rebar"
      );

      const rebargraphCoil = extractData(rebarFilteredData, "date");
      console.log(rebargraphCoil);

      const rebarnormalGraphCoil = extractData(
        rebarFilteredData,
        "normalorderhrs"
      );
      console.log(rebarnormalGraphCoil);

      const rebarRushgraphCoil = extractData(rebarFilteredData, "rushorderhrs");
      console.log(rebarRushgraphCoil);
    }
  };

  useEffect(() => {
    const currentDate = new Date();
    const formattedCurrentDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    setFromDateFilter(formattedCurrentDate);
    setToDateFilter(formattedCurrentDate);
    // applyFilters();
  }, []);

  const dataset = {
    labels: xaxisdiameter,
    datasets: [
      {
        label: "Weight",
        data: yaxistotalpieces,
        borderColor: "#8884d8",
        backgroundColor: "#8884d8",
        pointBorderColor: "red",
        borderWidth: 2,
        pointBackgroundColor: "red",
        barThickness: 20,
      },
    ],
  };

  const optionsForcoil = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        fontSize: 50,
        display: false,
        font: { size: 20 },
      },
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 1,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "Diameter",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "Weight (T)",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
      },
    },
  };
  const Linedataset = {
    labels: xaxisdiameter,
    datasets: [
      {
        label: "length",
        data: dataitemlength,
        borderColor: "#8884d8",
        backgroundColor: "#8884d8",
        pointBorderColor: "#8884d8",
        borderWidth: 1.4,
        pointBackgroundColor: "#8884d8",
        barThickness: 10,
      },
      {
        label: "Weight",
        data: dataitemweigth,
        borderColor: "#82ca9d",
        backgroundColor: "#82ca9d",
        pointBorderColor: "#82ca9d",
        borderWidth: 1.4,
        pointBackgroundColor: "#82ca9d",
        barThickness: 10,
      },
    ],
  };

  const Lineoptions = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 12,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "Diameter",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "Length ,Weight",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
    },
  };

  const chartData = {
    labels: [...new Set(xaxisproductiondate)],
    datasets: [
      {
        label: "No of Pieces",
        data: yaxisnoOfPieces,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "red",
        borderWidth: 2,
        pointBackgroundColor: "red",
        barThickness: 20,
      },
    ],
  };

  const optionschartData = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      // tooltip: {
      //   callbacks: {
      //     label: function (tooltipItem, data) {
      //       console.log(data)
      //       const dataset = data.datasets[tooltipItem.datasetIndex];
      //       const item = dataset.data[tooltipItem.index];
      //       const dataIndex = tooltipItem.index;
      //       const { diameter, weight } = dailyproductiongraph[dataIndex];
      //       return `No of Pieces: ${item}, Diameter: ${diameter}, Weight: ${weight}`;
      //     },
      //   },
      // },
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.parsed.y;
            return ` ${label}`;
          },
          afterLabel: function (context) {
            const dataIndex = context.dataIndex;
            const diameter =
              region !== "All"
                ? dailyWiseData[dataIndex]?.diameter
                : dailyproductiongraph[dataIndex].diameter;
            const weight =
              region !== "All"
                ? dailyWiseData[dataIndex]?.weight
                : dailyproductiongraph[dataIndex].weight;

            return `Diameter: ${diameter}, Weight: ${weight}`;
          },
        },
      },

      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 12,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "Production Date",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "Total No of pieces",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
    },
  };

  const diawisedataset = {
    labels: [...new Set(xaxisDiawiseDiameter)].sort((a, b) => a - b),
    datasets: [
      {
        label: "OrderQuantity",
        data: yaxisDiawiseorderQuantity,
        borderColor: "yellow",
        backgroundColor: "yellow",
        pointBorderColor: "yellow",
        borderWidth: 2,
        pointBackgroundColor: "yellow",
        barThickness: 19,
      },
      {
        label: "DeliveredQuantity",
        data: yaxisDiawisedeliveredQuantity,
        borderColor: "green",
        backgroundColor: "green",
        pointBorderColor: "green",
        borderWidth: 2,
        pointBackgroundColor: "green",
        barThickness: 20,
      },
      {
        label: "BalanceQuantity",
        data: yaxisDiawisebalanceQuantity,
        borderColor: "orange",
        backgroundColor: "orange",
        pointBorderColor: "orange",
        borderWidth: 2,
        pointBackgroundColor: "orange",
        barThickness: 20,
      },
    ],
  };

  const diawiseoptions = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        fontSize: 50,
        display: false,
        font: { size: 20 },
      },
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 1,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "Diameter",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "Quantity Count",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
      },
    },
  };
  //............................................customer order wise...............................................//
  const getCustomerOrder = async () => {
    await api
      .customerWiseOrders()
      .then((result) => {
        setCustomerWiseOrders(result?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // console.log(customerWiseOrders, "customerWiseOrders");
  useEffect(() => {
    getCustomerOrder();
  }, []);

  const getServiceCenterList = async () => {
    await api
      .listServiceCenter()
      .then((result) => {
        setServiceCenterListData(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getServiceCenterList();
  }, []);

  const getListDashboardApp = async () => {
    await api
      .listDashBoardApp(date, name)
      .then((result) => {
        setServiceCenterData(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getAppMetricsByRegions();
  }, [regionForAppMetrics]);
  const getAppMetricsByRegions = async () => {
    await api
      .listServiceCenterByRegion(regionForAppMetrics)
      .then((result) => {
        setRegionWiseSeviceCenterDataForApp(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getListDashboardApp();
  }, [date, name]);

  const filterCutomerDataBasedOnName = () => {
    customerWiseOrders &&
      customerWiseOrders.length > 0 &&
      customerWiseOrders.filter((customOrderName) => {
        if (customOrderName?.customerName === customerOrderWiseFilter) {
          setCustomOrderGraphData(customOrderName);
        }
      });
  };

  useEffect(() => {
    filterCutomerDataBasedOnName();
  }, [customerOrderWiseFilter]);

  useEffect(() => {
    getRegions();
  }, [regionForCustomer]);
  const getRegions = async () => {
    await api
      .listServiceCenterByRegion(regionForCustomer)
      .then((result) => {
        if (result?.data?.rows.length > 0) {
          setRegionWiseSeviceCenterDataForCustomer(result?.data?.rows);
        } else {
          setRegionWiseSeviceCenterDataForCustomer([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const serviceData =
      regionWiseSeviceCenterDataForCustomer &&
      regionWiseSeviceCenterDataForCustomer.length > 0 &&
      regionWiseSeviceCenterDataForCustomer.filter(
        (item) => item.name === serviceCenterNameForCustomerWise
      );

    if (serviceData.length > 0) {
      getListCustomer(serviceData[0]?.serviceCenterId);
    }
  }, [serviceCenterNameForCustomerWise]);
  const getListCustomer = async (serviceCenterId) => {
    let queryString = "";
    if (serviceCenterId) {
      queryString += `?serviceCenterId=${serviceCenterId}`;
    }
    await api
      .listCustomers(queryString)
      .then((result) => {
        setAllCustomers(result?.data.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getListCustomer();
  }, []);
  ///...............................get diawise dashboard functions..........................///

  useEffect(() => {
    // getDiaWisequantityByServiceId();
    getDiaWisequantityByRegions();
  }, [regionForDiawise, diaWiseName]);
  const getDiaWisequantityByRegions = async () => {
    await api
      .listServiceCenterByRegion(regionForDiawise)
      .then((result) => {
        setRegionWiseSeviceCenterData(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const serviceData = regionWiseSeviceCenterData.filter(
      (item) => item.name === diaWiseName
    );

    if (serviceData.length > 0) {
      getDiaWisequantityByServiceId(serviceData[0]?.serviceCenterId);
    }
  }, [diaWiseName, regionForDiawise]);
  const getDiaWisequantityByServiceId = async (serviceCenterId) => {
    let queryString = "";
    if (regionForDiawise) {
      queryString += `?region=${regionForDiawise}&`;
    }
    if (serviceCenterId) {
      queryString += `serviceCenterId=${serviceCenterId}&`;
    }
    await api
      .listDiaWiseQuantityByRegion(queryString)
      .then((result) => {
        if (result?.data?.rows.length > 0) {
          setDiaWiseData(result?.data?.rows);
        } else {
          setDiaWiseData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  ///...............................get dailyWise dashboard functions..........................///

  useEffect(() => {
    // getDailyWisequantityByServiceId();
    getDailyWiseByRegions();
  }, [region, dailyWiseName]);
  const getDailyWiseByRegions = async () => {
    await api
      .listServiceCenterByRegion(region)
      .then((result) => {
        setRegionWiseSeviceCenterDataForDailyWise(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const serviceData = regionWiseSeviceCenterDataForDailyWise.filter(
      (item) => item.name === dailyWiseName
    );
    if (serviceData.length > 0) {
      getDailyWisequantityByServiceId(serviceData[0]?.serviceCenterId);
    }
  }, [dailyWiseName, region, startDate, endDate]);
  const getDailyWisequantityByServiceId = async (serviceCenterId) => {
    let queryString = "";
    if (region) {
      queryString += `?region=${region}&`;
    }
    if (serviceCenterId) {
      queryString += `serviceCenterId=${serviceCenterId}&`;
    }

    if (startDate) {
      queryString += `sDate=${startDate}&`;
    }
    if (endDate) {
      queryString += `eDate=${endDate}`;
    }

    await api
      .listDailyProductionByServiceIdAndRegion(queryString)
      .then((result) => {
        if (result?.data?.rows.length > 0) {
          setDailyWiseData(result?.data?.rows);
        } else {
          setDailyWiseData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const chartDataCustomerOrder = {
    labels: [
      "Total Quantity",
      "Finished Quantity",
      "Balance Quantity",
      "Dispatched Quantity",
    ],
    datasets: [
      {
        label: "Total Quantity",
        data: [
          customOrderGraphData.totalQuantity,
          customOrderGraphData.finishedQuantity,
          customOrderGraphData.balanceQuantity,
          customOrderGraphData.dispatchedQuantity,
        ],
        borderColor: "#3480eb",
        backgroundColor: ["#3480eb", "#18BC27", "#FF3131", "#F6BF1D"],
        barThickness: 35,
        spanGaps: true,
      },
    ],
  };
  const totalQuantity = customerWiseOrders.map((item) => item.totalQuantity);
  const finishedQuantity = customerWiseOrders.map(
    (item) => item.finishedQuantity
  );
  const balanceQuantity = customerWiseOrders.map(
    (item) => item.balanceQuantity
  );
  const dispatchedQuantity = customerWiseOrders.map(
    (item) => item.dispatchedQuantity
  );
  const chartDataForAllCustomers = {
    labels: [
      "Total Quantity",
      "Finished Quantity",
      "Balance Quantity",
      "Dispatched Quantity",
    ],
    datasets: [
      {
        label: "Total Quantity",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 99, 132, 0.4)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        data: totalQuantity,
      },
      {
        label: "Finished Quantity",
        backgroundColor: "rgba(255, 99, 132, 0.2)",
        borderColor: "rgba(255, 99, 132, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(255, 99, 132, 0.4)",
        hoverBorderColor: "rgba(255, 99, 132, 1)",
        data: finishedQuantity,
      },
      {
        label: "Balance Quantity",
        backgroundColor: "rgba(54, 162, 235, 0.2)",
        borderColor: "rgba(54, 162, 235, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(54, 162, 235, 0.4)",
        hoverBorderColor: "rgba(54, 162, 235, 1)",
        data: balanceQuantity,
      },
      {
        label: "Dispatched Quantity",
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
        hoverBackgroundColor: "rgba(75, 192, 192, 0.4)",
        hoverBorderColor: "rgba(75, 192, 192, 1)",
        data: dispatchedQuantity,
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Total Quantity",
        },
        stacked: true,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        interaction: {
          intersect: false,
        },
      },
      datalabels: {
        display: false,
        align: "center",
        anchor: "center",
      },
      title: {
        display: true,
      },
    },
  };
  const optionsForAppMetrics = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Success Count",
        },
        stacked: true,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        interaction: {
          intersect: false,
        },
      },
      datalabels: {
        display: false,
        align: "center",
        anchor: "center",
      },
      title: {
        display: true,
      },
    },
  };

  const labelsForGraph = serviceCenterData.map((item) => {
    return item?.functionName;
  });
  const dataForGraph2 = serviceCenterData.map((item) => {
    return item?.isSuccessCount;
  });
  const dailyGraph = {
    labels: [...new Set(labelsForGraph)],
    datasets: [
      {
        // label: "Total Quantity",
        data: [dataForGraph2].flat(),
        borderColor: "#3480eb",
        backgroundColor: ["#3480eb"],
        barThickness: 35,
        spanGaps: true,
      },
    ],
  };

  return (
    <>
      <div>
        <div className="main-title">INVENTORY DASHBOARD</div>

        <div className="main-container">
          <div className="main-cards">
            <div className="card">
              <div className="card-inner">
                <h3>Total Diameter</h3>
                <img
                  src={Products}
                  style={{ height: 30, width: 30 }}
                  className="card_icon"
                  alt="Total count"
                />
              </div>
              <h2>{totalcount}</h2>
            </div>
          </div>

          <div
            className="filter-card"
            style={{ margin: "20px 10px", padding: "20px 20px" }}
          >
            <div
              className="filter"
              style={{ alignItems: "center", gap: "10px" }}
            >
              <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                Grade
              </label>
              <select
                name="grade"
                id="dia-total-gradeFil"
                style={{ width: "fit-content" }}
                onChange={(e) => setGradeFilter(e.target.value)}
              >
                {/* <option value="IS:1786 - FE550D">IS:1786 - FE550D</option> */}
                {[...new Set(originalData.map((item) => item.gradeName))].map(
                  (data) => (
                    <option value={data}>{data}</option>
                  )
                )}
              </select>
            </div>
          </div>

          <div className="charts">
            <div className="head">Diameter Vs TotalPieces</div>
            <div className="chart-box">
              <Bar
                // className={hmiCss.formGroup2}
                data={dataset}
                options={optionsForcoil}
                height={400}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              />
            </div>
            <div className="main-title">Diameter Vs length,weight</div>
            <div className="chart-box">
              <div>
                <Line
                  data={Linedataset}
                  height={400}
                  // width={300}
                  options={Lineoptions}
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="main-title" style={{ fontSize: "30px" }}>
          PRODUCTION DASHBOARD
        </div>
        <div className="head">Daily Production quantity</div>
        <div
          className="filter-card"
          style={{
            margin: "20px 10px",
            padding: "20px 20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
            {roleName === "superadmin" ? (
              <>
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Regions
                </label>
                <select
                  name="grade"
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setRegion(e.target.value)}
                >
                  <option value="All">All</option>
                  {regions.map((region, index) => (
                    <option key={index} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                Region:{regionName}
              </label>
            )}
          </div>
          <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
            <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
              Service Center Name
            </label>
            <select
              name="grade"
              id="dia-total-gradeFil"
              style={{ width: "fit-content" }}
              onChange={(e) => setDailyWiseName(e.target.value)}
            >
              <option value="" hidden>
                -Select-
              </option>
              {[
                ...new Set(
                  region === ""
                    ? serviceCenterListData.map((item) => item.name)
                    : regionWiseSeviceCenterDataForDailyWise.map(
                        (item) => item.name
                      )
                ),
              ].map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select>
          </div>
          {roleName === "superadmin" && (
            <>
              <div
                className="filter"
                style={{ alignItems: "center", gap: "10px" }}
              >
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Start Date
                </label>
                <input
                  name="grade"
                  type="date"
                  value={startDate}
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setStartDate(e.target.value)}
                  // max={maxDate}
                ></input>
              </div>
              <div
                className="filter"
                style={{ alignItems: "center", gap: "10px" }}
              >
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  End Date
                </label>
                <input
                  name="grade"
                  type="date"
                  value={endDate}
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setEndDate(e.target.value)}
                  max={maxDate}
                ></input>
              </div>
            </>
          )}
        </div>
        <div className="chart-box">
          <Bar
            // className={hmiCss.formGroup2}
            data={chartData}
            options={optionschartData}
            height={400}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          />
        </div> */}

        <div className="head">Dia Wise Order Quantity</div>
        <div
          className="filter-card"
          style={{
            margin: "20px 10px",
            padding: "20px 20px",
            // display: "flex",
            // flexDirection: "row",
          }}
        >
          <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
            {roleName === "superadmin" && roleName !== "admin" ? (
              <>
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Regions
                </label>
                <select
                  name="grade"
                  id="dia-total-gradeFil"
                  style={{
                    width: "fit-content",
                    display: "flex",
                    flexDirection: "flex-end",
                  }}
                  onChange={(e) => setRegionForDiawise(e.target.value)}
                >
                  <option value="All">All</option>
                  {regions.map((region, index) => (
                    <option key={index} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              roleName !== "admin" && (
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Region:{roleName !== "admin" && regionName}
                </label>
              )
            )}
            {roleName !== "admin" && (
              <>
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Service Center Name
                </label>
                <select
                  name="grade"
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setDiaWiseName(e.target.value)}
                >
                  <option value="" hidden>
                    -Select-
                  </option>
                  {[
                    ...new Set(
                      regionWiseSeviceCenterData.length > 0
                        ? regionWiseSeviceCenterData.map((item) => item.name)
                        : serviceCenterListData.map((item) => item.name)
                    ),
                  ].map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </>
            )}
          </div>

          {/* {roleName === "superadmin" && (
            <>
              <div
                className="filter"
                style={{ alignItems: "center", gap: "10px" }}
              >
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Start Date
                </label>
                <input
                  name="grade"
                  type="date"
                  value={startDateForDiaWise}
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setStartDateForDiaWise(e.target.value)}
                  // max={maxDate}
                ></input>
              </div>
              <div
                className="filter"
                style={{ alignItems: "center", gap: "10px" }}
              >
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  End Date
                </label>
                <input
                  name="grade"
                  type="date"
                  value={endDateForDiawise}
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setEndDateForDiaWise(e.target.value)}
                  max={maxDate}
                ></input>
              </div>
            </>
          )} */}
        </div>
        <div className="chart-box">
          <Bar
            // className={hmiCss.formGroup2}
            data={diawisedataset}
            options={diawiseoptions}
            height={400}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          />
        </div>

        <div className="head">Dia Wise Production History</div>

        <AGResizeTables
          data={prodHistDiaWiseData}
          columns={prodHistDiaWiseColumns}
          width="75%"
        />

        <div className="head">Order Wise Production Record</div>

        <AGResizeTables
          data={prodRcdOrdWiseData}
          columns={prodRcdOrdWiseColumns}
          width="100%"
        />
      </div>
    </>
  );
};

export default DashBoard;
