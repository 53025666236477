import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React from 'react'
import font from "../../../../assets/fonts/OpenSans-Variable.ttf"
// import font2 from "../../../../assets/fonts/OpenSans-Bold.ttf"
// import font3 from "../../../../assets/fonts/OpenSans-MediumItalic.ttf"
import { dateChanger } from '../../../../components/reusableFunctions';
import font2 from "../../../../assets/fonts/NotoSansSymbols-Regular.ttf"
import font3 from "../../../../assets/fonts/NotoSansSymbols2-Regular.ttf"
import font5 from "../../../../assets/fonts/Roboto-Bold.ttf"
import HeaderRight from '../../../../assets/images/productSelectionPage/smartFabLogo.png'


const serviceCenterName = sessionStorage.getItem('serviceCenterName');

const styles = StyleSheet.create({
    
    bbsInfo: {
        flexDirection: 'column',
        fontSize: '9',
        // border: '1px solid black',
        width: '100%',
        alignItems: "center",
        height: '23%'
    },
    bbsHeader: {
        flexDirection: 'row',
        // border: '1px slid black',
        width: '100%',
        height: '30%',
    },
    bbsParty: {
        flexDirection: 'row',
        alignSelf: "center",
        width: '95%',
        marginTop: '10px',
        marginBottom: '10px',
        borderBottom: '1px solid black',
        // border: '1px solid black',
        height: '60%'
    },
    belowParty: {
        flexDirection: 'column',
        width: '95%',
        // border: '1px solid black',
        height: '20%'
    },
    extraDetails: {
        flexDirection: 'row',
        height: '100%',
        // border: '1px solid red',
        width: '100%'
    },
})

const BbsTabularPdf = (props) => {

    const { parameters, type = 'bbs tabular', orderInfo, parties, serviceCenterLogo } = props;

    Font.register({
        family: "OpenSans", format: "truetype", src: font
    });

    Font.register({
        family: "OpenSans-Bold", format: "truetype", src: font2
    });

    Font.register({
        family: "OpenSans-Italic", format: "truetype", src: font3
    });


    let orderItemCount = 0
    let tagCount = 0

    const charList = [
        '2690',
        '2742',
        '275B',
        '25A0', '25A1', '25B2', '25B3', '25CF', '25CB', '2680',
        '2687', '2689', '268A', '268B', '268C', '268D', '268E', '268F', '26D2', '2700',
        '2710', '2751', '275D', '2794', '29BF', '2B12', '2B19', '2B1F', '2B20', '2B22',
        '2B85', '2B89', '2B8C', '2B90', '2B94', '2B98', '2B9C', '2BB0', '2BD2', '2BD3',
        '2BD4', '2BD5', '2BD6', '2BD7', '2BD8', '2BD9', '2BDA', '2BDB', '2BDC', '2BDD',
        '2BDE', '2BDF', '2BE0', '2BE1', '2BE3', '2BE4', '2BE5', '2BE6', '2BE7', '2BEC',
        '2BF4',
    ]

    console.log(type);

    const weightCalc = () => {
        let calcW = 0;
        parameters.forEach(element => {
            calcW += element.totalWeight;
        });
        return calcW
    }

    return (
        <>
            

                <Text style={{ textAlign: 'right', fontSize: '8' }} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} of ${totalPages}`
                )} fixed />

                <View render={({ pageNumber }) => {

                    return pageNumber !== 1 && (<>
                        <TableHeaderFixed />
                    </>)

                }} fixed />

                <BbsInfo parameters={parameters} parties={parties} orderInfo={orderInfo} serviceCenterLogo={serviceCenterLogo} totalWeight={weightCalc()}/>

                <View style={{
                    flexDirection: 'column',
                    width: '100%',
                    alignItems: "center"
                }}>

                    <TableHeader />

                </View>

                {parameters.map((element, index) => {
                    return (
                        <View wrap={false}>

                            <PartItemMapper partItem={element} index={index} />

                        </View>
                    )
                }
                )}
                <View style={{ position: 'absolute', bottom: '0', margin: '1%', padding: '4px 0', borderTop: '1px solid #d5d5d5', width: '100%' }} fixed>
                    
                </View>



            
        </>
    )

}

export default BbsTabularPdf

const TableHeaderFixed = () => {

    let tableData = [
        { label: "Sl No", width: '4%' },
        { label: "RM", width: '6%' },
        { label: "Finish", width: '8%' },
        { label: "MW /CW Dia mm", width: '6%' },
        { label: "MW /CW spacing mm", width: '7%' },
        { label: "Length /Width", width: '8%' },
        { label: "MW (OH1 /OH2)", width: '8%' },
        { label: "CW (OH1 /OH2)", width: '8%' },
        { label: "No. of MW /CW", width: '6%' },
        { label: "Total in NOS", width: '7%' },
        { label: "Wt. in MT", width: '7%' },
        { label: "Sheet Weight in Kg", width: '7%' },
        { label: "Kg/m2", width: '7%' },
        { label: "Welded Length /Width", width: '7%' },
        { label: "MW /CW total length", width: '8%' },
        // { label: "mm2/m for MW along width", width: '7%' },
        // { label: "mm2/m for CW along length", width: '7%' }
    ]

    return (
        <View style={{ flexDirection: 'row', height: '60px', width: '100%' }}>
            {tableData.map((data, tableIndex) => (
                <View style={{
                    flexDirection: 'column',
                    width: data.width,
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                    fontSize: '9',
                    fontFamily: 'OpenSans-Bold',
                    borderLeft: `${tableIndex === 0 ? '1px' : '0px'}`,
                    padding:'1'
                }}>
                    <Text style={{}}>{data.label || ''}</Text>
                </View>
            ))}
        </View>
    )
}

const BbsInfo = (props) => {

    const { parties, parameters, orderInfo, serviceCenterLogo, totalWeight } = props;
    const l = parameters.length;

    console.log(serviceCenterName);

    return (
        <View style={styles.bbsInfo}>
            <View style={styles.bbsHeader}>
                <View style={{ width: '15%', height: '100%', border: '1px solid black' }}>
                    {
                        serviceCenterLogo != null && serviceCenterLogo !== '' ?
                            <Image src={serviceCenterLogo} style={{ height: '100%' }} alt={serviceCenterName}></Image>
                            : <Text style={{ fontSize: '15' }}>{serviceCenterName}</Text>
                    }
                </View>
                <View style={{ flexDirection: 'column', width: '70%', height: '100%', border: '1px solid black' }}>
                    <View style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                        <Text style={{ textDecoration: 'underline', fontSize: '11', fontFamily: 'OpenSans-Bold' }}>Production Parameters</Text>
                        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '5px' }}>
                            <Text style={{ fontWeight: '600', fontSize: '11', width: '40%' }}><Text style={{ fontFamily: 'OpenSans-Bold' }}>Order Id: </Text>{orderInfo.combId || ''}</Text>
                            <Text style={{ fontWeight: '600', fontSize: '11', width: '50%', }}>Ref: {orderInfo.orderRemarks || ''}</Text>
                        </View>

                    </View>
                </View>
                <View style={{ width: '15%', height: '100%', border: '1px solid black' }}>
                    <Image src={HeaderRight} style={{ height: '100' }}></Image>
                </View>
            </View>
            <View style={styles.bbsParty}>
                <View style={{ width: '33.33%', height: '100%', gap: '5px', paddingLeft: '15px' }}>
                    <Text style={{ textDecoration: 'underline', fontWeight: '600', fontSize: '11', fontFamily: 'OpenSans-Bold' }}>Sold to Party</Text>
                    <Text>{parties.soldToParty || ''}</Text>
                    <Text>{parties.soldToPartyAddress || ''}</Text>
                    <Text>{parties.soldToPartyEmail || ''}</Text>
                    <Text>{parties.soldToPartyPhoneNo || ''}</Text>
                </View>
                <View style={{ width: '33.33%', height: '100%', gap: '5px', paddingLeft: '15px' }}>
                    <Text style={{ textDecoration: 'underline', fontWeight: '600', fontSize: '11', fontFamily: 'OpenSans-Bold' }}>Bill to Party</Text>
                    <Text>{parties.billToParty || ''}</Text>
                    <Text>{parties.billToPartyAddress || ''}</Text>
                    <Text>{parties.billToPartyEmail || ''}</Text>
                    <Text>{parties.billToPartyPhoneNo || ''}</Text>
                </View>
                <View style={{ width: '33.33%', height: '100%', gap: '5px', paddingLeft: '15px' }}>
                    <Text style={{ textDecoration: 'underline', fontWeight: '600', fontSize: '11', fontFamily: 'OpenSans-Bold' }}>Ship to Party</Text>
                    <Text>{parties.shipToParty || ''}</Text>
                    <Text>{parties.shipToPartyAddress || ''}</Text>
                    <Text>{parties.shipToPartyEmail || ''}</Text>
                    <Text>{parties.shipToPartyPhoneNo || ''}</Text>
                </View>

            </View>
            <View style={styles.belowParty}>
                <View style={styles.extraDetails}>
                    <View style={{ width: '33.33%', paddingLeft: '15px' }}>
                        <Text>No. of Parameters: {l || ''}</Text>
                    </View>
                    <View style={{ width: '33.33%', paddingLeft: '15px' }}>
                        <Text>Weight (T): {(totalWeight || '')}</Text>
                    </View>
                    <View style={{ width: '33.33%', paddingLeft: '15px' }}>
                        <Text>Order Date: {orderInfo.createdTs || ''}</Text>
                        <Text>Delivery Date: {dateChanger(orderInfo.deliveryDate) || ''}</Text>
                    </View>
                </View>


            </View>
        </View>
    )
}

const TableHeader = () => {

    let tableData = [
        { label: "Sl No", width: '4%' },
        { label: "RM", width: '6%' },
        { label: "Finish", width: '8%' },
        { label: "MW /CW Dia mm", width: '6%' },
        { label: "MW /CW spacing mm", width: '7%' },
        { label: "Length /Width", width: '8%' },
        { label: "MW (OH1 /OH2)", width: '8%' },
        { label: "CW (OH1 /OH2)", width: '8%' },
        { label: "No. of MW /CW", width: '6%' },
        { label: "Total in NOS", width: '7%' },
        { label: "Wt. in MT", width: '7%' },
        { label: "Sheet Weight in Kg", width: '7%' },
        { label: "Kg/m2", width: '7%' },
        { label: "Welded Length /Width", width: '8%' },
        { label: "MW /CW total length", width: '8%' },
        // { label: "mm2/m for MW along width", width: '7%' },
        // { label: "mm2/m for CW along length", width: '7%' }
    ]

    return (
        <View style={{ flexDirection: 'row', height: '60px', width: '100%' }}>
            {tableData.map((data, tableIndex) => (
                <View style={{
                    flexDirection: 'column',
                    width: data.width,
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                    fontSize: '9',
                    textAlign: 'center',
                    fontFamily: 'OpenSans-Bold',
                    borderLeft: `${tableIndex === 0 ? '1px' : '0px'}`,
                    padding:'1'
                }}>

                    <Text style={{}}>{data.label || ''}</Text>
                </View>
            ))}
        </View>
    )
}


const PartItemMapper = ({ partItem, index }) => {

    let tableData = [
        { key: "index", width: '4%', },
        { key: "rm", width: '6%' },
        { key: "finish", width: '8%' },
        { key: "mwDia-cwDia", width: '6%' },
        { key: "mwSpacing-cwSpacing", width: '7%' },
        { key: "length-width", width: '8%' },
        { key: "mwOH1-mwOH2", width: '8%' },
        { key: "cwOH1-cwOH2", width: '8%' },
        { key: "noOfMw-noOfCw", width: '6%' },
        { key: "totalSheets", width: '7%' },
        { key: "totalWeight", width: '7%' },
        { key: "sheetWeight", width: '7%' },
        { key: "weight/area", width: '7%' }, // sheetWeight/(length*width)
        { key: "weldedLength-weldedWidth", width: '8%' },
        { key: "mwTotalLength-cwTotalLength", width: '8%' },
        // { key: "mm2perMeterForMwAlongWidth", width: '7%' },
        // { key: "mm2perMeterForMwAlongLength", width: '7%' }
    ]

    const keyConverter = (data) => {

        const [first, second] = data.split('-')

        return `${partItem[first]}
        /${partItem[second]}`

    }

    const rmConverter = (data) => {
        if (data.includes('- ')) {
           return data.split('- ')[1] 
        } else {
            return data
        }
        
    }


    return (
        <View wrap={false} style={{ flexDirection: 'row', height: '79px', width: '100%', marginBottom: `${'0'}` }}>

            {tableData.map((data, partIndex) => (
                <>

                    <View style={{
                        flexDirection: 'column',
                        width: data.width,
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid black',
                        fontSize: '9',
                        borderLeft: `${partIndex === 0 ? '1px' : '0px'}`,
                        borderTop: `${'0px'}`

                    }}>
                        {/* TT-${partItem[data.key]} */}
                        {
                            data.key === 'index' ? <Text style={{ fontWeight: "bold" }}>{index + 1 || ''}</Text> :
                                data.key.includes('-') ? <Text style={{ fontWeight: "bold", textAlign: 'center' }}>{keyConverter(data.key)}</Text> :
                                    data.key === 'weight/area' ? <Text style={{ fontWeight: "bold" }}>{Math.round((partItem.sheetWeight * 100000000)/ (partItem.length * partItem.width))/100}</Text> :
                                        data.key === 'rm' ? <Text style={{ fontWeight: "bold" }}>{rmConverter(partItem.smartFabProductType)}</Text> :
                                            <Text style={{ fontWeight: "bold" }}>{partItem[data.key]}</Text>
                        }

                        {/* <Image src={url} style={{ width: '100%' }}></Image> :
                                    data.key === 'threads' ?
                                        <Text style={{ fontWeight: "bold", textAlign: 'center' }}>{`HT-${partItem.totalNoOfPieces * partItem.halfThread || '0'}
  FT-${partItem.totalNoOfPieces * partItem.fullThread || '0'}`}</Text> :
                                        <Text style={{ fontWeight: "bold" }}>{partItem[data.key]}</Text> */}

                    </View>
                </>
            ))}
        </View>
    )
}