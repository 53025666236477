import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { postOrganizations, updateOrganization } from '../../../redux-tookit/slices/organizationSlice';
import Button from '../../../utils/Button';
import { fetchEquipmentCategory, fetchEquipmentCategorySF, postEquipmentCategory, postEquipmentCategorySF, updateEquipmentCategory, updateEquipmentCategorySF } from '../../../redux-tookit/slices/equipmentCategorySlice';

const AddEquipmentCategory = (props) => {
  const dispatch = useDispatch();
  const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData, prname } = props
  const [newRow, setNewRow] = useState({});
  const columns = useSelector((state) => state.equipmentCategory.columns);
  const handleInputChange = (header, e) => {
    const updatedData = JSON.parse(JSON.stringify(editData));
    updatedData[header] = e.target.value;
    setEditData(updatedData);
  }

  const handleSaveButtonClick = async (e) => {

    if (prname === 'smartFab') {
      dispatch(postEquipmentCategorySF({ ...newRow })).then(res => {
        if (res.payload !== undefined) {
          setTimeout(() => { dispatch(fetchEquipmentCategorySF()) }, 100)
        }
      })
    } else {
      dispatch(postEquipmentCategory({ ...newRow })).then(res => {
        if (res.payload !== undefined) {
          setTimeout(() => { dispatch(fetchEquipmentCategory()) }, 100)
        }
      })
    }



    e.preventDefault();
    console.log(newRow);
    setIsAdding(false);
    setNewRow({});
  };

  const handleUpdate = async (e) => {
    e.preventDefault();

    let id = '';
    
    let type = ''; 
    
    console.log(id);
    console.log(type);

    if (prname==='smartFab') {
      id = editData.smartFabEquipmentCategoryId;
      type = { 'smartFabEquipmentCategory': editData.smartFabEquipmentCategory };
      dispatch(updateEquipmentCategorySF({ id, type }))
    } else {
      id = editData.equipmentCategoryId;
      type = { 'equipmentCategory': editData.equipmentCategory };
      dispatch(updateEquipmentCategory({ id, type }))
    }

    
    setIsEditing(false);

  }

  return (
    <div>
      <form onSubmit={handleSaveButtonClick}>{isAdding &&
        <div>
          <div className="AddContainer" >
            <div className="form-container"><div className="head">Add Equipment Category</div>
              <div className="table-container">
                {Object.keys(columns).map((column) => (
                  !column.toLowerCase().includes('id') ?
                    (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                      <label>
                        {columns[column].label}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        key={column}
                        type={columns[column].type}
                        className='table-input'
                        required
                        value={newRow[column] || ''}
                        onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                      />
                    </div>) :
                    ""
                ))}
              </div>
              <div className="bottom-button-container">
                <Button type='submit' label='Save' className='blue' />
                <Button onClick={() => { setIsAdding(false); setNewRow({}) }} label='Cancel' className='blue' />
              </div>
            </div>
          </div>
          <div className="bg-dark"></div>
        </div>
      }
      </form>
      <form onSubmit={handleUpdate}>{isEditing &&
        <div>
          <div className="AddContainer" >
            <div className="form-container"><div className="head">Edit Equipment Category</div>
              <div className="table-container">
                {Object.keys(columns).map((key) => (
                  columns[key].editable === true ?
                    (

                      <div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                        <label>
                          {columns[key].label}
                          <span style={{ color: 'red' }}>*</span>
                        </label>
                        <input
                          type={columns[key].type}
                          className='table-input'
                          value={editData[key]}
                          required
                          onChange={(e) => handleInputChange(key, e)}
                        /></div>
                    ) :
                    ""
                ))}
              </div>
              <div className="bottom-button-container">
                <Button type='submit' label='Save' className='blue' />
                <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
              </div>
            </div>
          </div>
          <div className="bg-dark"></div>
        </div>
      }
      </form>
    </div>
  )
}

export default AddEquipmentCategory