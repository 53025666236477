import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const initialState = {
  columns: {},
  labeledData: [],
  loading: 'idle',
  error: null,
};
 
export const fetchServiceCenterEquipments = createAsyncThunk('data/fetchServiceCenterEquipments', async () => {
  try {
    const response = await api.listServiceCenterEquipments();
    const json = response.data;
 
    const columnLabels = {
        sceId: {label:"Service Center Equipment ID",type:'number',isRequired:true,pattern:".*",editable:false},
        facilityName:{label:"Facility Name",type:'text',isRequired:true,pattern:".*",editable:true},
       // processTypeId:{label:"Process Type ID",type:"number",isRequired: true,editable:false} ,
        equipmentName:{label: "Equipment Name",type:"text",isRequired: true,pattern:".*",editable:true} ,
        equipmentType:{label:"Equipment Type",type:"text",isRequired: true,pattern:".*",editable:true},
        //equipmentCategoryId:{label: "Equipment Category ID",type:"number",isRequired: true,pattern:".*",editable:false},
        equipmentCategory:{label: 'Equipment Category',type:'text',isRequired: true,editable:true},
        processType:{label: 'Process Type',type:'text',isRequired: true,editable:true},
        dateOfEntry: {label:"Date of Entry",type:'date',isRequired:true,pattern:".*",editable:true},
        efficiency: {label:"Efficiency",type:'number',isRequired:true,pattern:".*",editable:true},

        // Add more as needed
      };

    const selectedColumns = ['sceId','facilityName','equipmentName','equipmentType','equipmentCategory','processType','dateOfEntry','efficiency'];
 
    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });
 
    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        // if(columnName==='dateOfEntry'){
        //   let [dat,tim] = row[columnName].split('T');
        //   let [year, month, day] = dat.split('-')
          
        //   rowData[columnName] = `${day}-${month}-${year}`;
        // } else
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });
 
    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});
export const postServiceCenterEquipments = createAsyncThunk('data/postServiceCenterEquipments', async (newRow) => {
  try {
    const response = await api.addServiceCenterEquipments(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});
export const updateServiceCenterEquipment = createAsyncThunk('data/updateEquipment', async ({id,type}) => {
  try {
    console.log(type);
    const response = await api.updateServiceCenterEquipment(id,type);
   
    const data = response.data;
    console.log('Update Success');
    return {data, id};
  } catch (error) {
    throw error;
  }
});
export const deleteServiceCenterEquipment = createAsyncThunk('data/deleteServiceCenterEquipment', async (id) => {
  try {
    await api.deleteServiceCenterEquipment(id);
    return id;
  } catch (error) {
    throw error.response.data;
  }
});
const serviceCenterEquipmentsSlice = createSlice({
  name: 'serviceCenterEquipments',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchServiceCenterEquipments.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchServiceCenterEquipments.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchServiceCenterEquipments.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(postServiceCenterEquipments.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postServiceCenterEquipments.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        toast.success('Equipment added successfully')
      })
      .addCase(postServiceCenterEquipments.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add Equipment')
      })
      .addCase(updateServiceCenterEquipment.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateServiceCenterEquipment.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
       
        // const index=state.labeledData.find(item=>item.sceId===action.payload.id);
        // if(index){
        //   const updatedItem={sceId: action.payload.id,...action.payload.type};
        //   state.labeledData=state.labeledData.map(item=>{
        //     if(item.sceId===action.payload.id){
        //       return updatedItem;
        //     }
        //     return item;
        //   })
        //   console.log(state.labeledData);
        // }
        //state.labeledData.push(action.payload);
        // toast.success('Equipment updated successfully')
 
      })
      .addCase(updateServiceCenterEquipment.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update Equipment')
 
      })
      .addCase(deleteServiceCenterEquipment.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteServiceCenterEquipment.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        const deletedId = action.payload;
        state.labeledData=state.labeledData.filter((data) => data.sceId !== deletedId);
        toast.success('Equipment deleted successfully')
      })
      .addCase(deleteServiceCenterEquipment.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error(state.error)
      });
  },
});
 
export default serviceCenterEquipmentsSlice.reducer;
//End of File