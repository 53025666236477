import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import '../../assets/styles/ReportPage.css'

import AGTable from '../../components/AGTable';
import TableComponent from '../../components/TableComponent';


const OrderFeatures = () => {

  const dispatch = useDispatch();
  const ogcolumns = useSelector((state) => state.sdbr.OrderFeatures.columns);
  const originalData = useSelector((state) => state.sdbr.OrderFeatures.data);
  const [data, setData] = useState(originalData);
  const [columns, setColumns] = useState(ogcolumns);
  const [diaFilter, setdiaFilter] = useState('');
  const [orderstatusFilter, setorderstatusFilter] = useState('');
  const [ordernoFilter, setordernoFilter] = useState('');
  
  
  const diaValues = [...new Set(originalData.map(item => item.dia))];
  const orderstatusValues = [...new Set(originalData.map(item => item.orderstatus))];
  const ordernoValues = [...new Set(originalData.map(item => item.orderno))];
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
    console.log(rowIndex);
  };

// console.log(obj)
  const applyFilters = () => {

    let filteredData = originalData;

    if (diaFilter) {
      filteredData = filteredData.filter((item) =>
        item.dia.includes(diaFilter)
      );
    }
    if (orderstatusFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderstatus.includes(orderstatusFilter)
      );
    }
    if (ordernoFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderno.includes(ordernoFilter)
      );
    }


    setData(filteredData);
  }

  

  useEffect(() => {

    dispatch(fetchDataFromApi('OrderFeatures'));
    
    setData(originalData);
    
    
    console.log(columns)
  }, [originalData,ogcolumns]);

  
  useEffect(() => {

    applyFilters();
  }, [diaFilter, orderstatusFilter, ordernoFilter]);


  return (
    <div className='sdbr'>
      <div className='filter-card'>
        <div className="Filter-OrdFeat">
          <div className="filter">
            <label htmlFor="diaFilter">Diameter</label>
            <select
              id="diaFilter"
              value={diaFilter}
              onChange={(e) => setdiaFilter(e.target.value)}
            >
              <option value="">All</option>
              {diaValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label htmlFor="orderstatusFilter">Order Status</label>
            <select
              id="orderstatusFilter"
              value={orderstatusFilter}
              onChange={(e) => setorderstatusFilter(e.target.value)}
            >
              <option value="">All</option>
              {orderstatusValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label htmlFor="ordernoFilter">Order No</label>
            <select
              id="ordernoFilter"
              value={ordernoFilter}
              onChange={(e) => setordernoFilter(e.target.value)}
            >
              <option value="">All</option>
              {ordernoValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='head'>Report</div>
      
        <TableComponent data={originalData} columns={ogcolumns} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex}/>
      
    </div>
  );
};

export default OrderFeatures;
