import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GenericTable from '../../components/GenericTable';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import Button from '../../utils/Button';
import '../../assets/styles/TpmRate.css'

const DiaWiseRoute = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.diaWiseRoute.columns);
  const data = useSelector((state) => state.sdbr.diaWiseRoute.data);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };

  useEffect(() => {

    dispatch(fetchDataFromApi('diaWiseRoute'));
  }, [dispatch]);

  
  return (
    <div className='sdbr'>
      <div className='head'>Dia Wise Route</div>

      <GenericTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
      {selectedRowIndex!==null?<div className='Buttons'><Button label='Save' className='blue'/><Button label="Cancel" className='blue' onClick={()=>setSelectedRowIndex(null)}/></div>:""} 
    </div>
  );
};

export default DiaWiseRoute;