import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import TableComponent from '../../components/TableComponent';
import { useNavigate, useParams } from 'react-router-dom';
import FilterComponent from '../../components/FilterComponent';
import excel from '../../assets/images/button-icons/excel.svg';
import Button from '../../utils/Button';
import '../../assets/styles/OrderSummary.css';

const SAPOrderSummary = () => {
    const dispatch=useDispatch();
    const navigate=useNavigate();
    const{sapId}=useParams();
    const originalData=useSelector((state)=>state.sdbr.sapOrderSummary.data);
    const columns=useSelector((state)=>state.sdbr.sapOrderSummary.columns);
    const data=originalData.filter((item) => item.sapId === sapId);
    const filteredColumns = columns.filter((column) => column.key !== 'orderId'&&column.key!=='sapId');
    const [filteredData,setFilteredData]=useState(data);

    const[sapFilter,setSapFilter]=useState('');

    const sapValues = [...new Set(originalData.map(item => item.sapId))];

    const applyFilter = () =>{
        let filteredData1 = originalData;

        if (sapFilter) {
            filteredData1 = filteredData1.filter((item) =>
                item.sapId.includes(sapFilter)
            );
        }
        setFilteredData(filteredData1);
    }

    useEffect(()=>{
        dispatch(fetchDataFromApi("sapOrderSummary"));
    },[dispatch]);
    useEffect(()=>{
        applyFilter();
    },[sapFilter,data]);
    console.log(filteredData);
  return (
    <div className="sdbr">
        <div className="head">SAP Order Summary</div>
        <div className='ordIdFilter' style={{display:"flex", justifyContent:"space-between"}}>
            
            <div>
                <FilterComponent
                    label="SAP Order Id"
                    filterValue={sapFilter}
                    options={sapValues}
                    onFilterChange={setSapFilter}
                />
            </div>
            <div>
                Order Id
                <input type="text"  />
            </div>
        </div>
        <div className='excel'>
            <img src={excel} alt='' width={17} height={18}/>
        </div>
        <TableComponent  columns={filteredColumns} data={filteredData}/>
        <div className="buttons">
        <Button label="Exit" className="blue" onClick={()=>navigate('/order details')}/>
        </div>
    </div>
    
  )
}

export default SAPOrderSummary;
//End of File