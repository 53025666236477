import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
	columns: {},
	labeledData: [],
	fetcher: true,
	loading: 'idle',
	error: null,
};

export const fetchProducts = createAsyncThunk('data/fetchProducts', async () => {
	try {

		const response = await api.listProducts();
		const json = response.data;

		const columnLabels = {
			productMasterId: { label: 'Product ID', type: 'number', editable: false ,isRequired: true,},
			name: { label: 'Product Name', type: 'text', editable: true ,isRequired: true,},
			barType: { label: 'Bar Type', type: 'text', editable: true ,isRequired: true,}
			// Add more as needed
		};

		const selectedColumns = ['productMasterId', 'name', 'barType'];

		const columns = {};
		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});

		return { columns, labeledData };
	} catch (error) {
		throw error;
	}
});
export const postProducts = createAsyncThunk('data/postProducts', async (newRow) => {
	try {
		const response = await api.addProducts(newRow);
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		throw error;
	}
});
export const deleteProduct = createAsyncThunk('data/deleteProduct', async (id) => {
	try {
		await api.deleteProduct(id);
		return id;
	} catch (error) {
		throw error.response.data;
	}
});
export const updateProduct = createAsyncThunk('data/updateProduct', async ({ id, type }) => {
	try {
		console.log(id);
		const response = await api.updateProduct(id, type);
		const data = response.data;
		console.log('Update Success');
		return { data, id, type };
	} catch (error) {
		throw error;
	}
});

//	SMART FAB
export const fetchProductType = createAsyncThunk('data/fetchProductType', async () => {
	try {

		const response = await api.listProductType();
		const json = response.data;

		const columnLabels = {
			smartFabProductTypeId: { label: 'ProductType ID', type: 'number', editable: false ,isRequired: true,},
			smartFabProductType: { label: 'Product Type', type: 'text', editable: true ,isRequired: true,}
			// Add more as needed
		};

		const selectedColumns = ['smartFabProductTypeId', 'smartFabProductType'];

		const columns = {};
		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});

		return { columns, labeledData };
	} catch (error) {
		throw error;
	}
});
export const postProductType = createAsyncThunk('data/postProductType', async (newRow) => {
	try {
		const response = await api.addProductType(newRow);
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		throw error;
	}
});
export const deleteProductType = createAsyncThunk('data/deleteProductType', async (id) => {
	try {
		await api.deleteProductType(id);
		return id;
	} catch (error) {
		throw error.response.data;
	}
});
export const updateProductType = createAsyncThunk('data/updateProductType', async ({ id, type }) => {
	try {
		console.log(id);
		const response = await api.updateProductType(id, type);
		const data = response.data;
		console.log('Update Success');
		return { data, id, type };
	} catch (error) {
		throw error;
	}
});

const productMasterSlice = createSlice({
	name: 'productMaster',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
		//READY BUILD
			.addCase(fetchProducts.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(fetchProducts.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns = action.payload.columns;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchProducts.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(postProducts.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postProducts.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.labeledData.push(action.payload);
				toast.success('Product added sucessfully')
			})
			.addCase(postProducts.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to add product')
			})
			.addCase(updateProduct.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(updateProduct.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				const index = state.labeledData.find(item => item.productMasterId === action.payload.id);
				if (index) {
					const updatedItem = { ...index, name: action.payload.type.name };
					state.labeledData = state.labeledData.map(item => {
						if (item.productMasterId === action.payload.id) {
							return updatedItem;
						}
						return item;
					})
					console.log(state.labeledData);
				}
				toast.success('Product updated sucessfully')
			})
			.addCase(updateProduct.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to update product')
			})
			.addCase(deleteProduct.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(deleteProduct.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				const deletedId = action.payload;
				state.labeledData = state.labeledData.filter((data) => data.productMasterId !== deletedId);
				toast.success('Product deleted sucessfully')
			})
			.addCase(deleteProduct.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error(state.error)
			})

		//SMART FAB

		.addCase(fetchProductType.pending, (state) => {
			state.loading = 'pending';
		})
		.addCase(fetchProductType.fulfilled, (state, action) => {
			state.loading = 'fulfilled';
			state.columns = action.payload.columns;
			state.labeledData = action.payload.labeledData;
		})
		.addCase(fetchProductType.rejected, (state, action) => {
			state.loading = 'rejected';
			state.error = action.error.message;
		})
		.addCase(postProductType.pending, (state) => {
			state.loading = 'pending';
		})
		.addCase(postProductType.fulfilled, (state, action) => {
			state.loading = 'fulfilled';
			state.labeledData.push(action.payload);
			toast.success('Product Type added sucessfully')
		})
		.addCase(postProductType.rejected, (state, action) => {
			state.loading = 'rejected';
			state.error = action.error.message;
			toast.error('Failed to add product')
		})
		.addCase(updateProductType.pending, (state) => {
			state.loading = 'pending';
		})
		.addCase(updateProductType.fulfilled, (state, action) => {
			state.loading = 'fulfilled';
			state.fetcher = !state.fetcher;
			const index = state.labeledData.find(item => item.smartFabProductTypeId === action.payload.id);
			if (index) {
				const updatedItem = { ...index, name: action.payload.type.name };
				state.labeledData = state.labeledData.map(item => {
					if (item.smartFabProductTypeId === action.payload.id) {
						return updatedItem;
					}
					return item;
				})
				console.log(state.labeledData);
			}
			toast.success('Product Type updated sucessfully')
		})
		.addCase(updateProductType.rejected, (state, action) => {
			state.loading = 'rejected';
			state.error = action.error.message;
			toast.error('Failed to update product')
		})
		.addCase(deleteProductType.pending, (state) => {
			state.loading = 'pending';
		})
		.addCase(deleteProductType.fulfilled, (state, action) => {
			state.loading = 'fulfilled';
			const deletedId = action.payload;
			state.labeledData = state.labeledData.filter((data) => data.smartFabProductTypeId !== deletedId);
			toast.success('Product Type deleted sucessfully')
		})
		.addCase(deleteProductType.rejected, (state, action) => {
			state.loading = 'rejected';
			state.error = action.error.message;
			toast.error(state.error)
		});
	},
});
export default productMasterSlice.reducer;
//End of File