import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { dateChanger } from "../../../components/reusableFunctions";
import Button from "../../../utils/Button";
import { fetchOrderSF } from "../../../redux-tookit/slices/orderSlice";
import "./productionParams.css";
import TopButtonContainer from "../../../components/UserAccess/TopButtonContainer";
import {
    deleteProductionParameters,
    fetchProductionParameters,
    postProductionParameters,
    updateProductionParameters,
} from "../../../redux-tookit/slices/smartFab/productionParameterSlice";
import AGTable from "../../../components/AGTable";
import { fetchProductType } from "../../../redux-tookit/slices/productMasterSlice";
import { toast } from "react-toastify";
import { fetchGradesSF } from "../../../redux-tookit/slices/gradeMasterSlice";
import { fetchCustomerParties } from "../../../redux-tookit/slices/customerPartiesSlice";
import { fetchServiceCenter } from "../../../redux-tookit/slices/serviceCenterSlice";
import {
    downloadGenPdf,
    uploadGenPdf,
} from "../../../redux-tookit/slices/bbsPdfGenerationSlice";
import { Document, pdf } from "@react-pdf/renderer";
import ParamsPdfChooser from "./pdfPrints/ParamsPdfChooser";

const ProductionParams = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { orderId } = useParams();

    const navigate = useNavigate();

    const [newRow, setNewRow] = useState({});

    const [actionType, setActionType] = useState("add");

    const [popupOpen, setPopupOpen] = useState(false);

    const [combId, setCombId] = useState(location.state?.combId);

    const [preview, setPreview] = useState(false);

    const originalData = useSelector(
        (state) => state.productionParameter.labeledData
    );

    const columns = useSelector((state) => state.productionParameter.columns);

    const products = useSelector((state) => state.productMaster.labeledData);

    const orderData = useSelector((state) => state.order.labeledData).filter(
        (item) => `${item.orderId}` === orderId
    );

    const gradeData = useSelector((state) => state.gradeMaster.labeledData);

    const orderInfo = useSelector((state) => state.order.labeledData)[0];

    const serviceCenter1 =
        useSelector((state) => state.serviceCenter.labeledData).filter(
            (sc) => sc.imageUrl
        )[0] || "";

    const [partiesId, setPartiesId] = useState(null);

    const [parties, setParties] = useState();

    const partiesData = useSelector(
        (state) => state.customerParties.labeledData
    );

    // .filter(item => item.customerPartiesId * 1 === state.partiesId * 1)[0]

    const [selectedRow, setSelectedRow] = useState(null);

    console.log(gradeData);

    useEffect(() => {
        dispatch(fetchProductType());
        dispatch(fetchOrderSF(`?orderId=${orderId}`));
        dispatch(fetchProductionParameters(`?orderId=${orderId}`));
        dispatch(fetchGradesSF());
        dispatch(fetchCustomerParties());
        dispatch(fetchServiceCenter());
    }, []);

    const handleSave = async (e) => {
        e.preventDefault();

        let type = {
            ...newRow,
            orderId: orderId * 1,
        };

        type = {
            ...type,
            weldedWidth: newRow.width - newRow.cwOH1 - newRow.cwOH2,
            weldedLength: newRow.length - newRow.mwOH1 - newRow.mwOH2,
        };

        type = {
            ...type,
            noOfMw: type.weldedWidth / newRow.mwSpacing + 1,
            noOfCw: type.weldedLength / newRow.cwSpacing + 1,
        };



        if (!(Number.isInteger(type.noOfMw) && Number.isInteger(type.noOfCw))) {
            toast.error(
                `MW/CW number not correct. Please check the parameters`
            );
            return;
        }

        type = {
            ...type,
            mwTotalLength: newRow.length * type.noOfMw,
            cwTotalLength: newRow.width * type.noOfCw,
        };

        type = {
            ...type,
            sheetWeight: Math.round(
                ((newRow.mwDia * newRow.mwDia * type.mwTotalLength +
                    newRow.cwDia * newRow.cwDia * type.cwTotalLength) *
                    7850 *
                    Math.PI) /
                    4000000000
            ),
        };

        type = {
            ...type,
            totalWeight:
                Math.round(type.sheetWeight * newRow.totalSheets) / 1000,
        };

        let error = false

        Object.keys(type).forEach(key => {
            if(typeof type[key] === "number") {
                if(type[key]<0){
                    error = true
                }
            }
        })

        if(error === true){
            toast.error('The parameters contain negative values')
            return
        }

        if (actionType === "add") {
            dispatch(postProductionParameters(type)).then((res) => {
                if (res.payload) {
                    setNewRow({});
                    dispatch(fetchProductionParameters(`?orderId=${orderId}`));
                    setPopupOpen(false);
                }
            });
        } else {
            const id = originalData[selectedRow].smartfabProductionRecordingId;

            dispatch(updateProductionParameters({ id, type })).then((res) => {
                if (res.payload) {
                    setNewRow({});
                    setPopupOpen(false);
                }
            });
        }
    };

    const handlePreview = () => {
        let type = {
            weldedWidth: newRow.width - newRow.cwOH1 - newRow.cwOH2,
            weldedLength: newRow.length - newRow.mwOH1 - newRow.mwOH2,
        };

        type = {
            ...type,
            noOfMw: type.weldedWidth / newRow.mwSpacing + 1,
            noOfCw: type.weldedLength / newRow.cwSpacing + 1,
        };

        if (!(Number.isInteger(type.noOfMw) && Number.isInteger(type.noOfCw))) {
            toast.error(
                `MW/CW number not correct. Please check the parameters`
            );
        }

        type = {
            ...type,
            mwTotalLength: newRow.length * type.noOfMw,
            cwTotalLength: newRow.width * type.noOfCw,
        };

        type = {
            ...type,
            sheetWeight:
                Math.round(
                    ((newRow.mwDia * newRow.mwDia * type.mwTotalLength +
                        newRow.cwDia * newRow.cwDia * type.cwTotalLength) *
                        7850 *
                        Math.PI) /
                        40000000
                ) / 100,
        };

        type = {
            ...type,
            totalWeight:
                Math.round(type.sheetWeight * newRow.totalSheets) / 1000,
        };

        setNewRow({ ...newRow, ...type });
    };

    useEffect(() => {
        const tempPartiesId = orderData.map((el, index) => {
            if (index === 0) {
                return el.customerPartiesId;
            }
        })[0];

        console.log(tempPartiesId);

        setPartiesId(tempPartiesId);

        setParties(
            partiesData.filter(
                (item) => item.customerPartiesId * 1 === tempPartiesId * 1
            )[0]
        );
    }, [orderData]);

    const handleAddButtonClick = () => {
        setActionType("add");
        setPreview(false);
        setNewRow({});
        setPopupOpen(true);
    };

    const handleEditButtonClick = () => {
        setActionType("edit");
        setPreview(false);
        setNewRow(originalData[selectedRow]);
        setPopupOpen(true);
    };

    const handleDeleteButtonClick = () => {
        const id = originalData[selectedRow]?.smartfabProductionRecordingId;
        dispatch(deleteProductionParameters(id));
    };

    const setNewData = (e) => {
        setPreview(false);
        setNewRow((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
    };

    const pdfPrint = (type) => {
        navigate(`/params pdf viewer/`, {
            state: { orderId, type, partiesId },
        });
    };

    const rmConverter = (data) => {
        if (data.includes("-")) return data.split("- ")[1];
        else return data;
    };

    console.log(columns);

    const bufferToBase64 = (buffer) => {
        try {
            // console.log(buffer);

            const byteArray = new Uint8Array(buffer);
            const decoder = new TextDecoder("utf-8");

            let result = "";
            for (let i = 0; i < byteArray.length; i += 1024) {
                const chunk = byteArray.subarray(i, i + 1024);
                result += decoder.decode(chunk);
            }
            //   console.log('this ran');
            // console.log(result);
            return result;
        } catch (error) {
            console.log(error);
        }
    };

    const serviceCenterLogo =
        bufferToBase64(serviceCenter1?.imageUrl?.data) || null;

    const [generatingPdf, setIsGeneratingPdf] = useState(false);

    const getCurrentDateTime = () => {
        const date = new Date();

        const formatData = (input) => (input < 10 ? `0${input}` : input);

        const day = formatData(date.getDate());
        const month = formatData(date.getMonth() + 1); // Months are zero-indexed
        const year = date.getFullYear();

        return `${day}-${month}-${year}`;
    };

    const uploadData = async (type) => {
        if (generatingPdf) {
            return;
        }

        if(originalData.length===0){
            toast.error('No parameters in the bbs.')
            return
        }

        setIsGeneratingPdf(true);

        

        const toastId = toast.loading("Generating Pdf...");

        console.log(parties);

        
        

        try {
            const blob = await pdf(
                <Document>
                    <ParamsPdfChooser
                        parameters={originalData}
                        orderInfo={orderInfo}
                        type={type}
                        serviceCenterLogo={serviceCenterLogo}
                        parties={parties}
                    />
                </Document>
            ).toBlob();

            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = async () => {
                const base64data = reader.result; // This is the Base64 string

                // Prepare data for upload
                const pdfData = {
                    filePath: base64data,
                    fileName: `${
                        orderInfo.combId
                    }-${type ==="tag" ? "Tag" : "BbsTabular"}-${getCurrentDateTime()}`,
                    fileType: `${
                        type === "tag" ? "smartFabTags" : "smartFabBBS"
                    }`,
                    orderId: orderInfo.orderId,
                    orderType: 'smartFab'
                };

                console.log(pdfData);

                try {
                    dispatch(uploadGenPdf(pdfData)).then((res) => {
                        if (res.payload) {
                            toast.update(toastId, {
                                render: "Generated successfully",
                                type: "success",
                                isLoading: false,
                                autoClose: 3000, // Close the toast after 3 seconds
                            });
                            setIsGeneratingPdf(false);
                        } else {
                            toast.update(toastId, {
                                render: "Failed to generate Pdf",
                                type: "error",
                                isLoading: false,
                                autoClose: 3000, // Close the toast after 3 seconds
                            });
                            setIsGeneratingPdf(false);
                        }
                    });
                } catch (error) {
                    console.error("Error uploading file:", error);
                    setIsGeneratingPdf(false);
                }
            };
        } catch (error) {
            toast.update(toastId, {
                render: "Failed to generate Pdf",
                type: "error",
                isLoading: false,
                autoClose: 3000, // Close the toast after 3 seconds
            });
        } 
    };

    const downloadBase64File = (base64Data, filename) => {
        const link = document.createElement("a");

        link.href = base64Data;

        link.download = filename;

        document.body.appendChild(link);

        link.click();

        document.body.removeChild(link);
    };

    const handleDownloadPDF = (fileType) => {
        dispatch(
            downloadGenPdf(`?orderId=${orderId}&fileType=${fileType}&orderType=smartFab`)
        ).then((res) => {
            if (res.payload) {
                // console.log(res.payload.json);
                if (res.payload.json[0]) {
                    downloadBase64File(
                        res.payload.json[0].filePath,
                        res.payload.json[0].fileName
                    );
                } else {
                    toast.error('No files found')
                }
            }
        });
    };

    return (
        <div className="bbs">
            <div className="head">
                {" "}
                Production Parameters for Order Id : {combId}{" "}
            </div>
            <div className="section1">
                <div className="shadow-box-1">
                    <div className="row-1">
                        <div className="box-item-1">
                            <label htmlFor="customerName">Customer Name</label>
                            {orderData.map((order, index) =>
                                index === 0 ? (
                                    <input
                                        type="text"
                                        readOnly
                                        value={order?.soldToParty}
                                        style={{ width: "fit-content" }}
                                    />
                                ) : (
                                    ""
                                )
                            )}
                        </div>

                        <div className="box-item-1">
                            <label htmlFor="OrderDate">Order Date</label>
                            {orderData.map((order, index) =>
                                index === 0 ? (
                                    <input
                                        id="Order Date"
                                        type="date"
                                        readOnly
                                        value={dateChanger(order?.createdTs)}
                                        style={{ width: "fit-content" }}
                                    />
                                ) : (
                                    ""
                                )
                            )}
                        </div>

                        <div className="box-item-1">
                            <label htmlFor="DeliveryDate">Delivery Date</label>
                            {orderData.map((order, index) =>
                                index === 0 ? (
                                    <input
                                        id="DeliveryDate"
                                        type="date"
                                        readOnly
                                        value={order?.deliveryDate}
                                    />
                                ) : (
                                    ""
                                )
                            )}
                        </div>
                    </div>

                    <div
                        className="row-2"
                        style={{ justifyContent: "space-between" }}
                    >
                        <div className="dlvAdd">
                            <div className="box-item-1">
                                <label htmlFor="customerName">
                                    Delivery Address
                                </label>
                                {orderData.map((order, index) =>
                                    index === 0 ? (
                                        <textarea
                                            id="DeliveryAddress"
                                            type="text"
                                            readOnly
                                            value={order?.address}
                                            style={{
                                                width: "95%",
                                                paddingBottom: "0",
                                                height: "70px",
                                            }}
                                        />
                                    ) : (
                                        ""
                                    )
                                )}
                            </div>
                        </div>

                        <div className="box-item-1">
                            <label htmlFor="totalTags">No of Parameters</label>
                            {orderData.map((order, index) =>
                                index === 0 ? (
                                    <input
                                        id="totalTags"
                                        type="text"
                                        readOnly
                                        value={originalData?.length || 0}
                                        style={{ width: "5rem" }}
                                    />
                                ) : (
                                    ""
                                )
                            )}
                        </div>

                        <div className="box-item-1">
                            <label htmlFor="weight"> Weight (T) </label>

                            {orderData.map((order, index) =>
                                index === 0 ? (
                                    <input
                                        id="weight"
                                        type="text"
                                        readOnly
                                        value={order?.productionWeight}
                                        style={{ width: "7rem" }}
                                    />
                                ) : (
                                    ""
                                )
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div className="head">
                Parameters
                <div
                    className="top-button-container"
                    style={{ alignItems: "center" }}
                >
                    <TopButtonContainer
                        add={() => handleAddButtonClick()}
                        edit={() => handleEditButtonClick()}
                        del={() => handleDeleteButtonClick()}
                        access={{
                            writePermission: true,
                            updatePermission: true,
                            deletePermission: true,
                        }}
                    />
                </div>
            </div>
            <AGTable
                data={originalData}
                columns={columns}
                onSelectRow={setSelectedRow}
            />
            <div className="bottombbs">
                <Button label="Close" className="blue" link="order details" />
                <div className="bottom-button-container">
                    <Button
                        label="Tag Pdf Generate"
                        className="blue"
                        onClick={() => uploadData("tag")}
                    />
                    <Button
                        label="Download Tag Pdf"
                        className="blue"
                        onClick={() => handleDownloadPDF("smartFabTags")}
                    />
                    <Button
                        label="Parameter Tabular Pdf Generate"
                        className="blue"
                        onClick={() => uploadData("bbs tabular")}
                    />
                    <Button
                        label="Download Parameter Tabular Pdf"
                        className="blue"
                        onClick={() => handleDownloadPDF("smartFabBBS")}
                    />
                </div>
            </div>

            {popupOpen ? (
                <>
                    <div className="SmFabPopup">
                        <div className="content-area">
                            <div className="sdbr head">Add Parameters</div>
                            <form onSubmit={(e) => handleSave(e)}>
                                <div className="formContainer">
                                    {Object.keys(tempColumn).map((columnName) =>
                                        tempColumn[columnName]?.form ? (
                                            <div className="formField">
                                                <label htmlFor={columnName}>
                                                    {
                                                        tempColumn[columnName]
                                                            .label
                                                    }
                                                </label>
                                                {tempColumn[columnName].type ===
                                                "dropdown" ? (
                                                    columnName ===
                                                    "smartFabProductType" ? (
                                                        <select
                                                            name={
                                                                "smartFabProductTypeId"
                                                            }
                                                            value={
                                                                newRow.smartFabProductTypeId
                                                            }
                                                            onChange={(e) =>
                                                                setNewData(e)
                                                            }
                                                            id={
                                                                "smartFabProductTypeId"
                                                            }
                                                            required
                                                        >
                                                            <option
                                                                value=""
                                                                hidden
                                                            >
                                                                -select-
                                                            </option>
                                                            {products.map(
                                                                (row) => (
                                                                    <option
                                                                        value={
                                                                            row.smartFabProductTypeId
                                                                        }
                                                                    >
                                                                        {
                                                                            row.smartFabProductType
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    ) : (
                                                        <select
                                                            name={columnName}
                                                            value={
                                                                newRow[
                                                                    columnName
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                setNewData(e)
                                                            }
                                                            id={columnName}
                                                            required
                                                        >
                                                            <option
                                                                value=""
                                                                hidden
                                                            >
                                                                -select-
                                                            </option>
                                                            {tempColumn[
                                                                columnName
                                                            ].options.map(
                                                                (
                                                                    optionName
                                                                ) => (
                                                                    <option
                                                                        value={
                                                                            optionName
                                                                        }
                                                                    >
                                                                        {
                                                                            optionName
                                                                        }
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                    )
                                                ) : columnName ===
                                                  "gradeName" ? (
                                                    <select
                                                        name={"gradeId"}
                                                        value={newRow.gradeId}
                                                        onChange={(e) =>
                                                            setNewData(e)
                                                        }
                                                        id={"gradeId"}
                                                        required
                                                    >
                                                        <option value="" hidden>
                                                            -select-
                                                        </option>
                                                        {gradeData
                                                            .filter(
                                                                (item) =>
                                                                    item.smartFabProductTypeId *
                                                                        1 ===
                                                                    newRow.smartFabProductTypeId *
                                                                        1
                                                            )
                                                            .map((rowData) => (
                                                                <option
                                                                    value={
                                                                        rowData.gradeId
                                                                    }
                                                                >
                                                                    {
                                                                        rowData.material
                                                                    }
                                                                </option>
                                                            ))}
                                                    </select>
                                                ) : columnName.includes(
                                                      "Dia"
                                                  ) ? (
                                                    newRow.smartFabProductTypeId ===
                                                    "1" ? (
                                                        <input
                                                            name={columnName}
                                                            min={2}
                                                            max={10}
                                                            step={0.01}
                                                            value={
                                                                newRow[
                                                                    columnName
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                setNewData(e)
                                                            }
                                                            id={columnName}
                                                            type="number"
                                                            required
                                                        />
                                                    ) : newRow.smartFabProductTypeId ===
                                                          "2" ||
                                                      newRow.smartFabProductTypeId ===
                                                          "3" ? (
                                                        <select
                                                            name={columnName}
                                                            value={
                                                                newRow[
                                                                    columnName
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                setNewData(e)
                                                            }
                                                            id={columnName}
                                                            required
                                                        >
                                                            <option value="">
                                                                -select-
                                                            </option>
                                                            <option value="6">
                                                                6
                                                            </option>
                                                            <option value="7">
                                                                7
                                                            </option>
                                                            <option value="8">
                                                                8
                                                            </option>
                                                            <option value="9">
                                                                9
                                                            </option>
                                                            <option value="10">
                                                                10
                                                            </option>
                                                            <option value="11">
                                                                11
                                                            </option>
                                                            <option value="12">
                                                                12
                                                            </option>
                                                        </select>
                                                    ) : (
                                                        <select
                                                            name={columnName}
                                                            value={
                                                                newRow[
                                                                    columnName
                                                                ]
                                                            }
                                                            onChange={(e) =>
                                                                setNewData(e)
                                                            }
                                                            id={columnName}
                                                            required
                                                        >
                                                            <option value="">
                                                                -select-
                                                            </option>
                                                            <option value="6">
                                                                6
                                                            </option>
                                                            <option value="8">
                                                                8
                                                            </option>
                                                            <option value="10">
                                                                10
                                                            </option>
                                                            <option value="12">
                                                                12
                                                            </option>
                                                            <option value="16">
                                                                16
                                                            </option>
                                                        </select>
                                                    )
                                                ) : (
                                                    <input
                                                        name={columnName}
                                                        value={
                                                            newRow[columnName]
                                                        }
                                                        onChange={(e) =>
                                                            setNewData(e)
                                                        }
                                                        id={columnName}
                                                        type="number"
                                                        required
                                                    />
                                                )}
                                            </div>
                                        ) : (
                                            preview && (
                                                <div className="formField">
                                                    <label htmlFor={columnName}>
                                                        {
                                                            tempColumn[
                                                                columnName
                                                            ].label
                                                        }
                                                    </label>
                                                    <input
                                                        name={columnName}
                                                        value={
                                                            newRow[columnName]
                                                        }
                                                        type="number"
                                                        readOnly
                                                    />
                                                </div>
                                            )
                                        )
                                    )}
                                </div>
                                <div className="bottom-button">
                                    <Button
                                        label="Preview"
                                        className="blue"
                                        onClick={() => {
                                            if (!preview) handlePreview();
                                            setPreview(!preview);
                                        }}
                                    />
                                    <Button
                                        label="Save"
                                        className="blue"
                                        type="submit"
                                    />
                                    <Button
                                        label="Cancel"
                                        className="blue"
                                        onClick={() => setPopupOpen(!popupOpen)}
                                    />
                                </div>
                            </form>
                        </div>
                    </div>
                </>
            ) : (
                ""
            )}
        </div>
    );
};

export default ProductionParams;

// Move the code below to the slice

const tempColumn = {
    shape: {
        label: "Shape Type",
        options: ["SHEET", "ROLLS"],
        type: "dropdown",
        form: true,
    },
    smartFabProductType: {
        label: "RM",
        options: ["WR", "MA", "MAC"],
        type: "dropdown",
        form: true,
    },
    gradeName: {
        label: "Grade",
        form: true,
    },
    finish: {
        label: "Finish",
        options: ["Ribbed", "Plain"],
        type: "dropdown",
        form: true,
    },
    mwDia: {
        label: "MW Dia (in mm)",
        form: true,
    },
    cwDia: {
        label: "CW Dia (in mm)",
        form: true,
    },
    mwSpacing: {
        label: "MW Spacing (in mm)",
        form: true,
    },
    cwSpacing: {
        label: "CW Spacing (in mm)",
        form: true,
    },
    length: {
        label: "Length (in mm)",
        form: true,
    },
    width: {
        label: "Width (in mm)",
        form: true,
    },
    mwOH1: {
        label: "MW OH 1 (in mm)",
        form: true,
    },
    mwOH2: {
        label: "MW OH 2 (in mm)",
        form: true,
    },
    cwOH1: {
        label: "CW OH 1 (in mm)",
        form: true,
    },
    cwOH2: {
        label: "CW OH 2 (in mm)",
        form: true,
    },
    totalSheets: {
        label: "Total Sheets/Rolls in nos",
        form: true,
    },
    noOfMw: {
        label: "No. of MW",
        form: false,
    },
    noOfCw: {
        label: "No. of CW",
        form: false,
    },
    totalWeight: {
        label: "Total Weight in MT",
        form: false,
    },
    sheetWeight: {
        label: "Sheet weight in Kg",
        form: false,
    },
    weldedLength: {
        label: "Welded Length",
        form: false,
    },
    weldedWidth: {
        label: "Welded width",
        form: false,
    },
    mwTotalLength: {
        label: "MW total length",
        form: false,
    },
    cwTotalLength: {
        label: "CW total length",
        form: false,
    },
};
