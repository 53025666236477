import React, { useEffect, useCallback, useMemo, useRef, useState, useLayoutEffect } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import '../../assets/styles/AGTable.css'

const AGTable = (props) => {

  const { columns = {}, data = [], rowSelection = 'single', onSelectRow, selectedRow, dispatch, flex = 0, bbs = false, deselector, height = data.length===0?150: 300 } = props
  let rowHeight = 25;
  { bbs === true ? rowHeight = 120 : rowHeight = 40 };
  const gridRef = useRef();
  const gridApiRef = useRef(null)
  const [columnDef, setcolumnDef] = useState(null);
  // const [rowData, setRowData] = useState(data);
  const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const columnKeys = Object.keys(columns);
  const [chData, uChData] = useState(null);

  const [autoSizing, setAutoSizing] = useState(true);


  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  };


  const deSelectAll = () => {
    try{
      const selectedNodes = gridApiRef.current.getSelectedNodes();
    selectedNodes.forEach(node => node.setSelected(false))
    } catch(error){
      
    }
  }



  useLayoutEffect(() => {

    if (deselector > 0) { deSelectAll() }

  }, [deselector]);


  const defaultColDef = useMemo(() => {

    return {
      initialWidth: 150,
      sortable: true,
      enableCellChangeFlash: true,
      resizable: true,
      filter: true,
      autoHeight: true,
      autoHeaderHeight: true,
    }

  }, []);

  const dateTimeConverter = (dateTime) => {
    const [date, time] = dateTime.split('T')
    const [year, month, day] = date.split('-')
    const [nonTime, amPm, noData] = time.split(' ')
    const [hour, min, sec] = nonTime.split(':')

    const output = day + '-' + month + '-' + year + ' ' + hour + ':' + min + ' ' + amPm
    console.log(output)
    return output
  }


  const bufferToBase64 = (buffer) => {
    try {
      // console.log(buffer);

      const byteArray = new Uint8Array(buffer);
      const decoder = new TextDecoder('utf-8');

      let result = '';
      for (let i = 0; i < byteArray.length; i += 1024) {
        const chunk = byteArray.subarray(i, i + 1024);
        result += decoder.decode(chunk);
      }
      console.log('this ran');
      // console.log(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }


  const changeValues = () => {

    let newColumns = [{ colId: '0', field: "slno", headerName: "Sl.No", headerCheckboxSelection: rowSelection === 'multiple' ? true : false, checkboxSelection: true, resizable: false, filter: false, lockPosition: 'left', cellClass: 'locked-col' }];
    let newData = []
    columnKeys.map((data, index) => {


      let obj = { colId: '', field: "", headerName: "", checkboxSelection: false };
      if (data === 'image') {
        obj = {
          ...obj, cellRenderer: params => {
            // console.log(params);
            return <img src={String.fromCharCode.apply(null, new Uint8Array(params.data.image.data))} alt="shape" />
          }
        }
      } else
      if (data === 'imageUrl') {
        obj = {

          ...obj, cellRenderer: params => {
            // console.log(params);
            return <img src={bufferToBase64(params?.data?.imageUrl?.data) || ''} style={{ height: '70px', border: '1px solid black' }} alt="No Logo" />
          }
        }
      } else
        if (data === 'grossWeightTs') {
          obj = {
            ...obj, cellRenderer: params => {
              // console.log(params);

              return dateTimeConverter(params.data.grossWeightTs)
            }
          }
        } else
          if (data === 'unloadedTs') {
            obj = {
              ...obj, cellRenderer: params => {
                // console.log(params);
                if (params.data.unloadedTs)
                  return dateTimeConverter(params.data.unloadedTs)
                else return params.data.unloadedTs
              }
            }
          } else
            if (data === 'dateOfEntry' || data === 'deliveryDate' || data === 'orderDate' || data === 'planDate') {
              obj = {
                ...obj, cellRenderer: params => {
                  // console.log(params);
                  if (params.data[data]) {
                    let [dat, tim] = params.data[data].split('T');
                    let [year, month, day] = dat.split('-')

                    return `${day}-${month}-${year}`;
                  }

                  else return params.data[data]
                }
              }
            } else
              if (data === 'planStatus') {
                obj = {
                  ...obj, cellRenderer: params => {
                    // console.log(params);
                    if (params.data[data] * 1 === 1) {
                      return <div style={{ width: '100%', height: '40px', backgroundColor: 'red' }}></div>;
                    } else if (params.data[data] * 1 === 0) {
                      return <div style={{ width: '100%', height: '40px', backgroundColor: 'green' }}></div>;
                    }

                    else  return params.data[data]
                  }
                }
              } else

                // if (index === 0) {
                //   obj={...obj}
                // }
                // <img src={String.fromCharCode.apply(null, new Uint8Array(params.data.image.data))} alt="shape" />

                obj.colId = index + 1;
      obj.field = data;
      obj.headerName = columns[data].label;
      newColumns.push(obj);
    }
    );

    data.map((data, index) => {

      let obj = { slno: index + 1 };

      newData[index] = { ...obj, ...data };
    })
    uChData(newData);
    setcolumnDef(newColumns);

  }

  const autoSizeAll = useCallback(() => {
    try {
      if (gridRef.current.columnApi) {
        const allColumnIds = [];
        gridRef.current.api.getColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridRef.current.api.autoSizeColumns(allColumnIds, false);
      }
    } catch (error) {
      console.log(error);
    }
    setAutoSizing(false)
  }, []);


  const onSelectionChanged = () => {

    const selectedNodes = gridApi.getSelectedNodes();

    // const selectedRowIndex = event.node.rowIndex;
    // console.log("@@@", selectedRowIndex);

    const selectedRowIndexes = selectedNodes.map((node) => node.data.slno - 1);//FOR MULTIPLE ROW ALSO
    // console.log("***", selectedRowIndexes[0]);
    if (rowSelection === 'single')
      onSelectRow(selectedRowIndexes[0]);
    else onSelectRow(selectedRowIndexes);
  }

  const rowStyle = { background: 'white' };


  const getRowStyle = params => {
    //console.log("@@@", params.node);
    if (params.node.data.tierWeight === null || params.node.data.tierWeight === 0) {
      return { color: 'red' };
    }

  };

  useLayoutEffect(() => {

    // uChData(data);

    // setcolumnDef(columns);

    changeValues();

  }, [columns, dispatch, data]);



  useLayoutEffect(() => {

    if (chData) {
      const resizeTimeout = setTimeout(() => {
        autoSizeAll();
      }, 100);

      return () => clearTimeout(resizeTimeout);
    }

  }, [columnDef, chData, autoSizeAll]);


  return (
    <div className="table-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative' }}>
       {
        autoSizing &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', backgroundColor: '#fff', zIndex: '100', height: '100%', width: '100%' }}>
          <div className="circle-loader2" style={{ position: 'relative' }}></div>
        </div>
      }
      <div className="ag-theme-alpine" style={{ height: height, width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          onGridReady={onGridReady}
          rowMultiSelectWithClick={true}
          rowData={chData}
          columnDefs={columnDef}
          animateRows={true}
          rowSelection={rowSelection}
          defaultColDef={defaultColDef}
          rowHeight={rowHeight}
          onSelectionChanged={onSelectionChanged}
        />
      </div>
    </div>
  )
}

export default AGTable
