import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { resetState } from '../loginSlice';

const initialState = {
	columns: {},
	labeledData: [],
	loading: 'idle',
	fetcher: true,
	error: null,
};


export const fetchProductionParameters = createAsyncThunk('data/fetchProductionParameters', async (params = '') => {
	try {

		const response = await api.listProductionParameters(params);

		// console.log(toSort);

		const json = response.data;

		const columnLabels = {
			shape: { label: "Shape Type", options: ['SHEET', 'ROLLS'], type: 'dropdown', form: true },
			smartFabProductType: { label: "RM", options: ['WR', 'MA', 'MAC'], type: 'dropdown', form: true },
			gradeName: { label: "Grade", form: true },
			finish: { label: "Finish", options: ['Ribbed', 'Plain'], type: 'dropdown', form: true },
			mwDia: { label: "MW Dia (in mm)", form: true },
			cwDia: { label: "CW Dia (in mm)", form: true },
			mwSpacing: { label: "MW Spacing (in mm)", form: true },
			cwSpacing: { label: "CW Spacing (in mm)", form: true },
			length: { label: "Length (in mm)", form: true },
			width: { label: "Width (in mm)", form: true },
			mwOH1: { label: "MW OH 1 (in mm)", form: true },
			mwOH2: { label: "MW OH 2 (in mm)", form: true },
			cwOH1: { label: "CW OH 1 (in mm)", form: true },
			cwOH2: { label: "CW OH 2 (in mm)", form: true },
			totalSheets: { label: "Total Sheets/Rolls in nos", form: true },
			noOfMw: { label: "No. of MW", form: false },
			noOfCw: { label: "No. of CW", form: false },
			totalWeight: { label: "Total Weight in MT", form: false },
			sheetWeight: { label: "Sheet weight in Kg", form: false },
			weldedLength: { label: "Welded Length", form: false },
			weldedWidth: { label: "Welded width", form: false },
			mwTotalLength: { label: "MW total length", form: false },
			cwTotalLength: { label: "CW total length", form: false },
			mm2perMeterForMwAlongWidth: { label: "mm2/m for MW along width" },
			mm2perMeterForMwAlongLength: { label: "mm2/m for MW along length" },
			smartfabProductionRecordingId: { label: "smart fab production recording id" },
			smartFabProductTypeId: { label: "productTypeId" },
		};

		// 	"tagDescription": "tagDescriprion",
		// "imageProperties": "req.body.imageProperties",

		const selectedColumns = [
			'shape',
			'smartFabProductType',
			// 'gradeName',
			'finish',
			'mwDia',
			'cwDia',
			'mwSpacing',
			'cwSpacing',
			'length',
			'width',
			'mwOH1',
			'mwOH2',
			'cwOH1',
			'cwOH2',
			'totalSheets',
			'noOfMw',
			'noOfCw',
			'totalWeight',
			'sheetWeight',
			'weldedLength',
			'weldedWidth',
			'mwTotalLength',
			'cwTotalLength',
			"orderId",
			"combId",
			// 'mm2perMeterForMwAlongWidth',
			// 'mm2perMeterForMwAlongLength',
			'smartfabProductionRecordingId',
			'smartFabProductTypeId',
			"productionStatus",
			// "shape",
			"smartFabProcessTypeId",
			// "SmartFabProcessType",
		];

		const showColumns = [
			'shape',
			'smartFabProductType',
			// 'gradeName',
			'finish',
			'mwDia',
			'cwDia',
			'mwSpacing',
			'cwSpacing',
			'length',
			'width',
			'mwOH1',
			'mwOH2',
			'cwOH1',
			'cwOH2',
			'totalSheets',
			'noOfMw',
			'noOfCw',
			'totalWeight',
			'sheetWeight',
			'weldedLength',
			'weldedWidth',
			'mwTotalLength',
			'cwTotalLength',
			// 'mm2perMeterForMwAlongWidth',
			// 'mm2perMeterForMwAlongLength',
			// 'smartfabProductionRecordingId',
			// 'smartFabProductTypeId'
			// "productionStatus",																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																																									
			// "shape",
			// "smartFabProcessTypeId",
			// "SmartFabProcessType",
			// 'smartFabProductTypeId',
			// "orderId",
			// "combId",
			// "deliveryDate",
			// "status"
		]

		const temp = {
			"smartfabProductionRecordingId": 1,
			"productionStatus": "0",
			"shape": "Sheets",
			// "smartFabProcessTypeId": 1,
			// "SmartFabProcessType": "DRAWING",
			"finish": "Not yet",
			"mwDia": 6,
			"cwDia": 6,
			"mwSpacing": 10,
			"cwSpacing": 10,
			"length": 2000,
			"width": 100,
			"mwOH1": 100,
			"mwOH2": 100,
			"cwOH1": 100,
			"cwOH2": 100,
			"noOfMw": 10,
			"noOfCw": 10,
			"totalSheets": 100,
			"totalWeight": 100,
			"sheetWeight": 30,
			"weldedLength": 100,
			"weldedWidth": 200,
			"mwTotalLength": 1000,
			"cwTotalLength": 2000,
			"mm2perMeterForMwAlongWidth": 100,
			"mm2perMeterForMwAlongLength": 100,
			"orderId": 1,
			"combId": "LT-20240904-1111",
			"deliveryDate": "2024-06-28",
			"status": 0,
			"createdBy": "000000",
			"createdTs": "2024-09-04T23:28:14.000Z",
			"updatedBy": "000000",
			"updatedTs": "2024-09-04T23:47:21.000Z"
		}

		const columns = {};

		showColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		})

		return { columns, labeledData };
	} catch (error) {
		throw error

	}
});


export const postProductionParameters = createAsyncThunk('data/postProductionParameters', async (newRow) => {
	try {
		const response = await api.addProductionParameters(newRow)
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
		throw error;
	}
});

export const deleteProductionParameters = createAsyncThunk('data/deleteProductionParameters', async (id) => {
	try {
		await api.deleteProductionParameters(id);
		return id;
	} catch (error) {
		throw error;
	}
});

export const updateProductionParameters = createAsyncThunk('data/updateProductionParameters', async ({ id, type }) => {
	try {
		console.log(type, id);
		const response = await api.updateProductionParameters(id, type);
		const data = response.data;
		console.log('Update Success');
		return { data, id, type };
	} catch (error) {
		throw error;
	}
});

export const postConfirmProductionSF = createAsyncThunk('data/postConfirmProductionSF', async (type) => {
	try {
		const response = await api.confirmProductionSF(type);
		const data = response.data;
		console.log('Update Success');
		return { data, type };
	} catch (error) {
		throw error;
	}
});

export const postSmartFabExcelBbs = createAsyncThunk('data/postSmartFabExcelBbs', async ({newRow}) => {
	try {
		console.log(newRow);
		const response = await api.addSmartFabExcelBbs({newRow})
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
		throw error;
	}
});

const productionParameterSlice = createSlice({
	name: 'productionParameter',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(resetState, () => initialState)
			.addCase(fetchProductionParameters.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				// console.log('done');
				state.columns = action.payload.columns;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchProductionParameters.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(fetchProductionParameters.pending, (state) => {
				state.loading = 'pending';
			})

			.addCase(postProductionParameters.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postProductionParameters.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				// state.labeledData.push(action.payload);
				console.log(action.payload)
				toast.success('Production parameter added successfully!');
			})
			.addCase(postProductionParameters.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to add Production parameter');
			})

			.addCase(updateProductionParameters.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(updateProductionParameters.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				const index = state.labeledData.find(item => item.partItemId === action.payload.id);
				if (index) {
					const updatedItem = { partItemId: action.payload.id, ...action.payload.type };
					console.log(updatedItem);
					state.labeledData = state.labeledData.map(item => {
						if (item.orderId === action.payload.id) {
							return updatedItem;
						}
						return item;
					})
				}
				toast.success('Production parameter updated sucessfully');
			})
			.addCase(updateProductionParameters.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to update Production parameter');
			})
			.addCase(deleteProductionParameters.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(deleteProductionParameters.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				const deletedId = action.payload;
				console.log(action.payload);
				state.labeledData = state.labeledData.filter((data) => `${data.smartfabProductionRecordingId}` !== `${deletedId}`);
				toast.success('Production parameter deleted sucessfully');
			})
			.addCase(deleteProductionParameters.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error(state.error);
			})
			.addCase(postConfirmProductionSF.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postConfirmProductionSF.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				toast.success('Confirmed successfully!');
			})
			.addCase(postConfirmProductionSF.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(postSmartFabExcelBbs.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postSmartFabExcelBbs.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				toast.success('BBS uploaded added successfully!');
				console.log(action.payload)
			})
			.addCase(postSmartFabExcelBbs.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
	},
});

export default productionParameterSlice.reducer;

//End of File