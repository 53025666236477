import React, { useState } from 'react';
import './multiSelect.css';

const MultiSelect = ({ options = [{ value: '', label: '' }], selectedOptions = [], setSelectedOptions }) => {

    const [isOpen, setIsOpen] = useState(false);

    const toggleOption = (option) => {
        setSelectedOptions((prev) => {
            if (prev.includes(option)) {
                return prev.filter(item => item !== option);
            } else {
                return [...prev, option];
            }
        });
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    return (<div className="MultiSelect-dropdown">
        <div className="selected-options" onClick={toggleDropdown}>
            {selectedOptions.slice(0, 2).map((option, index) => (
                <span key={index} className='selected-option'>{option}</span>
            ))}
            {selectedOptions.length > 2 && (
                <span className='selected-option'>+{selectedOptions.length - 2}</span>
            )}
        </div>
        <div className="options">
            {options.map(option =>
                <div
                    className={`option ${selectedOptions.includes(option) ? 'selected' : ''}`}
                    onClick={() => toggleOption(option)}>
                    <input type="checkbox" name="" id={`${option.label}`} checked={selectedOptions.includes(options.value)} />
                    <label htmlFor="">{option.label}</label>
                </div>)}
        </div>

    </div>)

}

export default MultiSelect
