import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchPricing } from '../../redux-tookit/slices/presalesSlice';
import AGTable from '../../components/AGTable1';
import CustomBundleTable from '../transaction/Dispatch/CustomBundleTable';
import AGShowTable from '../../components/AGShowTable';

const PricingDetails = () => {
  const dispatch = useDispatch();
  //const myData = location.state?.planId;

  const columns = useSelector((state) => state.presales.columns);
  const data = useSelector((state) => state.presales.labeledData);

  useEffect(()=>{
    dispatch(fetchPricing())
  },[])
  return (
    <div className='sdbr'>
      <div className="head">
        Presales history
      </div>
      <AGShowTable data= {data} columns={columns} width='100%'/>
      
    </div>
  )
}

export default PricingDetails
