import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GenericTable1 from '../../../components/GenericTable1';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { deleteProcessType, deleteProcessTypeSF, fetchProcessType, fetchProcessTypeSF } from '../../../redux-tookit/slices/processTypeSlice';
import AGTable from '../../../components/AGTable1';
import { toast } from 'react-toastify';
import AddProcessType from './AddProcessType';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
import { UserAccessContext } from '../../../App';


const ProcessType = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const productName = useContext(UserAccessContext).productName;

  console.log(productName);


  const permission = useState(location.state?.permission);
  const columns = useSelector((state) => state.processType.columns);
  const data = useSelector((state) => state.processType.labeledData);
  const [editData, setEditData] = useState([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deselector, setDeselector] = useState(0)



  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);

  };

  const handleAddButtonClick = () => {
    setIsAdding(true);
  };
  const handleEditButtonClick = () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      setIsEditing(true);
      setEditData(data[selectedRowIndex]);
    } else toast.error("No rows selected, Please select a row and try again"); setSelectedRowIndex(null);
  };

  const handleDeleteButtonClick = async () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {

      let id = '';

      console.log(id);
      setDeselector(deselector + 1)

      if (productName === 'smartFab') {
        id = data[selectedRowIndex].smartFabProcessTypeId;
        dispatch(deleteProcessTypeSF(id));
      } else {
        id = data[selectedRowIndex].processTypeId;
        dispatch(deleteProcessType(id))
      }

    } else toast.error("No rows selected, Please select a row and try again");
    setSelectedRowIndex(null);
  };
  
  useEffect(() => {
    if (productName === 'smartFab') {
      dispatch(fetchProcessTypeSF());
    } else {
      dispatch(fetchProcessType());
    }
  }, [dispatch, isAdding, isEditing, productName]);


  return (
    <div className='sdbr'>
      <div className='head small'>Process Type
        <div className="top-button-container">
          {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
          <img src={edit} alt="edit" onClick={handleEditButtonClick} />
          <img src={del} alt="delete" onClick={handleDeleteButtonClick} /> */}
          <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]} />

        </div>
      </div>

      <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} deselector={deselector} width='60%' />


      <AddProcessType isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} productName={productName} />

    </div>
  );
};

export default ProcessType;