import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { fetchPartners } from '../../../redux-tookit/slices/partnerSlice';
import { deleteServiceCenter, fetchServiceCenter } from '../../../redux-tookit/slices/serviceCenterSlice';
import AGTable from '../../../components/AGTable';
import AddServiceCenter from './AddServiceCenter';
import {  toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
 
const ServiceCenter = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const permission = useState(location.state?.permission);

  const columns = useSelector((state) => state.serviceCenter.columns);
  const data = useSelector((state) => state.serviceCenter.labeledData);
  const fetcher = useSelector((state)=>state.serviceCenter.fetcher);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [editData, setEditData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  
  };
 
  const handleAddButtonClick = () => {
    setEditData({})
    setIsAdding(true);
  };
 
  const handleEditButtonClick = () => {
    if (selectedRowIndex !== null&&selectedRowIndex!==undefined) {
      setIsEditing(true);
      setEditData(data[selectedRowIndex]);
    }
    else toast.error("No rows selected, Please select a row and try again");setSelectedRowIndex(null);
  };
 
  useEffect(() => {
    
    dispatch(fetchServiceCenter()).then(dispatch(fetchPartners()));
   
  }, [dispatch,isAdding,isEditing,fetcher]);
 
  const handleDeleteButtonClick=async()=>{
    if (selectedRowIndex !== null&&selectedRowIndex!==undefined) {
      const id=data[selectedRowIndex].serviceCenterId;
      console.log(id);
      dispatch(deleteServiceCenter(id));
    }
    else toast.error("No rows selected, Please select a row and try again");
 
    setSelectedRowIndex(null);
  };

  useEffect(()=>{

  },[data])
 
  return (
    <div className='sdbr'>
      <div className='head'>Service Centers
        <div className="top-button-container">
          {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
          <img src={edit} alt="edit" onClick={handleEditButtonClick}/>
          <img src={del} alt="delete" onClick={handleDeleteButtonClick}/> */}
          <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]}/>

        </div>
      </div>

      <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} bbs={true} selectedRow={selectedRowIndex} />
      {isAdding || isEditing?
      <AddServiceCenter isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} />:""
}
    </div>
  );
};
 
export default ServiceCenter;