import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import 'react-toastify/dist/ReactToastify.css'


const initialState = {
  columns: {},
  labeledData: [],
  loading: 'idle',
  error: null,
};


export const fetchInventory = createAsyncThunk('data/fetchInventory', async () => {
  try {
    const response = await api.graphlistinventry();
    const json = response.data;

    const columnLabels = {
      inventoryId: { label: 'inventoryId', type: 'number', editable: false },
      material: { label: 'material', type: 'text', isRequired: true, editable: true },
      gradeName: { label: 'Grade Name', type: 'text', isRequired: true, editable: true },
      diameter: { label: 'diameter', type: 'number', isRequired: true, editable: true },
      length: { label: 'length', type: 'number', isRequired: true, editable: true },
      weight: { label: 'weight', type: 'number', isRequired: true, editable: true },
      totalPieces: { label: 'totalPieces', type: 'number', isRequired: true, editable: true },

      // Add more as needed
    };

    

    const selectedColumns = ['inventoryId', 'material', 'diameter', 'length', 'weight', 'totalPieces', 'gradeName'];

    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });

      return rowData;
    });

    console.log(labeledData);

    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});


const graphinventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInventory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchInventory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })

  },
});

export default graphinventorySlice.reducer
//End of File