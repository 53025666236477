import React, { useEffect, useState } from 'react'
import './Header.css'
import HeaderLeft from './../assets/images/icons/header/header-left.svg'
import { ReactComponent as HeaderDrop } from './../assets/images/icons/header/header-dropdown.svg'
import { ReactComponent as UserImg } from './../assets/images/user/user1.svg'
import { useDispatch, useSelector } from 'react-redux'
import { logout, resetState } from '../redux-tookit/slices/loginSlice'
import { useNavigate } from 'react-router-dom'
import userImg from './../assets/images/user/user1.svg'

const Header = () => {
    const email = sessionStorage.getItem('email');
    const userName = sessionStorage.getItem('userName');
    const scName = sessionStorage.getItem('serviceCenterName');
    const roleName = sessionStorage.getItem('roleName');

    const image = useSelector(state => state.login.image)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [url, setUrl] = useState(null)
    const [isDropdownOpen, setIsDropDownOpen] = useState(false);
    const handleLogout = () => {
        dispatch(logout());
        dispatch(resetState())
        navigate('/');
    };

    // console.log(image);
    const toggleDropdown = (choice) => {
        if (choice)
            setIsDropDownOpen(!isDropdownOpen);
        else setIsDropDownOpen(choice)
    }

    const handleFileChange = (event) => {
        const file = event;
        let base64Image = ''
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                // setImage(reader.result);
                // console.log(reader.result);
                base64Image = reader.result;
            };
            reader.readAsDataURL(file);
        }
        return base64Image
    };

    const bufferToBase64 = buffer => {
        try {
            // console.log(buffer);

            const byteArray = new Uint8Array(buffer);
            const decoder = new TextDecoder('utf-8');

            let result = '';
            for (let i = 0; i < byteArray.length; i += 1024) {
                const chunk = byteArray.subarray(i, i + 1024);
                result += decoder.decode(chunk);
            }
            // console.log(result);
            sessionStorage.setItem('image', result)
            return result;
        } catch (error) {
            console.log(error);
        }

    };

    const handleUpdatePassword = () => {
        sessionStorage.setItem('navSelectedOption', 'Update Password');
        sessionStorage.setItem('navExpandedOption', 'Settings');
        navigate('update password')
    }

    useEffect(() => {
        if (image !== null) {
            // console.log('/////////////////////////////');
            // console.log(image);
            // handleFileChange(image.data)
            // console.log(String.fromCharCode.apply(null, new Uint8Array(image.data)));

            //   const base64String = bufferToBase64(image.data);
            //   console.log(base64String);



            if (image) {
                setUrl(bufferToBase64(image.data));
            }

        } else {
            const storedImage = sessionStorage.getItem('image')
            // console.log(storedImage);
            setUrl(storedImage);
        }
    }, [])
    // console.log(url);

    const handleBlur = () => {

        setTimeout(() => {
            toggleDropdown(false);
        }, 1000);
    }


    return (
        <div className='hed'>
            <div className="hed-logo">

                <img src={HeaderLeft} alt="Logo" />
            </div>
            <div className="user-info" onClick={toggleDropdown} onMouseLeave={handleBlur} >
                <div className="user-img">
                    {url !== null ? <img src={url} alt='user image' /> :
                        <UserImg />
                    }

                </div>
                <div className="dropdown-container">
                    <span className='username'>
                        <div>{userName ? userName : 'User'}</div>
                    </span>



                    {isDropdownOpen && (
                        <div className='user-drop-1' onClick={toggleDropdown}>

                            <div className="logout-space">
                                {/* <div className="role-name">
                                    {roleName}
                                </div> */}
                                {/* <div className='logout-btn' onClick={handleLogout}>
                                    Logout
                                </div> */}

                            </div>

                            <div className="user-data">
                                <div className='bigProfile' style={{ backgroundImage: `url(${url !== null ? url : userImg})` }}></div>

                                <div className="user-details">

                                    <div className='user-name'>
                                        {userName}
                                    </div>

                                    <div className='user-email'>
                                        {email}
                                    </div>

                                    <div className="user-role">
                                        Service Center: {scName}
                                    </div>

                                    <div className="user-role">
                                        Role: {roleName}
                                    </div>
                                </div>

                            </div>

                            <ul className='belowDropMenu' style={{ listStyleType: 'none', paddingLeft: '0' }}>
                                <li className='dropdown-btn' onClick={handleUpdatePassword}>
                                    Update Password
                                </li>
                                <li className='dropdown-btn' onClick={handleLogout}>
                                    Logout
                                </li>
                            </ul>

                        </div>
                    )}</div>



            </div>
        </div>
    )
}

export default Header