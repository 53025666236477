import React from 'react'

const PasswordInputField = ({handleValidation, handlePasswordChange, passwordValue, passwordError, label, className}) => {
    return (
        
    <div className={className}>
        <label htmlFor="password">{label} <span style={{color:'red'}}>*</span></label>
        <input type="password" id='password' value={passwordValue} required  onChange={handlePasswordChange} onKeyUp={handleValidation} name="password" placeholder={''} className="table-input" />
        <p className="text-danger">{passwordError}</p>
   </div>
          
        
    )
}

export default PasswordInputField
