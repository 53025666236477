import '../assets/styles/TableComponent.css';

const TableComponent2 = ({ columns, data }) => {

  const columnKeys=Object.keys(columns);
  return (
    <div className="table-container">
      <table className="generic-table">
        <thead>
          <tr>
            {columnKeys.map((key) => (
              <th key={key} className="table-header">
                {columns[key].label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columnKeys.map((data,index) => (
                <td key={index}>{row[data]}</td>
              ))}
            </tr>
          ))}
          
         
        </tbody>
      </table>
    </div>
  );
};

export default TableComponent2;