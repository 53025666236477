import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../../../utils/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { deleteOrderItems, fetchOrderItems } from '../../../redux-tookit/slices/orderItemsSlice';
import AGTable from '../../../components/AGTable';
import { toast } from 'react-toastify';
import AddElements from './AddElements';
import { fetchUserAccess } from '../../../redux-tookit/slices/userAccessSlice';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';

const Element = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const { orderId } = useParams();
	const [combId, setCombId] = useState(location.state?.combId)
	const columns = useSelector((state) => state.orderItems.columns);
	const originalData = (useSelector((state) => state.orderItems.labeledData))
	console.log(originalData);
	// let filteredData = originalData.filter((item) => `${item.orderId}` === orderId);
	const fetcher = useSelector(state => state.orderItems.fetcher)
	const [isAdding, setIsAdding] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [editData, setEditData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [deselector, setDeselector] = useState(0)
	// const filteredColumns = columnKeys.filter((column) => column !== 'orderId');
	const [selectedRowIndex, setSelectedRowIndex] = useState(null);
	const [elementName, setElementName] = useState('');


	const [permission, setPermission] = useState({});
	useEffect(() => {
		dispatch(fetchUserAccess())
			.then(res => {
				if (res.payload) {
					if (res.payload.navbarNames.Transaction["Order Details"][0]) {
						setPermission(res.payload.navbarNames.Transaction["Order Details"][0])
					}
				}
			})
	}, [])

	const handleRowSelect = (rowIndex) => {

		//     setElementName(filteredData[rowIndex].orderItemName);
		setSelectedRowIndex(rowIndex);
	};

	const handleAddButtonClick = () => {
		setIsAdding(true);
		setDeselector(deselector + 1);
	};

	const handleEditButtonClick = () => {
		if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
			setIsEditing(true);
			setEditData(filteredData[selectedRowIndex]);
		} else toast.error("No rows selected, Please select a row and try again");
		setSelectedRowIndex(null);
		setDeselector(deselector + 1);

	};

	const handleDeleteButtonClick = async () => {
		if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
			const id = filteredData[selectedRowIndex].orderItemId;
			dispatch(deleteOrderItems(id));
		}
		else toast.error("No rows selected, Please select a row and try again");
		setSelectedRowIndex(null);
	};

	const handleShowElement = () => {

		const id = filteredData[selectedRowIndex].orderItemId;
		const elementName = filteredData[selectedRowIndex].orderItemName;
		navigate(`/element show/${id}`, { state: { elementName } })
		setSelectedRowIndex(null);
	}


	useEffect(() => {

		dispatch(fetchOrderItems(orderId));
		console.log('this runs');

	}, [dispatch, fetcher]);


	useEffect(() => {

		setFilteredData(originalData)

	}, [originalData]);
	console.log(permission);
	return (
		<div className='sdbr'>
			<div className='head' >
				<div>Element details: Order ID- {combId}</div>
				<div className='top-button-container'>
					<TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission} />
				</div>
			</div>
			
			<div className="Table-Container" >
				<AGTable columns={columns} data={filteredData} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} deselector={deselector} />
			</div>
			<AddElements isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} orderId={orderId} />

			<div className="bottom-button-container" >
				{permission.writePermission && <Button label="Copy" className="blue" onClick={() => navigate(`/element/${orderId}/copy`, { state: { combId: combId, orderId: orderId } })} />}
				<Button label="Show" className="blue" onClick={() => handleShowElement()} disabled={selectedRowIndex} />
				<Button label="Back" className="blue" link='order details' />
				<Button label="BBS Details" className="blue" onClick={() => navigate(`/bbs detail/${orderId}`, { state: { combId: combId, orderId: orderId, orderItemName: filteredData[selectedRowIndex]?.orderItemName || '' } })} />
			</div>

		</div>
	);
}

export default Element
//End of File 
