import React, { useEffect, useCallback, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import '../../assets/styles/AGTable.css'

const AGResizeTables = (props) => {

  const { columns = {}, data = [], rowSelection = 'single', onSelectRow, selectedRow, dispatch, flex = 0, width = '100%' } = props
  const gridRef = useRef();
  const gridApiRef = useRef(null)
  const [columnDef, setcolumnDef] = useState(null);
  // const [rowData, setRowData] = useState(data);
  const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const columnKeys = Object.keys(columns);
  const [chData, uChData] = useState(null);
  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  };


  const deSelectAll = () => {
    try{
      const selectedNodes = gridApiRef.current.getSelectedNodes();
    selectedNodes.forEach(node => node.setSelected(false))
    } catch(error){
      
    }
  }

  useEffect(() => {


    if (selectedRow === 'A') { deSelectAll() }


  }, [selectedRow]);



  const defaultColDef = useMemo(() => {

    return {
      initialWidth: 150,
      sortable: true,
      enableCellChangeFlash: true,
      resizable: true,
      filter: true,
      autoHeaderHeight: true,
      autoHeight: true,
      maxWidth: 500
    }

  }, []);

  const changeValues = () => {

    let newColumns = [{ colId: '0', field: "slno", headerName: "Sl.No", headerCheckboxSelection: rowSelection === 'multiple' ? true : false, checkboxSelection: false, resizable: false, filter: false, lockPosition: 'left', cellClass: 'locked-col' }];
    let newData = []
    columnKeys.map((data, index) => {
      let obj = { colId: '', field: "", headerName: "", headerCheckboxSelection: rowSelection === 'multiple' ? true : false, checkboxSelection: false };

      // if (index === 0) {
      //   obj={...obj}
      // }

      if (!data.toLowerCase().includes('inventoryid')) {
        obj.colId = index + 1;
        obj.field = data;
        obj.headerName = columns[data].label;

        newColumns.push(obj)
      }

    });

    data.map((data, index) => {

      let obj = { slno: index + 1 };

      newData[index] = { ...obj, ...data };
    })
    uChData(newData);
    setcolumnDef(newColumns);

  }

  const autoSizeAll = useCallback(() => {

    try {
      if (gridRef.current.columnApi) {
      const allColumnIds = [];
      gridRef.current.columnApi.getColumns().forEach((column) => {
        allColumnIds.push(column.getId());
      });
      gridRef.current.columnApi.autoSizeColumns(allColumnIds, false);
    }
    } catch (error) {
      
    }
    
  }, []);

  const onSelectionChanged = () => {

    const selectedNodes = gridApi.getSelectedNodes();

    // const selectedRowIndex = event.node.rowIndex;
    // console.log("@@@", selectedRowIndex);

    const selectedRowIndexes = selectedNodes.map((node) => node.rowIndex);//FOR MULTIPLE ROW ALSO
    // console.log("***", selectedRowIndexes[0]);

    onSelectRow(selectedRowIndexes[0]);
  }

  const rowStyle = { background: 'white' };

  const getRowStyle = params => {
    //console.log("@@@", params.node);
    if (params.node.data.tierWeight === null || params.node.data.tierWeight === 0) {
      return { color: 'red' };
    }
  };

  useEffect(() => {
    uChData(data);
    setcolumnDef(columns);

    changeValues();


  }, [columns, dispatch, data]);



  useEffect(() => {

    setTimeout(() => {
      autoSizeAll();
    }, 2)

  }, [columnDef, chData]);


  return (
    <div className="table-container small" style={{ maxWidth: width, minWidth: width}}>

      <div className="ag-theme-alpine" style={{ height: 300, width: '100%' }}>
        <AgGridReact
          ref={gridRef}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          onGridReady={onGridReady}
          rowData={chData}
          columnDefs={columnDef}
          animateRows={true}
          rowSelection={rowSelection}
          defaultColDef={defaultColDef}
        // onSelectionChanged={onSelectionChanged}
        />
      </div>
    </div>
  )
}

export default AGResizeTables
