import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import TableComponent from '../../components/TableComponent';
import '../../assets/styles/Production.css';


const ProductionUploadingOpt = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.ProductionUploadingOpt_RMUsed.columns);
  const data = useSelector((state) => state.sdbr.ProductionUploadingOpt_RMUsed.data);

  const FGcolumns = useSelector((state) => state.sdbr.ProductionUploadingOpt_FG.columns);
  const FGdata = useSelector((state) => state.sdbr.ProductionUploadingOpt_FG.data);

  const Remcolumns = useSelector((state) => state.sdbr.ProductionUploadingOpt_Remnant.columns);
  const Remdata = useSelector((state) => state.sdbr.ProductionUploadingOpt_Remnant.data);

  const [selectPlan, setSelectPlan] = useState("Select PLan");



  useEffect(() => {

    dispatch(fetchDataFromApi('ProductionUploadingOpt_RMUsed'));
  }, [dispatch]);
  useEffect(() => {

    dispatch(fetchDataFromApi('ProductionUploadingOpt_FG'));
  }, [dispatch]);

  useEffect(() => {

    dispatch(fetchDataFromApi('ProductionUploadingOpt_Remnant'));
  }, [dispatch]);


  return (
    <div className='sdbr'>

      <div className="select">
        <label htmlFor="plan" style={{ paddingRight: "10px" }}>Plan Id</label>
        <div>
          <select
            name="plan"
            id="PlanIDfilter"
            onChange={(e) => setSelectPlan(e.target.value)}
            value={selectPlan}
            className='plId'
          >
            <option value="Select PLan">Select Plan</option>
            <option value="PlanID-20160513011">PlanID-20160513011</option>


          </select>
        </div>
      </div>
      <div className='head'>RM Used</div>
      <TableComponent columns={columns} data={data} />
      <div className='head'>FG</div>
      <TableComponent columns={FGcolumns} data={FGdata} />
      <div className='head'>Remnant</div>
      <TableComponent columns={Remcolumns} data={Remdata} />
    </div>
  )
}

export default ProductionUploadingOpt
