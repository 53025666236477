import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../assets/styles/TableAlign.css'
import { deleteProduct, deleteProductType, fetchProducts, fetchProductType } from '../../../redux-tookit/slices/productMasterSlice';
import AddProduct from './AddProduct';
import AGTable from '../../../components/AGTable1';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
import { UserAccessContext } from '../../../App';

const ProductMaster = () => {
    const dispatch = useDispatch();
    const location = useLocation()

    const productName = useContext(UserAccessContext).productName;

    const columns = useSelector((state) => state.productMaster.columns);
    const data = useSelector((state) => state.productMaster.labeledData);
    const fetcher = useSelector((state) => state.productMaster.fetcher)
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [editData, setEditData] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false)
    const permission = useState(location.state?.permission);

    const handleRowSelect = (rowIndex) => {
        setSelectedRowIndex(rowIndex);
    };

    const handleAddButtonClick = () => {
        setIsAdding(true);
    };

    const handleEditButtonClick = () => {
        if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
            setIsEditing(true);
            setEditData(data[selectedRowIndex]);
        } else toast.error("No rows selected, Please select a row and try again"); setSelectedRowIndex(null)
    };

    const handleDeleteButtonClick = async () => {
        if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
            if (productName === 'smartFab') {
                const id = data[selectedRowIndex].smartFabProductTypeId;
                dispatch(deleteProductType(id));
            } else {
                const id = data[selectedRowIndex].productMasterId;
                dispatch(deleteProduct(id));
            }

        }
        else toast.error("No rows selected, Please select a row and try again");
        setSelectedRowIndex(null);
    };
    useEffect(() => {
        if (productName === 'smartFab') {
            dispatch(fetchProductType());
        } else {
            dispatch(fetchProducts());
        }

    }, [dispatch, fetcher, isEditing, isAdding, productName]);

    return (
        <div className='sdbr'>
            <div className='head small'>Product Master
                <div className="top-button-container">
                    {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
                    <img src={edit} alt="edit" onClick={handleEditButtonClick} />
                    <img src={del} alt="delete" onClick={handleDeleteButtonClick} /> */}
                    <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]} />

                </div>
            </div>

            <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} width='60%' />
            <AddProduct isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} />

        </div>
    );
};

export default ProductMaster;