import React, { useEffect } from 'react'
import Loader from '../components/Loader/Loader'
import { useNavigate } from 'react-router-dom'

const BlankLoadingPage = () => {
    const navigate = useNavigate()
    useEffect(()=>{

        

      let timer1 = setTimeout(() => {
        navigate('Not found')
      }, 4000);

      return () => { clearTimeout(timer1) }

    },[])
  return (
    <div className='sdbr'>
      <Loader />
    </div>
  )
}

export default BlankLoadingPage
