import React,{useState} from 'react'
import '../../../App.css';
import CanvasDraw from './CanvasDraw';
import CanvasTable from './CanvasTable';
import Button from '../../../utils/Button';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const AddNewShape = (props) => {
  
  const {initialShape=[], selectedShape='',update=false, mag='100,147,1'} = props;
   
  const [shapes,uShapes] = useState(initialShape);
  const l=shapes.length;
  console.log(mag);

  const [orX,orY,magValue] = mag.split(',')

  const [orginX, setOrginX] = useState(orX*1);
  const [orginY, setOrginY] = useState(orY*1);
  const [mF, setMF] = useState(magValue*1);
  
  console.log(shapes);
  const addNew = (obj) => {    
    uShapes((prev)=>[...prev,obj]);
    console.log({l})
  }
  
  const handleTableDataChange = (newData) => {
    uShapes(newData);
  };

  const delshape = () => {
    console.log(shapes) 
    let updatedShapes=[...shapes];
    updatedShapes.splice(l-1,1);
    uShapes(updatedShapes);
  }



  const editShapes = (index, header, value) => {
    let h='Angle';
    uShapes((prev) => {
      const newShapes = [...prev];
      if(value==='Rad+ArcLength'){newShapes[index].Length2='180';}
      if(header==='Type'&&newShapes[index].Geometry==='Angle'){
        newShapes[index][header] = value;
        // newShapes[index][h]*=(-1);
        console.log(newShapes[index][h]);
      }
      // if(header==='Type'&&newShapes[index].Geometry==='Arc')
      //more conditions to be given
      else {newShapes[index][header] = value;}
      return newShapes;
    });
  }

  const clearShapes = () => {
    uShapes([]);
  }

  // useEffect(() => {
  //   uShapes(initialShape);
  // },[])


  
  return(
    <div className='addShapeContainer'>
      <div className="canvas-row">
      <CanvasDraw drawingElements={shapes} addNew={addNew} delshape={delshape} clearShapes={clearShapes} selectedShape={selectedShape} update={update} orginX={orginX} setOrginX={setOrginX} orginY={orginY} setOrginY={setOrginY} mF={mF} setMF={setMF}/>

     </div>
      <CanvasTable data={shapes} onInputChange={editShapes}  onDataChange={handleTableDataChange}/>
    </div>
  )
}

export default AddNewShape;