import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import "./updatePassword.css";
import Password from "../UserCreation/Password";
import Button from "../../../utils/Button";
import { updatePassword } from "../../../redux-tookit/slices/passwordSlice";
import { useNavigate } from "react-router-dom";

const UpdatePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [valid, setValid] = useState({
    password: false,
    confirmPassword: false,
  });
  const [addNew, setAddNew] = useState({});

  const passwordStatus = sessionStorage.getItem("passwordStatus");

  const regions = ["North", "South", "East", "West"];
  const changePassword = async (e) => {
    e.preventDefault();
    let errorCount = 0;

    if (valid.password === false) {
      errorCount += 1;
      toast.error("Password invalid");
    }
    if (valid.confirmPassword === false) {
      errorCount += 1;
      toast.error("Password does not match");
    }
    if (addNew.oldPassword === addNew.password) {
      errorCount += 1;
      toast.error("New Password cannot be as Old Password");
    }

    const email = sessionStorage.getItem("email");

    const newRow = {
      oldPassword: addNew.oldPassword,
      newPassword: addNew.password,
      userEmail: email,
      region:addNew.region
    };

    if (errorCount * 1 === 0) {
      dispatch(updatePassword(newRow)).then((res) => {
        if (res.payload) {
          toast.success("Password updated successfully");
          sessionStorage.setItem("navSelectedOption", "Order Details");
          sessionStorage.setItem("navExpandedOption", "Transaction");
          if (passwordStatus === "1") {
            sessionStorage.setItem("passwordStatus", "0");
          }
          navigate("/order details");
        }
      });
    }
  };
  console.log(passwordStatus);

  useEffect(() => {
    if (passwordStatus === "1") {
      toast.warning("Please Update Password After First Login ", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, []);

  const handleDisableClick = () => {
    toast.error("Cannot Proceed Without Updating Password");
  };

  return (
    <div className="sdbr">
      <form
        onSubmit={changePassword}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div className="head">Update Password</div>
        <div className="update-password-main-container">
          <div className="update-field-container">
            <label htmlFor="oldPassword">
              Password <span style={{ color: "red" }}>*</span>
            </label>
            <input
              type="password"
              id="oldPassword"
              required
              placeholder="Password"
              onChange={(e) =>
                setAddNew({ ...addNew, oldPassword: e.target.value })
              }
            />
            <p className="text-danger"></p>
          </div>

          <Password
            valid={valid}
            setValid={setValid}
            setAddNew={setAddNew}
            label="New Password"
            className="update-field-container"
          />
          {/* <label
            htmlFor="Region"
            style={{
              marginTop: "10px",
              padding: " 1px 0",
              marginRight: "20px",
            }}
          >
            Region <span style={{ color: "red" }}>*</span>
          </label>
          <select
            name="region"
            required
            id="region"
            style={{
              width: "200px",
              marginTop: "40px",
              padding: " 7px 0",
              marginLeft: "-80px",
              marginBottom: "30px",
            }}
            onChange={(e) => setAddNew({ ...addNew, region: e.target.value })}
          >
            <option value="">-Select-</option>
            {regions.map((region, index) => (
              <option key={index} value={region}>
                {region}
              </option>
            ))}
          </select> */}
          {/* <div style={{ width: "20v" }}>
       
          </div> */}
        </div>
        <Button label="Save" className="blue" type="submit" />
      </form>
      {passwordStatus === "1" ? (
        <div
          className="menu disabler"
          style={{ zIndex: "10", position: "fixed", top: "0", left: "0" }}
          onClick={handleDisableClick}
        ></div>
      ) : (
        ""
      )}
    </div>
  );
};

export default UpdatePassword;
