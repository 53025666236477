import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataFromApi } from "../../redux-tookit/slices/sdbrSlice";
import TableComponent from "../../components/TableComponent";
import "../../assets/styles/ReportPage.css";

const SapDocument = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.SapDocument.columns);
  const originalData = useSelector((state) => state.sdbr.SapDocument.data);
  const [data, setData] = useState(originalData);

  const [postingdateFilter, setpostingdateFilter] = useState("");
  const [fgmatFilter, setfgmatFilter] = useState("");
  const [rmmatFilter, setrmmatFilter] = useState("");
  const [docnoFilter, setdocnoFilter] = useState("");
  const [yearFilter, setyearFilter] = useState("");
  const [fromlocFilter, setfromlocFilter] = useState("");
  const [tolocFilter, settolocFilter] = useState("");

  const postingdateValues = [
    ...new Set(originalData.map((item) => item.postingdate)),
  ];
  const fgmatValues = [...new Set(originalData.map((item) => item.fgmat))];
  const rmmatValues = [...new Set(originalData.map((item) => item.rmmat))];
  const docnoValues = [...new Set(originalData.map((item) => item.docno))];
  const yearValues = [...new Set(originalData.map((item) => item.year))];
  const fromlocValues = [...new Set(originalData.map((item) => item.fromloc))];
  const tolocValues = [...new Set(originalData.map((item) => item.toloc))];

  const applyFilters = () => {
    let filteredData = originalData;

    if (postingdateFilter) {
      filteredData = filteredData.filter((item) =>
        item.postingdate.includes(postingdateFilter)
      );
    }
    if (fgmatFilter) {
      filteredData = filteredData.filter((item) =>
        item.fgmat.includes(fgmatFilter)
      );
    }
    if (rmmatFilter) {
      filteredData = filteredData.filter((item) =>
        item.rmmat.includes(rmmatFilter)
      );
    }
    if (docnoFilter) {
      filteredData = filteredData.filter((item) =>
        item.docno.includes(docnoFilter)
      );
    }
    if (yearFilter) {
      filteredData = filteredData.filter((item) =>
        item.year.includes(yearFilter)
      );
    }
    if (fromlocFilter) {
      filteredData = filteredData.filter((item) =>
        item.year.includes(fromlocFilter)
      );
    }
    if (tolocFilter) {
      filteredData = filteredData.filter((item) =>
        item.year.includes(tolocFilter)
      );
    }

    setData(filteredData);
  };

  useEffect(() => {
    dispatch(fetchDataFromApi("SapDocument"));
    setData(originalData);
  }, [dispatch, originalData]);

  useEffect(() => {
    applyFilters();
  }, [
    postingdateFilter,
    fgmatFilter,
    rmmatFilter,
    docnoFilter,
    yearFilter,
    fromlocFilter,
    tolocFilter,
  ]);

  return (
    <div className="sdbr">
      <div className="filter-card">
        <div className="Filter-OrdFeat">
          <div className="filter">
            <label htmlFor="postingdateFilter">Posting Date</label>
            <select
              id="postingdateFilter"
              value={postingdateFilter}
              onChange={(e) => setpostingdateFilter(e.target.value)}
            >
              <option value="">All</option>
              {postingdateValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label htmlFor="fgmatFilter">FG Mat</label>
            <select
              id="fgmatFilter"
              value={fgmatFilter}
              onChange={(e) => setfgmatFilter(e.target.value)}
            >
              <option value="">All</option>
              {fgmatValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label htmlFor="rmmatFilter">RM Mat</label>
            <select
              id="rmmatFilter"
              value={rmmatFilter}
              onChange={(e) => setrmmatFilter(e.target.value)}
            >
              <option value="">All</option>
              {rmmatValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label htmlFor="docnoFilter">Doc no</label>
            <select
              id="docnoFilter"
              value={docnoFilter}
              onChange={(e) => setdocnoFilter(e.target.value)}
            >
              <option value="">All</option>
              {docnoValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="Sap-mar">
          <div className="Filter-OrdFeat">
            {/* <div className="filter">
              <label htmlFor="docnoFilter">Doc no</label>
              <select
                id="docnoFilter"
                value={docnoFilter}
                onChange={(e) => setdocnoFilter(e.target.value)}
              >
                <option value="">All</option>
                {docnoValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div> */}
            <div className="filter">
              <label htmlFor="yearFilter">Year</label>
              <select
                id="yearFilter"
                value={yearFilter}
                onChange={(e) => setyearFilter(e.target.value)}
              >
                <option value="">All</option>
                {yearValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-SAPD">
              <div className="filter">
                <label htmlFor="fromlocFilter">From loc</label>
                <select
                  id="fromlocFilter"
                  value={fromlocFilter}
                  onChange={(e) => setfromlocFilter(e.target.value)}
                >
                  <option value="">All</option>
                  {fromlocValues.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter">
                <label htmlFor="tolocFilter">To loc</label>
                <select
                  id="tolocFilter"
                  value={tolocFilter}
                  onChange={(e) => settolocFilter(e.target.value)}
                >
                  <option value="">All</option>
                  {tolocValues.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="head">Sap Document</div>
      <div>
        <TableComponent columns={columns} data={data} />
      </div>
    </div>
  );
};

export default SapDocument;
