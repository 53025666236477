import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceCenter } from '../../../redux-tookit/slices/serviceCenterSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchProcessCenter, postProcessCenter, updateProcessCenter } from '../../../redux-tookit/slices/processCenterSlice';
import Button from '../../../utils/Button';

const AddProcessCenter = (props) => {
    const dispatch = useDispatch();
    const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData } = props
    const columns = useSelector((state) => state.processCenter.columns);
    const data = useSelector((state) => state.processCenter.labeledData);
    const scData = useSelector((state) => state.serviceCenter.labeledData); const columnKeys = Object.keys(columns);
    const [newRow, setNewRow] = useState({});



    const handleSaveButtonClick = async (e) => {
        dispatch(postProcessCenter({ ...newRow }));
        e.preventDefault();
        console.log(newRow);
        setIsAdding(false);
        setNewRow({});
    };
    const handleInputChange = (header, e) => {
        const updatedData = JSON.parse(JSON.stringify(editData));
        updatedData[header] = e.target.value;
        setEditData(updatedData);
    }
    useEffect(() => {
        setTimeout(() => {
            dispatch(fetchProcessCenter());
            dispatch(fetchServiceCenter());
        }, 200)

    }, [isAdding, isEditing]);
    const handleUpdate = async (e) => {

        e.preventDefault();
        const index = scData.find(item => item.name == editData.name);
        const id = editData.processCenterId;
        const type = { 'processCenterName': editData.processCenterName, 'serviceCenterId': index.serviceCenterId };
        console.log(type);
        dispatch(updateProcessCenter({ id, type }))
        setIsEditing(false);
    }
    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isAdding &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Add Process Center</div>
                            <div className="table-container">


                                {columnKeys.map((column) => (
                                    column !== 'processCenterId' ?
                                        (column === 'name' ?
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[column].label}
                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>} 
                                                </label>
                                                <select
                                                    id="serviceCenterName"
                                                    onChange={(e) => {
                                                        setNewRow({ ...newRow, ['serviceCenterId']: e.target.value });
                                                    }}
                                                    required
                                                    
                                                >
                                                    <option value="">Select</option>
                                                    {scData.map((value, index) => (
                                                        <option key={index} value={value.serviceCenterId}>
                                                            {value.name}
                                                        </option>
                                                    ))}
                                                </select></div>) :
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[column].label}
                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                <input
                                                    key={column}
                                                    type={columns[column].type}
                                                    className='table-input'
                                                    value={newRow[column] || ''}
                                                    onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                    required={columns[column].isRequired ? true : false}
                                                /></div>
                                            )
                                        ) :
                                        ""
                                ))}

                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsAdding(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Edit Process Center</div>
                            <div className="table-container">


                                {columnKeys.map((key) => (
                                    columns[key].editable === true ?
                                        (key === 'name' ?
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[key].label}
                                                    {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                <select
                                                    id="serviceCenterName"
                                                    value={editData[key]}
                                                    onChange={(e) => handleInputChange(key, e)}
                                                >
                                                    {scData.map((value, index) => (
                                                        <option key={index} value={value.name}>
                                                            {value.name}
                                                        </option>
                                                    ))}
                                                </select></div>) :
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[key].label}
                                                    {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                <input

                                                    type={columns[key].type}
                                                    className='table-input'
                                                    value={editData[key]}
                                                    onChange={(e) => handleInputChange(key, e)}
                                                    required={columns[key].isRequired ? true : false}


                                                /></div>
                                            )
                                        ) :
                                        ""
                                ))}

                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
        </div>
    )
}

export default AddProcessCenter