import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../assets/styles/NewPlan.css'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Button from '../../utils/Button';
import { useNavigate } from 'react-router-dom';
import { editScrapDefinition, fetchPlan, fetchScrapDefinition, postPlan } from '../../redux-tookit/slices/planSlice';
import AGTable from '../../components/AGTable';
import Loader from '../../components/Loader/Loader';
import { setNavOptions } from '../../redux-tookit/slices/navbarSlice';
import { fetchUserAccess } from '../../redux-tookit/slices/userAccessSlice';
import CustomBundleTable from './Dispatch/CustomBundleTable';

const NewPlan = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState('fulfilled')
  const orderItemsColumns = useSelector((state) => state.plan.columns);
  const orderItemsData = useSelector((state) => state.plan.labeledData)
  const scrapDefColumns = useSelector(state => state.plan.scrapDefColumns)
  const scrapDefData = useSelector(state => state.plan.scrapDefData)
  // .filter(item => item.totalTags!==0);

  console.log(scrapDefColumns, scrapDefData);

  const [selectedRowIndex, setSelectedRowIndex] = useState([]);

  const [scrapDefVisible, setScrapDefVisible] = useState(false);

  const [permission, setPermission] = useState({});

  const [inventoryData, setInventoryData] = useState([]);

  const [inventoryShow, setInventoryShow] = useState(false);

  const inventoryColumns = {
    "grade": { label: 'Grade' },
    "diameter": { label: 'Diameter' },
    "requiredPieces": { label: 'Pieces Required' },
    "requiredLength": { label: 'Length Required' }
  }

  //   "requiredInventory": [
  //     {
  //         "partItemId": 5335,
  //         "grade": "IS1786 FE550SD",
  //         "diameter": 10,
  //         "requiredPieces": 200,
  //         "requiredLength": 387
  //     }
  // ]

  useEffect(() => {
    dispatch(fetchUserAccess())
      .then(res => {
        if (res.payload) {
          if (res.payload.navbarNames.Transaction["New Plan"][0]) {
            setPermission(res.payload.navbarNames.Transaction["New Plan"][0])
          }
        }
      })
  }, [])

  const handleRowSelect = (rowIndex) => {
    if (rowIndex !== undefined) {
      setSelectedRowIndex(rowIndex);
    } else {
      setSelectedRowIndex(null);
    }
  };

  const handleOptimizer = async () => {

    let orderItemIdValues = []

    selectedRowIndex.map((rowIndex) => {
      orderItemIdValues = [...orderItemIdValues, orderItemsData[rowIndex].orderItemId]
    })

    const newRow = {
      "orderItemIds": orderItemIdValues,
      "scrapDefn": scrapDefData.values
    }


    console.log(newRow);

    setLoading('pending')

    dispatch(postPlan(newRow)).then((res) => {
      setLoading('fulfilled')
      if (res.payload !== undefined) {
        console.log(res.payload);
        if (res.payload.message) {
          toast.error(`${res.payload.message}`)

          setInventoryData(res.payload.requiredInventory)
          setInventoryShow(true)
          //   "requiredInventory": [
          //     {
          //         "partItemId": 5335,
          //         "grade": "IS1786 FE550SD",
          //         "diameter": 10,
          //         "requiredPieces": 200,
          //         "requiredLength": 387
          //     }
          // ]
          return
        }
        dispatch(setNavOptions({ expOpt: 'Reports', selOpt: 'Plan Details' }));
        navigate('/plan details', { state: { planId: res.payload.planId } });
      }
    })

    // navigate('/summary');
    
  }

  const openScrapDefinition = () => {
    setScrapDefVisible((prev) => !prev)
  }


  useEffect(() => {
    dispatch(fetchPlan());
    dispatch(fetchScrapDefinition())
  }, [dispatch]);

  return (
    <div className='sdbr'>
      {loading === 'pending' ? <Loader /> :
        <>
          <div className="head">New Plan</div>

          <div className="normal-head"> </div>
          <AGTable data={orderItemsData} columns={orderItemsColumns} onSelectRow={handleRowSelect} rowSelection={'multiple'} />

          <div className='bottom-button-container'>
            {permission.updatePermission === true ? <>
              <Button className='blue' label='Scrap Definition' onClick={() => { openScrapDefinition() }} />
              <Button className='blue' label='Run Optimizer' onClick={() => { handleOptimizer(selectedRowIndex) }} disabled={selectedRowIndex} />
            </>

              : ''}
          </div>
          {scrapDefVisible && <ScrapDefPopup data={scrapDefData} columns={scrapDefColumns} onClose={openScrapDefinition} />}

          {inventoryShow && <InventoryShow data={inventoryData} columns={inventoryColumns} onClose={setInventoryShow} />}
        </>

      }
    </div>
  )
}

export default NewPlan


const ScrapDefPopup = (props) => {

  const dispatch = useDispatch();

  const { data = [], columns = {}, onClose } = props

  const [innerData, setInnerData] = useState(data.values)

  const handleEdit = (header, value) => {
    setInnerData({ ...innerData, [header]: value * 1 })
  }

  const handleSave = () => {
    const id = data.scrapDefinationId;
    const updatedData = { "values": { ...innerData } };

    dispatch(editScrapDefinition({ id, updatedData })).then(() => { onClose(); dispatch(fetchScrapDefinition()) })
  }


  useEffect(() => {

  }, [data])
  return (
    <div>
      <form>
        <div className="AddContainer" >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'fit-content', backgroundColor: '#fff', borderRadius: '10px', paddingBottom: '1vw' }}>
            <div className="table-container" style={{ height: '300px', overflow: 'scroll' }}>

              <table className='generic-table' >
                <thead>
                  <tr>
                    <th className="table-header">
                      Diameter
                    </th>
                    <th className="table-header">
                      Scrap Length
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {columns.map((header, index) => (
                    <tr key={index}>
                      <td >{header}</td>
                      <td style={{ padding: '0', paddingLeft: '0', paddingRight: '0' }} >
                        <input type="number" value={innerData[header]} style={{ padding: '10px' }} onChange={(e) => handleEdit(header, e.target.value)} />
                      </td>

                    </tr>
                  ))}

                </tbody>

              </table>

            </div>
            <div className="bottom-button-container">
              <Button label='Save' className='blue' onClick={() => handleSave()} />

              <Button label='Close' className='blue' onClick={() => onClose()} />
            </div>
          </div>
        </div>
        <div className="bg-dark"></div>
      </form>
    </div>
  )
}

const InventoryShow = (props) => {

  const { data = [], columns = {}, onClose } = props

  return (<>
    <div>
      <form>
        <div className="AddContainer" >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'fit-content', backgroundColor: '#fff', borderRadius: '10px', paddingBottom: '1vw' }}>
            <div className="table-container" style={{ height: '300px', overflow: 'scroll' }}>

              <table className='generic-table' >
                <thead>
                  <tr>
                    {Object.keys(columns).map((data, index) => (
                      <th className="table-header" key={index}>{columns[data].label}</th>
                    ))}

                  </tr>
                </thead>
                <tbody>
                  {data.map((data, index) =>
                    <tr key={index}>{Object.keys(columns).map(header =>
                      <td >{data[header]}</td>
                    )}</tr>

                  )}

                </tbody>

              </table>

            </div>
            <div className="bottom-button-container">

              <Button label='Close' className='blue' onClick={() => onClose(false)} />
            </div>
          </div>
        </div>
        <div className="bg-dark"></div>
      </form>
    </div>
  </>)
}