import React, { useState, useEffect } from "react";
import "../../../assets/styles/BbsShapeSetting.css";
import Button from "../../../utils/Button.jsx";
import { useDispatch, useSelector } from "react-redux";
// import CanvasDraw from "../../master/Shape/CanvasDraw.jsx";
import {
    fetchPartItems,
    postPartItems,
    updatePartItems,
} from "../../../redux-tookit/slices/partItemsSlice.jsx";
import { fetchGrades } from "../../../redux-tookit/slices/gradeMasterSlice.jsx";
import { toast } from "react-toastify";
import BBSDraw from "../../../components/Test/BBSDraw.jsx";

const BbsShapeSetting = (props) => {
    const {
        BBSDetailsProps,
        tempDis,
        uTempDis,
        shapeD = [],
        orderId,
        materials = [],
        diameterValues,
        gradeValues,
        ordItems,
        bbsShapeId,
        editData = {},
        compAction = "add",
        id,
        mag = "100,147,1",
        lastData,
        actualShape,
    } = props;

    const dispatch = useDispatch();
    const [diaValues, uDiaValues] = useState([]);
    const [elementWiseData, setElementWiseData] = useState(null);
    const [totalNoOfPieces, setTotalNoOfPieces] = useState(0);
    const [grValues, uGrValues] = useState([]);
    const [orItValues, setOrItValues] = useState([]);
    const [changer, uChanger] = useState(true);
    const [localChanger, uLocalChanger] = useState(true);
    const [saveInit, setSaveInit] = useState(false);
    const [material, setMaterial] = useState([]);
    const [bbsCanvasRef, setBbsCanvasRef] = useState();

    const gradeMaster = useSelector((state) => state.gradeMaster.labeledData);

    let extraAdder = {};

    const defaultDiameter = BBSDetailsProps?.diameter ||
            [
                ...new Set(
                    gradeMaster
                        .filter((item) => item.gradeName === BBSDetailsProps?.gradeName)
                        .map((item) => item.diameter)
                ),
            ].sort((a, b) => a - b)[0]

    const [extraData, uExtraData] = useState({
        diameter: defaultDiameter,
        gradeName: BBSDetailsProps?.gradeName,
        pinDiameter: defaultDiameter * 4
    });

    const [pinDiaValue, setPinDiaValue] = useState();

    useEffect(() => {
        setPinDiaValue(extraData.diameter * 4);
        console.log(1);

    }, [extraData.diameter]);

    const [formData, setFormData] = useState({});

    // const [diameterValues, setDiameterValues] = useState([]);
    // const [gradeValues, setGradeValues] = useState([])
    // const [orderItemValues, setOrderItemValues] = useState([]);

    const [shapeData, uShapeData] = useState(shapeD);

    const [orX, orY, magValue] = mag.split(",");

    const [orginX, setOrginX] = useState(orX * 1);
    const [orginY, setOrginY] = useState(orY * 1);
    const [mF, setMF] = useState(magValue * 1);

    const [partItemDetails, uPartItemDetails] = useState({
        id: bbsShapeId, //
        serviceCenterId: 2,
        orderItemId:
            BBSDetailsProps.orderItemId ||
            (compAction === "add" && lastData?.orderItemId), //
        diffFactId: 2,
        routeId: 1,
        noOfPieces: BBSDetailsProps?.noOfPieces || lastData?.noOfPieces,
        totalNoOfPieces: totalNoOfPieces, //
        bundleId: 1,
        bmRow: "1", //posAfterBmrow
        lengthDeduction: 10,
        scale: 1,
        inventoryLength: "",
        productionLength: "",
        inventoryWeight: "",
        productionWeight: "",
        tagId: 1,
        tagDescription: "",
        bundleQuantity: totalNoOfPieces,
        // barMarkId: barMarkIdLength,
        plId: 1,
        image: "",
        pinDiameter: extraData.pinDiameter,
    });

    console.log(partItemDetails, BBSDetailsProps, editData, extraData);

    const noOfSetsForDataShow =
        ordItems &&
        ordItems.length > 0 &&
        ordItems.find((item) => {
            if (item?.orderItemId === partItemDetails?.orderItemId) {
                return item;
            } else if (item?.orderItemName === partItemDetails?.orderItemId) {
                return item;
            }
        });

    useEffect(() => {
        console.log(2);
        const data =
            BBSDetailsProps?.barMarkIdLength.filter(
                (item) => item.orderItemId === partItemDetails.orderItemId
            ).length + 1;

        setBarMarkId(editData.barMarkId ? editData.barMarkId : `BM_${data}`);
    }, [
        partItemDetails?.orderItemId,
        BBSDetailsProps?.barMarkIdLength,
        editData.barMarkId,
    ]);

    function getTotalNumberOfPieces() {
        if (noOfSetsForDataShow?.noOfSets && partItemDetails.noOfPieces) {
            setTotalNoOfPieces(
                noOfSetsForDataShow?.noOfSets === undefined
                    ? 0
                    : noOfSetsForDataShow?.noOfSets * (partItemDetails.noOfPieces)
            );
            uPartItemDetails({
                ...partItemDetails, 
                totalNoOfPieces: noOfSetsForDataShow?.noOfSets === undefined
                    ? 0
                    : noOfSetsForDataShow?.noOfSets * partItemDetails.noOfPieces,
                    bundleQuantity: noOfSetsForDataShow?.noOfSets === undefined
                    ? 0
                    : noOfSetsForDataShow?.noOfSets * partItemDetails.noOfPieces,
            })
            console.log('this ru', noOfSetsForDataShow?.noOfSets, partItemDetails.noOfPieces);

        }

        // if (partItemDetails.noOfPieces !== '' && partItemDetails.noOfPieces) {
        //     uPartItemDetails({
        //         ...partItemDetails, 
        //         totalNoOfPieces: noOfSetsForDataShow?.noOfSets === undefined
        //             ? 0
        //             : noOfSetsForDataShow?.noOfSets * partItemDetails.noOfPieces,
        //     })
        //     console.log('this ru', noOfSetsForDataShow?.noOfSets, partItemDetails.noOfPieces);

        // } else {
        //     uPartItemDetails({
        //         ...partItemDetails, 
        //         totalNoOfPieces: noOfSetsForDataShow?.noOfSets === undefined
        //             ? 0
        //             : noOfSetsForDataShow?.noOfSets * BBSDetailsProps.noOfPieces,
        //     })
        //     console.log('that lo', noOfSetsForDataShow?.noOfSets, partItemDetails.noOfPieces)
        // }
    }

    useEffect(() => {
        console.log(9);
        getTotalNumberOfPieces();
    }, [noOfSetsForDataShow, BBSDetailsProps]);


    useEffect(() => {
        console.log(3);

        BBSDetailsProps?.barMarkIdLength.map((item) => {
            if (item.orderItemId === partItemDetails.orderItemId) {
                setElementWiseData(item);
                if (BBSDetailsProps?.diameter) {
                    setElementWiseData({ ...elementWiseData, diameter: BBSDetailsProps?.diameter })
                }

            } else {
                setElementWiseData(null);
            }
        });


    }, [BBSDetailsProps]);


    const [barMarkId, setBarMarkId] = useState();

    useEffect(() => {
        console.log(4);

        if (elementWiseData !== null) {
            
            uExtraData({
                ...extraData,
                diameter: elementWiseData?.diameter,
                gradeName: elementWiseData?.gradeName || BBSDetailsProps?.gradeName,
                // pinDiameter: editData.pinDia,
            });
        } else {
            uExtraData({
                ...extraData,
                // gradeName: elementWiseData?.gradeName,
            });
        }

    }, [elementWiseData]);

    const editShapes = (index, header, value) => {
        uShapeData((prev) => {
            const newShapes = [...prev];
            newShapes[index][header] = value;
            return newShapes;
        });
        diaInputHandle();
        uChanger(!changer);
    };

    const addExtraData = () => {
        const noOfSet = orItValues.filter(
            (item) => item.orderItemId * 1 === partItemDetails.orderItemId * 1
        )[0].noOfSets;

        const totalPieces = partItemDetails.noOfPieces * noOfSet;
        let gradeId = "";
        gradeMaster.map((item) => {
            if (
                item.diameter * 1 === extraData.diameter * 1 &&
                `${item.gradeName}` === `${extraData.gradeName}`
            )
                gradeId = item.gradeId;
        });

        // findGradeId(gradeMaster, extraData.gradeName, extraData.diameter)
        // gradeMaster.filter(item => (item.diameter * 1 === extraData.diameter * 1 && `${item.gradeName}` === `${extraData.gradeName}`))[0].gradeId

        const d = extraData.diameter;
        const xon = (totalPieces * Math.PI * d * d * 7850) / 4000000000;

        const invWeight = partItemDetails.inventoryLength * xon;
        const prodWeight = partItemDetails.productionLength * xon;

        extraAdder = {
            totalNoOfPieces: totalPieces,
            gradeId: gradeId,
            productionWeight: Math.round(prodWeight),
            inventoryWeight: Math.round(invWeight),
        };
    };

    const handleSave = async (e) => {
        e.preventDefault();
        addExtraData();

        const dataURL = bbsCanvasRef.toDataURL("image/png");

        const image = { image: dataURL };

        const pinDia = extraData.pinDiameter;
        // const barMarkIdLength = extraData?.barMarkIdLength?.length || 0;
        // const barMarkId = `BM_${barMarkIdLength + 1}`;
        const type = {
            ...partItemDetails,
            ...extraAdder,
            ...image,
            pinDia,
            barMarkId,

            imageProperties: JSON.stringify(shapeData),
            magnifications: `${Math.floor(orginX)},${Math.floor(orginY)},${Math.floor(mF * 1000) / 1000
                }`,
        };

        if (extraAdder.gradeId === "") {
            toast.error("No grade Id found");
            return;
        }

        // if (type.productionLength > 12000) {
        //   toast.error("The Production length cannot be greater than 12000mm ");
        //   return;
        // }

        if (type.productionLength < extraData.diameter * 0) {
            toast.error(
                "The Production length cannot be less than 60 times of diameter "
            );
            return;
        }

        if (compAction === "add") {
            dispatch(postPartItems(type)).then((res) => {
                if (res.payload !== undefined) {
                    dispatch(fetchPartItems(`?orderId=${orderId}`));
                    uTempDis();
                }
            });
        } else
            dispatch(updatePartItems({ id, type })).then((res) => {
                if (res.payload !== undefined) {
                    dispatch(fetchPartItems(`?orderId=${orderId}`));
                    uTempDis();
                }
            });

    };

    const handleFormChange = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const ProdLengthCalculator = (dia = null, Dia = null) => {

        let cuLen = 0,
            direction = 0,
            invLen = 0,
            cu2 = 0;
        let D = extraData.diameter * 4,
            d = extraData.diameter * 1;
        if (dia !== null) {
            d = dia * 1;
            D = dia * 4;
        }

        if (Dia !== null) {
            D = Dia * 1
        }


        shapeData.map((i, k) => {

            // direction = -(Math.atan(l2 / l1));
            // console.log('index:',k,'L:',i.Length1,'Ang:',i.Angle)

            i.Geometry === "Angle"
                ? (direction = direction - (i.Angle * Math.PI) / 180)
                : i.Geometry === "Arc"
                    ? i.Type === "Rad+Sw_Angle"
                        ? (direction = direction - (i.Angle * Math.PI) / 180)
                        : i.Type === "Chord+Normal"
                            ? (direction = direction - 2 * Math.PI)
                            : (direction = direction - ((i.Length2 / i.Length1) * Math.PI) / 180)
                    : i.Geometry === "Length"
                        ? i.Type === "Offset + Normal"
                            ? (direction = -Math.atan(i.Length2 / i.Length1))
                            : console.log("#")
                        : console.log("#");

            if (i.Geometry === "Spring") {
                if (i.Match1 === "A") {
                    cuLen = 1 / i.Length1;
                } else if (i.Match1 === "B") {
                    cuLen = cuLen * i.Length1;
                } else if (i.Match1 === "C") {
                    cuLen = cuLen * i.Length1 * Math.PI;
                }
            }

            i.Geometry === "Length"
                ? i.Type === "Actual"
                    ? (cuLen = cuLen + 1 * i.Length1)
                    : i.Type === "Normal"
                        ? (cuLen = cuLen + i.Length1 / Math.sin(-direction))
                        : i.Type === "Offset"
                            ? (cuLen = cuLen + i.Length1 / Math.cos(-direction))
                            : (cuLen =
                                cuLen + Math.sqrt(i.Length1 * i.Length1 + i.Length2 * i.Length2))
                : i.Geometry === "Arc"
                    ? i.Type === "Rad+Sw_Angle"
                        ? (cuLen = cuLen + (i.Length1 * Math.abs(i.Angle) * Math.PI) / 180)
                        : i.Type === "Dia+Sw_Angle"
                            ? (cuLen =
                                cuLen + ((i.Length1 / 2) * Math.abs(i.Angle) * Math.PI) / 180)
                            : i.Type === "Chord+Normal"
                                ? (cuLen =
                                    cuLen +
                                    (((i.Length1 * i.Length1) / 4 + i.Length2 * i.Length2) /
                                        (2 * i.Length2)) *
                                    2 *
                                    Math.PI)
                                : (cuLen = cuLen + i.Length2 * 1)
                    : i.Geometry === "Angle" && k !== 0
                        ? i.Angle <= 90 && i.Angle >= -90
                            ? (cuLen = cuLen - (D * 1 + 2 * d) / Math.tan((90 - Math.abs(i.Angle) / 2) * (Math.PI / 180)) + ((Math.PI * Math.abs(i.Angle)) / 360) * (D * 1 + d * 1))
                            : (cuLen = cuLen + ((Math.PI * Math.abs(i.Angle)) / 360) * (D + d) - (D * 1 + 2 * d))
                        : console.log("#");

            if (i.Geometry !== "Angle") {
                invLen = invLen + cuLen - cu2;
            }
            cu2 = cuLen;

        });


        uPartItemDetails({
            ...partItemDetails,
            productionLength: Math.round(cuLen),
            inventoryLength: Math.round(invLen),
        });
        uLocalChanger(!localChanger);
    };

    const diaInputHandle = (dia = null, Dia = null) => {
        if (partItemDetails.diameter !== "") {
            ProdLengthCalculator(dia, Dia);
        }
    };
    

    useEffect(() => {
        console.log(5);

        uShapeData(() => {
            const tempArray = JSON.parse(JSON.stringify(shapeD));
            return tempArray;
        });
        ProdLengthCalculator();
    }, [shapeD]);

    useEffect(() => {
        console.log(6);

        dispatch(fetchGrades());
    }, [dispatch]);

    useEffect(()=> {
        uPartItemDetails({...partItemDetails, bundleQuantity: totalNoOfPieces})
    },[totalNoOfPieces])

    //   noOfSetsForDataShow?.noOfSets);

    useEffect(() => {
        console.log(7);

        // setDiameterValues([...new Set(materials.map(item => item.diameter))].sort((a, b) => a - b))
        // setGradeValues([...new Set(materials.map(item => item.gradeName))])
        setOrItValues(ordItems);

        uDiaValues(diameterValues);
        uGrValues(gradeValues);
        // uPartItemDetails({ ...partItemDetails });
        setMaterial(materials);
        if (compAction === "update") {
            uPartItemDetails({ ...partItemDetails, ...editData });

            if (editData.diameter && editData.gradeName && editData.pinDia) {
                uExtraData({
                    ...extraData,
                    diameter: editData.diameter,
                    gradeName: editData.gradeName,
                    pinDiameter: editData.pinDia,
                });
            } else if (editData.gradeName) {
                uExtraData({ ...extraData, gradeName: editData.gradeName });
            }
        }
    }, [diameterValues, editData]);

    const HandleElementChange = () => {
        //renders grades based on element

        return compAction === "add" &&
            BBSDetailsProps?.barMarkIdLength?.filter(
                (item) => item.orderItemId === partItemDetails.orderItemId
            )?.length > 0 ? (
            <option
                value={
                    ordItems?.find(
                        (item) => item.orderItemId === partItemDetails.orderItemId
                    )?.gradeName
                }
                hidden
            >
                {
                    ordItems?.find(
                        (item) => item.orderItemId === partItemDetails.orderItemId
                    )?.gradeName
                }
            </option>
        ) : compAction === "update" &&
            (BBSDetailsProps?.barMarkIdLength?.filter(
                (item) => item.orderItemId === partItemDetails.orderItemId
            )?.length > 1 ||
                editData.orderItemId !== partItemDetails.orderItemId) ? (
            <option
                value={
                    ordItems?.find(
                        (item) => item.orderItemId === partItemDetails.orderItemId
                    )?.gradeName
                }
                hidden
            >
                {
                    ordItems?.find(
                        (item) => item.orderItemId === partItemDetails.orderItemId
                    )?.gradeName
                }
            </option>
        ) : (
            <>
                {grValues.map((value) => (
                    <option value={value}>{value}</option>
                ))}
            </>
        );
    };

    return (
        <div
            className="bbsSet"
            style={{ display: `${tempDis === true ? `block` : `none`}` }}
        >
            <div className="overlay-hide-bg"></div>
            <form onSubmit={handleSave}>
                <div className="dialog-box-1">
                    <div className="row-1">
                        <div className="box-1">
                            <div className="box-row-1">
                                <div className="row-item-1">
                                    <label htmlFor="diameter">Bar Mark Id</label>
                                    <input
                                        type="text"
                                        id="diameter"
                                        // value={extraData.diameter * 4}
                                        defaultValue={barMarkId}
                                        required
                                        style={{ width: "5vw", height: "25px" }}
                                        onChange={(e) => {
                                            setBarMarkId(e.target.value);
                                            uExtraData({
                                                ...extraData,
                                                barMarkId: e.target.value,
                                            });

                                            handleFormChange(e);
                                        }}
                                    ></input>
                                </div>
                                <div className="row-item-1">
                                    <label htmlFor="Grade">Grade Name</label>
                                    <select
                                        style={{ height: "37px" }}
                                        value={extraData.gradeName}
                                        required
                                        onChange={(e) => {
                                            uExtraData({
                                                ...extraData,
                                                gradeName: e.target.value,
                                                diameter: [
                                                    ...new Set(
                                                        gradeMaster
                                                            .filter(
                                                                (item) => item.gradeName === e.target.value
                                                            )
                                                            .map((item) => item.diameter)
                                                    ),
                                                ].sort((a, b) => a - b)[0],
                                            });
                                            handleFormChange(e);
                                        }}
                                    >
                                        <HandleElementChange />
                                    </select>
                                </div>
                                <div className="row-item-1">
                                    <label htmlFor="diameter">Diameter</label>
                                    <select
                                        style={{ height: "37px" }}
                                        id="diameter"
                                        value={extraData.diameter}
                                        required
                                        onChange={(e) => {
                                            uExtraData({ ...extraData, diameter: e.target.value });
                                            diaInputHandle(e.target.value);
                                            handleFormChange(e);
                                            setPinDiaValue(e.target.value * 4);

                                        }}
                                    >
                                        {BBSDetailsProps?.diameter === undefined ? (
                                            <></>
                                        ) : (
                                            <option value={BBSDetailsProps?.diameter} hidden>
                                                {BBSDetailsProps?.diameter}
                                            </option>
                                        )}
                                        {/* </option> */}
                                        {[
                                            ...new Set(
                                                gradeMaster
                                                    .filter(
                                                        (item) => item.gradeName === extraData.gradeName
                                                    )
                                                    .map((item) => item.diameter)
                                            ),
                                        ]
                                            .sort((a, b) => a - b)
                                            .map((value) => (
                                                <option value={value}> {value}</option>
                                            ))}
                                    </select>
                                </div>
                                <div className="row-item-1">
                                    <label htmlFor="diameter">Pin Dia</label>
                                    <input
                                        type="text"
                                        id="diameter"
                                        defaultValue={pinDiaValue}
                                        required
                                        value={pinDiaValue}
                                        style={{ width: "5vw", height: "27px" }}
                                        onChange={(e) => {
                                            setPinDiaValue(e.target.value);
                                            uExtraData({ ...extraData, pinDiameter: e.target.value });
                                            diaInputHandle(null, e.target.value);
                                            handleFormChange(e);
                                        }}
                                    ></input>
                                </div>
                            </div>
                            <div className="box-row-1">
                                {Object.keys(partItemDetails).map((header) =>
                                    header === "noOfPieces" ? (
                                        <>
                                            <div className="row-item-1">
                                                <label htmlFor="customerName">
                                                    No Of Pieces<span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    style={{ height: "26px" }}
                                                    type="number"
                                                    value={partItemDetails[header]}
                                                    required
                                                    onChange={(e) => {
                                                        uPartItemDetails({
                                                            ...partItemDetails,
                                                            noOfPieces: e.target.value * 1,
                                                            totalNoOfPieces: noOfSetsForDataShow?.noOfSets * e.target.value,
                                                            bundleQuantity:
                                                                noOfSetsForDataShow?.noOfSets * e.target.value,
                                                        });
                                                        setTotalNoOfPieces(noOfSetsForDataShow?.noOfSets * e.target.value,)
                                                        handleFormChange(e);
                                                    }}
                                                />
                                            </div>
                                            <div className="row-item-1">
                                                <label htmlFor="customerName">
                                                    Total No Of Pieces
                                                    <span style={{ color: "red" }}>*</span>
                                                </label>
                                                <input
                                                    style={{ height: "26px" }}
                                                    type="number"
                                                    value={totalNoOfPieces}
                                                    required
                                                />
                                            </div>
                                        </>
                                    ) : header === "bundleQuantity" ? (
                                        <div className="row-item-1">
                                            <label htmlFor="customerName">
                                                Pcs/Bundles<span style={{ color: "red" }}>*</span>
                                            </label>
                                            <input
                                                style={{ height: "26px" }}
                                                type="number"
                                                defaultValue={totalNoOfPieces}
                                                value={partItemDetails[header]}
                                                required
                                                min={1}
                                                onKeyDown={(e) =>
                                                    e.key === "e" ? e.preventDefault() : ""
                                                }
                                                onChange={(e) => {
                                                    uPartItemDetails({
                                                        ...partItemDetails,
                                                        bundleQuantity: e.target.value,
                                                    });
                                                    handleFormChange(e);
                                                }}
                                            />
                                        </div>
                                    ) : (
                                        // header === 'pinDia' ? (<div className="row-item-1">
                                        //     <label htmlFor="customerName">Pin Dia.<span style={{ color: 'red' }}>*</span></label>
                                        //     <input
                                        //         type='number'
                                        //         value={partItemDetails[header]}
                                        //         required
                                        //         onChange={(e) => { uPartItemDetails({ ...partItemDetails, pinDia: e.target.value }); handleFormChange(e) }}
                                        //         onBlur={() => diaInputHandle()} />
                                        // </div>) :
                                        ""
                                    )
                                )}
                            </div>

                            <div className="box-row-3">
                                <div className="row-item-1">
                                    <label htmlFor="customerName">Tag Desc</label>
                                    <input
                                        type="text"
                                        value={partItemDetails.tagDescription}
                                        onChange={(e) => {
                                            uPartItemDetails({
                                                ...partItemDetails,
                                                tagDescription: e.target.value,
                                            });
                                            handleFormChange(e);
                                        }}
                                    />
                                </div>
                                <div className="row-item-1">
                                    <label htmlFor="route">Route</label>
                                    <select
                                        id="route"
                                        value={partItemDetails.routeId}
                                        required
                                        onChange={(e) =>
                                            uPartItemDetails({
                                                ...partItemDetails,
                                                routeId: e.target.value,
                                            })
                                        }
                                    >
                                        <option value="1">Rebar Route</option>
                                        <option value="2">Coil Route</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className="box-2">
                            <div className="box-row-2">
                                <div className="row-item-1">
                                    <label htmlFor="prodLength">Prod. Length (auto-fill)</label>
                                    <input
                                        type="text"
                                        id="prodLength"
                                        value={partItemDetails.productionLength}
                                        onChange={handleFormChange}
                                        readOnly
                                        required
                                    />
                                </div>

                                <div className="row-item-1">
                                    <label htmlFor="element">
                                        Element<span style={{ color: "red" }}>*</span>
                                    </label>
                                    <select
                                        id="element"
                                        value={partItemDetails.orderItemId}
                                        required
                                        onChange={(e) => {
                                            uPartItemDetails({
                                                ...partItemDetails,
                                                orderItemId: e.target.value * 1,
                                            });
                                            if (
                                                ordItems?.filter(
                                                    (item) => item.orderItemId === e.target.value * 1
                                                ).length !== 0
                                            ) {
                                                uExtraData({
                                                    ...extraData,
                                                    gradeName: ordItems?.find(
                                                        (item) => item.orderItemId === e.target.value * 1
                                                    )?.gradeName,
                                                    diameter: ordItems?.find(
                                                        (item) => item.orderItemId === e.target.value * 1
                                                    )?.diameter,
                                                });
                                            }

                                            handleFormChange(e);
                                        }}
                                    >
                                        <option value="" hidden>
                                            -Select-
                                        </option>
                                        {orItValues.map((value) => (
                                            <option value={value.orderItemId}>
                                                {value.orderItemName}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="box-row-1">
                                <div className="row-item-1">
                                    <label htmlFor="PlId">Pl Id</label>
                                    <select
                                        value={partItemDetails.plId}
                                        onChange={(e) =>
                                            uPartItemDetails({
                                                ...partItemDetails,
                                                plId: e.target.value,
                                            })
                                        }
                                    >
                                        <option value="1">1</option>
                                    </select>
                                </div>
                                <div className="row-item-1">
                                    <label htmlFor="BarType">Bar Type</label>
                                    <select
                                        value={extraData.barType}
                                        onChange={(e) =>
                                            uExtraData({ ...extraData, barType: e.target.value })
                                        }
                                    >
                                        <option value="T">T</option>
                                    </select>
                                </div>
                                <div className="row-item-1">
                                    <label htmlFor="noOfSets">
                                        No Of Sets
                                        <span style={{ color: "red" }}>*</span>
                                    </label>
                                    <input
                                        type="text"
                                        value={noOfSetsForDataShow?.noOfSets}
                                        required
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row-2">
                        <div className="box-3">
                            <BBSDraw
                                bbs={true}
                                drawingElements={shapeData}
                                changer={changer}
                                partItemDetails={partItemDetails}
                                uPartItemDetails={uPartItemDetails}
                                saveInit={saveInit}
                                handleSave={handleSave}
                                setBbsCanvasRef={setBbsCanvasRef}
                                orginX={orginX}
                                setOrginX={setOrginX}
                                orginY={orginY}
                                setOrginY={setOrginY}
                                mF={mF}
                                setMF={setMF}
                                constantLengthValue={actualShape}
                            />
                            {/* <div className="pBM">
                            <label htmlFor="">Position after BMRow</label>
                            <select name="pBM" id=""></select>
                        </div> */}
                        </div>
                        <div className="box-4">
                            <div className="table-container" style={{ maxHeight: "200px" }}>
                                <table className="generic-table">
                                    <thead className="stick">
                                        <tr>
                                            <th className="table-header">Character</th>
                                            <th className="table-header">Value</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {shapeData.map((el, index) => {
                                            let Ch1 = "",
                                                Ch2 = "";
                                            if (el.Geometry === "Arc") {
                                                let [Cha1, Cha2] = el.Type.split("+");
                                                Ch1 = Cha1;
                                                Ch2 = Cha2;
                                            }

                                            return (el.Geometry === "Length" &&
                                                el.Type === "Actual" &&
                                                el.Length1 !== 0 &&
                                                el.Length1 !== "0") ||
                                                el.Geometry === "Spring" ? (
                                                <tr>
                                                    <td>{el.Match1}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={el.Length1}
                                                            onChange={(e) => {
                                                                editShapes(index, "Length1", e.target.value);
                                                                handleFormChange(e);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ) : el.Geometry === "Angle" &&
                                                index !== 0 &&
                                                el.Capt2 === "Yes" ? (
                                                <tr>
                                                    <td>{el.Match1}</td>
                                                    <td>
                                                        <input
                                                            type="number"
                                                            value={el.Angle}
                                                            onChange={(e) => {
                                                                editShapes(index, "Angle", e.target.value);
                                                                handleFormChange(e);
                                                            }}
                                                        />
                                                    </td>
                                                </tr>
                                            ) : el.Geometry === "Arc" ? (
                                                <tr>
                                                    <td>{el.Match1}</td>
                                                    <td>
                                                        {Ch1}
                                                        {` `}
                                                        <input
                                                            type="number"
                                                            value={el.Length1}
                                                            min={0}
                                                            onChange={(e) => {
                                                                editShapes(index, "Length1", e.target.value);
                                                                handleFormChange(e);
                                                            }}
                                                            style={{ width: "20%" }}
                                                        />
                                                        {` `}
                                                        {Ch2}
                                                        {` `}
                                                        <input
                                                            type="number"
                                                            value={
                                                                el.Type === "Chord+Normal" ||
                                                                    el.Type.includes("ArcLength")
                                                                    ? el.Length2
                                                                    : el.Angle
                                                            }
                                                            onChange={(e) => {
                                                                editShapes(
                                                                    index,
                                                                    el.Type === "Chord+Normal" ||
                                                                        el.Type.includes("ArcLength")
                                                                        ? "Length2"
                                                                        : "Angle",
                                                                    e.target.value
                                                                );
                                                                handleFormChange(e);
                                                            }}
                                                            style={{ width: "20%" }}
                                                        />
                                                    </td>
                                                </tr>
                                            ) : (
                                                ""
                                            );
                                        })}
                                    </tbody>
                                </table>
                            </div>

                            <div className="bottom-button">
                                <Button label="Save" className="blue" type="submit" />
                                <Button
                                    label="Cancel"
                                    className="blue"
                                    onClick={() => uTempDis(!tempDis)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default BbsShapeSetting;
//End of File
