import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
	columns: {},
	columns2: {},
	labeledData: [],
	loading: 'idle',
	fetcher: true,
	error: null,
};

export const postOldData = createAsyncThunk('data/postOldData', async (newRow) => {
	try {
		console.log(newRow);
		const response = await api.exportShapes(newRow)
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		if(error.response.data.message)
		{toast.error(`${error.response.data.message}`)}
		throw error;
	}
});


const dataMigration = createSlice({
	name: 'dataMigration',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(postOldData.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postOldData.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				console.log(action.payload)
				toast.success('Uploaded successfully!');
			})


			.addCase(postOldData.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;

				toast.error('Upload Failed');
				
			})
		
	},
});

export default dataMigration.reducer;

//End of File