import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { resetState } from './loginSlice';

const initialState = {
	columns: {},
	columns2: {},
	labeledData: [],
	loading: 'idle',
	fetcher: true,
	error: null,
};

export const fetchPartItems = createAsyncThunk('data/fetchPartItems', async (params = '') => {
	try {

		const response = await api.listPartItems(params);

		// console.log(toSort);
		
		const json = response.data;
		const columnLabels = {
			partItemId: { label: "Part Item Id", type: 'number', isRequired: true, editable: false },
			orderItemId: { label: "Order Item Id", type: 'number', isRequired: true, editable: false },
			barMarkId: { label: "Bar Mark Id", type: 'number', isRequired: true, editable: false },
			diameter: { label: "Diameter (mm)", type: 'number', isRequired: true, editable: true },
			noOfPieces: { label: "No. of Pieces", type: 'number', isRequired: true, editable: true },
			totalNoOfPieces: { label: "Total No. of Pieces ", type: 'number', isRequired: true, editable: true },
			shapeId: { label: "Shape Id", type: 'number', isRequired: true, editable: true },
			image: { label: "Shape", type: 'number', isRequired: true, editable: true },
			orderItemName: { label: "Element Name", type: 'text', isRequired: true, editable: true },
			gradeName: { label: "Grade", type: 'text', isRequired: true, editable: true },
			bundleQuantity: { label: "Bundle Qty", type: 'number', isRequired: true, editable: true },
			inventoryLength: { label: "Inv Len (mm)", type: 'number', isRequired: true, editable: true },
			productionLength: { label: "Prod Len (mm)", type: 'number', isRequired: true, editable: true },
			productionWeight: { label: "Prod Wt (T)", type: 'number', isRequired: true, editable: true },
			inventoryWeight: { label: "Inv Wt (T)", type: 'number', isRequired: true, editable: true },
			bmRow: { label: "BM_Row", type: 'number', isRequired: true, editable: true },
			tagId: { label: "Tag Id", type: 'number', isRequired: true, editable: true },
			route: { label: "Route Desc", type: 'text', isRequired: true, editable: true },
			pinDia: { label: "Pin Dia", type: 'number', isRequired: true, editable: true },
			orderId: { label: "order Id", type: 'number' },
			threads: { label: "threads" },
			id: { label: "id", type: 'number' },
			imageProperties: { label: "image properties" },
			tagDescription: { label: "Tag description" },
			magnifications: { label: 'Magnification' },
			combId: {label: 'Order Id'},
			halfThread: {label: 'Half threads'},
			fullThread: {label: 'Full Threads'}



			// "dispatchStatus": 1,
			// "vehicleFitment": 1,
			// "vehicleFitmentDetails": "smncbwajkcweucwecbdgweudgie",
			// "driverFitment": 1,
			// "driverFitmentDetails": "cwyxbet2iej23ehbwdqwi7d632",

			// noOfTags:{label: "No of Tags", type: 'text', isRequired: true, editable: true },
			// totalWeight:{label: "Total Weight (Kg.)", type: 'text', isRequired: true, editable: true }

		};

		// 	"tagDescription": "tagDescriprion",
		// "imageProperties": "req.body.imageProperties",

		const selectedColumns = ['partItemId', 'orderItemId', 'barMarkId', 'diameter', 'noOfPieces', 'totalNoOfPieces', 'shapeId', 'image', 'orderItemName', 'gradeName', 'bundleQuantity', 'inventoryLength', 'productionLength', 'inventoryWeight', 'productionWeight', 'bmRow', 'tagId', 'route', 'pinDia', 'orderId', 'id', 'imageProperties', 'tagDescription', 'magnifications', 'threads','combId','halfThread','fullThread'];
		const selectedColumns2 = ['shapeId', 'image', 'orderItemName', 'tagId', 'bmRow', 'gradeName', 'diameter', 'pinDia', 'noOfPieces', 'totalNoOfPieces', 'inventoryLength', 'productionLength', 'inventoryWeight', 'productionWeight', 'partItemId', 'orderItemId', 'orderId', 'tagDescription', 'threads','combId'];
		const partItemShowColumns = ['partItemId', 'orderItemId', 'barMarkId', 'diameter', 'noOfPieces', 'totalNoOfPieces', 'shapeId', 'image', 'orderItemName', 'gradeName', 'threads', 'bundleQuantity', 'inventoryLength', 'productionLength', 'inventoryWeight', 'productionWeight', 'tagDescription', 
			// 'bmRow',
			
			 'tagId', 
			// 'route',
			 'pinDia', 'orderId', 'id'];

		const columns = {}, columns2 = {};

		partItemShowColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		selectedColumns2.forEach((columnName) => {
			columns2[columnName] = columnLabels[columnName];
		});



		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		})

	

		return { columns, labeledData, columns2 };
	} catch (error) {
		throw error;
	}
});

export const fetchPartItems2 = createAsyncThunk('data/fetchPartItems2', async ({params = '', toSort = false}) => {
	try {

		const response = await api.listPartItems(params);

		// console.log(toSort);
		const json = response.data;
		const columnLabels = {
			partItemId: { label: "Part Item Id", type: 'number', isRequired: true, editable: false },
			orderItemId: { label: "Order Item Id", type: 'number', isRequired: true, editable: false },
			barMarkId: { label: "Bar Mark Id", type: 'number', isRequired: true, editable: false },
			diameter: { label: "Diameter (mm)", type: 'number', isRequired: true, editable: true },
			noOfPieces: { label: "No. of Pieces", type: 'number', isRequired: true, editable: true },
			totalNoOfPieces: { label: "Total No. of Pieces ", type: 'number', isRequired: true, editable: true },
			shapeId: { label: "Shape Id", type: 'number', isRequired: true, editable: true },
			image: { label: "Shape", type: 'number', isRequired: true, editable: true },
			orderItemName: { label: "Element Name", type: 'text', isRequired: true, editable: true },
			gradeName: { label: "Grade", type: 'text', isRequired: true, editable: true },
			bundleQuantity: { label: "Bundle Qty", type: 'number', isRequired: true, editable: true },
			inventoryLength: { label: "Inv Len (mm)", type: 'number', isRequired: true, editable: true },
			productionLength: { label: "Prod Len (mm)", type: 'number', isRequired: true, editable: true },
			productionWeight: { label: "Prod Wt (T)", type: 'number', isRequired: true, editable: true },
			inventoryWeight: { label: "Inv Wt (T)", type: 'number', isRequired: true, editable: true },
			bmRow: { label: "BM_Row", type: 'number', isRequired: true, editable: true },
			tagId: { label: "Tag Id", type: 'number', isRequired: true, editable: true },
			route: { label: "Route Desc", type: 'text', isRequired: true, editable: true },
			pinDia: { label: "Pin Dia", type: 'number', isRequired: true, editable: true },
			orderId: { label: "order Id", type: 'number' },
			threads: { label: "threads" },
			id: { label: "id", type: 'number' },
			imageProperties: { label: "image properties" },
			tagDescription: { label: "Tag description" },
			magnifications: { label: 'Magnification' },
			halfThread: {label: 'Half threads'},
			fullThread: {label: 'Full Threads'}



			// "dispatchStatus": 1,
			// "vehicleFitment": 1,
			// "vehicleFitmentDetails": "smncbwajkcweucwecbdgweudgie",
			// "driverFitment": 1,
			// "driverFitmentDetails": "cwyxbet2iej23ehbwdqwi7d632",

			// noOfTags:{label: "No of Tags", type: 'text', isRequired: true, editable: true },
			// totalWeight:{label: "Total Weight (Kg.)", type: 'text', isRequired: true, editable: true }

		};

		// 	"tagDescription": "tagDescriprion",
		// "imageProperties": "req.body.imageProperties",

		const selectedColumns = ['partItemId', 'orderItemId', 'barMarkId', 'diameter', 'noOfPieces', 'totalNoOfPieces', 'shapeId', 'image', 'orderItemName', 'gradeName', 'bundleQuantity', 'inventoryLength', 'productionLength', 'inventoryWeight', 'productionWeight', 'bmRow', 'tagId', 'route', 'pinDia', 'orderId', 'id', 'imageProperties', 'tagDescription', 'magnifications', 'threads','halfThread','fullThread'];
		const selectedColumns2 = ['shapeId', 'image', 'orderItemName', 'tagId', 'bmRow', 'gradeName', 'diameter', 'pinDia', 'noOfPieces', 'totalNoOfPieces', 'inventoryLength', 'productionLength', 'inventoryWeight', 'productionWeight', 'partItemId', 'orderItemId', 'orderId', 'tagDescription', 'threads'];
		const partItemShowColumns = ['partItemId', 'orderItemId', 'barMarkId', 'diameter', 'noOfPieces', 'totalNoOfPieces', 'shapeId', 'image', 'orderItemName', 'gradeName', 'threads', 'bundleQuantity', 'inventoryLength', 'productionLength', 'inventoryWeight', 'productionWeight', 'tagDescription', 'bmRow', 'tagId', 'route', 'pinDia', 'orderId', 'id'];

		const columns = {}, columns2 = {};

		partItemShowColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		selectedColumns2.forEach((columnName) => {
			columns2[columnName] = columnLabels[columnName];
		});



		const Data = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		})

		let labeledData = []

		if (toSort === true) {
			labeledData = Data.sort((a, b) => a.orderItemId - b.orderItemId)
		} else labeledData = Data

		return { columns, labeledData, columns2 };
	} catch (error) {
		throw error;
	}
});

export const postPartItems = createAsyncThunk('data/postPartItems', async (newRow) => {
	try {
		const response = await api.addPartItems(newRow)
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
		throw error;
	}
});

export const deletePartItems = createAsyncThunk('data/deletePartItems', async (id) => {
	try {
		await api.deletePartItems(id);
		return id;
	} catch (error) {
		throw error.response.data;
	}
});

export const updatePartItems = createAsyncThunk('data/updatePartItems', async ({ id, type }) => {
	try {
		console.log(type, id);
		const response = await api.updatePartItems(id, type);
		const data = response.data;
		console.log('Update Success');
		return { data, id, type };
	} catch (error) {
		throw error;
	}
});

export const postExcelBbs = createAsyncThunk('data/postExcelBbs', async ({newRow, cleanTags}) => {
	try {
		console.log(newRow);
		const response = await api.addExcelBbs({newRow, cleanTags})
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
		throw error;
	}
});

export const editBmRow = createAsyncThunk('data/editBmRow', async (type) => {
	try {
		console.log(type);
		const response = await api.updateBmRow( type);
		const data = response.data;
		console.log('Update Success');
		return { data, type };
	} catch (error) {
		throw error;
	}
});

const partItemsSlice = createSlice({
	name: 'partItems',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(resetState, () => initialState)
			.addCase(fetchPartItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				// console.log('done');
				state.columns = action.payload.columns;
				state.columns2 = action.payload.columns2;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchPartItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(fetchPartItems.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(fetchPartItems2.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				// console.log('done');
				state.columns = action.payload.columns;
				state.columns2 = action.payload.columns2;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchPartItems2.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(fetchPartItems2.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postPartItems.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postPartItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				state.labeledData.push(action.payload);
				console.log(action.payload)
				toast.success('Tag added successfully!');
			})
			.addCase(postPartItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to add Tag');
			})
			.addCase(postExcelBbs.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postExcelBbs.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				toast.success('BBS uploaded added successfully!');
				console.log(action.payload)
			})
			.addCase(postExcelBbs.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(updatePartItems.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(updatePartItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				const index = state.labeledData.find(item => item.partItemId === action.payload.id);
				if (index) {
					const updatedItem = { partItemId: action.payload.id, ...action.payload.type };
					console.log(updatedItem);
					state.labeledData = state.labeledData.map(item => {
						if (item.orderId === action.payload.id) {
							return updatedItem;
						}
						return item;
					})
				}
				toast.success('Tag updated sucessfully');
			})
			.addCase(updatePartItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to update Tag');
			})
			.addCase(deletePartItems.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(deletePartItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				const deletedId = action.payload;
				console.log(action.payload);
				state.labeledData = state.labeledData.filter((data) => `${data.partItemId}` !== `${deletedId}`);
				toast.success('Tag deleted sucessfully');
			})
			.addCase(deletePartItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error(state.error);
			});
	},
});

export default partItemsSlice.reducer;

//End of File