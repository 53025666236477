import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../utils/Button";
import validator from "validator";
import { toast } from "react-toastify";
import Password from "./Password";
import { editUser, fetchUser, postUser } from "../../../redux-tookit/slices/userSlice";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/action/CloseFilter.svg";
import MultiSelect from "../../../utils/MultiSelect";

const AddUser = ({
  isAdding,
  setIsAdding,
  role,
  scData,
  emailList,
  userNoList,
  isEditing = false,
  setIsEditing,
  action = 'add',
  editData = []
}) => {
  const dispatch = useDispatch();
  const [addNew, setAddNew] = useState({});
  const [email, setEmail] = useState("");
  const [valid, setValid] = useState({
    email: true,
    password: true,
    confirmPassword: true,
  });
  const [emailError, setEmailError] = useState("");
  const [userNoError, setUserNoError] = useState("");
  const [loading, setLoading] = useState(false);
  const regions = ["North", "South", "East", "West"];

  const bufferToBase64 = (buffer) => {
    try {
      // console.log(buffer);

      const byteArray = new Uint8Array(buffer);
      const decoder = new TextDecoder('utf-8');

      let result = '';
      for (let i = 0; i < byteArray.length; i += 1024) {
        const chunk = byteArray.subarray(i, i + 1024);
        result += decoder.decode(chunk);
      }
      console.log('this ran');
      // console.log(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  const [image, setImage] = useState(
    bufferToBase64(editData?.imageUrl?.data) ||
    undefined
  );

  const handleIsEdit = useCallback(() => {
    setAddNew(editData)
  }, [editData])

  useEffect(() => {
    if (action !== 'add')
      handleIsEdit()
  }, [editData, action])

  const checkEmail = (value) => {
    if (validator.isEmail(value)) {
      if (!emailList.includes(value)) {
        setEmailError("");
        setValid((prev) => {
          return { ...prev, email: true };
        });
      } else {
        setEmailError("Email already existing");
        setValid((prev) => {
          return { ...prev, email: false };
        });
      }
    } else {
      setEmailError("Enter a valid email");
      setValid((prev) => {
        return { ...prev, email: false };
      });
    }
    if (value === "") {
      setEmailError("");
    }
  };

  const checkUserNo = (value) => {
    let match = false;
    if (value.match(/^\d{0,6}$/)) match = true;
    if (match === true) {
      if (!userNoList.includes(value)) {
        setUserNoError("");
        setValid((prev) => {
          return { ...prev, userNo: true };
        });
        setAddNew({ ...addNew, userNo: value });
      } else {
        setUserNoError("User No. already existing");
        setValid((prev) => {
          return { ...prev, userNo: false };
        });
        setAddNew({ ...addNew, userNo: value });
      }
    }

    if (value === "") {
      setUserNoError("");
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    let errorCount = 0;
    if (valid.email === false) {
      errorCount += 1;
      toast.error("Email invalid");
    }
    if (valid.password === false) {
      errorCount += 1;
      toast.error("Password invalid");
    }
    if (valid.confirmPassword === false) {
      errorCount += 1;
      toast.error("Password does not match");
    }
    if (valid.userNo === false) {
      errorCount += 1;
      toast.error("User No. already exists");
    }
    const newRow = {
      serviceCenterId: addNew.serviceCenterId,
      userType: addNew.userType,
      roleId: addNew.roleId,
      userName: addNew.userName,
      userEmail: addNew.email,
      password: addNew.password,
      imageUrl: image,
      userNo: addNew.userNo,
      region: addNew.region,
    };
    if (errorCount * 1 === 0) {
      dispatch(postUser(newRow)).then((res) => {
        if (res.payload) {
          setIsAdding(false);
          dispatch(fetchUser());
          toast.success("User created successfully");
        }
      });
    }
  };

  const handleFileChange = (event) => {
    const file = event;
    let base64Image = "";
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        console.log(reader.result);
        base64Image = reader.result;
      };
      reader.readAsDataURL(file);
    }
    return base64Image;
  };

  const compressImage = (file, quality) => {
    console.log("Quality:", quality);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.onload = function () {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, image.width, image.height);
          canvas.toBlob(
            function (blob) {
              resolve(blob);
            },
            "image/jpeg",
            quality
          );
          // canvas.toDataURL('image/jpeg')
        };
        image.src = event.target.result;
      };
      reader.onerror = function (error) {
        reject(error);
        console.log(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const imageChange = async (file) => {
    if (file) {
      try {
        if (file.type.startsWith("image/")) {
          console.log(file);
          setLoading(true);
          const compressedBlob = await compressImage(
            file,
            file.size > 1024 * 1024 ? (1024 * 1024) / file.size : 1
          );
          // const compressedBlob = await imageToJPEG(file)
          console.log(compressedBlob);
          setImage(handleFileChange(compressedBlob));
          setLoading(false);
        } else {
          toast.error("Selected file is not an image");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const removeImage = () => {
    const fileInput = document.getElementById("imageUpload");
    if (fileInput) {
      fileInput.value = null;
      setImage(null);
    }
  };

  console.log(image);

  const handleEdit = async (e) => {
    e.preventDefault()
    const id = editData.userId
    const updatedData = {
      serviceCenterId: addNew.serviceCenterId,
      userType: addNew.userType,
      roleId: addNew.roleId,
      userName: addNew.userName,
      userEmail: addNew.email,
      password: addNew.password,
      imageUrl: image,
      userNo: addNew.userNo,
      region: addNew.region,
    };
    dispatch(editUser({ id, updatedData })).then((res) => {
      if (res.payload) {
        setIsEditing(false);
        dispatch(fetchUser());
        toast.success("User updated successfully");
      }
    });
  }

  return (
    <div>
      <form onSubmit={handleSave}>
        {isAdding && (
          <div>
            <div className="AddContainer">
              <div
                className="form-container"
                style={{ width: "70%", position: "absolute" }}
              >
                <div className="head">Add User</div>
                <div className="table-container user">
                  <div className="filter">
                    <label htmlFor="userName">User Name</label>
                    <input
                      type="text"
                      id="userName"
                      required
                      className="table-input"
                      onChange={(e) =>
                        setAddNew({ ...addNew, userName: e.target.value })
                      }
                    />
                  </div>
                  <div className="filter">
                    <label htmlFor="serviceCenter">Service Center</label>
                    <select
                      required
                      id="serviceCenter"
                      onChange={(e) =>
                        setAddNew({
                          ...addNew,
                          serviceCenterId: e.target.value,
                        })
                      }
                    >
                      <option value="">-Select-</option>
                      {scData.map((item) => (
                        <option value={item.serviceCenterId}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    {/* <MultiSelect options={scData.map(item => {return {value: item.serviceCenterId, label: item.name} })}  /> */}
                  </div>

                  <div className="filter">
                    <label htmlFor="userType">
                      User Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="userType"
                      required
                      className="table-input"
                      onChange={(e) =>
                        setAddNew({ ...addNew, userType: e.target.value })
                      }
                    />
                  </div>
                  <div className="filter">
                    <label htmlFor="Role">
                      Role <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      name="role"
                      required
                      id="Role"
                      onChange={(e) =>
                        setAddNew({ ...addNew, roleId: e.target.value })
                      }
                    >
                      <option value="">-Select-</option>
                      {role.map((item) => (
                        <option value={item.roleId}>{item.roleName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="filter">
                    <label htmlFor="Region">
                      Region <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      name="region"
                      required
                      id="region"
                      onChange={(e) =>
                        setAddNew({ ...addNew, region: e.target.value })
                      }
                    >
                      <option value="">-Select-</option>
                      {regions.map((region, index) => (
                        <option key={index} value={region}>
                          {region}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="filter">
                    <label htmlFor="userNo">
                      User No. <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="userNo"
                      required
                      className={`table-input `}
                      value={addNew.userNo}
                      onKeyDown={(evt) =>
                        evt.key === "e" ||
                        (evt.key === "-" && evt.preventDefault())
                      }
                      onChange={(e) => {
                        checkUserNo(e.target.value);
                      }}
                    />
                    <p className="text-danger">{userNoError}</p>
                  </div>

                  <div className="filter">
                    <label htmlFor="email">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      required
                      className={`table-input `}
                      onChange={(e) => {
                        checkEmail(e.target.value);
                        setAddNew({ ...addNew, email: e.target.value });
                      }}
                    />
                    <p className="text-danger">{emailError}</p>
                  </div>

                  <Password
                    valid={valid}
                    setValid={setValid}
                    setAddNew={setAddNew}
                  />
                  <div className="filter">
                    <label htmlFor="imageUpload">User Image</label>
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      className="image-upload-button"
                      onChange={(e) => imageChange(e.target.files[0])}
                    />
                    <label htmlFor="imageUpload" className="custom-button">
                      Choose File
                    </label>
                    {loading && <div className="circle-loader2"></div>}
                    <div className="imagePreview">
                      {image && (
                        <>
                          <img src={image} alt="cannot load" />
                          <CloseIcon
                            title="Remove"
                            className="imageRemove"
                            onClick={() => removeImage()}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Button type="submit" label="Save" className="blue" />
                  <Button
                    onClick={() => {
                      setIsAdding(false);
                    }}
                    label="Cancel"
                    className="blue"
                  />
                </div>
              </div>
            </div>
            <div
              className="bg-dark"
              style={{ backgroundColor: "rgb(0,0,0,0.6)" }}
            ></div>
          </div>
        )}
      </form>
      <form onSubmit={handleEdit}>
        {isEditing && (
          <div>
            <div className="AddContainer">
              <div
                className="form-container"
                style={{ width: "70%", position: "absolute" }}
              >
                <div className="head">Edit User</div>
                <div className="table-container user">
                  <div className="filter">
                    <label htmlFor="userName">User Name</label>
                    <input
                      type="text"
                      id="userName"
                      required
                      value={addNew.userName}
                      className="table-input"
                      onChange={(e) =>
                        setAddNew({ ...addNew, userName: e.target.value })
                      }
                    />
                  </div>
                  <div className="filter">
                    <label htmlFor="serviceCenter">Service Center</label>
                    <select
                      required
                      id="serviceCenter"
                      value={addNew.serviceCenterId}
                      onChange={(e) =>
                        setAddNew({
                          ...addNew,
                          serviceCenterId: e.target.value,
                        })
                      }
                    >
                      <option value="">-Select-</option>
                      {scData.map((item) => (
                        <option value={item.serviceCenterId}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="filter">
                    <label htmlFor="userType">
                      User Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="userType"
                      required
                      value={addNew.userType}
                      className="table-input"
                      onChange={(e) =>
                        setAddNew({ ...addNew, userType: e.target.value })
                      }
                    />
                  </div>
                  <div className="filter">
                    <label htmlFor="Role">
                      Role <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      name="role"
                      required
                      value={addNew.roleId}
                      id="Role"
                      onChange={(e) =>
                        setAddNew({ ...addNew, roleId: e.target.value })
                      }
                    >
                      <option value="">-Select-</option>
                      {role.map((item) => (
                        <option value={item.roleId}>{item.roleName}</option>
                      ))}
                    </select>
                  </div>
                  <div className="filter">
                    <label htmlFor="Region">
                      Region
                    </label>
                    <select
                      name="region"

                      value={addNew.region}
                      id="region"
                      onChange={(e) =>
                        setAddNew({ ...addNew, region: e.target.value })
                      }
                    >
                      <option value="">-Select-</option>
                      {regions.map((region, index) => (
                        <option key={index} value={region}>
                          {region}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="filter">
                    <label htmlFor="userNo">
                      User No. <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="userNo"
                      required
                      className={`table-input `}
                      value={addNew.userNo}
                      onKeyDown={(evt) =>
                        evt.key === "e" ||
                        (evt.key === "-" && evt.preventDefault())
                      }
                      onChange={(e) => {
                        checkUserNo(e.target.value);
                      }}
                    />
                    <p className="text-danger">{userNoError}</p>
                  </div>

                  <div className="filter">
                    <label htmlFor="email">
                      Email <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="email"
                      required
                      value={addNew.email}
                      className={`table-input `}
                      onChange={(e) => {
                        checkEmail(e.target.value);
                        setAddNew({ ...addNew, email: e.target.value });
                      }}
                    />
                    <p className="text-danger">{emailError}</p>
                  </div>

                  {/* <Password
                    valid={valid}
                    setValid={setValid}
                    setAddNew={setAddNew}
                  /> */}
                  <div className="filter">
                    <label htmlFor="imageUpload">User Image</label>
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      className="image-upload-button"
                      onChange={(e) => imageChange(e.target.files[0])}
                    />
                    <label htmlFor="imageUpload" className="custom-button">
                      Choose File
                    </label>
                    {loading && <div className="circle-loader2"></div>}
                    <div className="imagePreview">
                      {image && (
                        <>
                          <img src={image} alt="cannot load" />
                          <CloseIcon
                            title="Remove"
                            className="imageRemove"
                            onClick={() => removeImage()}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Button type="submit" label="Save" className="blue" />
                  <Button
                    onClick={() => {
                      if (action === 'add')
                        setIsAdding(false);
                      else setIsEditing(false);
                    }}
                    label="Cancel"
                    className="blue"
                  />
                </div>
              </div>
            </div>
            <div
              className="bg-dark"
              style={{ backgroundColor: "rgb(0,0,0,0.6)" }}
            ></div>
          </div>
        )}
      </form>
    </div>
  );
};



export default AddUser;
