import React from 'react'
import { useLocation } from 'react-router-dom';
import CuttingSequence from './CuttingSequence';
import { PDFViewer } from '@react-pdf/renderer';

const PdfContainer = () => {
    const location = useLocation();

    const planId = (location.state?.planCombId)

    const sequence = (location.state?.result)

    const offcutT = (location.state?.offcutT)

    const offcutP = (location.state?.offcutP)

    const scrapT = (location.state?.scrapT)

    const scrapP = (location.state?.scrapP)

  return (
    <div style={{ border: '2px solid black', height: '100%' }}>


            <PDFViewer style={{ height: '100%', width: '100%' }}>
              <CuttingSequence sequence={sequence} planId={planId} offcutT={offcutT} offcutP={offcutP} scrapT={scrapT} scrapP={scrapP}/>
            </PDFViewer>



    </div>
  )
}

export default PdfContainer
