// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plId{
    margin-top: 4px;
    border: 2px solid #d9d9d9;
    border-radius: 4px;
    height: 5vh;
    width: 20vw;
    box-shadow: 0px 0px 4px #00000040;
}

@media only screen and (min-width: 400px) and (max-width: 768px) {
    .plId{
        margin-top: 4px;
        border: 2px solid #d9d9d9;
        border-radius: 4px;
        height: 5vh;
        width: 35vw;
        box-shadow: 0px 0px 4px #00000040;
    }
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/Production.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,WAAW;IACX,WAAW;IACX,iCAAiC;AACrC;;AAEA;IACI;QACI,eAAe;QACf,yBAAyB;QACzB,kBAAkB;QAClB,WAAW;QACX,WAAW;QACX,iCAAiC;IACrC;EACF","sourcesContent":[".plId{\r\n    margin-top: 4px;\r\n    border: 2px solid #d9d9d9;\r\n    border-radius: 4px;\r\n    height: 5vh;\r\n    width: 20vw;\r\n    box-shadow: 0px 0px 4px #00000040;\r\n}\r\n\r\n@media only screen and (min-width: 400px) and (max-width: 768px) {\r\n    .plId{\r\n        margin-top: 4px;\r\n        border: 2px solid #d9d9d9;\r\n        border-radius: 4px;\r\n        height: 5vh;\r\n        width: 35vw;\r\n        box-shadow: 0px 0px 4px #00000040;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
