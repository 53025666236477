import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/API";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  columns: {},
  labeledData: [],
  columns2: {},
  labeledData2: [],
  loading: "idle",
  error: null,
};

export const fetchProdHistDiaWise = createAsyncThunk(
  "data/fetchProdHistDiaWise",
  async () => {
    try {
      let response = {};
      response = await api.listProdHistDiaWise();

      const json = response.data;
      const columnLabels = {
        productionWeight: { label: "Production Weight" },
        productionLength: { label: "Production Length" },
        gradeName: { label: "Grade Name" },
        diameter: { label: "Diameter" },
      };

      const selectedColumns = [
        "diameter",
        "productionWeight",
        "productionLength",
        "gradeName",
      ];

      const columns = {};
      selectedColumns.forEach((columnName) => {
        columns[columnName] = columnLabels[columnName];
      });

      const labeledData = json.rows.map((row) => {
        const rowData = {};
        selectedColumns.forEach((columnName) => {
          rowData[columnName] = row[columnName];
        });
        return rowData;
      });

      return { columns, labeledData };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchProdRecordOrderWise = createAsyncThunk(
  "data/fetchProdRecordOrderWise",
  async () => {
    try {
      const response = await api.listProdRecordOrderWise();
      const json = response.data;
      console.log(json);
      // const response1 = await api.listOrder();
      // const json1 = response1.data;
      // console.log(json1)
      // const orderData = new Map();
      // json1.rows.forEach(obj => orderData.set(obj.orderId, obj.combId))

      // console.log(orderData)

      const labeledData1 = json.rows.flatMap((row) =>
        row.OrderItems.flatMap((orderItem, i1) =>
          orderItem.PartItems.map((partItem, i2) => ({
            orderId: i1 === 0 && i2 === 0 ? row.combId : "",
            orderRemarks: i1 === 0 && i2 === 0 ? row.orderRemarks : "",
            soldToParty: i1 === 0 && i2 === 0 ? row.soldToParty : "",
            orderItemName: i2 === 0 ? orderItem.orderItemName : "",
            totalNoOfPieces: partItem.totalNoOfPieces,
            productionWeight: partItem.productionWeight,
            remainingPieces: partItem.remainingPieces,
            processedPieces: partItem.processedPieces,
          }))
        )
      );

      const columnLabels = {
        orderId: { label: "Order Id" },
        orderRemarks: { label: "Order Remark" },
        soldToParty: { label: "Customer Name" },
        orderItemName: { label: "Element Name" },
        totalNoOfPieces: { label: "Total Pieces" },
        productionWeight: { label: "Production Weight(Kg)" },
        remainingPieces: { label: "Remaining Pieces" },
        processedPieces: { label: "Processed Pieces" },
      };

      const selectedColumns = [
        "orderId",
        "orderRemarks",
        "soldToParty",
        "orderItemName",
        "totalNoOfPieces",
        "productionWeight",
        "remainingPieces",
        "processedPieces",
      ];

      const columns = {};
      selectedColumns.forEach((columnName) => {
        columns[columnName] = columnLabels[columnName];
      });

      const labeledData = labeledData1.map((row) => {
        const rowData = {};
        selectedColumns.forEach((columnName) => {
          rowData[columnName] = row[columnName];
        });
        return rowData;
      });
      console.log(labeledData);
      return { columns, labeledData, json };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchOrderProgress = createAsyncThunk(
  "data/fetchOrderProgress",
  async () => {
    try {
      ///dashboards/listOrdersProduction
      let response = {};
      response = await api.listOrderProgress();

      const json = response.data;
      const columnLabels = {
        productionWeight: { label: "Production Weight" },
        productionLength: { label: "Production Length" },
        gradeName: { label: "Grade Name" },
        diameter: { label: "Diameter" },
      };

      const selectedColumns = [
        "diameter",
        "productionWeight",
        "productionLength",
        "gradeName",
      ];

      const columns = {};
      selectedColumns.forEach((columnName) => {
        columns[columnName] = columnLabels[columnName];
      });

      const labeledData = json.rows;

      return { columns, labeledData };
    } catch (error) {
      throw error;
    }
  }
);
export const fetchOrderDateComparision = createAsyncThunk(
  "data/fetchOrderDateComparision",
  async () => {
    try {
      let response = {};
      response = await api.listOrderDateComparision();

      const json = response.data;
      console.log(json);
      const columnLabels = {
        productionWeight: { label: "Production Weight" },
        productionLength: { label: "Production Length" },
        gradeName: { label: "Grade Name" },
        diameter: { label: "Diameter" },
      };

      const selectedColumns = [
        "diameter",
        "productionWeight",
        "productionLength",
        "gradeName",
      ];

      const columns = {};
      selectedColumns.forEach((columnName) => {
        columns[columnName] = columnLabels[columnName];
      });

      const labeledData = json.rows;

      return { columns, labeledData };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchDailyProduction = createAsyncThunk(
  "data/fetchDailyProduction",
  async () => {
    try {
      let response = {};
      response = await api.listDailyProduction();

      const json = response.data;
      const columnLabels = {
        productionWeight: { label: "Production Weight" },
        productionLength: { label: "Production Length" },
        gradeName: { label: "Grade Name" },
        diameter: { label: "Diameter" },
      };

      const selectedColumns = [
        "diameter",
        "productionWeight",
        "productionLength",
        "gradeName",
      ];

      const columns = {};
      selectedColumns.forEach((columnName) => {
        columns[columnName] = columnLabels[columnName];
      });

      const labeledData = json.rows;

      return { columns, labeledData };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLengthWiseQty = createAsyncThunk(
  "data/fetchLengthWiseQty",
  async () => {
    try {
      ///dashboards/listOrdersProduction
      let response = {};
      response = await api.listLengthWiseQty();

      const json = response.data;
      const columnLabels = {
        productionWeight: { label: "Production Weight" },
        productionLength: { label: "Production Length" },
        gradeName: { label: "Grade Name" },
        diameter: { label: "Diameter" },
      };

      const selectedColumns = [
        "diameter",
        "productionWeight",
        "productionLength",
        "gradeName",
      ];

      const columns = {};
      selectedColumns.forEach((columnName) => {
        columns[columnName] = columnLabels[columnName];
      });

      const labeledData = json.rows.map((row) => {
        const rowData = {};
        selectedColumns.forEach((columnName) => {
          rowData[columnName] = row[columnName];
        });
        return rowData;
      });

      return { columns, labeledData, json };
    } catch (error) {
      throw error;
    }
  }
);

export const fetchOrderloadinglist = createAsyncThunk(
  "data/fetchOrderloadinglist",
  async () => {
    try {
      let response = {};
      response = await api.listOrderloadinglist();

      const json = response.data;
      const columnLabels = {
        productionWeight: { label: "Production Weight" },
        productionLength: { label: "Production Length" },
        gradeName: { label: "Grade Name" },
        diameter: { label: "Diameter" },
      };

      const selectedColumns = [
        "diameter",
        "productionWeight",
        "productionLength",
        "gradeName",
      ];

      const columns = {};
      selectedColumns.forEach((columnName) => {
        columns[columnName] = columnLabels[columnName];
      });

      const labeledData = json.rows.map((row) => {
        const rowData = {};
        selectedColumns.forEach((columnName) => {
          rowData[columnName] = row[columnName];
        });
        return rowData;
      });

      return { columns, labeledData };
    } catch (error) {
      throw error;
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchProdHistDiaWise.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchProdHistDiaWise.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      })
      .addCase(fetchProdRecordOrderWise.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.columns2 = action.payload.columns;
        state.labeledData2 = action.payload.labeledData;
      })
      .addCase(fetchProdRecordOrderWise.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      })

      .addCase(fetchOrderProgress.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.columns2 = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchOrderProgress.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      })
      .addCase(fetchOrderDateComparision.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.columns2 = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchOrderDateComparision.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      });
  },
});

export default dashboardSlice.reducer;
//End of File
