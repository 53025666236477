import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceCenter } from '../../../redux-tookit/slices/serviceCenterSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchProcessCenter, postProcessCenter } from '../../../redux-tookit/slices/processCenterSlice';
import Button from '../../../utils/Button';
import { fetchFacilities, postFacilities, updateFacility } from '../../../redux-tookit/slices/facilityMasterSlice';
import { postCustomers, updateCustomer } from '../../../redux-tookit/slices/customerSlice';
import { toast } from 'react-toastify';
import validator from 'validator';

const AddCustomer = (props) => {
    const dispatch = useDispatch();
    const columns = useSelector((state) => state.data.columns);
    const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData, emailList } = props;

    const columnKeys = Object.keys(columns);
    const [newRow, setNewRow] = useState({customerType:'sold to party'});
    

    const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        if (validator.isEmail(newRow.email)) {
            if (!emailList.includes(newRow.email)) {
                dispatch(postCustomers({ ...newRow }));
                console.log(newRow);
                setIsAdding(false);
                setNewRow({});
            } else {
                toast.error('Email already existing')
            }
        } else {
            toast.error('Enter a valid email')
        }
    };


    const onChangeHandle = (column, e, act) => {
        let match = false
        if (column === 'pinCode') {
            if (e.target.value.match(/^[1-9]{0,1}\d{0,5}$/)) match = true
        } else if (column === 'phoneNo') {
            if (e.target.value.match(/^\d{0,10}$/)) match = true
        } else if (column === 'fax') {
            if (e.target.value.match(/^\d{0,12}$/)) match = true
        } else {
            match = true
        }
        if (match === true) {
            if (act === 'add') {
                setNewRow({ ...newRow, [column]: e.target.value })
            } else setEditData({ ...editData, [column]: e.target.value })
        }

    }


    const handleUpdate = async (e) => {

        e.preventDefault();
        if (validator.isEmail(editData.email)) {
            if (!emailList.includes(newRow.email)) {
                const id = editData.customerId;

                console.log(id);
                console.log(editData);
                
                let type = {}
                Object.keys(editData).map((el) => { if (el !== 'customerId') type = { ...type, [el]: editData[el] } })

                console.log(type);
                dispatch(updateCustomer({ id, type }))
                setIsEditing(false);
            } else {
                toast.error('Email already existing')
            }
        } else {
            toast.error('Enter a valid email')
        }
    }


    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isAdding &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Add Customer</div>
                            <div className="table-container">


                                {columnKeys.map((column) => (
                                    column !== 'customerId' && column !== 'customerType'?
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                            <label>
                                                {columns[column].label}{column === 'invoiceWeight' ? '(T)' : ''}
                                                {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                            </label>
                                            <input
                                                key={column}
                                                type={columns[column].type}
                                                className='table-input'
                                                value={newRow[column] || ''}
                                                onChange={(e) => onChangeHandle(column, e, 'add')}
                                                required={columns[column].isRequired ? true : false}
                                                pattern={columns[column].pattern}

                                            /></div>
                                        )
                                        :
                                        ""
                                ))}
                                {/* setNewRow({ ...newRow, [column]: e.target.value }) */}
                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => { setIsAdding(false); setNewRow({}) }} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Edit Customer</div>
                            <div className="table-container">


                                {columnKeys.map((column) => (
                                    columns[column].editable === true && column !== 'customerType'?
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                            <label>{columns[column].label}<span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                key={column}
                                                type={columns[column].type}
                                                className='table-input'
                                                value={editData[column]}
                                                onChange={(e) => onChangeHandle(column, e, 'edit')}
                                                required={columns[column].isRequired ? true : false}
                                                pattern={columns[column].pattern}

                                            /></div>
                                        )
                                        :
                                        ""
                                ))}

                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => { setIsEditing(false); setNewRow({}) }} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
        </div>
    )
}

export default AddCustomer