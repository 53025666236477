import React, { useEffect, useRef, useState } from 'react'
import '../assets/styles/BBSExcel.css'
import Button from '../utils/Button';
import * as XLSX from "xlsx";
import ExcelConverter from './ExcelComponents/ExcelConverter';
import ExcelTemplate from '../assets/files/BBSSheet_template.xlsm'
import { ToastContainer, toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrderItems } from '../redux-tookit/slices/orderItemsSlice';
import { saveAs } from 'file-saver';


const BBSExcel = (props) => {

    const { isOpen, onClose, orderId, selectedRowIndex, combId } = props;
    console.log(orderId);

    const dispatch = useDispatch();

    const [excelJson, setExcelJson] = useState([])
    const [process, setProcess] = useState(false)
    const [isConverter, setIsConverter] = useState(false)
    const [cleanTags, setCleanTags] = useState(false)
    const handleUploadComplete = () => {
        setExcelJson([])
        setIsConverter(false)
        onClose()
    }
    const elementData = (useSelector((state) => state.orderItems.labeledData))

    const loaderBar = 0.5


    const excelToJson = (e) => {
        return new Promise((resolve, reject) => {

            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const data = event.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    resolve(jsonData);
                } catch (error) {
                    toast.error(`${error}`)
                    e.target.value = null
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsBinaryString(e.target.files[0]);
        });
    }

    const handleFileUpload = (e) => {
        // console.log(e.target.files[0].type);
        // console.log(e.target.value);
        setProcess(true)
        if (!e.target.value.endsWith('.xlsm')) {
            toast.error('File not in excel format')
            e.target.value = null
            setProcess(false)
            return
        }
        excelToJson(e)
            .then((jsonData) => {
                console.log(jsonData); // Do something with the JSON data
                if (jsonData.length === 0) {
                    toast.error('Excel is empty.')
                    e.target.value = null
                }
                setExcelJson(jsonData);
                setProcess(false)
                // setIsConverter(true)
            })
            .catch((error) => {
                setProcess(false)
                console.error('Error converting Excel to JSON:', error);
            });
    }

    // for reading and downloading from saved excel

    const readExcelFile = async () => {
        const response = await fetch(ExcelTemplate);
        const data = await response.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'array', bookType: 'xlsm' });
        return workbook;
    };

    const modifyExcelFile = (workbook) => {
        const sheet = workbook.Sheets[workbook.SheetNames[0]];
        // Assuming the order ID should be placed in cell A1
        sheet['B2'] = { v: combId};
        let col = 1;
        console.log(elementData);
        elementData.forEach((item, index) => {
            // Convert column number to letter (e.g., 1 -> A, 2 -> B)
            const colLetter = XLSX.utils.encode_col(col + index);

            // Set element in row 3
            sheet[`${colLetter}3`] = { v: item.orderItemName };

            // Set set in row 4
            sheet[`${colLetter}4`] = { v: item.noOfSets };
        });

    };

    const saveExcelFile = (workbook) => {
        const wbout = XLSX.write(workbook, { bookType: 'xlsm', type: 'array' });
        const blob = new Blob([wbout], { type: 'application/vnd.ms-excel.sheet.macroEnabled.12'  });
        saveAs(blob, `BBSSheet_${combId}.xlsm`)
        // const url = URL.createObjectURL(blob);
        // const a = document.createElement('a');
        // a.href = url;
        // a.download = `BBSSheet_${combId}.xlsm`;
        // document.body.appendChild(a);
        // a.click();
        // document.body.removeChild(a);
    };


    const handleDownload = async () => {
        const workbook = await readExcelFile();
        // modifyExcelFile(workbook);
        saveExcelFile(workbook);
    };

    useEffect(() => {
        if(orderId!=='')
        dispatch(fetchOrderItems(orderId))
    }, [orderId])


    return (
        isOpen && (<div className='popup'>


            {!isConverter &&
                <div className="popup-content">

                    <div style={{ color: "#138EE0", fontWeight: "600" }}>
                        BBS Excel Sheet for Order Id -{combId}
                    </div>

                    <div className="card1">
                        <div className='hr'>Download BBS Excel Sheet</div>
                        <hr></hr>
                        <div>
                            Click to download Excel sheet for BBS entry

                            <a
                                href={ExcelTemplate}
                                download={`BBSExcel_${combId}`}
                                target="_blank"
                                rel="noreferrer"
                                style={{ textDecoration: 'none' }}
                            >

                                <Button label="Download" className={`blue ${selectedRowIndex === null || selectedRowIndex === undefined ? 'disabled' : ''}`} />
 
                            </a>

                        </div>
                    </div>

                    <div className="card1">
                        <div>Upload BBS Excel Sheet</div>
                        <hr></hr>
                        <input type="file" accept='.xlsm' onChange={(e) => handleFileUpload(e)} />
                        {process &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                                <div className="circle-loader2" style={{ position: 'relative' }}></div>

                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    Extracting
                                    <div className="point1">.</div>
                                    <div className="point2">.</div>
                                    <div className="point3">.</div>
                                </div>

                            </div>
                        }
                        <div>

                            <input
                                type="checkbox"
                                checked={cleanTags}
                                onClick={()=>{setCleanTags(!cleanTags)}}
                                name="cleanPrev"
                                id="cleanPrev"
                            />
                            Clean previous tag

                        </div>
                        <Button label='Upload' className='blue' onClick={() => setIsConverter(true)} disabled={excelJson} message='Select a file for upload' />
                    </div>
                    <div className="button">
                        <Button label="Close" className="blue" onClick={onClose} />
                    </div>



                </div>
            }
            {isConverter && <ExcelConverter dataArray={excelJson} combId={combId} orderId={orderId} onClose={handleUploadComplete} cleanTags={cleanTags}/>}

        </div>)
    )
}

export default BBSExcel