import React, { useState } from 'react';
import '../assets/styles/GenericTable.css';

const GenericTableShow = ({ columns, data}) => {
  const columnKeys=Object.keys(columns);


  return (
    <div className="table-container" style={{width:'100%', minHeight:'500px'}} >
      {data.length!==0?<table className="generic-table" style={{width:'100%'}} >
        <thead className='stick'>
          <tr>
            
            {columnKeys.map((key) => (
              <th key={key} className="table-header">
                {columns[key].label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody >
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className='t-data' style={{borderBottom:'1px solid grey'}}>
              {columnKeys.map((data,key) => (
                data==='image'?
                <td><img src={String.fromCharCode.apply(null, new Uint8Array(row[data].data))} alt="shape" style={{width:'150px', height:'100px'}}/></td>:
                <td key={key}>{row[data]}</td>
              ))}
            </tr>
          ))}
          </tbody>
        </table>:'No part items to show'}
      
   </div>
  );
};

export default GenericTableShow;