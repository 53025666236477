// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ListOfShapes {
    display: block;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.ListShapeContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90%;
    box-sizing: border-box;
    border: 3px solid #ebebeb;
    border-radius: 10px;
    padding: 10px;
    box-shadow: rgb(73, 73, 73) 1px 1px 5px;
    overflow: scroll;
}

.TypeHeader {
    display: flex;

    width: 100%;
    box-sizing: border-box;
    background-color: #138EE0;
    padding: 5px;
    color: white;
    justify-content: center;
    border-radius: 5px 5px 0 0;

}

.TypeContainer {
    border-radius: 5px;
    background-color: #F5F5F5;
    margin-bottom: 5px;
}

.List-image-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(3, auto);
    gap: 1vw;
    max-width: 100%;
    overflow: auto;
    padding: 0.9vw;
    padding-bottom: 0;
}

.List-shapeId{
    display: block;
    position: absolute;
    padding: 0.1vw;
    top: 0;
    left: 0;
    font-size: 1.3vw;
}

/* .List-image-grid:nth-child(n+5){
    margin-top: 10px;
} */`, "",{"version":3,"sources":["webpack://./src/pages/help/ListShapes/ListOfShapes.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,WAAW;IACX,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,aAAa;IACb,uCAAuC;IACvC,gBAAgB;AACpB;;AAEA;IACI,aAAa;;IAEb,WAAW;IACX,sBAAsB;IACtB,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,uBAAuB;IACvB,0BAA0B;;AAE9B;;AAEA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,mCAAmC;IACnC,QAAQ;IACR,eAAe;IACf,cAAc;IACd,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,cAAc;IACd,MAAM;IACN,OAAO;IACP,gBAAgB;AACpB;;AAEA;;GAEG","sourcesContent":[".ListOfShapes {\r\n    display: block;\r\n    width: 100%;\r\n    height: 100%;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.ListShapeContainer {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n    height: 90%;\r\n    box-sizing: border-box;\r\n    border: 3px solid #ebebeb;\r\n    border-radius: 10px;\r\n    padding: 10px;\r\n    box-shadow: rgb(73, 73, 73) 1px 1px 5px;\r\n    overflow: scroll;\r\n}\r\n\r\n.TypeHeader {\r\n    display: flex;\r\n\r\n    width: 100%;\r\n    box-sizing: border-box;\r\n    background-color: #138EE0;\r\n    padding: 5px;\r\n    color: white;\r\n    justify-content: center;\r\n    border-radius: 5px 5px 0 0;\r\n\r\n}\r\n\r\n.TypeContainer {\r\n    border-radius: 5px;\r\n    background-color: #F5F5F5;\r\n    margin-bottom: 5px;\r\n}\r\n\r\n.List-image-grid {\r\n    display: grid;\r\n    grid-template-columns: repeat(5, 1fr);\r\n    grid-template-rows: repeat(3, auto);\r\n    gap: 1vw;\r\n    max-width: 100%;\r\n    overflow: auto;\r\n    padding: 0.9vw;\r\n    padding-bottom: 0;\r\n}\r\n\r\n.List-shapeId{\r\n    display: block;\r\n    position: absolute;\r\n    padding: 0.1vw;\r\n    top: 0;\r\n    left: 0;\r\n    font-size: 1.3vw;\r\n}\r\n\r\n/* .List-image-grid:nth-child(n+5){\r\n    margin-top: 10px;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
