import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../utils/Button";
import {
  postServiceCenter,
  updateServiceCenter,
} from "../../../redux-tookit/slices/serviceCenterSlice";
import { toast } from "react-toastify";
import validator from "validator";
import { ReactComponent as CloseIcon } from "../../../assets/images/icons/action/CloseFilter.svg";

const AddServiceCenter = (props) => {
  const dispatch = useDispatch();
  const {
    isAdding,
    setIsAdding,
    isEditing,
    setIsEditing,
    editData,
    setEditData,
  } = props;
  const columns = useSelector((state) => state.serviceCenter.columns);
  const partnerData = useSelector((state) => state.partner.labeledData);
  const [newRow, setNewRow] = useState({});
  const columnKeys = Object.keys(columns);

  const bufferToBase64 = (buffer) => {
    try {
      // console.log(buffer);

      const byteArray = new Uint8Array(buffer);
      const decoder = new TextDecoder('utf-8');

      let result = '';
      for (let i = 0; i < byteArray.length; i += 1024) {
        const chunk = byteArray.subarray(i, i + 1024);
        result += decoder.decode(chunk);
      }
      console.log('this ran');
      // console.log(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  const [image, setImage] = useState(
    bufferToBase64(editData?.imageUrl?.data) ||
      undefined
  );
  const [loading, setLoading] = useState(false);
  console.log(columns, "columns");
  console.log(editData);
  const regions = ["North", "South", "East", "West"];

  const handleInputChange = (header, e) => {
    const updatedData = JSON.parse(JSON.stringify(editData));
    console.log(updatedData, "updatedData");
    updatedData[header] = e.target.value;
    setEditData(updatedData);
  };

  useEffect(() => {
    return () => {
      setImage();
    };
  }, []);

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (validator.isEmail(editData.email)) {
      const selectedPartner = partnerData.find(
        (org) => org.name === editData.PartnerName
      );
      const id = editData.serviceCenterId;
      const type = {
        name: editData.name,
        partnerId: selectedPartner.partnerId,
        phoneNo: editData.phoneNo,
        email: editData.email,
        address: editData.address,
        monthlyCapacity: editData.monthlyCapacity,
        flag: "0",
        imageUrl: image,
        region: editData.region,
      };
      console.log(type);
      //console.log(editData);
      dispatch(updateServiceCenter({ id, type }));
      setIsEditing(false);
    } else {
      toast.error("Enter a valid email");
    }
  };

  const onChangeHandle = (column, e, act) => {
    let match = false;
    if (column === "pinCode") {
      if (e.target.value.match(/^[1-9]{0,1}\d{0,5}$/)) match = true;
    } else if (column === "phoneNo") {
      if (e.target.value.match(/^\d{0,10}$/)) match = true;
    } else if (column === "fax") {
      if (e.target.value.match(/^\d{0,12}$/)) match = true;
    } else {
      match = true;
    }
    if (match === true) {
      if (act === "add") {
        setNewRow({ ...newRow, [column]: e.target.value });
      } else setEditData({ ...editData, [column]: e.target.value });
    }
  };

  const handleSaveButtonClick = async (e) => {
    e.preventDefault();
    if (validator.isEmail(newRow.email)) {
      const newRow1 = {
        ...newRow,
        flag: "0",
        imageUrl: image,
      };
      console.log(newRow1);
      dispatch(postServiceCenter(newRow1));
      setIsAdding(false);
      setNewRow({});
    } else {
      toast.error("Enter a valid email");
    }
  };

  const compressImage = (file, quality) => {
    console.log("Quality:", quality);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (event) {
        const image = new Image();
        image.onload = function () {
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = image.width;
          canvas.height = image.height;
          context.drawImage(image, 0, 0, image.width, image.height);
          canvas.toBlob(
            function (blob) {
              resolve(blob);
            },
            "image/jpeg",
            quality
          );
          // canvas.toDataURL('image/jpeg')
        };
        image.src = event.target.result;
      };
      reader.onerror = function (error) {
        reject(error);
        console.log(error);
      };
      reader.readAsDataURL(file);
    });
  };
  const handleFileChange = (event) => {
    const file = event;
    let base64Image = "";
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
        console.log(reader.result);
        base64Image = reader.result;
      };
      reader.readAsDataURL(file);
    }
    return base64Image;
  };

  const imageChange = async (file) => {
    if (file) {
      try {
        if (file.type.startsWith("image/")) {
          console.log(file);
          setLoading(true);
          const compressedBlob = await compressImage(
            file,
            file.size > 1024 * 1024 ? (1024 * 1024) / file.size : 1
          );
          // const compressedBlob = await imageToJPEG(file)
          console.log(compressedBlob);
          setImage(handleFileChange(compressedBlob));
          setLoading(false);
        } else {
          toast.error("Selected file is not an image");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    }
  };

  const removeImage = () => {
    const fileInput = document.getElementById("imageUpload");
    if (fileInput) {
      fileInput.value = null;
      setImage(null);
    }
  };



  return (
    <div>
      <form onSubmit={handleSaveButtonClick}>
        {isAdding && (
          <div>
            <div className="AddContainer" style={{alignItems:'flex-end'}}>
              <div className="form-container" style={{width:'70%', margin:'2vw'}}>
                <div className="head">Add Service Center</div>
                <div className="table-container user">
                  {columnKeys.map((column) =>
                    column !== "serviceCenterId" ? (
                      column === "PartnerName" ? (
                        <div
                          className="filter"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            width: "10vw",
                          }}
                        >
                          <label>
                            Partner Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="organizationId"
                            onChange={(e) => {
                              setNewRow({
                                ...newRow,
                                partnerId: e.target.value,
                              });
                            }}
                          >
                            <option>Select</option>
                            {partnerData.map((value, index) => (
                              <option key={index} value={value.partnerId}>
                                {value.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : column === "region" ? (
                        <div
                          className="filter"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            width: "10vw",
                          }}
                        >
                          <label>
                            Region<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            onChange={(e) => {
                              setNewRow({
                                ...newRow,
                                region: e.target.value,
                              });
                            }}
                          >
                            <option>Select</option>
                            {regions.map((region, index) => (
                              <option key={index} value={region}>
                                {region}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div
                          className="filter"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            width: "10vw",
                          }}
                        >
                          <label>
                            {columns[column].label}
                            {columns[column].isRequired && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <input
                            key={column}
                            type={columns[column].type}
                            className="table-input"
                            value={newRow[column] || ""}
                            onChange={(e) => onChangeHandle(column, e, "add")}
                            required={columns[column].isRequired ? true : false}
                            pattern={columns[column].pattern}
                          />
                        </div>
                      )
                    ) : (
                      ""
                    )
                  )}
                  <div className="filter">
                    <label htmlFor="imageUpload">Service Center Logo</label>
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      className="image-upload-button"
                      onChange={(e) => imageChange(e.target.files[0])}
                    />
                    <label
                      htmlFor="imageUpload"
                      className="custom-button-file-upload"
                    >
                      Choose File
                    </label>
                    {loading && <div className="circle-loader2"></div>}
                    <div className="imagePreview">
                      {image && (
                        <>
                          <img src={image} alt="cannot load" />
                          <div className='filter-close-button'>
                          <CloseIcon
                            title="Remove"
                            className="close"
                            onClick={() => removeImage()}
                          />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>

                <div className="bottom-button-container">
                  <Button type="submit" label="save" className="blue" />
                  <Button
                    onClick={() => {
                      setIsAdding(false);
                      setNewRow({});
                    }}
                    label="Cancel"
                    className="blue"
                  />
                </div>
              </div>
            </div>
            <div className="bg-dark"></div>
          </div>
        )}
      </form>
      <form onSubmit={handleUpdate}>
        {isEditing && (
          <div>
            <div className="AddContainer" style={{alignItems:'flex-end'}}>
              <div className="form-container" style={{width:'70%', margin:'2vw'}}>
                <div className="head">Edit Service Center</div>
                <div className="table-container">
                  {Object.keys(columns).map((key) =>
                    columns[key].editable === true ? (
                      key === "PartnerName" ? (
                        <div
                          className="filter"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            width: "10vw",
                          }}
                        >
                          <label>
                            Partner Name<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            id="partnerId"
                            value={editData[key]}
                            onChange={(e) => {
                              handleInputChange(key, e);
                            }}
                          >
                            {partnerData.map((value, index) => (
                              <option key={index} value={value.name}>
                                {value.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : key === "region" ? (
                        <div
                          className="filter"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            width: "10vw",
                          }}
                        >
                          <label>
                            Region<span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            value={editData[key]}
                            onChange={(e) => {
                              handleInputChange(key, e);
                            }}
                          >
                            {/* <option>Select</option> */}
                            {regions.map((region, index) => (
                              <option key={index} value={region}>
                                {region}
                              </option>
                            ))}
                          </select>
                        </div>
                      ) : (
                        <div
                          className="filter"
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "4px",
                            width: "10vw",
                          }}
                        >
                          <label>
                            {columns[key].label}
                            {columns[key].isRequired && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                          </label>
                          <input
                            type={columns[key].type}
                            className="table-input"
                            value={editData[key]}
                            onChange={(e) => onChangeHandle(key, e, "edit")}
                            pattern={columns[key].pattern}
                          />
                        </div>
                      )
                    ) : (
                      ""
                    )
                  )}
                  <div className="filter">
                    <label htmlFor="imageUpload">Service Center Logo</label>
                    <input
                      type="file"
                      id="imageUpload"
                      accept="image/*"
                      className="image-upload-button"
                      onChange={(e) => imageChange(e.target.files[0])}
                    />
                    <label
                      htmlFor="imageUpload"
                      className="custom-button-file-upload"
                    >
                      Choose File
                    </label>
                    {loading && <div className="circle-loader2"></div>}
                    <div className="imagePreview">
                      {image && (
                        <>
                          <img src={image} alt="cannot load" />
                          <div className='filter-close-button' style={{borderRadius:'0 0 0 0', width:'15px', height:'15px', backgroundColor:'#fff', border:'1px solid black', display:'flex', alignContent:'center', justifyContent:'center'}}>

                          <CloseIcon
                            title="Remove"
                            className="close"
                            onClick={() => removeImage()}
                          />
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Button type="submit" label="Save" className="blue" />
                  <Button
                    onClick={() => setIsEditing(false)}
                    label="Cancel"
                    className="blue"
                  />
                </div>
              </div>
            </div>
            <div className="bg-dark"></div>
          </div>
        )}
      </form>
    </div>
  );
};

export default AddServiceCenter;
