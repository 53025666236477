import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API'; // Import your Axios API functions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
  columns: {},
  labeledData: [], // Initialize with an empty array
  loading: 'idle',
  error: null,
};

export const fetchSdbr = createAsyncThunk('data/fetchSdbr', async () => {
  try {
    // Fetch data using Axios
    const response = await api.listSdbr();
    const json = response.data;
    console.log(json)
    const columnLabels = {
      sdbrId: { label: "SDBR Id", type: 'number' },
      orderId: { label: "Id", type: 'text' },
      combId: { label: "Order Id", type: 'text' },
      customerName: { label: "Customer", type: "text" },
      customerExpectedDeliveryDate: {label: "Customer Expected Delivery Date"},
      productWeight: { label: "Prod. Wt (T)", type: 'text' },
     
      coilRouteDuration_SDBR: { label: "Coil Route", type: 'tel' },
      rebarRouteDuration_SDBR: { label: "Rebar Route", type: 'tel' },
     
      deliveryDate: { label: "Delivery Date", type: 'text', editable: true },
      sdbrstatus: { label: "SDBR Status", type: 'text' },
      sequenceNo: { label: "Sequence No.", type: 'text' },
      orderStatus: { label: "Order Status", type: 'text' },
      productionStartDate: { label: "Production Start Date", type: 'text' },
      productionEndDate: { label: "Production End Date", type: 'text' },
      groupNo: { label: "Group No.", type: 'text' },

      status: { label: "Status", type: 'text' },

      poDate: { label: "PO Date", type: 'text' },
      orderRemarks: {label: "Order Remark"}
      // Add more as needed
    };



    const selectedColumns = [
      "sdbrId",
      'combId',
      "orderId",
      "customerName",
      "orderRemarks",
      "customerExpectedDeliveryDate",
      "productWeight",
      "coilRouteDuration_SDBR",
      "rebarRouteDuration_SDBR",
      "deliveryDate",
      "sdbrstatus",
      "orderStatus",
      "productionStartDate",
      "productionEndDate",
      // "groupNo",
      // "sequenceNo",
      "poDate"];


    const columns = {};
    selectedColumns.forEach((columnName) => {
      if (columnName !== 'sdbrId') {
        columns[columnName] = columnLabels[columnName];

      }
    });


    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        columnName === 'customerName' ? rowData[columnName] = row.soldToParty :
        
          rowData[columnName] = row[columnName];
      });
      return rowData;
    });


    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});
export const runSdbr = createAsyncThunk('data/runSdbr', async (newRow) => {
  try {
    // Make a POST request to your API
    const response = await api.runSdbr(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
    throw error;
  }
});

export const confirmPO = createAsyncThunk('data/confirmPO', async (id) => {
  console.log(id);
  try {
    const response = await api.confirmPO(id);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
    throw error;
  }
});

export const unConfirmPO = createAsyncThunk('data/unConfirmPO', async (newRow) => {
  try {
    // Make a POST request to your API
    const response = await api.unConfirmPO(newRow);
    const data = response.data;
    return data;
  } catch (error) {
    throw error;
  }
});

// export const updateSdbr = createAsyncThunk('data/updateSdbr', async ({id, type}) => {
//   //console.log(editData)
//   try {
//     const response = await api.updateSdbr(id,type);
//     const data = response.data;
//     console.log('Update Success');
//     return {data,id,type};
//   } catch (error) {
//     throw error;
//   }
// });



const sdbrDataSlice = createSlice({
  name: 'sdbrData',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSdbr.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchSdbr.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchSdbr.rejected, (state, action) => {

        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(runSdbr.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(runSdbr.fulfilled, (state, action) => {
        state.loading = 'fulfilled';

        const index = state.labeledData.find(item => item.sdbrId === action.payload.sdbrId);
        if (index) {

          const updatedItem = { ...index, ...action.payload.type };
          state.labeledData = state.labeledData.map(item => {
            if (item.sdbrId === action.payload.sdbrId) {
              return updatedItem;

            }
            return item;
          })

        }
        toast.success('SDBR run successfully!');
      })
      .addCase(runSdbr.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(confirmPO.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(confirmPO.fulfilled, (state) => {
        state.loading = 'fulfilled';
        toast.success('PO Confirmed successfully!');
      })
      .addCase(confirmPO.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(unConfirmPO.fulfilled, (state) => {
        state.loading = 'fulfilled';
        toast.success('PO Un Confirmed successfully!');
      })
      .addCase(unConfirmPO.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })

    // .addCase(deleteSdbr.pending, (state) => {
    //   state.loading = 'pending';
    // })
    // .addCase(deleteSdbr.fulfilled, (state, action) => {
    //   state.loading = 'fulfilled';
    //   const deletedId = action.payload;
    //   state.labeledData=state.labeledData.filter((data) => data.customerId !== deletedId); // change this id 
    //   toast.success('Order deleted successfully!');
    // })
    // .addCase(deleteSdbr.rejected, (state, action) => {
    //   state.loading = 'rejected';
    //   state.error = action.error.message;
    //   toast.error(state.error);
    // })
  },
});

export default sdbrDataSlice.reducer;
//End of File