import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
	columns: {},
	labeledData: [],
	loading: 'idle',
	fetcher: true,
	error: null,
};

export const fetchOrganizations = createAsyncThunk('data/fetchOrganizations', async () => {
	try {
		const response = await api.listOrganizations();
		const json = response.data;
		const columnLabels = {
			organizationId: { label: "Organization ID", type: 'number', isRequired: true, editable: false },
			organizationName: { label: "Organization Name", type: 'text', isRequired: true, editable: true },
			// Add more as needed
		};

		const selectedColumns = ['organizationId', 'organizationName'];

		const columns = {};
		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});

		return { columns, labeledData };
	} catch (error) {
		throw error;
	}
});
export const postOrganizations = createAsyncThunk('data/postOrganizations', async (newRow) => {
	try {

		const response = await api.addOrganizations(newRow);
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		throw error;
	}
});
export const deleteOrganization = createAsyncThunk('data/deleteOrganizations', async (id) => {
	try {
		await api.deleteOrganization(id);
		return id;
	} catch (error) {
		throw error.response.data;
	}
});
export const updateOrganization = createAsyncThunk('data/updateOrganizations', async ({ id, type }) => {
	try {
		console.log(id);
		const response = await api.updateOrganization(id, type);
		const data = response.data;
		console.log('Update Success');
		return { data, id, type };
	} catch (error) {
		throw error;
	}
});
const organizationSlice = createSlice({
	name: 'organization',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchOrganizations.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns = action.payload.columns;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchOrganizations.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(postOrganizations.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postOrganizations.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.labeledData.push(action.payload);
				toast.success('Organization added successfully!');
			})
			.addCase(postOrganizations.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to add organization');
			})
			.addCase(updateOrganization.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(updateOrganization.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				console.log('1');
				const index = state.labeledData.find(item => item.organizationId === action.payload.id);
				console.log('2');
				if (index) {
					console.log('3');
					const updatedItem = { ...index, organizationName: action.payload.type.organizationName };
					state.labeledData = state.labeledData.map(item => {
						if (item.organizationId === action.payload.id) {
							console.log('4');
							return updatedItem;
						}
						return item;
						
					})

				}
				console.log('Organization updated sucessfully')
				toast.success('Organization updated sucessfully');
			})
			.addCase(updateOrganization.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to update Organization');
			})
			.addCase(deleteOrganization.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(deleteOrganization.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				const deletedId = action.payload;
				state.labeledData = state.labeledData.filter((data) => data.organizationId !== deletedId);
				toast.success('Organization deleted sucessfully');
			})
			.addCase(deleteOrganization.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error(state.error);
			});
	},
});

export default organizationSlice.reducer;
//End of File