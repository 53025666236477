import React from 'react'

const ConfirmPasswordInputField = ({handleValidation, handlePasswordChange, confirmPasswordValue, confirmPasswordError, className}) => {
    return (
       
     <div className={className}>
        <label htmlFor="confirmPassword">Confirm Password  <span style={{color:'red'}}>*</span></label>
        <input type="password" id='confirmPassword' value={confirmPasswordValue} required  onChange={handlePasswordChange} onKeyUp={handleValidation} name="confirmPassword" placeholder="" className="table-input" />
        <p className="text-danger">{confirmPasswordError}</p>
    </div>
    )
}

export default ConfirmPasswordInputField
