// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    
    .table-header-2{
      background-color: #08639F;
      color: white;
      font-size: 16px; 
      padding: 8px;
    }

    .table-header-1 {
        background-color: #138EE0;
        color: white;
        font-size: 16px; 
        padding: 8px;
        
    }

    .Update-multi{
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px; 
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    margin-top:10px;
    }


.table-header-1 select,
.table-header-1 input{
  width: 100%;
  padding: 5px;
  margin: 0;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/SDBRTable.css"],"names":[],"mappings":";;IAEI;MACE,yBAAyB;MACzB,YAAY;MACZ,eAAe;MACf,YAAY;IACd;;IAEA;QACI,yBAAyB;QACzB,YAAY;QACZ,eAAe;QACf,YAAY;;IAEhB;;IAEA;IACA,sBAAsB;IACtB,yCAAyC;IACzC,kBAAkB;IAClB,2DAA2D;IAC3D,eAAe;IACf;;;AAGJ;;EAEE,WAAW;EACX,YAAY;EACZ,SAAS;EACT,yBAAyB;EACzB,kBAAkB;AACpB","sourcesContent":["\r\n    \r\n    .table-header-2{\r\n      background-color: #08639F;\r\n      color: white;\r\n      font-size: 16px; \r\n      padding: 8px;\r\n    }\r\n\r\n    .table-header-1 {\r\n        background-color: #138EE0;\r\n        color: white;\r\n        font-size: 16px; \r\n        padding: 8px;\r\n        \r\n    }\r\n\r\n    .Update-multi{\r\n    background-color: #fff;\r\n    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);\r\n    border-radius: 8px; \r\n    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    margin-top:10px;\r\n    }\r\n\r\n\r\n.table-header-1 select,\r\n.table-header-1 input{\r\n  width: 100%;\r\n  padding: 5px;\r\n  margin: 0;\r\n  border: 2px solid #d9d9d9;\r\n  border-radius: 4px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
