import React, { useEffect, useCallback, useMemo, useRef, useState, useLayoutEffect } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import '../assets/styles/AGTable.css'
// import { wait } from '@testing-library/user-event/dist/utils';

const AGTable = (props) => {

  const { columns, data, rowSelection = 'single',deselector, onSelectRow, selectedRow, dispatch, width = '100%' } = props
  const gridRef = useRef();
  const gridApiRef = useRef(null)
  const [columnDef, setcolumnDef] = useState(null);
  // const [rowData, setRowData] = useState(data);
  const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const columnKeys = Object.keys(columns);
  const [chData, uChData] = useState(null);

  
  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  };

  const defaultColDef ={
    initialWidth: 100,
      sortable: true,
      enableCellChangeFlash: true,
      cellDataType: false,
      resizable: true,
      filter: true,
      wrapText: true,
      // autoHeight: true,
      autoHeaderHeight: true,
      flex: 1
  }

  const deSelectAll = () => {
    try{
      const selectedNodes = gridApiRef.current.getSelectedNodes();
    selectedNodes.forEach(node => node.setSelected(false))
    } catch(error){
      
    }
  }

  useLayoutEffect(() => {

    if (deselector > 0) { deSelectAll() }

  }, [deselector]);

  const changeValues = () => {

    let newColumns = [{ colId: '0', field: "slno", headerName: "Sl.No", headerCheckboxSelection: rowSelection === 'multiple' ? true : false, checkboxSelection: true, resizable: false, filter: false, lockPosition: 'left', cellClass: 'locked-col'}];
    let newData = []
    columnKeys.map((data, index) => {
      if (!data.toLowerCase().includes('id')) {
      let obj = { colId: '', field: "", headerName: "", headerCheckboxSelection: rowSelection === 'multiple' ? true : false, checkboxSelection: false };

      // if (index !== 0) {
      //   obj.headerCheckboxSelection = false;
      //   obj.checkboxSelection = false;
      // }

      obj.colId = index + 1;
      obj.field = data;
      obj.headerName = columns[data].label;

      newColumns.push(obj);
      }
    });
    
    data.map((data, index) => {

      let obj = { slno: index + 1 };

      newData[index] = { ...obj, ...data };
    })
    uChData(newData);
    setcolumnDef(newColumns);

  }


  const onSelectionChanged = () => {

    const selectedNodes = gridApi.getSelectedNodes();

    // const selectedRowIndex = event.node.rowIndex;
    // console.log("@@@", selectedRowIndex);

    const selectedRowIndexes = selectedNodes.map((node) => node.data.slno - 1);//FOR MULTIPLE ROW ALSO
    // console.log("***", selectedNodes.map((node) => node.colId));

    onSelectRow(selectedRowIndexes[0]);
  }

  useLayoutEffect(() => {
    uChData(data);
    setcolumnDef(columns);
    
    changeValues();

  }, [columns,dispatch,data]);


  useLayoutEffect(() => {

  }, [columnDef, chData]);





  return (
    <div className="table-container small" style={{maxWidth: width, minWidth: width}}>

      <div className="ag-theme-alpine" style={{ height: data.length===0?150: 57+data.length*57<300?57+data.length*57 : 300, width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          onGridReady={onGridReady}
          rowData={chData}
          columnDefs={columnDef}
          rowMultiSelectWithClick={true}
          animateRows={true}
          rowSelection={rowSelection}
          defaultColDef={defaultColDef}
          onSelectionChanged={onSelectionChanged}
          enableCellTextSelection={true}  
          ensureDomOrder={true}
        />
      </div>
    </div>
  )
}

export default AGTable
