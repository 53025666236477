import React, { useState } from 'react';
import '../assets/styles/GenericTable.css';

const GenericTable1 = ({ columns, data, onSelectRow, selectedRow }) => {
  const columnKeys = Object.keys(columns);
  const planFreeze = (value) => {
    if (value == 1) {
      return 'red';
    }
    else {
      return 'green';
    }
  }
  return (
    <div className="table-container">
      <table className="generic-table">
        <thead className='stick'>
          <tr>
            <th key="select" className="table-header">
            </th>
            {columnKeys.map((key) => (
              key !== 'planId' ?
                <th key={key} className="table-header">
                  {columns[key].label}
                </th>:''
            ))}
          </tr>
        </thead>
        <tbody >
          {data.length === 0 ? <tr> <td colSpan="15" style={{ fontWeight: 500, fontSize: '15px' }}>No Rows To Show</td> </tr> : ''}
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className='t-data'>
              <td>
                <input
                  type="radio"
                  name="rowSelect"
                  value={rowIndex}
                  checked={selectedRow === rowIndex}
                  onChange={()=>{}}
                  onClick={() => onSelectRow(rowIndex)}
                  id={`radio${rowIndex}`}
                />
                <label htmlFor={`radio${rowIndex}`} className="custom-radio" ></label>
              </td>
              {columnKeys.map((data, key) => (
                data === 'planStatus' ? <td style={{ backgroundColor: planFreeze(row[data]) }}></td>
                  : data!== 'planId'? <td key={key}>{row[data]}</td> : ''
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default GenericTable1;