import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';

 
const initialState = {
    shapes: [],
    loading: 'idle',
    error: null,
    fulfiller: ''
  };

  export const fetchShapes = createAsyncThunk('data/fetchShapes', async (category) => {
    try {
      let params = ''
      if(category){
        params = category
      }
      const response = await api.listShape(params);
      const json= response.data;
      // console.log(json.rows);
      const shapes = json.rows
      return { shapes };
    } catch (error) {
      throw error;
    }
  });
  
  export const postShapes = createAsyncThunk('data/postShape', async (newShape) => {
    try {
      
      const response = await api.addShape(newShape);
      
      const data = response.data;
      
      console.log('Success');
      return data;
    } catch (error) {
      throw error.response.data;
    }
  });

  export const updateShapes = createAsyncThunk('data/updateShapes', async ({id,type}) => {
    try {
      console.log(type);
      const response = await api.updateShapes(id,type);
     
      const data = response.data;
      console.log('Update Success');
      return {data, id, type};
    } catch (error) {
      throw error;
    }
  });

  

  export const deleteShapes = createAsyncThunk('data/deleteShape', async (id) => {
    try {
      await api.deleteShape(id);
      return id;
    } catch (error) {
      if(error.response.data.message)
		{toast.error(`${error.response.data.message}`)}
		throw error;
    }
  });

  

  export const searchShapes = createAsyncThunk('data/searchShapes', async (category) => {
    try {
      let params = ''
      if(category){
        params = category
      }
      const response = await api.listShape(params);
      const json= response.data;
      // console.log(json.rows);
      const shapes = json.rows
      return { shapes };
    } catch (error) {
      throw error;
    }
  });



  const shapeSlice = createSlice({
    name: 'shape',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchShapes.pending, (state) => {
            state.loading = 'pending';
        })
        .addCase(fetchShapes.fulfilled, (state, action) => {
            state.loading = 'fulfilled';
            state.shapes = action.payload.shapes;
        })
        .addCase(fetchShapes.rejected, (state, action) => {
            state.loading = 'rejected';
            state.error = action.error.message;
        })
        .addCase(postShapes.pending, (state) => {
          state.loading = 'pending';
          console.log('pen')
        })
        .addCase(postShapes.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          // state.shapes.push(action.payload);
          console.log('ful')
          console.log(action.payload)
          
          toast.success("Shape added successfully");
          //useNavigate('/shapes')
        })
        .addCase(postShapes.rejected, (state, action) => {
          state.loading = 'rejected';
          state.error = action.error.message;
          console.log('rej')
          toast.error(state.error)
        })
        .addCase(updateShapes.pending, (state) => {
          state.loading = 'pending';
        })
        .addCase(updateShapes.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          state.shapes.push(action.payload);
          toast.success('Shape updated successfully')
   
        })
        .addCase(updateShapes.rejected, (state, action) => {
          state.loading = 'rejected';
          state.error = action.error.message;
          toast.error('Failed to update Shape')
   
        })
        .addCase(deleteShapes.pending, (state) => {
          state.loading = 'pending';
        })
        .addCase(deleteShapes.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          console.log(state.shapes);
          // state.labeledData=state.labeledData.filter((data) => data.id !== deletedId);
          // state.shapes = state.shapes.filter((data) => data.s !== deletedId);
          toast.success('Shape deleted sucessfully');
        })
        .addCase(deleteShapes.rejected, (state, action) => {
          state.loading = 'rejected';
          state.error = action.error.message;
          // toast.error(state.error);
        });
    
    }
  });
   
  export default shapeSlice.reducer
  //End of File