import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import AGTable from '../../../components/AGTable1';
import AddOrganization from './AddOrganization';
import { deleteOrganization, fetchOrganizations } from '../../../redux-tookit/slices/organizationSlice';
import {  toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';

const Organization = () => {
	const dispatch = useDispatch();
	const location = useLocation()

	const columns = useSelector((state) => state.organization.columns);
	const data = useSelector((state) => state.organization.labeledData);
	const fetcher = useSelector((state) => state.organization.fetcher);
	//const filteredData = data.filter((item) => item.route === selectedRoute);
	// const columnKeys = Object.keys(columns);
	const [editData, setEditData] = useState([]);
	const [selectedRowIndex, setSelectedRowIndex] = useState(null);
	const [isAdding, setIsAdding] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [filteredColumns, setFilteredColumns] = useState({});

	const permission = useState(location.state?.permission);

	

	const handleRowSelect = (rowIndex) => {
		setSelectedRowIndex(rowIndex);
		console.log(rowIndex);

	};

	const handleAddButtonClick = () => {
		setIsAdding(true);
	};

	const handleEditButtonClick = () => {
		if (selectedRowIndex !== null&&selectedRowIndex!==undefined) { 
			setIsEditing(true); 
			setEditData(data[selectedRowIndex]);
		  }else toast.error("No rows selected, Please select a row and try again");setSelectedRowIndex(null);
	};

	const handleDeleteButtonClick = async () => {
		if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
			const id = data[selectedRowIndex].organizationId;
			dispatch(deleteOrganization(id));
		}
		else toast.error("No rows selected, Please select a row and try again");
		setSelectedRowIndex(null);
	};

	useEffect(() => {
		dispatch(fetchOrganizations());
		// columnKeys(columns).map((column) => column.key !== 'organizationId'?setFilteredColumns(...setFilteredColumns,columns[column]):"");

	}, [dispatch, isEditing,isAdding, fetcher]);


	return (
		<div>
			<div className='sdbr'>
				<div className='head small'>Organizations
					<div className="top-button-container">
						{/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
						<img src={edit} alt="edit" onClick={handleEditButtonClick} />
						<img src={del} alt="delete" onClick={handleDeleteButtonClick} /> */}
						<TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]}/>

					</div>
				</div>

				<AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} width='60%'/>
				<AddOrganization isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} />

			</div>
		</div>
	);
};

export default Organization;