import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchPartItems } from '../../../redux-tookit/slices/partItemsSlice';
import CustomBundleTable from '../../transaction/Dispatch/CustomBundleTable';
import TableComponent2 from '../../../components/TableComponent2';
import TableComponent from '../../../components/TableComponent';
import Button from '../../../utils/Button';

const ResultPopup = (props) => {

    const dispatch = useDispatch();
    const { data = [], setResultShow } = props

    const partItemData = useSelector(state => state.partItems.labeledData)

    const [insideData, setInsideData] = useState([])

    console.log(data);

    const columns = {
        inventoryLength: { label: 'Length' },
        inventoryType: { label: 'Inventory type' },
        requiredLength: { label: 'Required length' },
        noOfCuts: {label: 'No. of cuts'},
        diameter: { label: 'Diameter' },
        gradeName: { label: "Grade" },
        requiredPieces: { label: 'Pieces required' },
        piecesUsed: {label: "No. of bars used"},
        barMarkId: { label: 'Bar Mark Id' },
        orderItemName: { label: 'Element Name' }
    }

 

    useEffect(() => {
        dispatch(fetchPartItems())

    }, [])

    const dataEdit = () => {
        let newArray = [];
        data.forEach(element => {

            const extraData = partItemData.filter(item => item.partItemId * 1 === element.partItemId * 1)[0]
            console.log(extraData);
            const addElement = {
                orderItemName: extraData.orderItemName || '',
                barMarkId: extraData.barMarkId || '',
                gradeName: extraData.gradeName || ''
            }

            // console.log(addElement,extraData);
            newArray = [...newArray, { ...element, ...addElement }]
        });
        newArray = newArray.sort((a, b) => a.diameter - b.diameter)
        setInsideData(newArray)
    }

    useEffect(() => {
        if (data.length !== 0 && partItemData.length !== 0)
            dataEdit()
    }, [data, partItemData])

    return (
        <div>
            <form>
                <div className="AddContainer" >
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'fit-content', backgroundColor: '#fff', borderRadius: '10px', paddingBottom: '1vw' }}>
                        <CustomBundleTable data={insideData} columns={columns} rowSelection='multiple' width='70vw' />
                        <div className="bottom-button-container">
                            <Button label='Close' className='blue' onClick={() => setResultShow(false)} />
                        </div>
                    </div>
                </div>
                <div className="bg-dark"></div>
            </form>
        </div>
    )
}

export default ResultPopup
