import React, { useEffect, useState } from 'react'
import AGTable from '../../../components/AGTable'
import Button from '../../../utils/Button.jsx'

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrderItems } from '../../../redux-tookit/slices/orderItemsSlice.jsx'
import CustomInput from './CustomInput.jsx'
import { fetchBundles, fetchFgPartItems, fetchLoadingList, updateLoadingList } from '../../../redux-tookit/slices/loadingListSlice.jsx'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchOrder } from '../../../redux-tookit/slices/orderSlice.jsx'
import CustomBundleTable from './CustomBundleTable.jsx'

const UpdateLoadingDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [loadingDs, setLoadingDs] = useState('new')
    const [isAdding, setIsAdding] = useState(false)


    const location = useLocation();
    //const myData = location.state?.planId;
    const [loadingListId, setLoadingListId] = useState(location.state?.loadingListId)

    const [updateData, setUpdateData] = useState(location.state?.updateData)

    const [orderId] = `${location?.state?.orderIds}`.split(',')

    console.log(orderId);

    const loadData = useSelector((state) => state.loadingList.labeledData);

    const [addNew, setAddNew] = useState({ vehicleFitment: 1, driverFitment: 1 })
    console.log(loadData);

    const [id, setId] = useState(orderId);

    const columns = useSelector((state) => state.loadingList.columns3)

    const originalData = useSelector((state) => state.loadingList.labeledData3)

    console.log(originalData);

    const bundles = useSelector((state) => state.loadingList.labeledData4)

    const [tagMultiSelect, setTagMultiSelect] = useState(false);


    // const bundleColumns = useSelector(state => state.loadingList.columns4)

    const sortedArray = originalData.map(item => {
        return { orderId: item.orderId, combId: item.combId }
    }).sort((a, b) => a.combId - b.combId)

    const orderIds = originalData.map(item => {
        return { orderId: item.orderId, combId: item.combId }
    }).sort((a, b) => a.combId - b.combId).filter((data, index) => {
        if (index !== 0) {
            if (data.orderId !== sortedArray[index - 1].orderId)
                return true
            else return false
        } else return true

    })

    const bundleColumns = {

        combId: { label: 'Order Id' },
        orderItemName: { label: "Element Name" },
        noOfPieces: { label: "No of Pieces" },
        gradeName: { label: 'Grade' },
        diameter: { label: 'Diameter' },
        weight: { label: "Weight (T)" },

    };


    const [filteredBundles, setFilteredBundles] = useState([])

    const [selectedRowIndex, setSelectedRowIndex] = useState(null)

    const [selectedBundleRowIndex, setSelectedBundleRowIndex] = useState(null)

    const [selectedBundleIds, setSelectedBundleIds] = useState([])

    const [selectedBundleData, setSelectedBundleData] = useState([])

    const [loadNames, setLoadNames] = useState([])

    const [data, setData] = useState(originalData.filter(item => `${item.orderId}` === id))

    const [scanValue, setScanValue] = useState('')

    console.log(addNew);


    const handleRowSelect = (rowIndex) => {
        if (rowIndex !== undefined) {

            setSelectedRowIndex(rowIndex);
            // handleTotalWeight(rowIndex)

        } else setSelectedRowIndex(null)
    };


    const handleBundleRowSelect = (rowIndex) => {
        if (rowIndex !== undefined) {

            setSelectedBundleRowIndex(rowIndex);
            // handleTotalWeight(rowIndex)

        } else setSelectedBundleRowIndex(null)
    }


    const removeItems = (arr, indices) => {
        let newArray = [...arr];
        indices.sort((a, b) => b - a);
        indices.forEach(index => newArray.splice(index, 1));
        return newArray;
    }



    const handleBundleDeletion = () => {
        setSelectedBundleIds(prev => removeItems(prev, selectedBundleRowIndex))
    }



    const handleAddButtonClick = () => {

        if (selectedRowIndex !== null) {
            console.log(selectedRowIndex);
            if (selectedRowIndex.constructor === Array) {
                const partIds = selectedRowIndex.map(index => data[index].partItemId)
                const filBundles = bundles.filter(
                    item =>
                        partIds.includes(item.partItemId) &&
                        !selectedBundleIds.includes(item.bundleId)
                )

                setFilteredBundles(filBundles)
                setIsAdding(true)

            } else {
                const filBundles = bundles.filter(
                    item =>
                        item.partItemId * 1 === data[selectedRowIndex].partItemId * 1 &&
                        !selectedBundleIds.includes(item.bundleId)
                )

                setFilteredBundles(filBundles)
                setIsAdding(true)
            }

        } else {
            toast.error('')
        }
    }



    const handleBundleAddition = (bundleIds) => {
        setSelectedBundleIds((prev) => {
            return [...prev, ...bundleIds]
        })
    }



    const handleSaveButtonClick = async (e) => {
        e.preventDefault();

        if (addNew.vehicleFitment * 1 !== 1) {
            toast.error("Vehicle not fit. Cannot proceed with an unfit vehicle")
            return
        }
        if (addNew.driverFitment * 1 !== 1) {
            toast.error("Driver not fit. Cannot proceed with an unfit driver")
            return
        }
        if (selectedBundleIds.length === 0) {
            toast.error("No bundles selected")
            return
        }
        // if (Math.abs(addNew.weightBridgeWeight * 1000 - addNew.totalWeight) * 100 / addNew.totalWeight > 0.5) {
        //     toast.error("Weighbridge weight should be within 0.5% of Production weight")
        //     return
        // }


        let bundleId = selectedBundleIds.toString()


        const type = {
            ...addNew,
            "gatePassNo": 1,
            bundleId: bundleId,
            totalWeight: addNew.totalWeight * 1000
        }

        const id = loadingListId;

        dispatch(updateLoadingList({ id, type })).then((res) => {
            if (res.payload !== undefined) {
                console.log(res.payload);
                toast.success(res.payload.data.message)
                setTimeout(() => {
                    navigate(`/loading details`);
                }, 2000)
            }
        })
        console.log(type)
    }



    useEffect(() => {
        dispatch(fetchFgPartItems())
        dispatch(fetchLoadingList());
        dispatch(fetchOrderItems())
        dispatch(fetchOrder())
        dispatch(fetchBundles())

        setData(originalData.filter(item => `${item.orderId}` === id))
    }, [id])

    const bundleValueCalculator = () => {
        try {
            let totalWeight = 0;
            let noOfRods = 0;
            selectedBundleIds.forEach(data => {
                bundles.map(row => {
                    if (row.bundleId * 1 === data * 1) {

                        totalWeight += row.weight;
                        noOfRods += row.noOfPieces;

                    }
                })
            })
            const addData = {
                totalRods: noOfRods,
                totalBundles: selectedBundleIds.length,
                totalWeight: totalWeight
            }
            setAddNew({ ...addNew, ...addData })
            setSelectedBundleData(bundles.filter(item => selectedBundleIds.includes(item.bundleId)))

        } catch (error) {
            throw error
        }
    }

    const handleEditValue = (loadData) => {
        try {
            console.log(updateData.bundleId.split(','));
            loadData.forEach(data => {
                if (data.loaingListId * 1 === loadingListId * 1) {
                    setAddNew({ ...addNew, ...data })
                    setSelectedBundleIds(data.bundleId.split(','))
                    console.log(data.bundleId.split(','));
                }

            })
        } catch (error) {
            throw error
        }
    }



    useEffect(() => {
        handleEditValue(loadData)
    }, [loadData])


    useEffect(() => {

        bundleValueCalculator()

    }, [selectedBundleIds])

    const setEditData = async () => {
        const bundleIds = JSON.parse('[' + updateData.bundleId + ']')
        setSelectedBundleIds(bundleIds)
        setSelectedBundleData(await bundles.filter(item => bundleIds.includes(item.bundleId)))
        console.log(bundles.filter(item => bundleIds.includes(item.bundleId * 1)));
        console.log(updateData);
        setAddNew({ ...addNew, ...updateData })
    }

    useEffect(() => {
        setEditData()
    }, [updateData])


    const handleScannerInput = (data) => {
        if (data) {
            try {
                const [extraData, ids] = data.split('SCANNER INPUT:');
                const [partItemId, bundleId] = ids.split(',')
                console.log(partItemId);
                if (!selectedBundleIds.includes(bundleId * 1)) {
                    setSelectedBundleIds((prev) => {
                        return [...prev, bundleId * 1]
                    })
                } else {
                    toast.error('Bundle already added')
                }

            } catch (error) {

            }

        }
        // setScanValue(data)
    }


    const decimalValidator = (e) => {
        let match = false

        if (e.target.value.match(/^[0-9]*[.]?[0-9]{0,5}$/)) match = true

        if (match === true) {

            setAddNew({ ...addNew, weightBridgeWeight: e.target.value })

        }

        // setAddNew({ ...addNew, weightBridgeWeight: value * 1 })

    }


    const handleTagMultiSelect = (value) => {
        setTagMultiSelect(value)

    }

    console.log('data', data, 'bundles', bundles);


    return (
        <div className='sdbr'>
            <div className="head">
                <div>Update Loading List</div>
                <div className='top-button-container'>
                    {/* <Button label='Driver Fitment' className="blue" onClick={() => { setIsAdding(true) }} /> */}
                </div>
            </div>

            <form onSubmit={handleSaveButtonClick}>
                <div className='filter-card'>
                    <div className="Sumfilter-layout"
                        style={{ maxHeight: '250px', gridTemplateRows: '1fr 1fr 1fr', gap: '10px' }}
                    >

                        {/* <div className="filter">
                            <label htmlFor="totalProdWeight" >Total Prod. Weight(Kg)</label>
                            <input type="number" />
                        </div>
                        <div className="filter">
                            <label htmlFor="totalBundles">Total Bundles</label>
                            <input type="number" />
                        </div> */}

                        {/* <div className="filter">
                            <label htmlFor="sapDlvNo">SAP Dlv No./Tally Inv No</label>
                            <input type="text" />
                        </div> */}

                        {/* <div className="filter">
                            <label htmlFor="totalRods">Total Rods</label>
                            <input type="number" />
                        </div> */}

                        <div className="filter">
                            <label htmlFor="orderId">Order ID </label>
                            <select name="orderId" id="orderId" value={id} onChange={(e) => setId(e.target.value)} >
                                <option value="">-Select-</option>
                                {orderIds.map((id) =>
                                    (<option value={id.orderId}>{id.combId}</option>))}
                            </select>
                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Vehicle No. <span style={{ color: 'red' }}>*</span></label>
                            <input type="text"
                                value={addNew.trailerNo}
                                onChange={(e) => { setAddNew({ ...addNew, trailerNo: e.target.value }) }}
                                required
                            />
                        </div>
                        <div className="filter" >
                            <label htmlFor="loadingList">Loading List Name  <span style={{ color: 'red' }}>*</span></label>

                            {
                                loadingDs === 'existing' ?
                                    <select
                                        name="loadingList"
                                        id="loadingList"
                                        style={{ paddingBottom: '7.6px' }}
                                        value={addNew.loadingListName}
                                        onChange={(e) => { setAddNew({ ...addNew, loadingListName: e.target.value }) }}
                                    >
                                        <option value="">-Select-</option>
                                        {loadNames.map(item => (
                                            <option>
                                                {item}
                                            </option>
                                        ))}
                                    </select> :
                                    <input type="text"
                                        name="loadingList"
                                        id="loadingList"
                                        onChange={(e) => { setAddNew({ ...addNew, loadingListName: e.target.value }) }}
                                        value={addNew.loadingListName}
                                        required
                                    />
                            }

                            {/* <div style={{ alignContent: 'center', fontWeight: 'normal', marginTop: '4px' }}>

                                <input
                                    type="radio"
                                    name='loadingList'
                                    value='existing'
                                    id='existing'
                                    checked={loadingDs === 'existing'}
                                    onChange={(e) => setLoadingDs(e.target.value)} />
                                <label htmlFor="existing">Existing</label>

                                <input
                                    type="radio"
                                    name='loadingList'
                                    value='new'
                                    id='new'
                                    checked={loadingDs === 'new'}
                                    onChange={(e) => setLoadingDs(e.target.value)} />
                                <label htmlFor="new" >New</label>
                            </div> */}

                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Total Rods <span style={{ color: 'red' }}>*</span></label>
                            <input type="number"
                                onChange={(e) => { setAddNew({ ...addNew, totalRods: e.target.value }) }}
                                value={addNew.totalRods}
                                readOnly
                                required
                            />
                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Total Bundles <span style={{ color: 'red' }}>*</span></label>
                            <input type="number"
                                onChange={(e) => { setAddNew({ ...addNew, totalBundles: e.target.value }) }}
                                value={addNew.totalBundles}
                                readOnly
                                required
                            />
                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Total Production Weight(T) <span style={{ color: 'red' }}>*</span></label>
                            <input type="number"
                                onChange={(e) => { setAddNew({ ...addNew, totalWeight: e.target.value }) }}
                                value={Math.floor(addNew.totalWeight * 1000) / 1000}
                                readOnly
                                required
                            />

                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Weight Bridge Weight(T) <span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="number"
                                value={addNew.weightBridgeWeight}
                                onChange={(e) => { decimalValidator(e) }}
                                step={'0.001'}
                                required
                            />
                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Gate Pass print Date <span style={{ color: 'red' }}>*</span></label>
                            <input type="date"
                                value={addNew.gatePassPrintDate}
                                onChange={(e) => { setAddNew({ ...addNew, gatePassPrintDate: e.target.value }) }}
                                required
                            />
                        </div>

                        <div className="filter">
                            <label htmlFor="orderId">Vehicle Fitment <span style={{ color: 'red' }}>*</span></label>
                            <div style={{ alignContent: 'center', fontWeight: 'normal', marginTop: '4px' }}>

                                <input
                                    type="radio"
                                    name='vehicleFitment'
                                    value={1}
                                    id='vehFit'
                                    required
                                    checked={addNew.vehicleFitment === 1}
                                    onChange={(e) => { setAddNew({ ...addNew, vehicleFitment: e.target.value }) }} />
                                <label htmlFor="vehFit">Fit</label>

                                <input
                                    type="radio"
                                    name='vehicleFitment'
                                    value={0}
                                    id='vehNFit'
                                    required
                                    checked={addNew.vehicleFitment === 0}
                                    onChange={(e) => { setAddNew({ ...addNew, vehicleFitment: e.target.value }) }} />
                                <label htmlFor="vehNFit" >Not Fit</label>
                            </div>
                        </div>

                        <div className="filter">
                            <label htmlFor="orderId">Driver Fitment<span style={{ color: 'red' }}>*</span></label>
                            <div style={{ alignContent: 'center', fontWeight: 'normal', marginTop: '4px' }}>

                                <input
                                    type="radio"
                                    name='driverFitment'
                                    value={1}
                                    id='drFit'
                                    required
                                    checked={addNew.driverFitment === 1}
                                    onChange={(e) => { setAddNew({ ...addNew, driverFitment: e.target.value }) }} />
                                <label htmlFor="drFit">Fit</label>

                                <input
                                    type="radio"
                                    name='driverFitment'
                                    value={0}
                                    id='drNFit'
                                    required
                                    checked={addNew.driverFitment === 0}
                                    onChange={(e) => { setAddNew({ ...addNew, driverFitment: e.target.value }) }} />
                                <label htmlFor="drNFit" >Not Fit</label>
                            </div>
                        </div>

                        {/* <div className="filter">
                            <label htmlFor="deliveryDate">Delivery Date</label>
                            <input type="date" />
                        </div> */}


                        <div className="filter order-details" style={{ maxHeight: '120px', justifyContent: 'flex-start' }}>
                            <label htmlFor="trailerNumber">Vehicle fitment details </label>
                            <textarea
                                value={addNew.vehicleFitmentDetails}
                                style={{ maxWidth: '200px', minHeight: '30px', minWidth: '100px', border: '2px solid #d9d9d9' }}
                                onChange={(e) => { setAddNew({ ...addNew, vehicleFitmentDetails: e.target.value }) }}

                            />

                        </div>
                        <div className="filter order-details" style={{ maxHeight: '120px', justifyContent: 'flex-start' }}>
                            <label htmlFor="trailerNumber">Driver fitment details </label>
                            <textarea
                                value={addNew.driverFitmentDetails}
                                style={{ maxWidth: '200px', minHeight: '30px', minWidth: '100px', border: '2px solid #d9d9d9' }}
                                onChange={(e) => { setAddNew({ ...addNew, driverFitmentDetails: e.target.value }) }}

                            />
                        </div>
                    </div>

                </div>
                <div className="head">Tag List

                    <div className="bottomMultiDel" style={{ fontSize: '15px', color: 'black', alignContent: 'flex-end' }}>
                        <input type="checkbox" id="tagmultiSel"
                            checked={tagMultiSelect === true}
                            onClick={(e) => { handleTagMultiSelect(!tagMultiSelect) }} />
                        <label htmlFor="tagmultiSel">Multiple Select</label>
                    </div>
                </div>
                <AGTable data={data} columns={columns} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} rowSelection={`${tagMultiSelect === true ? 'multiple' : 'single'}`} />

                {/* <AGTable data={data} columns={columns} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} /> */}


                <div className="bottom-button-container" style={{ alignItems: 'center' }}>

                    <div className="filter" style={{ width: 'fit-content' }}>

                        <label htmlFor="scannerInput">Scanner Input </label>

                        <input
                            type="text"
                            value={scanValue}
                            style={{ width: '20%', marginLeft: '3px' }}
                            onChange={(e) => handleScannerInput(e.target.value)}
                            id='scannerInput'
                        />


                    </div>

                    <Button label="Add bundle details" className="blue" onClick={() => handleAddButtonClick()} disabled={selectedRowIndex} />
                    <Button label="Remove selected bundles" className="blue" onClick={() => handleBundleDeletion()} disabled={selectedBundleRowIndex} />
                    <Button label="Save" className="blue" type='submit' />
                    {/* <Button label="Print bbs tabular" className="blue" /> */}
                    <Button label="Back" className="blue" link='loading details' />
                </div>
                <div className="head">
                    <div>Selected Bundle List</div>

                </div>
                <CustomBundleTable data={selectedBundleData} columns={bundleColumns} onSelectRow={handleBundleRowSelect} selectedRow={selectedRowIndex} rowSelection='multiple' />
            </form>


            {isAdding === true ? <CustomInput data={filteredBundles} columns={columns} setData={setData} isAdding={isAdding} setIsAdding={setIsAdding} selectedBundleIds={selectedBundleIds} setSelectedBundleIds={setSelectedBundleIds} setSelectedBundleData={setSelectedBundleData}/> : ''}


        </div>
    )
}

export default UpdateLoadingDetails