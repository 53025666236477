import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../assets/styles/orderDetails.css'
import Button from '../../../utils/Button';
import refresh from '../../../assets/images/button-icons/Refresh.svg';
import { useNavigate } from 'react-router-dom';
import FilterComponent from '../../../components/FilterComponent';
import BBSExcel from '../../../components/BBSExcel';
import { fetchOrder } from '../../../redux-tookit/slices/orderSlice';
import AGTable from '../../../components/AGTable2';
import { toast } from 'react-toastify';
import ExcelDownloadButton from '../../../components/ExcelDownloadButton';
import Loader from '../../../components/Loader/Loader';
import { ReactComponent as Close } from '../../../assets/images/icons/action/Exit.svg';
import filterIcon from '../../../assets/images/icons/action/filter-outline.svg';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
import { fetchUserAccess } from '../../../redux-tookit/slices/userAccessSlice';



const OrderDetails = () => {

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [permission, setPermission] = useState({});

	useEffect(() => {
		dispatch(fetchUserAccess())
			.then(res => {
				try {
					if (res.payload) {

						if (res.payload.navbarNames.Transaction["Order Details"][0]) {
							setPermission(res.payload.navbarNames.Transaction["Order Details"][0])
						}

					}

				} catch (error) {
					toast.error(`${error.message}`)
				}
			})
	}, [])


	// navSelectedOption: getNavSelOption(),
	// navExpandedOption
	const columns = useSelector((state) => state.order.columns);
	// const [loading, setLoading] = useState('pending')
	const loading = useSelector((state) => state.order.loading);
	const originalData = useSelector((state) => state.order.labeledData);

	const [data, setData] = useState(originalData);
	const [selectedRowIndex, setSelectedRowIndex] = useState(null);
	const [orderId, setOrderId] = useState('');
	const [combId, setCombId] = useState('')
	const [popUp, setPopUp] = useState(false);
	// const [sapId, setSapId] = useState('');
	// console.log(data);
	const [soldToPartyFilter, setSoldToPartyFilter] = useState('');
	const [orderStatusFilter, setOrderStatusFilter] = useState('');
	const [offerFilter, setOfferFilter] = useState('');
	const [orderRegionFilter, setOrderRegionFilter] = useState('');
	const [fromDateFilter, setFromDateFilter] = useState('');
	const [toDateFilter, setToDateFilter] = useState('');
	const [orderTypeFilter, setOrderTypeFilter] = useState('');
	const [productTypeFilter, setProductTypeFilter] = useState('');
	const [filterButton, setFilterButton] = useState('Close');

	const [soldToPartyValues, setSoldToPartyValues] = useState([]);
	const [orderStatusValues, setOrderStatusValues] = useState([]);
	const [offerValues, setOfferValues] = useState([]);
	// const [orderRegionValues, setOrderRegionValues] = useState([]);
	const [orderTypeValues, setOrderTypeValues] = useState([]);
	const [productTypeValues, setProductTypeValues] = useState([]);
	const [deselector, setDeselector] = useState(0)

	useEffect(() => {
		setSoldToPartyValues([...new Set(originalData.map(item => item.soldToParty))]);
		setOrderStatusValues([...new Set(originalData.map(item => item.bbsStatus))]);
		setOfferValues([...new Set(originalData.map(item => item.offers))]);
		// setOrderRegionValues([...new Set(originalData.map(item => item.orderRegion))]);
		setOrderTypeValues(['Normal', 'Rush']);
		setProductTypeValues(['Ready build', 'Smart FAB'])
		// ...new Set(originalData.map(item => item.orderType))
		setData(originalData);

	}, [originalData]);

	const handleRowSelect = (rowIndex) => {

		setSelectedRowIndex(rowIndex);
		console.log(rowIndex);
		if (rowIndex !== null && rowIndex !== undefined) {
			setOrderId(data[rowIndex].orderId);
			setCombId(data[rowIndex].combId)
		}
		if (rowIndex === undefined) {
			setSelectedRowIndex(null);
		}
		// setSapId(originalData[rowIndex].sapId);
	};



	const handleElementsButtonClick = () => {
		if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
			// setOrderId(originalData[selectedRowIndex].orderId);
			navigate(`/element/${originalData[selectedRowIndex].orderId}`, { state: { combId: originalData[selectedRowIndex].combId, orderId: originalData[selectedRowIndex].orderId } });
			// console.log("##");
		} else toast.error("No rows selected, Please select a row and try again"); setSelectedRowIndex(null);
	}

	const onFilterClick = () => {
		filterButton === 'Filter' ? setFilterButton('Close') : setFilterButton('Filter');
	}

	const applyFilters = () => {
		let filteredData = originalData;

		if (soldToPartyFilter) {
			filteredData = filteredData.filter((item) =>
				item.soldToParty === soldToPartyFilter
			);
		}

		if (orderStatusFilter) {
			filteredData = filteredData.filter((item) =>
				item.bbsStatus === orderStatusFilter
			);
		}

		if (offerFilter) {
			filteredData = filteredData.filter((item) =>
				item.offers === offerFilter
			);
		}

		if (orderRegionFilter) {
			filteredData = filteredData.filter((item) =>
				handleDate(item.deliveryDate) === orderRegionFilter
			);
		}

		if (fromDateFilter && toDateFilter) {
			const fromDate = new Date(fromDateFilter);
			const toDate = new Date(toDateFilter);

			filteredData = filteredData.filter((item) => {
				const orderDate = new Date(item.deliveryDate);
				console.log(orderDate)
				return orderDate >= fromDate && orderDate <= toDate;
			});
		}

		if (orderTypeFilter) {
			filteredData = filteredData.filter((item) =>
				item.orderType === orderTypeFilter
			);
		}

		if (productTypeFilter) {
			filteredData = filteredData.filter((item) =>
				item.productType === productTypeFilter
			);
		}

		setData(filteredData);
	};

	const handleRefresh = () => {
		setSoldToPartyFilter('');
		setOrderStatusFilter('');
		setOfferFilter('');
		setOrderTypeFilter('');
		setProductTypeFilter('')
		setFromDateFilter('');
		setToDateFilter('');
	}

	useEffect(() => {
		dispatch(fetchOrder())
	}, [dispatch]);


	useEffect(() => {
		applyFilters();
	}, [soldToPartyFilter, orderStatusFilter, offerFilter, orderRegionFilter, fromDateFilter, toDateFilter, orderTypeFilter, productTypeFilter]);



	const openBBSExcel = () => {

		setDeselector(deselector + 1)
		setSelectedRowIndex(null)
		setPopUp(true);
	}

	const closeBBSExcel = () => {
		setSelectedRowIndex(null)
		setPopUp(false);
	}

	// const handleOrderRegionFilter = (color) => {

	// }

	console.log("this");

	const handleDate = (dateString) => {
		const [year, month, day] = dateString.split('-');
		// console.log('apple');
		// Convert day, month, and year to numbers
		const numericDay = parseInt(day, 10);
		const numericMonth = parseInt(month, 10) - 1; // Adjust for zero-based months
		const numericYear = parseInt(year, 10);

		const parsedDate = new Date(numericYear, numericMonth, numericDay, 23);
		const today = new Date();

		// Calculate the difference in days
		const differenceInDays = Math.floor((parsedDate - today) / (24 * 60 * 60 * 1000));
		//  console.log(differenceInDays)
		// Apply color logic based on the difference in days
		if (differenceInDays > 4) {
			//   console.log('Green');
			return 'green';
		} else if (differenceInDays > 2 && differenceInDays <= 4) {
			//   console.log('Yellow');
			return 'yellow';
		} else if (differenceInDays <= 2 && differenceInDays >= 0) {
			//   console.log('Red');
			return 'red';
		} else {
			//   console.log('Black');
			return 'black';
		}
	};

	return (
		<div className='sdbr'>
			{loading === 'pending' ? <Loader /> : <>
				{/* <Button label={filterButton} onClick={onFilterClick} className='blue' /> */}
				<div className="Filter-drop" style={{ display: `${filterButton === 'Close' ? `block` : `none`}` }}>
					<div className="filter-card stockDetails" style={{ position: 'relative' }}>
						<div className="filter-layout" style={{ gridTemplateRows: 'none' }}>
							<FilterComponent
								label="Sold To Party"
								filterValue={soldToPartyFilter}
								options={soldToPartyValues}
								onFilterChange={setSoldToPartyFilter}
							/>

							<FilterComponent
								label="BBS Status"
								filterValue={orderStatusFilter}
								options={orderStatusValues}
								onFilterChange={setOrderStatusFilter}
							/>

							{/* <FilterComponent
								label="Offer"
								filterValue={offerFilter}
								options={offerValues}
								onFilterChange={setOfferFilter}
							/> */}

							<FilterComponent
								label="Order Region"
								filterValue={orderRegionFilter}
								options={['green', 'yellow', 'red', 'black']}
								onFilterChange={setOrderRegionFilter}
							/>

							<FilterComponent
								label="Order Type"
								filterValue={orderTypeFilter}
								options={orderTypeValues}
								onFilterChange={setOrderTypeFilter}
							/>
							<FilterComponent
								label="Product Type"
								filterValue={productTypeFilter}
								options={productTypeValues}
								onFilterChange={setProductTypeFilter}
							/>
							<div className="filter-bottom">

								<div className='DateCont'>
									<label className='DateHead'>Order Delivery Date</label>
									<div className="DateFilt">
										<div className="filterD">
											<label htmlFor="fromDateFilter">From</label>
											<input
												id="fromDateFilter"
												type="date"
												value={fromDateFilter}
												onChange={(e) => setFromDateFilter(e.target.value)}
											/>
										</div>
										<div className="filterD">
											<label htmlFor="toDateFilter">To</label>
											<input
												id="toDateFilter"
												type="date"
												value={toDateFilter}
												onChange={(e) => setToDateFilter(e.target.value)}
											/>
										</div>
									</div>

								</div>

							</div>



						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'end', position: 'absolute', right: '35px', top: '7px' }}>
							<img src={refresh} alt="refresh" onClick={handleRefresh} title='Refresh filters' />
						</div>
						<div className='filter-close-button' onClick={onFilterClick}>
							<Close alt="close" className='close' />
						</div>
					</div>

				</div>

				<div className='head'>
					<div> Order Details</div>
					<div className='top-button-container'>
						
						<TopButtonContainer
							add={() => {
								navigate(`/add new/${null}`)
							}}
							edit={() => {
								if (orderId !== '' && orderId !== undefined)
									navigate(`/add new/${orderId}`)
								else toast.error('Select an order and try again')
							}}
							access={permission} accessOverride={[1, 1, 0]} />

						{/* <img src={refresh} alt="" onClick={handleRefresh} /> */}
						<img title='show filter' src={filterIcon} alt="filter" onClick={onFilterClick} />
						<ExcelDownloadButton csvData={data} fileName='OrderList' />
					</div>
				</div>
				<AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} deselector={deselector} loader={loading} height={filterButton === 'Close' ? 290 : 480}/>
				<div className="bottom-button-container">
					{/* <Button label="Production Parameters" className='blue' onClick={() => navigate(`/production parameters/${orderId}`, { state: { combId: originalData[selectedRowIndex].combId, orderId: originalData[selectedRowIndex].orderId } })} disabled={selectedRowIndex} /> */}

					<Button label="BBS" className='blue' onClick={() => navigate(`/bbs detail/${orderId}`, { state: { combId: originalData[selectedRowIndex].combId, orderId: originalData[selectedRowIndex].orderId } })} disabled={selectedRowIndex} />
					<Button label="BBS Excel" className='blue' onClick={openBBSExcel} disabled={selectedRowIndex} />
					{/* <Button label="Summary" className='blue' onClick={() => navigate(`/summary/${orderId}`)} disabled={selectedRowIndex} /> */}
					<Button label="Elements" className='blue' onClick={handleElementsButtonClick} disabled={selectedRowIndex} />
					{/* <Button label="SAP Order Summary" className='blue' onClick={() => navigate(`/sap summary/${sapId}`)} disabled={selectedRowIndex} /> */}
					{/* <Button label="Order Cost" className='blue' disabled={selectedRowIndex} /> */}
					<BBSExcel isOpen={popUp} onClose={closeBBSExcel} orderId={orderId} combId={combId} selectedRowIndex={selectedRowIndex} />
				</div></>}
		</div>
	);
};

export default OrderDetails;
//End of File