import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';

 
const initialState = {
  columns: {},
    shapes: [],
    loading: 'idle',
    error: null,
    fulfiller: ''
  };

  
  export const fetchFavShapes = createAsyncThunk('data/fetchFavShapes', async () => {
    try {
      const response = await api.listFavShape();
      const json= response.data;
      
      const shapes = json.rows.map((row)=>{
        return row;
      })
      return { shapes };
    } catch (error) {
      throw error;
    }
  });
  

  export const postFavShapes = createAsyncThunk('data/postFavShape', async (newShape) => {
    try {
      
      const response = await api.addFavShape(newShape);
      const data = response.data;
      
      console.log('Success');
      return data;
    } catch (error) {
      throw error.response.data;
    }
  });

  export const deleteFavShapes = createAsyncThunk('data/deleteFavShape', async (id) => {
    try {
      await api.deleteFavShape(id);
      return id;
    } catch (error) {
      throw error.response.data;
    }
  });

  
  const favShapeSlice = createSlice({
    name: 'favShape',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(fetchFavShapes.pending, (state) => {
            state.loading = 'pending';
        })
        .addCase(fetchFavShapes.fulfilled, (state, action) => {
            state.loading = 'fulfilled';
            state.shapes = action.payload.shapes;
            
        })
        .addCase(fetchFavShapes.rejected, (state, action) => {
            state.loading = 'rejected';
            state.error = action.error.message;
        })
        .addCase(postFavShapes.pending, (state) => {
          state.loading = 'pending';
          console.log('pen')
        })
        .addCase(postFavShapes.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          console.log('gg',state.loading);
          // state.shapes.push(action.payload);
          toast.success("Shape added Favourites");
          //useNavigate('/shapes')
        })
        .addCase(postFavShapes.rejected, (state, action) => {
          state.loading = 'rejected';
          state.error = action.error.message;
          console.log('rej')
          toast.error(state.error)
        })
        
        .addCase(deleteFavShapes.pending, (state) => {
          state.loading = 'pending';
        })
        .addCase(deleteFavShapes.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          console.log(state.loading);

          // state.labeledData=state.labeledData.filter((data) => data.s !== deletedId);
          toast.success('Shape removed from Favourites');
        })
        .addCase(deleteFavShapes.rejected, (state, action) => {
          state.loading = 'rejected';
          state.error = action.error.message;
          toast.error(state.error);
        });
    
    }
  });
   
  export default favShapeSlice.reducer
  //End of File