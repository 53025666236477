import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API'; // Import your Axios API functions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
  columns: {},
  labeledData: [], // Initialize with an empty array
  loading: 'idle',
  error: null,
  resp: {}
};

export const fetchCustomerParties = createAsyncThunk('data/fetchCustomerParties', async () => {
  try {
    // Fetch data using Axios
    const response = await api.listCustomerParties();
    const json = response.data;

    const columnLabels = {
      customerPartiesId: { label: "Customer Parties Id", type: 'number', isRequired: false, editable: false },
      soldToParty: { label: "Sold to Party", type: 'number', isRequired: true, editable: false },
      soldToPartyId: { label: "Sold to Party Id", type: 'number', isRequired: true, editable: false },
      soldToPartyAddress: { label: "Sold to Party address", type: 'text' },
      soldToPartyEmail: { label: 'Sold To Party email', type: 'email' },
      soldToPartyPhoneNo: { label: 'Sold To Party phone no', type: 'email' },
      billToParty: { label: "Bill to Party", type: 'number', isRequired: true, editable: true },
      billToPartyId: { label: "Bill to Party Id", type: 'number', isRequired: true, editable: true },
      billToPartyAddress:{ label:"Bill to Party address", type:"text"},
      billToPartyEmail:{ label:"Bill to Party email", type:'email'},
      billToPartyPhoneNo:{ label:"Bill to Party phone no"},
      shipToParty: { label: "Ship to Party", type: 'number', isRequired: true, editable: true },
      shipToPartyId: { label: "Ship to Party Id", type: 'number', isRequired: true, editable: true },
      shipToPartyAddress:{label: "Ship tp Party address", type:'text'},
      shipToPartyEmail:{label:"Ship to Party email", type:"email"},
      shipToPartyPhoneNo:{label:"Ship to Party phone no"},


      // Add more as needed
    };

    const selectedColumns = [
      'customerPartiesId',
      'soldToParty',
      'soldToPartyId',
      'soldToPartyAddress',
      'soldToPartyEmail',
      'soldToPartyPhoneNo',
      'billToParty',
      'billToPartyId',
      'billToPartyAddress',
      'billToPartyEmail',
      'billToPartyPhoneNo',
      'shipToParty',
      'shipToPartyId',
      'shipToPartyAddress',
      'shipToPartyEmail',
      'shipToPartyPhoneNo',
    ];

    const displayColumns = ['customerPartiesId', 'soldToParty', 'billToParty', 'shipToParty', 'soldToPartyId', 'billToParty', 'shipToParty'];

    const columns = {};

    displayColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });
    // selectedColumns.forEach((columnName) => {
    //   columns[columnName] = columnLabels[columnName];
    // });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});

export const postCustomerParties = createAsyncThunk('data/postCustomerParties', async (newRow) => {
  try {
    // Make a POST request to your API
    const response = await api.addCustomerParties(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const updateCustomerParties = createAsyncThunk('data/updateCustomerParties', async ({ id, type }) => {
  try {
    const response = await api.updateCustomerParties(id, type);
    const data = response.data;
    console.log('Update Success');
    return { data, id, type };
  } catch (error) {
    throw error;
  }
});

export const deleteCustomerParties = createAsyncThunk('data/deleteCustomerParties', async (id) => {
  try {
    await api.deleteCustomerParties(id);
    return id;
  } catch (error) {
    throw error;
  }
});

const customerPartiesSlice = createSlice({
  name: 'customerParties',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomerParties.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchCustomerParties.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchCustomerParties.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(postCustomerParties.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postCustomerParties.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.resp = action.payload.customerPartiesId
        console.log(action.payload);
        state.labeledData.push(action.payload);
        // toast.success('Customer Parties added successfully!');
      })
      .addCase(postCustomerParties.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add Customer Parties');
      })
      .addCase(updateCustomerParties.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(updateCustomerParties.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;

        // const index = state.labeledData.find(item => item.customerPartiesId === action.payload.id);
        // if (index) {
        // 	const updatedItem = { customerPartiesId: action.payload.id, ...action.payload.type };
        // 	console.log(updatedItem);
        // 	state.labeledData = state.labeledData.map(item => {
        // 		if (item.customerPartiesId === action.payload.id) {
        // 			return updatedItem;
        // 		}
        // 		return item;
        // 	})
        // }
        toast.success('Customer Parties updated sucessfully');
      })
      .addCase(updateCustomerParties.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update Customer Parties');
      })
      .addCase(deleteCustomerParties.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteCustomerParties.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        const deletedId = action.payload;
        state.labeledData = state.labeledData.filter((data) => data.customerPartiesId !== deletedId);
        toast.success('Customer Parties deleted sucessfully');
      })
      .addCase(deleteCustomerParties.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error(state.error);
      });
  },
});

export default customerPartiesSlice.reducer;
//End of File