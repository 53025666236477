import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import GenericTable from '../../components/GenericTable';
import Button from '../../utils/Button';
import edit from '../../assets/images/button-icons/edit.svg';
import '../../assets/styles/TpmRate.css'
import '../../assets/styles/GenericTable.css'
import FilterComponent from '../../components/FilterComponent';

const PlantCapacity = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.plantCapacity.columns);
  const originalData = useSelector((state) => state.sdbr.plantCapacity.data);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState('coil');
  const [data, setData] = useState(originalData);


  const handleRowSelect = (rowIndex) => {
    console.log(data);
    setSelectedRowIndex(rowIndex);
  };

  const [routeFilter, setRouteFilter] = useState('coil');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');

  const routeValues = [...new Set(originalData.map(item => item.route))];


  const applyFilters = () => {

    let filteredData = originalData;

    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.date);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }

    if (routeFilter) {
      filteredData = filteredData.filter((item) =>
        item.route.includes(routeFilter)
      );
    }

    setData(filteredData);
  };

  useEffect(() => {

    dispatch(fetchDataFromApi('plantCapacity'));
    setData(originalData);

  }, [dispatch, originalData]);

  useEffect(() => {

    applyFilters();

  }, [ routeFilter, fromDateFilter, toDateFilter]);

  const filteredColumns = columns.filter((column) => column.key !== 'route');


  return (
    <div className='sdbr'>

      <div className='filter-card'>
        <div className="Plant-row-1">
          <FilterComponent
            label="Route Id"
            filterValue={routeFilter}
            options={routeValues}
            onFilterChange={setRouteFilter}
          />

          <div className="filter">
            <label htmlFor="fromDateFilter">From </label>
            <input
              id="fromDateFilter"
              type="date"
              value={fromDateFilter}
              onChange={(e) => setFromDateFilter(e.target.value)}
            />
          </div>
          <div className="filter">
            <label htmlFor="toDateFilter">To </label>
            <input
              id="toDateFilter"
              type="date"
              value={toDateFilter}
              onChange={(e) => setToDateFilter(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className='head'>
        <div>Plant Capacity</div>
        <div className="top-button-container">
        <img src={edit} alt="" />
        </div>
      </div>

      <div>
        <GenericTable columns={filteredColumns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
      </div>
      <div className='bottom-button-container'><Button label='Close' className='blue'/>{selectedRowIndex !== null ? <div className='Buttons'><Button label='Save' className='blue'/><Button label="Cancel" className='blue' onClick={()=>setSelectedRowIndex(null)}/></div> : ""}</div>
    </div>
  );
};

export default PlantCapacity;