import React, { useState, useEffect } from "react";
import * as api from "../../../services/API";
import { Line, Bar } from "react-chartjs-2";
import "../Dashboard/Dashboard";
const DashBoard = () => {
  const [
    regionWiseSeviceCenterDataForApp,
    setRegionWiseSeviceCenterDataForApp,
  ] = useState([]);
  const [
    regionWiseSeviceCenterDataForDailyWise,
    setRegionWiseSeviceCenterDataForDailyWise,
  ] = useState([]);
  const [regionForAppMetrics, setRegionForAppMetrics] = useState("All");
  const [serviceCenterData, setServiceCenterData] = useState([]);
  const [serviceCenterListData, setServiceCenterListData] = useState([]);
  const [name, setServiceCenterName] = useState("All");
  const [maxDate, setMaxDate] = useState(getTodayDate());
  const [date, setDate] = useState(
    (regionForAppMetrics !== "All" && name != "All" && maxDate) || ""
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const regions = ["North", "South", "East", "West"];
  const roleName = localStorage.getItem("roleName");
  console.log(roleName, "roleName");
  const regionName = localStorage.getItem("region");
  function getTodayDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const getServiceCenterList = async () => {
    await api
      .listServiceCenter()
      .then((result) => {
        setServiceCenterListData(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getServiceCenterList();
  }, []);

  useEffect(() => {
    getAppMetricsByRegions();
  }, [regionForAppMetrics]);
  const getAppMetricsByRegions = async () => {
    let regionWise = "";
    if (roleName === "superadmin") {
      regionWise += `?region=${regionForAppMetrics}`;
    }
    await api
      .listServiceCenterByRegion(regionWise)
      .then((result) => {
        setRegionWiseSeviceCenterDataForApp(result?.data?.rows);
        setDate("");
        setServiceCenterName("All");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const optionsForAppMetrics = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Success Count",
        },
        stacked: true,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      // tooltip: {
      //   interaction: {
      //     intersect: false,
      //   },
      // },
      tooltip: {
        enabled: false, // Disable default tooltips
      },
      datalabels: {
        display: true,
        align: "top",
        anchor: "end",
        color: "black", // Customize color if needed
        font: {
          weight: "bold", // Make the text bold if needed
        },
        formatter: function (value, context) {
          return value; // Display data values on bars
        },
      },
      title: {
        display: true,
      },
    },
  };

  const labelsForGraph = serviceCenterData.map((item) => {
    return item?.functionName;
  });
  const dataForGraph2 = serviceCenterData.map((item) => {
    return item?.isSuccessCount;
  });
  const dailyGraph = {
    labels: [...new Set(labelsForGraph)],
    datasets: [
      {
        // label: "Total Quantity",
        data: [dataForGraph2].flat(),
        borderColor: "#3480eb",
        backgroundColor: ["#3480eb"],
        barThickness: 35,
        spanGaps: true,
      },
    ],
  };

  useEffect(() => {
    getAppMatricsByServId();
    getAppByRegions();
  }, [regionForAppMetrics, name, date]);
  const getAppByRegions = async () => {
    let regionWise = "";
    if (roleName === "superadmin") {
      regionWise = `?region=${regionForAppMetrics}`;
    } else {
      regionWise = `?region=${regionName}`;
    }
    await api
      .listServiceCenterByRegion(regionWise)
      .then((result) => {
        setRegionWiseSeviceCenterDataForDailyWise(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const serviceData = regionWiseSeviceCenterDataForApp.filter(
      (item) => item.name === name
    );
    const serviceDataForAllRegionsByName = serviceCenterListData.filter(
      (item) => item.name === name
    );

    if (serviceData.length > 0 || serviceDataForAllRegionsByName.length > 0) {
      getAppMatricsByServId(
        serviceData[0]?.serviceCenterId ||
          serviceDataForAllRegionsByName[0]?.serviceCenterId
      );
    }
    if (serviceDataForAllRegionsByName.length > 0) {
      getAppMatricsByServId(serviceDataForAllRegionsByName[0]?.serviceCenterId);
    }
  }, [name, regionForAppMetrics, regionName, date]);
  const getAppMatricsByServId = async (serviceCenterId) => {
    let queryString = "";
    if (regionForAppMetrics !== "All") {
      queryString += `?region=${regionForAppMetrics}&`;
    }
    if (serviceCenterId) {
      queryString += `${
        regionForAppMetrics === "All"
          ? `?serviceCenterId=${serviceCenterId}&`
          : `serviceCenterId=${serviceCenterId}&`
      }`;
    }

    if (roleName === "superadmin") {
      if (regionForAppMetrics === "All" && serviceCenterId) {
        queryString += `Date=${date}`;
      } else if (regionForAppMetrics !== "All" && serviceCenterId && date) {
        queryString += `Date=${date}`;
      } else if (regionForAppMetrics === "All" && !serviceCenterId) {
        queryString += `?Date=${date}`;
      }
    }
    if (roleName === "admin" || roleName === "regionalManager") {
      if (serviceCenterId) {
        queryString += `Date=${date}`;
      } else {
        queryString += `?Date=${date}`;
      }
    }

    // if (roleName === "superadmin" && regionForAppMetrics === "All") {
    //   if (serviceCenterId) {
    //     queryString += `Date=${date}`;
    //   }
    // }

    await api
      .listDashBoardApp(queryString)
      .then((result) => {
        if (result?.data?.rows.length > 0) {
          setServiceCenterData(result?.data?.rows);
        } else {
          setServiceCenterData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="sdbr">
        <div className="head">CuBe+ Usage</div>

        <div
          className="filter-card"
          style={{
            margin: "20px 10px",
            padding: "20px 20px",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
            {roleName === "superadmin" ? (
              <span style={{ padding: "2px", paddingRight: "30px" }}>
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Regions:
                </label>
                <select
                  name="grade"
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setRegionForAppMetrics(e.target.value)}
                >
                  <option value="All">All</option>
                  {regions.map((region, index) => (
                    <option key={index} value={region}>
                      {region}
                    </option>
                  ))}
                </select>
              </span>
            ) : (
              <>
                {roleName !== "admin" && roleName !== "salesManager" && (
                  <label
                    htmlFor="dia-total-gradeFil"
                    style={{ color: "black" }}
                  >
                    Region:
                    <span style={{ padding: "2px", paddingRight: "30px" }}>
                      {" "}
                      {regionName.charAt(0).toUpperCase() + regionName.slice(1)}
                    </span>
                  </label>
                )}
              </>
            )}
            {roleName !== "admin" && (
              <>
                {" "}
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Service Center Name
                </label>
                <select
                  name="grade"
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setServiceCenterName(e.target.value)}
                >
                  <option value="All">All</option>

                  {[
                    ...new Set(
                      regionForAppMetrics === "All"
                        ? serviceCenterListData.map((item) => item.name)
                        : regionWiseSeviceCenterDataForDailyWise.map(
                            (item) => item.name
                          )
                    ),
                  ].map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </>
            )}
            <span style={{ padding: "2px", paddingRight: "30px" }}>
              <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                Date:
              </label>

              <input
                name="grade"
                type="date"
                value={date}
                id="dia-total-gradeFil"
                style={{ width: "fit-content" }}
                onChange={(e) => setDate(e.target.value)}
                max={maxDate}
              ></input>
            </span>
          </div>
        </div>
        <div className="chart-box">
          <Bar
            // className={hmiCss.formGroup2}
            data={dailyGraph}
            options={optionsForAppMetrics}
            height={400}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DashBoard;
