
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const initialState = {
	columns: {},
	labeledData: [],
	fetcher: true,
	loading: 'idle',
	error: null,
};

export const fetchGrades = createAsyncThunk('data/fetchGrades', async () => {
	try {
		const response = await api.listGrades();
		const json = response.data;

		const columnLabels = {
			gradeId: { label: 'Grade ID', type: 'number' , isRequired: true, pattern: ".*", editable: false },
			material: { label: 'Material', type: 'text' , isRequired: true, pattern: ".*", editable: false },
			productName: { label: 'Product Name', type: 'text' , isRequired: true, pattern: ".*", editable: true },
			materialType: { label: 'Material Type', type: 'text', isRequired: true, pattern: ".*", editable: false },
			gradeName: { label: 'Grade', type: 'text' , isRequired: true, pattern: ".*", editable: false },
			diameter: { label: 'Diameter(mm)', type: 'number' , isRequired: true, pattern: ".*", editable: false },
			barType: { label: 'Bar Type', type: 'text' , isRequired: true, pattern: ".*", editable: false },
			density: { label: 'Density', type: 'number' , isRequired: true, pattern: ".*", editable: false },
			minLength: { label: 'Min. Length(mm)', type: 'number' , isRequired: true, pattern: ".*", editable: false },
			maxLength: { label: 'Max. Length(mm)', type: 'number' , isRequired: true, pattern: ".*", editable: false },
			RM_FG_Remnant: { label: 'RM/FG/Remnant', type: 'text' , isRequired: true, pattern: ".*", editable: false },
			productMasterId: { label: 'Product ID', type: 'number' , isRequired: true, pattern: ".*", editable: false },
			// Add more as needed
		};
		
		const selectedColumns = ['gradeId', 'productName','material', 'materialType', 'gradeName', 'diameter',  'productMasterId', 'barType', 'density', 'minLength', 'maxLength', 'RM_FG_Remnant'];
		const columns = {};
		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});
		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});
		return { columns, labeledData };
	} catch (error) {
		throw error;
	}
});

export const postGrades = createAsyncThunk('data/postGrades', async (newRow) => {
	try {
		const response = await api.addGrades(newRow);
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		throw error;
	}
});

export const updateGrade = createAsyncThunk('data/updateGrade', async ({ id, type }) => {
	try {
		console.log(id);
		const response = await api.updateGrade(id, type);
		const data = response.data;
		console.log('Update Success');
		return { data, id, type };
	} catch (error) {
		throw error;
	}
});
export const deleteGrade = createAsyncThunk('data/deleteGrade', async (id) => {
	try {
		await api.deleteGrade(id);
		return id;
	} catch (error) {
		console.log(error);
		throw error.response.data;
	}
});
const gradeMasterSlice = createSlice({
	name: 'gradeMaster',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(fetchGrades.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(fetchGrades.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns = action.payload.columns;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchGrades.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(postGrades.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postGrades.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.labeledData.push(action.payload);
				toast.success('Grade added successfully');
			})
			.addCase(postGrades.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to add Grade');
			})
			.addCase(updateGrade.pending, (state) => {
				state.loading = 'pending';

			})
			.addCase(updateGrade.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;

				const index = state.labeledData.find(item => item.gradeId === action.payload.id);
				if (index) {
					const updatedItem = { gradeId: action.payload.id, ...action.payload.type };
					console.log(updatedItem);
					state.labeledData = state.labeledData.map(item => {
						if (item.gradeId === action.payload.id) {
							return updatedItem;
						}
						return item;
					})
				}
				toast.success('Grade updated sucessfully');
			})
			.addCase(updateGrade.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to update Grade');
			})
			.addCase(deleteGrade.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(deleteGrade.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				const deletedId = action.payload;
				state.labeledData = state.labeledData.filter((data) => data.gradeId !== deletedId);
				toast.success('Grade Deleted successfully');
			})
			.addCase(deleteGrade.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error(state.error);
			});
	},
});

export default gradeMasterSlice.reducer;
//End of File
