import React, { useEffect, useState } from 'react'
import AGTable from '../../../components/AGTable'
import Button from '../../../utils/Button.jsx'

import { useDispatch, useSelector } from 'react-redux'
import { fetchOrderItems } from '../../../redux-tookit/slices/orderItemsSlice.jsx'
import CustomInput from './CustomInput.jsx'
import { fetchBundles, fetchFgPartItems, fetchLoadingList, fetchLoadingListSF, postLoadingList, postLoadingListSF } from '../../../redux-tookit/slices/loadingListSlice.jsx'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { fetchOrder, fetchOrderSF } from '../../../redux-tookit/slices/orderSlice.jsx'
import CustomBundleTable from './CustomBundleTable.jsx'
import { fetchProductionParameters } from '../../../redux-tookit/slices/smartFab/productionParameterSlice.jsx'

const CreateSmartFabLL = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loadingDs, setLoadingDs] = useState('new')
    // const [tagMultiSelect, setTagMultiSelect] = useState(false);

    const getCurrentDateTime = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');

        return `${year}-${month}-${day}`
    }

    const [addNew, setAddNew] = useState({ totalWeight: 0, vehicleFitmentDetails: '', driverFitmentDetails: '', gatePassPrintDate: getCurrentDateTime(), driverFitment: '1', vehicleFitment: '1' })

    const loadData = useSelector((state) => state.loadingList.labeledData);

    const [id, setId] = useState(0);

    const columns = useSelector((state) => state.productionParameter.columns)

    const originalData = useSelector((state) => state.productionParameter.labeledData)

    const bundles = useSelector((state) => state.loadingList.labeledData4)

    const sortedArray = originalData.map(item => {
        return { orderId: item.orderId, combId: item.combId }
    }).sort((a, b) => a.combId - b.combId)

    const orderIds = originalData.map(item => {
        return { orderId: item.orderId, combId: item.combId }
    }).sort((a, b) => a.combId - b.combId).filter((data, index) => {
        if (index !== 0) {
            if (data.orderId !== sortedArray[index - 1].orderId)
                return true
            else return false
        } else return true

    })

    const [selectedRowIndex, setSelectedRowIndex] = useState(null)

    const [selectedParamsRowIndex, setSelectedParamsRowIndex] = useState(null)

    const [ selectedParamsData, setSelectedParamsData ] = useState([])

    const [loadNames, setLoadNames] = useState([])

    const [data, setData] = useState(originalData.filter(item => `${item.orderId}` === id))

    console.log(addNew);

    const [selectedParamIds, setSelectedParamIds] = useState([]);

    const handleRowSelect = (rowIndex) => {
        if (rowIndex !== undefined) {

            setSelectedRowIndex(rowIndex);
            // handleTotalWeight(rowIndex)

        } else setSelectedRowIndex(null)
    };

    // const handleBundleRowSelect = (rowIndex) => {
    //     if (rowIndex !== undefined) {

    //         setSelectedParamsRowIndex(rowIndex);
    //         // handleTotalWeight(rowIndex)

    //     } else setSelectedParamsRowIndex(null)
    // }

    const removeItems = (arr, indices) => {
        let newArray = [...arr];
        indices.sort((a, b) => b - a);
        indices.forEach(index => newArray.splice(index, 1));
        return newArray;
    }

    const handleSelectedParamsDeletion = () => {

        const selParamIds = removeItems(selectedParamIds, selectedParamsRowIndex)

        setSelectedParamIds(selParamIds)
        setSelectedParamsData(prev => removeItems(prev, selectedParamsRowIndex))

        setData(originalData.filter(item => `${item.orderId}` === id).filter(item => !selParamIds.includes(item.smartfabProductionRecordingId)))

        setSelectedParamsRowIndex(null)
       
    }

    const handleAddButtonClick = () => {

        if (selectedRowIndex !== null) {

            console.log(selectedRowIndex);

            const paramIds = selectedRowIndex.map(index => data[index].smartfabProductionRecordingId);

            setSelectedParamIds(  [ ...selectedParamIds, ...paramIds ]  );

            console.log(paramIds, selectedRowIndex.map(index => data[index]));
            
            setSelectedParamsData([ ...selectedParamsData, ...selectedRowIndex.map(index => data[index])])

            setData(originalData.filter(item => `${item.orderId}` === id).filter(item => !paramIds.includes(item.smartfabProductionRecordingId)))
        } 

    }

    const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        // console.log(Math.abs(addNew.weightBridgeWeight*1000-addNew.totalWeight)*100/addNew.totalWeight);

        if (addNew.vehicleFitment !== "1") {
            toast.error("Vehicle not fit. Cannot proceed with an unfit vehicle")
            return
        }
        if (addNew.driverFitment !== "1") {
            toast.error("Driver not fit. Cannot proceed with an unfit driver")
            return
        }
        if (selectedParamIds.length === 0) {
            toast.error("No bundles selected")
            return
        }
        // if (addNew.totalWeight > 2)
        //     if (Math.abs(addNew.weightBridgeWeight - addNew.totalWeight) * 100 / addNew.totalWeight > 0.5) {
        //         toast.error("Weighbridge weight should be within 0.5% of Production weight")
        //         return
        //     }

        let orderIds = [...new Set(originalData
            .filter(item => selectedParamIds.includes(item.smartfabProductionRecordingId))
            .map(item => item.orderId))].toString()

        let paramsId = selectedParamIds.toString()

        const newRow = {
            ...addNew,
            "gatePassNo": 1,
            smartfabProductionRecordingId: paramsId,
            orderId: orderIds,
            loadingListRemarks: '',
            totalWeight: addNew.totalWeight
        }

        // let orderIds = [...new Set(bundles.filter(item => selectedParamIds.includes(item.bundleId)).map(item => item.orderId))]

        // let bundleDetails = [];



        // bundles.forEach((data, index) => {
        //     if (selectedParamIds.includes(data.bundleId)) {

        //         bundleDetails.push({
        //             orderId: data.orderId,
        //             bundleId: data.bundleId,
        //             weight: data.weight,
        //             rods: data.noOfPieces
        //         })
        //     }
        // })

        // let bundleId = selectedParamIds.toString()


        // const newRow = {
        //     ...addNew,
        //     "gatePassNo": 1,
        //     bundleId: bundleId,
        //     loadingListRemarks: ''

        // }

        dispatch(postLoadingListSF(newRow)).then((res) => {
            if (res.payload !== undefined) {
                console.log(res.payload);
                toast.success(res.payload.message)
                setTimeout(() => {
                    navigate(`/loading details`);
                }, 2000)
            }
        })
        console.log(newRow)
    }

    useEffect(() => {

        dispatch(fetchProductionParameters('?productionStatus=0'))
        dispatch(fetchLoadingListSF());
        dispatch(fetchOrderSF())
        setData(originalData.filter(item => `${item.orderId}` === id).filter(item => !selectedParamIds.includes(item.smartfabProductionRecordingId)))

    }, [id])

    const bundleValueCalculator = () => {
        try {
            let totalWeight = 0;
            let noOfRods = 0;

            selectedParamsData.forEach(row => {
               
                        totalWeight += row.totalWeight;
                        noOfRods += row.totalSheets;

            })

            const addData = {
                totalRods: noOfRods,
                totalBundles: selectedParamIds.length,
                totalWeight: totalWeight
            }
            setAddNew({ ...addNew, ...addData })
          

        } catch (error) {
            throw error
        }
    }

    useEffect(() => {
        setLoadNames([...new Set(loadData.map(item => item.loadingListName))])
    }, [loadData])


    useEffect(() => {

        bundleValueCalculator()

    }, [selectedParamIds])


    const decimalValidator = (e) => {
        let match = false

        if (e.target.value.match(/^[0-9]*[.]?[0-9]{0,2}$/)) match = true

        if (match === true) {

            setAddNew({ ...addNew, weightBridgeWeight: e.target.value })

        }
    }

    console.log(selectedParamsData);
    
    

    return (
        <div className='sdbr'>
            <div className="head">Create Loading List</div>
            <form onSubmit={handleSaveButtonClick}>
                <div className='filter-card'>
                    <div className="Sumfilter-layout"
                        style={{ maxHeight: '250px', gridTemplateRows: '1fr 1fr 1fr', gap: '10px' }}
                    >

                        {/* <div className="filter">
                            <label htmlFor="totalProdWeight" >Total Prod. Weight(Kg)</label>
                            <input type="number" />
                        </div>
                        <div className="filter">
                            <label htmlFor="totalBundles">Total Bundles</label>
                            <input type="number" />
                        </div> */}

                        {/* <div className="filter">
                            <label htmlFor="sapDlvNo">SAP Dlv No./Tally Inv No</label>
                            <input type="text" />
                        </div> */}

                        {/* <div className="filter">
                            <label htmlFor="totalRods">Total Rods</label>
                            <input type="number" />
                        </div> */}

                        <div className="filter">
                            <label htmlFor="orderId">Order ID </label>
                            <select name="orderId" id="orderId" onChange={(e) => setId(e.target.value)}>
                                <option value="">-Select-</option>
                                {orderIds.map((id) =>
                                    (<option value={id.orderId}>{id.combId}</option>))}
                            </select>
                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Vehicle No. <span style={{ color: 'red' }}>*</span></label>
                            <input type="text"
                                onChange={(e) => { setAddNew({ ...addNew, trailerNo: e.target.value }) }}
                                required
                            />
                        </div>

                        <div className="filter" >
                            <label htmlFor="loadingList">Loading List Name  <span style={{ color: 'red' }}>*</span></label>

                            {
                                loadingDs === 'existing' ?
                                    <select name="loadingList" id="loadingList" style={{ paddingBottom: '7.6px' }} onChange={(e) => { setAddNew({ ...addNew, loadingListName: e.target.value }) }}>
                                        <option value="">-Select-</option>
                                        {loadNames.map(item => (
                                            <option>
                                                {item}
                                            </option>
                                        ))}
                                    </select> :
                                    <input type="text"
                                        onChange={(e) => { setAddNew({ ...addNew, loadingListName: e.target.value }) }}
                                        required
                                    />
                            }



                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Total Sheets <span style={{ color: 'red' }}>*</span></label>
                            <input type="number"
                                onChange={(e) => { setAddNew({ ...addNew, totalRods: e.target.value }) }}
                                value={addNew.totalRods}
                                readOnly
                                required
                            />
                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">No of Items <span style={{ color: 'red' }}>*</span></label>
                            <input type="number"
                                onChange={(e) => { setAddNew({ ...addNew, totalBundles: e.target.value }) }}
                                value={addNew.totalBundles}
                                readOnly
                                required
                            />
                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Total Production Weight(T) <span style={{ color: 'red' }}>*</span></label>
                            <input type="number"
                                onChange={(e) => { setAddNew({ ...addNew, totalWeight: e.target.value }) }}
                                value={Math.floor(addNew.totalWeight * 1000) / 1000}
                                readOnly
                                required
                            />

                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Weighbridge Weight(T) <span style={{ color: 'red' }}>*</span></label>
                            <input
                                type="number"
                                value={addNew.weightBridgeWeight}
                                onChange={(e) => { decimalValidator(e) }}
                                step="0.01"
                                required
                            />
                        </div>

                        <div className="filter">
                            <label htmlFor="trailerNumber">Gate Pass print Date <span style={{ color: 'red' }}>*</span></label>
                            <input type="date"
                                value={addNew.gatePassPrintDate}
                                onChange={(e) => { setAddNew({ ...addNew, gatePassPrintDate: e.target.value }) }}
                                required
                            />
                        </div>

                        <div className="filter">
                            <label htmlFor="orderId">Vehicle Fitment <span style={{ color: 'red' }}>*</span></label>
                            <div style={{ alignContent: 'center', fontWeight: 'normal', marginTop: '4px' }}>

                                <input
                                    type="radio"
                                    name='vehicleFitment'
                                    value={1}
                                    id='vehFit'
                                    required
                                    checked={addNew.vehicleFitment === '1'}
                                    onChange={(e) => { setAddNew({ ...addNew, vehicleFitment: e.target.value }) }} />
                                <label htmlFor="vehFit">Fit</label>

                                <input
                                    type="radio"
                                    name='vehicleFitment'
                                    value={0}
                                    id='vehNFit'
                                    required
                                    checked={addNew.vehicleFitment === '0'}
                                    onChange={(e) => { setAddNew({ ...addNew, vehicleFitment: e.target.value }) }} />
                                <label htmlFor="vehNFit" >Not Fit</label>
                            </div>
                        </div>

                        <div className="filter">
                            <label htmlFor="orderId">Driver Fitment<span style={{ color: 'red' }}>*</span></label>
                            <div style={{ alignContent: 'center', fontWeight: 'normal', marginTop: '4px' }}>

                                <input
                                    type="radio"
                                    name='driverFitment'
                                    value={1}
                                    id='drFit'
                                    required
                                    checked={addNew.driverFitment === '1'}
                                    onChange={(e) => { setAddNew({ ...addNew, driverFitment: e.target.value }) }} />
                                <label htmlFor="drFit">Fit</label>

                                <input
                                    type="radio"
                                    name='driverFitment'
                                    value={0}
                                    id='drNFit'
                                    required
                                    checked={addNew.driverFitment === '0'}
                                    onChange={(e) => { setAddNew({ ...addNew, driverFitment: e.target.value }) }} />
                                <label htmlFor="drNFit" >Not Fit</label>
                            </div>
                        </div>

                        {/* <div className="filter">
                            <label htmlFor="deliveryDate">Delivery Date</label>
                            <input type="date" />
                        </div> */}

                        <div className="filter order-details" style={{ maxHeight: '120px', justifyContent: 'flex-start' }}>
                            <label htmlFor="trailerNumber">Vehicle fitment details
                                {/* <span style={{ color: 'red' }}>*</span> */}
                            </label>
                            <textarea
                                style={{ maxWidth: '200px', minHeight: '30px', minWidth: '100px', border: '2px solid #d9d9d9' }}
                                onChange={(e) => { setAddNew({ ...addNew, vehicleFitmentDetails: e.target.value }) }}

                            />
                        </div>

                        <div className="filter order-details" style={{ maxHeight: '120px', justifyContent: 'flex-start' }}>
                            <label htmlFor="trailerNumber">Driver fitment details
                                {/* <span style={{ color: 'red' }}>*</span> */}
                            </label>
                            <textarea
                                style={{ maxWidth: '200px', minHeight: '30px', minWidth: '100px', border: '2px solid #d9d9d9' }}
                                onChange={(e) => { setAddNew({ ...addNew, driverFitmentDetails: e.target.value }) }}

                            />
                        </div>
                    </div>

                </div>

                <div className="head">Production Parameter List

                    {/* <div className="bottomMultiDel" style={{ fontSize: '15px', color: 'black', alignContent: 'flex-end' }}>
                        <input type="checkbox" id="tagmultiSel"
                            checked={tagMultiSelect === true}
                            onClick={(e) => { handleTagMultiSelect(!tagMultiSelect) }} />
                        <label htmlFor="tagmultiSel">Multiple Select</label>
                    </div> */}
                </div>



                <AGTable data={data} columns={columns} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} rowSelection={'multiple'} />

                <div className="bottom-button-container" style={{ alignItems: 'center' }}>

                    <Button label="Seletect production parameters" className="blue" onClick={() => handleAddButtonClick()} disabled={selectedRowIndex} />
                    <Button label="Save" className="blue" type='submit' />
                    {/* <Button label="Print bbs tabular" className="blue" /> */}
                    <Button label="Back" className="blue" link='loading details' />

                </div>

                <div className="head" style={{ paddingTop: '1rem' }}>Selected Production Parameters
                    <div className="top-button-container">
                        <Button label="Remove selected bundles" className="blue" onClick={() => handleSelectedParamsDeletion()} disabled={selectedParamsRowIndex} />
                    </div>
                </div>

                <AGTable data={selectedParamsData} columns={columns} onSelectRow={setSelectedParamsRowIndex} selectedRow={selectedParamsRowIndex} rowSelection={'multiple'} />

            </form>
        </div>
    )
}

export default CreateSmartFabLL
