// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.displayMsg-card {
    background-color: #fff;
    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);
    border-radius: 8px; 
    width: 48%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 30px 0px;
    display: flex;
     margin-top: 5%;
    flex-direction: column;
   

  }

  .displayMsg-layout{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr ;
    gap: 5px; 
    
    
  }

  .displayMsg{
    width: 96px;
    color: #80818B;
    font-family: Myriad Pro;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    position: relative;
    left : 85px;
    top : 45px;
  }
`, "",{"version":3,"sources":["webpack://./src/assets/styles/RouteLoad.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yCAAyC;IACzC,kBAAkB;IAClB,UAAU;IACV,2DAA2D;IAC3D,iBAAiB;IACjB,aAAa;KACZ,cAAc;IACf,sBAAsB;;;EAGxB;;EAEA;IACE,aAAa;IACb,kCAAkC;IAClC,4BAA4B;IAC5B,QAAQ;;;EAGV;;EAEA;IACE,WAAW;IACX,cAAc;IACd,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,WAAW;IACX,UAAU;EACZ","sourcesContent":[".displayMsg-card {\r\n    background-color: #fff;\r\n    box-shadow: 0 3px 20px rgba(0, 0, 0, 0.1);\r\n    border-radius: 8px; \r\n    width: 48%;\r\n    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    padding: 30px 0px;\r\n    display: flex;\r\n     margin-top: 5%;\r\n    flex-direction: column;\r\n   \r\n\r\n  }\r\n\r\n  .displayMsg-layout{\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr 1fr;\r\n    grid-template-rows: 1fr 1fr ;\r\n    gap: 5px; \r\n    \r\n    \r\n  }\r\n\r\n  .displayMsg{\r\n    width: 96px;\r\n    color: #80818B;\r\n    font-family: Myriad Pro;\r\n    font-size: 15px;\r\n    font-style: normal;\r\n    font-weight: 400;\r\n    line-height: normal;\r\n    position: relative;\r\n    left : 85px;\r\n    top : 45px;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
