
import { createSlice } from '@reduxjs/toolkit';
import mockData from '../../data.json'
const initialState = {
 
  tpmRate: {
    columns: [],
    data: [],
  },
  diffFactShape: {
    columns: [],
    data: [],
  },
  diaWiseRoute:{
    columns: [],
    data: [],
  },
  diffFactFormulae: {
    columns: [],
    data: [],
  },
  orderDetails: {
    columns: [],
    data: [],
  },
  productionRecordedReport: {
    columns: [],
    data: [],
  },
  maxRebarPerCycle: {
    columns: [],
    data: [],
  },
  plantCapacity: {
    columns: [],
    data: [],
  },
  gradeDetails: {
    columns: [],
    data: [],
  },
  barMarkWiseQty: {
    columns: [],
    data: [],
  },
  CustomerDetails:{
    columns:[],
    data:[],
  },
  MachineDetails:{
    columns:[],
    data:[],
  },
  SapDocument:{
    columns:[],
    data:[],
  },
  OrderDateComparison:{
    columns:[],
    data:[],
  },
  OrderFeatures:{
    columns:[],
    data:[],
  },
  pricingMaster1:{
    columns:[],
    data:[],
  },
  pricingMaster2:{
    columns:[],
    data:[],
  },
  stockDetails:{
    columns:[],
    data:[],
  },
  stockSummary:{
    columns:[],
    data:[],
  },
  sdbr:{
    columns:[],
    data:[],
  },
  newPlan:{
    columns:[],
    data:[],
  },
  newPlanStock:{
    columns:[],
    data:[],
  },
  bbsDetailTable:{
    columns:[],
    data:[],
  },
  bbsShapeSetting:{
    columns:[],
    data:[],
  },
  orderSummary:{
    columns:[],
    data:[],
  },
  sapOrderSummary:{
    columns:[],
    data:[],
  },
  element:{
    columns:[],
    data:[],
  },
  tagDetails:{
    columns:[],
    data:[],
  },
  orderDetailsDiaWise:{
    columns:[],
    data:[],
  },
  OrderStatusHistory:{
    columns:[],
    data:[],
  },
  YieldMonitoring:{
    columns:[],
    data:[],
  },
  OrderProgress:{
    columns:[],
    data:[],
  },
  OrderWiseProgress:{
    columns:[],
    data:[],
  },
  LengthWiseProdQty:{
    columns:[],
    data:[],
  },
  DiaWiseBalanceOrder:{
    columns:[],
    data:[],
  },
  ProductionHistory:{
    columns:[],
    data:[],
  },
  Order_LL_Relation:{
    columns:[],
    data:[],
  },
  AutoDispatchOrder: {
    columns:[],
    data:[],
  },
  OpenPlan_PlanWiseOrder: {
    columns:[],
    data:[],
  },
  OpenPlan_OrderWisePlan: {
    columns:[],
    data:[],
  },
  ProductionUploadingOpt_RMUsed: {
    columns:[],
    data:[],
  },
  ProductionUploadingOpt_FG: {
    columns:[],
    data:[],
  },
  ProductionUploadingOpt_Remnant: {
    columns:[],
    data:[],
  },
  PlantLoad: {
    columns:[],
    data:[],
  },
  SummaryOffcutScrap: {
    columns:[],
    data:[],
  },
  SummaryFilter: {
    columns:[],
    data:[],
  },
  Summarystock: {
    columns:[],
    data:[],
  },
  OptimizationSummary: {
    columns:[],
    data:[],
  },
  productionRecordingPlanDetails: {
    columns:[],
    data:[],
  },
  productionRecordingOrderDetails: {
    columns:[],
    data:[],
  },
  ProductionRecNotUploaded: {
    columns:[],
    data:[],
  },
  ProductionRecNotUploaded2: {
    columns:[],
    data:[],
  },
  loadingDetails: {
    columns:[],
    data:[],
  },
};

const sdbrSlice = createSlice({
  name: 'sdbr',
  initialState,
  reducers: {
    setData: (state, action) => {
      state[action.payload.page].columns = action.payload.columns;
      state[action.payload.page].data = action.payload.data;
    },
  },
});

export const { setData } = sdbrSlice.actions;

export const fetchDataFromApi = (pageName) => async (dispatch) => {
  try {
    
    const { columns, data } = mockData[pageName];

    dispatch(setData({ page: pageName, columns: columns, data }));
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};
export default sdbrSlice.reducer;