import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/styles/Summary.css";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import * as api from "../../services/API";
import { AgGridReact } from "ag-grid-react";
import { fetchdailyproductionqty } from "../../redux-tookit/slices/dailyproductionquantitySlice";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import moment from "moment";

const ProductionHistory = () => {
  const [rowData, setRowData] = useState([]);
  const [tableData, setTableData] = useState([]);
  // const [columnDefs, setColumnDefs] = useState([]);
  const [region, setRegion] = useState("All");
  const dailyProductionGraph = useSelector(
    (state) => state.dailyproductionqty.labeledData
  );
  const [dailyWiseData, setDailyWiseData] = useState([]);
  const [serviceCenterListData, setServiceCenterListData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [xaxisproductiondate, setXaxisProductionDate] = useState([]);
  const [yaxisWeight, setyaxisWeight] = useState([]);
  const [tableDataForDailyProduction, setTableDataForDailyProduction] =
    useState([]);
  const [
    regionWiseSeviceCenterDataForDailyWise,
    setRegionWiseSeviceCenterDataForDailyWise,
  ] = useState([]);
  const dispatch = useDispatch();
  const [dailyWiseName, setDailyWiseName] = useState("All");
  const regions = ["North", "South", "East", "West"];
  const roleName = localStorage.getItem("roleName");
  const serviceCenterName = localStorage.getItem("serviceCenterName");
  const regionName = localStorage.getItem("region");
  const getServiceCenterList = async () => {
    await api
      .listServiceCenter()
      .then((result) => {
        setServiceCenterListData(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getServiceCenterList();
  }, []);
  useEffect(() => {
    getDailyWiseByRegions();
  }, [region]);
  useEffect(() => {
    getTableData();
    getDailyWiseQuantityByServiceId();
    // getDailyWiseByRegions();
  }, [region, dailyWiseName, dailyProductionGraph, startDate, endDate]);
  const getDailyWiseByRegions = async () => {
    let regionWise = "";
    if (roleName === "superadmin") {
      regionWise += `?region=${region}`;
    } else {
      regionWise += `?region=${regionName}`;
    }
    await api
      .listServiceCenterByRegion(regionWise)
      .then((result) => {
        setRegionWiseSeviceCenterDataForDailyWise(result?.data?.rows);
        setDailyWiseName("All");
        setStartDate("");
        setEndDate("");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(tableDataForDailyProduction, "tableDataForDailyProduction");
  useEffect(() => {
    const serviceData = regionWiseSeviceCenterDataForDailyWise.filter(
      (item) => item.name === dailyWiseName
    );
    const serviceDataForAllRegionsByName = serviceCenterListData.filter(
      (item) => item.name === dailyWiseName
    );

    if (serviceData.length > 0 || serviceDataForAllRegionsByName.length > 0) {
      getTableData(
        serviceData[0]?.serviceCenterId ||
          serviceDataForAllRegionsByName[0]?.serviceCenterId
      );
      getDailyWiseQuantityByServiceId(
        serviceData[0]?.serviceCenterId ||
          serviceDataForAllRegionsByName[0]?.serviceCenterId
      );
    }
    if (serviceDataForAllRegionsByName.length > 0) {
      getTableData(serviceDataForAllRegionsByName[0]?.serviceCenterId);
      getDailyWiseQuantityByServiceId(
        serviceDataForAllRegionsByName[0]?.serviceCenterId
      );
    }
  }, [dailyWiseName, region, startDate, endDate, serviceCenterListData]);
  console.log(dailyWiseName, "dailyWiseName");
  const getDailyWiseQuantityByServiceId = async (serviceCenterId) => {
    let queryString = "graph";
    if (region !== "All") {
      queryString += `?region=${region}&`;
    }
    if (serviceCenterId) {
      queryString += `${
        region === "All"
          ? `?serviceCenterId=${serviceCenterId}&`
          : `serviceCenterId=${serviceCenterId}&`
      }`;
    }

    if (startDate && roleName !== "admin" && roleName !== "regionalManager") {
      if (dailyWiseName === "All" && region === "All") {
        queryString += `?sDate=${startDate}&`;
      } else {
        queryString += `sDate=${startDate}&`;
      }
    }
    if (endDate && roleName !== "admin" && roleName !== "regionalManager") {
      queryString += `eDate=${endDate}`;
    }
    if (roleName === "admin" || roleName === "regionalManager") {
      if (dailyWiseName === "All") {
        queryString += `?sDate=${startDate}&`;
      } else {
        queryString += `sDate=${startDate}&`;
      }
    }
    if (roleName === "admin" || roleName === "regionalManager") {
      queryString += `eDate=${endDate}`;
    }

    await api
      .listDailyProductionByServiceIdAndRegion(`/${queryString}`)
      .then((result) => {
        if (result?.data?.rows.length > 0 || result?.data?.length > 0) {
          setDailyWiseData(result?.data?.rows || result?.data);
          console.log(result?.data?.rows, "result?.data?.rows");
        } else {
          setTableData([]);
          setDailyWiseData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const productionDates =
      region !== ""
        ? dailyWiseData.map((item) => item.productionDate)
        : dailyProductionGraph.map((item) => item.productionDate);

    const formattedDates = productionDates.map((date) =>
      moment(date).format("DD-MM-YYYY")
    );
    setXaxisProductionDate(formattedDates);
    const weight =
      region !== ""
        ? dailyWiseData.map((item) => item.weight)
        : dailyProductionGraph.map((item) => item.weight);
    setyaxisWeight(weight);
  }, [dailyProductionGraph, dailyWiseData, region]);
  console.log(xaxisproductiondate, "xaxisproductiondate");
  const chartData = {
    labels: xaxisproductiondate.sort((a, b) => a - b),
    datasets: [
      {
        label: "Weight",
        data: yaxisWeight,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        pointBorderColor: "red",
        borderWidth: 2,
        pointBackgroundColor: "red",
        barThickness: 20,
      },
    ],
  };

  const optionschartData = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.parsed.y;
            return ` ${label} T`;
          },
          afterLabel: function (context) {
            const dataIndex = context.dataIndex;

            const noOfPieces =
              region !== ""
                ? dailyWiseData[dataIndex]?.noOfPieces
                : dailyProductionGraph[dataIndex]?.noOfPieces;

            return ` No. of pieces: ${noOfPieces}`;
          },
        },
      },

      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 12,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "Production Date",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "Weight (T)",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
    },
  };
  useEffect(() => {
    dispatch(fetchdailyproductionqty());
  }, [dispatch]);

  const getTableData = async (serviceCenterId) => {
    let queryString = "";
    if (region !== "All") {
      queryString += `?region=${region}&`;
    }
    if (serviceCenterId) {
      queryString += `${
        region === "All"
          ? `?serviceCenterId=${serviceCenterId}&`
          : `serviceCenterId=${serviceCenterId}&`
      }`;
    }

    if (startDate && roleName !== "admin" && roleName !== "regionalManager") {
      if (dailyWiseName === "All" && region === "All") {
        queryString += `?sDate=${startDate}&`;
      } else {
        queryString += `sDate=${startDate}&`;
      }
    }
    if (endDate && roleName !== "admin" && roleName !== "regionalManager") {
      queryString += `eDate=${endDate}`;
    }
    if (roleName === "admin" || roleName === "regionalManager") {
      if (dailyWiseName === "All") {
        queryString += `?sDate=${startDate}&`;
      } else {
        queryString += `sDate=${startDate}&`;
      }
    }
    if (roleName === "admin" || roleName === "regionalManager") {
      queryString += `eDate=${endDate}`;
    }
    await api
      .listDailyProductionByServiceIdAndRegion(`/table${queryString}`)
      .then((result) => {
        const resp = result?.data?.rows || result?.data;
        if (result?.data?.rows.length > 0 || result?.data?.length > 0) {
          console.log("uuuuuuuuuuuuuuu");
          setTableData(resp);
        } else {
          console.log("xxxxxxxxxxxxxxxxxxx");
          setTableData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // useEffect(() => {
  //   getTableData();
  // }, [startDate, endDate]);

  const columnDefs = [
    {
      headerName: "Production Date",
      field: "productionDate",
      minWidth: 200,
      sortable: true,
      filter: true,
    },
    {
      headerName: "Total Weight (T)",
      field: "totalWeight",
      minWidth: 200,
      sortable: true,
      filter: true,
    },

    {
      headerName: "6 mm",
      field: "6",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "8 mm",
      field: "8",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "10 mm",
      field: "10",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "12 mm",
      field: "12",
      minWidth: 150,
      sortable: true,
      filter: true,
    },

    {
      headerName: "16 mm",
      field: "16",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "20 mm",
      field: "20",
      minWidth: 150,
      sortable: true,
      filter: true,
    },

    {
      headerName: "25 mm",
      field: "25",
      minWidth: 150,
      sortable: true,
      filter: true,
    },

    {
      headerName: "28 mm",
      field: "28",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "32 mm",
      field: "32",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "36 mm",
      field: "36",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "40 mm",
      field: "40",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
    {
      headerName: "45 mm",
      field: "45",
      minWidth: 150,
      sortable: true,
      filter: true,
    },
  ];
  // 6, 8, 10, 12, 16, 20, 25, 28, 32, 36, 40, 45
  const transformDataForAGGrid = (data) => {
    const aggregatedData = {};

    // Aggregate weights for the same production date
    data.forEach((item) => {
      const productionDate = moment(item.productionDate).format("DD-MM-YYYY");
      const diameter = item.diameter;
      const weight = item.weight;
      const totalWeight = item.totalWeight;

      if (!aggregatedData[productionDate]) {
        aggregatedData[productionDate] = {
          totalWeight: totalWeight,
          productionDate: productionDate,
          6: null,
          8: null,
          10: null,
          12: null,
          16: null,
          20: null,
          25: null,
          28: null,
          32: null,
          36: null,
          40: null,
          45: null,
        };
      }

      if (diameter === 6) {
        aggregatedData[productionDate]["6"] = weight;
      } else if (diameter === 8) {
        aggregatedData[productionDate]["8"] = weight;
      } else if (diameter === 10) {
        aggregatedData[productionDate]["10"] = weight;
      } else if (diameter === 12) {
        aggregatedData[productionDate]["12"] = weight;
      } else if (diameter === 16) {
        aggregatedData[productionDate]["16"] = weight;
      } else if (diameter === 20) {
        aggregatedData[productionDate]["20"] = weight;
      } else if (diameter === 25) {
        aggregatedData[productionDate]["25"] = weight;
      } else if (diameter === 28) {
        aggregatedData[productionDate]["28"] = weight;
      } else if (diameter === 32) {
        aggregatedData[productionDate]["32"] = weight;
      } else if (diameter === 36) {
        aggregatedData[productionDate]["36"] = weight;
      } else if (diameter === 40) {
        aggregatedData[productionDate]["40"] = weight;
      } else if (diameter === 45) {
        aggregatedData[productionDate]["45"] = weight;
      }
    });

    const transformedData = Object.values(aggregatedData);
    return transformedData;
  };

  useEffect(() => {
    const transformedData = transformDataForAGGrid(tableData);
    setRowData(transformedData);
  }, [tableData]);

  const gridOptions = {
    columnDefs: columnDefs,
    rowData: rowData,
    defaultColDef: {
      flex: 1,
      minWidth: 150,
      resizable: true,
    },
  };
  return (
    <div className="sdbr">
      <div className="head">Production History</div>
      <div className="head">Daily Production quantity</div>
      <div
        className="filter-card"
        style={{
          margin: "20px 10px",
          padding: "20px 20px",
          display: "flex",
          flexDirection: "row",
        }}
      >
        {roleName !== "salesManager" && (
          <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
            {roleName !== "admin" ? (
              <>
                {roleName === "superadmin" ? (
                  <>
                    {" "}
                    <label
                      htmlFor="dia-total-gradeFil"
                      style={{ color: "black" }}
                    >
                      Regions
                    </label>
                    <select
                      name="grade"
                      id="dia-total-gradeFil"
                      style={{ width: "fit-content" }}
                      onChange={(e) => setRegion(e.target.value)}
                    >
                      <option value="All">All</option>
                      {regions.map((region, index) => (
                        <option key={index} value={region}>
                          {region}
                        </option>
                      ))}
                    </select>
                  </>
                ) : (
                  <label
                    htmlFor="dia-total-gradeFil"
                    style={{ color: "black" }}
                  >
                    Region: <></>
                    {regionName.charAt(0).toUpperCase() + regionName.slice(1)}
                  </label>
                )}
              </>
            ) : (
              ""
            )}
          </div>
        )}

        {roleName !== "admin" && (
          <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
            <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
              Service Center Name
            </label>
            <select
              name="grade"
              id="dia-total-gradeFil"
              style={{ width: "fit-content" }}
              onChange={(e) => setDailyWiseName(e.target.value)}
            >
              <option value="All">All</option>
              {[
                ...new Set(
                  region === "All"
                    ? serviceCenterListData.map((item) => item.name)
                    : regionWiseSeviceCenterDataForDailyWise.map(
                        (item) => item.name
                      )
                ),
              ].map((data) => (
                <option value={data}>{data}</option>
              ))}
            </select>
          </div>
        )}

        <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
          {" "}
          <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
            Start Date:
          </label>
          <input
            name="grade"
            type="date"
            value={startDate}
            id="dia-total-gradeFil"
            style={{ width: "fit-content" }}
            onChange={(e) => setStartDate(e.target.value)}
            max={endDate}
          ></input>
        </div>
        <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
          {" "}
          <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
            End Date:
          </label>
          <input
            name="grade"
            type="date"
            value={endDate}
            id="dia-total-gradeFil"
            style={{ width: "fit-content" }}
            onChange={(e) => setEndDate(e.target.value)}
            min={startDate}
          ></input>
        </div>
      </div>
      <div className="chart-box">
        <Bar
          data={chartData}
          options={optionschartData}
          height={400}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        />
      </div>

      <div
        className="ag-theme-alpine"
        style={{ height: 490, width: "100%", marginTop: "50px" }}
      >
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          gridOptions={gridOptions}
        />
      </div>
    </div>
  );
};

export default ProductionHistory;
