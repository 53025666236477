import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { deleteEquipmentCategory, fetchEquipmentCategory } from '../../../redux-tookit/slices/equipmentCategorySlice';
import AGTable from '../../../components/AGTable1';
import AddEquipmentCategory from './AddEquipmentCategory';
import {  toast } from 'react-toastify';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
import { useLocation } from 'react-router-dom';
 
const EquipmentCategory = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const permission = useState(location.state?.permission);
    const columns = useSelector((state) => state.equipmentCategory.columns);
    const data = useSelector((state) => state.equipmentCategory.labeledData);
    const [editData,setEditData]=useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    console.log(columns);
 
    const handleRowSelect = (rowIndex) => {
      setSelectedRowIndex(rowIndex);
     
    };
 
 
    const handleAddButtonClick = () => {
      setIsAdding(true);
    };
    const handleEditButtonClick = () => {
      if (selectedRowIndex !== null&&selectedRowIndex!==undefined) {
        setIsEditing(true);
        setEditData(data[selectedRowIndex]);
        }else toast.error("No rows selected, Please select a row and try again");setSelectedRowIndex(null);
    };
    const handleDeleteButtonClick=async()=>{
      if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
        const id=data[selectedRowIndex].equipmentCategoryId;
        dispatch(deleteEquipmentCategory(id));
      }
      else toast.error("No rows selected, Please select a row and try again");
      setSelectedRowIndex(null);  
    };
 
    useEffect(() => {
              dispatch(fetchEquipmentCategory());
     
    }, [dispatch,isAdding,isEditing]);
 
 
    return (
      <div>
      <div className='sdbr'>
        <div className='head small'>Equipment Category
          <div className="top-button-container">
            {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
            <img src={edit} alt="edit" onClick={handleEditButtonClick}/>
            <img src={del} alt="delete" onClick={handleDeleteButtonClick}/> */}
            <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]}/>

          </div>
        </div>
  <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} width='60%'/>
  <AddEquipmentCategory isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} />
 
      </div>
      </div>
    );
  };
 
export default EquipmentCategory;