// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sub-data{
    margin: 1vw 0;
}
.sub-data select{
    margin-top: 4px;
    border: 2px solid #d9d9d9;
    border-radius: 4px;
    padding: 8px;
}

.sdbr .Buttons{
    display: flex;
    flex-direction: row;
}

.Plant-row-1{
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
}

.Plant-row-1 .filter:first-child{
    margin-right: 2vw;
}

.Plant-row-1 .filter{
    display: flex;
    align-items: center;
    gap: 1vh;
}



`, "",{"version":3,"sources":["webpack://./src/assets/styles/TpmRate.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;AACA;IACI,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,QAAQ;AACZ","sourcesContent":[".sub-data{\r\n    margin: 1vw 0;\r\n}\r\n.sub-data select{\r\n    margin-top: 4px;\r\n    border: 2px solid #d9d9d9;\r\n    border-radius: 4px;\r\n    padding: 8px;\r\n}\r\n\r\n.sdbr .Buttons{\r\n    display: flex;\r\n    flex-direction: row;\r\n}\r\n\r\n.Plant-row-1{\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 1.5vw;\r\n}\r\n\r\n.Plant-row-1 .filter:first-child{\r\n    margin-right: 2vw;\r\n}\r\n\r\n.Plant-row-1 .filter{\r\n    display: flex;\r\n    align-items: center;\r\n    gap: 1vh;\r\n}\r\n\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
