import React, { useEffect, useRef, useState, forceUpdate, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchShapes } from '../../redux-tookit/slices/shapeSlice';
import CanvasDraw from '../../pages/master/Shape/CanvasDraw';
import { postExcelBbs } from '../../redux-tookit/slices/partItemsSlice';
import Loader from '../Loader/Loader';
import { toast } from 'react-toastify';
import { fetchOrder } from '../../redux-tookit/slices/orderSlice';
import springShape from '../../assets/shapes/spring.jpg'

const ExcelConverter = (props) => {

    const { dataArray, orderId, combId, cleanTags} = props;
    const dispatch = useDispatch();
    // const data = useSelector(state => state.shape.shapes)
    // const [shapeData, setShapeData] = useState(data)
    // console.log(dataArray);

    const [tShapeProp, setTShapeProp] = useState([]);
    const [loading, setLoading] = useState(true)

    const [orginX, setOrginX] = useState(100);
    const [orginY, setOrginY] = useState(147);
    // const [mF, setMF] = useState(1)

    const [finalData, setFinalData] = useState([])



    const [partItemIndex, setPartItemIndex] = useState(0)

    const [bbsCanvasRef, setBbsCanvasRef] = useState()

    const [loaderValue, setLoaderValue] = useState(0);

    const handleLoaderValue = (value) => {
        setLoaderValue(() => (value))
        requestAnimationFrame(() => { });

        console.log(value);
    }

    // Function to update the progress
    // const updateProgress = (value) => {
    //     if (progressBarRef.current) {
    //         progressBarRef.current.style.width = `${value * 100}%`;
    //     }
    //     console.log(value);
    // };



    // console.log(finalData);
    ////////////////////////////////////////////////////

    const { onClose } = props;

    const bbs = '', saveInit = ''

    const [drawingElements, setDrawingElements] = useState()

    const canvasRef = useRef(null);
    const ctxRef = useRef(null);
    const fontSize = 20
    let count = 0;
    const [eff, setEff] = useState(0);

    let centerX = 0;
    let centerY = 0;

    let currentX = orginX;
    let currentY = orginY;

    let mF = 1

    let startAngle = 0, endAngle = 0, arcAngle = Math.PI;
    let direction = 0, pie = Math.PI;
    let endX = currentX, endY = currentY, x = 1, length = 100, textCount = 1, textNo;

    const [l1, setl1] = useState(100);
    const [l2, setl2] = useState(100);
    const [minX, setMinX] = useState(100);
    const [minY, setMinY] = useState(147);
    const [maxX, setMaxX] = useState(100);
    const [maxY, setMaxY] = useState(147);

    // const randomDraw = () => {
    //     draw(drawingElements);
    // }

    useEffect(() => {

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        ctxRef.current = ctx;
        ctx.lineWidth = 5;
        ctx.font = `bold ${fontSize}px Arial`;

        // randomDraw();

        setBbsCanvasRef(canvas)


    }, [eff]);

    const ogProperties = (id, shapeData) => {
        let tempParameter = []
        shapeData.map(data => {
            if (data.shapeId === id)
                tempParameter = JSON.parse(data.imageProperties)
        })
        return tempParameter
    }



    ////////////////////////////////////////////////////


    const parameterConverter = (id, parameter, shapeData) => {

        let trackIndex = 0
        let tempParameter = []
        shapeData.map(data => {
            if (data.shapeId === id)
                tempParameter = JSON.parse(data.imageProperties)
        })
        console.log(tempParameter);

        // console.log(shapeData);
        const convertedProperties = tempParameter.map(element => {
            let el = element;
            if (el.Geometry === 'Length') {

                el = { ...el, Length1: parameter[trackIndex] || 0 }
                trackIndex += 1
            } else if (el.Geometry === 'Angle') {

                el = { ...el, Angle: parameter[trackIndex] || 0 }
                trackIndex += 1
            }
            return el
        })

        // console.log(convertedProperties);
        return convertedProperties
    }



    const shapeMagnifier = (id, shapeParameter, shapeData) => {
        try {
            let imgProperties = [];
            let defaultCoords = ''
            let defaultMag = ''
            let newLength = 0;
            let oldLength = 0;
            let shapeFound = false;
            shapeParameter.map(l => {
                newLength += l
            })
            console.log(id * 1);

            shapeData.map(data => {
                if (data.shapeId == id) {
                    shapeFound = true;
                    imgProperties = data.imageProperties;
                    const [originX, originY, mag] = data.magnifications.split(',');
                    defaultMag = mag
                    defaultCoords = `${originX},${originY}`
                }

            })
            if (shapeFound === false) {
                return { ogMag: false }
            }

            JSON.parse(imgProperties).map(el => {
                if (el.Geometry === 'Length') {
                    oldLength += el.Length1;
                }
            })

            const mag = Math.floor((oldLength / newLength) * 1000) / 1000 * defaultMag

            return { ogMag: (defaultCoords + ',' + defaultMag), tempMag: (defaultCoords + ',' + mag) }
        } catch (error) {
            console.log("Error in shapeMagnifier", error);
        }

    }

    const dataConverter = async (shapeData) => {
        const elementNameArray = dataArray[2].slice(1);
        const elementSetArray = dataArray[3].slice(1);

        let tempFinalData = [];

        elementNameArray.forEach((elementName, index) => {
            tempFinalData.push({ orderId: orderId, noOfSets: elementSetArray[index], orderItemName: elementName, partItems: [] });
        });

        const varJson = dataArray[8];
        let convertedData = [];

        const chunkSize = 100; // Define your chunk size
        const startFrom = 5; // Start processing from index 5
        const totalChunks = Math.ceil((dataArray.length - startFrom) / chunkSize);

        const processChunk = async (start, end) => {
            const promises = [];

            dataArray.slice(start, end).map((data, rowIndex) => {
                if (data.length === 0) return;

                let rowJson = {};

                let tempParameter = ogProperties(data[11], shapeData)


                let shapeParameter = data.slice(12);
                const shapeProperties = parameterConverter(data[11], shapeParameter, shapeData);
                const mg = shapeMagnifier(data[11], shapeParameter, shapeData);
                const mag = mg.ogMag
                console.log(mag);
                if (mag === false) {
                    // toast.error()
                    return
                }
                console.log(tempParameter);

                const dataURL = draw(shapeProperties, mg.ogMag, tempParameter);
                console.log(dataURL);

                tempFinalData.forEach((OrItDet, finalDataIndex) => {
                    if (OrItDet.orderItemName === data[3]) {
                        tempFinalData[finalDataIndex].partItems.push({
                            shapeId: data[11],
                            diameter: data[4],
                            barMarkId: data[7],
                            gradeName: data[2],
                            image: dataURL,
                            noOfPieces: data[5],
                            imageProperties: JSON.stringify(shapeProperties),
                            ...ProdLengthCalculator(data[8], shapeProperties, data[9]),
                            magnifications: mag,
                            bundleQuantity: data[6] || '',
                            tagDescription: data[10]
                        });
                    }
                });

                data.forEach((columnData, index) => {
                    if (index < 12) {
                        rowJson[varJson[index]] = columnData;
                    }
                });

                convertedData.push({ ...rowJson });

            });

            return Promise.resolve();
        };

        const processChunks = async () => {
            for (let currentChunk = 0; currentChunk < totalChunks; currentChunk++) {
                const start = startFrom + currentChunk * chunkSize;
                const end = Math.min(start + chunkSize, dataArray.length);
                await processChunk(start, end);
                const progress = (currentChunk + 1) / totalChunks;
                handleLoaderValue(progress); // Update progress bar

                // updateProgress(progress);

            }
            return tempFinalData;
        };

        try {
            const finalData = await processChunks();
            console.log(finalData);

            if (finalData.length === 0) {
                toast.error('No data in excel');
                onClose();
            } else if (!dataArray[1][1]) {
                toast.error('Order Id not entered in excel');
                onClose();
            } else if (dataArray[1][1] !== combId) {
                console.log(dataArray[1][1], combId);
                toast.error('Order Id in excel does not match');
                onClose();
            } else {
                console.log('Done');
                console.log(finalData);
                const newRow = finalData;
                dispatch(postExcelBbs({newRow, cleanTags})).then(() => {
                    dispatch(fetchOrder())
                    onClose()

                }

                );
            }

            return finalData;
        } catch (error) {
            console.error('Error processing chunks:', error);
            // Handle error here
            return [];
        }
    };


    const ProdLengthCalculator = (d, shapeData, totalPieces) => {
        let cuLen = 0, direction = 0, invLen = 0, cu2 = 0;
        let D = d * 4
        // console.log(shapeData);

        shapeData.map((i, k) => {


            i.Geometry === 'Angle' ? (direction = direction - (i.Angle * Math.PI / 180)) :
                i.Geometry === 'Arc' ? (
                    i.Type === 'Rad+Sw_Angle' ? (direction = direction - (i.Angle * Math.PI / 180)) :
                        i.Type === 'Chord+Normal' ? (direction = direction - (2 * Math.PI)) :
                            (direction = direction - (i.Length2 / i.Length1 * Math.PI / 180))) :
                    i.Geometry === 'Length' ? (
                        i.Type === 'Offset + Normal' ? (direction = -(Math.atan(i.Length2 / i.Length1))) :
                            console.log('')
                    ) : console.log('')

                    if (i.Geometry === "Spring") {
                        if (i.Match1 === "A") {
                          cuLen = 1 / i.Length1;
                        } else if (i.Match1 === "B") {
                          cuLen = cuLen * i.Length1;
                        } else if (i.Match1 === "C") {
                          cuLen = cuLen * i.Length1 * Math.PI;
                        }
                      }

            i.Geometry === 'Length' ? (
                i.Type === 'Actual' ? (cuLen = cuLen + 1 * i.Length1) :
                    i.Type === 'Normal' ? (cuLen = cuLen + i.Length1 / Math.sin(-direction)) :
                        i.Type === 'Offset' ? (cuLen = cuLen + i.Length1 / Math.cos(-direction)) :
                            (cuLen = cuLen + Math.sqrt(i.Length1 * i.Length1 + i.Length2 * i.Length2))) :
                i.Geometry === 'Arc' ? (
                    i.Type === 'Rad+Sw_Angle' ? (cuLen = cuLen + i.Length1 * Math.abs(i.Angle) * Math.PI / 180) :
                        i.Type === 'Dia+Sw_Angle' ? (cuLen = cuLen + i.Length1 / 2 * Math.abs(i.Angle) * Math.PI / 180) :
                            i.Type === 'Chord+Normal' ? (cuLen = cuLen + (((i.Length1 * i.Length1) / 4) + i.Length2 * i.Length2) / (2 * i.Length2) * 2 * Math.PI) :
                                (cuLen = cuLen + i.Length2*1)) :
                    i.Geometry === 'Angle' && k !== 0 ? (
                        i.Angle <= 90 && i.Angle >= -90 ? (cuLen = cuLen - (D * 1 + 2 * d) / Math.tan((90 - Math.abs(i.Angle) / 2) * (Math.PI / 180)) + ((Math.PI * Math.abs(i.Angle)) / 360) * (D * 1 + d * 1)) :
                            (cuLen = cuLen + (Math.PI * Math.abs(i.Angle) / 360 * (D + d)) - (D * 1 + 2 * d))
                    ) : console.log('')

            if (i.Geometry !== 'Angle') {
                invLen = invLen + cuLen - cu2
            }
            cu2 = cuLen;

            // console.log(cuLen);  
        })

        const xon = totalPieces * Math.PI * d * d * 7850 / 4000000000;

        const invWeight = invLen * xon;
        const prodWeight = cuLen * xon;

        return { inventoryLength: Math.floor(invLen), productionLength: Math.floor(cuLen), productionWeight: prodWeight, inventoryWeight: invWeight }
    }

    useEffect(() => {

        console.log('start');
        let shapeIds = [];

        dataArray.slice(5, (dataArray.length)).map((data, rowIndex) => {
            if (data[11] !== '' && data[11]) {
                if (!shapeIds.includes(data[11]))
                    shapeIds.push(data[11])
            }
        })

        console.log(shapeIds.toString());

        dispatch(fetchShapes(`?shapeIds=${shapeIds.toString()}`)).then(res => {
            if (res.payload) {
                console.log(res.payload.shapes);
                let shapeFound = true, notFoundIds = []
                let gotIds = res.payload.shapes.map(item => item.shapeId)
                shapeIds.map(id => {
                    if (!gotIds.includes(id)) {
                        shapeFound = false
                        notFoundIds.push(id)
                    }
                })
                if (shapeFound === true) {
                    const bbsData = dataConverter(res.payload.shapes)
                } else {
                    toast.error(`${notFoundIds.toString()} - Shape Not foud`)
                    onClose()
                }

            }
        })
    }, [])

    const [preview, setPreview] = useState(true)


    ///////////////////////////////////////////////
    const drawLine = (constantLength, l, lineCase, CapLet, Capt3) => {

        // const [oX, oY, mF] = mag.split(',')

        let [CapUse, Letter] = CapLet.split('-');

        const ctx = ctxRef.current;
        let ogLength = l;
        l *= mF;


        if (lineCase === 0) {
            length = (constantLength * mF)
        } else
            if (lineCase === 1) {
                (length = l / Math.sin(-direction));
                length = length / l * constantLength * mF
                l = constantLength * mF
                dashDraw(ogLength, currentX, currentY, currentX, currentY + (l / Math.sin(-direction)));
            } else {
                (length = l / Math.cos(-direction));
                length = length / l * constantLength * mF
                l = constantLength * mF
                dashDraw(ogLength, currentX, currentY, currentX + (l / Math.cos(-direction)), currentY)
            }

        endX = currentX + length * Math.cos(direction);
        endY = currentY - length * Math.sin(direction);

        ctx.beginPath();
        ctx.moveTo(currentX, currentY);
        ctx.lineTo(endX, endY);
        ctx.stroke();
        direction % (2 * pie) < 0 && direction % (2 * pie) >= -pie ? x = -1 : x = 1;
        const midX = (currentX + endX) / 2;
        const midY = (currentY + endY) / 2;
        let textX = midX + 25 / (1 + (1 - x) / 20) * Math.cos(((1 - x) / 2 * pie + direction - pie / 2));
        let textY = midY - 25 / (1 + (1 - x) / 20) * Math.sin(((1 - x) / 2 * pie + direction - pie / 2));

        if (Capt3 !== 'No' && Capt3.includes(',')) {
            let [tx, ty] = Capt3.split(',');
            console.log(tx, ty);
            if (tx === '')
                tx = 0;
            if (ty === '')
                ty = 0;
            if (tx) {
                textX += tx * 1;
            }
            if (ty) {
                textY -= ty * 1
            }
            if (tx < 0 && (bbs || preview)) {
                textX -= `${ogLength}:${Letter}`.length * (fontSize / 5) * 2
                console.log(textNo);
            }
        }

        if (CapUse === 'Yes') {
            if (Letter === '') {
                textNo = String.fromCharCode(64 + textCount);

            } else if (preview === false) textNo = Letter;
            else if (saveInit === false || preview === true) textNo = ogLength + ":" + Letter;
            else textNo = ogLength;
            ctx.fillStyle = 'Black';
            ctx.fillText(textNo, textX, textY);
        }

        textCount++;


        currentX = endX;
        currentY = endY;


        // console.log({ endX, endY })
        count++;

    };

    const drawCoupler = (sS, n) => {
        const ctx = ctxRef.current;
        for (let i = 0; i < n; i++) {
            let x1 = currentX + 10 * Math.cos(direction - pie / 2);
            let y1 = currentY - 10 * Math.sin(direction - pie / 2);
            let x2 = currentX - 10 * Math.cos(direction - pie / 2);
            let y2 = currentY + 10 * Math.sin(direction - pie / 2);
            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.stroke();

            if (i === n - 1) {
                break;
            }

            endX = currentX + sS * Math.cos(direction);
            endY = currentY - sS * Math.sin(direction);

            ctx.moveTo(currentX, currentY);
            ctx.lineTo(endX, endY);
            ctx.stroke();
            currentX = endX;
            currentY = endY;

        }


        count++;
    };

    const drawCurve = (r, ang, CapLet, data, arcNo, Capt3) => {
        let [CapUse, Letter, Geom] = CapLet.split('-');
        const ctx = ctxRef.current;
        let radius = r * mF, i = 0;
        ang < 0 ? x = -1 : x = 1;
        radius *= x;
        arcAngle = ang;
        centerX = currentX + radius * Math.cos(direction - pie / 2);
        centerY = currentY - radius * Math.sin(direction - pie / 2);
        startAngle = -x * (x * direction + pie / 2);
        endAngle = startAngle + arcAngle;
        radius *= x;

        const endX = centerX + radius * Math.cos(endAngle);
        const endY = centerY + radius * Math.sin(endAngle);

        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, startAngle, endAngle, x === -1);
        ctx.stroke();
        ctx.fillStyle = 'blue';

        if (CapUse === 'Yes') {
            if (Letter === '' && Letter === undefined) { textNo = String.fromCharCode(64 + textCount); }
            else textNo = Letter
        }
        if (Geom === 'Arc') { ctx.fillStyle = 'blue'; }
        else { ctx.fillStyle = 'red'; }

        let textX = 0, textY = 0;

        if (Capt3 !== 'No' && Capt3.includes(',')) {

            let [tx, ty] = Capt3.split(',');
            console.log(tx, ty);
            if (tx === '')
                tx = 0;
            if (ty === '')
                ty = 0;
            if (tx) {
                textX += tx * 1;
            }
            if (ty) {
                textY -= ty * 1
            }
            if (tx < 0 && (bbs || preview)) {
                if (Geom === 'Arc') {
                    textX -= `${r}:${textNo}`.length * (fontSize / 5) * 2
                } else {
                    textX -= `${data}:${textNo}`.length * (fontSize / 5) * 2

                }
            }
        }

        // CapUse === 'Yes' ? ctx.fillText(textNo, centerX, centerY) : i++;

        if (CapUse === 'Yes' && Geom === 'Arc') {
            ctx.fillText(data, 210, arcNo * fontSize)
            ctx.fillText(preview ? r + ":" + textNo : textNo, centerX + textX, centerY + textY)
        } else if (CapUse === 'Yes' && textCount !== 1) {
            ctx.fillText(preview ? data + ":" + textNo : textNo, centerX + textX, centerY + textY)
        }

        currentX = endX;
        currentY = endY;
        direction -= arcAngle;
        textCount++;

        if (x === 1) { startAngle += 2 * pie; endAngle += 2 * pie; }

        if (startAngle <= 0 && 0 <= endAngle) { if (maxY < centerY + radius) setMaxX(centerX + radius); }
        if (startAngle <= pie / 2 && pie / 2 <= endAngle) { if (maxX < centerX + radius) { setMaxX(centerX + radius); } }
        if (startAngle <= pie && pie <= endAngle) { if (minX > centerX - radius) setMinX(centerX - radius); }
        if (startAngle <= (3 * pie / 2) && (3 * pie / 2) <= endAngle) { if (minY > centerY - radius) setMinY(centerY - radius); }



        count++;
    }

    const clearCanvas = (mag) => {
        const [oX, oY, zoom] = mag.split(',')

        const ctx = ctxRef.current;
        ctx.clearRect(0, 0, 462, 294);

        currentX = oX * 1;
        currentY = oY * 1;
        direction = 0; textCount = 1;
        length = 100;

        count++;
    };

    const draw = (drawingElements, mag, constantLengthValue) => {
        const [oX, oY, zoom] = mag.split(',')

        clearCanvas(mag);

        console.log(mag);

        currentX = oX * 1;
        currentY = oY * 1;
        mF = zoom * 1;

        let arcNo = 0

        if (drawingElements[0]?.Geometry === "Spring") {
            drawSpring(drawingElements)
        } else

            drawingElements.map((i, k) => {

                let j = constantLengthValue[k]

                i.Geometry === "Length" ?
                    i.Type === 'Actual' ? drawLine(j.Length1, i.Length1, 0, `${i.Capt1}-${i.Match1}`, i.Capt3) :
                        i.Type === 'Normal' ? drawLine(j.Length1, i.Length1, 1, `${i.Capt1}-${i.Match1}`, i.Capt3) :
                            i.Type === 'Offset' ? drawLine(j.Length1, i.Length1, 2, `${i.Capt1}-${i.Match1}`, i.Capt3) :
                                drawOfNor(i.Length1, i.Length2, `${i.Capt1}-${i.Match1}`, i.Capt3)
                    : i.Geometry === "Arc" ? (
                        i.Type === 'Rad+Sw_Angle' ? drawCurve(i.Length1, i.Angle * Math.PI / 180, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Rad:${i.Length1},Sw_Angle:${i.Angle}`, ++arcNo, i.Capt3) :
                            i.Type === 'Rad+ArcLength' ? drawCurve(i.Length1, i.Length2 / i.Length1, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Rad:${i.Length1},ArcLength:${i.Length2}`, ++arcNo, i.Capt3) :
                                i.Type === 'Dia+ArcLength' ? drawCurve(i.Length1 / 2, i.Length2 / i.Length1, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Dia:${i.Length1},ArcLength:${i.Length2}`, ++arcNo, i.Capt3) :
                                    i.Type === 'Dia+Sw_Angle' ? drawCurve(i.Length1 / 2, i.Angle * Math.PI / 180, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Dia:${i.Length1},Sw_Angle:${i.Angle}`, ++arcNo, i.Capt3) :
                                        drawCurve((((i.Length1 * i.Length1) / 4) + i.Length2 * i.Length2) / (2 * i.Length2), 2 * Math.PI, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Chord:${i.Length1},Normal:${i.Length2}`, ++arcNo, i.Capt3)
                    ) : i.Geometry === "Angle" ? (k === 0 ? (i.Type === 'Clockwise' ? drawCurve(0, i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match2}-${i.Geometry}`, '', 0, i.Capt3) : drawCurve(0, -i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match2}-${i.Geometry}`, '', 0, i.Capt3)) :
                        (i.Type === 'Clockwise' ? drawCurve(20, i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match1}-${i.Geometry}`, i.Angle, 0, i.Capt3) : drawCurve(20, -i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match1}-${i.Geometry}`, i.Angle, 0, i.Capt3))
                    ) :
                        // i.Geometry === "Coupler" ? (
                        i.Type === 'Half Thread' ?
                            drawCoupler(15, 3) :
                            i.Type === 'Full Thread' ?
                                drawCoupler(15, 5) :
                                drawCoupler(2.5, 10)

                return 0;

            })

        const canvas = canvasRef.current;

        const dataURL = canvas.toDataURL('image/png');

        return dataURL
    }


    const drawSpring = (array) => {
        const ctx = ctxRef.current;

        const img = new Image();
        img.src = springShape;
        img.onload = function () {

            //350 x 520 -> 2/5 -> 140 x 208

            ctx.clearRect(0, 0, 400, 300);
            ctx.drawImage(img, orginX, orginY, mF * 350, mF * 520);
            ctx.lineWidth = 5;
            ctx.font = `bold ${fontSize}px Arial`;
            ctx.fillStyle = 'Blue';
            ctx.fillText(preview ? array[0].Length1 : 'A', orginX - 20, orginY + 520 * mF / 4 + (fontSize / 5 * 4));
            ctx.fillText(preview ? array[1].Length1 : 'B', orginX + 350 * mF / 2, orginY + 520 * mF + (fontSize / 5 * 4));
            ctx.fillText(preview ? array[2].Length1 : 'C', orginX + 350 * mF, orginY + 520 * mF / 4 + (fontSize / 5 * 4));

            console.log("876");
        };

        img.onerror = function () {
            console.error('Error loading image:', img.src);
            // Provide fallback behavior or display an error message
        };
    }


    const drawOfNor = (ll1, ll2, CapLet, mag) => {
        // const [oX, oY, mF] = mag.split(',')

        let [CapUse, Letter] = CapLet.split('-');
        setl1(ll1 * mF);
        setl2(ll2 * mF);


        const ctx = ctxRef.current;

        direction = -(Math.atan(l2 / l1));

        dashDraw(ll1, currentX, currentY, currentX + l1 / l1 * l1, currentY);

        dashDraw(ll2, currentX + l1 / l1 * l1, currentY, currentX + l1 / l1 * l1, currentY + l2 / l2 * l2);

        ctx.beginPath();
        ctx.moveTo(currentX, currentY);
        ctx.lineTo(currentX + l1 / l1 * l1, currentY + l2 / l2 * l2);
        ctx.stroke();

        if (CapUse === 'Yes') {
            if (Letter === '') { textNo = String.fromCharCode(64 + textCount); }
            else textNo = Letter
        }
        let midX = currentX + l1 / 2;
        let midY = currentY + l2 / 2;
        x = 1;
        let textX = midX + 30 * Math.cos((x * direction - pie / 2));
        let textY = midY - 30 * Math.sin(((1 - x) / 2 * pie + direction - pie / 2));
        ctx.fillStyle = 'Black';
        ctx.fillText(textNo, textX, textY);

        currentX += l1; endX = currentX;
        currentY += l2; endY = currentY;
        if (endX < minX) { setMinX(endX); }
        if (endY < minY) { setMinY(endY); }
        if (endX > maxX) { setMaxX(endX); }
        if (endY > maxY) { setMaxY(endY); }

    }

    const dashDraw = (dLength, x1, y1, x2, y2) => {
        const ctx = ctxRef.current;

        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(x1 - 1 + 1, y1 - 1);
        ctx.lineTo(x2 - 1 + 1, y2 - 1 + 1);
        ctx.stroke();
        ctx.setLineDash([]);
        let midX = (x1 + x2) / 2, midY = (y1 + y2) / 2;
        if (y1 === y2) {
            midY -= 10;
        } else {
            midX += 10;
        }
        ctx.fillStyle = 'Black';
        ctx.fillText(dLength, midX, midY);
    }

    return (
        <>
            {/* {loading === true ?  */}

            <Loader />
            {/* :""} */}
            <div style={{ display: 'none' }}>

                <canvas ref={canvasRef} width="400" height="295" style={{ width: (bbs === true ? '250px' : '') }}></canvas><br />

            </div>
            {/* <LoaderBar loaderValue={loaderValue}/> */}

            {/* <div style={{
                width: '100vw',
                backgroundColor: '#fff',
                height: '5px',
                display: 'block',
                position: 'absolute',
                top: '0',
                left: '0',
                zIndex: '1000',
                padding: '0',
            }}>
                <div
                    className="progress-bar"
                    style={{
                        width: `${(loaderValue) * 100}%`,
                        backgroundColor: '#138EE0',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        margin: '0',
                        // transform: `scaleX(${loaderValue})`,
                        // transformOrigin: 'left'
                    }}>


                </div>

            </div> */}
            {/* {loaderValue} */}
        </>
    )
}

export default ExcelConverter
