import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API'; // Import your Axios API functions
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
  columns: {},
  labeledData: [], // Initialize with an empty array
  loading: 'idle',
  error: null,
  resp: {}
};

export const fetchSegment = createAsyncThunk('data/fetchSegment', async () => {
	try {
		const response = await api.listSegment();
		const json = response.data;

		const columnLabels = {
			customerSegmentId: { label: 'Customer Segment Id', type: 'number' },
			customerSegmentName: { label: 'Customer Segment Name', type: 'text' },
			
            // "customerSegmentId": 9,
            // "customerSegmentName": "Housing & Commercial - MES",
		};
		
		const selectedColumns = ["customerSegmentId","customerSegmentName"];
		const columns = {};
		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});
		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});
		return { columns, labeledData };
	} catch (error) {
		throw error;
	}
});



const customerSegmentSlice = createSlice({
  name: 'customerSegment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSegment.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchSegment.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchSegment.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      
  },
});

export default customerSegmentSlice.reducer;
//End of File