import React from 'react'
import { Font, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import BbsTabularPdf from './BbsTabularPdf';
import font from "../../../../assets/fonts/OpenSans-Variable.ttf"
// import font2 from "../../../../assets/fonts/OpenSans-Bold.ttf"
// import font3 from "../../../../assets/fonts/OpenSans-MediumItalic.ttf"
import { dateChanger } from '../../../../components/reusableFunctions';
import font2 from "../../../../assets/fonts/NotoSansSymbols-Regular.ttf"
import font3 from "../../../../assets/fonts/NotoSansSymbols2-Regular.ttf"
import font4 from "../../../../assets/fonts/Quivira-A8VL.ttf"
import font5 from "../../../../assets/fonts/Roboto-Bold.ttf"
import HeaderImg from "../../../../assets/images/paramsTagPrint/HeaderImg.png"
import ShapeImg from "../../../../assets/images/paramsTagPrint/ShapeImg.png"

const d = new Date();
const todayDate = (`${d.getDate()}-${(d.getMonth() + 1) > 9 ? '' : '0'}${(d.getMonth() + 1)}-${d.getFullYear()}`);



const serviceCenterName = sessionStorage.getItem('serviceCenterName');

const styles = StyleSheet.create({
    page: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        // padding: '1%'
    },
    page2: {
        flexDirection: 'column',
        backgroundColor: 'white',
        // alignItems: "center",
        // flexWrap: 'wrap',
        border: '1px solid black',
        width: '100%',
        height: '100%',
        padding: '2%',
    },
    tag: {
        height: '20%',
        width: '50%',
        padding: '0.5%',
        margin: '0.5%',
        boxSizing: 'border-box',
        border: '2px solid black',
        borderRadius: '7%'
    },
    content: {
        fontSize: '8'
    },

});

const ParamsPdfChooser = (props) => {
    const { parameters, type, orderInfo, serviceCenterLogo, parties } = props;

    Font.register({
        family: "NotoSansSymbols-Regular", format: "truetype", src: font2
    });

    Font.register({
        family: "NotoSansSymbols2-Regular", format: "truetype", src: font3
    });

    Font.register({
        family: "OpenSans-Bold", format: "truetype", src: font5
    });

    const charList = [
        '2690',
        '2742',
        '275B',
        '25A0', '25A1', '25B2', '25B3', '25CF', '25CB', '2680',
        '2687', '2689', '268A', '268B', '268C', '268D', '268E', '268F', '26D2', '2700',
        '2710', '2751', '275D', '2794', '29BF', '2B12', '2B19', '2B1F', '2B20', '2B22',
        '2B85', '2B89', '2B8C', '2B90', '2B94', '2B98', '2B9C', '2BB0', '2BD2', '2BD3',
        '2BD4', '2BD5', '2BD6', '2BD7', '2BD8', '2BD9', '2BDA', '2BDB', '2BDC', '2BDD',
        '2BDE', '2BDF', '2BE0', '2BE1', '2BE3', '2BE4', '2BE5', '2BE6', '2BE7', '2BEC',
        '2BF4',
    ]

    console.log(type);

    return (
        <>
            {type === 'tag' ?
                <Page size="A4" style={styles.page}>

                    {parameters.map((rowData, index) => (
                        <Select el={rowData} index={index} oI={orderInfo}
                            serviceCenterLogo={serviceCenterLogo} key={index}
                        />
                    ))}
                </Page> :
                type === 'bbs tabular' ?
                    <Page size="A4" style={styles.page2}>
                        <BbsTabularPdf parameters={parameters} orderInfo={orderInfo} serviceCenterLogo={serviceCenterLogo} parties={parties} />
                    </Page>
                    : ""}
        </>
    )

}

export default ParamsPdfChooser

const Select = (props) => {
    const { el, serviceCenterLogo, oI, index } = props;


    Font.register({
        family: "OpenSans-Bold", format: "truetype", src: font5
    });

    Font.register({
        family: "NotoSansSymbols-Regular", format: "truetype", src: font2
    });

    Font.register({
        family: "NotoSansSymbols2-Regular", format: "truetype", src: font3
    });

    Font.register({
        family: "Quivira-A8VL", format: "truetype", src: font4
    });


    return (
        <View wrap={false} style={{
            height: '49%',
            width: '49%',
            padding: '0.5%',
            margin: '0.5%',
            border: '1px solid #62A6D8',
            position: 'relative'
        }}>

            <View style={{ width: '100%', display: 'flex', alignItems: 'center', paddingTop: '10' }} >
                <Image style={{ width: '160' }} src={HeaderImg} alt='headerImg' />
            </View>

            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingVertical: '6' }} >
                <View style={{ width: '50%', display: 'flex', flexDirection: 'column', paddingHorizontal: '5', gap: '3' }}>
                    <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '10', color: '#0069BD' }}>Order Id: {oI.combId}</Text>
                    <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '10', color: '#0069BD' }}>{el.mwDia + '/' + el.cwDia + '/' + el.mwSpacing + '/' + el.cwSpacing}</Text>
                </View>
                <View style={{ width: '25%', display: 'flex', flexDirection: 'column', alignItems: 'flex-end', gap: '3' }}>
                    <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '10', color: '#0069BD' }}>Date:</Text>
                    <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '10', color: '#0069BD' }}>Sheets/Rolls:</Text>
                </View>
                <View style={{ width: '20%', display: 'flex', flexDirection: 'column', paddingHorizontal: '5', gap: '3' }}>
                    <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '10', color: '#0069BD' }}>{todayDate}</Text>
                    <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '10', color: '#0069BD' }}>{el.totalSheets}</Text>
                </View>
            </View>

            <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>

                <View style={{ width: '100%', borderTop: '1px solid #62A6D8', margin: '2' }}>
                    <ParamsTable label='Dm/Dc' value={el.mwDia + '/' + el.cwDia} />
                    <ParamsTable label='Dimensions' value={(el.length / 1000) + ' MTR x ' + (el.width / 1000) + ' MTR'} />
                    <ParamsTable label='Sm/Sc' value={el.mwSpacing + '/' + el.cwSpacing} />
                    <ParamsTable label='MO1/MO2' value={el.mwOH1 + '/' + el.mwOH2} />
                    <ParamsTable label='CO1/CO2' value={el.cwOH1 + '/' + el.cwOH2} />
                </View>

                <View style={{ width: '100%', padding: '5' }}>
                    <Image style={{}} src={ShapeImg} alt='shapeImg' />
                </View>

            </View>

            <View style={{ width: '100%', display: 'flex', flexDirection: 'row', paddingVertical: '6' }} >
                <View style={{ width: '50%', display: 'flex', flexDirection: 'column', paddingHorizontal: '10', gap: '3' }}>
                    <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '10', color: '#0069BD' }}>Customer: {oI.soldToParty}</Text>
                    <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '10', color: '#0069BD' }}>Project: </Text>
                </View>

            </View>
            <View style={{ position: 'absolute', bottom: '0', right: '0', padding: '5' }}>
                <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '7', color: '#0069BD' }}>readybuild.tatasteel.com</Text>
            </View>
        </View>
    )
}

const ParamsTable = ({ label, value }) => {
    return (
        <View style={{ display: 'flex', flexDirection: 'row', width: '100%', height: '30', borderBottom: '1px solid #62A6D8', borderRight: '1px solid #62A6D8' }}>
            <View style={{ display: 'flex', width: '40%', height: '30', borderLeft: '1px solid #62A6D8', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: '5' }}>
                <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '8', color: '#0069BD' }}>{label}</Text>
            </View>
            <View style={{ display: 'flex', width: '60%', height: '30', borderLeft: '1px solid #62A6D8', justifyContent: 'center', alignItems: 'flex-start', paddingHorizontal: '5' }}>
                <Text style={{ fontFamily: "OpenSans-Bold", fontSize: '8', color: '#0069BD' }}>{value}</Text>

            </View>
        </View>
    )
}


