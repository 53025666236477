import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../utils/Button';
import { postProducts, updateProduct } from '../../../redux-tookit/slices/productMasterSlice';
 
const AddProduct = (props) => {
    const dispatch=useDispatch();
    const {isAdding, setIsAdding,isEditing,setIsEditing,editData,setEditData} = props
    const [newRow, setNewRow] = useState({});
    const columns = useSelector((state) => state.productMaster.columns);
    const handleInputChange=(header,e)=>{
        const updatedData=JSON.parse(JSON.stringify(editData));
        updatedData[header]=e.target.value;
        setEditData(updatedData);
      }
 
      const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        dispatch(postProducts({ ...newRow }));
        
        console.log(newRow);
        setIsAdding(false);
        setNewRow({});
      };
      const handleUpdate=async(e)=>{
     
        e.preventDefault();
       
        const id=editData.productMasterId;
        const type={'name':editData.name, 'barType': editData.barType};
        console.log(id);
        console.log(type);
        dispatch(updateProduct({id,type}))
        setIsEditing(false);
      }
    return (
    <div>
        <form onSubmit={handleSaveButtonClick}>{isAdding &&
          <div>
            <div className="AddContainer" >
              <div className="form-container"><div className="head">Add Product</div>
                <div className="table-container">
                        {Object.keys(columns).map((column) => (
                          column !== 'productMasterId' ?
                            (<div className='filter'style={{ display: 'flex', flexDirection: 'column',gap: '4px', width: '10vw' }}>
                                <label>{columns[column].label}<span style={{ color: 'red' }}>*</span></label>
                              <input
                                key={column}
                                type={columns[column].type}
                                className='table-input'
                                required
                                value={newRow[column] || ''}
                                onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                              />
                            </div>) :
                            ""
                        ))}
                </div>
                <div className="bottom-button-container">
                  <Button type='submit' label='Save' className='blue' />
                  <Button onClick={() => { setIsAdding(false); setNewRow({}) }} label='Cancel' className='blue' />
                </div>
              </div>
            </div>
            <div className="bg-dark"></div>
          </div>
        }
        </form>
        <form onSubmit={handleUpdate}>{isEditing &&
          <div>
            <div className="AddContainer" >
              <div className="form-container"><div className="head">Edit Product</div>
                <div className="table-container">
                        {Object.keys(columns).map((key) => (
                          columns[key].editable===true?
                            (
                            <div >
                            <div className='filter' style={{ display: 'flex', flexDirection: 'column',gap: '4px', width: '10vw' }}>
                                <label>{columns[key].label}<span style={{ color: 'red' }}>*</span></label>
                              <input
                                type={columns[key].type}
                                className='table-input'
                                value={editData[key]}
                                onChange={(e) => handleInputChange(key,e)}
                              /></div>
                            </div>) :
                            ""
                        ))}
                </div>
                <div className="bottom-button-container">
                  <Button type='submit' label='Save' className='blue' />
                  <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
                </div>
              </div>
            </div>
            <div className="bg-dark"></div>
          </div>
        }
        </form>
    </div>
  )
}
 
export default AddProduct