// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cus-Detail1,
.Cus-Detail2 {
    display: flex;

}

.Cus-Detail1 {
    display: flex;
    flex-direction: row;
    gap: 1.5vw;
}

.Cus-Detail2 {
    justify-content: space-between;
    align-items: flex-end;
}

.Cus-Detail1 .filter,
.Cus-Detail2 .filter {
    display: flex;
    flex-direction: column;
}

.Cus-Detail2 select {
    width: 20vw;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/CustomerDetails.css"],"names":[],"mappings":"AAAA;;IAEI,aAAa;;AAEjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,UAAU;AACd;;AAEA;IACI,8BAA8B;IAC9B,qBAAqB;AACzB;;AAEA;;IAEI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,WAAW;AACf","sourcesContent":[".Cus-Detail1,\r\n.Cus-Detail2 {\r\n    display: flex;\r\n\r\n}\r\n\r\n.Cus-Detail1 {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 1.5vw;\r\n}\r\n\r\n.Cus-Detail2 {\r\n    justify-content: space-between;\r\n    align-items: flex-end;\r\n}\r\n\r\n.Cus-Detail1 .filter,\r\n.Cus-Detail2 .filter {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.Cus-Detail2 select {\r\n    width: 20vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
