import React from 'react';
 
const NotFound = () => {
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '80vh',
    background: '#f8f9fa', // Use your preferred background color
  };
 
  const contentStyle = {
    textAlign: 'center',
  };
 
  const headingStyle = {
    fontSize: '2rem',
    fontWeight: 'bold',
    color: '#007bff', // Use your preferred text color
    marginBottom: '1rem',
  };
 
  const emojiStyle = {
    fontSize: '4rem',
    marginBottom: '1rem',
    color: '#6c757d', // Use your preferred text color
  };
 
  const leadTextStyle = {
    fontSize: '1.25rem',
    color: '#343a40', // Use your preferred text color
    // marginBottom: '2rem',
  };

  const leadTextStyle2 = {
    fontSize: '1.25rem',
    color: '#343a40', // Use your preferred text color
    marginBottom: '2rem',
  }
 
  const buttonStyle = {
    fontSize: '1.5rem',
    padding: '1rem 2.5rem',
    borderRadius: '30px',
    background: '#007bff', // Use your preferred button color
    color: '#fff', // Use your preferred button text color
    fontWeight: 'bold',
    textDecoration: 'none',
  };
 
  return (
    <div style={containerStyle}>
      <div style={contentStyle}>
        <h1 style={headingStyle}>Uh-oh! Are you lost?</h1>
        <div style={emojiStyle}>
          <span role="img" aria-label="Confused face">
            😵
          </span>
        </div>
        <p style={leadTextStyle}>It seems like you've entered an invalid URL.</p>
        <p style={leadTextStyle2}>Let's get you back on track!</p>
        <a href="/order details" style={buttonStyle}>
          Go back home
        </a>
      </div>
    </div>
  );
};
 
export default NotFound;