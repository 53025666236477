// loginSlice.js
import { createSlice, createAsyncThunk, createAction } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import * as api from "../../services/API";

const isValidToken = (token) => {
  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp * 1000 > Date.now();
  } catch (error) {
    sessionStorage.removeItem("token");
    return false;
  }
};
const storedToken = sessionStorage.getItem("token");

const initialState = {
  email: null,
  userName: null,
  role: null,
  scName: null,
  image: null,
  currentUser: null,
  error: null,
  token: isValidToken(storedToken) ? storedToken : null,
  loading: "idle",
  region: null,
};

export const login = createAsyncThunk("data/login", async (newRow) => {
  try {
    const response = await api.login(newRow);
    const data = await response.data;
    console.log("Success");
    return data;
  } catch (error) {
    throw error;
  }
});

export const fetchToken = createAsyncThunk("data/fetchToken", async () => {
  try {
    const token = sessionStorage.getItem("token");
    return token;
  } catch (error) {
    throw error;
  }
});

export const resetState = createAction("login/reset");

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    logout: (state) => {
      state.currentUser = null;
      state.error = null;
      state.token = null;
      state.image = null;
      state.region = null;
      sessionStorage.removeItem("email");
      sessionStorage.removeItem("userName");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("image");

      localStorage.removeItem("region");
      localStorage.removeItem("serviceCenterId");
      localStorage.removeItem("roleName");
      localStorage.removeItem("serviceCenterName");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.currentUser = null;
        state.token = null;
        state.loading = "pending";
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("token");
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        sessionStorage.setItem("navSelectedOption", "Order Details");
        sessionStorage.setItem("navExpandedOption", "Transaction");
        const token1 = action.payload.data.accessToken;
        state.currentUser = jwtDecode(token1);
        state.email = action.payload.data.userEmail;
        state.userName = action.payload.data.userName;
        state.image = action.payload.data.imageUrl;
        state.region = action.payload.data.region; // Store region in state
        console.log(action.payload.data.imageUrl);
        // console.log(String.fromCharCode.apply(null, new Uint8Array(action.payload.data.imageUrl.data)));
        state.error = null;
        state.token = token1;
        sessionStorage.setItem("email", action.payload.data.userEmail);
        sessionStorage.setItem("userName", action.payload.data.userName);
        sessionStorage.setItem("roleName", action.payload.data.roleName);
        sessionStorage.setItem(
          "serviceCenterName",
          action.payload.data.serviceCenterName || ""
        );
        sessionStorage.setItem(
          "passwordStatus",
          action.payload.data.passwordStatus || ""
        );
        sessionStorage.setItem("token", token1);
        localStorage.setItem("region", action.payload.data.region);
        localStorage.setItem(
          "serviceCenterId",
          action.payload.data.serviceCenterId
        );
        localStorage.setItem(
          "serviceCenterName",
          action.payload.data.serviceCenterName
        );
        localStorage.setItem("roleName", action.payload.data.roleName);
      })
      .addCase(login.rejected, (state, action) => {
        state.currentUser = null;
        state.loading = "rejected";
        state.error = "Invalid email or password";
        state.token = null;
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("token");
      })
      .addCase(fetchToken.fulfilled, (state, action) => {
        state.token = action.token;
      });
  },
});

export const { logout } = loginSlice.actions;
export default loginSlice.reducer;
//End of File
