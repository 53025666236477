import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
 
const initialState = {
    columns: {},
    labeledData: [],
    loading: 'idle',
    error: null,
};


export const fetchOpenPlan = createAsyncThunk('data/fetchOpenPlan', async () => {
    try {
        const response = await api.listOpenPlan()
        // axios.get('http://13.234.248.118:5300/plan/listPlanOPtimization');
        // http://localhost:5300/plan/listPlanOptimization
        const json = response.data;
        console.log(json)
        const columnLabels = {
            planId: { label: 'ID'},
            planCombId: {label: 'Plan Id'},
            orderId: { label: 'Order ID'},
            //customerName: { label: 'Customer Name'},
            planDate: { label: 'Plan Date'},
            productionWeight: {label: 'Production Weight (T)'},
            scrapWithRebar:{label:'Scrap (T)'},
            offcutWithRebar: {label: 'Offcut (T)'},
            scrapPercentWithRebar: { label: 'Scrap %'},
            offcutPercentWithRebar: { label: 'Offcut %'},
            totalTags: { label: 'Total Tags'},
            // totalCycles: { label: 'Total Cycle'},
            // totalCuts: { label: 'Total Cut'},
            planStatus:{label:'Status'} ,
            //weight:{label:'Weight',type:'number',editable:false,isRequired:false} ,
           // remark:{label:'Remark',type:'text',editable:false,isRequired:false} ,
       
        };

        const selectedColumns = ['planId',
        'planCombId',
        // 'orderId',
        'planDate',
        'productionWeight','scrapWithRebar','offcutWithRebar','planStatus','scrapPercentWithRebar','offcutPercentWithRebar'];

        const columns = {};
        selectedColumns.forEach((columnName) => {
            if(columnName!=='planId')
            columns[columnName] = columnLabels[columnName];
        });
        // .PlanDetails
         
		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});
         
        return { columns, labeledData };
    } catch (error) {
        throw error;
    }
});

export const freezePlan = createAsyncThunk('data/freezePlan', async (id) => {
    try {

        const response = await api.uFreezePlan(id)
        // axios.put(`http://13.234.248.118:5300/masterPlan/update/${id}`);
        const data = response.data;
        console.log('Success');
        return data;
    } catch (error) {
        if(error.response.data.message)
		{toast.error(`${error.response.data.message}`)}
        throw error;
    }
});

const openPlanSlice = createSlice({
    name: 'openPlan',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchOpenPlan.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchOpenPlan.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.columns = action.payload.columns;
                state.labeledData = action.payload.labeledData;
            })
            .addCase(fetchOpenPlan.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(freezePlan.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(freezePlan.fulfilled, (state) => {
                toast.success('Plan Freezed')
                state.loading = 'fulfilled';
            })
            .addCase(freezePlan.rejected, (state) => {
                state.loading = 'rejected';
            })
    },
});
 
export default openPlanSlice.reducer;