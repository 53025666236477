// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.popup{
    position: fixed;
    top: 0;
    left:0;
    width: 100%;
    height:100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
}


.popup-content{
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}

.card1{
    border-radius: 5px;
    box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.25);
    padding: 10px;
    margin: 10px;
}
.button{
    display: flex;
    justify-content: right;
}

.card1 input[type="radio"] {
    display: none; 
  }`, "",{"version":3,"sources":["webpack://./src/assets/styles/BBSExcel.css"],"names":[],"mappings":";AACA;IACI,eAAe;IACf,MAAM;IACN,MAAM;IACN,WAAW;IACX,WAAW;IACX,iCAAiC;IACjC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;AACf;;;AAGA;IACI,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;AAC5C;;AAEA;IACI,kBAAkB;IAClB,4CAA4C;IAC5C,aAAa;IACb,YAAY;AAChB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;EACf","sourcesContent":["\r\n.popup{\r\n    position: fixed;\r\n    top: 0;\r\n    left:0;\r\n    width: 100%;\r\n    height:100%;\r\n    background-color: rgba(0,0,0,0.5);\r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    z-index: 10;\r\n}\r\n\r\n\r\n.popup-content{\r\n    background-color: #fff;\r\n    padding: 20px;\r\n    border-radius: 5px;\r\n    box-shadow: 0px 0px 10px rgba(0,0,0,0.3);\r\n}\r\n\r\n.card1{\r\n    border-radius: 5px;\r\n    box-shadow: 0px 1px 6px 0px rgba(0,0,0,0.25);\r\n    padding: 10px;\r\n    margin: 10px;\r\n}\r\n.button{\r\n    display: flex;\r\n    justify-content: right;\r\n}\r\n\r\n.card1 input[type=\"radio\"] {\r\n    display: none; \r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
