// // import React, { useState, useEffect } from "react";
// // import { toast } from "react-toastify";
// // import * as api from "../../services/API";
// // import { useSelector, useDispatch } from "react-redux";
// // import "./Dashboard/Dashboard";
// // import {
// //   fetchProdHistDiaWise,
// //   fetchProdRecordOrderWise,
// // } from "../../redux-tookit/slices/dashboardSlice";
// // import { fetchDiaWiseOrderQty } from "../../redux-tookit/slices/diaWiseQuantitySlice";
// // import { fetchdailyproductionqty } from "../../redux-tookit/slices/dailyproductionquantitySlice";
// // import AGResizeTables from "../../components/Tables/AGResizeTables";
// // const DashBoard = () => {
// //   const [
// //     regionWiseSeviceCenterDataForDailyWise,
// //     setRegionWiseSeviceCenterDataForDailyWise,
// //   ] = useState([]);
// //   const [region, setRegion] = useState("All");
// //   const [orderId, setOrderId] = useState("");
// //   const [serviceCenterListData, setServiceCenterListData] = useState([]);
// //   const [dailyWiseData, setDailyWiseData] = useState([]);
// //   const dashboard = useSelector((state) => state.dashboard.json);
// //   const dispatch = useDispatch();
// //   const [dataForTable, setDataForTable] = useState([]);

// //   const [dailyWiseName, setDailyWiseName] = useState("All");
// //   const regions = ["North", "South", "East", "West"];
// //   const roleName = localStorage.getItem("roleName");
// //   const regionName = localStorage.getItem("region");
// //   console.log(dashboard, "dashboard");
// //   const prodRcdOrdWiseData = useSelector(
// //     (state) => state.dashboard.labeledData2
// //   );
// //   const prodRcdOrdWiseColumns = useSelector(
// //     (state) => state.dashboard.columns2
// //   );
// //   console.log(dataForTable, "dataForTable");
// //   console.log(dailyWiseData, "dailyWiseData");
// //   useEffect(() => {
// //     dispatch(fetchProdRecordOrderWise()).then((result) => {
// //       setDataForTable(result?.payload?.json?.rows);
// //     });
// //   }, [dispatch]);

// //   useEffect(() => {
// //     // getDailyWiseQuantityByServiceId();
// //     getDailyWiseByRegions();
// //   }, [region, dailyWiseName, orderId]);
// //   const getDailyWiseByRegions = async () => {
// //     let regionWise = "";
// //     if (roleName === "superadmin") {
// //       regionWise = region;
// //     } else {
// //       regionWise = regionName;
// //     }
// //     await api
// //       .listServiceCenterByRegion(regionWise)
// //       .then((result) => {
// //         setRegionWiseSeviceCenterDataForDailyWise(result?.data?.rows);
// //       })
// //       .catch((err) => {
// //         console.log(err);
// //       });
// //   };
// //   const getServiceCenterList = async () => {
// //     await api
// //       .listServiceCenter()
// //       .then((result) => {
// //         setServiceCenterListData(result?.data?.rows);
// //       })
// //       .catch((err) => {
// //         console.log(err);
// //       });
// //   };

// //   useEffect(() => {
// //     getServiceCenterList();
// //   }, []);
// //   useEffect(() => {
// //     const serviceData = regionWiseSeviceCenterDataForDailyWise.filter(
// //       (item) => item.name === dailyWiseName
// //     );
// //     const serviceDataForAllRegionsByName = serviceCenterListData.filter(
// //       (item) => item.name === dailyWiseName
// //     );
// //     // const serviceDataByOrdeId = dataForTable.filter(
// //     //   (item) => item.combId === orderId
// //     // );

// //     if (serviceData.length > 0) {
// //       getDailyWiseQuantityByServiceId(serviceData[0]?.serviceCenterId);
// //     }
// //     if (serviceDataForAllRegionsByName.length > 0) {
// //       getDailyWiseQuantityByServiceId(
// //         serviceDataForAllRegionsByName[0]?.serviceCenterId
// //       );
// //     }
// //     if (serviceDataForAllRegionsByName.length > 0) {
// //       getDailyWiseQuantityByServiceId(
// //         serviceDataForAllRegionsByName[0]?.serviceCenterId
// //       );
// //     }
// //     // if (serviceDataByOrdeId.length > 0) {
// //     //   getDailyWiseQuantityByServiceId(serviceDataByOrdeId[0]?.combId);
// //     // }
// //   }, [dailyWiseName, region, serviceCenterListData, orderId]);

// //   const getDailyWiseQuantityByServiceId = async (serviceCenterId) => {
// //     let queryString = "";
// //     if (region !== "All") {
// //       queryString += `?region=${region}&`;
// //     }
// //     if (serviceCenterId) {
// //       queryString += `${
// //         region === "All"
// //           ? `?serviceCenterId=${serviceCenterId}&`
// //           : `serviceCenterId=${serviceCenterId}&`
// //       }`;
// //     }
// //     // if (dailyWiseName !== "All" && orderId) {
// //     //   queryString += `combId=${orderId}`;
// //     // }

// //     await api
// //       .listProdRecordOrderWise(queryString)
// //       .then((result) => {
// //         if (result?.data?.rows.length > 0) {
// //           setDailyWiseData(result?.data?.rows);
// //           console.log(result?.data?.rows, "result?.data?.rows");
// //         } else {
// //           setDailyWiseData([]);
// //         }
// //       })
// //       .catch((err) => {
// //         console.log(err);
// //       });
// //   };
//   return (
//     <>
//       <div>
//         <div className="head">Order Wise Production Record</div>

//         <div
//           className="filter-card"
//           style={{
//             margin: "20px 10px",
//             padding: "20px 20px",
//             display: "flex",
//             flexDirection: "row",
//           }}
//         >
//           <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
//             {roleName !== "admin" ? (
//               <>
//                 {roleName === "superadmin" ? (
//                   <>
//                     {" "}
//                     <label
//                       htmlFor="dia-total-gradeFil"
//                       style={{ color: "black" }}
//                     >
//                       Regions
//                     </label>
//                     <select
//                       name="grade"
//                       id="dia-total-gradeFil"
//                       style={{ width: "fit-content" }}
//                       onChange={(e) => setRegion(e.target.value)}
//                     >
//                       <option value="All">All</option>
//                       {regions.map((region, index) => (
//                         <option key={index} value={region}>
//                           {region}
//                         </option>
//                       ))}
//                     </select>
//                   </>
//                 ) : (
//                   <label
//                     htmlFor="dia-total-gradeFil"
//                     style={{ color: "black" }}
//                   >
//                     Region: <></>
//                     {regionName.charAt(0).toUpperCase() + regionName.slice(1)}
//                   </label>
//                 )}
//               </>
//             ) : (
//               ""
//             )}
//           </div>
//           {roleName !== "admin" && (
//             <div
//               className="filter"
//               style={{ alignItems: "center", gap: "10px" }}
//             >
//               <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
//                 Service Center Name
//               </label>
//               <select
//                 name="grade"
//                 id="dia-total-gradeFil"
//                 style={{ width: "fit-content" }}
//                 onChange={(e) => setDailyWiseName(e.target.value)}
//               >
//                 <option value="All">All</option>
//                 {[
//                   ...new Set(
//                     region === "All"
//                       ? serviceCenterListData.map((item) => item.name)
//                       : regionWiseSeviceCenterDataForDailyWise.map(
//                           (item) => item.name
//                         )
//                   ),
//                 ].map((data) => (
//                   <option value={data}>{data}</option>
//                 ))}
//               </select>
//             </div>
//           )}
//           {/* {roleName !== "admin" && (
//             <div
//               className="filter"
//               style={{ alignItems: "center", gap: "10px" }}
//             >
//               <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
//                 Order Id
//               </label>
//               <select
//                 name="grade"
//                 id="dia-total-gradeFil"
//                 style={{ width: "fit-content" }}
//                 onChange={(e) => setOrderId(e.target.value)}
//               >
//                 <option value="All">All</option>
//                 {[
//                   ...new Set(
//                     region === "All"
//                       ? dataForTable.map((item) => item.combId)
//                       : dailyWiseData.map((item) => item.combId)
//                   ),
//                 ].map((data) => (
//                   <option value={data}>{data}</option>
//                 ))}
//               </select>
//             </div>
//           )} */}

//           {/* <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
//           {" "}
//           <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
//             Start Date:
//           </label>
//           <input
//             name="grade"
//             type="date"
//             value={startDate}
//             id="dia-total-gradeFil"
//             style={{ width: "fit-content" }}
//             onChange={(e) => setStartDate(e.target.value)}
//             max={endDate}
//           ></input>
//         </div>
//         <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
//           {" "}
//           <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
//             End Date:
//           </label>
//           <input
//             name="grade"
//             type="date"
//             value={endDate}
//             id="dia-total-gradeFil"
//             style={{ width: "fit-content" }}
//             onChange={(e) => setEndDate(e.target.value)}
//             min={startDate}
//           ></input>
//         </div> */}
//         </div>

//         <AGResizeTables
//           data={
//             region === "All" && dailyWiseName === "All"
//               ? prodRcdOrdWiseData
//               : dailyWiseData
//           }
//           columns={prodRcdOrdWiseColumns}
//           width="100%"
//           height="500px"
//         />
//       </div>
//     </>
//   );
// };

// export default DashBoard;

import { useSelector, useDispatch } from "react-redux";
import React, { useMemo, useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  fetchProdHistDiaWise,
  fetchProdRecordOrderWise,
} from "../../redux-tookit/slices/dashboardSlice";
import * as api from "../../services/API";
const MyGrid = () => {
  const [filteredData, stFilteredData] = useState([]);
  const [
    regionWiseSeviceCenterDataForDailyWise,
    setRegionWiseSeviceCenterDataForDailyWise,
  ] = useState([]);
  const [region, setRegion] = useState("All");
  const [orderId, setOrderId] = useState("All");
  const [serviceCenterListData, setServiceCenterListData] = useState([]);
  const [dailyWiseData, setDailyWiseData] = useState([]);
  const dashboard = useSelector((state) => state.dashboard.json);

  const [dailyWiseName, setDailyWiseName] = useState("All");
  const regions = ["North", "South", "East", "West"];
  const roleName = localStorage.getItem("roleName");
  const regionName = localStorage.getItem("region");
  const [tableData, setDataForTable] = useState([]);
  const dispatch = useDispatch();
  // useEffect(() => {
  //   dispatch(fetchProdRecordOrderWise()).then((result) => {
  //     setDataForTable(result?.payload?.json?.rows);
  //   });
  // }, [dispatch]);
  // useEffect(() => {
  //   if (region !== "All") {
  //     getDailyWiseByRegions();
  //   }
  //   getDailyWiseQuantityByServiceId();
  // }, [region]);
  // useEffect(() => {
  //   getDailyWiseByRegions();
  // }, [region, dailyWiseName, orderId]);
  // const getDailyWiseByRegions = async () => {
  //   let regionWise = "";
  //   if (roleName === "superadmin") {
  //     regionWise += `?region=${region}`;
  //   } else {
  //     regionWise += `?regionName=${regionName}`;
  //   }
  //   await api
  //     .listServiceCenterByRegion(regionWise)
  //     .then((result) => {
  //       setRegionWiseSeviceCenterDataForDailyWise(result?.data?.rows);
  //       // getDailyWiseQuantityByServiceId();
  //       setOrderId("All");
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  // const getServiceCenterList = async () => {
  //   await api
  //     .listServiceCenter()
  //     .then((result) => {
  //       setServiceCenterListData(result?.data?.rows);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getServiceCenterList();
  // }, []);
  // useEffect(() => {
  //   const serviceData = regionWiseSeviceCenterDataForDailyWise.filter(
  //     (item) => item.name === dailyWiseName
  //   );
  //   const serviceDataForAllRegionsByName = serviceCenterListData.filter(
  //     (item) => item.name === dailyWiseName
  //   );
  //   const serviceDataByOrdeId = tableData.filter(
  //     (item) => item.combId === orderId
  //   );

  //   if (serviceData.length > 0) {
  //     getDailyWiseQuantityByServiceId(serviceData[0]?.serviceCenterId);
  //   }
  //   if (serviceDataForAllRegionsByName.length > 0) {
  //     getDailyWiseQuantityByServiceId(
  //       serviceDataForAllRegionsByName[0]?.serviceCenterId
  //     );
  //   }
  //   // if (serviceDataForAllRegionsByName.length > 0) {
  //   //   getDailyWiseQuantityByServiceId(
  //   //     serviceDataForAllRegionsByName[0]?.serviceCenterId
  //   //   );
  //   // }
  //   if (serviceDataByOrdeId.length > 0) {
  //     getDailyWiseQuantityByServiceId(serviceDataByOrdeId[0]?.combId);
  //   }
  // }, [dailyWiseName, region, serviceCenterListData, orderId]);

  // const getDailyWiseQuantityByServiceId = async (serviceCenterId) => {
  //   let queryString = "";
  //   if (region !== "All") {
  //     queryString += `?region=${region}&`;
  //   }
  //   if (serviceCenterId) {
  //     queryString += `${
  //       region === "All"
  //         ? `?serviceCenterId=${serviceCenterId}&`
  //         : `serviceCenterId=${serviceCenterId}&`
  //     }`;
  //   }
  //   if (dailyWiseName !== "All" && orderId !== "All" && orderId) {
  //     queryString += `combId=${orderId}`;
  //   }

  //   await api
  //     .listProdRecordOrderWise(queryString)
  //     .then((result) => {
  //       if (result?.data?.rows?.length > 0) {
  //         setDataForTable(result?.data?.rows);
  //         console.log(result?.data?.rows, "result?.data?.rows");
  //       } else {
  //         setDataForTable([]);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const getServiceCenterList = async () => {
    await api
      .listServiceCenter()
      .then((result) => {
        setServiceCenterListData(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getServiceCenterList();
  }, []);

  useEffect(() => {
    setDailyWiseName("All");
    setOrderId("All");
    getAppMetricsByRegions();
  }, [region, regionName]);
  const getAppMetricsByRegions = async () => {
    let regionWise = "";
    if (roleName === "superadmin") {
      regionWise += `?region=${region}`;
    } else {
      regionWise += `?region=${regionName}`;
    }
    await api
      .listServiceCenterByRegion(regionWise)
      .then((result) => {
        setRegionWiseSeviceCenterDataForDailyWise(result?.data?.rows);
        setOrderId("");
        setDailyWiseName("All");
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (
      (region === "All" && dailyWiseName === "All") ||
      (regionName && dailyWiseName === "All")
    ) {
      getAppMatricsByServId();
    } else if (region !== "All" && dailyWiseName === "All") {
      setOrderId("All");
      getAppMatricsByServId();
    }
  }, [region, dailyWiseName, orderId]);
  useEffect(() => {
    setOrderId("All");
  }, [dailyWiseName]);
  useEffect(() => {
    getAppByRegions();
    setOrderId("All");
    setDailyWiseName("All");
  }, [region, regionName]);
  const getAppByRegions = async () => {
    let regionWise = "";
    if (roleName === "superadmin") {
      regionWise = `?region=${region}`;
    } else {
      regionWise = `?region=${regionName}`;
    }
    await api
      .listServiceCenterByRegion(regionWise)
      .then((result) => {
        if (result?.data?.length > 0) {
          setRegionWiseSeviceCenterDataForDailyWise(result?.data?.rows);
        } else {
          setRegionWiseSeviceCenterDataForDailyWise([]);
          setDataForTable([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const serviceData = regionWiseSeviceCenterDataForDailyWise.filter(
      (item) => item.name === dailyWiseName
    );
    const serviceDataForAllRegionsByName = serviceCenterListData.filter(
      (item) => item.name === dailyWiseName
    );

    if (serviceData.length > 0 || serviceDataForAllRegionsByName.length > 0) {
      getAppMatricsByServId(
        serviceData[0]?.serviceCenterId ||
          serviceDataForAllRegionsByName[0]?.serviceCenterId
      );
    }
    if (serviceDataForAllRegionsByName.length > 0) {
      getAppMatricsByServId(serviceDataForAllRegionsByName[0]?.serviceCenterId);
    }
  }, [dailyWiseName, region, regionName, orderId]);

  const getAppMatricsByServId = async (serviceCenterId) => {
    let queryString = "";
    if (region !== "All") {
      queryString += `?region=${region}&`;
    }
    if (serviceCenterId) {
      queryString += `${
        region === "All"
          ? `?serviceCenterId=${serviceCenterId}&`
          : `serviceCenterId=${serviceCenterId}&`
      }`;
    }

    if (roleName === "superadmin" && orderId !== "All" && orderId) {
      if (region === "All" && serviceCenterId) {
        queryString += `combId=${orderId}`;
      } else if (region !== "All" && serviceCenterId && orderId) {
        queryString += `combId=${orderId}`;
      } else if (region === "All" && !serviceCenterId) {
        queryString += `?combId=${orderId}`;
      }
    }

    if (roleName === "regionalManager" && orderId !== "All" && orderId) {
      if (serviceCenterId) {
        queryString += `combId=${orderId}`;
      } else {
        queryString += `?combId=${orderId}`;
      }
    }

    await api
      .listProdRecordOrderWise(queryString)
      .then((result) => {
        if (result?.data?.rows?.length > 0) {
          setDataForTable(result?.data?.rows);
        } else {
          setDataForTable([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columnLabels = {
    sln: { label: "SL.No" },
    orderId: { label: "Order Id", sortable: true, filter: true },
    orderRemarks: { label: "Order Remark", sortable: true, filter: true },
    soldToParty: { label: "Customer Name", sortable: true, filter: true },
    orderItemName: { label: "Element Name", sortable: true, filter: true },
    totalNoOfPieces: { label: "Total Pieces", sortable: true, filter: true },
    productionWeight: {
      label: "Production Weight(T)",
      sortable: true,
      filter: true,
    },
    remainingPieces: {
      label: "Remaining Pieces",
      sortable: true,
      filter: true,
    },
    processedPieces: {
      label: "Processed Pieces",
      sortable: true,
      filter: true,
    },
  };

  const columnDefs = useMemo(() => {
    return Object.keys(columnLabels).map((key) => ({
      field: key,
      headerName: columnLabels[key].label,
    }));
  }, [columnLabels]);
  let slnCounter = 1;

  const rowData = useMemo(() => {
    const flattenedData = tableData.flatMap((order, index) =>
      order.OrderItems.flatMap((orderItem, i1) =>
        orderItem.PartItems.map((partItem, i2) => ({
          sln: slnCounter++, // Calculating SLN based on index
          orderId: i1 === 0 && i2 === 0 ? order.combId : "",
          orderRemarks: i1 === 0 && i2 === 0 ? order.orderRemarks : "",
          soldToParty: i1 === 0 && i2 === 0 ? order.soldToParty : "",
          orderItemName: i2 === 0 ? orderItem.orderItemName : "",
          totalNoOfPieces: partItem.totalNoOfPieces,
          productionWeight: partItem.productionWeight,
          remainingPieces: partItem.remainingPieces,
          processedPieces: partItem.processedPieces,
        }))
      )
    );
    return flattenedData;
  }, [tableData]);

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      autoHeaderHeight: true,
      autoHeight: true,
      maxWidth: 500,
    };
  }, []);

  return (
    <div className="sdbr">
      <div className="head">Order Wise Production Record</div>
      {roleName !== "admin" && (
        <>
          <div
            className="filter-card"
            style={{
              margin: "20px 10px",
              padding: "20px 20px",
              display: "flex",
              flexDirection: "row",
            }}
          >
            {roleName !== "salesManager" && (
              <div
                className="filter"
                style={{ alignItems: "center", gap: "10px" }}
              >
                {roleName !== "admin" ? (
                  <>
                    {roleName === "superadmin" ? (
                      <>
                        {" "}
                        <label
                          htmlFor="dia-total-gradeFil"
                          style={{ color: "black" }}
                        >
                          Regions
                        </label>
                        <select
                          name="grade"
                          id="dia-total-gradeFil"
                          style={{ width: "fit-content" }}
                          onChange={(e) => setRegion(e.target.value)}
                        >
                          <option value="All">All</option>
                          {regions.map((region, index) => (
                            <option key={index} value={region}>
                              {region}
                            </option>
                          ))}
                        </select>
                      </>
                    ) : (
                      <label
                        htmlFor="dia-total-gradeFil"
                        style={{ color: "black" }}
                      >
                        Region: <></>
                        {regionName.charAt(0).toUpperCase() +
                          regionName.slice(1)}
                      </label>
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
            )}

            {roleName !== "admin" && (
              <div
                className="filter"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Service Center Name
                </label>
                <select
                  value={dailyWiseName}
                  name="grade"
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setDailyWiseName(e.target.value)}
                >
                  <option value="All">All</option>
                  {[
                    ...new Set(
                      region
                        ? serviceCenterListData.map((item) => item.name)
                        : regionWiseSeviceCenterDataForDailyWise.map(
                            (item) => item.name
                          )
                    ),
                  ].map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
            )}
            {roleName !== "admin" && (
              <div
                className="filter"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Order Id
                </label>
                <select
                  value={orderId}
                  name="grade"
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setOrderId(e.target.value)}
                >
                  <option value="All">All</option>
                  {[...new Set(tableData.map((item) => item.combId))].map(
                    (data) => (
                      <option value={data}>{data}</option>
                    )
                  )}
                </select>
              </div>
            )}

            {/* <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
  {" "}
  <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
    Start Date:
  </label>
  <input
    name="grade"
    type="date"
    value={startDate}
    id="dia-total-gradeFil"
    style={{ width: "fit-content" }}
    onChange={(e) => setStartDate(e.target.value)}
    max={endDate}
  ></input>
</div>
<div className="filter" style={{ alignItems: "center", gap: "10px" }}>
  {" "}
  <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
    End Date:
  </label>
  <input
    name="grade"
    type="date"
    value={endDate}
    id="dia-total-gradeFil"
    style={{ width: "fit-content" }}
    onChange={(e) => setEndDate(e.target.value)}
    min={startDate}
  ></input>
</div> */}
          </div>
        </>
      )}

      <div className="ag-theme-alpine" style={{ height: 500, width: "100%" }}>
        <AgGridReact
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          // pagination={true}
          // paginationPageSize={10}
        />
      </div>
    </div>
  );
};

export default MyGrid;
