import React, { useState } from 'react'
import './productSelectPage.css'
import SmFab from '../assets/images/productSelectionPage/smartFabLogo.png'
import TscnRdyBld from '../assets/images/icons/header/header-left.svg'

const ProductSelectPage = ({setProductName, productName}) => {

  const [name, uName] = useState('')

  const interSetName = (name) => {

    uName(name)
    setTimeout(() => {
      setProductName(name)
    }, 300);
    
  }

  return (
    <div className='productSelectPage' style={{display:`${productName===''?'flex':'none'}`}}>
      
      <div className={`productCard ${name==='tiscon'?'selected':''}`} onClick={()=>{interSetName("tiscon")}}>
        <img src={TscnRdyBld} alt="Tiscon ReadyBuild" />
      </div>

      <div className={`productCard ${name==='smartFab'?'selected':''}`} onClick={()=>{interSetName("smartFab")}} >
        <img src={SmFab} alt="Sm@rtFAB" />
      </div>
      
      <div className="productBackground">

      </div>
    </div>
  )
}

export default ProductSelectPage