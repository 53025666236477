// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.access-table-container {
    border: 1px solid rgba(0, 0, 0, 0.438);
    width: 100%;
    max-height: 60vh;
    /* border-radius: 10px; */
    overflow: auto;
}

.access-table-container table {
    border-collapse:collapse;
    border-spacing: 1px;
    text-indent:initial;
    width: 100%;
}

.access-table-container td,.access-table-container th {
    border: 2px solid #ddd;
    padding: 10px 20px;
    text-align: center;

}

.access-table-container th {
    border:1px solid #ddd;
    border-top: 0px;
}

.access-table-container th:first-child {
    border-left: 0px;
}

.access-table-container thead{
    background-color: #138EE0;
    
    color: #fff;
    position: sticky;
    top: 0;
}

.access-table-container tbody{
    font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/UserAccess/Access.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;IACtC,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,wBAAwB;IACxB,mBAAmB;IACnB,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,kBAAkB;IAClB,kBAAkB;;AAEtB;;AAEA;IACI,qBAAqB;IACrB,eAAe;AACnB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;;IAEzB,WAAW;IACX,gBAAgB;IAChB,MAAM;AACV;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".access-table-container {\r\n    border: 1px solid rgba(0, 0, 0, 0.438);\r\n    width: 100%;\r\n    max-height: 60vh;\r\n    /* border-radius: 10px; */\r\n    overflow: auto;\r\n}\r\n\r\n.access-table-container table {\r\n    border-collapse:collapse;\r\n    border-spacing: 1px;\r\n    text-indent:initial;\r\n    width: 100%;\r\n}\r\n\r\n.access-table-container td,.access-table-container th {\r\n    border: 2px solid #ddd;\r\n    padding: 10px 20px;\r\n    text-align: center;\r\n\r\n}\r\n\r\n.access-table-container th {\r\n    border:1px solid #ddd;\r\n    border-top: 0px;\r\n}\r\n\r\n.access-table-container th:first-child {\r\n    border-left: 0px;\r\n}\r\n\r\n.access-table-container thead{\r\n    background-color: #138EE0;\r\n    \r\n    color: #fff;\r\n    position: sticky;\r\n    top: 0;\r\n}\r\n\r\n.access-table-container tbody{\r\n    font-weight: 400;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
