import React, { useState } from 'react';
import '../assets/styles/GenericTable.css';
import '../assets/styles/SDBRTable.css'


const ProdUploadTable2 = ({ columns, data}) => {


  return (
    <div className="table-container">
      <table className="generic-table">
        <thead className='stick'>
          <tr>
            {columns.map((column) => (
              <th key={column.key} className="table-header-1">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
              {columns.map((column) => (
                
                <td key={column.key}>{row[column.key]}</td>
              ))}
            </tr>
          ))}
            
            <tr>
            {columns.map((column) => (
              <th key={column.key} className="table-header-1">
                {column.key==="length"||column.key==="actualQuantity"?<input type="text" />:  <select name="" id=""></select>}
              
              </th>
            ))}
          </tr>
            
        </tbody>
      </table>
    </div>
  );
};

export default ProdUploadTable2;