import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { postOrganizations, updateOrganization } from '../../../redux-tookit/slices/organizationSlice';
import Button from '../../../utils/Button';
import { postEquipmentCategory } from '../../../redux-tookit/slices/equipmentCategorySlice';
import { fetchProcessType, fetchProcessTypeSF, postProcessType, postProcessTypeSF, updateProcessType, updateProcessTypeSF } from '../../../redux-tookit/slices/processTypeSlice';

const AddProcessType = (props) => {

  const dispatch = useDispatch();
  const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData, productName } = props
  const [newRow, setNewRow] = useState({});
  const columns = useSelector((state) => state.processType.columns);
  const handleInputChange = (header, e) => {
    const updatedData = JSON.parse(JSON.stringify(editData));
    updatedData[header] = e.target.value;
    setEditData(updatedData);
  }
  const handleSaveButtonClick = async (e) => {
    e.preventDefault();

    if (productName === 'smartFab') {
      dispatch(postProcessTypeSF({ ...newRow }));
    } else
      dispatch(postProcessType({ ...newRow }));
    
    console.log(newRow);
    setIsAdding(false);
    setNewRow({});
  };
  
  const handleUpdate = async (e) => {
    e.preventDefault();

    let id = ''

    let type = {}

    if (productName === 'smartFab') {
      type = { 'smartFabProcessType': editData.smartFabProcessType };
      id = editData.smartFabProcessTypeId
    } else {
      type = { 'processType': editData.processType };
      id = editData.processTypeId
    }
    console.log(id);
    console.log(type);

    if (productName === 'smartFab') {
      dispatch(updateProcessTypeSF({ id, type })).then((res) => {
        if (res.payload !== undefined) {
          dispatch(fetchProcessTypeSF())
        }
      })
    } else {
      dispatch(updateProcessType({ id, type })).then((res) => {
        if (res.payload !== undefined) {
          dispatch(fetchProcessType())
        }
      })
    }

    setIsEditing(false);
  }

  return (
    <div>
      <form onSubmit={handleSaveButtonClick}>{isAdding &&
        <div>
          <div className="AddContainer" >
            <div className="form-container"><div className="head">Add Process Type</div>
              <div className="table-container">
                {Object.keys(columns).map((column) => (
                  !column.toLowerCase().includes('id') ?
                    (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                      <label>
                        {columns[column].label}
                        <span style={{ color: 'red' }}>*</span>
                      </label>
                      <input
                        key={column}
                        type={columns[column].type}
                        className='table-input'
                        value={newRow[column] || ''}
                        onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                      />
                    </div>) :
                    ""
                ))}
              </div>
              <div className="bottom-button-container">
                <Button type='submit' label='Save' className='blue' />
                <Button onClick={() => { setIsAdding(false); setNewRow({}) }} label='Cancel' className='blue' />
              </div>
            </div>
          </div>
          <div className="bg-dark"></div>
        </div>
      }
      </form>
      <form onSubmit={handleUpdate}>{isEditing &&
        <div>
          <div className="AddContainer" >
            <div className="form-container"><div className="head">Edit Process Type</div>
              <div className="table-container">
                {Object.keys(columns).map((key) => (
                  columns[key].editable === true ?
                    (
                      <div >
                        <div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                          <label>
                            {columns[key].label}
                            <span style={{ color: 'red' }}>*</span>
                          </label>
                          <input
                            type={columns[key].type}
                            className='table-input'
                            value={editData[key]}
                            onChange={(e) => handleInputChange(key, e)}
                          /></div>
                      </div>) :
                    ""
                ))}
              </div>
              <div className="bottom-button-container">
                <Button type='submit' label='Save' className='blue' />
                <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
              </div>
            </div>
          </div>
          <div className="bg-dark"></div>
        </div>
      }
      </form>
    </div>
  )
}

export default AddProcessType