import React, { useContext } from 'react'
import AddIcon from '../../../assets/images/icons/action/plus-box.svg'
import '../../../assets/styles/StockDetails.css'
import { toast } from 'react-toastify';
import { UserAccessContext } from '../../../App';


const MultipleAddStock = ({ newRow, gradeMasterData, columnKeys, columns, tempArray, setTempArray }) => {

    const tableHeaders = ['Stock Type', 'Grade', 'Diameter (mm)', 'Material', 'Invoice Weight (T)', 'Batch No.', 'No. of Pieces', 'Min length', 'Max length', 'Description'];
    const tableKeys = ['stockType', 'gradeName', 'diameter', 'material', 'invoiceWeight', 'batchNo', 'noOfPieces', 'minLength', 'maxLength', 'description'];

    const productName = useContext(UserAccessContext).productName

    console.log(gradeMasterData, tempArray);
    

    const handleNewMultiAdd = () => {
        setTempArray((prev) => {
            let multiArray = JSON.parse(JSON.stringify(prev))
            if(productName==='smartFab'){
                multiArray.push({ productName: '', diameter: '', material: '', 'invoiceWeight': '', 'batchNo': '', 'noOfPieces': '', 'description': '' })

            } else multiArray.push({ gradeName: '', diameter: '', material: '', stockType: '', 'invoiceWeight': '', 'batchNo': '', 'noOfPieces': '', 'description': '' })
            return multiArray
        })
    }

    const handleRemoveRow = (index) => {
        if (tempArray.length === 1) {
            toast.error('Cannot remove')
            return
        }
        setTempArray((prev) => {
            let multiArray = JSON.parse(JSON.stringify(prev))
            multiArray.splice(index, 1)
            return multiArray
        })
    }

    return (

        <div className="table-container" style={{ maxHeight: '400px', overflow: 'auto', boxShadow: '0px 4px 9px 1px rgba(0, 0, 0, 0.2)' }}>

            <table className="generic-table">
                <thead>
                    <tr>
                        {tableHeaders.map(data => (
                            (productName==='smartFab'&& data!=='No. of Pieces' &&  data!=='Grade' &&  data!=='Min length' &&  data!=='Max length'|| productName!=='smartFab')&&
                            (data === 'Batch No.' || data === 'Description' ?
                                <th key={data} className="table-header" style={{ backgroundColor: '#fff', color: '#555', borderLeft: '1px solid #e5e5e5' }}>{data}</th>
                                :
                                <th key={data} className="table-header" style={{ backgroundColor: '#fff', color: '#555', borderLeft: '1px solid #e5e5e5' }}>{data}<span style={{ color: 'red' }}>*</span></th>)
                        ))}
                        <th className="table-header" style={{ width: '20px', backgroundColor: '#fff' }} onClick={handleNewMultiAdd}>
                            <img src={AddIcon} alt="add" style={{ width: '30px' }} />
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {tempArray.map((row, index) => (
                        <tr>

                            {tableKeys.map((header) => (
                                <>
                                {(productName==='smartFab'&&header!=='noOfPieces'&&header!=='stockType'&&header!=='material'&&header!=='diameter'&&header!=='gradeName' &&header!=='minLength' &&header!=='maxLength' || productName!=='smartFab') &&
                                (header === 'gradeName' ?
                                    
                                    <>
                                        <td>
                                            <select name="gradeName" id="gradeName"
                                                required
                                                value={row[header]}
                                                style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                onChange={(e) => {
                                                    setTempArray((prev) => {
                                                        let multiArray = JSON.parse(JSON.stringify(prev))
                                                        multiArray[index] = {
                                                            ...multiArray[index],
                                                            gradeName: e.target.value,
                                                            diameter: '',
                                                            material: '',
                                                            noOfPieces: ''
                                                        }
                                                        return multiArray
                                                    })
                                                }}
                                            >

                                                <option value="">-Select-</option>
                                                {[...new Set(gradeMasterData.filter(item => item.materialType === row.stockType).map(item => item.gradeName))].map((value, index) => (
                                                    <option key={index} value={value}>
                                                        {value}
                                                    </option>
                                                ))}
                                            </select>
                                        </td>

                                    </>
                                    :
                                    header === 'stockType' ?
                                        <>
                                            <td>
                                                <select
                                                    id="materialType"
                                                    // required
                                                    value={row.stockType}
                                                    required
                                                    style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}

                                                    onChange={(e) => {
                                                        setTempArray((prev) => {
                                                            let multiArray = JSON.parse(JSON.stringify(prev))
                                                            multiArray[index] = {
                                                                ...multiArray[index],
                                                                stockType: e.target.value,
                                                                diameter: '',
                                                                gradeName: '',
                                                                material: '',
                                                                noOfPieces: ''
                                                            }
                                                            return multiArray
                                                        })
                                                    }}
                                                >

                                                    <option value=''>Select</option>
                                                    {[...new Set(gradeMasterData
                                                        // .filter((item) => item.gradeName === gradeDrop.gradeName && item.diameter * 1 === gradeDrop.diameter * 1)
                                                        .map(item => item.materialType))].sort((a, b) => a - b).map((value, index) => (
                                                            <option key={index} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                </select>
                                            </td>
                                        </>
                                        :
                                        header === 'diameter' ?
                                            <>
                                                <td>
                                                    <select
                                                        id="diameter"
                                                        value={row.diameter}
                                                        required
                                                        style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                        onChange={(e) => {
                                                            setTempArray((prev) => {
                                                                let multiArray = JSON.parse(JSON.stringify(prev))
                                                                multiArray[index] = {
                                                                    ...multiArray[index],
                                                                    diameter: e.target.value,
                                                                    material: '',
                                                                    noOfPieces: ''
                                                                }
                                                                return multiArray
                                                            })
                                                        }}
                                                    >
                                                        <option value=''>Select</option>

                                                        {[...new Set(gradeMasterData.filter((item) => item.gradeName === row.gradeName && item.materialType === row.stockType).map(item => item.diameter))].sort((a, b) => a - b).map((value, index) => (
                                                            <option key={index} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </td>
                                            </> :
                                            header === 'material' ?
                                                <>
                                                    <td>
                                                        <select
                                                            id="material"
                                                            value={row.material}
                                                            required
                                                            style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                            onChange={(e) => {
                                                                //         if(foundMaterial){
                                                                //         setNewRow({
                                                                //         ...newRow, 
                                                                //         minLength: foundMaterial.minLength,
                                                                //         maxLength: foundMaterial.maxLength
                                                                //         })
                                                                //         }
                                                                setTempArray((prev) => {
                                                                    let multiArray = JSON.parse(JSON.stringify(prev))
                                                                    let minLength
                                                                    let maxLength
                                                                    if (e.target.value !== '' && multiArray[index].diameter !== '' && multiArray[index].invoiceWeight) {
                                                                        let length = 0;
                                                                        
                                                                        gradeMasterData.forEach(el => {
                                                                            if (el.material === e.target.value) { 
                                                                                length = (el.minLength + el.maxLength) / 2; 
                                                                                minLength = el.minLength;
                                                                                maxLength = el.maxLength;
                                                                            }
                                                                        })
                                                                        let totalPiece = 0;
                                                                        if (`${length}` === '0') {
                                                                            totalPiece = ''
                                                                        } else {
                                                                            totalPiece = Math.floor(multiArray[index].invoiceWeight * 4000000000000 / (length * Math.PI * 7850 * multiArray[index].diameter * multiArray[index].diameter))
                                                                        }
                                                                        multiArray[index] = { ...multiArray[index], noOfPieces: totalPiece}
                                                                    } else {
                                                                        gradeMasterData.forEach(el => {
                                                                            if (el.material === e.target.value) { 
                                                                                minLength = el.minLength;
                                                                                maxLength = el.maxLength;
                                                                            }
                                                                        })
                                                                    }
                                                                    multiArray[index] = { ...multiArray[index], [header]: e.target.value, minLength, maxLength }
                                                                    return multiArray
                                                                })
                                                            }}
                                                        >

                                                            <option value=''>Select</option>
                                                            {gradeMasterData.filter((item) =>
                                                                item.gradeName === row.gradeName &&
                                                                `${item.diameter}` === row.diameter &&
                                                                item.materialType === row.stockType

                                                            )
                                                                .map((value, index) => (
                                                                    <option key={index} value={value.material}>
                                                                        {value.material}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </td>
                                                </>
                                                : header === 'noOfPieces' ?
                                                    <td >
                                                        <input type="number" readOnly value={row[header]}
                                                            style={{ width: '80px', padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                        />

                                                    </td>
                                                    :
                                                    header === 'invoiceWeight' ?
                                                        <td >
                                                            <input type="number" value={row[header]}
                                                                style={{ width: '60px', padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                                min={0}
                                                                required
                                                                onChange={(e) => {
                                                                    setTempArray((prev) => {
                                                                        let multiArray = JSON.parse(JSON.stringify(prev))
                                                                        if (multiArray[index].material !== '' && multiArray[index].diameter !== '') {
                                                                            let length = 0;

                                                                            gradeMasterData.forEach(el => {
                                                                                if (el.material === multiArray[index].material) { length = (el.minLength + el.maxLength) / 2; }
                                                                            })
                                                                            let totalPiece = 0;
                                                                            if (`${length}` === '0') {
                                                                                totalPiece = 0;
                                                                            } else {
                                                                                totalPiece = Math.floor(e.target.value * 4000000000000 / (length * Math.PI * 7850 * multiArray[index].diameter * multiArray[index].diameter))
                                                                            }
                                                                            console.log('098w');
                                                                            multiArray[index] = { ...multiArray[index], noOfPieces: totalPiece }
                                                                        }
                                                                        multiArray[index] = { ...multiArray[index], [header]: e.target.value }
                                                                        return multiArray
                                                                    })

                                                                }}
                                                            />

                                                        </td> :
                                                        header === 'batchNo' ?
                                                            <td >
                                                                <input type="text" value={row[header]}
                                                                    style={{ width: '60px', padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                                    onChange={(e) => {
                                                                        setTempArray((prev) => {
                                                                            let multiArray = JSON.parse(JSON.stringify(prev))
                                                                            multiArray[index] = { ...multiArray[index], [header]: e.target.value }
                                                                            return multiArray
                                                                        })
                                                                    }}
                                                                />

                                                            </td> :
                                                            header === 'description' ?
                                                                <>
                                                                    <td>
                                                                        <textarea name="description" id="description" cols="20" rows="3" value={row[header]}
                                                                            style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                                            onChange={(e) => {
                                                                                setTempArray((prev) => {
                                                                                    let multiArray = JSON.parse(JSON.stringify(prev))
                                                                                    multiArray[index] = { ...multiArray[index], [header]: e.target.value }
                                                                                    return multiArray
                                                                                })
                                                                            }}
                                                                        ></textarea>
                                                                    </td>
                                                                </> :
                                                                header === 'minLength' || header === 'maxLength'?
                                                                <>
                                                                    <td>
                                                                    <input type="number" value={row[header]}
                                                                        required
                                                                        style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                                        min={0}
                                                                        max={12000}
                                                                        onChange={(e) => {
                                                                            setTempArray((prev) => {
                                                                                let multiArray = JSON.parse(JSON.stringify(prev))
                                                                                multiArray[index] = { ...multiArray[index], [header]: e.target.value }
                                                                                return multiArray
                                                                            })
                                                                        }}
                                                                    />
                                                                    </td>
                                                                </> :
                                                                <td >
                                                                    <input type="text" value={row[header]}
                                                                        required
                                                                        style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                                        
                                                                        onChange={(e) => {
                                                                            setTempArray((prev) => {
                                                                                let multiArray = JSON.parse(JSON.stringify(prev))
                                                                                multiArray[index] = { ...multiArray[index], [header]: e.target.value }
                                                                                return multiArray
                                                                            })
                                                                        }}
                                                                    />
                                                                    </td>)}

                                {(productName==='smartFab'&&(header=='stockType'||header=='material'||header=='diameter'))&&
                                
                                    header === 'stockType' ?
                                        <>
                                            <td>
                                                <select
                                                    id="materialType"
                                                    // required
                                                    value={row.productName}
                                                    required
                                                    style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}

                                                    onChange={(e) => {
                                                        setTempArray((prev) => {
                                                            let multiArray = JSON.parse(JSON.stringify(prev))
                                                            multiArray[index] = {
                                                                ...multiArray[index],
                                                                productName: e.target.value,
                                                                diameter: '',
                                                                material: '',
                                                                noOfPieces: ''
                                                            }
                                                            return multiArray
                                                        })
                                                    }}
                                                >

                                                    <option value=''>Select</option>
                                                    {[...new Set(gradeMasterData
                                                        // .filter((item) => item.gradeName === gradeDrop.gradeName && item.diameter * 1 === gradeDrop.diameter * 1)
                                                        .map(item => item.productName))].sort((a, b) => a - b).map((value, index) => (
                                                            <option key={index} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                </select>
                                            </td>
                                        </>
                                        :
                                        header === 'diameter' ?
                                            <>
                                                <td> {row.productName?.includes('MS')?
                                                    <input type="number"
                                                    className='table-input'
                                                    id="diameter"
                                                    required
                                                    min={2}
                                                    max={10}
                                                    step={0.01}
                                                    value={row.diameter}
                                                    style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                    onChange={(e) => {
                                                        setTempArray((prev) => {
                                                            let multiArray = JSON.parse(JSON.stringify(prev))
                                                            multiArray[index] = {
                                                                ...multiArray[index],
                                                                diameter: e.target.value,
                                                                material: '',
                                                                noOfPieces: ''
                                                            }
                                                            return multiArray
                                                        })
                                                    }}
                                                    />:
                                                    <select
                                                        id="diameter"
                                                        value={row.diameter}
                                                        required
                                                        style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                        onChange={(e) => {
                                                            setTempArray((prev) => {
                                                                let multiArray = JSON.parse(JSON.stringify(prev))
                                                                multiArray[index] = {
                                                                    ...multiArray[index],
                                                                    diameter: e.target.value,
                                                                    material: '',
                                                                    noOfPieces: ''
                                                                }
                                                                return multiArray
                                                            })
                                                        }}
                                                    >
                                                        <option value=''>Select</option>

                                                        {[...new Set(gradeMasterData.filter((item) => item.productName === row.productName ).map(item => item.diameter))].sort((a, b) => a - b).map((value, index) => (
                                                            <option key={index} value={value}>
                                                                {value}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    }
                                                </td>
                                            </> :
                                            header === 'material' ?
                                                <>
                                                    <td>
                                                        <select
                                                            id="material"
                                                            value={row.material}
                                                            required
                                                            style={{ padding: '10px', margin: '0', border: '2px solid #e5e5e5', borderRadius: '5px' }}
                                                            onChange={(e) => {
                                                                //         if(foundMaterial){
                                                                //         setNewRow({
                                                                //         ...newRow, 
                                                                //         minLength: foundMaterial.minLength,
                                                                //         maxLength: foundMaterial.maxLength
                                                                //         })
                                                                //         }
                                                                setTempArray((prev) => {
                                                                    let multiArray = JSON.parse(JSON.stringify(prev))
                                                                    // let minLength
                                                                    // let maxLength
                                                                    if (e.target.value !== '' && multiArray[index].diameter !== '' && multiArray[index].invoiceWeight) {
                                                                        let length = 0;
                                                                        
                                                                        gradeMasterData.forEach(el => {
                                                                            if (el.material === e.target.value) { 
                                                                                length = (el.minLength + el.maxLength) / 2; 
                                                                                // minLength = el.minLength;
                                                                                // maxLength = el.maxLength;
                                                                            }
                                                                        })
                                                                        let totalPiece = 0;
                                                                        if (`${length}` === '0') {
                                                                            totalPiece = ''
                                                                        } else {
                                                                            totalPiece = Math.floor(multiArray[index].invoiceWeight * 4000000000000 / (length * Math.PI * 7850 * multiArray[index].diameter * multiArray[index].diameter))
                                                                        }
                                                                        multiArray[index] = { ...multiArray[index], noOfPieces: totalPiece}
                                                                    } else {
                                                                        gradeMasterData.forEach(el => {
                                                                            if (el.material === e.target.value) { 
                                                                                // minLength = el.minLength;
                                                                                // maxLength = el.maxLength;
                                                                            }
                                                                        })
                                                                    }
                                                                    multiArray[index] = { ...multiArray[index], [header]: e.target.value,
                                                                        //  minLength, maxLength 
                                                                        }
                                                                    return multiArray
                                                                })
                                                            }}
                                                        >

                                                            <option value=''>Select</option>

                                                            {gradeMasterData.filter((item) =>

                                                                (row.productName?.includes('MS') || `${item.diameter}` === row.diameter) &&
                                                                item.productName === row.productName

                                                            )
                                                                .map((value, index) => (
                                                                    <option key={index} value={value.material}>
                                                                        {value.material}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </td>
                                                </>:''}</>
                            ))}

                            <td onClick={() => handleRemoveRow(index)}>
                                <svg width="31" height="31" fill="none" >
                                    <path
                                        d="M15 0C6.729 0 0 6.729 
                                        0 15s6.729 15 15 15 15-6.729 
                                        15-15S23.271 0 15 0Zm6.31 
                                        19.19-2.12 2.12L15 17.122l-4.19 
                                        4.19-2.12-2.122L12.879 15l-4.19-4.19 
                                        2.121-2.12L15 12.879l4.19-4.19 2.12 
                                        2.121L17.122 15l4.19 4.19Z"
                                        fill="#BB1010" />
                                </svg>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>



        </div>

    )
}

export default MultipleAddStock
