// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-dropdown {
    position: relative;
    width: 200px;
}

.selected-options {
    border: 1px solid #ccc;
    padding: 10px;
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
}

.selected-option {
    background-color: #e0e0e0;
    border-radius: 5px;
    padding: 5px;
    margin-right: 5px;
}

.options {
    position: absolute;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 1000;
    width: 100%;
    max-height: 150px;
    overflow-y: auto;
}

.option {
    padding: 10px;
    cursor: pointer;
}

.option.selected {
    background-color: #f0f0f0;
}

.option:hover {
    background-color: #f5f5f5;
}`, "",{"version":3,"sources":["webpack://./src/utils/multiSelect.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,sBAAsB;IACtB,aAAa;IACb,eAAe;IACf,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;IAClB,sBAAsB;IACtB,uBAAuB;IACvB,aAAa;IACb,WAAW;IACX,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":[".custom-dropdown {\r\n    position: relative;\r\n    width: 200px;\r\n}\r\n\r\n.selected-options {\r\n    border: 1px solid #ccc;\r\n    padding: 10px;\r\n    cursor: pointer;\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n}\r\n\r\n.selected-option {\r\n    background-color: #e0e0e0;\r\n    border-radius: 5px;\r\n    padding: 5px;\r\n    margin-right: 5px;\r\n}\r\n\r\n.options {\r\n    position: absolute;\r\n    border: 1px solid #ccc;\r\n    background-color: white;\r\n    z-index: 1000;\r\n    width: 100%;\r\n    max-height: 150px;\r\n    overflow-y: auto;\r\n}\r\n\r\n.option {\r\n    padding: 10px;\r\n    cursor: pointer;\r\n}\r\n\r\n.option.selected {\r\n    background-color: #f0f0f0;\r\n}\r\n\r\n.option:hover {\r\n    background-color: #f5f5f5;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
