import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchShapes } from '../../../redux-tookit/slices/shapeSlice';
import './ListOfShapes.css'

const ListOfShapes = () => {
  const dispatch = useDispatch();
  const shapeTypes = [

    { name: '0 Bend with Arc' },
    { name: '0 Bend without Arc' },
    { name: '1 Bend with Arc' },
    { name: '1 Bend without Arc' },
    { name: '2 Bend with Arc' },
    { name: '2 Bend without Arc' },
    { name: '3 Bend with Arc' },
    { name: '3 Bend without Arc' },
    { name: '4 Bend with Arc' },
    { name: '4 Bend without Arc' },
    { name: 'Multi Bend with Arc' },
    { name: 'Multi Bend without Arc' },
    { name: '3D' },
    { name: 'Coupler' },
    { name: 'Special' },
    { name: 'Favourite' }

  ];

  const shapes = useSelector(state => state.shape.shapes)

  console.log(shapes);
  useEffect(() => {
    dispatch(fetchShapes())
  }, [])

  return (
    <div className='ListOfShapes'>
      <div className="head">List of Shapes</div>
      <div className="ListShapeContainer">
        {shapeTypes.map((shapeType) => (
          <div className="TypeContainer">
            <div className="TypeHeader">
              {shapeType.name}
            </div>
            <div className="List-image-grid">
              {
                shapes.filter(item => item.category === shapeType.name.toLowerCase())
                  .map((data) =>
                    <ShapeCard shapeId={data.shapeId} shapeURL={data.image} />
                  )
              }
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}


const ShapeCard = (props) => {
  const { shapeId, shapeURL } = props;
  // const [shapeDisplay, setShapeDisply] = useState(false);

  const url = String.fromCharCode.apply(null, new Uint8Array(shapeURL.data));


  return (
    <div className={`shapeContainer `}>
      <div className="List-shapeId">{shapeId}</div>
      <div className="shapeImg">
        <img src={url} alt="shape1" />
      </div>
    </div>
  )
}

export default ListOfShapes