import React from 'react'
import './Loader.css'
const Loader = ({type}) => {
  return (<div>
    {type==='small'?<div className='circle-loader2'></div>:
    <div className='circle-loader'>
    </div>}
    {/* <div style={{display:'flex',justifyContent:'center', alignItems:'center',position:'fixed',top:'0',left:'0',width:'100vw',height:'100vh'}}>
          <div  style={{backgroundColor:'white', display:'flex', zIndex:'200',height:'400px', width:'400px'}}></div>

    </div> */}
    </div>
  )
}

export default Loader
