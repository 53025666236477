import { configureStore } from '@reduxjs/toolkit';
import sdbrSlice from './slices/sdbrSlice';
import dataReducer from './slices/customerSlice';
import processTypeSlice from './slices/processTypeSlice';
import equipmentCategorySlice from './slices/equipmentCategorySlice';
import equipmentMasterSlice from './slices/equipmentMasterSlice';
import organizationSlice from './slices/organizationSlice';
import productMasterSlice from './slices/productMasterSlice';
import gradeMasterSlice from './slices/gradeMasterSlice';
import partnerSlice from './slices/partnerSlice';
import serviceCenterSlice from './slices/serviceCenterSlice';
import facilityMasterSlice from './slices/facilityMasterSlice';
import serviceCenterEquipmentsSlice from './slices/serviceCenterEquipmentsSlice';
import diaWiseRouteSlice from './slices/diaWiseRouteSlice';
import inventorySlice from './slices/inventorySlice';
import shapeSlice from './slices/shapeSlice';
import processCenterSlice from './slices/processCenterSlice';
import loginReducer from './slices/loginSlice'
import customerPartiesSlice from './slices/customerPartiesSlice';
import orderSlice from './slices/orderSlice';
import orderItemsSlice from './slices/orderItemsSlice';
import partItemsSlice from './slices/partItemsSlice';
import sdbrDataSlice from './slices/sdbrDataSlice';
import planSlice from './slices/planSlice';
import favShapeSlice from './slices/favShapeSlice';
import openPlanSlice from './slices/openPlanSlice';
import cuttingSeqSlice from './slices/cuttingSeqSlice';
import productionRecordingSlice from './slices/productionRecordingSlice';
import customerSegmentSlice from './slices/customerSegmentSlice';
import orderLLRelationSlice from './slices/orderLLRelationSlice';
import orderStatusHistorySlice from './slices/orderStatusHistorySlice';
import orderWiseProgressSlice from './slices/orderWiseProgressSlice';
import orderDateComparisonSlice from './slices/orderDateComparisonSlice';
import barMarkWiseQtySlice from './slices/barMarkWiseQtySlice';
import graphlistinventorySlice from './slices/graphinventorySlice'
import tempPlanSummarySlice from './slices/tempPlanSummarySlice';
import loadingListSlice from './slices/loadingListSlice';
import tempProdRecordSlice from './slices/tempProdRecordSlice';
import navbarSlice from './slices/navbarSlice';
import presalesSlice from './slices/presalesSlice';
import dashboardSlice from './slices/dashboardSlice';
import productionRecordHistorySlice from './slices/productionRecordHistorySlice';
import productionRecordedSlice from './slices/productionRecordedSlice';
import dailyproductionquantitySlice from './slices/dailyproductionquantitySlice';
import diaWiseQuantitySlice from './slices/diaWiseQuantitySlice';
import userAccessSlice from './slices/userAccessSlice';
import userSlice from './slices/userSlice';
import passwordSlice from './slices/passwordSlice';
import  fetchCubeIssues  from './slices/userFeedBackSlice';
 
  

const store = configureStore({
	reducer: {
		fetchCubeIssues:fetchCubeIssues,
		sdbr: sdbrSlice,
		data: dataReducer,
		processType: processTypeSlice,
		equipmentCategory: equipmentCategorySlice,
		equipmentMaster: equipmentMasterSlice,
		organization: organizationSlice,
		partner: partnerSlice,
		productMaster: productMasterSlice,
		gradeMaster: gradeMasterSlice,
		serviceCenter: serviceCenterSlice,
		facilityMaster: facilityMasterSlice,
		serviceCenterEquipments: serviceCenterEquipmentsSlice,
		diaWiseRoutes: diaWiseRouteSlice,
		inventory: inventorySlice,
		shape: shapeSlice,
		processCenter: processCenterSlice,
		login: loginReducer,
		customerParties: customerPartiesSlice,
		order: orderSlice,
		orderItems: orderItemsSlice,
		partItems: partItemsSlice,
		sdbrData:sdbrDataSlice,
		plan:planSlice,
		favShape:favShapeSlice,
		openPlan:openPlanSlice,
		cutSeq:cuttingSeqSlice,
		productionRecording:productionRecordingSlice,
		customerSegment:customerSegmentSlice,
		barMarkWiseQty: barMarkWiseQtySlice,
		orderDateComparison: orderDateComparisonSlice,
		orderLLRelation: orderLLRelationSlice,
		orderStatusHistory: orderStatusHistorySlice,
		orderWiseProgress: orderWiseProgressSlice,
		graphinventory: graphlistinventorySlice,
		tempPlan: tempPlanSummarySlice,
		loadingList: loadingListSlice,
		tempProdRecord:tempProdRecordSlice,
		navbar: navbarSlice,
		presales: presalesSlice,
		dashboard: dashboardSlice,
		productionHistory:productionRecordHistorySlice,
		productionRecorded:productionRecordedSlice,
		dailyproductionqty:dailyproductionquantitySlice,
		diawiseQuantity:diaWiseQuantitySlice,
		userAccess:userAccessSlice,
		user:userSlice,
		password:passwordSlice
	}

});

export default store;

//End of File