import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchProductionParameters, postConfirmProductionSF } from '../../redux-tookit/slices/smartFab/productionParameterSlice';
import AGTable from '../../components/AGTable';
import { fetchOrderSF } from '../../redux-tookit/slices/orderSlice';
import AGTable2 from '../../components/AGTable2';
import Button from '../../utils/Button';
import { orderDateComparison } from '../../services/API';
import { toast } from 'react-toastify';

const SmartFabProdRecording = () => {

    const dispatch = useDispatch();

    const parameterLoading = useSelector(state => state.productionParameter.loading);
    const orderLoading = useSelector(state => state.order.loading);

    useEffect(() => {

        if (orderLoading !== 'pending') {
            uLoading(parameterLoading)
        }

    }, [orderLoading])

    useEffect(() => {

        if (parameterLoading !== 'pending') {
            uLoading(orderLoading)
        }

    }, [parameterLoading])


    const [loading, uLoading] = useState('pending')

    const orderColumns = useSelector(state => state.order.columns)
    const orderData = useSelector(state => state.order.labeledData)
    const [prodRecordFilter, setProdRecordFilter] = useState('');

    const parameterData = useSelector(state => state.productionParameter.labeledData)
        .filter(item => prodRecordFilter !== '' ? item.productionStatus * 1 === prodRecordFilter * 1 : true);
        console.log(parameterData);
        

    const parameterColumns = useSelector(state => state.productionParameter.columns)

    const [filteredParameters, uFilteredParameteres] = useState([])

    const [selectedOrder, uSelectedOrder] = useState(null)
    const [selectedParameters, uSelectedParameters] = useState(null)

    const getTodayDate = () => {
        return new Date();
    };


    const getOneMonthBackDate = () => {
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        return date;
    };


    const formatDate = (date) => {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    };



    const [fromDateFilter, setFromDateFilter] = useState(formatDate(getOneMonthBackDate()));
    const [toDateFilter, setToDateFilter] = useState(formatDate(getTodayDate()));



    const confirmProduction = () => {


        let ids = []
        let confirmedItem = false

        selectedParameters.forEach(index => {
            if(filteredParameters[index].productionStatus*1===0){
                confirmedItem =true
            } else
            ids = [...ids, filteredParameters[index].smartfabProductionRecordingId]
          })

        if(confirmedItem){
            toast.error('Selected rows contain already confirmed products')
            return
        }

        const type = {
            "smartfabProductionRecordingId": ids
        }

        console.log(filteredParameters);

        if (prodRecordFilter !== '0')
        {

         dispatch(postConfirmProductionSF( type )).then(res => {
                if (res.payload) {
                    dispatch(fetchOrderSF());
                    dispatch(fetchProductionParameters());
                    uFilteredParameteres([])
                    // prodRecordFilter !== '' ? `?status=${prodRecordFilter}` : ''
                }
            })   
        }
            

            else toast.error('Production already complete')

            uSelectedParameters(null)
    }

    const setSelctedOrder = (rowIndex) => {
        console.log("run", rowIndex);

        try {
            if (rowIndex !== null && rowIndex) {
                uFilteredParameteres(
                    parameterData.filter(item => item.orderId * 1 === orderData[rowIndex].orderId * 1)
                )
            } else{
                uFilteredParameteres([])
            }
            uSelectedOrder(rowIndex)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        dispatch(fetchOrderSF())
        dispatch(fetchProductionParameters());
        // `eDate=${toDateFilter}&sDate=${fromDateFilter}`
    }, [dispatch, toDateFilter, fromDateFilter, prodRecordFilter]);



    return (
        <div className='sdbr'>
            <div className="head">Production Recording</div>
            <div className="filter-card">
                <div className="filter-layout" style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start", padding: '10px' }}>
                    <div className="filter" style={{ flexDirection: 'column', gap: '5px', width: 'fit-content' }}>
                        <label htmlFor="planStatus">Production Status</label>
                        <select name="planStatus" id="planStatus" value={prodRecordFilter} style={{ width: 'fit-content' }} onChange={(e) => { setProdRecordFilter(e.target.value) }}>
                            <option value="">All</option>
                            <option value="0">Completed</option>
                            <option value="1">Incomplete</option>
                        </select>
                    </div>


                    <div className='DateCont'>
                        <label className='DateHead'>Order Delivery Date</label>
                        <div className="DateFilt" style={{ padding: '2px' }}>
                            <div className="filterD">
                                <label htmlFor="fromDateFilter">From</label>
                                <input
                                    id="fromDateFilter"
                                    type="date"
                                    value={fromDateFilter}
                                    onChange={(e) => setFromDateFilter(e.target.value)}
                                />
                            </div>

                            <div className="filterD">
                                <label htmlFor="toDateFilter">To</label>
                                <input
                                    id="toDateFilter"
                                    type="date"
                                    value={toDateFilter}
                                    onChange={(e) => setToDateFilter(e.target.value)}
                                />
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <div className="head">Orders</div>

            <AGTable2 data={orderData} columns={orderColumns} onSelectRow={setSelctedOrder} selectedRow={selectedOrder} />

            <div className="head">Parameters</div>
            <div
                className="table-container"
                style={{ maxHeight: "300px", overflowY: "hide" }}
            >
                {loading !== "pending" ? (
                    <AGTable data={filteredParameters} columns={parameterColumns} onSelectRow={uSelectedParameters} selectedRow={selectedParameters} rowSelection='multiple'/>

                ) : (
                    <div
                        style={{
                            height: "300px",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        <div
                            className="circle-loader3"
                            style={{ position: "relative", left: "0", top: "0" }}
                        ></div>
                    </div>
                )}
            </div>
            <div className="bottom-button-container">
                <Button label='Confirm Production' className='green' onClick={confirmProduction} disabled={selectedParameters} />
            </div>
        </div>
    )
}

export default SmartFabProdRecording
