import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi} from '../../redux-tookit/slices/sdbrSlice';
import TableComponent from '../../components/TableComponent';
import '../../assets/styles/Production.css';
import Button from '../../utils/Button';


const ProductionUploadingOpt = () => {
  const dispatch = useDispatch();
  const stLocTo= ["CAB1", "REPL", "R001", "F001"];
  const [stLocToCheck, uStLocToCheck] = useState("R001");
  const stLocFrom= ["CAB1", "REPL", "R001", "F001"];
  const [stLocFromCheck, uStLocFromCheck] = useState("CAB1");

  const columns = useSelector((state) => state.sdbr.ProductionUploadingOpt_RMUsed.columns);
  const data = useSelector((state) => state.sdbr.ProductionUploadingOpt_RMUsed.data);

  const FGcolumns = useSelector((state) => state.sdbr.ProductionUploadingOpt_FG.columns);
  const FGdata = useSelector((state) => state.sdbr.ProductionUploadingOpt_FG.data);

  const Remcolumns = useSelector((state) => state.sdbr.ProductionUploadingOpt_Remnant.columns);
  const Remdata = useSelector((state) => state.sdbr.ProductionUploadingOpt_Remnant.data);

  const [selectPlan, setSelectPlan] = useState("Select PLan");
 
  useEffect(() => {
   
    dispatch(fetchDataFromApi('ProductionUploadingOpt_RMUsed'));
  }, [dispatch]);
  useEffect(() => {
   
    dispatch(fetchDataFromApi('ProductionUploadingOpt_FG'));
  }, [dispatch]);
 
  useEffect(() => {
   
    dispatch(fetchDataFromApi('ProductionUploadingOpt_Remnant'));
  }, [dispatch]);


  return (
    <div className='sdbr'>
      
      <div className="select">
        <label htmlFor="plan" style={{ paddingRight: "10px" }}>Plan Id</label>
        <div>
        <select
          name="plan"
          id="PlanIDfilter"
          onChange={(e) => setSelectPlan(e.target.value)}
          value={selectPlan}
          className='plId'
        >
          <option value="Select PLan">Select Plan</option>
          <option value="PlanID-20160513011">PlanID-20160513011</option>
          
        </select>
        </div>
      </div>
      <div className='head'>RM Used</div>
      <TableComponent columns = {columns} data = {data}/>
      <div className='head'>FG</div>
      <TableComponent columns = {FGcolumns} data = {FGdata}/>
      <div className='head'>Remnant</div>
      <TableComponent columns = {Remcolumns} data = {Remdata}/>
      <div className="basic-shadow-box">
        <span className="scrapCheck">
          <label htmlFor='scrapDef' className='label2'>
            Storage location To:
          </label>
          {stLocTo.map((value, index) => (
            <span>
              <input
                type="radio"
                name="stLocTo"
                checked={stLocToCheck === value}
                onChange={() => uStLocToCheck(value)}
                id={`radio${index}`}
              />
              <label htmlFor={`radio${index}`} className="custom-radio-scrap">{value}</label>
            </span>
          ))}
        </span>
        
        <span className="scrapCheck">
          <label htmlFor='scrapDef' className='label2'>
            Storage location From:
          </label>
          {stLocFrom.map((value, index) => (
            <span>
              <input
                type="radio"
                name="scrapDef"
                value='All'
                checked={stLocFromCheck === value}
                onChange={() => uStLocFromCheck(value)}
                id={`radio${index}`}
              />
              <label htmlFor={`radio${index}`} className="custom-radio-scrap">{value}</label>
            </span>
          ))}
        </span>
      </div>
      <div className='bottom-button-container'>
          <Button className='blue' label='Validate' />
          <Button className='blue' label='Save' />
        </div>
      </div>
  )
}

export default ProductionUploadingOpt
//End of File