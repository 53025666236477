import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../utils/Button';
import React, { useEffect } from 'react'
import { fetchProducts } from '../../../redux-tookit/slices/productMasterSlice';
import { useState } from 'react';
import { postGrades, postGradesSF, updateGrade, updateGradeSF } from '../../../redux-tookit/slices/gradeMasterSlice';

const IsAddingGradeDetails = (props) => {
	const dispatch = useDispatch();
	const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData, productName } = props
	const columns = useSelector((state) => state.gradeMaster.columns);
	const [newRow, setNewRow] = useState({});
	const columnKeys = Object.keys(columns);
	const productMasterData = useSelector((state) => state.productMaster.labeledData);
	const barTypeData = productMasterData.map(item => item.barType)
	
	const handleInputChange = (header, e) => {
		const updatedData = JSON.parse(JSON.stringify(editData));
		updatedData[header] = e.target.value;
		setEditData(updatedData);
	}

	const handleUpdate = async (e) => {

		e.preventDefault();
		const index = productMasterData.find(item => item.productMasterId == editData.productMasterId);
		const id = editData.gradeId;
		console.log(editData);
		console.log(productMasterData)
		console.log('apple', index);
		let type = {
			name: index.name,
			materialType: editData.materialType,
			gradeName: editData.gradeName,
			diameter: editData.diameter,
			density: editData.density,
			minLength: editData.minLength,
			maxLength: editData.maxLength,
			RM_FG_Remnant: editData.RM_FG_Remnant,
			
			
		};

		if(productName === 'smartFab') {
			type = {...type, smartFabProductTypeId: editData.smartFabProductTypeId, barType: 'T'}
			dispatch(updateGradeSF({ id, type }))
		} else {
			type = {...type, productMasterId: editData.productMasterId, barType: editData.barType,}
			dispatch(updateGrade({ id, type }))
		}

		//productMasterId: editData.productMasterId

		console.log(id);
		console.log(type);
		//console.log(editData);

		
		setIsEditing(false);
	}

	const handleSaveButtonClick = async (e) => {

		e.preventDefault();
		let index = productMasterData.find(item => item.productMasterId === newRow.productMasterId);

		let newRow1 = {
			smartFabProductType: index.smartFabProductType,
			...newRow
		};

		if (productName === 'smartFab') {
			index = productMasterData.find(item => item.smartFabProductTypeId === newRow.smartFabProductTypeId);
			newRow1 = {
				smartFabProductType: index.smartFabProductType,
				barType:'T',
				...newRow
			};
			dispatch(postGradesSF({ ...newRow1 }));
		} else {
			index = productMasterData.find(item => item.productMasterId === newRow.productMasterId);
			newRow1 = {
				name: index.name,
				...newRow
			};
			dispatch(postGrades({ ...newRow1 }));
		}

		console.log(newRow1);
		setIsAdding(false);
		setNewRow({});

	};

	console.log(productMasterData);

	return (
		<div>
			<form onSubmit={handleSaveButtonClick}>{isAdding &&
				<div>
					<div className="AddContainer" >
						<div className="form-container"><div className="head">Add Grade</div>
							<div className="table-container">
								{columnKeys.map((column) => (
									column !== 'gradeId' && column !== 'material' && column !== 'productName' ?
										(column === 'productMasterId'?
											(<div className="filter">
												<label>Product {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
												<select
													id="productId"
													onChange={(e) => { setNewRow({ ...newRow, [column]: e.target.value * 1 }); }
													}
												>
													<option value="">-Select-</option>
													{productMasterData.map((value, index) => (
														<option key={index} value={value.productMasterId}>
															{value.name}
														</option>
													))}
												</select></div>) :
												column === 'smartFabProductTypeId'?
												(<div className="filter">
												<label>Product {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
												<select
													id="smartFabProductTypeId"
													onChange={(e) => { setNewRow({ ...newRow, [column]: e.target.value * 1 }); }
													}
												>
													<option value="">-Select-</option>
													{productMasterData.map((value, index) => (
														<option key={index} value={value.smartFabProductTypeId}>
															{value.smartFabProductType}
														</option>
													))}
												</select></div>) :
											column === 'materialType' ?
												(<div className="filter">
													<label>Material Type {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
													<select
														id="materialType"
														onChange={(e) => { setNewRow({ ...newRow, [column]: e.target.value }); }
														}
													>
														<option value="">-Select-</option>
														<option value="COIL">COIL</option>
														<option value="STRAIGHT LENGTH">STRAIGHT LENGTH</option>
														<option value="FINISHED GOODS">FINISHED GOODS</option>
														<option value="SHORT LENGTH">SHORT LENGTH</option>
														<option value="OFFCUT">OFFCUT</option>
														<option value="CUSTOMIZED LENGTH">CUSTOMIZED LENGTH</option>
														{/* {productMasterData.map((value, index) => (
															<option key={index} value={value.productMasterId}>
																{value.name}
															</option>
														))} */}
													</select></div>) :
												column === 'barType'  ? productName !== 'smartFab'?
													(<div className="filter">
														<label>Bar Type {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
														<select
															id="barType"
															onChange={(e) => { setNewRow({ ...newRow, [column]: e.target.value }); }
															}
														>

															<option value="">-Select-</option>
															{barTypeData.map(data => (
																<option value={data}>{data}</option>
															))}


														</select></div>):'' :

													(<div className="filter">
														<label>{columns[column].label}{columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
														<input
															key={column}
															required
															type={columns[column].type}
															className='table-input'
															value={newRow[column] || ''}
															onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}

														/></div>
													)) :
										""
								))}

							</div>

							<div className="bottom-button-container">
								<Button type='submit' label='Save' className='blue' />
								<Button onClick={() => { setIsAdding(false); setNewRow({}) }} label='Cancel' className='blue' />
							</div>
						</div>
					</div>
					<div className="bg-dark"></div>
				</div>}
			</form>
			<form onSubmit={handleUpdate}>{isEditing &&
				<div>
					<div className="AddContainer" >
						<div className="form-container"><div className="head">Edit Grade</div>
							<div className="table-container">
								{columnKeys.map((column) => (
									column !== 'gradeId' && column !== 'material' && column !== 'productName' ?
										(column === 'productMasterId' ?
											(<div className="filter">
												<label>Product {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
												<select
													id="productId"
													value={editData[column]}
													required
													onChange={(e) => {
														handleInputChange(column, e);
													}}
												>

													{productMasterData.map((value, index) => (
														<option key={index} value={value.productMasterId}>
															{value.name}
														</option>
													))}
												</select></div>) :
											column === 'barType' ?
												(<div className="filter">
													<label>Bar Type {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
													<select
														id="barType"
														onChange={(e) => { setNewRow({ ...newRow, [column]: e.target.value }); }}
														value={editData[column]}
													>
														<option value="">-Select-</option>
														<option value="T">T</option>

													</select></div>) :


												(<div className="filter">
													<label>{columns[column].label}{columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}</label>
													<input
														key={column}
														value={editData[column]}
														type={columns[column].type}
														className='table-input'
														onChange={(e) => handleInputChange(column, e)}
													/></div>
												)) :
										""
								))}

							</div>

							<div className="bottom-button-container">
								<Button type='submit' label='Save' className='blue' />
								<Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
							</div>
						</div>
					</div>
					<div className="bg-dark"></div>
				</div>}
			</form>
		</div>
	)
}

export default IsAddingGradeDetails
