import React, { useState, useEffect } from "react";
import * as api from "../../../services/API";

import "../Dashboard/Dashboard.css";
import { Bar } from "react-chartjs-2";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
function Inventory() {
    const [productionServiceName, setProductionServiceName] = useState("All");
    const [stockName, setStockName] = useState("All");
    const [listGrades, setListGrades] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [serviceCenterListData, setServiceCenterListData] = useState([]);
    const [listStockType, setListStockType] = useState([]);
    const [productionGrade, setProductionGrade] = useState("All");
    const [regionWiseSeviceCenterData, setRegionWiseSeviceCenterData] = useState(
        []
    );
    const roleName = localStorage.getItem("roleName");
    const [proDuctionHistoryDataForGraph, setProDuctionHistoryDataForGraph] =
        useState([]);
    const [regionForCustomer, setRegionForCustomer] = useState("All");
    const regions = ["North", "South", "East", "West"];
    const regionName = localStorage.getItem("region");
    const getServiceCenterList = async () => {
        await api
            .listServiceCenter()
            .then((result) => {
                setServiceCenterListData(result?.data?.rows);
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const getRegions = async () => {
        let queryString = "";
        if (regionForCustomer !== "All") {
            queryString += `?region=${regionForCustomer}`;
        }
        await api
            .listServiceCenterByRegion(queryString)
            .then((result) => {
                if (result?.data?.rows.length > 0) {
                    setRegionWiseSeviceCenterData(result?.data?.rows);
                } else {
                    setRegionWiseSeviceCenterData([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        getRegions();
    }, [regionForCustomer]);
    useEffect(() => {
        getServiceCenterList();
    }, []);
    useEffect(() => {
        if (
            regionForCustomer !== "All" &&
            productionServiceName === "All" &&
            productionGrade === "All" &&
            stockName === "All"
        ) {
            setStockName("All");
            getTableData();
            getProductionHistoryByServiceId();
        }
    }, [regionForCustomer, productionServiceName, stockName, productionGrade]);
    useEffect(() => {
        if (
            regionForCustomer === "All" &&
            productionServiceName === "All" &&
            productionGrade === "All" &&
            stockName === "All"
        ) {
            setProductionGrade("All");
            getTableData();
            getProductionHistoryByServiceId();
        }
    }, [regionForCustomer, productionServiceName, stockName, productionGrade]);

    useEffect(() => {
        setStockName("All");
        setProductionGrade("All");
    }, [productionServiceName]);
    useEffect(() => {
        setStockName("All");
    }, [productionGrade]);
    useEffect(() => { }, [productionGrade]);

    const getTableData = async (serviceCenterId, gradeId, stockName) => {
        let queryString = "";
        if (regionForCustomer !== "All") {
            queryString += `&region=${regionForCustomer}&`;
        }
        if (serviceCenterId) {
            queryString += `${regionForCustomer === "All"
                ? `&serviceCenterId=${serviceCenterId}&`
                : `serviceCenterId=${serviceCenterId}&`
                }`;
        }
        if (
            roleName !== "admin" &&
            productionGrade === "All" &&
            productionServiceName === "All" &&
            regionForCustomer === "All" &&
            stockName
        ) {
            if (serviceCenterId) {
                queryString += `stockType=${stockName}&`;
            } else {
                queryString += `&stockType=${stockName}&`;
            }
        }

        if (gradeId && roleName !== "admin") {
            if (regionForCustomer === "All" && productionServiceName === "All") {
                queryString += `&gradeName=${gradeId}&`;
            } else if (
                regionForCustomer === "All" &&
                productionServiceName !== "All"
            ) {
                queryString += `gradeName=${gradeId}&`;
            } else if (
                regionForCustomer !== "All" &&
                productionServiceName === "All"
            ) {
                queryString += `gradeName=${gradeId}&`;
            } else if (
                regionForCustomer !== "All" &&
                productionServiceName !== "All"
            ) {
                queryString += `gradeName=${gradeId}&`;
            }
        }
        if (
            regionForCustomer === "All" &&
            productionServiceName !== "All" &&
            productionGrade !== "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (
            regionForCustomer !== "All" &&
            productionServiceName !== "All" &&
            productionGrade !== "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (
            regionForCustomer !== "All" &&
            productionServiceName !== "All" &&
            productionGrade === "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (
            regionForCustomer !== "All" &&
            productionServiceName === "All" &&
            productionGrade === "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (
            regionForCustomer === "All" &&
            productionServiceName !== "All" &&
            productionGrade === "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (roleName === "admin") {
            if (productionGrade !== "All" && productionGrade) {
                queryString += `&gradeName=${productionGrade}&`;
            }
        }

        if (roleName === "admin") {
            if (productionGrade === "All" && stockName !== "All" && stockName) {
                queryString += `&stockType=${stockName}&`;
            }
        }
        if (
            regionForCustomer === "All" &&
            productionServiceName === "All" &&
            productionGrade !== "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }

        await api
            .listDashboardInventary(`?type=table${queryString}`)
            .then((result) => {
                const resp = result?.data?.rows;
                if (result?.data?.rows.length > 0) {
                    setTableData(resp);
                } else {
                    setTableData([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getProductionHistoryByServiceId = async (
        serviceCenterId,
        gradeId,
        stockName
    ) => {
        let queryString = "";
        if (regionForCustomer !== "All") {
            queryString += `?region=${regionForCustomer}&`;
        }
        if (serviceCenterId) {
            queryString += `${regionForCustomer === "All"
                ? `?serviceCenterId=${serviceCenterId}&`
                : `serviceCenterId=${serviceCenterId}&`
                }`;
        }

        if (
            roleName !== "admin" &&
            productionGrade === "All" &&
            productionServiceName === "All" &&
            regionForCustomer === "All" &&
            stockName
        ) {
            if (serviceCenterId) {
                queryString += `stockType=${stockName}&`;
            } else {
                queryString += `?stockType=${stockName}&`;
            }
        }

        if (gradeId && roleName !== "admin") {
            if (regionForCustomer === "All" && productionServiceName === "All") {
                queryString += `?gradeName=${gradeId}&`;
            } else if (
                regionForCustomer === "All" &&
                productionServiceName !== "All"
            ) {
                queryString += `gradeName=${gradeId}&`;
            } else if (
                regionForCustomer !== "All" &&
                productionServiceName === "All"
            ) {
                queryString += `gradeName=${gradeId}&`;
            } else if (
                regionForCustomer !== "All" &&
                productionServiceName !== "All"
            ) {
                queryString += `gradeName=${gradeId}&`;
            }
        }
        if (
            regionForCustomer === "All" &&
            productionServiceName !== "All" &&
            productionGrade !== "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (
            regionForCustomer !== "All" &&
            productionServiceName !== "All" &&
            productionGrade !== "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (
            regionForCustomer !== "All" &&
            productionServiceName !== "All" &&
            productionGrade === "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (
            regionForCustomer !== "All" &&
            productionServiceName === "All" &&
            productionGrade === "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (
            regionForCustomer === "All" &&
            productionServiceName !== "All" &&
            productionGrade === "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }
        if (roleName === "admin") {
            if (productionGrade !== "All" && productionGrade) {
                queryString += `?gradeName=${productionGrade}&`;
            }
        }

        if (roleName === "admin") {
            if (productionGrade === "All" && stockName !== "All" && stockName) {
                queryString += `?stockType=${stockName}&`;
            }
        }
        if (
            regionForCustomer === "All" &&
            productionServiceName === "All" &&
            productionGrade !== "All" &&
            stockName !== "All" &&
            stockName
        ) {
            queryString += `stockType=${stockName}&`;
        }

        await api
            .listDashboardInventary(queryString)
            .then((result) => {
                if (result?.data?.rows.length > 0) {
                    setProDuctionHistoryDataForGraph(result?.data?.rows);
                } else {
                    setProDuctionHistoryDataForGraph([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        getGradeId();
    }, []);

    const labels = proDuctionHistoryDataForGraph.map((item) => item.diameter);
    const weights = proDuctionHistoryDataForGraph.map((item) => item.weight);
    console.log(weights, "weights");

    useEffect(() => {
        getStockType();
    }, []);
    const chartData = {
        labels: labels,
        datasets: [
            {
                label: "Weight",
                backgroundColor: "rgba(75, 192, 192, 0.2)",
                borderColor: "rgba(75, 192, 192, 1)",
                pointBorderColor: "red",
                borderWidth: 2,
                pointBackgroundColor: "red",
                barThickness: 20,
                data: weights,
            },
        ],
    };
    const chartOptions = {
        scales: {
            y: {
                grid: { display: true },
                title: {
                    display: true,
                    text: "Weight (T)",
                    color: "grey",
                    font: { size: 16, weight: "bold" },
                },
                ticks: {
                    color: "black",
                    beginAtZero: true,
                },
            },
            x: {
                grid: { display: true },
                title: {
                    display: true,
                    text: "Diameter (mm)",
                    color: "grey",
                    font: { size: 14, weight: "bold" },
                },
                ticks: {
                    color: "black",
                    beginAtZero: true,
                },
            },
        },

        plugins: {
            legend: {
                display: false,
                position: "top",
            },

            tooltip: {
                enabled: false,
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || "";
                        if (label) {
                            label += ": ";
                        }
                        if (context.parsed.y !== null) {
                            label += `${context.parsed.y.toFixed(2)} T`;
                        }
                        return label;
                    },
                },
            },
            datalabels: {
                display: true,
                align: "top",
                anchor: "end",
                color: "black",
                font: {
                    weight: "bold",
                },
                formatter: function (value, context) {
                    return value;
                },
            },
            title: {
                display: true,
            },
        },
    };

    const getGradeId = async () => {
        await api
            .listGrades()
            .then((result) => {
                if (result?.data?.rows.length > 0) {
                    setListGrades(result?.data?.rows);
                } else {
                    setListGrades([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const getStockType = async () => {
        await api
            .listStockType()
            .then((result) => {
                if (result?.data.rows.length > 0) {
                    setListStockType(result?.data?.rows);
                } else {
                    setListStockType([]);
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const columnDefs = [
        {
            headerName: "SL.No",
            minWidth: 100,
            maxWidth: 100,
            valueGetter: "node.rowIndex + 1",
            cellClass: "sln-cell",
        },
        {
            headerName: "Stock Type",
            field: "stockType",
            sortable: true,
            filter: true,
            width: 200,
        },
        {
            headerName: "Grade Name",
            field: "gradeName",
            sortable: true,
            filter: true,
            width: 200,
        },
        {
            headerName: "Diameter (mm)",
            field: "diameter",
            sortable: true,
            filter: true,
            width: 200,
        },
        {
            headerName: "Weight (T)",
            field: "weight",
            sortable: true,
            filter: true,
            width: 200,
        },
        {
            headerName: "Total Pieces",
            field: "totalPieces",
            sortable: true,
            filter: true,
            width: 200,
        },
    ];
    const serviceData =
        regionWiseSeviceCenterData &&
        regionWiseSeviceCenterData.length > 0 &&
        regionWiseSeviceCenterData.filter(
            (item) => item.name === productionServiceName
        );
    const grade =
        listGrades &&
        listGrades.length > 0 &&
        listGrades.filter((item) => item.gradeName === productionGrade);

    const stockType =
        listStockType &&
        listStockType.length > 0 &&
        listStockType.filter((item) => item.stockType === stockName);

    const handleRegions = (e) => {
        setRegionForCustomer(e.target.value);
        setStockName("All");
        setProductionGrade("All");
        setProductionServiceName("All");
    };
    const serviceDataForAllRegionsByName = serviceCenterListData.filter(
        (item) => item.name === productionServiceName
    );
    const handleProductionServiceChange = (e) => {
        const serviceData =
            regionWiseSeviceCenterData &&
            regionWiseSeviceCenterData.length > 0 &&
            regionWiseSeviceCenterData.filter((item) => item.name === e.target.value);

        getProductionHistoryByServiceId(
            serviceData[0]?.serviceCenterId ||
            serviceDataForAllRegionsByName[0]?.serviceCenterId
        );
        getTableData(
            serviceData[0]?.serviceCenterId ||
            serviceDataForAllRegionsByName[0]?.serviceCenterId
        );
        setProductionServiceName(e.target.value);
    };
    const handleStockTypeChange = (e) => {
        getProductionHistoryByServiceId(
            serviceData[0]?.serviceCenterId,
            grade[0]?.gradeName,
            e.target.value
        );
        getTableData(
            serviceData[0]?.serviceCenterId,
            grade[0]?.gradeName,
            e.target.value
        );
        setStockName(e.target.value);
    };

    useEffect(() => {
        if (roleName === "admin" && stockName === "All") {
            getTableData(
                serviceData[0]?.serviceCenterId,
                grade[0]?.gradeName,
                stockName
            );
            getProductionHistoryByServiceId(
                serviceData[0]?.serviceCenterId,
                grade[0]?.gradeName,
                stockName
            );
        }
    }, [productionGrade]);

    const handleGradeChange = (e) => {
        setStockName("All");
        setProductionGrade("All");
        const grade =
            listGrades &&
            listGrades.length > 0 &&
            listGrades.filter((item) => item.gradeName === e.target.value);

        if (grade.length > 0) {
            getProductionHistoryByServiceId(
                serviceData[0]?.serviceCenterId,
                grade[0]?.gradeName
            );
            getTableData(serviceData[0]?.serviceCenterId, grade[0]?.gradeName);
            setProductionGrade(e.target.value);
            setStockName("All");
        }
        // setProductionGrade(e.target.value);
    };
    
    return (
        <div className="sdbr">
            <div className="head">Inventory</div>
            <div
                className="filter-card"
                style={{ margin: "20px 10px", padding: "20px 20px" }}
            >
                <div className="filter">
                    <div style={{ display:'flex',alignItems: "center", gap: "20px", justifyContent:'space-between'  }}>
                        {roleName === "superadmin" ? (
                            <div className="flex-col">
                                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                                    Regions
                                </label>
                                <select
                                    value={regionForCustomer}
                                    name="grade"
                                    id="dia-total-gradeFil"
                                    style={{ width: "fit-content" }}
                                    onChange={handleRegions}
                                >
                                    <option value="All">All</option>
                                    {regions.map((region, index) => (
                                        <option key={index} value={region}>
                                            {region}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        ) : (
                            roleName === "regionalManager" && (
                                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                                    Region: <></>
                                    {regionName.charAt(0).toUpperCase() + regionName.slice(1)}
                                </label>
                            )
                        )}
                        {roleName !== "admin" && (
                            <div className="flex-col">
                                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                                    Service Center
                                </label>
                                <select
                                    value={productionServiceName}
                                    name="grade"
                                    id="dia-total-gradeFil"
                                    style={{ width: "fit-content", minWidth:'150px' }}
                                    onChange={handleProductionServiceChange}
                                    
                                >
                                    <option value="All">All </option>
                                    {[
                                        ...new Set(
                                            regionForCustomer === "All"
                                                ? serviceCenterListData.map((item) => item.name)
                                                : regionWiseSeviceCenterData.map((item) => item.name)
                                        ),
                                    ].map((data) => (
                                        <option value={data}>{data}</option>
                                    ))}
                                </select>
                            </div>
                        )}
                        <div className="flex-col">
                            <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                                Grade
                            </label>
                            <select
                                value={productionGrade}
                                name="grade"
                                id="dia-total-gradeFil"
                                style={{ width: "fit-content" }}
                                onChange={handleGradeChange}
                            >
                                <option value="All">All</option>
                                {[...new Set(listGrades.map((item) => item.gradeName))]
                                    .sort()
                                    .map((data, index) => (
                                        <option value={data} key={index}>
                                            {data}
                                        </option>
                                    ))}
                            </select>
                        </div>

                        <div className="flex-col">
                            <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                                Stock Type
                            </label>
                            <select
                                value={stockName}
                                name="grade"
                                id="dia-total-gradeFil"
                                style={{ width: "fit-content" }}
                                onChange={handleStockTypeChange}
                            >
                                <option value="All">All</option>
                                {[...new Set(listStockType.map((item) => item.stockType))]
                                    .sort()
                                    .map((data) => (
                                        <option value={data}>{data}</option>
                                    ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="chart-box">
                {<center>{weights.length === 0 && "No Data Found"}</center>}
                <Bar
                    data={chartData}
                    options={chartOptions}
                    height={100}
                    margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                />
            </div>
            <div
                className="ag-theme-alpine"
                style={{ height: 490, width: "100%", marginTop: "50px" }}
            >
                <AgGridReact
                    rowData={tableData}
                    columnDefs={columnDefs}
                // gridOptions={gridOptions}
                />
            </div>
        </div>
    );
}

export default Inventory;
