import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


function Button(props) {
  const navigate = useNavigate();
  const { label, onClick, className, src = "", link = '', type = 'button', disabled = "", message = "No rows selected, Please select a row and try again" } = props;
  // let [a,disabled] = className.split(' ');
  const disabledFunctionHandle = () => {
    toast.error(message)
  }

  // const red = '#D60000', green = '#1F8A30', blue = '#138EE0';

  // const handleButtonColorChange = () => {

  // }

  // const [buttonColor, setButtonColor] = useState(className)

  const handleNavigation = () => {
    navigate(`/${link}`)
  }

  return (
    <div className='custom-button' style={{ height: 'fit-content', width: 'fit-content' }}>

      {link === '' ?
        <button
          className={`${className} ${disabled === null || disabled === undefined || (disabled.constructor === Array && disabled.length === 0) ? 'disabled' : ''}`}
          onClick={disabled === null || disabled === undefined || (disabled.constructor === Array && disabled.length === 0) ? disabledFunctionHandle : onClick} type={type} 
      
          >

          <div>
            {label}
            {src !== "" ? <img src={src} alt='' /> : ""}
          </div>
        </button> :
        <Link to={`/${link}`} >
          <button
            className={`${className}  ${disabled === null || disabled === undefined || (disabled.constructor === Array && disabled.length === 0) ? 'disabled' : ''}`}
            onClick={disabled === null || disabled === undefined || (disabled.constructor === Array && disabled.length === 0) ? disabledFunctionHandle : handleNavigation} type={type}>
            <div>
              {label}
              {src !== "" ? <img src={src} alt='' /> : ""}
            </div>
          </button>
        </Link>
      }
    </div>
  );
}

export default Button;
