import React, { useEffect, useState, useRef, useCallback } from "react";
import Collapsible from "react-collapsible";
import "./navbar.css";
import { useLocation, useNavigate } from "react-router-dom";
import Master from "./../assets/images/icons/navbar/Master.svg";
import SDBR from "./../assets/images/icons/navbar/SDBR.svg";
import Transaction from "./../assets/images/icons/navbar/Transaction.svg";
import Reports from "./../assets/images/icons/navbar/Reports.svg";
import Help from "./../assets/images/icons/navbar/Help.svg";
import { ReactComponent as Dropdown } from "./../assets/images/icons/navbar/dropdown.svg";
import { ReactComponent as OrgLogo } from "./../assets/images/icons/navbar/logo.svg";
import { ReactComponent as DropdownB } from "./../assets/images/icons/navbar/dropdownBlue.svg";
import MasterB from "./../assets/images/icons/navbar/MasterBlue.svg";
import SDBRB from "./../assets/images/icons/navbar/SDBRBlue.svg";
import TransactionB from "./../assets/images/icons/navbar/TransactionBlue.svg";
import ReportsB from "./../assets/images/icons/navbar/ReportsBlue.svg";
import HelpB from "./../assets/images/icons/navbar/HelpBlue.svg";
import { useDispatch, useSelector } from "react-redux";
import MenuCollapse from "./../assets/images/icons/action/menu.svg";
import Settings from "../assets/images/icons/navbar/Settings.svg";
import SettingsB from "../assets/images/icons/navbar/SettingsBlue.svg";
import { toast } from "react-toastify";

const Navbar = (props) => {
  const { navbarData } = props;

  const location = useLocation();
  const menuRef = useRef(null);
  // const scrollToRef = useRef();

  // const navbarData = 
  //  useSelector((state) => state.userAccess.navbarNames);

  const [expandedOption, setExpandedOption] = useState(null);
  const [selectedSubOption, setSelectedSubOption] = useState(null);

  const [NavVisible, setNavVisible] = useState("");
  // const navbarData = useSelector(state => state.userAccess.navbarNames)
  const loading = useSelector((state) => state.userAccess.loading);

  // const passwordStatus = sessionStorage.getItem('passwordStatus');

  const handleOptionClick = (option) => {
    // if(passwordStatus!=='1')
    if (expandedOption === option) {
      setExpandedOption(null);
      sessionStorage.setItem("navExpandedOption", null);
    } else {
      setExpandedOption(option);
      // sessionStorage.setItem('navExpandedOption', option)
    }
  };

  console.log("nav bar");

  // const handleSubOptionClick = (option) => {
  //   setSelectedSubOption(option);
  //   sessionStorage.setItem("navSelectedOption", option);
  //   sessionStorage.setItem("navExpandedOption", expandedOption);
  // };

  useEffect(() => {
    const navExpOpt = sessionStorage.getItem("navExpandedOption");
    const navSelOpt = sessionStorage.getItem("navSelectedOption");

    if (navExpOpt) {
      setExpandedOption(navExpOpt);
      // console.log(navExpOpt);
    }

    if (navSelOpt) {
      setSelectedSubOption(navSelOpt);
      // console.log(navSelOpt);
    }
    // console.log('insideEffect');
    console.log('useEffect 1');
    // scrollToRef?.current?.scrollIntoView()
  }, [location]);


  const displayChange = () => {
    if (NavVisible === "") {
      setNavVisible("block");
    } else setNavVisible("");
  };


 
  

  useEffect(() => {
    console.log('useEffect 2');
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setExpandedOption(null);
        setNavVisible("");
      }
    };

    if (window.innerWidth < 768) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    const handleResize = () => {
      if (window.innerWidth < 768) {
        document.addEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("mousedown", handleClickOutside);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="menu" ref={menuRef}>
      <div
        className="menu-collapse-logo"
        onClick={() => {
          displayChange();
        }}
      >
        <img src={MenuCollapse} alt="MenuCollapse" />
      </div>
      <div className="logo-icon">
        <OrgLogo />
      </div>

      {loading === "pending" ? (
        <div className="circle-loader2"></div>
      ) : (
        <>
          <div className={`menu-container ${NavVisible}`}>
            {Object.keys(navbarData).map((header) =>
              header !== "SDBR" ? (
                <CustomMenuItem
                  key={header}
                  label={header}
                  subOptions={navbarData[header]}
                  selectedSubOption={selectedSubOption}
                  setSelectedSubOption={setSelectedSubOption}
                  expandedOption={expandedOption}
                  handleOptionClick={handleOptionClick}
                  displayChange={displayChange}
                />
              ) : (
                ""
              )
            )}

            {/* 
        <CustomMenuItem label="Master" subOptions={
          [
            'Organization',
            'Partner',
            'Service Center',
            'Process Center',
            'Facility Master',
            'Process Type',
            'Equipment Category',
            'Equipment Master',
            'Service Center Equipments',
            'Product Master',
            'Grade Details',
            'Shapes',
            'Customer Details',
            'Customer Parties',
            'Presales Master'
          ]
        } />

        <CustomMenuItem label="SDBR" subOptions={
          [
            'Plant Capacity',
            // 'TPM Rate', 
            // 'Dia Wise Route', 
            // 'Diff Fact Shape', 
            // 'Diff Fact Formulae'
          ]
        } />
        <CustomMenuItem label="Transaction" subOptions={[
          'Stock Details',
          'Order Details',
          'Presales',
          'SDBR',
          'New Plan',
          'Open Plan',
          'Production Recording',
          'Loading Details'
        ]} />

        <CustomMenuItem label="Reports" subOptions={
          [
            "Dashboard",
            'Plan Details',
            'Presales Details',
            'Production History',
            'Production Recorded',
            'Route Load',
            'Plant Load',
            'Order Progress',
            'Dia Wise Balance Order',
            'Order Features',
            'Length Wise Product Quantity',
            'Bar Mark Wise Qty',
            'Order Date Comparision',
            'SAP Document',
            'Order LL Relation',
            'Order Status Hist',
            'Order Wise Progress',
            'Auto Dispatch Order',
            'Yield Monitoring',
          ]
        } /> */}
            <CustomMenuItem
              label="Help"
              subOptions={{
                "FeedBack": "",
                "List Of Shapes": "",
                // 'Access Settings':'',
                // 'Update Password':'',
                // 'User Master':'',
                // 'Suggestive Pricing Module':'',
                // "BBS Entry": "",
                // "Yield Report": "",
                // "Order Mgmt Dashboard in CuBe": "",
                // "User Manual": "",

                // "Data Converter": "",
                // "Spring Upload File": ""
                // 'Issue List':''
              }}
              selectedSubOption={selectedSubOption}
              setSelectedSubOption={setSelectedSubOption}
              expandedOption={expandedOption}
              handleOptionClick={handleOptionClick}
              displayChange={displayChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

const CustomMenuItem = ({ label, subOptions, selectedSubOption, setSelectedSubOption, expandedOption, handleOptionClick, displayChange }) => {

  const navigate = useNavigate();

  const handleSubOptionClick = (option) => {
    setSelectedSubOption(option);
    sessionStorage.setItem("navSelectedOption", option);
    sessionStorage.setItem("navExpandedOption", expandedOption);
  };


  return (
    <Collapsible
      trigger={
        <div
          className={`menu-item ${expandedOption === label ? "active" : ""}`}
          onClick={() => handleOptionClick(label)}
        >
          <div className="menu-header-container">
            {expandedOption !== label ? (
              <img
                className="menu-icon"
                src={
                  label === "Master"
                    ? Master
                    : label === "SDBR"
                      ? SDBR
                      : label === "Transaction"
                        ? Transaction
                        : label === "Reports"
                          ? Reports
                          : label === "Settings"
                            ? Settings
                            : Help
                }
                alt=""
              />
            ) : (
              <img
                className="menu-icon"
                src={
                  label === "Master"
                    ? MasterB
                    : label === "SDBR"
                      ? SDBRB
                      : label === "Transaction"
                        ? TransactionB
                        : label === "Reports"
                          ? ReportsB
                          : label === "Settings"
                            ? SettingsB
                            : HelpB
                }
                alt=""
              />
            )}
            <span className="menu-label">{label}</span>{" "}
            {/* Wrap the label in a span */}
          </div>

          {expandedOption === label ? (
            <DropdownB className="drop" />
          ) : (
            <Dropdown className="drop" />
          )}
          
          {/* <img className='drop' src={expandedOption === label ? DropdownB : Dropdown} alt="drop"></img> */}
        </div>
      }
      open={expandedOption === label}
    >
      <div className="submenu">
        <div className="verticalLine"></div>
        <div className="suboptions-container">
          {Object.keys(subOptions).map((subOption, index) => (
            <div
              key={subOption}
              // ref={selectedSubOption === subOption && label === "Master" ? scrollToRef : null}
              className={`suboptions ${selectedSubOption === subOption ? "active" : ""
                }`}
              onClick={() => {
                displayChange();
                handleSubOptionClick(subOption);
                navigate(`/${subOption.toLowerCase()}`, {
                  state: { permission: subOptions[subOption] },
                });
              }}
            >
              <a style={{ cursor: "pointer" }}>{subOption}</a>
            </div>
          ))}
        </div>
      </div>
    </Collapsible>
  );
}

export default Navbar;