import React from 'react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { convertShortLength, fetchInventory } from '../../../redux-tookit/slices/inventorySlice'
import AGResizeTables from '../../../components/Tables/AGResizeTables'
import { useState } from 'react'
import ExcelDownloadButton1 from '../../../components/ExcelDownloadButton1'
import refresh from '../../../assets/images/button-icons/Refresh.svg';
import AGTable from '../../../components/AGTable'
import Button from '../../../utils/Button'
import AddIcon from '../../../assets/images/icons/action/plus-box.svg'
import { toast } from 'react-toastify'
import { fetchUserAccess } from '../../../redux-tookit/slices/userAccessSlice'
import { fetchGrades } from '../../../redux-tookit/slices/gradeMasterSlice'
import AddInventory from './AddInventory'
import StockBulkUpload from './StockUpload/StockBulkUpload'


const InventoryMaster = () => {
    const dispatch = useDispatch()
    const originalData = useSelector(state => state.inventory.labeledData2)
    const columns = useSelector(state => state.inventory.columns2)
    const stockSummary = useSelector(state => state.inventory.stockSummary);
    const summaryColumns = useSelector(state => state.inventory.summaryColumns)
    const [data, setData] = useState(originalData);

    // console.log(originalData);

    const [stockFilter, setStockFilter] = useState('');

    const [stockTypeValues, setStockTypeValues] = useState([])

    const stock = ['Summary', 'Details'];

    const [stockRadio, setStockRadio] = useState('Details');

    const [selectedRowIndex, setSelectedRowIndex] = useState(null)

    const [isConverting, setIsConverting] = useState(false)

    const [isEditing, setIsEditing] = useState(false);

    const [editData, setEditData] = useState([])

    const [permission, setPermission] = useState({});

    const [bulkPopup, setBulkPopup] = useState(false);

    const [nFG, setNFG] = useState([]);

    const [showNFG, setShowNFG] = useState(false);

    console.log(nFG, showNFG);

    useEffect(() => {
        dispatch(fetchUserAccess())
            .then(res => {
                try {
                    if (res.payload) {
                        if (res.payload.navbarNames.Transaction["Stock Details"][0]) {
                            setPermission(res.payload.navbarNames.Transaction["Stock Details"][0])
                        }
                    }

                } catch (error) {
                    toast.error(`${error.message}`)
                }
            })
    }, [])

    const applyFilters = () => {
        let filteredData = originalData;

        if (stockFilter) {
            filteredData = filteredData.filter((item) =>
                item.stockType === stockFilter
            );
        }

        setData(filteredData);
    };

    const handleRefresh = () => {
        setStockFilter('')
    }

    const handleConvertion = () => {
        if (data[selectedRowIndex].stockType === 'SHORT LENGTH') {
            if (data[selectedRowIndex].totalPieces * 1 !== 0) {
                setIsConverting(true)
            }
            else toast.error('No pieces in selected stock')
        } else toast.error('Convertion only for Short length')
    }
    // console.log(selectedRowIndex);

    useEffect(() => {
        setStockTypeValues([...new Set(originalData.map((item) => item.stockType))])
        applyFilters()
    }, [originalData, stockFilter])

    useEffect(() => {
        dispatch(fetchInventory()).then(() => dispatch(fetchGrades()))
    }, [])


    // const handleEditButtonClick = () => {
    //     if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
    //         setIsEditing(true);
    //         setEditData({ ...data[selectedRowIndex] });
    //       }
    //       else toast.error("No rows selected, Please select a row and try again");
    //       setSelectedRowIndex(null);
    //       setDeselector(deselector + 1);
    // }



    const handleRowSelect = (rowIndex) => {
        setSelectedRowIndex(rowIndex);
        console.log(rowIndex);
    };

    return (
        <>
            <div>
                <div className="head">Realtime Stock</div>
                <div className="filter-card">
                    <div className="filter" style={{ width: '100%', position: 'relative' }}>
                        <div className='filter' style={{ flexDirection: 'column' }}>
                            <label className='DateHead' style={{ marginBottom: '1.5vh' }}>Stocks: </label>
                            <div className="DateFilt" style={{ paddingBottom: '1vh' }}>
                                {stock.map((v) => (
                                    <div key={v} className='filterD' style={{}}>
                                        <input
                                            type="radio"
                                            name="stock-select"
                                            value={v}
                                            checked={v === stockRadio}
                                            onChange={(e) => { setStockRadio(e.target.value) }}
                                            id={v}
                                        />
                                        <label htmlFor={v} className="stockId-radio">{v}</label>
                                    </div>
                                ))}
                            </div>
                        </div>
                        {stockRadio === 'Summary' ? '' :
                            <div className="filter" style={{ flexDirection: 'column' }}>
                                <label htmlFor="stockType" >Stock Type{' '}
                                </label>
                                <div style={{ flexDirection: 'row' }}>
                                    <select name="stock" id="stockType" style={{ width: 'fit-content' }} onChange={(e) => setStockFilter(e.target.value)} value={stockFilter}>
                                        <option value="" >All</option>
                                        {stockTypeValues.map((item, index) => (
                                            <>
                                                <option key={index} value={item}>{item}</option>
                                            </>
                                        ))}
                                    </select>
                                </div>
                            </div>
                        }
                        <div style={{ position: 'absolute', bottom: '0', right: '0' }}>
                            {
                                stockRadio === 'Summary' ? '' :
                                    <div >
                                        <img src={refresh} alt="refresh" style={{ width: '20px' }} onClick={() => handleRefresh()} />
                                    </div>
                            }

                        </div>
                    </div>
                </div>

                <div className="head">{stockRadio}
                    <div className="top-button-container">

                        {/* <TopButtonContainer
                            
                            edit={handleEditButtonClick}
                            access={permission}
                            accessOverride = {[0,1,0]} 
                            /> */}

                        {
                            stockRadio === 'Summary' ? '' :
                                <>
                                    <ExcelDownloadButton1 csvData={data} columns={columns} fileName='InventoryList' />
                                </>
                        }
                    </div>
                </div>
                {
                    stockRadio === 'Summary' ?
                        <AGResizeTables data={stockSummary} columns={summaryColumns} width='59%' />
                        : <>
                            <AGTable data={data} columns={columns} onSelectRow={setSelectedRowIndex} />
                            <div className="bottom-button-container">
                                {permission.updatePermission === true ?
                                    <>
                                        <Button label='Convert Short length' className='blue' onClick={() => handleConvertion()} disabled={selectedRowIndex} />
                                        <Button label='Stock Bulk Upload' className='blue' onClick={() => setBulkPopup(true)} />
                                    </>
                                    : ''}
                            </div>
                        </>
                }

            </div>
            {isEditing === true ?
                <AddInventory isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} onSelectRow={handleRowSelect} />
                : ""}
            {isConverting && <ConverterPopup setIsConverting={setIsConverting} data={data[selectedRowIndex]} />}
            {bulkPopup && <StockBulkUpload setBulkPopup={setBulkPopup} setNFG={setNFG} setShowNFG={setShowNFG}/> }
        </>
    )
}

const ConverterPopup = (props) => {
    const dispatch = useDispatch()
    const { setIsConverting, data } = props
    const xon = Math.PI * data.diameter * data.diameter * 7850 / 4000000000000;
    const [tableData, setTableData] = useState([{
        "weight": '',
        "noOfpieces": '',
        "stockType": "CUSTOMIZED LENGTH",
        "length": ''
    }])

    const addNewRow = () => {
        const blankRow = {
            "weight": '',
            "noOfpieces": '',
            "stockType": "CUSTOMIZED LENGTH",
            "length": ''
        }
        setTableData((prev) => {
            return [...prev, blankRow]
        })
    }

    const handleValueChange = (index, header, value) => {

        setTableData((prev) => {
            let newArray = [...prev];
            newArray[index] = { ...newArray[index], [header]: value }
            if (header === 'noOfpieces') {
                newArray[index] = { ...newArray[index], weight: Math.floor(value * newArray[index].length * xon * 1000) / 1000 }
            } else if (header === 'length') {
                newArray[index] = { ...newArray[index], weight: Math.floor(value * newArray[index].noOfpieces * xon * 1000) / 1000 }
            }
            return newArray;
        })

    }

    const handleRemoveRow = (index) => {
        if (tableData.length === 1) {

            toast.error('Cannot remove')
            return
        }
        setTableData((prev) => {
            let newArray = [...prev];
            newArray.splice(index, 1)
            return newArray
        })
    }

    const handleSave = async (e) => {
        e.preventDefault();
        // console.log(data.gradeId);
        const newRow = {
            "gradeId": data.gradeId,
            "stockType": "SHORT LENGTH",
            "updateInv": tableData
        }

        dispatch(convertShortLength(newRow))
            .then((res) => {
                if (res.payload !== undefined) {
                    dispatch(fetchInventory())
                    setIsConverting(false)
                }
            });
    }


    return (
        <div >
            <form onSubmit={handleSave}>
                <div className="AddContainer" style={{ padding: '50px', justifyContent: 'flex-start' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'fit-content', backgroundColor: '#fff', borderRadius: '30px', padding: '40px', paddingBottom: '1vw' }}>
                        <GradeDetailTable data={data} tableData={tableData} />
                        <br />
                        <CustomAddTable data={tableData} addNewRow={addNewRow} handleValueChange={handleValueChange} handleRemoveRow={handleRemoveRow} defaultData={data} />
                        <div className="bottom-button-container">
                            <Button label='Add' type='submit' className='blue' onClick={() => { }} />
                            <Button label='Close' className='blue' onClick={() => setIsConverting(false)} />
                        </div>
                    </div>
                </div>
                <div className="bg-dark"></div>
            </form>
        </div>
    )
}



const CustomAddTable = ({ data, addNewRow, handleValueChange, handleRemoveRow, defaultData }) => {
    const tableHeaders = ['Length (mm)', 'No. of pieces', 'Weight (T)'];
    const tableKeys = ['length', 'noOfpieces', 'weight']

    return (
        <div className="table-container" style={{ maxHeight: '400px', overflow: 'auto' }}>

            <table className="generic-table">
                <thead>
                    <tr>
                        {tableHeaders.map(data => (
                            <th key={data} className="table-header" style={{ backgroundColor: '#fff', color: '#666' }}>{data}</th>
                        ))}
                        <th className="table-header" style={{ width: '20px', backgroundColor: '#fff' }} onClick={addNewRow}>
                            <img src={AddIcon} alt="add" style={{ width: '30px' }} />

                        </th>
                    </tr>
                </thead>
                <tbody>

                    {data.map((row, index) => (
                        <tr>
                            {tableKeys.map((header) => (
                                header !== 'stockType' ?
                                    <td >
                                        {header !== 'weight' ?
                                            <input
                                                type="number"
                                                value={row[header]}
                                                onChange={(e) => handleValueChange(index, header, e.target.value)}
                                                onKeyDown={(e) => e.key === 'e' && e.preventDefault()}
                                                max={header === 'length' ? 12000 : defaultData.totalPieces}
                                                style={{ padding: '10px', borderRadius: '3px', border: '1px solid #d5d5d5' }}
                                                required
                                            /> :
                                            row[header]
                                        }
                                    </td> : ''
                            ))}
                            <td onClick={() => handleRemoveRow(index)}>
                                <svg width="31" height="31" fill="none" >
                                    <path
                                        d="M15 0C6.729 0 0 6.729 
                                        0 15s6.729 15 15 15 15-6.729 
                                        15-15S23.271 0 15 0Zm6.31 
                                        19.19-2.12 2.12L15 17.122l-4.19 
                                        4.19-2.12-2.122L12.879 15l-4.19-4.19 
                                        2.121-2.12L15 12.879l4.19-4.19 2.12 
                                        2.121L17.122 15l4.19 4.19Z"
                                        fill="#BB1010" />
                                </svg>
                            </td>
                        </tr>
                    ))}

                </tbody>
            </table>

        </div>
    )
}


const GradeDetailTable = ({ data, tableData }) => {
    const tableHeader = ['Input Material', 'Diameter', 'Average Length', 'Weight Remaining', 'Pieces']
    let weightSum = 0;

    tableData.forEach(el => {
        weightSum += el.weight * 1;

    });
    return (<div>
        <div className="table-container">
            <table className="generic-table">
                <thead>
                    <tr>
                        {tableHeader.map((data) => (
                            <th key={data} className="table-header">{data} </th>
                        ))}

                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{data.material}</td>
                        <td>{data.diameter}</td>
                        <td>{data.length}</td>
                        <td>{data.weight - weightSum < 0 ? 0 : data.weight - weightSum}</td>
                        <td>{data.totalPieces}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>)
}



export default InventoryMaster

