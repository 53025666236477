import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import TableComponent from '../../components/TableComponent2';
import Button from '../../utils/Button';
import Print from '../../assets/images/icons/print.svg'
import '../../assets/styles/Summary.css'
import GenericTable1 from '../../components/GenericTable1';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchTempPlan } from '../../redux-tookit/slices/tempPlanSummarySlice';
import { getNavOptions, setNavOptions } from '../../redux-tookit/slices/navbarSlice';
import ResultPopup from './Plan details/ResultPopup';
import { fetchCuttingSeq } from '../../redux-tookit/slices/cuttingSeqSlice';

const TempSummary = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [resultShow, setResultShow] = useState()
    //const myData = location.state?.planId;
    const [planId, setPlanId] = useState(location.state?.planId)
    const [planCombId, setPlanCombId] = useState('')


    const columns = useSelector((state) => state.tempPlan.planColumns);
    const data = useSelector((state) => state.tempPlan.labeledData);

    const [matUsed, setMatUsed] = useState({})
    // const summary = useSelector((state) => state.cutSeq.summary);

    // const [result, setResult] = useState(location.state?.result)

    const [matData, setMatData] = useState({
        productionWeight: '',
        offcutWithRebar: '',
        offcutWithCoil: '',
        scrapWithRebar: '',
        scrapWithCoil: '',
        offcutPercentWithRebar: '',
        offcutPercentWithCoil: '',
        scrapPercentWithRebar: '',
        scrapPercentWithCoil: '',
    });

    const index = data.findIndex(item => item.planId === planId);

    // useEffect(() => {
    //     const uniqueOrderIds = [...new Set(summary.map(item => item.orderId))];
    //     setOrders(uniqueOrderIds);
    // }, [summary]);

    const [selectedRowIndex, setSelectedRowIndex] = useState(index);

    const handleRowSelect = (rowIndex) => {
        if (rowIndex !== selectedRowIndex) {
            setSelectedRowIndex(rowIndex);
            setPlanId(data[rowIndex].planId)
            setMatUsed(data[rowIndex]);
        } else {
            setSelectedRowIndex(null);
            setPlanId(null)
            setMatUsed({
                productionWeight: '',
                offcutWithRebar: '',
                offcutWithCoil: '',
                scrapWithRebar: '',
                scrapWithCoil: '',
                offcutPercentWithRebar: '',
                offcutPercentWithCoil: '',
                scrapPercentWithRebar: '',
                scrapPercentWithCoil: ''
            });
        }


    };

    // useEffect(()=>{
    //     if(result){
    //         setResultShow(true)
    //     }
    // },[])

    const handlePlanNavigate = () => {
        dispatch(setNavOptions({ expOpt: 'Transaction', selOpt: 'New Plan' }))
            .then(dispatch(getNavOptions()))
        // localStorage.setItem('navExpandedOption','transaction')
        // localStorage.setItem('navSelectedOption','new plan')

        navigate('/new plan')
    }

    const handleOpenPlanNavigate = () => {
        dispatch(setNavOptions({ expOpt: 'Transaction', selOpt: 'Open Plan' }))
        // localStorage.setItem('navExpandedOption','transaction')
        // localStorage.setItem('navSelectedOption','open plan')
        navigate('/open plan')
    }

    useEffect(() => {
        if (matUsed.productionWeight !== undefined) {
            setMatData({
                productionWeight: matUsed.productionWeight,
                offcutWithRebar: matUsed.offcutWithRebar,
                offcutWithCoil: matUsed.offcutWithCoil,
                scrapWithRebar: matUsed.scrapWithRebar,
                scrapWithCoil: matUsed.scrapWithCoil,
                offcutPercentWithRebar: matUsed.offcutPercentWithRebar,
                offcutPercentWithCoil: matUsed.offcutPercentWithCoil,
                scrapPercentWithRebar: matUsed.scrapPercentWithRebar,
                scrapPercentWithCoil: matUsed.scrapPercentWithCoil,

            });
        }

    }, [matUsed]);

    //             "productionWeight": 95.606,
    //             "offcutWithRebar": 0.619,
    //                 "offcutWithCoil": 0,
    //             "scrapWithRebar": 12.052,
    //                 "scrapWithCoil": 0,
    //             "offcutPercentWithRebar": 0.647,
    //                 "offcutPercentWithCoil": 0,
    //             "scrapPercentWithRebar": 12.606,
    //                 "scrapPercentWithCoil": 0


    const generateCuttingPdf = () => {
        const id = planId;

        dispatch(fetchCuttingSeq(id))
            .then((res) => {
                if (res.payload && res.payload.result && res.payload.result.length > 0) {
                    console.log(res.payload.result);
                    const result = res.payload.result[0].cuttingSequenceOrder;
                    const offcutT = res.payload.result[0].totalOffcut;
                    const scrapT = res.payload.result[0].totalScrap;
                    const offcutP = res.payload.result[0].offcutPercentage;
                    const scrapP = res.payload.result[0].scrapPercentage;
                    console.log(offcutT, offcutP, scrapT, scrapP);
                    navigate(`/cutting sequence/`, { state: { result, planId, offcutT, offcutP, scrapT, scrapP, planCombId } })
                }
            })
    }

    useEffect(() => {
        const id = planId;
        dispatch(fetchTempPlan());
        if (index !== -1) {
            setMatUsed(data[index])
            setSelectedRowIndex(index)
        }
    }, [dispatch, index]);

    useEffect(() => {
        setPlanCombId(data.find(item => item?.planId === planId)?.planCombId || '')
    }, [planId, data])


    return (
        <div className='sdbr'>
            <div className='head'>Plan Details - {planCombId}</div>
            <div className='filter-card'>
                <div className="Sumfilter-layout" style={{ gridTemplateRows: '1fr' }}>
                    {/* // "productionWeight": 95.606,
    //             "offcutWithRebar": 0.619,
    //                 "offcutWithCoil": 0,
    //             "scrapWithRebar": 12.052,
    //                 "scrapWithCoil": 0,
    //             "offcutPercentWithRebar": 0.647,
    //                 "offcutPercentWithCoil": 0,
    //             "scrapPercentWithRebar": 12.606,
    //                 "scrapPercentWithCoil": 0 */}
                    {/* <div className="filter">
                        <label htmlFor='totaltags'>Production Weight(T)</label>
                        <input type="text" id="totaltags" value={matData.productionWeight} readOnly />
                    </div> */}
                    <div className="filter">
                        <label htmlFor='totalusedstock'>Offcut with Rebar(T)</label>
                        <input type="text" id="totalusedstock" placeholder="T" value={matData.offcutWithRebar} readOnly />
                    </div>
                    {/* <div className="filter">
                        <label htmlFor='totalscrap'>Offcut with Coil</label>
                        <input type="text" id="totalscrap" placeholder="Kg" value={matData.offcutWithCoil} readOnly />
                    </div> */}
                    <div className="filter">
                        <label htmlFor='totalscrapPercent'>Scrap with Rebar(T)</label>
                        <input type="text" id="totalscrapPercent" placeholder="T" value={matData.scrapWithRebar} readOnly />
                    </div>
                    {/* <div className="filter">
                        <label htmlFor='totalallocation'>Scrap with Coil</label>
                        <input type="text" id="totalallocation" placeholder="Kg" value={matData.scrapWithCoil} readOnly />
                    </div> */}
                    <div className="filter">
                        <label htmlFor='totalallocationpercent'>Offcut with Rebar(%)</label>
                        <input type="text" id="totalallocationpercent" placeholder="%" value={matData.offcutPercentWithRebar} readOnly />
                    </div>
                    {/* <div className="filter">
                        <label htmlFor='totaloffcut'>Offcut with Coil(%)</label>
                        <input type="text" id="totaloffcut" placeholder="Kg" value={matData.offcutPercentWithCoil} readOnly />
                    </div> */}
                    <div className="filter">
                        <label htmlFor='totaloffcutpercent'>Scrap with Rebar(%)</label>
                        <input type="text" id="totaloffcutpercent" placeholder="%" value={matData.scrapPercentWithRebar} readOnly />
                    </div>
                    {/* <div className="filter">
                        <label htmlFor='totaloffcutpercent'>Scrap with Coil(%)</label>
                        <input type="text" id="totaloffcutpercent" placeholder="%" value={matData.scrapPercentWithCoil} readOnly />
                    </div> */}


                </div>
            </div>
            {/* Tables */}
            <div>
                <div className='head'>Filter</div>
                <GenericTable1 columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
            </div>

            <div className="bottom-button-container">
                {/* <Button label="Summary" className="blue" />
                <Button label="Excel Cutting Sequence" className="blue" /> */}
                <Button label="Cutting Sequence" className="blue" onClick={generateCuttingPdf} />
                <Button label="New plan" className="blue" onClick={handlePlanNavigate} />
                <Button label="Open plan" className="blue" onClick={handleOpenPlanNavigate} />
            </div>
            {/* {resultShow===true?<ResultPopup data={result.optimizationDetails[0]} summary={result.optimizationResult} setResultShow={setResultShow} />:""} */}

        </div>
    )
}

export default TempSummary