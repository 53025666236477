
export const dateChanger = (date) => {
    if (date !== '' && date !== null && date) {
        if(date.includes('-')){
           const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}` 
        } else return date
        
    }
};
//For changing the dates in the tables from yyyy-mm-dd to dd-mm-yyy
