import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import { resetState } from './loginSlice';

const initialState = {
    navbarNames: {},
    rawJson: [],
    loading: 'idle',
    error: null,
};

export const fetchUserAccess = createAsyncThunk('data/fetchUserAccess', async () => {
    try {
        const response = await api.listPagePermissions();
        if (response.data.length === 0) {
            throw ({ message: 'User Access Not Provided' })
        }


        const json = response.data[0].Role.Modules;

        // const scId = response.data[0].serviceCenterId
        // console.log(response.data);
        // console.log(json);

        let navbarNames = {}

        json.forEach(ele => {

            navbarNames = { ...navbarNames, [ele.moduleName]: {} }

            ele.Pages.forEach(data => {

                if (data.Permissions[0]) {
                    if (data.Permissions[0].readPermission) {

                        navbarNames[ele.moduleName] = { ...navbarNames[ele.moduleName], [data.pageName]: data.Permissions }

                    }
                }

                // console.log(data.Permissions[0],data.pageName );
            })

        });

        // console.log(navbarNames);
        // const labeledData = navbarNames;


        return { navbarNames };
    } catch (error) {
        if (error.message) { toast.error(`${error.message}`) }
        throw error;
    }
});

export const fetchRoleWiseAccess = createAsyncThunk('data/fetchPagePermission', async () => {
    try {
        const response = await api.listRoleWisePagePermissions();
        const json = response.data;
        // console.log('HERE2');


        // console.log(json, response);

        return { json };
    } catch (error) {
        if (error.message) { toast.error(`${error.message}`) }
        throw error;
    }
})


export const updateUserAccess = createAsyncThunk('data/updateUserAccess', async (data) => {
    try {
        const response = await api.updatePermissions(data);
        const json = response.data;

        return { json }
    } catch (error) {
        if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
        throw error;
    }
})

const userAccessSlice = createSlice({
    name: 'userAccess',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetState, () => initialState)
            .addCase(fetchUserAccess.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchUserAccess.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.navbarNames = action.payload.navbarNames;
            })
            .addCase(fetchUserAccess.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(fetchRoleWiseAccess.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchRoleWiseAccess.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.rawJson = action.payload.json;
            })
            .addCase(fetchRoleWiseAccess.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
    },

});

export default userAccessSlice.reducer;