import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchBarMarkQtyData } from '../../redux-tookit/slices/barMarkWiseQtySlice';
import TableComponent from '../../components/TableComponent2';
import excel from '../../assets/images/button-icons/excel.svg';
import '../../assets/styles/ReportPage.css'

const BarMarkWiseQty = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.barMarkWiseQty.columns);
  const originalData = useSelector((state) => state.barMarkWiseQty.labeledData);
  const [data, setData] = useState(originalData);

  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [customerFilter, setcustomerFilter] = useState('');
  const [ordernoFilter, setordernoFilter] = useState('');

  const customerValues = [...new Set(originalData.map(item => item.soldToParty))];
  const ordernoValues = [...new Set(originalData.map(item => item.orderId))];

  const applyFilters = () => {
    let filteredData = originalData;

    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.createdTs);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }

    if (customerFilter) {
      filteredData = filteredData.filter((item) =>
        item.soldToParty.includes(customerFilter)
      );
    }

    if (ordernoFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderId === parseInt(ordernoFilter)
      );
    }

    setData(filteredData);
  }

  useEffect(() => {
    dispatch(fetchBarMarkQtyData());
  }, [dispatch]);
  
  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  useEffect(() => {
    applyFilters();
  }, [fromDateFilter, toDateFilter, customerFilter, ordernoFilter]);

  return (
    <div className='sdbr'>
      <div className='filter-card'>
        <div className="filter-BMWQ">

          <div className="row-1">
            <div className="filter">
              <label htmlFor="customerFilter">Customer</label>
              <select
                id="customerFilter"
                value={customerFilter}
                onChange={(e) => setcustomerFilter(e.target.value)}
              >
                <option value="">All</option>
                {customerValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            <div className='DateCont'>
              <label className='DateHead'>Order Creation Date</label>
              <div className="DateFilt">
                <div className="filterD">
                  <label htmlFor="fromDateFilter">From</label>
                  <input
                    id="fromDateFilter"
                    type="date"
                    value={fromDateFilter}
                    onChange={(e) => setFromDateFilter(e.target.value)}
                  />
                </div>
                <div className="filterD">
                  <label htmlFor="toDateFilter">To</label>
                  <input
                    id="toDateFilter"
                    type="date"
                    value={toDateFilter}
                    onChange={(e) => setToDateFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>
            
          </div>

          <div className="row-2">
            <div className="filter">
              <label htmlFor="ordernoFilter">Order Number</label>
              <select
                id="ordernoFilter"
                value={ordernoFilter}
                onChange={(e) => setordernoFilter(e.target.value)}
              >
                <option value="">All</option>
                {ordernoValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>

        </div>


      </div>
      <div className='head'>
        <div> Order Details</div>
        <div className='top-button-container'>

          <img src={excel} alt="" width={17} height={18} />

        </div>

      </div>
      <div>
        <TableComponent columns={columns} data={data} />
      </div>

    </div>
  );
};

export default BarMarkWiseQty;