import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../../utils/Button';
import { fetchEquipments, postEquipments, updateEquipment } from '../../../redux-tookit/slices/equipmentMasterSlice';
import { fetchEquipmentCategory } from '../../../redux-tookit/slices/equipmentCategorySlice';
import { fetchProcessType } from '../../../redux-tookit/slices/processTypeSlice';
 
const AddEquipments = (props) => {
    const dispatch = useDispatch();
    const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData } = props
    const columns = useSelector((state) => state.equipmentMaster.columns);
    const equipmentCategoryData = useSelector((state) => state.equipmentCategory.labeledData);
    const processTypeData = useSelector((state) => state.processType.labeledData);
    const [newRow, setNewRow] = useState({});
    const columnKeys = Object.keys(columns);
    const handleInputChange = (header, e) => {
        const updatedData = JSON.parse(JSON.stringify(editData));
        updatedData[header] = e.target.value;
        setEditData(updatedData);
    }
    const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        dispatch(postEquipments({ ...newRow }))
        .then(res =>{
            if(res.payload!==undefined){
                setTimeout(()=>{dispatch(fetchEquipments())},100)
                
            }
        })

        setIsAdding(false);
        setNewRow({});
    };
    
    const handleUpdate = async (e) => {
 
        e.preventDefault();
        const selectedProcess = processTypeData.find(org => org.processType === editData.processType);
        const selectedCategory = equipmentCategoryData.find(org => org.equipmentCategory === editData.equipmentCategory);
        const id = editData.equipmentId;
        const type = {
            'equipmentName': editData.equipmentName,
            'processType': selectedProcess.processTypeId,
            'equipmentType': editData.equipmentType,
            'equipmentCategory': selectedCategory.equipmentCategoryId,
        };
        const type1 = {
            'equipmentName': editData.equipmentName,
            'processType': selectedProcess.processType,
            'equipmentType': editData.equipmentType,
            'equipmentCategory': selectedCategory.equipmentCategory,
        };
 
        console.log(type);
        //console.log(editData);
        dispatch(updateEquipment({ id, type,type1 }))
        setIsEditing(false);
    }
    useEffect(() => {
        dispatch(fetchEquipmentCategory());
        dispatch(fetchProcessType());
    }, [isAdding]);
    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isAdding &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Add Equipments</div>
                            <div className="table-container">
                                {columnKeys.map((column, key) => (
                                    column !== 'equipmentId' ?
                                        (column === 'equipmentCategory' ?
                                            (<div className="filter">
                                                <label>
                                                                {columns[column].label}
                                                                {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                                <select
                                                    key={key}
                                                    id="equipmentCategories"
                                                    onChange={(e) => {
                                                        setNewRow({ ...newRow, [column]: e.target.value });
                                                    }}
                                                    required
                                                >
                                                    <option value=''>Select</option>
                                                    {equipmentCategoryData.map((value, index) => (
                                                        <option value={value.equipmentCategoryId}>
                                                            {value.equipmentCategory}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>)
                                            :
                                            column === 'processType' ?
                                                (<div className="filter">
                                                    <label>
                                                                {columns[column].label}
                                                                {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                                    <select
                                                        key={key}
                                                        id="processTypes"
                                                        onChange={(e) => {
                                                            setNewRow({ ...newRow, [column]: e.target.value });
                                                        }}
                                                        required
                                                    >
                                                        <option value=''>Select</option>
                                                        {processTypeData.map((value) => (
                                                            <option value={value.processTypeId}>
                                                                {value.processType}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>) :
                                                (<div className="filter">
                                                    <label>
                                                                {columns[column].label}
                                                                {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                                    <input
                                                        key={key}
                                                        type={columns[column].type}
                                                        className='table-input'
                                                        value={newRow[column] || ''}
                                                        onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                        required={columns[column].isRequired ? true : false}
                                                    />
                                                </div>)
                                        ) :
                                        ""
                                ))}
 
                            </div>
 
                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsAdding(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Edit Service Center</div>
                            <div className="table-container">
                                {Object.keys(columns).map((key) => (columns[key].editable === true ?
                                    (key === 'equipmentCategory' ?
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                            <label>
                                                                {columns[key].label}
                                                                {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                            <select
                                                id="equipmentCategory"
                                                value={editData[key]}
                                                required
                                                onChange={(e) => {
                                                    handleInputChange(key, e);
                                                }}
                                            >
 
                                                {equipmentCategoryData.map((value, index) => (
                                                    <option key={index} value={value.equipmentCategory}>
                                                        {value.equipmentCategory}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>) :
                                        (key === 'processType' ?
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                        <label>
                                                                {columns[key].label}
                                                                {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                        <select
                                            id="processType"
                                            value={editData[key]}
                                            required
                                            onChange={(e) => {
                                                handleInputChange(key, e);
                                            }}
                                        >
 
                                            {processTypeData.map((value, index) => (
                                                <option key={index} value={value.processType}>
                                                    {value.processType}
                                                </option>
                                            ))}
                                        </select>
                                    </div>) :
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                            <label>
                                                                {columns[key].label}
                                                                {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                            <input
                                                type={columns[key].type}
                                                className='table-input'
                                                value={editData[key]}
                                                required
                                                onChange={(e) => handleInputChange(key, e)}
                                                pattern={columns[key].pattern}
                                            /></div>))) :
                                    ""
                                ))}
                            </div>
                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>
            }
            </form>
        </div>
    )
}
 
export default AddEquipments