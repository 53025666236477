import React, { useState } from 'react'
import CreateLoadingListPDF from './CreateLoadingListPDF'
import { PDFViewer } from '@react-pdf/renderer'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { fetchPartItems, fetchPartItems2 } from '../../../../../redux-tookit/slices/partItemsSlice'
import { fetchBundles } from '../../../../../redux-tookit/slices/loadingListSlice'
import Loader from '../../../../../components/Loader/Loader'
import { fetchServiceCenter } from '../../../../../redux-tookit/slices/serviceCenterSlice'

const PrintLoadingList = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const listDetails = state?.data;
  const bundleData = useSelector(state => state.loadingList.labeledData4)

  const serviceCenter1 = useSelector(state => state.serviceCenter.labeledData).filter((sc) => sc.imageUrl)[0] || ''
 
  const bufferToBase64 = (buffer) => {
    try {
      // console.log(buffer);

      const byteArray = new Uint8Array(buffer);
      const decoder = new TextDecoder('utf-8');

      let result = '';
      for (let i = 0; i < byteArray.length; i += 1024) {
        const chunk = byteArray.subarray(i, i + 1024);
        result += decoder.decode(chunk);
      }
      console.log('this ran');
      // console.log(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  const serviceCenterLogo = bufferToBase64(serviceCenter1?.imageUrl?.data);

  const [loading, setLoading] = useState(false)

  const [partItems, setPartItems] = useState([])

  const [partItemIds, setPartItemIds] = useState([])

  console.log({ bundleData, partItems });

  const getAllPartItems = (partIds) => {
    const orderIds = JSON.parse(`[${listDetails.orderId}]`).sort((a,b) => a - b)
    let tempPartItems = []


    console.log(orderIds);

    orderIds.forEach((ele, index) => {
      dispatch(fetchPartItems(`?orderId=${ele}`))
        .then((res) => {
          if (res.payload) {
            console.log(res.payload, 'yo');
            const tempData = res?.payload?.labeledData
            console.log(tempData);
            tempPartItems = [...tempPartItems, ...tempData]
            console.log(tempPartItems, 'yes');
            if (orderIds.length === index + 1) {
              setPartItems(() => {
                let tempPartArray = tempPartItems.filter(item => partIds.includes(item.partItemId)) 
                tempPartArray.sort((a,b) => a.orderItemId - b.orderItemId).forEach((rowData, index) => {
                  let normalCount = 0, balanceQty = 0
                  bundleData.filter(item => item.partItemId === rowData.partItemId).forEach(bundle => {
                    if(bundle.noOfPieces === rowData.bundleQuantity){
                      normalCount++;
                    } else {
                      balanceQty = bundle.noOfPieces
                    }
                  })
                  tempPartArray[index] = {...rowData, "bundles": `${normalCount}x(${rowData.bundleQuantity}) ${balanceQty!==0?`+ ${balanceQty}`:''}`}
                })
                return tempPartArray
              })
              setLoading(false)
            }
          }
        })
    });

  }



  useEffect(() => {

    dispatch(fetchBundles(`?bundleIds=${listDetails.bundleId}`))

    dispatch(fetchServiceCenter())


  }, [])

  useEffect(() => {
    let partIds = []
    bundleData.forEach(ele => {
      partIds.push(ele.partItemId)
    });

    getAllPartItems(partIds)

    setPartItemIds(partIds)

    // dispatch(fetchPartItems(`?partItemIds=${partItemIds}`))
  }, [bundleData])

  return (
    <div style={{ border: '2px solid black', height: '100%' }}>
      {loading ? <Loader /> :
        partItems.length !== 0 ?
          <PDFViewer style={{ height: '100%', width: '100%' }}>
            <CreateLoadingListPDF
              serviceCenterLogo={serviceCenterLogo}
              listDetails={listDetails}
              partItems={partItems}
              bundleData={bundleData}
            />
          </PDFViewer>
          : ''
      }
    </div>
  )
}

export default PrintLoadingList
