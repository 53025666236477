import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';


const initialState = {
    columns: {},
    labeledData: [],
    loading: 'idle',
    error: null,
};

export const updatePassword = createAsyncThunk('data/updatePassword', async (newRow) => {
    try {

        const response = await api.putPassword(newRow);
        const data = response;
        console.log('Success');
        return data;

    } catch (error) {
        if(error.response.data.message)
		{toast.error(`${error.response.data.message}`)}
		throw error;
    }
});



const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(updatePassword.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(updatePassword.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                console.log(action.payload.data);
            })
            .addCase(updatePassword.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
    }
});

export default passwordSlice.reducer;