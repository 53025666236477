// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ag-theme-alpine {
    --ag-background-color: #fff;
    --ag-font-size:13px;
    --ag-font-weight: narrow;
    --ag-odd-row-background-color: #fff;
    --ag-header-background-color: #138EE0;
    --ag-header-foreground-color: #fff;
    --ag-header-cell-moving-background-color:#1f86cbb1;
    --ag-control-panel-background-color: #fff;

}

.ag-theme-alpine .ag-header-cell-label {
    /* justify-content: center; */
    font-size:15px;
}

.ag-theme-alpine .ag-cell-value {
    display: flex;
    justify-content:flex-start;
    /* align-items: center; */
    /* align-content: center; */
    text-wrap: wrap;
    word-break: break-all;
    align-content: space-around;
    line-height: 2;
}

.ag-theme-alpine .ag-cell{
    border-right: solid 1px #e9e8e8;
    border-collapse: collapse;
    align-content: space-around;
}

.bbs .ag-header-cell-text{
    font-size: 0.8rem;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/AGTable.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,mBAAmB;IACnB,wBAAwB;IACxB,mCAAmC;IACnC,qCAAqC;IACrC,kCAAkC;IAClC,kDAAkD;IAClD,yCAAyC;;AAE7C;;AAEA;IACI,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,0BAA0B;IAC1B,yBAAyB;IACzB,2BAA2B;IAC3B,eAAe;IACf,qBAAqB;IACrB,2BAA2B;IAC3B,cAAc;AAClB;;AAEA;IACI,+BAA+B;IAC/B,yBAAyB;IACzB,2BAA2B;AAC/B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".ag-theme-alpine {\r\n    --ag-background-color: #fff;\r\n    --ag-font-size:13px;\r\n    --ag-font-weight: narrow;\r\n    --ag-odd-row-background-color: #fff;\r\n    --ag-header-background-color: #138EE0;\r\n    --ag-header-foreground-color: #fff;\r\n    --ag-header-cell-moving-background-color:#1f86cbb1;\r\n    --ag-control-panel-background-color: #fff;\r\n\r\n}\r\n\r\n.ag-theme-alpine .ag-header-cell-label {\r\n    /* justify-content: center; */\r\n    font-size:15px;\r\n}\r\n\r\n.ag-theme-alpine .ag-cell-value {\r\n    display: flex;\r\n    justify-content:flex-start;\r\n    /* align-items: center; */\r\n    /* align-content: center; */\r\n    text-wrap: wrap;\r\n    word-break: break-all;\r\n    align-content: space-around;\r\n    line-height: 2;\r\n}\r\n\r\n.ag-theme-alpine .ag-cell{\r\n    border-right: solid 1px #e9e8e8;\r\n    border-collapse: collapse;\r\n    align-content: space-around;\r\n}\r\n\r\n.bbs .ag-header-cell-text{\r\n    font-size: 0.8rem;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
