import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi} from '../../redux-tookit/slices/sdbrSlice';
import GenericTable from '../../components/GenericTable';
import Button from '../../utils/Button';
import '../../assets/styles/TpmRate.css'

const DiffFactFormulae= () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.diffFactFormulae.columns);
  const data = useSelector((state) => state.sdbr.diffFactFormulae.data);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRoute, setSelectedRoute] = useState('Coil'); 

  const handleRowSelect = (rowIndex) => {
    console.log(data);
    setSelectedRowIndex(rowIndex);
  };


  const filteredData = data.filter((item) => item.route === selectedRoute);
  const filteredColumns = columns.filter((column) => column.key !== 'Route');


  useEffect(() => {
   
    dispatch(fetchDataFromApi('diffFactFormulae')); 
  }, [dispatch]);


  return (
    <div className='sdbr'>
      <div className='head'>Diff Fact Formulae</div>
      <div className="sub-data">
        <label htmlFor="route" style={{ paddingRight: "10px" }}>CCR:</label>
        <select
          name="route"
          id="route"
          onChange={(e) => setSelectedRoute(e.target.value)}
          value={selectedRoute}
        >
          <option value="Coil">Coil Route</option>
          <option value="Rebar">Rebar Route</option>
        </select>
      </div>
      <div>
        
        <GenericTable columns={filteredColumns} data={filteredData} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
        {selectedRowIndex!==null?<div className='Buttons'><Button label='Save' className='blue'/><Button label="Cancel" className='blue' onClick={()=>setSelectedRowIndex(null)}/></div>:""} 

      </div>
    </div>
  );
};

export default DiffFactFormulae;