import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '../../../assets/styles/orderDetails.css'
import GenericTable from '../../../components/GenericTable';
import { fetchDataFromApi } from '../../../redux-tookit/slices/sdbrSlice';
import Button from '../../../utils/Button';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import refresh from '../../../assets/images/button-icons/Refresh.svg';
import excel from '../../../assets/images/button-icons/excel.svg';
import { Link, useNavigate } from 'react-router-dom';
import FilterComponent from '../../../components/FilterComponent';
import BBSExcel from '../../../components/BBSExcel';

const OrderDetailsDiaWise = () => {
  const dispatch = useDispatch();
  const navigate=useNavigate();
  const columns = useSelector((state) => state.sdbr.orderDetailsDiaWise.columns);
  const originalData = useSelector((state) => state.sdbr.orderDetailsDiaWise.data);
  const [data, setData] = useState(originalData);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [orderId,setOrderId]= useState('');
  const[popUp,setPopUp]=useState(false);
  const [sapId,setSapId]= useState('');

  const [soldToPartyFilter, setSoldToPartyFilter] = useState('');
  const [orderStatusFilter, setOrderStatusFilter] = useState('');
  const [offerFilter, setOfferFilter] = useState('');
  const [orderRegionFilter, setOrderRegionFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [orderTypeFilter, setOrderTypeFilter] = useState('');
  const [filterButton, setFilterButton] = useState('Close')


  const soldToPartyValues = [...new Set(originalData.map(item => item.soldToParty))];
  const orderStatusValues = [...new Set(originalData.map(item => item.bbsStatus))];
  const offerValues = [...new Set(originalData.map(item => item.offer))];
  const orderRegionValues = [...new Set(originalData.map(item => item.orderRegion))];
  const orderTypeValues = [...new Set(originalData.map(item => item.orderType))];

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
    setOrderId(originalData[rowIndex].orderId);
    setSapId(originalData[rowIndex].sapId);
  };

  const onFilterClick = () => {
    filterButton === 'Filter' ? setFilterButton('Close') : setFilterButton('Filter');
  }

  const applyFilters = () => {
    let filteredData = originalData;

    if (soldToPartyFilter) {
      filteredData = filteredData.filter((item) =>
        item.soldToParty.includes(soldToPartyFilter)
      );
    }

    if (orderStatusFilter) {
      filteredData = filteredData.filter((item) =>
        item.bbsStatus === orderStatusFilter
      );
    }

    if (offerFilter) {
      filteredData = filteredData.filter((item) =>
        item.offer.includes(offerFilter)
      );
    }

    if (orderRegionFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderRegion.includes(orderRegionFilter)
      );
    }
    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.orderDate);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }

    if (orderTypeFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderType === orderTypeFilter
      );
    }

    setData(filteredData);
  };
  const [f,setF]=useState(false);
const handleRefresh=()=>{
  setF(!f);
};
  useEffect(() => {
    dispatch(fetchDataFromApi('orderDetailsDiaWise')); 
    setData(originalData);
    
  }, [dispatch,originalData,f]);
  useEffect(() => {
    applyFilters();
  }, [soldToPartyFilter, orderStatusFilter, offerFilter, orderRegionFilter, fromDateFilter, toDateFilter, orderTypeFilter]);
  const openBBSExcel=()=>{
    setPopUp(true);
  }
  const closeBBSExcel=()=>{
    setPopUp(false);
  }
  return (
    <div className='sdbr'>
      <Button label={filterButton} onClick={onFilterClick} className='blue' />
      <div className="Filter-drop" style={{ display: `${filterButton === 'Close' ? `block` : `none`}` }}>
        <div className="filter-card">
          <div className="filter-layout">
            <FilterComponent
              label="Sold To Party"
              filterValue={soldToPartyFilter}
              options={soldToPartyValues}
              onFilterChange={setSoldToPartyFilter}
            />
            <FilterComponent
              label="Order Status"
              filterValue={orderStatusFilter}
              options={orderStatusValues}
              onFilterChange={setOrderStatusFilter}
            />
            <FilterComponent
              label="Offer"
              filterValue={offerFilter}
              options={offerValues}
              onFilterChange={setOfferFilter}
            />
            <FilterComponent
              label="Order Region"
              filterValue={orderRegionFilter}
              options={orderRegionValues}
              onFilterChange={setOrderRegionFilter}
            />
            <FilterComponent
              label="Order Type"
              filterValue={orderTypeFilter}
              options={orderTypeValues}
              onFilterChange={setOrderTypeFilter}
            />
            <div></div>
          </div>

          <div className="filter-bottom">
            <div className='DateCont'>
              <label className='DateHead'>Order Delivery Date</label>
              <div className="DateFilt">
                <div className="filterD">
                  <label htmlFor="fromDateFilter">From</label>
                  <input
                    id="fromDateFilter"
                    type="date"
                    value={fromDateFilter}
                    onChange={(e) => setFromDateFilter(e.target.value)}
                  />
                </div>
                <div className="filterD">
                  <label htmlFor="toDateFilter">To</label>
                  <input
                    id="toDateFilter"
                    type="date"
                    value={toDateFilter}
                    onChange={(e) => setToDateFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>

           
          </div>


        </div>
      </div>

      <div className='head'>
        <div> Order Details</div>
        <div className='top-button-container'>
          <Link to="/add-new">
            <img src={addnew} alt="" />
          </Link>
          <Link to="/add-new">
            <img src={edit} alt="" />
          </Link>
          <img src={refresh} alt="" onClick={handleRefresh}/>
          <Link to="/">
            <img src={excel} alt="" width={17} height={18}/>
          </Link>
        </div>
        
      </div>
      <GenericTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
      <div className="bottom-button-container">
        <Button label="BBS" className="blue" />
        <Button label="BBS Excel" className="blue" onClick={openBBSExcel}/>
        <Button label="Summary" className="blue" onClick={()=>navigate(`/summary/${orderId}`)}/>
        <Button label="Elements" className="blue" onClick={()=>navigate(`/element/${orderId}`)}></Button>
        <Button label="SAP Order Summary" className="blue" onClick={()=>navigate(`/sap summary/${sapId}`)}/>
        <Button label="Order Cost" className="blue" />
        <BBSExcel isOpen={popUp} onClose={closeBBSExcel} orderId={orderId} />
      </div>
    </div>
  );
};

export default OrderDetailsDiaWise;
//End of File