import React, { useState, useEffect } from 'react'

const CanvasTable = ({ data, onInputChange, onDataChange }) => {
  const [drawingElements, setInternalData] = useState(data);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const randArray = [0];

  useEffect(() => {
    setInternalData(data);
  }, [data]);

  const handleInputChange = (index, header, event) => {
    const newData = [...drawingElements];
    newData[index][header] = event.target.value;
    setInternalData(newData);
    setShouldSubmit(true);
  };


  const handleBlur = () => {
    if (shouldSubmit) {
      onDataChange(drawingElements);
      setShouldSubmit(false);
    }
  };

  const handleCoordsChange = (tx, ty , index, header) => {
    let coords = tx+','+ty;
    const newData = JSON.parse(JSON.stringify(drawingElements));
    newData[index][header] = coords;
    onDataChange(newData);
  }


  let elementType = [];
  return (
    <div className='table-container shape'>
      {!drawingElements || drawingElements.length === 0 ? (<div>No drawing elements to display</div>) : (
        <table className='generic-table'>
          <thead className='stick'>
            <tr><th className='table-header'>Sl.No</th>
              {Object.keys(drawingElements[0]).map((header) => (
                <th key={header} className='table-header'>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {drawingElements.map((element, index) => (
              <tr key={index} className='t-data'><td>{index + 1}</td>
                {Object.keys(element).map((header) => (
                  <td key={header}>
                    {header === 'Type' ? (
                      /// Create the Type dropdown with options
                      <select
                        value={element[header]}
                        onChange={(event) => onInputChange(index, header, event.target.value)}
                      >{
                          element.Geometry === 'Length' ? elementType = ['Actual', 'Normal', 'Offset', 'Offset + Normal'] :
                            element.Geometry === 'Arc' ? elementType = ['Rad+ArcLength', 'Rad+Sw_Angle', 'Dia+ArcLength', 'Dia+Sw_Angle', 'Chord+Normal', 'Others'] :
                              element.Geometry === 'Angle' ? elementType = ['Clockwise', 'AntiClock'] :
                                elementType = ['Half Thread', 'Full Thread', 'Coupler B']}
                        {elementType.map((optionValue) => (
                          <option key={optionValue} value={optionValue}>
                            {optionValue}
                          </option>
                        ))}
                      </select>
                    ) : header === 'Geometry' ? (element.Geometry) : (element.Geometry === 'Length' && header === 'Capt1') || (element.Geometry === 'Length' && element.Type === 'Offset + Normal' && header === 'Capt2') || (element.Geometry === 'Angle' && header === 'Capt2') ? (<select value={element[header]}
                      onChange={(event) => onInputChange(index, header, event.target.value)}>{elementType = ['Yes', 'No']}{elementType.map((optionValue) => (
                        <option key={optionValue} value={optionValue}>
                          {optionValue}
                        </option>
                      ))}</select>) :
                      (header === 'Capt3' ?
                        (
                          [0].map((value) => {

                            let tx=0, ty=0;

                            if(element[header].includes(',')){
                              let [tempx, tempy] = element[header].split(',');
                              tx += tempx*1;
                              ty += tempy*1
                            }
                              
                            console.log(tx, ty);

                            return <>
                              <input
                                type="number"
                                value={tx}
                                style={{width:'40px'}}
                                onChange={(event) => handleCoordsChange(event.target.value, ty, index, header)}
                              />
                              <input
                                type="number"
                                value={ty}
                                style={{width:'40px'}}
                                onChange={(event) => handleCoordsChange(tx, event.target.value, index, header)}
                              />
                            </>
                          })

                        ) : (
                          // Create an input field for other columns
                          <input
                            type="text"
                            value={element[header]}

                            onChange={(event) => handleInputChange(index, header, event)}
                            onBlur={() => handleBlur()}
                          />
                        )
                      )

                    }
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  )
};

export default CanvasTable