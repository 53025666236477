import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/API"; // Import your Axios API functions
import "react-toastify/dist/ReactToastify.css";
const initialState = {
  isLoading: false,
  data: [],
  error: null,
};

export const fetchCubeIssues = createAsyncThunk("cubeIssue/list", async () => {
  try {
    const response = await api.cubeIssueList();
    const json = response.data.rows;
    console.log(json, "json");
    return json;
  } catch (error) {
    console.log("error");
    throw error;
  }
});

const getAllSliceCubeIssues = createSlice({
  name: "getAll",
  initialState: initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchCubeIssues.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchCubeIssues.fulfilled, (state, action) => {
        console.log(action.payload, "action payload");
        state.isLoading = false;
        state.data = action.payload;
        state.error = null;
      })
      .addCase(fetchCubeIssues.rejected, (state, action) => {
        console.log(action);
        state.isLoading = false;
        state.error = action.error.message;
      });
  },
});

export default getAllSliceCubeIssues.reducer;
//End of File
