
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
    columns: {},
    planColumns: {},
    labeledData: [],
    planList: [],
    loading: 'idle',
    fetcher: true,
    error: null,
};

export const fetchTempPlan = createAsyncThunk('data/fetchTempPlan', async () => {
    try {
        const response = await api.listTempPlan();
        const json = response.data;
        const columnLabels = {
            "planId": { label: ' Id' },
            "soldToParty": { label: 'Customer Name' },
            "orderId": { label: 'order Id' },
            "combId": { label: 'Order Id' },
            "planCombId": { label: 'Plan Id' },

            "planDate": { label: 'planDate' },
            "productionWeight": { label: 'Production Weight' },
            "offcutWithRebar": { label: 'Offcut with Rebar' },
            "offcutWithCoil": { label: 'Offcut with Coil' },
            "scrapWithRebar": { label: 'Scrap with Rebar' },
            "scrapWithCoil": { label: 'Scrap with Coil' },
            "offcutPercentWithRebar": { label: 'Offcut Percent with Rebar' },
            "offcutPercentWithCoil": { label: 'Offcut Percent with Coil' },
            "scrapPercentWithRebar": { label: 'Scrap percent with Rebar' },
            "scrapPercentWithCoil": { label: 'Scrap percent with Rebar' }
        };
        const selectedColumns = [
            'planId',
            'combId',
            'planCombId',
            'soldToParty',
            'orderId',
            'planDate',
            "productionWeight",
            "offcutWithRebar",
            "offcutWithCoil",
            "scrapWithRebar",
            "scrapWithCoil",
            "offcutPercentWithRebar",
            "offcutPercentWithCoil",
            "scrapPercentWithRebar",
            "scrapPercentWithCoil"
        ];

        const columns = {};

        selectedColumns.forEach((columnName) => {
            columns[columnName] = columnLabels[columnName];
        });

        const selectedPlanColumns = ['planId', 'planCombId', 'combId', 'productionWeight', 'planDate']

        const planColumns = {};

        selectedPlanColumns.forEach((columnName) => {

            planColumns[columnName] = columnLabels[columnName];
        });

        const labeledData = json.rows.map((row) => {
            const rowData = {};
            selectedColumns.forEach((columnName) => {
                // if (columnName === 'soldToParty') {
                //     // const id = JSON.parse(row.orderId)
                //     // console.log(id);
                //     response2.data.rows.map((data) => {
                //         if (row.orderId * 1 === data.orderId * 1) {
                //             rowData[columnName] = data.soldToParty
                //         }
                //     }
                //     )
                // } else
                if (columnName === 'planDate') {
                    let [dat] = row[columnName].split('T');
                    let [year, month, day] = dat.split('-')

                    rowData[columnName] = `${day}-${month}-${year}`;
                } else
                    rowData[columnName] = row[columnName];
            });


            return rowData;
        });

        return { columns, labeledData, planColumns };
    } catch (error) {
        throw error;
    }
});

export const postTempPlan = createAsyncThunk('data/postTempPlan', async (newRow) => {
    try {
        const response = await api.addServiceCenter(newRow);
        const data = response.data;
        console.log('Success');
        return data;
    } catch (error) {
        throw error;
    }
});

export const updateTempPlan = createAsyncThunk('data/updateTempPlan', async ({ id, type }) => {
    try {
        console.log(id);
        const response = await api.updateServiceCenter(id, type);
        const data = response.data;
        console.log('Update Success');
        return { data, id, type };
    } catch (error) {
        throw error;
    }
});

const tempPlanSummarySlice = createSlice({
    name: 'serviceCenter',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchTempPlan.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchTempPlan.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.columns = action.payload.columns;
                state.labeledData = action.payload.labeledData;
                state.planColumns = action.payload.planColumns;
            })
            .addCase(fetchTempPlan.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(postTempPlan.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(postTempPlan.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.labeledData.push(action.payload);
                toast.success('Plan added successfully!');
            })
            .addCase(postTempPlan.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to add Plan');
            })
            .addCase(updateTempPlan.pending, (state) => {
                state.loading = 'pending';

            })
            .addCase(updateTempPlan.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.fetcher = !state.fetcher;

                // const index = state.labeledData.find(item => item.serviceCenterId === action.payload.id);
                // if (index) {
                // 	const updatedItem = { serviceCenterId: action.payload.id, ...action.payload.type };
                // 	console.log(updatedItem);
                // 	state.labeledData = state.labeledData.map(item => {
                // 		if (item.serviceCenterId === action.payload.id) {
                // 			return updatedItem;
                // 		}
                // 		return item;
                // 	})

                // }
                toast.success('Plan updated sucessfully');
            })
            .addCase(updateTempPlan.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to update Plan');
            })

    },
});

export default tempPlanSummarySlice.reducer;
//End of File

