import React, { useState } from "react";
import "../../../assets/styles/GenericTable.css";

const ElementCopyTable = ({ data, handleNameChange }) => {
  const columns1 = ["oldName", "orderItemName", "noOfSets", "noOfTags"];

  const headerColumns = [
    "Old Element Name",
    "New Element Name",
    "No. of Sets",
    "No. of Tags",
  ];

  return (
    <div
      className="table-container"
      style={{ width: "100%", minHeight: "200px", maxHeight: "200px" }}
    >
      <table className="generic-table" style={{ width: "100%" }}>
        {data.length !== 0 ? (
          <>
            <thead className="stick">
              <tr>
                {headerColumns.map((value) => (
                  <th className="table-header">{value}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr
                  className="t-data"
                  style={{ borderBottom: "1px solid grey" }}
                >
                  {columns1.map((data) =>
                    data === "orderItemName" ? (
                      <td>
                        <input
                          type="text"
                          value={row[data]}
                          onChange={(e) =>
                            handleNameChange(e.target.value, rowIndex)
                          }
                        />
                      </td>
                    ) : (
                      <td>{row[data]}</td>
                    )
                  )}
                </tr>
              ))}
            </tbody>
          </>
        ) : (
          "No Elements copied"
        )}
      </table>
    </div>
  );
};

export default ElementCopyTable;
//End of File
