import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { deletePartner, fetchPartners, postPartners } from '../../../redux-tookit/slices/partnerSlice';
import { fetchOrganizations } from '../../../redux-tookit/slices/organizationSlice';
import GenericTable1 from '../../../components/GenericTable1';
import AddPartner from './AddPartner';
import AGTable from '../../../components/AGTable';
import {  toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
 
const Partner = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const permission = useState(location.state?.permission);
    const columns = useSelector((state) => state.partner.columns);
    const data = useSelector((state) => state.partner.labeledData);
    const fetcher = useSelector((state)=>state.partner.fetcher)
    const [editData, setEditData] = useState([]);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [isAdding, setIsAdding] = useState(false);

    console.log(permission);

    const handleRowSelect = (rowIndex) => {
        setSelectedRowIndex(rowIndex);
    };
    
    const handleAddButtonClick = () => {
        setIsAdding(true);
    };  
    const handleEditButtonClick = () => {
        if (selectedRowIndex !== null&&selectedRowIndex!==undefined) { 
            setIsEditing(true); 
            setEditData(data[selectedRowIndex]);
          }else toast.error("No rows selected, Please select a row and try again");setSelectedRowIndex(null);
      };
    useEffect(() => {
        dispatch(fetchPartners()).then(()=>dispatch(fetchOrganizations()));
    }, [dispatch,isAdding,isEditing,fetcher]);

    const handleDeleteButtonClick=async()=>{
        if (selectedRowIndex !== null&&selectedRowIndex!==undefined) {
            const id=data[selectedRowIndex].partnerId;
            console.log(id);
            dispatch(deletePartner(id));
        }
        else toast.error("No rows selected, Please select a row and try again");
        setSelectedRowIndex(null);
    };
 
    return (
        <div className='sdbr'>
            <div className='head'>Partner
                <div className="top-button-container">
                    <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]}/>
                </div>
            </div>
 
            <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
            <AddPartner isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} />
           
        </div >
    );
};
 
export default Partner;