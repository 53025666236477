import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi} from '../../redux-tookit/slices/sdbrSlice';
import GenericTable from '../../components/GenericTable';
import edit from '../../assets/images/button-icons/edit.svg';
import addnew from '../../assets/images/button-icons/Vector.svg';
import '../../assets/styles/Costing.css'

const Costing = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.pricingMaster1.columns);
  const columns2 = useSelector((state) => state.sdbr.pricingMaster2.columns); 
  const originalData = useSelector((state) => state.sdbr.pricingMaster1.data);
  const originalData2 = useSelector((state) => state.sdbr.pricingMaster2.data);
  const [data, setData] = useState(originalData);
  const [data2, setData2] = useState(originalData2);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRowIndex2, setSelectedRowIndex2] = useState(null);

  const [cellData,setCellData] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleInputChange = (index, header, event) => {
    const newData = [...data];
    newData[index][header] = event.target.value;
    setCellData(newData);
    setShouldSubmit(true);
    
  };

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };
  

  useEffect(() => {
    
    dispatch(fetchDataFromApi('pricingMaster1')); 
    dispatch(fetchDataFromApi('pricingMaster2'));
    setData(originalData);
    setData2(originalData2);
    console.log(data2);
  }, [dispatch,originalData,originalData2]);

  return (
    <div>
      <div className="sdbr">
        <div className="head">Pricing Master
          <div className="top-button-container">          
            <img src={edit} alt="edit" />
          </div>
        </div>
        <div className='cosTable-container'>
        <table className='generic-table2'>
          <thead >
            <tr className='table-header'>
              <th></th>
              <th>Scrap<sup>[1]</sup></th>
              <th>Offcut<sup>[1]</sup></th>
              <th>Coil Route<sup>[2]</sup></th>
              <th>Rebar Route<sup>[2]</sup></th>
              <th>Miscellaneous<sup>[1]</sup></th>
            </tr>
          </thead>
          <tbody className='CostT'>
          {data2.map((row, rowIndex) => (
            <tr key={rowIndex}>
              <td>
                Cost per unit. (INR)
              </td>
              {columns2.map((column) => (
                column.editable===true&&selectedRowIndex2===rowIndex?
                <td key={column.key}>
                  <input type="text" value={row[column.key]} onChange={(event)=> handleInputChange(rowIndex, column.key ,event)} style={{ width: '50px' }} />
                </td>:
                <td key={column.key}>{row[column.key]}</td>
              ))}
            </tr>
          ))}
          <tr className='table-header'>
            <td>Unit: [1] Kg. [2] Difficulty factor</td>
            {Array.from({ length: 5 }).map((_, index) => (
              <td></td>))}
          </tr>
          </tbody>
        </table>
        </div>
        <div></div>
        <div className="costEd">
          <div className="top-button-container">
            <img src={addnew} alt="add" />          
            <img src={edit} alt="edit" />
          </div>
       </div>
        <GenericTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex}/>
      </div>
    </div>
  )
}

export default Costing