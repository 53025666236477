import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { dateChanger } from '../../components/reusableFunctions';
import { resetState } from './loginSlice';

const initialState = {
  columns: {},
  labeledData: [],
  loading: 'idle',
  fetcher: true,
  error: null,
  orderId: null,
};


export const fetchOrder = createAsyncThunk('data/fetchOrder', async (params='') => {
  try {

    const response = await api.listOrder(params);

    const json = response.data;

    const columnLabels = {
      orderId: { label: "Id", type: 'number', isRequired: true, editable: false },
      combId: { label: "Order Id", type: 'number', isRequired: true, editable: false },
      orderRemarks: { label: "Order Desc", type: 'number', isRequired: true, editable: false },
      sapId: { label: "SAP Id", type: 'text', isRequired: true, editable: true },
      soldToParty: { label: "Sold to Party", type: 'text', isRequired: true, editable: true },
      soldToPartyId: { label: "Sold to Party Id", type: 'text', isRequired: true, editable: true },
      soldToPartAddress: { label: 'Sold to Party Address', type: 'text' },
      customerExpectedDeliveryDate: { label: "Cust req date", type: 'date', isRequired: true, editable: true },
      deliveryDate: { label: "Delv Date", type: 'date', isRequired: true, editable: true },
      // actualDeliveryDate:{},
      productionWeight: { label: "Prod Wt (T)", type: 'text', isRequired: true, editable: true },
      inventoryWeight: { label: "Inv Wt (T)", type: 'text', isRequired: true, editable: true },
      // weightbridgeWeight:{},
      // balanceQuantity:{},
      // orderStatus:{},
      bbsRemarks: { label: "BBS Remarks", type: 'text', isRequired: true, editable: true },
      bbsStatus: { label: "BBS Status", type: 'text', isRequired: true, editable: true },
      // orderRegion:{},
      // customerReqDate:{},
      productionStartDate: { label: "Production Start Date", type: 'text', isRequired: true, editable: true },
      productionEndDate: { label: "Production End Date", type: 'text', isRequired: true, editable: true },
      // actualRelDate:{},
      poDate: { label: "PO Conf Date", type: 'date', isRequired: true, editable: true },
      orderType: { label: "Order Type", type: 'text', isRequired: true, editable: true },
      totalNoOfTags: { label: "No of Tags", type: 'text', isRequired: true, editable: true },
      billToParty: { label: "Bill to Party", type: 'text', isRequired: true, editable: true },
      billToPartyId: { label: "Bill to Party Id", type: 'text', isRequired: true, editable: true },
      shipToParty: { label: "Ship to Party", type: 'text', isRequired: true, editable: true },
      shipToPartyId: { label: "Ship to Party Id", type: 'text', isRequired: true, editable: true },
      address: { label: "Delv Address", type: 'text', isRequired: true, editable: true },
      offers: { label: "Offer", type: 'text', isRequired: true, editable: true },


      diffFactor: { label: "Difficulty Factor", type: 'text', isRequired: true, editable: true },
      createdTs: { label: "Order Date", type: 'date', isRequired: true, editable: true },
      // deliveryAddress:{},
      // rebarPrice:{},
      // deltaPrice:{},
      customerSegmentId: { label: "Customer Segment Id ", type: 'number' },
      customerSegmentName: { label: "Customer Segment", type: 'text' },

      //REST

      // customerId: { label: "Customer Id", type: 'text', isRequired: true, editable: true },
      // customerType: { label: "Customer Type", type: 'text', isRequired: true, editable: true },
      customerPartiesId: { label: "Customer Parties Id", type: 'text' },

    };

    const selectedColumns = ['orderId', 'combId', 'orderRemarks', 'sapId', 'soldToParty', 'soldToPartyId', 'soldToPartAddress', 'billToPartyId', 'customerExpectedDeliveryDate', 'deliveryDate', 'productionWeight', 'inventoryWeight', 'bbsRemarks', 'bbsStatus', 'productionStartDate','productionEndDate', 'poDate', 'orderType', 'totalNoOfTags', 'billToParty', 'shipToParty', 'address', 'diffFactor', 'customerSegmentName', 'customerSegmentId', 'createdTs', 'shipToPartyId', 'customerPartiesId','offers'];

    const showColumns = [
      'combId', 
      'orderRemarks', 
      'sapId', 
      'soldToParty', 
      'createdTs',
      'deliveryDate', 
      'productionWeight', 
      'inventoryWeight', 
      'bbsRemarks',
      'bbsStatus', 
      'customerExpectedDeliveryDate', 
      'productionStartDate',
      'productionEndDate', 
      'poDate', 
      'orderType', 
      'totalNoOfTags', 
      'billToParty', 
      'shipToParty', 
      'address', 
      'customerSegmentName', 
    ];

    const columns = {};
    showColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        if (columnName === 'createdTs'||columnName ==='productionStartDate'|| columnName ==='productionEndDate') {
          let [dat] = row[columnName].split('T');
          rowData[columnName] = dateChanger(dat);
        } else if (columnName === 'productionWeight' || columnName === 'inventoryWeight') {
          rowData[columnName] = Math.floor(row[columnName]*1000)/1000;
        } else
          rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    return { columns, labeledData };
  } catch (error) {
    console.log('ERROR')
    throw error;
  }
});
export const postOrder = createAsyncThunk('data/postOrder', async (newRow) => {
  try {
    console.log(newRow);
    const response = await api.addOrder(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const updateOrder = createAsyncThunk('data/updateOrder', async ({ id, type }, sdbr = false) => {
  try {
    console.log(type);
    const response = await api.updateOrder(id, type)
    // .then(res=>{
    //   if(res.payload!==undefined){
    //     if(sdbr===true){
    //       toast.success('Delivery Date updated sucessfully');
    //     } else {
    //       toast.success('Order updated sucessfully');
    //     }

    //   }
    // })

    const data = response.data;
    console.log('Update Success');
    return { data, id, type };
  } catch (error) {
    throw error;
  }
});

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetState, () => initialState)
      .addCase(fetchOrder.pending, (state, action) => {
        state.loading = 'pending';
      })
      .addCase(fetchOrder.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchOrder.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(postOrder.pending, (state) => {
        state.loading = 'pending';
        console.log('pen')
      })
      .addCase(postOrder.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.orderId = action.payload.orderId;
        state.labeledData.push(action.payload);
        console.log(action.payload);
        toast.success('Order added successfully!');
      })
      .addCase(postOrder.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error(state.error);
      })
      .addCase(updateOrder.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateOrder.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
        toast.success('Order updated sucessfully');
      })
      .addCase(updateOrder.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update Order');
      });
  },
});

export default orderSlice.reducer;
//End of File