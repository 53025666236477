import React, { useState, useRef, useEffect } from 'react';
import dial from '../../assets/images/icons/canvas/seek-dial.svg'
import plus from '../../assets/images/icons/canvas/magnifying-glass-plus.svg'
import minus from '../../assets/images/icons/canvas/magnifying-glass-minus.svg'
import auto from '../../assets/images/icons/canvas/seek-center.svg'
import up from '../../assets/images/icons/canvas/seek-top.svg'
import down from '../../assets/images/icons/canvas/seek-down.svg'
import left from '../../assets/images/icons/canvas/seek-left.svg'
import right from '../../assets/images/icons/canvas/seek-right.svg'
import Angle from '../../assets/images/icons/canvas/Bend.svg'
import Arc from '../../assets/images/icons/canvas/Arc.svg'
import AntiArc from '../../assets/images/icons/canvas/Anti-Arc.svg'
import Coupler from '../../assets/images/icons/canvas/C.svg'
import Undo from '../../assets/images/icons/canvas/Undo.svg'
import ClearCanvas from '../../assets/images/icons/canvas/Delete-X.svg'
import '../../assets/styles/CanvasDraw.css'
import Button from '../../utils/Button';
import { useDispatch } from 'react-redux';
import { fetchShapes, postShapes, updateShapes } from '../../redux-tookit/slices/shapeSlice';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import springShape from '../../assets/shapes/spring.jpg'


const BBSDraw = (props) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { drawingElements, addNew, delshape, clearShapes, selectedShape = '', bbs = false, converter = false, changer, saveInit, update, setBbsCanvasRef, orginX, setOrginX, orginY, setOrginY, mF, setMF, imageCapture, constantArray, constantLengthValue } = props;
    const canvasRef = useRef(null);
    const ctxRef = useRef(null);
    const [fontSize, setFontSize] = useState(25)
    let count = 0;
    const [eff, setEff] = useState(0);



    //////////////////////////////////////////////////////////////////////////////////////////////

    // let constantLengthValue = []
    // console.log(constantLengthValue);
    // useEffect(()=>{

    //     constantLengthValue = drawingElements

    // },[])

    //////////////////////////////////////////////////////////////////////////////////////////////


    // const [constantArray, setConstantArray] = useState()

    // const copyData = drawingElements;

    console.log(orginX, orginY, mF);

    const randomDraw = () => {
        draw(drawingElements);
    }

    // console.log(drawingElements);

    useEffect(() => {

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // ctx.scale(1, 1);
        ctxRef.current = ctx;
        ctx.lineWidth = 5;
        ctx.font = `bold ${fontSize}px Arial`;

        randomDraw();
        if (bbs === true) {
            setBbsCanvasRef(canvas)
        }

    }, [drawingElements, eff, changer, saveInit, fontSize]);

    useEffect(() => {
        if (converter === true) {
            // setBbsCanvasRef(canvas)
            // panButtons(0, 0, 0, 0, 2)
        }
    }, [drawingElements])

    let centerX = 0;
    let centerY = 0;

    let currentX = orginX;
    let currentY = orginY;
    let startAngle = 0, endAngle = 0, arcAngle = Math.PI;
    let direction = 0, pie = Math.PI;
    let endX = currentX, endY = currentY, x = 1, length = 100, textCount = 1, textNo;

    const [isCouplerButtonDisabled, setIsCouplerButtonDisabled] = useState(false);
    const [isRestButtonDisabled, setIsRestButtonDisabled] = useState(false);
    const [isLineButtonDisabled, setIsLineButtonDisabled] = useState(false);
    const [isAngleButtonDisabled, setIsAngleButtonDisabled] = useState(false);
    const [lineCounter, setLc] = useState(1);
    const [l1, setl1] = useState(100);
    const [l2, setl2] = useState(100);
    const [minX, setMinX] = useState(100);
    const [minY, setMinY] = useState(147);
    const [maxX, setMaxX] = useState(100);
    const [maxY, setMaxY] = useState(147);

    // console.log(mF, orginX, orginY);

    const [shapeId, setShapeId] = useState(selectedShape);

    // console.log({ minX, minY, maxX, maxY, mF });
    // console.log({ orginX, orginY });


    const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@");
        if (drawingElements.length === 0) {
            toast.error("Please draw a shape");
            return;
        }

        if (shapeId === '') {
            toast.error("Please enter the Shape Id");
            return;
        }

        let bendCou = 0;
        let arcCh = false;

        let noOfBends = 0;
        let threads = 0

        drawingElements.forEach((el, index) => {
            if (el.Geometry === 'Arc') { arcCh = true; }
            else if (el.Geometry === 'Angle' && index !== 0) { bendCou = bendCou + 1; }

            if (el.Geometry === 'Coupler') {
                threads++;
            }
        })

        drawingElements.forEach((el, index) => {
            if (el.Geometry === 'Arc') {
                if (el.Angle === 360 || el.Angle === -360) {
                    noOfBends = noOfBends + 6;
                }
                else if (el.Angle === -180 || el.Angle === 180) {
                    noOfBends = noOfBends + 7
                }
            }
            else if (el.Geometry === 'Angle' && index !== 0) { noOfBends = noOfBends + 1; }
        })

        let ShapeCat = ''

        if (bendCou > 4) {
            ShapeCat = `multi bend ${arcCh === true ? 'with' : 'without'} arc`
        } else { ShapeCat = (`${bendCou} bend ${arcCh === true ? 'with' : 'without'} arc`) }

        // console.log("$$$$$$", ShapeCat, drawingElements);

        const canvas = canvasRef.current;

        const dataURL = canvas.toDataURL('image/png');

        // setImageURL(JSON.stringify(dataURL));
        // console.log(dataURL);


        const shapesObj = {
            shapeId: shapeId,
            category: ShapeCat,
            imageProperties: JSON.stringify(drawingElements),
            image: dataURL,
            noOfBends: noOfBends,
            magnifications: `${orginX},${orginY},${Math.floor(mF * 100000) / 100000}`,
            threads: threads
        }

        const id = update, type = shapesObj;
        if (update === false)
            dispatch(postShapes(shapesObj)).then((res) => {
                if (res.payload !== undefined) {
                    dispatch(fetchShapes('?category=0 Bend Without Arc'))
                        .then(() => {
                            setTimeout(() => {
                                navigate('/shapes');
                            }, 1000)

                        });
                }
            })
        else dispatch(updateShapes({ id, type })).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchShapes('?category=0 Bend Without Arc'))
                    .then(() => {
                        setTimeout(() => {
                            navigate('/shapes');
                        }, 1000)

                    });
            }
        })
        // console.log(update);
        // console.log(shapesObj);
    }



    const drawLine = (constantLength, l, lineCase, CapLet, Capt3) => {

        let [CapUse, Letter] = CapLet.split('-');

        const ctx = ctxRef.current;
        let ogLength = l;
        l *= mF;
        // lineCase===0?length=l:lineCase===1?(length=l/Math.sin(-direction)):length=l/Math.cos(-direction);//convert this ternary operation into if else statement.
        if (lineCase === 0) {
            // length = l;
            // console.log(bbs);
            // if (bbs === true)
            length = (constantLength * mF)
        } else
            if (lineCase === 1) {
                (length = l / Math.sin(-direction));
                if (bbs === true) {
                    length = length / l * constantLength * mF
                    l = constantLength * mF
                }
                dashDraw(l, currentX, currentY, currentX, currentY + l / l * l);
            } else {
                (length = l / Math.cos(-direction));
                if (bbs === true) {
                    length = length / l * constantLength * mF
                    l = constantLength * mF
                }
                dashDraw(l, currentX, currentY, currentX + l / l * l, currentY)
            }

        endX = currentX + length * Math.cos(direction);
        endY = currentY - length * Math.sin(direction);

        ctx.beginPath();
        ctx.moveTo(currentX, currentY);
        ctx.lineTo(endX, endY);
        ctx.stroke();
        direction % (2 * pie) < 0 && direction % (2 * pie) >= -pie ? x = -1 : x = 1;
        const midX = (currentX + endX) / 2;
        const midY = (currentY + endY) / 2;
        let textX = midX + 25 / (1 + (1 - x) / 20) * Math.cos(((1 - x) / 2 * pie + direction - pie / 2));
        let textY = midY - 25 / (1 + (1 - x) / 20) * Math.sin(((1 - x) / 2 * pie + direction - pie / 2));

        if (Capt3 !== 'No' && Capt3.includes(',')) {
            let [tx, ty] = Capt3.split(',');
            console.log(tx, ty);
            if (tx === '')
                tx = 0;
            if (ty === '')
                ty = 0;
            if (tx) {
                textX += tx * 1;
            }
            if (ty) {
                textY -= ty * 1
            }
            if (tx < 0 && (bbs)) {
                textX -= `${ogLength}:${Letter}`.length * (fontSize / 5) * 2
                console.log(textNo);
            }
        }

        if (CapUse === 'Yes') {
            if (Letter === '') {
                textNo = String.fromCharCode(64 + textCount);

            }
            else if (bbs === false) textNo = Letter;
            else if (saveInit === false) textNo = ogLength + ":" + Letter;
            else textNo = ogLength;
            ctx.fillStyle = 'Black';
            ctx.fillText(textNo, textX, textY);
        }

        textCount++;

        if (currentX < minX) { setMinX(currentX); }
        if (currentY < minY) { setMinY(currentY); }
        if (currentX > maxX) { setMaxX(currentX); }
        if (currentY > maxY) { setMaxY(currentY); }

        currentX = endX;
        currentY = endY;

        if (endX < minX) { setMinX(endX); }
        if (endY < minY) { setMinY(endY); }
        if (endX > maxX) { setMaxX(endX); }
        if (endY > maxY) { setMaxY(endY); }


        count++;

    };

    const drawCoupler = (sS, n) => {
        const ctx = ctxRef.current;
        for (let i = 0; i < n; i++) {
            let x1 = currentX + 10 * Math.cos(direction - pie / 2);
            let y1 = currentY - 10 * Math.sin(direction - pie / 2);
            let x2 = currentX - 10 * Math.cos(direction - pie / 2);
            let y2 = currentY + 10 * Math.sin(direction - pie / 2);
            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.stroke();

            if (i === n - 1) {
                break;
            }

            endX = currentX + sS * Math.cos(direction);
            endY = currentY - sS * Math.sin(direction);

            ctx.moveTo(currentX, currentY);
            ctx.lineTo(endX, endY);
            ctx.stroke();
            currentX = endX;
            currentY = endY;

        }
        //_______________________________________________________________________________________________________________________


        //createObjectURL(dataURL);\
        // var binaryData = [];
        // binaryData.push(dataURL);
        // const url = URL.createObjectURL(new Blob(binaryData, {type: "image/png"}))


        //______________________________________________________________________________________________________________________
        if (endX < minX) { setMinX(endX); }
        if (endY < minY) { setMinY(endY); }
        if (endX > maxX) { setMaxX(endX); }
        if (endY > maxY) { setMaxY(endY); }
        // Your coupler function code here
        // ...
        count++;
    };

    const pointReturner = (start, end) => {
        let big = start > end ? start : end;
        let small = start > end ? end : start;

        if (small <= 90 && 90 <= big) {

        }
    }

    const drawCurve = (r, ang, CapLet, data, arcNo, Capt3) => {
        let [CapUse, Letter, Geom] = CapLet.split('-');
        const ctx = ctxRef.current;
        let radius = r * mF, i = 0;
        ang < 0 ? x = -1 : x = 1;

        radius *= x;
        arcAngle = ang;
        centerX = currentX + radius * Math.cos(direction - pie / 2);
        centerY = currentY - radius * Math.sin(direction - pie / 2);
        startAngle = -x * (x * direction + pie / 2);

        endAngle = startAngle + arcAngle;
        radius *= x;

        // startAngle = startAngle % (2 * pie)
        // console.log((startAngle % (2 * pie)) * 180 / pie);

        const endX = centerX + radius * Math.cos(endAngle);
        const endY = centerY + radius * Math.sin(endAngle);

        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, startAngle, endAngle, x === -1);
        ctx.stroke();
        ctx.fillStyle = 'blue';

        if (CapUse === 'Yes') {
            if (Letter === '' || Letter === undefined || Letter === "0") { textNo = String.fromCharCode(64 + textCount); }
            else textNo = Letter
        }
        if (Geom === 'Arc') { ctx.fillStyle = 'blue'; }
        else { ctx.fillStyle = 'red'; }

        let textX = 0, textY = 0;

        if (Capt3 !== 'No' && Capt3 && Capt3.includes(',')) {
            console.log(Capt3, data);

            let [tx, ty] = Capt3.split(',');
            console.log(tx, ty);
            if (tx) {
                textX += tx * 1;
            }
            if (ty) {
                textY -= ty * 1
            }
            if (tx < 0 && (bbs)) {
                if (Geom === 'Arc') {
                    textX -= `${r}:${textNo}`.length * (fontSize / 5) * 2
                } else {
                    textX -= `${data}:${textNo}`.length * (fontSize / 5) * 2

                }

                console.log(textNo);
            }
        }



        // CapUse === 'Yes' ? ctx.fillText(data, centerX, centerY) : i++;

        if (CapUse === 'Yes' && Geom === 'Arc') {
            ctx.fillText(data, 410-data.length* (fontSize / 3.5) * 2, arcNo * fontSize)
            ctx.fillText(r + ":" + textNo, (centerX + radius * Math.cos(startAngle + arcAngle / 2)) + textX, (centerY + radius * Math.sin(startAngle + arcAngle / 2)) + textY)
        } else if (CapUse === 'Yes' && textCount !== 1) {
            ctx.fillText(data + ":" + textNo, centerX + textX, centerY + textY)
        }



        if (CapUse === 'Yes') {

        }

        currentX = endX;
        currentY = endY;
        direction -= arcAngle;
        textCount++;

        if (Geom === 'Angle' && textCount === 2) {
            // textCount--;
        }


        // console.log(startAngle * 180 / pie, endAngle * 180 / pie, ang * 180 / pie);

        if (x === 1) { startAngle += 2 * pie; endAngle += 2 * pie; }


        if (startAngle <= 0 && 0 <= endAngle) { if (maxY < centerY + radius) setMaxX(centerX + radius); }
        if (startAngle <= pie / 2 && pie / 2 <= endAngle) { if (maxX < centerX + radius) { setMaxX(centerX + radius); } }
        if (startAngle <= pie && pie <= endAngle) { if (minX > centerX - radius) setMinX(centerX - radius); }
        if (startAngle <= (3 * pie / 2) && (3 * pie / 2) <= endAngle) { if (minY > centerY - radius) setMinY(centerY - radius); }


        if (endX < minX) { setMinX(endX); }
        if (endY < minY) { setMinY(endY); }
        if (endX > maxX) { setMaxX(endX); }
        if (endY > maxY) { setMaxY(endY); }


        count++;
    }

    const clearCanvas = (cS) => {
        // let l = drawingElements.length;
        if (cS === true) { clearShapes(); setLc(1); setMF(1); console.log("++++++++++++++++") }
        const ctx = ctxRef.current;
        ctx.clearRect(0, 0, 400, 295);

        currentX = orginX;
        currentY = orginY;
        direction = 0; textCount = 1;
        length = 100;

        // Your clearCanvas function code here
        // ...

        count++;
    };

    const addLine = () => {
        let typeV = 'Actual', length2 = 100, linePoss = 1, l = drawingElements.length + 1;
        setLc(lineCounter + 1);
        if (l > 26) {
            l += 6
        }
        textNo = String.fromCharCode(64 + l);
        if (lineCounter > 1) {
            let uC = false;
            uC = window.confirm('Length already drawn. Introduce length with Offset and Normal?')
            if (uC) {
                typeV = 'Offset + Normal';
            }
            else { linePoss = 0; }

        }
        if (linePoss === 1) {
            const elementDetails = {
                Geometry: 'Length',
                Type: typeV,
                Length1: 100,
                Capt1: 'Yes',
                Match1: textNo,
                Angle: '',
                Capt2: '',
                Match2: '',
                Length2: length2,
                Capt3: '',
                Match3: ''
            };
            addNew(elementDetails);
            setIsCouplerButtonDisabled(false);
            count++;
        }
    }

    const addCurve = (r, arcAngle, type) => {
        let l = drawingElements.length + 1;
        textNo = String.fromCharCode(64 + l);
        const elementDetails = {
            Geometry: r === 40 ? 'Arc' : 'Angle',
            Type: r === 20 ? type : 'Rad+Sw_Angle',
            Length1: r === 40 ? r : "",
            Capt1: r === 40 ? 'Yes' : "",
            Match1: r === 40 ? textNo : "",
            Angle: arcAngle * 180 / pie,
            Capt2: r === 40 ? 'Yes' : 'No',
            Match2: '',
            Length2: '',
            Capt3: '',
            Match3: '',
        };
        addNew(elementDetails);
        setIsCouplerButtonDisabled(true);
        count++;
        setLc(1);
    }

    const addCoupler = () => {

        const elementDetails = {
            Geometry: 'Coupler',
            Type: 'Half Thread',
            Length1: '',
            Capt1: '',
            Match1: '',
            Angle: '',
            Capt2: '',
            Match2: '',
            Length2: '',
            Capt3: '',
            Match3: '',
        };

        addNew(elementDetails);
        setIsCouplerButtonDisabled(true);
        count++;
        setLc(1);
    }

    const setButton = (coup, rest, line, angle) => {
        setIsCouplerButtonDisabled(coup);
        setIsRestButtonDisabled(rest);
        setIsLineButtonDisabled(line);
        setIsAngleButtonDisabled(angle)

    }


    const draw = (drawingElements) => {

        clearCanvas();
        // const ctx = ctxRef.current;
        // ctx.translate(-250, -250);

        if (!Array.isArray(drawingElements)) {
            console.error('drawingElements is not an array.');
            return;
        }
        let arcNo = 0

        //ENABLE ALL BUTTONS

        setButton(false, false, false, false);

        setMaxX(orginX);
        setMaxY(orginY);
        setMinX(orginX);
        setMinY(orginY);

        if (drawingElements[0]?.Geometry === "Spring") {
            drawSpring(drawingElements)
        } else

            drawingElements.forEach((i, k) => {

                let j = constantLengthValue[k]

                k !== 0 && i.Geometry === 'Coupler' ? setButton(true, true, true, true) :
                    k === 0 && i.Geometry === 'Coupler' ? setButton(true, true, false, true) :
                        i.Geometry !== 'Length' || i.Geometry === 'none' ? setButton(true, false, false, true) :
                            setButton(false, false, false, false);

                i.Geometry === "Length" ?
                    i.Type === 'Actual' ? drawLine(j.Length1, i.Length1, 0, `${i.Capt1}-${i.Match1}`, i.Capt3) :
                        i.Type === 'Normal' ? drawLine(j.Length1, i.Length1, 1, `${i.Capt1}-${i.Match1}`, i.Capt3) :
                            i.Type === 'Offset' ? drawLine(j.Length1, i.Length1, 2, `${i.Capt1}-${i.Match1}`, i.Capt3) :
                                drawOfNor(i.Length1, i.Length2, `${i.Capt1}-${i.Match1}`, i.Capt3)
                    : i.Geometry === "Arc" ? (
                        i.Type === 'Rad+Sw_Angle' ? drawCurve(i.Length1, i.Angle * Math.PI / 180, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Rad:${i.Length1},Sw_Angle:${i.Angle}`, ++arcNo, i.Capt3) :
                            i.Type === 'Rad+ArcLength' ? drawCurve(i.Length1, i.Length2 / i.Length1 * Math.abs(i.Angle) / i.Angle, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Rad:${i.Length1},ArcLength:${i.Length2}`, ++arcNo, i.Capt3) :
                                i.Type === 'Dia+ArcLength' ? drawCurve(i.Length1 / 2, i.Length2 / i.Length1 * Math.abs(i.Angle) / i.Angle, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Dia:${i.Length1},ArcLength:${i.Length2}`, ++arcNo, i.Capt3) :
                                    i.Type === 'Dia+Sw_Angle' ? drawCurve(i.Length1 / 2, i.Angle * Math.PI / 180, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Dia:${i.Length1},Sw_Angle:${i.Angle}`, ++arcNo, i.Capt3) :
                                        drawCurve((((i.Length1 * i.Length1) / 4) + i.Length2 * i.Length2) / (2 * i.Length2), 2 * Math.PI, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:Chord:${i.Length1},Normal:${i.Length2}`, ++arcNo, i.Capt3)
                    ) : i.Geometry === "Angle" ? (
                        k === 0 ? (i.Type === 'Clockwise' ? drawCurve(0, i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match2}-${i.Geometry}`, '', 0, i.Capt3) : drawCurve(0, -i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match2}-${i.Geometry}`, '', 0, i.Capt3)) :
                            (i.Type === 'Clockwise' ? drawCurve(20, i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match1}-${i.Geometry}`, i.Angle, 0, i.Capt3) : drawCurve(20, -i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match1}-${i.Geometry}`, i.Angle, 0, i.Capt3))
                    ) :
                        // i.Geometry === "Coupler"?(
                        i.Type === 'Half Thread' ?
                            drawCoupler(15, 3) :
                            i.Type === 'Full Thread' ?
                                drawCoupler(15, 5) :
                                drawCoupler(2.5, 10)
                // ) : i.Geometry === "Spring"?(

                // )

                return 0;
            })


    }

    const drawSpring = (array) => {
        const ctx = ctxRef.current;

        const img = new Image();
        img.src = springShape;
        img.onload = function () {

            //350 x 520 -> 2/5 -> 140 x 208

            ctx.clearRect(0, 0, 400, 300);
            ctx.drawImage(img, orginX, orginY, mF * 350, mF * 520);
            ctx.lineWidth = 5;
            ctx.font = `bold ${fontSize}px Arial`;
            ctx.fillStyle = 'Blue';
            ctx.fillText(`${array[0].Length1}:A`, orginX - 20 - `${array[0].Length1}:A`.length * (fontSize / 5) * 2, orginY + 520 * mF / 4 + (fontSize / 5 * 4));
            ctx.fillText(`${array[1].Length1}:B`, orginX + 350 * mF / 2 - `${array[1].Length1}:B`.length * (fontSize / 5), orginY + 520 * mF + (fontSize / 5 * 4));
            ctx.fillText(`${array[2].Length1}:C`, orginX + 350 * mF, orginY + 520 * mF / 4 + (fontSize / 5 * 4));

            console.log("876");
        };

        img.onerror = function () {
            console.error('Error loading image:', img.src);
            // Provide fallback behavior or display an error message
        };
    }


    const panButtons = (up, left, right, down, reset) => {
        if (!mF) {
            setOrginX(100); setOrginY(147)
            setMF(1);

        } else
            if (reset === 0) {
                let xcor = orginX, ycor = orginY;
                setOrginY(ycor = ycor - (up - down));
                setOrginX(xcor = xcor - (left - right));
            }
            else if (reset === 1) { setOrginX(100); setOrginY(147); }
            else if (reset === 2) {


                if (((maxX - minX) / 260) <= ((maxY - minY) / 155)) {
                    setMF((155 / (maxY - minY)) * mF);

                    setOrginX(((orginX - minX) * ((155 / (maxY - minY)))) + 70);

                    setOrginY(((orginY - minY) * ((155 / (maxY - minY)))) + 70);


                } else {
                    setMF((260 / (maxX - minX)) * mF);

                    setOrginX(((orginX - minX) * ((260 / (maxX - minX)))) + 70);

                    setOrginY(((orginY - minY) * ((260 / (maxX - minX)))) + 70);

                }

            } else if (reset === 3) { setMF(mF * 5 / 4); }
            else if (mF > 0.1) { setMF(mF * 4 / 5); } else { setMF(mF * 4 / 5); }

        draw(drawingElements);

        if (converter === true && eff < 3) {
            // setBbsCanvasRef(canvas)
            panButtons(0, 0, 0, 0, 2)
        }


        setEff(eff + 1);
    }



    const drawOfNor = (ll1, ll2, CapLet, Capt3) => {
        let [CapUse, Letter] = CapLet.split('-');
        setl1(ll1 * mF);
        setl2(ll2 * mF);


        const ctx = ctxRef.current;

        direction = -(Math.atan(l2 / l1));

        dashDraw(ll1, currentX, currentY, currentX + l1 / l1 * l1, currentY);

        dashDraw(ll2, currentX + l1 / l1 * l1, currentY, currentX + l1 / l1 * l1, currentY + l2 / l2 * l2);
        // const hyp = Math.sqrt(l1*l1 + l2*l2);
        // drawLine(hyp,0)
        ctx.beginPath();
        ctx.moveTo(currentX, currentY);
        ctx.lineTo(currentX + l1 / l1 * l1, currentY + l2 / l2 * l2);
        ctx.stroke();

        if (CapUse === 'Yes') {
            if (Letter === '') { textNo = String.fromCharCode(64 + textCount); }
            else textNo = Letter
        }
        let midX = currentX + l1 / 2;
        let midY = currentY + l2 / 2;
        x = 1;
        let textX = midX + 30 * Math.cos((x * direction - pie / 2));
        let textY = midY - 30 * Math.sin(((1 - x) / 2 * pie + direction - pie / 2));

        if (Capt3 !== 'No') {
            let [tx, ty] = Capt3;
            textX += tx;
            textY += ty
        }

        ctx.fillStyle = 'Black';
        ctx.fillText(textNo, textX, textY);

        currentX += l1; endX = currentX;
        currentY += l2; endY = currentY;
        if (endX < minX) { setMinX(endX); }
        if (endY < minY) { setMinY(endY); }
        if (endX > maxX) { setMaxX(endX); }
        if (endY > maxY) { setMaxY(endY); }

    }

    const dashDraw = (dLength, x1, y1, x2, y2) => {
        const ctx = ctxRef.current;

        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(x1 - 1 + 1, y1 - 1);
        ctx.lineTo(x2 - 1 + 1, y2 - 1 + 1);
        ctx.stroke();
        ctx.setLineDash([]);
        let midX = (x1 + x2) / 2, midY = (y1 + y2) / 2;
        if (y1 === y2) {
            midY -= 10;
        } else {
            midX += 10;
        }
        ctx.fillStyle = 'Black';
        ctx.fillText(dLength, midX, midY);
    }



    const deleteshapeEle = () => {
        setLc(1);
        delshape();
    }



    return (

        <div className="canvas-container" style={{ flexDirection: (bbs === true ? 'row' : 'column'), margin: (bbs === true ? '1vw' : ''), backgroundColor: (converter === true ? 'white' : '') }}>
            <div className="canvas-row" >
                <canvas ref={canvasRef} width="400" height="294" style={{ width: (bbs === true ? '250px' : '') }}></canvas><br />
                {/* <div className="imageTake">
                    <canvas ref={canvasRef} width="462" height="294"></canvas>
                    <br/>
    </div> */}
                <div style={{ display: (bbs === true || converter === true ? 'none' : '') }}>
                    <div>
                        <div className="shape-Id" >
                            <label htmlFor="">Shape ID </label>
                            <input className='' type="text" value={shapeId} onChange={(e) => setShapeId(e.target.value)} />
                        </div>

                    </div>
                    <div style={{ margin: '30px', marginLeft: '3vw', display: 'flex', flexDirection: 'row' }}>
                        <Button className='blue' label='Save' onClick={handleSaveButtonClick} />
                        <Button className='blue' label='Back' link='shapes' />
                    </div>
                </div>

            </div>
            <div className="canvasDrawControls" style={{ marginLeft: (bbs === true ? '1.5vw' : '') }}>

                <div className="dial">
                    <img src={dial} alt="" className='dialImg' />
                    <div className="dial-buttons">
                        <div className="11"></div>
                        <div className="B" onClick={() => panButtons(20, 0, 0, 0, 0)}><img src={up} alt="" /></div>
                        <div className="13"></div>
                        <div className="B" onClick={() => panButtons(0, 20, 0, 0, 0)}><img src={left} alt="" /></div>
                        <div className="B" onClick={() => panButtons(0, 0, 0, 0, 2)}><img src={auto} alt="" /></div>
                        <div className="B" onClick={() => panButtons(0, 0, 20, 0, 0)}><img src={right} alt="" /></div>
                        <div className="31"></div>
                        <div className="B" onClick={() => panButtons(0, 0, 0, 20, 0)}><img src={down} alt="" /></div>
                        <div className="33"></div>
                    </div>
                </div>

                <div className="zoom-icon">
                    <div title='Zoom in' className="plus" onClick={() => panButtons(0, 0, 0, 0, 3)}>
                        <img src={plus} alt="" />
                    </div>
                    <div title='Zoom out' className="minus" onClick={() => panButtons(0, 0, 0, 0, 4)}>
                        <img src={minus} alt="" />
                    </div>
                </div>
                {bbs === true || converter === true ? '' : <div className="canvasDrawButtons" >

                    <button title='Length' className='circleButton B' onClick={() => addLine()} disabled={isLineButtonDisabled}>
                        <div className="midLine"></div>
                    </button>

                    <button title='Angle' className='circleButton B' onClick={() => addCurve(20, Math.PI / 2, 'Clockwise')} disabled={isAngleButtonDisabled}><img style={{ transform: 'rotateZ(180deg)' }} src={Angle} alt="" /></button>
                    <button title='AntiAngle' className='circleButton B' onClick={() => addCurve(20, Math.PI / 2, 'AntiClock')} disabled={isAngleButtonDisabled}><img src={Angle} alt="" /></button>
                    <button title='Undo' className='circleButton B' onClick={() => deleteshapeEle()}><img src={Undo} alt="" /></button>
                    <button title='Coupler' className='circleButton B' onClick={() => addCoupler()} disabled={isCouplerButtonDisabled}><img src={Coupler} alt="" /></button>
                    <button title='Arc' className='circleButton B' onClick={() => addCurve(40, Math.PI)} disabled={isRestButtonDisabled}><img src={Arc} alt="" /></button>
                    <button title='AntiArc' className='circleButton B' onClick={() => addCurve(40, -Math.PI)} disabled={isRestButtonDisabled}><img src={AntiArc} alt="" /></button>
                    <button title='Clear Canvas' className='circleButton D' onClick={() => clearCanvas(true)}><img src={ClearCanvas} alt="" /></button>

                </div>}

                <div>
                    <div className="" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="fontSize">Font Size </label>
                        <input id="fontSize" type="number" value={fontSize} min={10} max={35} onChange={(e) => setFontSize(e.target.value)} style={{ border: '2px solid #d2d2d2', padding: '4px', borderRadius: '7px', width: '50px' }} />
                    </div>
                </div>
                <div>
                    <div className="" style={{ display: (converter === true ? 'flex' : 'none'), flexDirection: 'column', margin: '15px' }}>
                        <Button label="Confirm" className='blue' onClick={() => imageCapture(canvasRef.current)} />
                    </div>
                </div>
            </div>
        </div>

    );
};

export default BBSDraw;