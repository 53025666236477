import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/API";
import { toast } from "react-toastify";

const initialState = {
  columns: {},
  labeledData: [],
  loading: "idle",
  error: null,
};

export const fetchUser = createAsyncThunk("data/fetchUser", async () => {
  try {
    const response = await api.listUsers();
    const json = response.data;

    // const iuy = {
    //     "userId": 13,
    //     "userType": "Direct Employee",
    //     "roleId": 1,
    //     "roleName": "superadmin",
    //     "userEmail": "operator111@tatasteel.com",
    //     "imageUrl": null,
    //     "status": 1,
    //     "serviceCenterId": 1,
    //     "name": "Pasa123",
    //     "address": "jhamsedpur",
    //     "phoneNo": "9999000022",
    //     "email": "pasamnoaadddda@pasa1.com",
    //     "dateStamp": null,
    //     "flag": 0,
    //     "monthlyCapacity": 2000,
    //     "createdBy": "Admin",
    //     "createdTs": "2024-04-01T14:43:06.000Z",
    //     "updatedBy": null,
    //     "updatedTs": null
    // }

    const columnLabels = {
      // userEmail: {
      //   label: "User email",
      //   type: "email",
      //   isRequired: true,
      //   pattern: ".*",
      // },
      userType: {
        label: "User Type",
        type: "text",
        isRequired: true,
        pattern: ".*",
      },
      roleName: {
        label: "Role",
        type: "text",
        isRequired: true,
        pattern: ".*",
      },
      roleId: {
        label: "roleId",
      },
      imageUrl: {
        label: "User image",
      },
      userEmail: {
        label: "User email",
      },
      name: {
        label: "Service center Name",
        type: "text",
        isRequired: true,
        pattern: ".*",
      },
      region: {
        label: "Region",
        type: "text",
        isRequired: true,
        pattern: ".*",
      },
      userName: { label: "User Name" },
      userNo: { label: "User No." },
      userId: { label: "User Id" },
      serviceCenterId: { label: "seviceCenterId"}

      // Add more as needed
    };

    const selectedColumns = [
      "userName",
      "userEmail",
      "name",
      "userNo",
      "userId",
      "roleId",
      "imageUrl",
      "userType",
      "roleName",
      "region",
      "serviceCenterId"
    ];

    const showColumns = [
      "userName",
      "userEmail",
      "name",
      "userNo",
      // "roleId",
      "imageUrl",
      "userType",
      "roleName",
      "region",
    ]


    const columns = {};



    showColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    return { columns, labeledData };
  } catch (error) {
    if (error.message) {
      toast.error(`${error.message}`);
    }
    throw error;
  }
});

export const postUser = createAsyncThunk("data/postUser", async (newRow) => {
  try {
    const response = await api.addUser(newRow);
    const data = response;
    console.log("Success");
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(`${error.response.data.message}`);
    }
    throw error;
  }
});

export const editUser = createAsyncThunk("data/editUser", async ({id, updatedData}) => {
  try {
    const response = await api.updateUser(id, updatedData);
    const data = response;
    console.log("Success");
    return data;
  } catch (error) {
    if (error.response.data.message) {
      toast.error(`${error.response.data.message}`);
    }
    throw error;
  }
});


export const deleteUser = createAsyncThunk("data/deleteUser", async (id) => {
  try {
    await api.deleteTpmRate(id);
    return id;
  } catch (error) {
    throw error;
  }
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      })
      .addCase(postUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(postUser.fulfilled, (state, action) => {
        state.loading = "fulfilled";
      })
      .addCase(postUser.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        // state.labeledData=state.labeledData.filter((data) => data.equipmentId !== deletedId);
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
