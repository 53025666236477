import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API'; // Import your Axios API functions
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
  columns: {},
  labeledData: [], // Initialize with an empty array
  loading: 'idle',
  error: null,
  resp: {}
};

export const fetchBarMarkQtyData = createAsyncThunk('data/fetchBarMarkQtyData', async () => {
	try {
		const response = await api.barMarkWiseQty();
		const json = response.data;

		const columnLabels = {
            soldToParty: { label: "Customer", type: 'string'},
            orderId: { label: "Order Id", type: 'number'},
            orderItemName: { label: "Order Desc", type: 'string'},
            tagId: { label: "Tag Id", type: 'number'},
            barMarkId: { label: "Bar Mark", type: 'number'},
            diameter: { label: "Dia", type: 'number'},
            totalNoOfPieces: { label: "No of Pcs", type: 'number'},
            productionLength: { label: "Prod Len(RM)", type: 'number'},
            bundleQuantity: { label: "Prod Qty(Kg)", type: 'number'},
            createdTs: { label: "Created Date", type: 'date'}
		};
		
        
		const selectedColumns = ["soldToParty", "orderId", "orderItemName", "tagId", "barMarkId", "diameter", "totalNoOfPieces", "productionLength", "bundleQuantity", "createdTs"];
		const columns = {};
		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});
		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});

    console.log(columns)
		return { columns, labeledData };
	} catch (error) {
    console.log('error')
		throw error;
	}
});



const barMarkWiseQtySlice = createSlice({
  name: 'barMarkWiseQty',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBarMarkQtyData.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchBarMarkQtyData.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchBarMarkQtyData.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      
  },
});

export default barMarkWiseQtySlice.reducer;
//End of File