import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { resetState } from './loginSlice';

const initialState = {
	columns: {},
	columns2: {},
	labeledData: [],
	loading: 'idle',
	fetcher: true,
	error: null,
};

export const fetchOrderItems = createAsyncThunk('data/fetchOrderItems', async (id = null) => {
	try {
		let response = {}
		if (id === null) {
			response = await api.listOrderItems();
		} else response = await api.listOrderItems2(id);

		const json = response.data;
		const columnLabels = {
			orderItemId: { label: "Element Id", type: 'number', isRequired: true, editable: false },
			orderItemName: { label: "Element Name", type: 'text', isRequired: true, editable: true },
			noOfSets: { label: "No of Set", type: 'number', isRequired: true, editable: true },
			noOfTags: { label: "No of Tags", type: 'text', isRequired: true, editable: true },
			productionWeight: { label: "Total Weight (T)", type: 'text', isRequired: true, editable: true },
			tagPrintStatus: { label: "Tag Print Status", type: 'text', isRequired: true, editable: false },
			orderItemStatus: { label: "Element Status", type: 'text', isRequired: true, editable: false },
			orderId: { label: "Order Id", type: 'text', isRequired: true, editable: false },

			// Add more as needed
		};

		const selectedColumns = ['orderItemId', 'orderItemName', 'noOfSets', 'tagPrintStatus', 'orderItemStatus', 'orderId', 'noOfTags', 'productionWeight'];
		const selectedColumns2 = ['orderItemName', 'noOfSets', 'orderItemId', 'noOfTags']

		const columns = {}, columns2 = {};
		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		selectedColumns2.forEach((columnName) => {
			columns2[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				if (columnName === 'productionWeight') {
					rowData[columnName] = Math.floor(row[columnName]*1000)/1000;
				} else
					rowData[columnName] = row[columnName];
			});
			return rowData;
		});

		return { columns, labeledData, columns2 };
	} catch (error) {
		throw error;
	}
});

export const postOrderItems = createAsyncThunk('data/postOrderItems', async (newRow) => {
	try {

		const response = await api.addOrderItems(newRow);
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		throw error;
	}
});
export const deleteOrderItems = createAsyncThunk('data/deleteOrderItems', async (id) => {
	try {
		await api.deleteOrderItems(id);
		return id;
	} catch (error) {
		throw error.response.data;
	}
});
export const updateOrderItems = createAsyncThunk('data/updateOrderItems', async ({ id, type }) => {
	try {
		console.log(type);
		const response = await api.updateOrderItems(id, type);
		const data = response.data;
		console.log('Update Success');
		return { data, id, type };
	} catch (error) {
		throw error;
	}
});
export const copyOrderItems = createAsyncThunk('data/copyOrderItems', async (items) => {
	try {
		console.log(items)
		const response = await api.copyOrderItems(items);

		const data = response.data;
		return data;
	} catch (error) {
		throw error;
	}
});


const orderitemsSlice = createSlice({
	name: 'organization',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(resetState, () => initialState)
			.addCase(fetchOrderItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns = action.payload.columns;
				state.columns2 = action.payload.columns2;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchOrderItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(postOrderItems.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postOrderItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				state.labeledData.push(action.payload);
				toast.success('Element added successfully!');
			})
			.addCase(postOrderItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to add Element');
			})
			.addCase(copyOrderItems.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(copyOrderItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				state.labeledData.push(action.payload);
				toast.success('Elements copied successfully!');
			})
			.addCase(copyOrderItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to copy Elements');
			})
			.addCase(updateOrderItems.pending, (state) => {
				state.loading = 'pending';

			})
			.addCase(updateOrderItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;

				const index = state.labeledData.find(item => item.orderItemId === action.payload.id);
				if (index) {
					const updatedItem = { orderItemId: action.payload.id, ...action.payload.type };
					console.log(updatedItem);
					state.labeledData = state.labeledData.map(item => {
						if (item.orderItemId === action.payload.id) {
							return updatedItem;
						}
						return item;
					})

				}
				toast.success('Element updated sucessfully');
			})
			.addCase(updateOrderItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error('Failed to update Element');
			})
			.addCase(deleteOrderItems.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(deleteOrderItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				const deletedId = action.payload;
				state.labeledData = state.labeledData.filter((data) => data.orderItemId !== deletedId);
				toast.success('Element deleted sucessfully');
			})
			.addCase(deleteOrderItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				toast.error(state.error);
			})
			
	},
});

export default orderitemsSlice.reducer;
//End of File