import { PDFViewer } from '@react-pdf/renderer'
import React, { useEffect } from 'react'
import CreatePass from './CreatePass'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { fetchOrder } from '../../../../redux-tookit/slices/orderSlice';

const GatePassPrint = () => {
    const dispatch = useDispatch();
    const location = useLocation();

    const data = location.state?.data;

    const orderData = useSelector(state => state.order.labeledData)

    useEffect(() => {
        // dispatch(fetchLoadingList())
        dispatch(fetchOrder())
    }, [])

    return (
        <div style={{ border: '2px solid black', height: '100%' }}>
            <PDFViewer style={{ height: '100%', width: '100%' }}>
                <CreatePass data={data} orderData={orderData}/>
            </PDFViewer>
        </div>
    )
}

export default GatePassPrint
