import React from 'react'
import Button from '../../../utils/Button'

const ShowResult = (props) => {

  const { result, setShowResult } = props

  // "totalDelta": 32887,
  // "valueCreation": 8566,
  // "recommendedDelta": 4283,

  return (
    <div>
      <div className="AddContainer" >
        <div className="form-container">
          <div className="head">Presales Result</div>
          <div className="table-container" style={{border:'1px solid #d2d2d2'}}>
            <table className="generic-table">
              <thead>
                <tr>
                  <th className="table-header">Total price</th>
                  <th className="table-header">Value creation</th>
                  <th className="table-header">Recommended delta</th>
                </tr>
              </thead>
              <tbody>
                
                  <tr>
                    <td>{result.totalDelta} </td>
                    <td>{result.valueCreation}</td>
                    <td>{result.recommendedDelta}</td>
                  </tr>
  
              </tbody>
            </table>
          </div>
          <div className="bottom-button-container">

            <Button onClick={() => setShowResult(false)} label='Exit' className='blue' />
          </div>
        </div>
      </div>
      <div className="bg-dark"></div>
    </div>
  )
}

export default ShowResult
