import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GenericTable1 from '../../../components/GenericTable1';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import excel from '../../../assets/images/button-icons/excel.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { deleteFacility, fetchFacilities } from '../../../redux-tookit/slices/facilityMasterSlice';
import { fetchServiceCenter } from '../../../redux-tookit/slices/serviceCenterSlice';
import AddFacility from './AddFacility';
import AGTable from '../../../components/AGTable';
import {  toast } from 'react-toastify';
import { fetchProcessCenter } from '../../../redux-tookit/slices/processCenterSlice';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';

const FacilityMaster = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const permission = useState(location.state?.permission);
    const columns = useSelector((state) => state.facilityMaster.columns);
    const data = useSelector((state) => state.facilityMaster.labeledData);
    const fetcher = useSelector((state) => state.facilityMaster.fetcher)
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [editData, setEditData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);



    const handleRowSelect = (rowIndex) => {
        setSelectedRowIndex(rowIndex);
    };

    const handleAddButtonClick = () => {
        setIsAdding(true);
    };

    const handleEditButtonClick = () => {
        if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
            setIsEditing(true);
            setEditData(data[selectedRowIndex]);
        }
        else toast.error("No rows selected, Please select a row and try again");
        setSelectedRowIndex(null);
    };


    useEffect(() => {
        dispatch(fetchFacilities()).then(dispatch(fetchProcessCenter()));
    }, [dispatch, isAdding, isEditing]);

    const handleDeleteButtonClick = async () => {
        if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
            const id = data[selectedRowIndex].facilityId;
            console.log(id);
            dispatch(deleteFacility(id));
        }
        else toast.error("No rows selected, Please select a row and try again");
        setSelectedRowIndex(null);
    }

    return (
        <div className='sdbr'>
            <div className='head' style={{ width: '670px' }}>Facility Master
                <div className="top-button-container">
                    {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
                    <img src={edit} alt="edit" onClick={handleEditButtonClick} />
                    <img src={del} alt="delete" onClick={handleDeleteButtonClick} /> */}
                    <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]}/>

                </div>
            </div>

            <div className="Table-Container" style={{ width: '700px' }}>
                <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />

            </div>
            <AddFacility isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} />


        </div >

    );
};

export default FacilityMaster;