// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-new-order {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.add-new-order .order-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    
    

}

.add-new-order .order-details .order-fields {
    display: flex;
    flex-direction: column;
    min-height: 4vw;
    justify-content: flex-end;

}

.add-new-order .order-details .row-1,
.add-new-order .order-details .row-2,
.add-new-order .order-details .row-3{
    display: flex;
    flex-direction: row;
    margin-top: 1.5vw;
    
}

.add-new-order .order-details .order-fields.one{
    width: 40%;
}

.add-new-order .order-details .order-fields.two{
    width: 25%;
}

.add-new-order .order-details .order-fields.three{
    width: 35%;
}

.order-details input[type='text'],
.order-details textarea,
.order-details select,
.order-details input[type='date']{
    padding: 8px;
    margin: 4px;
    margin-left: 0px;
    border: 3px solid #d9d9d9;
    border-radius: 4px;
    margin-right: 2vw;

}`, "",{"version":3,"sources":["webpack://./src/assets/styles/AddNew.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;IAC9B,WAAW;;;;AAIf;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,eAAe;IACf,yBAAyB;;AAE7B;;AAEA;;;IAGI,aAAa;IACb,mBAAmB;IACnB,iBAAiB;;AAErB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,UAAU;AACd;;AAEA;;;;IAII,YAAY;IACZ,WAAW;IACX,gBAAgB;IAChB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;;AAErB","sourcesContent":[".add-new-order {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 100%;\r\n}\r\n\r\n.add-new-order .order-details {\r\n    display: flex;\r\n    flex-direction: column;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n    \r\n    \r\n\r\n}\r\n\r\n.add-new-order .order-details .order-fields {\r\n    display: flex;\r\n    flex-direction: column;\r\n    min-height: 4vw;\r\n    justify-content: flex-end;\r\n\r\n}\r\n\r\n.add-new-order .order-details .row-1,\r\n.add-new-order .order-details .row-2,\r\n.add-new-order .order-details .row-3{\r\n    display: flex;\r\n    flex-direction: row;\r\n    margin-top: 1.5vw;\r\n    \r\n}\r\n\r\n.add-new-order .order-details .order-fields.one{\r\n    width: 40%;\r\n}\r\n\r\n.add-new-order .order-details .order-fields.two{\r\n    width: 25%;\r\n}\r\n\r\n.add-new-order .order-details .order-fields.three{\r\n    width: 35%;\r\n}\r\n\r\n.order-details input[type='text'],\r\n.order-details textarea,\r\n.order-details select,\r\n.order-details input[type='date']{\r\n    padding: 8px;\r\n    margin: 4px;\r\n    margin-left: 0px;\r\n    border: 3px solid #d9d9d9;\r\n    border-radius: 4px;\r\n    margin-right: 2vw;\r\n\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
