import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { dateChanger } from '../../../components/reusableFunctions';
import Button from '../../../utils/Button';
import { fetchOrder } from '../../../redux-tookit/slices/orderSlice';
import './productionParams.css'
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';

const ProductionParams = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const { orderId } = useParams();

  const [popupOpen, setPopupOpen] = useState(false)
  const [combId, setCombId] = useState(location.state?.combId);

  const orderData = useSelector((state) => state.order.labeledData).filter(
    (item) => `${item.orderId}` === orderId
  );

  useEffect(() => {
    dispatch(fetchOrder())
  }, [])

  const columns = {
    shapeType: { label: "Shape Type", options: ['SHEET', 'ROLLS'], type: 'dropdown' },
    rm: { label: "RM", options: ['WR', 'MA', 'MAC'], type: 'dropdown' },
    finish: { label: "Finish", options: ['Ribbed', 'Plain'], type: 'dropdown' },
    mwDia: { label: "MW Dia (in mm)" },
    cwDia: { label: "CW Dia (in mm)" },
    length: { label: "Length (in mm)" },
    width: { label: "Width (in mm)" },
    mwOh1: { label: "MW OH 1 (in mm)" },
    mwOh2: { label: "MW OH 2 (in mm)" },
    cwOh1: { label: "CW OH 1 (in mm)" },
    cwOh2: { label: "CW OH 2 (in mm)" },
    noOfMW: { label: "No. of MW" },
    noOfCW: { label: "No. of CW" },
    totalNos: { label: "Total Sheets/Rolls in nos" },
    totalWeigth: { label: "Total Weight in MT" },
    sheetWeight: { label: "Sheet weight in Kg" },
    weldedLength: { label: "Welded Length" },
    weldedWidth: { label: "Welded width" },
    mwTotalLength: { label: "MW total length" },
    cwTotalLength: { label: "CW total length" },
    mwAlongWidth: { label: "mm2/m for MW along width" },
    mwAlongLength: { label: "mm2/m for MW along length" }
  }

  const handleAddButtonClick = () => {
    setPopupOpen(true)
  }

  return (
    <div className='bbs'>
      <div className="head">Production Parameters for Order Id : {combId}</div>
      <div className="section1">
        <div className="shadow-box-1">
          <div className="row-1" >
            <div className="box-item-1">
              <label htmlFor="customerName">Customer Name</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <input type="text" readOnly value={order.soldToParty} />
                ) : (
                  ""
                )
              )}
            </div>
            <div className="box-item-1">
              <label htmlFor="OrderDate">Order Date</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <input
                    id="Order Date"
                    type="date"
                    readOnly
                    value={dateChanger(order.createdTs)}
                  />
                ) : (
                  ""
                )
              )}
            </div>
            <div className="box-item-1">
              <label htmlFor="DeliveryDate">Delivery Date</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <input
                    id="DeliveryDate"
                    type="date"
                    readOnly
                    value={order.deliveryDate}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </div>
          <div className="row-2" style={{ justifyContent: 'space-between' }}>
            <div className="dlvAdd">
              <div className="box-item-1">
                <label htmlFor="customerName">Delivery Address</label>
                {orderData.map((order, index) =>
                  index === 0 ? (
                    <textarea
                      id="DeliveryAddress"
                      type="text"
                      readOnly
                      value={order.address}
                      style={{ width: '95%', paddingBottom: '0', height: '70px' }}
                    />
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
            <div className="box-item-1">
              <label htmlFor="totalTags">Total Tags</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <input
                    id="totalTags"
                    type="text"
                    readOnly
                    value={order.totalNoOfTags}
                  />
                ) : (
                  ""
                )
              )}
            </div>
            <div className="box-item-1">
              <label htmlFor="weight">Weight (T)</label>

              {orderData.map((order, index) =>
                index === 0 ? (
                  <input
                    id="weight"
                    type="text"
                    readOnly
                    value={order.productionWeight}
                  />
                ) : (
                  ""
                )
              )}
            </div>

          </div>

        </div>
      </div>
      <div className="head">
        Parameters
        <div className="top-button-container" style={{ alignItems: "center" }}>

          <TopButtonContainer
            add={handleAddButtonClick}
            edit={() => { }}
            del={() => { }}
            access={{ writePermission: true, updatePermission: true, deletePermission: true }}
          />
        </div>
      </div>
      <div className="bottombbs">

        <Button label="Close" className="blue" link="order details" />

      </div>



      {popupOpen ? <>
        <div className='SmFabPopup'>
          <div className="content-area">
            <div className="sdbr head">Add Parameters</div>
            <form onSubmit={() => { }}>
              {Object.keys(columns).map((columnName) =>
                <div className='formField'>
                  <label htmlFor={columnName}>{columns[columnName].label}</label>
                  {columns[columnName].type === 'dropdown' ? <select>
                    <option value="">-select-</option>
                    {columns[columnName].options.map((optionName) =>
                      <option value={optionName}>{optionName}</option>
                    )}
                  </select> : <input id={columnName} type="number" />}
                </div>
              )}
            </form>
            <div className="bottom-button">
              <Button label="Save" className="blue" type="submit" />
              <Button
                label="Cancel"
                className="blue"
                onClick={() => setPopupOpen(!popupOpen)}
              />
            </div>
          </div>
        </div>
      </> : ''}

    </div>

  )
}

export default ProductionParams
