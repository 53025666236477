import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { deleteEquipments, fetchEquipments } from '../../../redux-tookit/slices/equipmentMasterSlice';
import AGTable from '../../../components/AGTable1';
import AddEquipments from './AddEquipments';
import {  toast } from 'react-toastify';
import ExcelDownloadButton from '../../../components/ExcelDownloadButton';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';

const EquipmentMaster = () => {
  
  const dispatch = useDispatch();
  const location = useLocation()
  const permission = useState(location.state?.permission);
  const columns = useSelector((state) => state.equipmentMaster.columns);
  const data = useSelector((state) => state.equipmentMaster.labeledData);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editData, setEditData] = useState([]);
  const [deselector, setDeselector] = useState(0)

  useEffect(() => {
    dispatch(fetchEquipments());
  }, [dispatch, isAdding, isEditing]);

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };

  const handleAddButtonClick = () => {
    setIsAdding(true);
  };

  const handleEditButtonClick = () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      setIsEditing(true);
      setEditData(data[selectedRowIndex]);
    } else toast.error("No rows selected, Please select a row and try again"); setSelectedRowIndex(null);
  };

  const handleDeleteButtonClick = async () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      const id = data[selectedRowIndex].equipmentId;
      dispatch(deleteEquipments(id));
    }
    else toast.error("No rows selected, Please select a row and try again");
    setSelectedRowIndex(null);
  };

  return (
    <div className='sdbr'>
      <div className='head'>Equipment Master
        <div className="top-button-container">
          {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
          <img src={edit} alt="edit" onClick={handleEditButtonClick} />
          <img src={del} alt="delete" onClick={handleDeleteButtonClick} /> */}
          <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]}/>
        </div>
      </div>

      <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} deselector={deselector}/>
      <AddEquipments isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} />

    </div>

  );
};

export default EquipmentMaster;