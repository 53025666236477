// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.order-details{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    white-space: nowrap;;
}
.copy{
    display: flex;
    justify-content: right;
}
.current-order{
    display: flex;
    justify-content: right;
}

.sdbr>.card{

    box-shadow: 0px 4px 9px #00000040;
    border-radius: 8px; 
    padding: 2%;
    margin: 25px 0;
}

.order-dropdown>select{
    width: 10vw;
    margin-top: -1px;
    margin-bottom: 7px;
    border: 2px solid #d9d9d9;
    border-radius: 4px;
    padding: 8px;
    margin-left: 250px;
}

.copyPage{
    padding: 10px 20px;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/ElementCopy.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;AACA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;;IAEI,iCAAiC;IACjC,kBAAkB;IAClB,WAAW;IACX,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":["\r\n.order-details{\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    white-space: nowrap;;\r\n}\r\n.copy{\r\n    display: flex;\r\n    justify-content: right;\r\n}\r\n.current-order{\r\n    display: flex;\r\n    justify-content: right;\r\n}\r\n\r\n.sdbr>.card{\r\n\r\n    box-shadow: 0px 4px 9px #00000040;\r\n    border-radius: 8px; \r\n    padding: 2%;\r\n    margin: 25px 0;\r\n}\r\n\r\n.order-dropdown>select{\r\n    width: 10vw;\r\n    margin-top: -1px;\r\n    margin-bottom: 7px;\r\n    border: 2px solid #d9d9d9;\r\n    border-radius: 4px;\r\n    padding: 8px;\r\n    margin-left: 250px;\r\n}\r\n\r\n.copyPage{\r\n    padding: 10px 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
