import React, { useState } from 'react'
import * as XLSX from "xlsx";
import Button from '../../../../utils/Button';
import { useDispatch } from 'react-redux';
import { fetchInventory, stockBulkUpload } from '../../../../redux-tookit/slices/inventorySlice';
import { fetchGrades } from '../../../../redux-tookit/slices/gradeMasterSlice';
import ExcelTemplate from '../../../../assets/files/BulkStockUploadTemplate.xlsx'
import { toast } from 'react-toastify';
import * as api from '../../../../services/API';


const StockBulkUpload = (props) => {

  const { setBulkPopup } = props

  const dispatch = useDispatch();

  const [excelJson, setExcelJson] = useState([])

  const [uploadData, setUploadData] = useState([])

  const [nFG, setNFG] = useState([])

  const [showNFG, setShowNFG] = useState(false)

  const columns = {
    gradeName: { label: 'Grade Name' },
    diameter: { label: 'Diameter (mm)' },
    stockType: { label: 'Stock Type' },
    minLength: { label: 'Min-Length (mm)' },
    maxLength: { label: 'Max-Length (mm)' },
    weight: { label: 'Weight (T)' },
    totalPieces: { label: 'Total Pieces' }
  }

  const errorColumns = {
    gradeName: { label: 'Grade Name' },
    diameter: { label: 'Diameter (mm)' },
    stockType: { label: 'Stock Type' },
    minLength: { label: 'Min-Length (mm)' },
    maxLength: { label: 'Max-Length (mm)' }
  }


  const excelToJson = (e) => {
    return new Promise((resolve, reject) => {

      const reader = new FileReader();
      reader.onload = (event) => {
        try {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: 'binary' });
          const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
          resolve(jsonData);
          console.log(jsonData);
        } catch (error) {
          console.log(`${error}`)
          e.target.value = null
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(e.target.files[0]);
    });
  }

  const handleFileUpload = (e) => {

    excelToJson(e)
      .then((jsonData) => {
        console.log(jsonData); // Do something with the JSON data
        if (jsonData.length === 0) {
          console.log('Excel is empty.')
          e.target.value = null
        }
        // setExcelJson(jsonData);
        converter(jsonData)
        // setIsConverter(true)
        console.log(jsonData);
      })
      .catch((error) => {
        console.error('Error converting Excel to JSON:', error);
      });
  }



  const converter = (data) => {

    let tempData = []
    let tempColumn = Object.keys(columns);
    let shouldSkip = false;

    data.slice(1, 10001).forEach((row, rowIndex) => {

      if (shouldSkip) {
        return;
      }

      if (row.length !== 0) {

        row.slice(1, 8).forEach((cellData, index) => {
          tempData[rowIndex] = { ...tempData[rowIndex], [tempColumn[index]]: cellData }
        });
      } else {
        shouldSkip = true
      }
    })

    console.table(tempData);
    setUploadData(tempData)
  }


  const handleUpload = async () => {

    try {
      const response = await api.inventoryUpload(uploadData);
      // const data = response.data;
      console.log('Success');
      toast.success('Stock uploaded sucessfully');
      dispatch(fetchInventory()).then(() => dispatch(fetchGrades()))
      
      setBulkPopup(false)

    } catch (error) {
      console.log(error?.response);
      if (error?.response?.status === 409) {
        setNFG(error?.response?.data?.data)
        setShowNFG(true)
        toast.error('Error in Uploading Stocks')
      } else {
        toast.error('Error in Uploading Stocks')
      }

    }


  }



  return (
    <div >
      <form>
        <div className="AddContainer" >
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'fit-content', backgroundColor: '#fff', borderRadius: '10px', padding: '2rem' }}>
            {/* <label htmlFor='excelUpload'></label> */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', backgroundColor: '#fff', marginBottom: '1rem' }}>
              <input type="file" accept='.xlsx' onChange={(e) => handleFileUpload(e)} style={{ padding: '1rem' }} />
              <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                Click to download Excel Template
                <a
                  href={ExcelTemplate}
                  download="InventoryUpload_ExcelTemplate"
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: 'none' }}
                >
                  <Button label="Download" className={`blue`} />

                </a>
              </div>
            </div>

            {uploadData.length !== 0 ? <PreviewTable columns={columns} data={uploadData} /> : ''}

            <div className="bottom-button-container">
              <Button label='Upload' className='blue' onClick={() => handleUpload()} disabled={uploadData} message='No stocks added' />
              <Button label='Close' className='blue' onClick={() => setBulkPopup(false)} />
            </div>
          </div>
        </div>
        <div className="bg-dark"></div>
      </form>

      {showNFG &&

        <form>
          <div className="AddContainer" >

            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'fit-content', backgroundColor: '#fff', borderRadius: '10px', padding: '2rem' }}>
              
              <div className="head">Grades not found</div>
              <PreviewTable columns={errorColumns} data={nFG} />
              <div className="bottom-button-container">
                <Button label='Close' className='blue' onClick={() => setShowNFG(false)} />
              </div>
            </div>
          </div>
          <div className="bg-dark"></div>
        </form>

      }


    </div>
  )
}

const PreviewTable = (props) => {

  const { data = [], columns = {} } = props
  return (
    <div className="table-container" style={{ height: '300px', overflow: 'scroll' }}>

      <table className='generic-table' >
        <thead>
          <tr>
            {Object.keys(columns).map((data, index) => (
              <th className="table-header" key={index}>{columns[data].label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((data, index) =>
            <tr key={index}>{Object.keys(columns).map(header =>
              <td >{data[header]}</td>
            )}</tr>
          )}
        </tbody>

      </table>

    </div>
  )
}

export default StockBulkUpload