import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
const initialState = {
  columns: {},
  labeledData: [],
  loading: 'idle',
  error: null,
};
 
export const updateEquipmentCategory = createAsyncThunk('data/updateEquipmentCategory', async ({id,type}) => {
    try {
      const response = await api.updateEquipmentCategory(id,type);
      const data = response.data;
      console.log('Update Success');
      return {data,id,type};
    } catch (error) {
      throw error;
    }
  });
 
export const fetchEquipmentCategory = createAsyncThunk('data/fetchEquipmentCategory', async () => {
  try {
    const response = await api.listEquipmentCategory();
    const json = response.data;
    const columnLabels = {
      equipmentCategoryId: { label: 'Equipment Category ID' ,type:'number', isRequired:true,editable:false},
      equipmentCategory: { label: 'Equipment Category', type: 'text',isRequired:true ,editable:true},
      // Add more columns as needed
    };
 
    const selectedColumns = ['equipmentCategoryId', 'equipmentCategory'];
 
    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });
    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });
 
    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});
 
export const postEquipmentCategory = createAsyncThunk('data/postEquipmentCategory', async (newRow) => {
  try {
    const response = await api.addEquipmentCategory(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});
export const deleteEquipmentCategory = createAsyncThunk('data/deleteEquipmentCategory', async (id) => {
  try {
    await api.deleteEquipmentCategory(id);
    return id;
  } catch (error) {
    throw error.response.data;
  }
});
 
const equipmentCategorySlice = createSlice({
  name: 'equipmentCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEquipmentCategory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchEquipmentCategory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchEquipmentCategory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(postEquipmentCategory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postEquipmentCategory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.labeledData.push(action.payload);
        toast.success('Equipment Category added successfully')
      })
      .addCase(postEquipmentCategory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add Equipment Category')
      })
      .addCase(updateEquipmentCategory.pending, (state) => {
       state.loading = 'pending'
      })
      .addCase(updateEquipmentCategory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
       
        const index=state.labeledData.find(item=>item.equipmentCategoryId===action.payload.id);
        if(index){
          const updatedItem={...index,equipmentCategory:action.payload.type.equipmentCategory};
          state.labeledData=state.labeledData.map(item=>{
            if(item.equipmentCategoryId===action.payload.id){
              return updatedItem;
            }
            return item;
          })
          console.log(state.labeledData);
        }
        toast.success('Equipment Category updated successfully')
      })
      .addCase(updateEquipmentCategory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update Equipment Category')
 
      })
      .addCase(deleteEquipmentCategory.pending, (state) => {
        state.loading = 'pending';
        console.log('pending');
      })
      .addCase(deleteEquipmentCategory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        console.log('ok');
        const deletedId = action.payload;
        state.labeledData=state.labeledData.filter((data) => data.equipmentCategoryId !== deletedId);
        toast.success('Equipment Category deleted successfully')
       
      })
      .addCase(deleteEquipmentCategory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error(state.error);
      });
  },
});
 
export default equipmentCategorySlice.reducer;
//End of File