import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import { resetState } from './loginSlice';

const initialState = {
    columns: {},
    labeledData: [],
    loading: 'idle',
    fetcher: true,
    error: null,
};

export const fetchPartners = createAsyncThunk('data/fetchPartners', async () => {
    try {
        const response = await api.listPartners();
        const json = response.data;

        const columnLabels = {


            partnerId: { label: "Partner ID", type: 'number', isRequired: true, pattern: '.*', editable: false },
            organizationName: { label: "Organization Name", type: 'text', isRequired: true, pattern: '.*', editable: true },
            name: { label: "Partner Name", type: 'text', isRequired: true, pattern: '.*', editable: true },
            phone: { label: "Phone No.", type: 'tel', isRequired: true, pattern: '[0-9]{10}', editable: true },
            email: { label: "Email", type: 'email', isRequired: true, pattern: '[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$', editable: true },
            address: { label: "Address", type: 'text', isRequired: true, pattern: '.*', editable: true }
            // Add more as needed
        };

        const selectedColumns = ['partnerId', 'name', 'organizationName', 'phone', 'email', 'address'];

        const columns = {};
        selectedColumns.forEach((columnName) => {
            columns[columnName] = columnLabels[columnName];
        });

        const labeledData = json.map((row) => {
            const rowData = {};
            selectedColumns.forEach((columnName) => {
                rowData[columnName] = row[columnName];
            });
            return rowData;
        });

        return { columns, labeledData };
    } catch (error) {
        throw error;
    }
});
export const postPartners = createAsyncThunk('data/postPartners', async (newRow) => {
    try {

        const response = await api.addPartners(newRow);
        const data = response.data;
        console.log('Success');
        return data;
    } catch (error) {
        throw error;
    }
});
export const deletePartner = createAsyncThunk('data/deletePartner', async (id) => {
    try {
        await api.deletePartner(id);
        return id;
    } catch (error) {
        throw error.response.data;
    }
});
export const updatePartner = createAsyncThunk('data/updatePartner', async ({ id, type }) => {
    try {
        console.log(id);
        const response = await api.updatePartner(id, type);
        const data = response.data;
        console.log('Update Success');
        return { data, id, type };
    } catch (error) {
        throw error;
    }
});
const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(resetState, () => initialState)
            .addCase(fetchPartners.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchPartners.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.columns = action.payload.columns;
                state.labeledData = action.payload.labeledData;
            })
            .addCase(fetchPartners.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(postPartners.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(postPartners.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.labeledData.push(action.payload);
                state.fetcher = !state.fetcher;
                toast.success('Partner added sucessfully')
            })
            .addCase(postPartners.rejected, (state, action) => {
                console.log(action.error);
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to add Partner')
            })
            .addCase(updatePartner.pending, (state) => {
                state.loading = 'pending';

            })
            .addCase(updatePartner.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.fetcher = !state.fetcher;

                const index = state.labeledData.find(item => item.partnerId === action.payload.id);
                if (index) {
                    const updatedItem = { partnerId: action.payload.id, ...action.payload.type };
                    console.log(updatedItem);
                    state.labeledData = state.labeledData.map(item => {
                        if (item.organizationId === action.payload.id) {
                            return updatedItem;
                        }
                        return item;
                    })

                }
                toast.success('Partner updated sucessfully');
            })
            .addCase(updatePartner.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to update Partner');
            })
            .addCase(deletePartner.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(deletePartner.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                const deletedId = action.payload;
                state.labeledData = state.labeledData.filter((data) => data.partnerId !== deletedId);
                toast.success('Partner deleted sucessfully')
            })
            .addCase(deletePartner.rejected, (state, action) => {

                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error(state.error);
            });

    },
});

export default partnerSlice.reducer;
//End of File