import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import GenericTable from '../../components/GenericTable';
import Button from '../../utils/Button';
import '../../assets/styles/ReportPage.css'

const YieldMonitoring = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.YieldMonitoring.columns);
  const originalData = useSelector((state) => state.sdbr.YieldMonitoring.data);
  const [data, setData] = useState(originalData);


  // State variables for filters

  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');




  ;





  const applyFilters = () => {
    // Apply filters to the data
    let filteredData = originalData;
    // Date range filtering logic
    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.orderDate);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }




    setData(filteredData);
  }



  useEffect(() => {
    // Fetch data when the component mounts
    dispatch(fetchDataFromApi('YieldMonitoring')); // Specify the page name
    setData(originalData);
  }, [dispatch, originalData]);



  return (
    <div className='sdbr'>
      <div className='filter-card'>
      <div className="filter-OSH">
        <div className='DateCont'>
          <label className='DateHead'>Plan Date</label>
          <div className="DateFilt">
            <div className="filterD">
              <label htmlFor="fromDateFilter">From</label>
              <input
                id="fromDateFilter"
                type="date"
                value={fromDateFilter}
                onChange={(e) => setFromDateFilter(e.target.value)}
              />
            </div>
            <div className="filterD">
              <label htmlFor="toDateFilter">To</label>
              <input
                id="toDateFilter"
                type="date"
                value={toDateFilter}
                onChange={(e) => setToDateFilter(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="YM-button-container">
          <Button label='Download' className='blue' />
        </div>
        </div>
      </div>

      <div className='head'>Yield Monitoring</div>
      <div>

        <GenericTable columns={columns} data={data} />
      </div>

    </div>
  );
};

export default YieldMonitoring;
