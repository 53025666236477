import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API'; // Import your Axios API functions
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
  columns: {},
  labeledData: [], // Initialize with an empty array
  loading: 'idle',
  error: null,
};

export const fetchCustomers = createAsyncThunk('data/fetchCustomers', async () => {
  try {
    // Fetch data using Axios
    const response = await api.listCustomers();
    const json = response.data;

    const columnLabels = {
      customerId: {label:"Customer ID",type:'number',isRequired:true,pattern:'.*'},
      customerName: {label:"Customer Name",type:'text',editable:true,isRequired:true,pattern:'.*'},
      customerType: {label:"Customer Type",type:'text',editable:true,isRequired:true,pattern:'.*'},
      address: {label:"Address",type:'text',editable:true,isRequired:true,pattern:'.*'},
      town:{label:"Town",type:'text',editable:true,isRequired:true,pattern:'.*'},
      pinCode:{label:"PinCode",type:'tel',editable:true,isRequired:true,pattern:'[1-9][0-9]{5}'},
      phoneNo:{label:"Phone No.",type:'tel',editable:true,isRequired:true,pattern:'[0-9]{10}'},
      fax:{label:"Fax",type:'tel',editable:true,isRequired:false,pattern:'[0-9]{12}'},
      email:{label:"Email",type:'email',editable:true,isRequired:true, pattern:'[a-z0-9._%+\-]+@[a-z0-9.\-]+\.[a-z]{2,}$'}
      // Add more as needed
    };
	

    const selectedColumns = ['customerId', 'customerName', 'customerType','address','town','pinCode','phoneNo','fax','email'];

    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});
export const postCustomers = createAsyncThunk('data/postCustomers', async (newRow) => {
  try {
    // Make a POST request to your API
    const response = await api.addCustomer(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    if(error.response.data.message)
		{toast.error(`${error.response.data.message}`)}
    throw error;
  }
});
export const deleteCustomer = createAsyncThunk('data/deleteCustomer', async (id) => {
  try {
    await api.deleteCustomer(id);
    return id;
  } catch (error) {
    throw error;
  }
});
export const updateCustomer = createAsyncThunk('data/updateCustomer', async ({id, type}) => {
  console.log(type)
  try {
    const response = await api.updateCustomer(id,type);
    const data = response.data;
    console.log('Update Success');
    return {data,id,type};
  } catch (error) {
    throw error;
  }
});
const customerSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomers.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchCustomers.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchCustomers.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(postCustomers.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postCustomers.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.labeledData.push(action.payload);
        toast.success('Customer added successfully!');
      })
      .addCase(postCustomers.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        // toast.error('Failed to add Customer');
      })
      .addCase(deleteCustomer.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteCustomer.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        const deletedId = action.payload;
        state.labeledData=state.labeledData.filter((data) => data.customerId !== deletedId);
        toast.success('Customer deleted successfully!');
      })
      .addCase(deleteCustomer.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error(state.error);
      })
      .addCase(updateCustomer.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
       
        const index=state.labeledData.find(item=>item.customerId===action.payload.id);
        if(index){
          const updatedItem={...index,...action.payload.type};
          state.labeledData=state.labeledData.map(item=>{
            if(item.customerId===action.payload.id){
              return updatedItem;
            }
            return item;
          })
         
        }
        toast.success('Customer updated sucessfully');
      })
      .addCase(updateCustomer.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update Customer');
      })
  },
});

export default customerSlice.reducer;
//End of File