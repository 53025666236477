import React, { useState } from 'react'
import InventoryMaster from './InventoryMaster';
import StockDetails from './StockDetails';
import { useLocation } from 'react-router-dom';

const CombinedInventory = () => {

    const location = useLocation()
    const permission = useState(location.state?.permission);
    const stock = ['Realtime Stock', 'Stock Record'];
    const [stockRadio, setStockRadio] = useState('Stock Record');

    return (
        <div className='sdbr'>
            <div className="head">Stock Details</div>
            <div className="filter-card">

                <div className='filter' style={{ flexDirection: 'column' }}>
                    <label className='DateHead' style={{ marginBottom: '1.5vh' }}>Stocks: </label>
                    <div className="DateFilt" style={{ paddingBottom: '1vh' }}>
                        {stock.map((v) => (
                            <div key={v} className='filterD' style={{}}>
                                <input
                                    type="radio"
                                    name="page-select"
                                    value={v}
                                    checked={v === stockRadio}
                                    onChange={(e) => { setStockRadio(e.target.value) }}
                                    id={v}
                                />
                                <label htmlFor={v} className="stockId-radio">{v}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            {stockRadio === 'Realtime Stock' ?
                <InventoryMaster /> :
                <>
                    <StockDetails permission={permission}/>
                </>
            }
        </div>
    )
}

export default CombinedInventory
