import React, { useEffect, useMemo, useState } from "react";
import * as api from "../../../services/API";
import { AgGridReact } from "ag-grid-react";
import del from "../../../assets/images/button-icons/delete.svg";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import addnew from "../../../assets/images/button-icons/Vector.svg";
import edit from "../../../assets/images/button-icons/edit.svg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchCubeIssues } from "../../../redux-tookit/slices/userFeedBackSlice";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import "./UserFeedBack.css";
import ListGroup from "react-bootstrap/ListGroup";
import "../../../assets/styles/AddNew.css";
import Button from "../../../utils/Button";
import Base64Downloader from "common-base64-downloader-react";

function UserFeedBack() {
  const history = useNavigate();
  const addInputFields = {
    issueName: "",
    pageName: "",
    issueType: "",
    userId: "",
    serviceCenterId: "",
    description: "",
    imageUrl: "",
  };

  const [selectedRows, setSelectedRows] = useState(null);
  const [listOfDatas, setListOfDatas] = useState([]);
  const [values, setValues] = useState(addInputFields);
  const [files, setFiles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const originalData = useSelector((state) => state.fetchCubeIssues);
  const dispatch = useDispatch();
  const [cubeIssues, setCubeIssues] = useState(originalData.data);
  const roleName = localStorage.getItem("roleName");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [downloadModalIsOpen, setDownloadModalIsOpen] = useState(false);
  const [selectedImageUrl, setSelectedImageUrl] = useState("");
  console.log(listOfDatas, "listOfDatas");
  const openImageModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setModalIsOpen(true);
  };

  const openDownloadModal = (imageUrl) => {
    setSelectedImageUrl(imageUrl);
    setDownloadModalIsOpen(true);
  };
  const closeImageModal = () => {
    setModalIsOpen(false);
  };
  const closeDownloadModal = () => {
    setDownloadModalIsOpen(false);
  };

  const toggleModal = () => {
    if (selectedRows !== null && selectedRows !== undefined) {
      setIsOpen(true);
    } else {
      toast.error("Please select row to Update");
    }
  };

  const toggleModalClose = () => {
    setSelectedRows(null);
    setIsOpen(false);
  };

  const issueTypes = ["Critical", "Major", "Minor"];

  const columnDefs = [
    {
      headerName: "Sl No",
      valueGetter: "node.rowIndex + 1",
      checkboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      headerCheckboxSelection: true,
      width: "100px",
    },
    { headerName: "Issue Name", field: "issueName", width: "210px" },
    { headerName: "Issue Type", field: "issueType", width: "100px" },
    { headerName: "Page Name", field: "pageName", width: "210px" },
    { headerName: "Description", field: "description", width: "500px" },
    { headerName: "Status", field: "status", width: "120px" },
    { headerName: "Service Center Name", field: "name", width: "250px" },
    { headerName: "Created By", field: "userNo" },
    { headerName: "Created Ts", field: "createdTs", width: "150px" },
    {
      headerName: "Images",
      field: "imageUrl",
      width: "200px",
      cellRenderer: (params) => {
        const imageUrl = params.value;

        const handleViewImage = () => {
          console.log(imageUrl);
          if (imageUrl) {
            openImageModal(imageUrl);
          } else {
            toast.error("No files available.");
          }
        };

        const handleDownloadImage = () => {
          if (imageUrl) {
            openDownloadModal(imageUrl);
          } else {
            toast.error("No files available.");
          }
        };

        return (
          <div className="bottom-button-container">
            {imageUrl && imageUrl?.length !== 0 ? <>
              <Button className="blue" label="View" onClick={handleViewImage} />
              <Button
                className="blue"
                label="Download"
                onClick={handleDownloadImage}
              /></> : 'No files available.'}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    dispatch(fetchCubeIssues());
  }, [dispatch]);

  console.log(originalData, "originalData");
  useEffect(() => {
    setCubeIssues(
      originalData.data.map((item) => {
        const createdTs = item.createdTs.replace("T", " ").split(".")[0];
        const statusLabel = item.status === 1 ? "Open" : "Closed";
        const capitalizeFirstLetter = (str) => {
          return str.charAt(0).toUpperCase() + str.slice(1);
        };
        const capitalizedItem = {};
        Object.keys(item).forEach((key) => {
          capitalizedItem[key] =
            typeof item[key] === "string"
              ? capitalizeFirstLetter(item[key])
              : item[key];
        });

        return {
          ...capitalizedItem,
          status: statusLabel,
          createdTs: moment(createdTs).format("DD-MM-YYYY"),
        };
      })
    );
  }, [originalData]);

  useEffect(() => {
    if (selectedRows) {
      const selectedIssueId = selectedRows?.issueId;
      let selectedIssueData = originalData?.data.find(
        (item) => item.issueId === selectedIssueId
      );

      if (selectedIssueData) {
        let downloadsArray = [];
        Object.entries(selectedIssueData).forEach(([key, values]) => {
          if (
            typeof values === "object" &&
            Array.isArray(values) &&
            values.length > 0 &&
            typeof values[0] === "object" &&
            values[0].string
          ) {
            values.forEach((value) => {
              let newObj = {
                key,
                string: value.string,
                filename: value.filename,
              };
              downloadsArray.push(newObj);
            });
          }
        });

        setListOfDatas(downloadsArray);
      } else {
        setListOfDatas([]);
      }
    } else {
      setListOfDatas([]);
    }
  }, [selectedRows, originalData]);

  const onSelectionChanged = (event) => {
    const selectedNodes = event.api.getSelectedNodes();
    const selectedData = selectedNodes.map((node) => node.data);
    setSelectedRows(selectedData[0]);
    setValues(selectedData[0]);
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const gridOptions = {
    rowSelection: "single",
    onSelectionChanged: onSelectionChanged,
  };

  const deleteCubeIssues = async () => {
    if (selectedRows === null || selectedRows === undefined) {
      toast.error("Please select row to delete.");
      return;
    } else if (selectedRows?.status === "Closed") {
      toast.error("The selected row feedback already Closed");
      return;
    }

    await api
      .delCubeIssues([selectedRows?.issueId])
      .then((result) => {
        toast.success(result?.data?.message);
        dispatch(fetchCubeIssues());
      })
      .catch((err) => {
        console.log(err);
      });
  };
  console.log(selectedRows, "selectedRows");

  const updateCubeIssues = async (e) => {
    e.preventDefault();
    try {


      if (selectedRows === null) {
        toast.error("Please select row to Update.");
        return;
      }
      const payload = {
        ...values,
        imageUrl: files.map((file) => ({
          string: file.string,
          type: file.type,
          filename: file.filename,
        })),
      };
      await api
        .updateCubeIssue([selectedRows?.issueId], payload)
        .then((result) => {
          toast.success(result?.data?.message);
          dispatch(fetchCubeIssues());
          setSelectedRows(null);
          setIsOpen(false);
        })
        .catch((err) => {
          console.log(err);
        });

    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };



  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileChange = async (e) => {
    const selectedFiles = e.target.files;
    if (selectedFiles.length > 5) {
      toast.error("Max 5 file selection allowed!");
      return;
    }

    const newFiles = await Promise.all(
      Array.from(selectedFiles).map(async (file) => {
        const base64 = await convertBase64(file);
        return {
          string: base64,
          type: file.type,
          filename: file.name,
        };
      })
    );

    setFiles(newFiles);

    setValues((prevValues) => ({
      ...prevValues,
      imageUrl: newFiles.length > 0 ? newFiles[0].string : "",
    }));
  };
  const removeFile = (filenameToRemove) => {
    // Filter out the file to be removed
    setFiles((prevFiles) => {
      return prevFiles.filter((file) => file.filename !== filenameToRemove);
    });

    setListOfDatas((prevFiles) => {
      return prevFiles.filter((file) => file.filename !== filenameToRemove);
    });
  };

  const defaultColDef = useMemo(() => {

    return {
      initialWidth: 150,
      sortable: true,
      enableCellChangeFlash: true,
      resizable: true,
      filter: true,
      autoHeaderHeight: true,
      autoHeight: true
    }

  }, []);

  return (
    <div className="sdbr">
      {!isOpen && (
        <>
          <div className="head">
            <div> Feedback Details</div>
            <div className="top-button-container">
              <Link to="/Add-Issue">
                <img src={addnew} alt="Add New" />
              </Link>
              <img src={edit} alt="Edit" onClick={toggleModal} />
              {roleName === "superadmin" && (
                <img src={del} alt="Delete" onClick={deleteCubeIssues} />
              )}
            </div>
          </div>
          <div
            className="ag-theme-alpine"
            style={{
              height: 490,
              width: "100%",
            }}
          >
            <AgGridReact
              rowData={cubeIssues}
              columnDefs={columnDefs}
              gridOptions={gridOptions}
              defaultColDef={defaultColDef}
            />
          </div>
        </>
      )}

      {isOpen && selectedRows?.issueId && (
        <div className="sdbr">
          <div className="head">Update Feedback</div>
          <form onSubmit={updateCubeIssues}>
            <div className="add-new-order">
              <div className="order-details">
                <div className="row-1">
                  <div className="order-fields one">
                    <label htmlFor="issueName">
                      Issue Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="issueName"
                      name="issueName"
                      value={values.issueName}
                      required
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="order-fields one">
                    <label htmlFor="pageName">
                      Page Name <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      id="pageName"
                      name="pageName"
                      required
                      value={values.pageName}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="order-fields one">
                    <label htmlFor="issueType">
                      Issue Type <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      type="select"
                      id="issueType"
                      name="issueType"
                      required
                      onChange={handleInputChange}
                      value={values.issueType}
                    >
                      <option value="" hidden>
                        -Select-
                      </option>
                      {issueTypes.map((issue, index) => (
                        <option key={index} value={issue}>
                          {issue}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row-2" style={{ alignItems: "flex-start" }}>
                  <div className="order-fields one">
                    <label htmlFor="description">Description</label>
                    <textarea
                      style={{ height: "80px" }}
                      type="text"
                      id="description"
                      name="description"
                      value={values.description}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="order-fields one">
                    <label htmlFor="imageUrl" style={{ marginBottom: "15px" }}>
                      Image Upload
                    </label>
                    <input
                      type="file"
                      id="imageUrl"
                      name="imageUrl"
                      multiple // Allow multiple files
                      onChange={handleFileChange}
                    />
                    {listOfDatas.length > 0 ? (
                      <div>
                        <b>Uploaded Files:</b>
                        {listOfDatas.map((item, index) => (
                          <div
                            key={index} // Key should be unique, use index here
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <span>{item.filename}</span>
                            <button
                              onClick={() => removeFile(item.filename)} // Pass filename directly
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                                background: "none",
                                border: "none",
                                color: "red",
                              }}
                            >
                              ✖
                            </button>
                          </div>
                        ))}
                      </div>
                    ) : (
                      "No files Added"
                    )}

                    <p></p>
                  </div>
                </div>
                <div className="bottom-button-container">
                  <Button
                    className="blue"
                    type="submit"
                    label="Save"
                  />
                  <Button className="blue" label="Close" onClick={toggleModalClose} />
                </div>
              </div>
            </div>
          </form>


        </div>
      )}
      {modalIsOpen && (
        <div className="modal_background">
          <div className="contentBody" contentLabel="File Modal">
            <div className="title">View Uploaded Files</div>
            <div className="prerequisiteModel">
              {selectedImageUrl !== "" && (
                <ListGroup>
                  {listOfDatas && listOfDatas.length > 0
                    ? listOfDatas.map((item, i) => {
                      const fileExtension = item.filename
                        .split(".")
                        .pop()
                        .toLowerCase();
                      let fileContent;

                      if (fileExtension === "pdf") {
                        fileContent = (
                          <embed
                            src={item.string}
                            type="application/pdf"
                            width="150%"
                            height="700"
                            style={{ border: "none" }}
                            alt="PDF Preview"
                          />
                        );
                      } else if (
                        ["jpg", "jpeg", "png"].includes(fileExtension)
                      ) {
                        fileContent = (
                          <img
                            src={item.string}
                            alt="view"
                            style={{
                              width: "100%",
                              height: "auto",
                              background: "#11133d",
                            }}
                          />
                        );
                      } else if (["xls", "xlsx"].includes(fileExtension)) {
                        fileContent = (
                          <div>
                            <p>
                              Excel files cannot be previewed directly in the
                              browser.
                              <a
                                href={item.string}
                                title={item.filename}
                                download={item.filename}
                              >
                                Download Excel File
                              </a>
                            </p>
                          </div>
                        );
                      } else if (["zip"].includes(fileExtension)) {
                        fileContent = (
                          <div>
                            <p>
                              Zip files cannot be previewed directly in the
                              browser.
                              <a
                                href={item.string}
                                title={item.filename}
                                download={item.filename}
                              >
                                Download Zip File
                              </a>
                            </p>
                          </div>
                        );
                      } else {
                        fileContent = <p>Unsupported file type.</p>;
                      }

                      return (
                        <ListGroup.Item key={i} className="listBox2">
                          {fileContent}
                        </ListGroup.Item>
                      );
                    })
                    : "No Files To Show !"}
                </ListGroup>
              )}
            </div>
            <div className="bottom-button-container">
              <Button
                className="blue"
                label="Close"
                onClick={closeImageModal}
              />
            </div>
          </div>
        </div>
      )}

      {downloadModalIsOpen && (
        <div className="modal_background">
          <div className="contentBody" contentLabel="Image Modal">
            <div className="title">Uploaded Files</div>
            <div className="downLoadModel">
              {selectedImageUrl !== "" && (
                <ListGroup>
                  {listOfDatas && listOfDatas.length > 0
                    ? listOfDatas.map((item, i) => {
                      return (
                        <ListGroup.Item key={i} className="listBox2">
                          <span title={item.filename}>
                            {capitalizeFirstLetter(item.filename).replace(
                              /_/g,
                              " "
                            )}
                            {/* {item.filename.length < 40 ? (
                                capitalizeFirstLetter(
                                  item.filename.replace(/_/g, " ")
                                )
                              ) : (
                                <>
                                  {item.filename.length > 40}{" "}
                                  <span title={item.filename}>
                                    {capitalizeFirstLetter(
                                      item.filename.replace(/_/g, " ")
                                    ).slice(0, 40)}
                                    ...
                                  </span>
                                </>
                              )} */}
                          </span>
                          <Base64Downloader
                            style={{
                              background: "#11133d",
                              color: "#fff",
                              fontSize: ".7em",
                            }}
                            className="buttonCss"
                            base64={item.string}
                            downloadName={item.filename
                              .split(".")
                              .slice(0, -1)
                              .join(".")}
                          >
                            <span title={item.filename}>Download</span>
                          </Base64Downloader>
                        </ListGroup.Item>
                      );
                    })
                    : "No Files To Show !"}
                </ListGroup>
              )}
            </div>
            <div className="bottom-button-container">
              <Button
                className="blue"
                label="Close"
                onClick={closeDownloadModal}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default UserFeedBack;
