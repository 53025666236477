import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const initialState = {
  columns: {},
  labeledData: [],
  columns2: {},
  labeledData2: [],
  summaryColumns: {},
  stockSummary: [],
  loading: 'idle',
  error: null,
  errorData: []
};

// READY BUILD INVENTORY

export const fetchInventory = createAsyncThunk('data/fetchInventory', async () => {
  try {
    const response = await api.listInventory();
    const json = response.data;

    const columnLabels = {
      inventoryId: { label: 'inventoryId' },
      material: { label: 'Material' },
      diameter: { label: 'Diameter' },
      length: { label: 'Length' },
      weight: { label: 'Weight (T)' },
      totalPieces: { label: 'Total Pieces' },
      stockType: { label: 'Stock Type' },
      gradeId: { label: 'Grade Id' },
      gradeName: { label: 'Grade' }
      // Add more as needed
    };

    const summaryColumns = {
      stockType: { label: 'Stock Type' },
      weight: { label: 'Weight (T)' },
      totalPieces: { label: 'Total Pieces' }
    }


    const selectedColumns = [
      'gradeName',
      'length',
      'diameter',
      'material',
      'stockType',
      'weight',
      'totalPieces',
      'gradeId',
      'inventoryId',
    ]

    const columns = {};

    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    const stockType = [...new Set(labeledData.map(data => data.stockType))]

    let stockSummary = []

    stockType.forEach((item) => {

      let weightSum = 0;
      let pieceSum = 0
      labeledData.forEach((data) => {
        if (data.stockType === item) {
          weightSum += data.weight * 1;
          pieceSum += data.totalPieces * 1
        }
      })

      stockSummary = [...stockSummary, { stockType: item, weight: Math.floor(weightSum * 1000) / 1000, totalPieces: pieceSum }]
    })

    return { columns, labeledData, stockSummary, summaryColumns };
  } catch (error) {
    throw error;
  }
});

export const updateInventory = createAsyncThunk('data/postInventory', async ({ id, type }) => {

  try {
    const response = await api.putInventory(id, type);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const convertShortLength = createAsyncThunk('data/convertShortLength', async (newRow) => {

  try {
    const response = await api.convertRmInventory(newRow)
    const data = response.data;
    console.log('Success');
    toast.success('Stock uploaded sucessfully');
    return data;
  } catch (error) {
    if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
    else toast.error('Error in Converting Stock')
    throw error;
  }
});

export const stockBulkUpload = createAsyncThunk('data/stockBulkUpload', async (newRow) => {

  try {
    const response = await api.inventoryUpload(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    console.log(error.response);
    if (error.response.status === 409) {

    } else {
      toast.error('Error in Uploading Stocks')
    }
    // if (error?.response?.data?.message) { toast.error(`${error?.response?.data?.message}`) }
    // else toast.error('Error in Uploading Stocks')
    return error;
  }
});

// READY BUILD RM RECEIPT

export const updateRmInventory = createAsyncThunk('data/updateRmInventory', async ({ id, type }) => {
  //console.log(editData)
  try {
    const response = await api.updateRmInventory(id, type);
    const data = response.data;
    console.log('Update Success');
    return { data, id, type };
  } catch (error) {
    throw error;
  }
});

export const fetchRmInventory = createAsyncThunk('data/fetchRmInventory', async () => {
  try {
    const response = await api.listRmInventory();
    const json = response.data;

    const columnLabels = {
      rmReceiptId: { label: 'Id', type: 'number', editable: false },
      vehicleNo: { label: 'Vehicle No.', type: 'text', isRequired: true, editable: true },
      invoiceNumber: { label: 'Invoice No.', type: 'text', isRequired: true, editable: true },
      invoiceType: { label: 'Invoice Type', type: 'text', isRequired: true, editable: true },
      invoiceWeight: { label: 'Invoice Weight', type: 'number', isRequired: true, editable: true },
      grossWeight: { label: 'Gross Weight', type: 'number', isRequired: true, editable: true },
      tierWeight: { label: 'Tare Weight', type: 'number', isRequired: true, editable: true },
      netWeight: { label: 'Net Weight', type: 'number', isRequired: true, editable: true },
      description: { label: 'Description', type: 'textarea', isRequired: true, editable: true },
      gradeId: { label: 'Grade ID', type: 'number', isRequired: true, editable: false },
      gradeName: { label: 'Grade', type: 'text', isRequired: true, editable: false },
      barType: { label: 'Bar Type', type: 'text', isRequired: true, editable: false },
      diameter: { label: 'Diameter', type: 'number', isRequired: true, editable: false },
      material: { label: 'Material', type: 'text', isRequired: true, editable: false },
      minLength: { label: 'Min Length (mm)', type: 'number', isRequired: true, editable: true },
      maxLength: { label: 'Max Length (mm)', type: 'number', isRequired: true, editable: true },
      noOfPieces: { label: 'Total Pieces', type: 'number', isRequired: true, editable: true },
      grossWeightTs: { label: 'Gross Weight Timestamp', type: 'date', isRequired: true, editable: true },
      unloadedTs: { label: 'Unloading Timestamp', type: 'date', isRequired: false, editable: true },

      materialType: { label: 'Stock Type', type: 'text', isRequired: false, editable: false },
      batchNo: { label: 'Batch No', type: 'text', isRequired: false, editable: true },
      // Add more as needed
    };

    const selectedColumns = ['rmReceiptId', 'vehicleNo', 'invoiceNumber', 'invoiceType', 'gradeName', 'gradeId', 'diameter', 'materialType', 'barType', 'material', 'minLength', 'maxLength', 'batchNo', 'invoiceWeight', 'grossWeight', 'tierWeight', 'netWeight', 'noOfPieces', 'grossWeightTs', 'unloadedTs', 'description'];

    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });
    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});

export const postRmInventory = createAsyncThunk('data/postRmInventory', async (newRow) => {

  try {
    const response = await api.addRmInventory(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const postMultiInventory = createAsyncThunk('data/postMultiInventory', async (newRow) => {

  try {
    const response = await api.addMultipleInventory(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const deleteRmInventory = createAsyncThunk('data/deleteInventory', async (id) => {
  try {
    console.log(id);
    await api.deleteRmInventory(id);
    return id;
  } catch (error) {
    throw error;
  }
});

// SMART FAB INVENTORY

export const fetchInventorySF = createAsyncThunk('data/fetchInventorySF', async () => {
  try {
    const response = await api.listInventorySF();
    const json = response.data;

    const columnLabels = {
      inventoryId: { label: 'inventoryId' },
      material: { label: 'Material' },
      diameter: { label: 'Diameter' },
      length: { label: 'Length' },
      weight: { label: 'Weight (T)' },
      totalPieces: { label: 'Total Pieces' },
      stockType: { label: 'Stock Type' },
      gradeId: { label: 'Grade Id' },
      gradeName: { label: 'Grade' },
      // productName:{ label: 'Product Name'}
      // Add more as needed
    };

    const summaryColumns = {
      stockType: { label: 'Stock Type' },
      weight: { label: 'Weight (T)' },
      totalPieces: { label: 'Total Pieces' }
    }


    const selectedColumns = [
      'gradeName',
      'length',
      'diameter',
      'material',
      'stockType',
      'weight',
      'totalPieces',
      'gradeId',
      'inventoryId',
      // 'productName'
    ]

    const columns = {};

    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    const stockType = [...new Set(labeledData.map(data => data.stockType))]

    let stockSummary = []

    stockType.forEach((item) => {

      let weightSum = 0;
      let pieceSum = 0
      labeledData.forEach((data) => {
        if (data.stockType === item) {
          weightSum += data.weight * 1;
          pieceSum += data.totalPieces * 1
        }
      })

      stockSummary = [...stockSummary, { stockType: item, weight: Math.floor(weightSum * 1000) / 1000, totalPieces: pieceSum }]
    })

    return { columns, labeledData, stockSummary, summaryColumns };
  } catch (error) {
    throw error;
  }
});

export const updateInventorySF = createAsyncThunk('data/postInventorySF', async ({ id, type }) => {

  try {
    const response = await api.putInventorySF(id, type);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const updateRmInventorySF = createAsyncThunk('data/updateRmInventorySF', async ({ id, type }) => {
  //console.log(editData)
  try {
    const response = await api.updateRmInventorySF(id, type);
    const data = response.data;
    console.log('Update Success');
    return { data, id, type };
  } catch (error) {
    throw error;
  }
});

export const fetchRmInventorySF = createAsyncThunk('data/fetchRmInventorySF', async () => {
  try {
    const response = await api.listRmInventorySF();
    const json = response.data;

    const columnLabels = {
      rmReceiptId: { label: 'Id', type: 'number', editable: false },
      vehicleNo: { label: 'Vehicle No.', type: 'text', isRequired: true, editable: true },
      invoiceNumber: { label: 'Invoice No.', type: 'text', isRequired: true, editable: true },
      invoiceType: { label: 'Invoice Type', type: 'text', isRequired: true, editable: true },
      invoiceWeight: { label: 'Invoice Weight', type: 'number', isRequired: true, editable: true },
      grossWeight: { label: 'Gross Weight', type: 'number', isRequired: true, editable: true },
      tierWeight: { label: 'Tare Weight', type: 'number', isRequired: true, editable: true },
      netWeight: { label: 'Net Weight', type: 'number', isRequired: true, editable: true },
      description: { label: 'Description', type: 'textarea', isRequired: true, editable: true },
      gradeId: { label: 'Grade ID', type: 'number', isRequired: true, editable: false },
      smartFabProductType: { label: 'Product Type', type: 'text', isRequired: true, editable: false },
      barType: { label: 'Bar Type', type: 'text', isRequired: true, editable: false },
      diameter: { label: 'Diameter', type: 'number', isRequired: true, editable: false },
      material: { label: 'Material', type: 'text', isRequired: true, editable: false },
      minLength: { label: 'Min Length(mm)', type: 'number', isRequired: true, editable: false },
      maxLength: { label: 'Max Length(mm)', type: 'number', isRequired: true, editable: false },
      noOfPieces: { label: 'Total Pieces', type: 'number', isRequired: true, editable: true },
      grossWeightTs: { label: 'Gross Weight Timestamp', type: 'date', isRequired: true, editable: true },
      unloadedTs: { label: 'Unloading Timestamp', type: 'date', isRequired: false, editable: true },

      materialType: { label: 'Stock Type', type: 'text', isRequired: false, editable: false },
      batchNo: { label: 'Batch No', type: 'text', isRequired: false, editable: true },
      // Add more as needed
    };

    // "rmReceiptId": 4,
    // "vehicleNo": "test veh",
    // "serviceCenterId": 36,
    // "serviceCenterName": "Test service center",
    // "region": "West",
    // "invoiceNumber": "tsl 1212",
    // "invoiceType": "TSL Invoice",
    // "invoiceWeight": 20,
    // "grossWeight": 0,
    // "tierWeight": 0,
    // "netWeight": 20,
    // "tolarence": 0,
    // "description": null,
    // "grossWeightTs": "2024-10-22T01:17:00 PM",
    // "unloadedTs": "2024-10-22T01:17:00 PM",
    // "status": 1,
    // "gradeId": 37,
    // "material": "TISCON TMT FE500D CRS AIR COOL 8.00mm",
    // "diameter": 8,
    // "smartFabProductTypeId": 3,
    // "smartFabProductType": "Ai

    const selectedColumns = [
      'rmReceiptId', 
      'vehicleNo', 
      'invoiceNumber', 
      'invoiceType', 
      'smartFabProductType',
      'gradeId', 
      'diameter', 
      'material', 
      // 'materialType', 
      // 'barType', 
      // 'minLength', 
      // 'maxLength',
      // 'batchNo', 
      'invoiceWeight', 
      'grossWeight', 
      'tierWeight', 
      'netWeight', 
      // 'noOfPieces', 
      'grossWeightTs', 
      'unloadedTs', 
      'description'
    ];

    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });
    console.log(columns, labeledData );
    
    return { columns, labeledData };
  } catch (error) {
    console.log(error);
    
    throw error;
  }
});

export const postRmInventorySF = createAsyncThunk('data/postRmInventorySF', async (newRow) => {

  try {
    const response = await api.addRmInventorySF(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const postMultiInventorySF = createAsyncThunk('data/postMultiInventorySF', async (newRow) => {

  try {
    const response = await api.addMultipleInventorySF(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const deleteRmInventorySF = createAsyncThunk('data/deleteInventorySF', async (id) => {
  try {
    console.log(id);
    await api.deleteRmInventorySF(id);
    return id;
  } catch (error) {
    throw error;
  }
});


const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

    // READY BUILD

      .addCase(fetchRmInventory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchRmInventory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchRmInventory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })

      .addCase(fetchInventory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchInventory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns2 = action.payload.columns;
        state.labeledData2 = action.payload.labeledData;
        state.summaryColumns = action.payload.summaryColumns;
        state.stockSummary = action.payload.stockSummary;
      })
      .addCase(fetchInventory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })

      .addCase(postRmInventory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postRmInventory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
        state.labeledData.push(action.payload);
        console.log(action.payload)
        toast.success('Stock Added successfully')
      })
      .addCase(postRmInventory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add stock')
      })

      .addCase(postMultiInventory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postMultiInventory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
        state.labeledData.push(action.payload);
        console.log(action.payload)
        toast.success('Stock Added successfully')
      })
      .addCase(postMultiInventory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add stock')
      })


      .addCase(updateInventory.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateInventory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
        // state.labeledData.push(action.payload);
        console.log(action.payload)
        toast.success('Stock Updated successfully')
      })
      .addCase(updateInventory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to Update Stock')
      })


      .addCase(convertShortLength.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(convertShortLength.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
        toast.success('Stock Converted successfully')
      })
      .addCase(convertShortLength.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;

      })
     
      .addCase(updateRmInventory.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(updateRmInventory.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;

        const index = state.labeledData.find(item => item.organizationId === action.payload.id);
        if (index) {
          const updatedItem = { ...index, organizationName: action.payload.type.organizationName };
          state.labeledData = state.labeledData.map(item => {
            if (item.organizationId === action.payload.id) {
              return updatedItem;
            }
            return item;
          })

        }
        toast.success('Stock updated sucessfully');
      })
      .addCase(updateRmInventory.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update Stock');
      })

      .addCase(stockBulkUpload.pending, (state) => {
        state.loading = 'pending';
      })
      // .addCase(stockBulkUpload.rejected, (state, action) => {
      //   state.loading = 'rejected';
      //   console.log(action.error);
      //   // state.error = action.error.message;
      //   // console.log('failed')
      //   toast.error('Failed to upload stock')
      // })
      .addCase(stockBulkUpload.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        // console.log('Success');
        console.log(action.error);
        // console.log(action.error);
        // toast.success('Stock uploaded sucessfully');
      })
      

      // SMART FAB

      .addCase(fetchInventorySF.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchInventorySF.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns2 = action.payload.columns;
        state.labeledData2 = action.payload.labeledData;
        state.summaryColumns = action.payload.summaryColumns;
        state.stockSummary = action.payload.stockSummary;
      })
      .addCase(fetchInventorySF.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })

      .addCase(updateInventorySF.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateInventorySF.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
        // state.labeledData.push(action.payload);
        console.log(action.payload)
        toast.success('Stock Updated successfully')
      })
      .addCase(updateInventorySF.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to Update Stock')
      })

      .addCase(fetchRmInventorySF.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchRmInventorySF.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchRmInventorySF.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(deleteRmInventorySF.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteRmInventorySF.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        const deletedId = action.payload;
        console.log('Success');
        state.fetcher = !state.fetcher;
        state.labeledData = state.labeledData.filter((data) => data.rmReceiptId !== deletedId);
        toast.success('Stock deleted successfully');
      })
      .addCase(deleteRmInventorySF.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        console.log('failed')
        toast.error('Failed to delete stock')
      })
      .addCase(updateRmInventorySF.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(updateRmInventorySF.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;

        const index = state.labeledData.find(item => item.organizationId === action.payload.id);
        if (index) {
          const updatedItem = { ...index, organizationName: action.payload.type.organizationName };
          state.labeledData = state.labeledData.map(item => {
            if (item.organizationId === action.payload.id) {
              return updatedItem;
            }
            return item;
          })

        }
        toast.success('Stock updated sucessfully');
      })
      .addCase(updateRmInventorySF.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update Stock');
      })

      .addCase(postRmInventorySF.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postRmInventorySF.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
        state.labeledData.push(action.payload);
        console.log(action.payload)
        toast.success('Stock Added successfully')
      })
      .addCase(postRmInventorySF.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add stock')
      })

      .addCase(postMultiInventorySF.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postMultiInventorySF.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.fetcher = !state.fetcher;
        state.labeledData.push(action.payload);
        console.log(action.payload)
        toast.success('Stock Added successfully')
      })
      .addCase(postMultiInventorySF.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add stock')
      })

  },
});

export default inventorySlice.reducer
//End of File