// Login.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchToken, login, logout } from "../redux-tookit/slices/loginSlice";
import { useNavigate } from "react-router-dom";
import "../assets/styles/Login.css";
import Button from "../utils/Button";
import bigBg from "../assets/images/login-page/login-Rbg.png";
import orgIcon from "../assets/images/login-page/Cube-logo.jpg";
import checkCircle from "../assets/images/login-page/circle-check.svg";
import TATAlogo from "../assets/images/login-page/TATA-logo.png";
import TELlogo from "../assets/images/login-page/TEL-logo.png";
import { setNavOptions } from "../redux-tookit/slices/navbarSlice";
import { IoIosEyeOff, IoIosEye } from "react-icons/io";
import TETHlogo from '../assets/images/icons/content-bottom-logo.svg'
import { fetchUserAccess } from "../redux-tookit/slices/userAccessSlice";

const Login = () => {
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.login.currentUser);
  const error = useSelector((state) => state.login.error);
  const loading = useSelector((state) => state.login.loading);
  const navigate = useNavigate();
  sessionStorage.removeItem("token");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [renderCounter, setRenderCounter] = useState(0);
  const [idType, setIdType] = useState("Username / Email");
  const [userNo, setUserNo] = useState("");

  useEffect(() => {
    navigate("/");
  }, []);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // const refreshPage = () => {
  //   window.location.reload(false);
  // }

  const handleLogin = async (e) => {
    e.preventDefault();
    let newRow = { password };
    // if (idType === 'Username') {
    newRow = { ...newRow, email: userNo };
    // } else {
    //   newRow = { ...newRow, email }
    // }

    

    dispatch(login(newRow)).then((res) => {
      if (res.payload) {
        // sessionStorage.setItem("navSelectedOption", "Order Details");
        // sessionStorage.setItem("navExpandedOption", "Transaction");
        sessionStorage.setItem("token", res.payload.data.accessToken);
        if (res.payload.data.passwordStatus === 1) {
          dispatch(
            setNavOptions({ expOpt: "Settings", selOpt: "Update Password" })
          );
          dispatch(
            fetchUserAccess()
          )
          navigate("/update password");
        } else 
        
        if (res.payload.data.roleName !== "admin") {
          dispatch(
            setNavOptions({ expOpt: "Reports", selOpt: "Production History" })
          );
          dispatch(
            fetchUserAccess()
          )
          navigate("/production history");
        } else {
          dispatch(
            setNavOptions({ expOpt: "Transaction", selOpt: "Order Details" })
          );
          navigate("/order details");
        }
      }
    });

  };

  useEffect(() => {
    dispatch(logout());
  }, []);

  // // Redirect to a new page on successful login
  // if (currentUser) {

  //   navigate('/order details'); // Replace '/dashboard' with the desired route
  // }

  return (
    <div className="login">
      <div className="bigBg" />
      <div className="login-page">
        {/* <div>
          <h2 className='login-head'>Login</h2>
        </div> */}
        <div className="login-bg">
          <img src={orgIcon} alt="User" className="orgIcon" />
          <div className="TELTETH">
            <img src={TELlogo} alt="" className="TEL-logo" />
            <div className="TETH">
              Powered by<img src={TETHlogo} alt="" className="TETHlogo" />
            </div>
          </div>


          <img src={TATAlogo} alt="" className="TATA-logo" />
        </div>

        <form className="loginForm" onSubmit={handleLogin}>
          {/* <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>

            <div>
              <input type="radio" name='idType' id='Username' value='Username' onChange={e => setIdType(e.target.value)} checked={'Username' === idType} />
              <label htmlFor="Username">Username</label>
            </div>
            <div>
              <input type="radio" name='idType' id='Email' value='Email' onChange={e => setIdType(e.target.value)} checked={'Email' === idType} />
              <label htmlFor="Email" >Email </label>
            </div>

          </div> */}

          <div className="loginElement email">
            <label
              style={{ color: "#001659", fontSize: "26px", fontWeight: "700" }}
            >
              {idType}
            </label>
            {/* {idType === 'Email' ?
              <input type="email" value={email} onChange={handleEmailChange} required placeholder='Email' /> : */}
            <input
              type="text"
              value={userNo}
              onChange={(e) => setUserNo(e.target.value)}
              required
              placeholder="Username / Email"
              onKeyDown={(e) => { if (['<','>','=','-'].includes(e.key)) e.preventDefault() }}
            />
            {/* } */}
          </div>
          <div className="loginElement password">
            <label
              style={{ color: "#001659", fontSize: "26px", fontWeight: "700" }}
            >
              Password
            </label>
            <div
              style={{
                display: "flex",
                position:'relative',
                justifyContent: "space-between",
                // width: "100%",
                padding: "0",
                borderRadius: "5px",
                border: "2px solid #DEE2EC",
                // fontSize: "medium",
              }}
            >
              <input
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={handlePasswordChange}
                required
                placeholder="Password"
                style={{
                  padding: "20px 10px",
                  border: "0",
                  borderRadius: "5px",
                  fontSize: "medium",
                  width: "100%",
                }}
              />
              <button
                type="button"
                style={{
                  padding: "20px",
                  border: "0",
                  borderRadius: "5px",
                  fontSize: "medium",
                  paddingBlock: "1px",
                  paddingInline: "10px",
                  position: "absolute",
                  right: "0",
                  background: "none",
                  height:'100%'
                }}
                onClick={togglePasswordVisibility}
              >
                {showPassword ? <IoIosEye /> : <IoIosEyeOff />}
              </button>
            </div>
          </div>
          <div className="login-button">
            <button type="submit">Log in</button>
          </div>
          <div className="login-button" style={{ height: '20px' }}>
            {loading === "pending" ? (
              <>
                <div
                  className="circle-loader2"
                  style={{ position: "relative" }}
                ></div>
              </>
            ) : (
              error && <p style={{ color: "red", fontSize: "20px" }}>{error}</p>
            )}
          </div>
        </form>
      </div>
      <div className="login-background"></div>
    </div>
  );
};

export default Login;
//End of File
