import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'

const initialState = {
    columns: {},
    labeledData: [],
    loading: 'idle',
    error: null,
};

//Ready Build

export const fetchProcessType = createAsyncThunk('data/fetchProcessType', async () => {
    try {

        const response = await api.listProcessType();
        const json = response.data;

        const columnLabels = {
            processTypeId: { label: 'Process Type ID', type: 'number', editable: false },
            processType: { label: 'Process Type', type: 'text', editable: true },
            // Add more as needed
        };

        const selectedColumns = ['processTypeId', 'processType'];

        const columns = {};
        selectedColumns.forEach((columnName) => {
            columns[columnName] = columnLabels[columnName];
        });

        const labeledData = json.rows.map((row) => {
            const rowData = {};
            selectedColumns.forEach((columnName) => {
                rowData[columnName] = row[columnName];
            });
            return rowData;
        });

        return { columns, labeledData };
    } catch (error) {
        throw error;
    }
});

export const postProcessType = createAsyncThunk('data/postProcessType', async (newRow) => {
    try {

        const response = await api.addProcessType(newRow);
        const data = response.data;
        console.log('Success');
        return data;
    } catch (error) {
        throw error;
    }
});

export const updateProcessType = createAsyncThunk('data/updateProcessType', async ({ id, type }) => {
    try {
        console.log(type);
        const response = await api.updateProcessType(id, type);

        const data = response.data;
        console.log('Update Success');
        return { data, id, type };
    } catch (error) {
        throw error;
    }
});

export const deleteProcessType = createAsyncThunk('data/deleteProcessType', async (id) => {
    try {
        await api.deleteProcessType(id);
        console.log('Success');
        return id;
    } catch (error) {
        throw error.response.data;
    }
});

//Smart FAB

export const fetchProcessTypeSF = createAsyncThunk('data/fetchProcessTypeSF', async () => {
    try {
        const response = await api.listProcessTypeSF();

        const json = response.data

        const columnLabels = {
            smartFabProcessTypeId: { label: 'Process Type ID', type: 'number', editable: false },
            smartFabProcessType: { label: 'Process Type', type: 'text', editable: true },
            // Add more as needed
        };
        // smartFabProcessType
        // : 
        // "DRAWING"
        // smartFabProcessTypeId
        // : 
        // 1

        const selectedColumns = ['smartFabProcessTypeId', 'smartFabProcessType'];

        const columns = {};
        selectedColumns.forEach((columnName) => {
            columns[columnName] = columnLabels[columnName];
        });

        const labeledData = json.rows.map((row) => {
            const rowData = {};
            selectedColumns.forEach((columnName) => {
                rowData[columnName] = row[columnName];
            });
            return rowData;
        });

        return { columns, labeledData };
    } catch (error) {
        throw error;
    }
});

export const postProcessTypeSF = createAsyncThunk('data/postProcessTypeSF', async (newRow) => {
    try {
        const response = await api.addProcessTypeSF(newRow);
        const data = response.data;
        console.log('Success');
        return data;
    } catch (error) {
        throw error;
    }
});

export const updateProcessTypeSF = createAsyncThunk('data/updateProcessTypeSF', async ({ id, type }) => {
    try {
        console.log(type);
        const response = await api.updateProcessTypeSF(id, type);
        const data = response.data;
        console.log('Update Success');
        return { data, id, type };
    } catch (error) {
        throw error;
    }
});

export const deleteProcessTypeSF = createAsyncThunk('data/deleteProcessTypeSF', async (id) => {
    try {
        await api.deleteProcessTypeSF(id);
        console.log('Success');
        return id;
    } catch (error) {
        throw error.response.data;
    }
});
const processTypeSlice = createSlice({
    name: 'processType',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchProcessType.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchProcessType.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.columns = action.payload.columns;
                state.labeledData = action.payload.labeledData;
            })
            .addCase(fetchProcessType.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(postProcessType.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(postProcessType.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.labeledData.push(action.payload);
                toast.success('Process Type added successfully')

            })
            .addCase(postProcessType.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to add Process Type')

            })
            .addCase(updateProcessType.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(updateProcessType.fulfilled, (state, action) => {
                state.loading = 'fulfilled';

                const index = state.labeledData.find(item => item.processTypeId === action.payload.id);
                if (index) {
                    const updatedItem = { ...index, processType: action.payload.type.processType };
                    state.labeledData = state.labeledData.map(item => {
                        if (item.processTypeId === action.payload.id) {
                            return updatedItem;
                        }
                        return item;
                    })
                    console.log(state.labeledData);
                }
                //state.labeledData.push(action.payload);
                toast.success('Process Type updated successfully')

            })
            .addCase(updateProcessType.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to update Process Type')

            })
            .addCase(deleteProcessType.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(deleteProcessType.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                const deletedId = action.payload;
                state.labeledData = state.labeledData.filter((processType) => processType.processTypeId !== deletedId);
                toast.success('Process Type deleted successfully')

            })
            .addCase(deleteProcessType.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error(state.error)
            })

            //Smart FAB

            .addCase(fetchProcessTypeSF.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchProcessTypeSF.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.columns = action.payload.columns;
                state.labeledData = action.payload.labeledData;
            })
            .addCase(fetchProcessTypeSF.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(postProcessTypeSF.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(postProcessTypeSF.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.labeledData.push(action.payload);
                toast.success('Process Type added successfully')

            })
            .addCase(postProcessTypeSF.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to add Process Type')

            })
            .addCase(updateProcessTypeSF.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(updateProcessTypeSF.fulfilled, (state, action) => {
                state.loading = 'fulfilled';

                const index = state.labeledData.find(item => item.processTypeId === action.payload.id);
                if (index) {
                    const updatedItem = { ...index, processType: action.payload.type.processType };
                    state.labeledData = state.labeledData.map(item => {
                        if (item.processTypeId === action.payload.id) {
                            return updatedItem;
                        }
                        return item;
                    })
                    console.log(state.labeledData);
                }
                //state.labeledData.push(action.payload);
                toast.success('Process Type updated successfully')
            })
            .addCase(updateProcessTypeSF.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to update Process Type')
            })
            .addCase(deleteProcessTypeSF.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(deleteProcessTypeSF.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                const deletedId = action.payload;
                state.labeledData = state.labeledData.filter((processType) => processType.smartFabProcessTypeId !== deletedId);
                toast.success('Process Type deleted successfully')
            })
            .addCase(deleteProcessTypeSF.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error(state.error)
            });
    },
});
export default processTypeSlice.reducer;
//End of File