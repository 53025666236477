import React, { useState } from 'react';
import '../assets/styles/GenericTable.css';
import '../assets/styles/SDBRTable.css'

const ProdUploadTable = ({ columns, data, onSelectRow, selectedRow }) => {

  let totalV=[0,0];

  data.forEach(e => {
    totalV[0]+=(e.toBeUpldPcs*1);
    totalV[1]+=(e.toBeUpldWt*1);
  });

  return (
    <div className="table-container">
      <table className="generic-table">
        <thead className='stick'>
          <tr>
          <th key="select" className="table-header">
            </th>
            {columns.map((column) => (
              <th key={column.key} className="table-header-1">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, rowIndex) => (
            <tr key={rowIndex}>
                <td>
                <input
                  type="radio"
                  name="rowSelect"
                  value={rowIndex}
                  checked={selectedRow === rowIndex}
                  onChange={() => onSelectRow(rowIndex)}
                  id={`radio${rowIndex}`}
                />
                <label htmlFor={`radio${rowIndex}`} className="custom-radio"></label>
              </td>
              {columns.map((column) => (
                
                <td key={column.key}>{row[column.key]}</td>
              ))}
            </tr>
          ))}
          <tr className='table-header-1'>
            
                <th colSpan="9"></th>
                <th>Total(T):</th>
                {totalV.map((e) =>
                <th >{e}</th>
                )}
                <th colSpan="1"></th>
            </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ProdUploadTable;