import React from 'react'
import AddNewShape from './AddNewShape'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';

const ShapeUpdate = () => {
const location = useLocation();
  const {  shapeData,selectedShape, id, mag} = location.state;

  

  //  const [shapeDA,ushapeDA] = useState(shapeData);
   console.log(mag, shapeData)

  return (
    <div>
      <AddNewShape initialShape={JSON.parse(shapeData)} selectedShape={selectedShape} update={id} mag={mag}/>
    </div>
  )
}

export default ShapeUpdate