import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { resetState } from './loginSlice';
const initialState = {
  columns: {},
  labeledData: [],
  loading: 'idle',
  fetcher:true,
  error: null,
};
 
export const fetchFacilities = createAsyncThunk('data/fetchFacilities', async () => {
  try {
    const response = await api.listFacilities();
    const json = response.data;
 
    const columnLabels = {
      facilityId: {label:"Facility ID",type:'number',isRequired:true,editable:false},
      processCenterName: {label:'Process Center Name',type:'text',editable:true,isRequired:true},
      facilityName:{label:"Facility Name",type:'text',editable:true,isRequired:true},
      dimensions:{label:"Dimensions",type:'text',isRequired:true,editable:true},
      // Add more as needed
    };
 
    const selectedColumns = ['facilityId','facilityName','processCenterName','dimensions'];
 
    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });
 
    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });
 
    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});
export const postFacilities = createAsyncThunk('data/postFacilities', async (newRow) => {
  try {
    const response = await api.addFacilities(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});
export const updateFacility = createAsyncThunk('data/updateFacility', async ({ id, type }) => {
  try {
    console.log(id);
    const response = await api.updateFacility(id, type);
    const data = response.data;
    console.log('Update Success');
    return { data, id, type };
  } catch (error) {
    throw error;
  }
});
export const deleteFacility = createAsyncThunk('data/deleteFacility', async (id) => {
  try {
    await api.deleteFacility(id);
    return id;
  } catch (error) {
    throw error;
  }
});
const facilityMasterSlice = createSlice({
  name: 'facilityMaster',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(resetState, () => initialState)
      .addCase(fetchFacilities.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchFacilities.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchFacilities.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(postFacilities.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postFacilities.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.labeledData.push(action.payload);
        toast.success('Facility added successfully')
      })
      .addCase(postFacilities.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add facility')
      })
      .addCase(updateFacility.pending, (state) => {
        state.loading = 'pending';
 
      })
      .addCase(updateFacility.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        // state.fetcher = !state.fetcher;
        console.log(action.payload)
        // const index = state.labeledData.find(item => item.facilityId === action.payload.id);
        // if (index) {
        //   const updatedItem = { facilityId: action.payload.id, ...action.payload.type };
        //   console.log(updatedItem);
        //   state.labeledData = state.labeledData.map(item => {
        //     if (item.facilityId === action.payload.id) {
        //       return updatedItem;
        //     }
        //     return item;
        //   })
 
        // }
        toast.success('Facility updated sucessfully');
      })
      .addCase(updateFacility.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update Facility');
      })
      .addCase(deleteFacility.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteFacility.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        const deletedId = action.payload;
        state.labeledData=state.labeledData.filter((data) => data.facilityId !== deletedId);
        toast.success('Facility deleted successfully')
      })
      .addCase(deleteFacility.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to delete facility')
      });
  },
});
 
export default facilityMasterSlice.reducer;
//End of File