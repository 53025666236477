import React, { useEffect, useCallback, useMemo, useRef, useState, useLayoutEffect } from 'react';
import { AgGridReact } from 'ag-grid-react'; // the AG Grid React Component

import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import '../assets/styles/AGTable.css'

const AGTable2 = (props) => {

  const { columns, data, rowSelection = 'single', onSelectRow, selectedRow, dispatch, flex = 0, deselector, loader = 'fullfilled', height = 290 } = props
  const gridRef = useRef();
  const gridApiRef = useRef(null)

  const [autoSizing, setAutoSizing] = useState(true);

  const [columnDef, setcolumnDef] = useState([]);
  // const [rowData, setRowData] = useState(data);
  const [gridApi, setGridApi] = useState(null);
  // const [gridColumnApi, setGridColumnApi] = useState(null);
  const columnKeys = Object.keys(columns);

  const [chData, uChData] = useState(null);

  const onGridReady = (params) => {
    gridApiRef.current = params.api;
    setGridApi(params.api);
    // setGridColumnApi(params.columnApi);
  };

  const deSelectAll = () => {
    try {
      const selectedNodes = gridApiRef.current.getSelectedNodes();
      selectedNodes.forEach(node => node.setSelected(false))
    } catch (error) {

    }
  }

  useLayoutEffect(() => {

    if (deselector > 0) { deSelectAll() }

  }, [deselector]);


  const defaultColDef = useMemo(() => {

    return {
      initialWidth: 100,
      sortable: true,
      enableCellChangeFlash: true,
      cellDataType: false,
      resizable: true,
      filter: true,
      wrapText: true,
      autoHeight: true,
      autoHeaderHeight: true,
      flex: 1,
      maxWidth: 350
    }

  }, []);

  const handleDate = (dateString) => {
    try {
      const [year, month, day] = dateString.split('-');
      // console.log('apple');
      // Convert day, month, and year to numbers
      const numericDay = parseInt(day, 10);
      const numericMonth = parseInt(month, 10) - 1; // Adjust for zero-based months
      const numericYear = parseInt(year, 10);

      const parsedDate = new Date(numericYear, numericMonth, numericDay, 23);
      const today = new Date();

      // Calculate the difference in days
      const differenceInDays = Math.floor((parsedDate - today) / (24 * 60 * 60 * 1000));
      //  console.log(differenceInDays)
      // Apply color logic based on the difference in days
      if (differenceInDays > 4) {
        //   console.log('Green');
        return 'green';
      } else if (differenceInDays > 2 && differenceInDays <= 4) {
        //   console.log('Yellow');
        return 'yellow';
      } else if (differenceInDays <= 2 && differenceInDays >= 0) {
        //   console.log('Red');
        return 'red';
      } else {
        //   console.log('Black');
        return 'black';
      }
    } catch (error) {
      console.log(error);
    }

  };

  const changeValues = () => {

    let newColumns = [{ colId: '0', field: "slno", headerName: "Sl.No", headerCheckboxSelection: rowSelection === 'multiple' ? true : false, checkboxSelection: true, autoResize: false, filter: false, lockPosition: 'left', cellClass: 'locked-col' }];
    let objOrderRegion = { colId: '12', field: "orderRegion", headerName: "Order Region", headerCheckboxSelection: rowSelection === 'multiple' ? true : false, checkboxSelection: false, cellStyle: params => { return { color: params.value, backgroundColor: params.value } } };

    let newData = []
    let dateValues = ['customerExpectedDeliveryDate', 'deliveryDate', 'poDate']
    columnKeys.map((data, index1) => {
      let index = index1
      let obj = { colId: '', field: "", headerName: "", headerCheckboxSelection: rowSelection === 'multiple' ? true : false, checkboxSelection: false };

      if (dateValues.includes(data)) {
        obj = {
          ...obj, cellRenderer: params => {
            // console.log(params);
            if (params.data[data]) {
              try {
                let [dat, tim] = params.data[data].split('T');
                let [year, month, day] = dat.split('-')

                return `${day}-${month}-${year}`;
              } catch (error) {
                return params.data[data]
              }


            }

            else return params.data[data]

          }
        }
      }

      obj.colId = index + 1;
      obj.field = data;
      obj.headerName = columns[data].label;


      newColumns.push(obj)

    });

    data.map((data, index) => {

      let obj = { slno: index + 1 };
      let obj2 = { orderRegion: handleDate(data.deliveryDate) }


      newData[index] = { ...obj, ...data, ...obj2 };
    })

    uChData(newData);
    setcolumnDef([...newColumns.slice(0, 11), objOrderRegion, ...newColumns.slice(11)])
  }

  const autoSizeAll = useCallback(() => {


    try {
      if (gridRef.current.columnApi) {
        const allColumnIds = [];
        gridRef.current.api.getColumns().forEach((column) => {
          allColumnIds.push(column.getId());
        });
        gridRef.current.api.autoSizeColumns(allColumnIds, false);
      }
    } catch (error) {

    }
    setAutoSizing(false)
  }, []);



  const autoSizeStrategy = useMemo(() => {
    return {
      type: 'fitCellContents',
    };
  }, []);

  useLayoutEffect(() => {
    if (chData) {
      const resizeTimeout = setTimeout(() => {
        autoSizeAll();
      }, 100);

      return () => clearTimeout(resizeTimeout);
    }
  }, [columnDef, chData, autoSizeAll]);


  const onSelectionChanged = () => {

    const selectedNodes = gridApi.getSelectedNodes();



    const selectedRowIndexes = selectedNodes.map((node) => node.data.slno - 1);

    onSelectRow(selectedRowIndexes[0]);
  }

  const rowStyle = { background: 'white' };



  const getRowStyle = params => {
    //console.log("@@@", params.node);
    if (params.node.data.tierWeight === null || params.node.data.tierWeight === 0) {
      return { color: 'red' };
    }
  };


  useLayoutEffect(() => {

    // uChData(data);
    // setcolumnDef(columns);

    changeValues();

  }, [columns, dispatch, data]);



  return (
    <div className="table-container" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', maxHeight: '600px' }}>
      {
        autoSizing &&
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute', backgroundColor: '#fff', zIndex: '100', height: '100%', width: '100%' }}>
          <div className="circle-loader2" style={{ position: 'relative' }}></div>
        </div>
      }

      <div className="ag-theme-alpine" style={{ height: height, width: "100%" }}>

        <AgGridReact
          ref={gridRef}
          rowStyle={rowStyle}
          getRowStyle={getRowStyle}
          onGridReady={onGridReady}
          rowData={chData}
          columnDefs={columnDef}
          animateRows={true}
          rowSelection={rowSelection}
          defaultColDef={defaultColDef}
          rowMultiSelectWithClick={true}
          onSelectionChanged={onSelectionChanged}
          autoSizeStrategy={autoSizeStrategy}
          enableCellTextSelection={true}
          ensureDomOrder={true}
        />

      </div>

    </div>
  )
}

export default AGTable2

