import { Document, Page, View, StyleSheet, Text, Image, Font } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import font from "../../../../../assets/fonts/OpenSans-Variable.ttf"
import font2 from "../../../../../assets/fonts/OpenSans-Bold.ttf"

import smartFabLogo from '../../../../../assets/images/productSelectionPage/smartFabLogo.png'
import { dateChanger } from '../../../../../components/reusableFunctions';


const styles = StyleSheet.create({
    page: {
        flexDirection: 'column',
        backgroundColor: 'white',
        width: '100%',
        height: '100%',
        padding: '5% 8% ',
        fontFamily: 'OpenSans',
        fontSize: '10px'

    },
    elementHeader: {
        borderBottom: '1px solid black',
        fontSize: '12',
        padding: '5px 0'
    }
});

const SmFabLoadingListPdf = (props) => {

    const { serviceCenterLogo, listDetails, parameters } = props

    Font.register({
        family: "OpenSans", format: "truetype", src: font
    });

    Font.register({
        family: "OpenSans-Bold", format: "truetype", src: font2
    });

    console.log(parameters);
    


    return (
        <Document>
            <Page size="A4" style={styles.page}>

                <Text style={{ textAlign: 'right', fontSize: '8' }} render={({ pageNumber, totalPages }) => (
                    `Page ${pageNumber} of ${totalPages}`
                )} fixed />

                <View render={({ pageNumber, totalPages }) => {

                    return (pageNumber !== 1 && pageNumber !== totalPages) && (<>
                        <TableHeaderFixed />
                    </>)

                }} fixed />

                <TopSection
                    serviceCenterLogo={serviceCenterLogo}
                    listDetails={listDetails}
                />


                <TableHeader />


                {parameters.map((element, index) => {
                    return (<View wrap={false}>

                        {
                            index !== 0 ? parameters[index].orderId !== parameters[index - 1].orderId || (index) % 6 === 0 ?
                                <View style={{ ...styles.elementHeader, borderBottom: '1px solid black' }}><Text>OrderId: {parameters[index].combId}</Text></View> :
                                <View /> :
                                <View style={{ ...styles.elementHeader, borderBottom: '1px solid black' }}><Text>OrderId: {parameters[index].combId}</Text></View>
                        }

                        <PartItemMapper partItem={element} index={index} />

                    </View>
                    )
                }
                )}

            </Page>
            
        </Document>
    );
}

export default SmFabLoadingListPdf



const PartItemMapper = ({ partItem, index }) => {

    let tableData = [
        { key: "index", width: '4%', },
        { key: "rm", width: '6%' },
        { key: "finish", width: '8%' },
        { key: "mwDia-cwDia", width: '6%' },
        { key: "mwSpacing-cwSpacing", width: '7%' },
        { key: "length-width", width: '8%' },
        { key: "mwOH1-mwOH2", width: '8%' },
        { key: "cwOH1-cwOH2", width: '8%' },
        { key: "noOfMw-noOfCw", width: '6%' },
        { key: "totalSheets", width: '7%' },
        { key: "totalWeight", width: '7%' },
        { key: "sheetWeight", width: '8%' },
        { key: "weight/area", width: '7%' }, // sheetWeight/(length*width)
        { key: "weldedLength-weldedWidth", width: '8%' },
        { key: "mwTotalLength-cwTotalLength", width: '8%' },
        // { key: "mm2perMeterForMwAlongWidth", width: '8%' },
        // { key: "mm2perMeterForMwAlongLength", width: '8%' }
    ]

    const keyConverter = (data) => {

        const [first, second] = data.split('-')

        return `${partItem[first]}
        /${partItem[second]}`

    }

    const rmConverter = (data) => {
        if(data.includes('- ')){
            return data.split('- ')[1]
        } else {
            return data
        }
        
    }


    return (
        <View wrap={false} style={{ flexDirection: 'row', height: '79px', width: '100%', marginBottom: `${'0'}` }}>

            {tableData.map((data, partIndex) => (
                <>

                    <View style={{
                        flexDirection: 'column',
                        width: data.width,
                        height: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: '1px solid black',
                        fontSize: '9',
                        borderLeft: `${partIndex === 0 ? '1px' : '0px'}`,
                        borderTop: `${'0px'}`

                    }}>
                        {/* TT-${partItem[data.key]} */}
                        {
                            data.key === 'index' ? <Text style={{ fontWeight: "bold" }}>{index + 1 || ''}</Text> :
                                data.key.includes('-') ? <Text style={{ fontWeight: "bold", textAlign: 'center' }}>{keyConverter(data.key)}</Text> :
                                    data.key === 'weight/area' ? <Text style={{ fontWeight: "bold" }}>{Math.round((partItem.sheetWeight * 100000000)/ (partItem.length * partItem.width))/100}</Text> :
                                        data.key === 'rm' ? <Text style={{ fontWeight: "bold" }}>{rmConverter(partItem.smartFabProductType)}</Text> :
                                            <Text style={{ fontWeight: "bold" }}>{partItem[data.key]}</Text>
                        }

                        {/* <Image src={url} style={{ width: '100%' }}></Image> :
                                    data.key === 'threads' ?
                                        <Text style={{ fontWeight: "bold", textAlign: 'center' }}>{`HT-${partItem.totalNoOfPieces * partItem.halfThread || '0'}
  FT-${partItem.totalNoOfPieces * partItem.fullThread || '0'}`}</Text> :
                                        <Text style={{ fontWeight: "bold" }}>{partItem[data.key]}</Text> */}

                    </View>
                </>
            ))}
        </View>
    )
}


const TableHeader = () => {

    let tableData = [
        { label: "Sl No", width: '4%' },
        { label: "RM", width: '6%' },
        { label: "Finish", width: '8%' },
        { label: "MW /CW Dia mm", width: '6%' },
        { label: "MW /CW spacing mm", width: '7%' },
        { label: "Length /Width", width: '8%' },
        { label: "MW (OH1 /OH2)", width: '8%' },
        { label: "CW (OH1 /OH2)", width: '8%' },
        { label: "No. of MW /CW", width: '6%' },
        { label: "Total in NOS", width: '7%' },
        { label: "Wt. in MT", width: '7%' },
        { label: "Sheet Weight in Kg", width: '8%' },
        { label: "Kg per m2", width: '7%' },
        { label: "Welded Length /Width", width: '8%' },
        { label: "MW /CW total length", width: '8%' },
        // { label: "mm2/m for MW along width", width: '8%' },
        // { label: "mm2/m for CW along length", width: '8%' }
    ]

    return (
        <View style={{ flexDirection: 'row', height: '75', width: '100%' }}>
            {tableData.map((data, tableIndex) => (
                <View style={{
                    flexDirection: 'column',
                    width: data.width,
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                    fontSize: '9',
                    textAlign: 'center',
                    fontFamily: 'OpenSans-Bold',
                    borderLeft: `${tableIndex === 0 ? '1px' : '0px'}`,
                    padding:'0.5'
                }}>

                    <Text style={{}}>{data.label || ''}</Text>
                </View>
            ))}
        </View>
    )
}


const TableHeaderFixed = () => {

    let tableData = [
        { label: "Sl No", width: '4%' },
        { label: "RM", width: '6%' },
        { label: "Finish", width: '8%' },
        { label: "MW /CW Dia mm", width: '6%' },
        { label: "MW /CW spacing mm", width: '7%' },
        { label: "Length /Width", width: '8%' },
        { label: "MW (OH1 /OH2)", width: '8%' },
        { label: "CW (OH1 /OH2)", width: '8%' },
        { label: "No. of MW /CW", width: '6%' },
        { label: "Total in NOS", width: '7%' },
        { label: "Wt. in MT", width: '7%' },
        { label: "Sheet Weight in Kg", width: '8%' },
        { label: "Kg/m2", width: '7%' },
        { label: "Welded Length /Width", width: '8%' },
        { label: "MW /CW total length", width: '8%' },
        // { label: "mm2/m for MW along width", width: '8%' },
        // { label: "mm2/m for CW along length", width: '8%' }
    ]

    return (
        <View style={{ flexDirection: 'row', height: '75', width: '100%' }}>
            {tableData.map((data, tableIndex) => (
                <View style={{
                    flexDirection: 'column',
                    width: data.width,
                    height: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    border: '1px solid black',
                    fontSize: '9',
                    fontFamily: 'OpenSans-Bold',
                    borderLeft: `${tableIndex === 0 ? '1px' : '0px'}`,
                    padding:'0.5',
                    
                }}>
                    <Text style={{}}>{data.label || ''}</Text>
                </View>
            ))}
        </View>
    )
}


const TopSection = (props) => {

    const { serviceCenterLogo, listDetails } = props
    return (
      <View style={{ width: '100%', borderBottom: '1px solid black' }}>
        <View style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
          <Image src={serviceCenterLogo} style={{ height: '27px', border: '1px solid black' }}></Image>
          <Image src={smartFabLogo} style={{ height: '27px', border: '1px solid black' }}></Image>
        </View>
        <View style={{ border: '2px solid black', flexDirection: 'row', justifyContent: 'center' }}>
          <Text style={{ color: 'brown', fontFamily: 'OpenSans-Bold' }}>DELIVERY NOTE</Text>
        </View>
        <View style={{ flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
          <View><Text ><Text style={{ fontFamily: 'OpenSans-Bold' }}> Loading List Name: </Text> {listDetails.loadingListName}</Text></View>
          <View><Text></Text></View>
          <View><Text><Text style={{ fontFamily: 'OpenSans-Bold' }}>Gate Pass Print Date: </Text> {dateChanger(listDetails.gatePassPrintDate)}</Text></View>
        </View>
      </View>
    )
  }