import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../utils/Button";
import "../../../assets/styles/AddNew.css";
import {
  fetchCustomerParties,
  postCustomerParties,
} from "../../../redux-tookit/slices/customerPartiesSlice";
import {
  fetchOrder,
  fetchOrderSF,
  postOrder,
  postOrderSF,
  updateOrder,
  updateOrderSF,
} from "../../../redux-tookit/slices/orderSlice";
import { fetchCustomers } from "../../../redux-tookit/slices/customerSlice";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { fetchSegment } from "../../../redux-tookit/slices/customerSegmentSlice";
import { UserAccessContext } from "../../../App";

const AddNew = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const productName = useContext(UserAccessContext).productName;
  const { orderId } = useParams();
  const [data, setData] = useState("");
  const [pageAction, uPageAction] = useState("add");
  const [offer, setOffer] = useState("project");
  const [bTPCheck, uBTPCheck] = useState("related");
  const [sTPCheck, uSTPCheck] = useState("related");
  const parties = useSelector((state) => state.customerParties.labeledData);
  const customerList = useSelector((state) => state.data.labeledData);
  const resp = useSelector((state) => state.customerParties.resp);
  const segment = useSelector((state) => state.customerSegment.labeledData);
  const [address, setAddress] = useState("");
  const orderDetails = useSelector((state) => state.order.labeledData).filter(
    (item) => `${item.orderId}` === orderId
  )[0];
  // const fulfilled = useSelector((state) => state.order.fulfilled)
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedParty, setSelectedParty] = useState({
    soldToParty: null,
    billToParty: null,
    shipToParty: null,
  });
  // const soldToPartyValues = [...new Set(originalData.map(item => item.customername))];

  const [addNew, setAddNew] = useState({ customerSegmentId: 13 });

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  console.log(selectedParty);
  console.log(parties);

  const navigator = (orderId, combId) => {
    if ((orderId !== null) & (orderId !== undefined)) {
      setTimeout(() => {
        if (productName === 'smartFab') {
          navigate(`/production parameters/${orderId}`, { state: { combId } });
        } else {
          navigate(`/element/${orderId}`, { state: { combId } });
        }

      }, 700);
    }
  };

  const handleUpdateOrder = async (id, type) => {
    try {
      if (productName === 'smartFab') {

        await dispatch(updateOrderSF({ id, type })).then((res) => {
          if ((res.payload.id !== null) & (res.payload.id !== undefined)) {
            setTimeout(() => {
              navigate(`/order details`);
            }, 700);
          }
        });

      } else {

        await dispatch(updateOrder({ id, type })).then((res) => {
          if ((res.payload.id !== null) & (res.payload.id !== undefined)) {
            setTimeout(() => {
              navigate(`/order details`);
            }, 700);
          }
        });

      }
    } catch (error) { }
  };
  console.log(productName);


  const handleAddOrder = async (partiesId, adder) => {

    try {
      if (productName === 'smartFab') {
        await dispatch(postOrderSF({ ...adder, customerPartiesId: partiesId })).then(
          (res) => {
            navigator(res.payload.orderId, res.payload.combId);
          }
        );
      } else {
        await dispatch(postOrder({ ...adder, customerPartiesId: partiesId })).then(
          (res) => {
            navigator(res.payload.orderId, res.payload.combId);
          }
        );
      }


    } catch (error) {
      console.log(error);

    }

  };

  // const customerSegment = ['Infra-MRTS', 'Infra-TLT', 'Infra-Windmill', 'Infra-Roads & Bridges', 'Infra-Power', 'Infra-Railways', 'Housing & Commercial-IHB', 'Housing & Commercial-Apartment & Villas', 'Housing & Commercial-MES', 'Housing & Commercial-Car Parking', 'Housing & Commercial-Mall', 'Housing & Commercial-Complexes'];

  const handleSaveButtonClick = async (e) => {
    try {
      e.preventDefault();
      let adder = { ...addNew, offers: offer, flag: "1", diffFactor: "None", orderAddress: address };
      if (addNew.orderType === undefined) {
        adder = { ...adder, orderType: "Normal" };
      }
      if (addNew.orderRemarks === undefined) {
        adder = { ...adder, orderRemarks: "" };
      }

      const selectedCustArray = parties.filter(
        (item) =>
          item.soldToPartyId === selectedParty.soldToParty &&
          item.billToPartyId === selectedParty.billToParty &&
          item.shipToPartyId === selectedParty.shipToParty
      );
      if (pageAction === "add") {
        if (selectedCustArray.length === 0) {
          await dispatch(postCustomerParties(selectedParty)).then((response) =>
            handleAddOrder(response.payload.customerPartiesId, adder)
          );
        } else {
          if (productName === 'smartFab') {
            await dispatch(
              postOrderSF({
                ...adder,
                customerPartiesId: selectedCustArray[0].customerPartiesId * 1,
              })
            ).then((res) => {
              navigator(res.payload.orderId, res.payload.combId);
            });
          } else {
            await dispatch(
              postOrder({
                ...adder,
                customerPartiesId: selectedCustArray[0].customerPartiesId * 1,
              })
            ).then((res) => {
              navigator(res.payload.orderId, res.payload.combId);
            });
          }


        }
      } else {
        if (selectedCustArray.length === 0) {
          const id = orderId;
          await dispatch(postCustomerParties(selectedParty)).then((res) => {
            const type = {
              ...adder,
              customerPartiesId: res.payload.customerPartiesId,
            };
            handleUpdateOrder(id, type);
          });
        } else {
          const type = {
            ...adder,
            customerPartiesId: selectedCustArray[0].customerPartiesId * 1,
          };
          const id = orderId;
          if (productName === 'smartFab') {
            await dispatch(updateOrderSF({ id, type })).then((res) => {
            if ((res.payload.id !== null) & (res.payload.id !== undefined)) {
              setTimeout(() => {
                navigate(`/order details`);
              }, 700);
            }
          });
          } else {
            await dispatch(updateOrder({ id, type })).then((res) => {
            if ((res.payload.id !== null) & (res.payload.id !== undefined)) {
              setTimeout(() => {
                navigate(`/order details`);
              }, 700);
            }
          });
          }
          

          console.log("edit", type);
        }
      }

      console.log(adder);
    } catch (error) { }
  };

  const handleSetAddress = (value) => {
    if (customerList) {
      customerList.map((item) => {
        item.customerId * 1 === value * 1
          ? setAddress(item.address)
          : console.log("");
      });
    }
  };

  const updateValueSetter = () => {
    if (orderId !== null && orderDetails !== undefined) {
      console.log(orderDetails);
      let tempParties = {};
      let tempAddNew = {};
      uPageAction("update");

      Object.keys(orderDetails).map((item) => {
        item === "offer"
          ? (offer = orderDetails.offer)
          : item === "deliveryDate" ||
            item === "orderType" ||
            item === "orderRemarks" ||
            item === "customerExpectedDeliveryDate" ||
            item === "sapId" ||
            item === "customerSegmentId"
            ? (tempAddNew = { ...tempAddNew, [item]: orderDetails[item] })
            : item === "soldToPartyId"
              ? (tempParties = {
                ...tempParties,
                soldToParty: orderDetails.soldToPartyId,
              })
              : item === "billToPartyId"
                ? (tempParties = {
                  ...tempParties,
                  billToParty: orderDetails.billToPartyId,
                })
                : item === "shipToPartyId"
                  ? (tempParties = {
                    ...tempParties,
                    shipToParty: orderDetails.shipToPartyId,
                  })
                  : console.log(item);
      });
      setSelectedParty(tempParties);
      setAddNew({ ...tempAddNew, ...addNew });
      setAddNew({
        ...addNew,
        customerSegmentId: orderDetails.customerSegmentId,
        orderRemarks: orderDetails.orderRemarks,
        sapId: orderDetails.sapId,
        customerExpectedDeliveryDate: orderDetails.customerExpectedDeliveryDate,
        deliveryDate: orderDetails.deliveryDate,
      });
      setAddress(orderDetails.address);
    }
  };

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  useEffect(() => {
    updateValueSetter();
  }, [orderDetails]);

  useEffect(() => {
    dispatch(fetchCustomerParties());
    dispatch(fetchCustomers());
    if (productName === 'smartFab') {
      dispatch(fetchOrderSF());
    } else {
      dispatch(fetchOrder());
    }
    dispatch(fetchSegment());
  }, [dispatch]);
  
  console.log(addNew);
  return (
    <div className="sdbr">
      <form onSubmit={handleSaveButtonClick}>
        {orderId === "null" ? (
          <div className="head">Add Order</div>
        ) : (
          <div className="head">Update Order</div>
        )}
        <div className="add-new-order">
          <div className="order-details">
            <div className="row-1">
              <div className="order-fields one">
                <label htmlFor="Sap">Sap / Tally / Nav Order Id</label>
                <input
                  type="text"
                  id="Sap"
                  value={addNew.sapId}
                  onChange={(e) =>
                    setAddNew({ ...addNew, sapId: e.target.value })
                  }
                />
              </div>
              {/* <div className='order-fields two'>
                <label htmlFor="OrderId">Order Id</label>
                <input id="OrderId" type='text' className='column2' onChange={(e) => setAddNew({ ...addNew, orderId: e.target.value })} />
              </div> */}
              <div className="order-fields two">
                <label htmlFor="date">Delivery Date</label>
                <input
                  type="date"
                  id="deliveryDate"
                  value={addNew.deliveryDate}
                  onChange={(e) =>
                    setAddNew({ ...addNew, deliveryDate: e.target.value })
                  }
                  min={getCurrentDate()}
                />
              </div>
              {/* <div className='order-fields three' style={{ justifyContent: 'start' }}>
								<div style={{ marginBottom: '1.5vh' }}>Offer</div>
								<label htmlFor="offer"></label>
								<div>

									<input
										type="radio"
										name="Offer"
										value="project"
										checked={offer === 'project'}
										onChange={(e) => setOffer(e.target.value)}
										id="project"
									/>
									<label htmlFor="project" style={{ marginRight: '1vw' }}>Project</label>
									<input
										type="radio"
										name="Offer"
										value="retail"
										checked={offer === 'retail'}
										onChange={(e) => setOffer(e.target.value)}
										id="retail"
									/>
									<label htmlFor="retail" >Retail</label>
								</div>
							</div> */}
            </div>

            <div className="row-2" style={{ alignItems: "flex-start" }}>
              <div className="order-fields one">
                <label htmlFor="OrderType">Order Type</label>
                <select
                  name="orderType"
                  id="orderType"
                  value={addNew.orderType}
                  onChange={(e) =>
                    setAddNew({ ...addNew, orderType: e.target.value })
                  }
                >
                  <option value="Normal">Normal Order</option>
                  <option value="Rush">Rush Order</option>
                </select>
              </div>

              <div className="order-fields one">
                <label htmlFor="orderRemarks">Order Remarks</label>
                <textarea
                  name="orderRemarks"
                  id="orderRemarks"
                  cols="20"
                  rows="5"
                  value={addNew.orderRemarks}
                  onChange={(e) =>
                    setAddNew({ ...addNew, orderRemarks: e.target.value })
                  }
                ></textarea>
              </div>
            </div>

            <div className="row-1">
              <div className="order-fields one">
                <label htmlFor="soldToParty">
                  Sold To Party<span style={{ color: "red" }}>*</span>
                </label>
                <select
                  name="soldToParty"
                  id="soldToParty"
                  type="number"
                  value={selectedParty.soldToParty}
                  onChange={(e) => {
                    handleSetAddress(e.target.value);
                    setSelectedParty({
                      ...selectedParty,
                      soldToParty: e.target.value * 1,
                      billToParty: e.target.value * 1,
                      shipToParty: e.target.value * 1,
                    });
                    uBTPCheck("related");
                    uSTPCheck("related");
                  }}
                >
                  <option value=""></option>
                  {customerList.map((value, index) => (
                    <option key={index} value={value.customerId}>
                      {value.customerName}
                    </option>
                  ))}
                  {/* {soldToPartyValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))} */}
                </select>
              </div>
              {/* <div className="order-fields two">
                <input type='text' placeholder='Customer Id' value={addNew.soldToParty} />
              </div> */}

              <div className="order-fields two">
                <label htmlFor="expectedDate">
                  Customer Expected Date<span style={{ color: "red" }}>*</span>
                </label>
                <input
                  type="date"
                  id="expectedDate"
                  required={true}
                  value={addNew.customerExpectedDeliveryDate}
                  min={getCurrentDate()}
                  onChange={(e) =>
                    setAddNew({
                      ...addNew,
                      customerExpectedDeliveryDate: e.target.value,
                    })
                  }
                />
              </div>
            </div>

            <div className="row-2">
              <div className="order-fields one">
                <div className="radio-buttons">
                  <label htmlFor="billToParty">
                    Bill to Party<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    disabled={
                      selectedParty?.billToParty === null ||
                        selectedParty?.soldToParty === null ||
                        selectedParty?.shipToParty === null
                        ? true
                        : false
                    }
                    type="radio"
                    name="billToParty"
                    value="all"
                    checked={bTPCheck === "all"}
                    onChange={(e) => {
                      uBTPCheck(e.target.value);
                      setSelectedParty({
                        ...selectedParty,
                        billToParty: customerList[0].customerId,
                      });
                    }}
                    id="all"
                  />
                  <label htmlFor="billToParty">All</label>
                  <input
                    disabled={
                      selectedParty?.billToParty === null ||
                        selectedParty?.soldToParty === null ||
                        selectedParty?.shipToParty === null
                        ? true
                        : false
                    }
                    type="radio"
                    name="billToParty"
                    value="related"
                    checked={bTPCheck === "related"}
                    onChange={(e) => {
                      uBTPCheck(e.target.value);
                      setSelectedParty({
                        ...selectedParty,
                        billToParty: selectedParty.soldToParty,
                      });
                    }}
                    id="related"
                  />
                  <label htmlFor="billToParty">Related</label>
                </div>
                <select
                  name="billToParty"
                  disabled={
                    selectedParty?.billToParty === null ||
                      selectedParty?.soldToParty === null ||
                      selectedParty?.shipToParty === null
                      ? true
                      : false
                  }
                  id="billToParty"
                  value={selectedParty.billToParty}
                  onChange={(e) => {
                    setSelectedParty({
                      ...selectedParty,
                      billToParty: e.target.value * 1,
                    });
                  }}
                >
                  {bTPCheck === "all" ? (
                    customerList.map((value, index) => (
                      <option key={index} value={value.customerId}>
                        {value.customerName}
                      </option>
                    ))
                  ) : selectedParty.soldToParty !== null ? (
                    <>
                      {customerList.map((item) =>
                        item.customerId === selectedParty.soldToParty ? (
                          <option value={item.customerId}>
                            {item.customerName}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                    </>
                  ) : (
                    ""
                  )}
                  {/* {soldToPartyValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))} */}
                </select>
              </div>
              <div className="order-fields one">
                <div className="radio-buttons">
                  <label htmlFor="shipToParty">
                    Ship To Party<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    disabled={
                      selectedParty?.billToParty === null ||
                        selectedParty?.soldToParty === null ||
                        selectedParty?.shipToParty === null
                        ? true
                        : false
                    }
                    type="radio"
                    name="shipToParty"
                    value="all"
                    checked={sTPCheck === "all"}
                    onChange={(e) => {
                      uSTPCheck(e.target.value);
                      setSelectedParty({
                        ...selectedParty,
                        shipToParty: customerList[0].customerId,
                      });
                    }}
                    id="all"
                  />
                  <label htmlFor="shipToParty">All</label>
                  <input
                    disabled={
                      selectedParty?.billToParty === null ||
                        selectedParty?.soldToParty === null ||
                        selectedParty?.shipToParty === null
                        ? true
                        : false
                    }
                    type="radio"
                    name="shipToParty"
                    value="related"
                    checked={sTPCheck === "related"}
                    onChange={(e) => {
                      uSTPCheck(e.target.value);
                      setSelectedParty({
                        ...selectedParty,
                        shipToParty: selectedParty.soldToParty,
                      });
                    }}
                    id="related"
                  />
                  <label htmlFor="shipToParty">Related</label>
                </div>

                <select
                  name="shipToParty"
                  disabled={
                    selectedParty?.billToParty === null ||
                      selectedParty?.soldToParty === null ||
                      selectedParty?.shipToParty === null
                      ? true
                      : false
                  }
                  id="shipToParty"
                  value={selectedParty.shipToParty}
                  onChange={(e) => {
                    handleSetAddress(e.target.value);
                    setSelectedParty({
                      ...selectedParty,
                      shipToParty: e.target.value * 1,
                    });
                  }}
                >
                  {sTPCheck === "all" ? (
                    customerList.map((value, index) => (
                      <option key={index} value={value.customerId}>
                        {value.customerName}
                      </option>
                    ))
                  ) : selectedParty.soldToParty !== null ? (
                    <>
                      {customerList.map((item) =>
                        item.customerId === selectedParty.soldToParty ? (
                          <option value={item.customerId}>
                            {item.customerName}
                          </option>
                        ) : (
                          ""
                        )
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </select>
              </div>
              {/* <div className="order-fields two">
                <input type='text' placeholder='' />
              </div> */}
            </div>

            <div className="row-3" style={{ paddingBottom: "20px" }}>
              <div className="order-fields one">
                <label htmlFor="custSegment">Customer Segment</label>
                {/* <select name="custSegment" id="custSegment">
									<option value="NA">NA</option>
									{customerSegment.map((value) => (
										<option key={value} value={value}>
											{value}
										</option>
									))}
								</select> */}
                <select
                  name="custSegment"
                  id="custSegment"
                  required
                  value={addNew.customerSegmentId}
                  onChange={(e) =>
                    setAddNew({
                      ...addNew,
                      customerSegmentId: e.target.value * 1,
                    })
                  }
                >
                  {segment.map((value) => (
                    <option value={value.customerSegmentId}>
                      {value.customerSegmentName}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="row-4">
              <div className="order-fields one">
                <label htmlFor="address">Delivery Address</label>
                <textarea
                  name="address"
                  id="address"
                  cols="20"
                  rows="5"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                // readOnly
                ></textarea>
              </div>
            </div>
          </div>
          <div className="bottom-button-container">
            {/* <Button className='blue' label='Add Price' /> */}
            <Button
              className="blue"
              type="submit"
              label={`${pageAction === "update" ? "Update" : "Save"}`}
            />
            <Button className="blue" label="Exit" link="order details" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default AddNew;
//End of File
