import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { amber } from '@mui/material/colors';

const initialState = {
	columns: {},
	columns2: {},
	columns3: {},
	columns4: {},
	labeledData: [],
	labeledData3: [],
	labeledData4: [],
	loading: 'idle',
	fetcher: true,
	error: null,
};


export const fetchLoadingList = createAsyncThunk('data/fetchPartItems2', async () => {
	try {

		const response = await api.listLoadingList();
		const json = response.data;


		// const selectedPartItemIds = json2.rows.map((item) => item.partItemId)

		const columnLabels = {

			'dispatchId': { label: "Dispatch Id" },
			'orderId': { label: "orderId" },
			'loadingListName': { label: "Loading List Name" },
			'gatePassPrintDate': { label: "Gate Pass print Date" },
			'loadingListId': { label: "Loading List Id" },
			'gatePassNo': { label: "Gate Pass No." },
			'status': { label: "Gate pass print status" },
			'totalBundles': { label: "Total Bundles" },
			'totalRods': { label: "Total Rods" },
			'totalWeight': { label: "Total Production Weight (T)" },
			'trailerNo': { label: "Vehicle No." },
			'weightBridgeWeight': { label: "Weight Bridge Weight(T)" },
			'gatePassPrintStatus': { label: "Status" },
			'loadingListRemarks': { label: "Loading List Remarks" },
			'vehicleFitmentDetails': { label: 'Vehicle Fitment Details' },
			'driverFitmentDetails': { label: 'Driver Fitment Details' },
			'bundleId': { label: "Bundle Id" }

			// noOfTags:{label: "No of Tags", type: 'text', isRequired: true, editable: true },
			// totalWeight:{label: "Total Weight (Kg.)", type: 'text', isRequired: true, editable: true }

		};



		const selectedColumns = [
			'dispatchId',
			'orderId',
			'loadingListName',
			'trailerNo',
			'loadingListId',
			'totalWeight',
			'totalBundles',
			'totalRods',
			'gatePassPrintDate',
			// 'gatePassNo',
			'weightBridgeWeight',
			'gatePassPrintStatus',
			// 'loadingListRemarks',
			'bundleId',
			'vehicleFitmentDetails',
			'driverFitmentDetails'
			// 'sapDlvNo',
			// 'deviation',
			// 'gatePassPrintDate'
		];

		const selectedColumns2 = [];

		const columns = {}, columns2 = {};

		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		selectedColumns2.forEach((columnName) => {
			columns2[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};

			selectedColumns.forEach((columnName) => {
				if (columnName === 'gatePassPrintStatus') {
					rowData[columnName] = row[columnName] === 1 ? 'Not dispatched' : 'dispatched'
				} else

					rowData[columnName] = row[columnName];
			});
			return rowData;
		})

		console.log(labeledData);

		return { columns, labeledData, columns2 };
	} catch (error) {
		throw error;
	}
});



export const fetchFgPartItems = createAsyncThunk('data/fetchFgPartItems', async (params='') => {
	try {

		let response = {}

		response = await api.listFgPartItems(params);

		const json = response.data;
		const columnLabels = {

			partItemId: { label: "Part Item Id", type: 'number', isRequired: true, editable: false },
			dispatchId: { label: "Element Id", type: 'number', isRequired: true, editable: false },
			barMarkId: { label: "Bar Mark Id", type: 'number', isRequired: true, editable: false },
			diameter: { label: "Diameter (mm)", type: 'number', isRequired: true, editable: true },
			noOfPieces: { label: "No. of Pieces", type: 'number', isRequired: true, editable: true },
			totalNoOfPieces: { label: "Total No. of Pieces ", type: 'number', isRequired: true, editable: true },
			shapeId: { label: "Shape Id", type: 'number', isRequired: true, editable: true },
			image: { label: "Shape", type: 'number', isRequired: true, editable: true },
			orderItemName: { label: "Element Name", type: 'text', isRequired: true, editable: true },
			gradeName: { label: "Grade", type: 'text', isRequired: true, editable: true },
			bundleQuantity: { label: "Bundle Qty", type: 'number', isRequired: true, editable: true },
			productionLength: { label: "Production Length (mm)", type: 'number', isRequired: true, editable: true },
			productionWeight: { label: "Production Weight (T)", type: 'number', isRequired: true, editable: true },
			inventoryWeight: { label: "Inventory Weight (T)", type: 'number', isRequired: true, editable: true },
			bmRow: { label: "BM_Row", type: 'number', isRequired: true, editable: true },
			tagId: { label: "Tag Id", type: 'number', isRequired: true, editable: true },
			route: { label: "Route Desc", type: 'text', isRequired: true, editable: true },
			pinDia: { label: "Pin Dia", type: 'number', isRequired: true, editable: true },
			orderId: { label: "Id", type: 'number' },
			combId: {label: "Order Id"},
			id: { label: "id", type: 'number' },
			totalProductionWeight: { label: 'Total Production Weight' },
			dispatchedWeight: { label: 'Dispatched Weight' },
			bundleNumber: { label: 'Pieces Loaded' }

		};

		const selectedColumns = [

			'combId',
			'orderItemName',
			'image',
			'diameter',
			'totalNoOfPieces',
			'partItemId',
			'noOfPieces',
			'pinDia',
			'gradeName',
			'productionWeight',
			'productionLength',
			'orderId',
			// 'totalProductionWeight',
			'bundleNumber',

		];

		const columns = {}, columns2 = {};

		selectedColumns.forEach((columnName) => {
			if (columnName !== 'bundleNumber')
				columns2[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				if (columnName !== 'bundleNumber')
					rowData[columnName] = row[columnName];
				else rowData[columnName] = ''
			});
			return rowData;
		});

		return { columns, labeledData, columns2 };
	} catch (error) {
		throw error;
	}
});

export const fetchBundles = createAsyncThunk('data/fetchBundles', async (filter) => {
	try {
		let response = ''
		if (filter !== null) {
			response = await api.listBbsBundles(filter)
		} else {
			response = await api.listBbsBundles()
		}

		const json = response.data;
		const columnLabels = {
			bundleId: { label: "Bundle Id" },
			partItemId: { label: 'Part Item Id' },
			orderId: {label: 'Ord Id'},
			noOfPieces: { label: "No of Pieces" },
			gradeName: { label: 'Grade' },
			diameter: { label: 'Diameter' },
			weight: { label: "Weight (T)" },
			combId: {label: 'Order Id'},
			orderItemName: { label: "Element Name"},
			productionStatus: { label: "Prodution Status"}
			// Add more as needed

		};

		console.log(response)

		const selectedColumns = [
			'bundleId', 
			'partItemId', 
			'orderId',
			'combId',
			'orderItemName',
			'noOfPieces', 
			'gradeName', 
			'diameter', 
			'weight',
			'productionStatus'
		];



		const columns = {};

		selectedColumns.forEach((columnName) => {
			columns[columnName] = columnLabels[columnName];
		});

		const labeledData = json.rows.map((row) => {
			const rowData = {};
			selectedColumns.forEach((columnName) => {
				rowData[columnName] = row[columnName];
			});
			return rowData;
		});

		return { columns, labeledData };
	} catch (error) {
		console.log(error);
		throw error
	}
})



export const postLoadingList = createAsyncThunk('data/postLoadingList', async (newRow) => {

	try {
		const response = await api.addLoadingList(newRow);
		const data = response.data;
		console.log('Success');
		return data;
	} catch (error) {
		if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
		throw error;
	}
});

export const updateLoadingList = createAsyncThunk('data/updateLoadingList', async ({ id, type }) => {
	try {
		console.log(type, id);
		const response = await api.updateLoadingList(id, type);
		const data = response.data;
		console.log('Update Success');
		return { data, id, type };
	} catch (error) {
		if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
		throw error;
	}
});


export const dispatchLoadingList = createAsyncThunk('data/dispatchLoadingList', async (id) => {
	try {
		const response = await api.dispatchLoadingList(id);
		const data = response.data;
		console.log('Dispatch Success');
		return { data, id };
	} catch (error) {
		if (error.response.data.message) { toast.error(`${error.response.data.message}`) }
		throw error;
	}
});


const loadingListSlice = createSlice({
	name: 'loadingList',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder

			.addCase(fetchLoadingList.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns = action.payload.columns;
				state.columns2 = action.payload.columns2;
				state.labeledData = action.payload.labeledData;
			})
			.addCase(fetchLoadingList.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(fetchFgPartItems.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns = action.payload.columns;
				state.columns3 = action.payload.columns2;
				state.labeledData3 = action.payload.labeledData;
			})
			.addCase(fetchFgPartItems.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(fetchBundles.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.columns4 = action.payload.columns;
				state.labeledData4 = action.payload.labeledData;
			})
			.addCase(fetchBundles.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
			})
			.addCase(postLoadingList.pending, (state) => {
				state.loading = 'pending';
			})
			.addCase(postLoadingList.fulfilled, (state, action) => {
				state.loading = 'fulfilled';
				state.fetcher = !state.fetcher;
				state.labeledData.push(action.payload);
				console.log(action.payload)
				if (action.payload.message)
					toast.success(action.payload.message)
				else
					toast.success("Loading list added successfully")
			})
			.addCase(postLoadingList.rejected, (state, action) => {
				state.loading = 'rejected';
				state.error = action.error.message;
				// toast.error('Failed to add Loading list')
			})

	},
});

export default loadingListSlice.reducer;

//End of File