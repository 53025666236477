import React from 'react';

const FilterComponent = ({
  label,
  filterValue,
  options,
  onFilterChange
}) => {
  return (
    <div className="filter">
      <label htmlFor={label}>{label}</label>
      <select
        id={label}
        value={filterValue}
        onChange={(e) => onFilterChange(e.target.value)}
      >
        <option value="">All</option>
        {options.map((value, index) => (
          <option key={index} value={value}>
            {value}
          </option>
        ))}
      </select>
    </div>
  );
};

export default FilterComponent;