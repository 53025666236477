//PdfViewer.jsx

import { PDFViewer, Document } from '@react-pdf/renderer';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {  fetchPartItems2 } from '../../redux-tookit/slices/partItemsSlice';
import { useLocation } from 'react-router-dom';
import { fetchOrder } from '../../redux-tookit/slices/orderSlice';
import { fetchCustomerParties } from '../../redux-tookit/slices/customerPartiesSlice';
import MyDocument from './MyDocument';
import { fetchBundles } from '../../redux-tookit/slices/loadingListSlice';
import Loader from '../Loader/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { fetchServiceCenter } from '../../redux-tookit/slices/serviceCenterSlice';

const PdfViewer = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const loading = useSelector(state => state.partItems.loading)
  const [loading1, uLoading1] = useState(true);

  const [bundleData, setBundleData] = useState([])

  const [partItem, setPartItems] = useState([])
  // .sort((a,b) => a.orderItemName - b.orderItemName)
  const orderInfo = useSelector(state => state.order.labeledData)[0]
  // .filter(item => `${item.orderId}` === state.orderId)
  // const orderInfo = useSelector(state => state.order.labeledData).filter(item => `${item.orderId}` === 1)[0]
  const parties = useSelector(state => state.customerParties.labeledData).filter(item => item.customerPartiesId * 1 === state.partiesId * 1)[0]
  const diaValues = [...new Set(partItem.map(item => item.diameter))].sort((a, b) => a - b)

  const serviceCenter1 = useSelector(state => state.serviceCenter.labeledData).filter((sc) => sc.imageUrl)[0] || ''

  // const [bundleData, setBundleData] = useState([])
  // .filter(item => `${item.orderId}` === state.orderId)


  //change orderInfo,parties when api is working ^

  //  console.log(diaValues);
  // console.log(parties);
  // console.log(orderInfo);

  // console.log({ parties, orderInfo });
  // console.log({ partItem });

  const getBundlles = async () => {
    dispatch(fetchBundles(`?orderId=${state.orderId}`))
      .then((res) => {
        if (res.payload) {
          console.log(res.payload.labeledData)
          if (res.payload.labeledData.filter(item => `${item.orderId}` === state.orderId).length === 0) {
            toast.error("No bundles in this order")
          } else
            setBundleData(res.payload.labeledData.filter(item => `${item.orderId}` === state.orderId))

        }
      })
  }


  console.log(loading);
  useEffect(() => {
    let params = `?orderId=${state.orderId}`
    let toSort = true

    dispatch(fetchPartItems2({ params, toSort }))
      .then(res => {
        console.log(res.payload.labeledData);
        setPartItems(res.payload.labeledData)
      })
    dispatch(fetchOrder(`?orderId=${state.orderId}`)).then(res => { console.log(res.payload.labeledData); })

    dispatch(fetchCustomerParties())

    if (state.type === 'tag') {
      getBundlles()
    }

    dispatch(fetchServiceCenter())
    // uLoading(false)
  },[])

  // console.log(serviceCenter);
  // console.log(serviceCenter1);
  const bufferToBase64 = (buffer) => {
    try {
      // console.log(buffer);

      const byteArray = new Uint8Array(buffer);
      const decoder = new TextDecoder('utf-8');

      let result = '';
      for (let i = 0; i < byteArray.length; i += 1024) {
        const chunk = byteArray.subarray(i, i + 1024);
        result += decoder.decode(chunk);
      }
      console.log('this ran');
      // console.log(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  const serviceCenterLogo = bufferToBase64(serviceCenter1?.imageUrl?.data) || null;



  console.log(serviceCenterLogo);

  return (
    <div style={{ border: '2px solid black', height: '100%' }}>

      <ToastContainer />
      {loading !== 'fulfilled' && loading1 ? <Loader /> :
        <>

          {partItem.length !== 0 && orderInfo !== undefined && parties !== undefined ?
            <PDFViewer style={{ height: '100%', width: '100%' }}>
              {<Document onRender={() => { uLoading1(false) }} >
                <MyDocument partItem={partItem} bundleData={bundleData} orderInfo={orderInfo} type={state.type}
                  parties={parties} diaValues={diaValues} serviceCenterLogo={serviceCenterLogo} lengthDeduction={state?.lengthDeduction}
                />
              </Document>
              }
            </PDFViewer>
            : ""
          }
        </>
      }
    </div>
  )
}

export default PdfViewer

//End of File
