import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../assets/styles/BBS.css";
import Shapes from "../../master/Shape/Shapes";
import Button from "../../../utils/Button";
import BbsShapeSetting from "./BbsShapeSetting";
import { useLocation, useParams } from "react-router-dom";
import { fetchOrder } from "../../../redux-tookit/slices/orderSlice";
import { fetchGrades } from "../../../redux-tookit/slices/gradeMasterSlice";
import { fetchOrderItems } from "../../../redux-tookit/slices/orderItemsSlice";
import AGTable from "../../../components/AGTable";
import {
  deletePartItems,
  fetchPartItems,
} from "../../../redux-tookit/slices/partItemsSlice";
import { toast } from "react-toastify";
import {
  fetchShapes,
  searchShapes,
} from "../../../redux-tookit/slices/shapeSlice";
import search from "../../../assets/images/button-icons/Search.svg";
import TopButtonContainer from "../../../components/UserAccess/TopButtonContainer";
import { fetchUserAccess } from "../../../redux-tookit/slices/userAccessSlice";
import { dateChanger } from "../../../components/reusableFunctions";

const BBS = () => {

  const dispatch = useDispatch();
  const location = useLocation();
  const { orderId } = useParams();

  const [combId, setCombId] = useState(location.state?.combId);

  const orderData = useSelector((state) => state.order.labeledData).filter(
    (item) => `${item.orderId}` === orderId
  );

  const materials = useSelector((state) => state.gradeMaster.labeledData);
  const partItems = useSelector((state) => state.partItems.labeledData);


  const orderItems = useSelector((state) => state.orderItems.labeledData)
    .filter((item) => `${item.orderId}` === orderId)
    .map((item) => {
      if (partItems.length !== 0) {
        const foundGrade = partItems.find(
          (data) => data.orderItemId === item.orderItemId
        );
        return { ...item, gradeName: foundGrade?.gradeName, diameter: foundGrade?.diameter };
        // return item
      } else return item;
    });


  const shapes = useSelector((state) => state.shape.shapes);
  const orderItemIdValues = orderItems.map((item) => item.orderItemId);

  const columns = useSelector((state) => state.partItems.columns);
  const loading = useSelector((state) => state.partItems.loading);


  const [diameterValues, setDiameterValues] = useState([]);
  const [gradeValues, setGradeValues] = useState([]);
  const [orderItemValues, setOrderItemValues] = useState([]);
  const [orderItemsFilter, setOrderItemsFilter] = useState("");
  const [selectedPart, uSelectedPart] = useState({
    plId: "1",
    barType: "T",
    gradeName: "IS:1786 - FE550D",
  });

  const [selOrderItem, uSelOrderItem] = useState("");
  const [shDaBBS, setShDaBBS] = useState([]);
  const [shDaBBSEdit, setShDaBBSEdit] = useState([]);
  const [actualShape, setActualShape] = useState([])

  const [shapeId, setShapeId] = useState(null);
  const [partiesId, setPartiesId] = useState(null);
  const [multiDelete, setMultiDelete] = useState(false);
  const [editData, setEditData] = useState({});
  const [data, setData] = useState([]);
  const [id, setId] = useState(null);
  const [bbsShapeId, setBbsShapeId] = useState(null);
  const [bbsShapeIdEdit, setBbsShapeIdEdit] = useState(null);
  // const [dia, setDia] = useState('');
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deselector, setDeselector] = useState(0);
  const [shapeIdInput, setShapeIdInput] = useState(true);
  const [mag, setMag] = useState('');
  const [shapeIdInputValue, setShapeIdInputValue] = useState("");
  const [orderItemId, setOrderItemId] = useState("");

  const [searchKey, setSearchKey] = useState("");

  const [permission, setPermission] = useState({});
  useEffect(() => {
    dispatch(fetchUserAccess()).then((res) => {
      if (res.payload) {
        if (res.payload.navbarNames.Transaction["Stock Details"][0]) {
          setPermission(
            res.payload.navbarNames.Transaction["Stock Details"][0]
          );
        }
      }
    });
  }, []);


  const BBSDetailsProps = useMemo(() => {
    return {
      gradeName: partItems[partItems.length-1]?.gradeName || selectedPart?.gradeName,
      diameter: selectedPart?.diameter || partItems[partItems.length-1]?.diameter,
      noOfPieces: selectedPart?.noOfPieces,
      Element: orderItemsFilter,
      orderItemId: orderItemId,
      barMarkIdLength: partItems,
      tagDetails: data,
    }
  }, [partItems, selectedPart?.gradeName, selectedPart?.diameter, orderItemsFilter, orderItemId, selectedPart?.noOfPieces])



  useEffect(() => {
    orderItems.find((item) => {
      if (item.orderItemName === orderItemsFilter) {
        setOrderItemId(item.orderItemId);
      }
    });
  }, [orderItems]);




  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
    if (multiDelete === false && rowIndex !== null) {
      try {
        dispatch(searchShapes(`?shapeId=${data[rowIndex].shapeId}`))
          .then((res) => {
            if (res.payload) {
              // console.log(res.payload);
              if (res.payload.shapes.length === 0) {
                toast.error(`No shape found, ${data[rowIndex].shapeId}`)
                return
              }
              // console.log(res.payload.shapes[0]);
              setActualShape(res.payload.shapes[0].imageProperties)
            }
          })
        setBbsShapeIdEdit(data[rowIndex].id);
        // console.log(data[rowIndex]);
        let shapeDA = `${data[rowIndex].imageProperties}`;
        setId(data[rowIndex].partItemId);
        setMag(data[rowIndex].magnifications || "100,147,1");
        if (shapeDA !== "null") setShDaBBSEdit(shapeDA || "[]");
        else setShDaBBSEdit("[]");
      } catch (error) {
        toast.error(`${error}`);
      }
    }
  };


  const applyFilters = () => {
    let filteredData = partItems
      .filter((item) => `${item.orderId}` === orderId)
      .filter((item) => orderItemIdValues.includes(item.orderItemId));

    if (orderItemsFilter) {
      filteredData = filteredData.filter(
        (item) => item.orderItemName === orderItemsFilter
      );
    }

    setData(filteredData);
  };


  const handleAddButtonClick = () => {
    if (bbsShapeId !== null && bbsShapeId !== undefined) {
      setIsAdding(!isAdding);
      setDeselector(deselector + 1);

      if (data.length !== 0) {
        setEditData({ ...editData, gradeName: data[0].gradeName });
      }

      // setShapeId(shapeId);
      // setShDaBBS(shapeDA);
      // setBbsShapeId(ogId);
    } else toast.error("No shape is selected. Select a shape and try again");
    setShapeIdInputValue("");
  };

  const handleDeleteButtonClick = () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      if (multiDelete === false) {
        const id = data[selectedRowIndex].partItemId;
        dispatch(deletePartItems(id)).then(
          dispatch(fetchPartItems(`?orderId=${orderId}`))
        );
      } else {
        selectedRowIndex.map((rowId) => {
          const id = data[rowId].partItemId;
          // console.log(id)
          dispatch(deletePartItems(id)).then(
            dispatch(fetchPartItems(`?orderId=${orderId}`))
          );
        });
      }
    } else toast.error("No rows selected, Please select a row and try again");
    setSelectedRowIndex(null);
  };

  const handleEditButtonClick = () => {
    setDeselector(deselector + 1);

    const tempJson = {
      serviceCenterId: 1,
      id: 1, //
      diameter: 6,
      gradeName: "",
      orderItemId: "", //
      diffFactId: 2,
      routeId: 1,
      gradeId: 1,
      noOfPieces: "",
      totalNoOfPieces: "", //
      bundleId: 1,
      bmRow: 1, //posAfterBmrow
      lengthDeduction: 10,
      scale: 1,
      inventoryLength: "",
      productionLength: "",
      inventoryWeight: "",
      productionWeight: "",
      tagDescription: "",
      tagId: 1,
      bundleQuantity: "",
      pinDia: "",
      barMarkId: "",
      plId: 1,
      image: "",
    };

    if (multiDelete === false) {
      if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
        let tempEdit = {};

        Object.keys(tempJson).map((item) => {
          if (data[selectedRowIndex][item] !== undefined) {
            tempEdit = { ...tempEdit, [item]: data[selectedRowIndex][item] };
          }
        });

        setEditData(tempEdit);
        // if (data[selectedRowIndex].magnifications !== null) {
        setMag(data[selectedRowIndex].magnifications);
        // } else setMag("100,147,1");


        if (shDaBBSEdit === "") {
          toast.error("The shape used in the Tag is Unavailable");
        } else {

          try {
            let shapeInfo = JSON.parse(shDaBBSEdit);
            setIsEditing(true);
          } catch (error) {
            toast.error(`${error}`);
          }

        }
      } else toast.error("No rows selected, Please select a row and try again");
      setSelectedRowIndex(null);
    } else toast.error("Uncheck Multiple select and try again");
  };


  const handleShapeInput = (shapeId, condition = false) => {
    let foundShape = false;
    let ogId = null;
    let searchId = shapeId;

    let shapeDA = [];

    setShapeIdInputValue(shapeId);

    dispatch(searchShapes(`?shapeId=${searchId}`)).then((res) => {
      if (res.payload) {
        if (res.payload.shapes.length === 0) {
          toast.error("No shape found");
          return;
        } else {
          const shape = res.payload.shapes[0];
          foundShape = true;
          ogId = shape.id;
          setMag(shape.magnifications);
          // console.log(shape.magnifications);
          shapeDA = shape.imageProperties;
        }

        shapes.map((el) => {
          if (el.shapeId === shapeId) {
            // setId(el.id);
            ogId = el.id;
            // setMag(el.magnifications);
            foundShape = true;
          }
          if (el.shapeId === shapeId) {
            shapeDA = el.imageProperties;
          }
        });

        if (foundShape === false) {
          toast.error("No shape matches the Id");
          setShapeId(null);
          setShDaBBS(null);
          setBbsShapeId(null);

          return;
        }
        
        setShDaBBS(shapeDA);
        setBbsShapeId(ogId);

        if (condition) {

          if (ogId !== null && ogId !== undefined) {
            setIsAdding(!isAdding);
            setDeselector(deselector + 1);

            if (data.length !== 0) {
              setEditData({ ...editData, gradeName: data[0].gradeName });
            }

            // setShapeId(shapeId);
            // setShDaBBS(shapeDA);
            // setBbsShapeId(ogId);
          }
          setShapeIdInputValue("");
          return
        }

        setShapeId(shapeId);




        // setId(el.id);

        // if (shapeHead.toLowerCase() !== shape.category) {
        // dispatch(fetchShapes(`?category=${shape.category}`))
        // }
      }
    });

  };

  useEffect(() => {
    dispatch(fetchPartItems(`?orderId=${orderId}`));
    dispatch(fetchOrder());
    dispatch(fetchGrades());
    dispatch(fetchOrderItems());
    dispatch(fetchShapes("?category=0 Bend Without Arc"));
  }, [dispatch]);

  useEffect(() => {
    applyFilters();
  }, [orderItemsFilter]);

  const onClose = () => {
    setIsAdding(false);
    setIsEditing(false);
    setShapeId(null);
    setShDaBBS(null);
    setBbsShapeId(null);
  };

  useEffect(() => {
    const selectGrade = partItems[0]?.gradeName || selectedPart?.gradeName || 0;
    console.log(selectGrade);
    setDiameterValues(
      [...new Set(materials.filter( item => selectGrade?item.gradeName === selectGrade : true ).map((item) => item.diameter))].sort((a, b) => a - b)
    );
    setData(
      partItems
        .filter((item) => `${item.orderId}` === orderId)
        .filter((item) => orderItemIdValues.includes(item.orderItemId))
    );
    setGradeValues([...new Set(materials.map((item) => item.gradeName))]);
    setOrderItemValues([
      ...new Set(orderItems.map((items) => items.orderItemName)),
    ]);
    setPartiesId(
      orderData.map((el, index) => {
        if (index === 0) {
          return el.customerPartiesId;
        }
      })[0]
    );
  }, [materials, partItems]);

  //   useEffect(() => {
  //     let timer1 = setTimeout(() => {

  //     }, 500);

  //     return () => { clearTimeout(timer1) }

  // }, [searchKey])

  return (
    <div className="bbs">
      <div className="head">BBS Details for Order Id : {combId}</div>
      <div className="section1">
        <div className="shadow-box-1">
          <div className="row-1">
            <div className="box-item-1">
              <label htmlFor="customerName">Customer Name</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <input type="text" readOnly value={order.soldToParty} />
                ) : (
                  ""
                )
              )}
            </div>
            <div className="box-item-1">
              <label htmlFor="OrderDate">Order Date</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <input
                    id="Order Date"
                    type="date"
                    readOnly
                    value={dateChanger(order.createdTs)}
                  />
                ) : (
                  ""
                )
              )}
            </div>
            <div className="box-item-1">
              <label htmlFor="DeliveryDate">Delivery Date</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <input
                    id="DeliveryDate"
                    type="date"
                    readOnly
                    value={order.deliveryDate}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </div>
          <div className="row-2">
            <div className="box-item-1">
              <label htmlFor="totalTags">Total Tags</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <input
                    id="totalTags"
                    type="text"
                    readOnly
                    value={order.totalNoOfTags}
                  />
                ) : (
                  ""
                )
              )}
            </div>
            <div className="box-item-1">
              <label htmlFor="weight">Weight (T)</label>

              {orderData.map((order, index) =>
                index === 0 ? (
                  <input
                    id="weight"
                    type="text"
                    readOnly
                    value={order.productionWeight}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </div>
          <div className="dlvAdd">
            <div className="box-item-1">
              <label htmlFor="customerName">Delivery Address</label>
              {orderData.map((order, index) =>
                index === 0 ? (
                  <textarea
                    id="DeliveryAddress"
                    type="text"
                    readOnly
                    value={order.address}
                  />
                ) : (
                  ""
                )
              )}
            </div>
          </div>
        </div>
        <div className="shadow-box-2">
          <div className="row-1">
            <div className="box-item-1">
              <label htmlFor="diameter">Diameter</label>
              <select
                id="diameter"
                value={selectedPart.diameter}
                onChange={(e) =>
                  uSelectedPart({ ...selectedPart, diameter: e.target.value })
                }
              >
                <option value="">select</option>
                {diameterValues.map((value, index) => (
                  <option key={index} value={value}>
                    {" "}
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="box-item-1">
              <label htmlFor="PlId">Pl Id</label>
              <select
                value={selectedPart.plId}
                onChange={(e) =>
                  uSelectedPart({ ...selectedPart, plId: e.target.value })
                }
              >
                <option value="1">1</option>
              </select>
            </div>
          </div>
          <div className="row-2">
            <div className="box-item-1">
              <label htmlFor="barType">Bar Type</label>
              <select
                value={selectedPart.barType}
                onChange={(e) =>
                  uSelectedPart({ ...selectedPart, barType: e.target.value })
                }
              >
                <option value="T">T</option>
              </select>
            </div>
          </div>

          <div className="Element">
            <div className="box-item-1">
              <label htmlFor="el">Element</label>
              <select
                value={orderItemsFilter}
                onChange={(e) => setOrderItemsFilter(e.target.value)}
              >
                <option value="">All</option>
                {orderItemValues.map((value, index) => (
                  <option key={index} value={value}>
                    {" "}
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="Grade">
            <div className="box-item-1">
              <label htmlFor="grade">Grade</label>
              <select
                id="grade"
                value={selectedPart.gradeName}
                onChange={(e) =>
                  uSelectedPart({ ...selectedPart, gradeName: e.target.value })
                }
              >
                {gradeValues.map((value, index) => (
                  <option key={index} value={value}>
                    {" "}
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>

      <Shapes
        bbs={true}
        setShDaBBS={setShDaBBS}
        orderId={orderId}
        setShapeId={setShapeId}
        shapeId={shapeId}
        partiesId={partiesId}
        setBbsShapeId={setBbsShapeId}
        setMg={setMag}
      />
      <div className="head">
        Tag Details
        <div className="top-button-container" style={{ alignItems: "center" }}>
          <input
            placeholder="Enter Shape Id"
            className={`ShapeIdInput ${shapeIdInput === true ? "active" : ""}`}
            type="text"
            value={shapeIdInputValue}
            onChange={(e) => {
              setSearchKey(e.target.value);
              setShapeIdInputValue(e.target.value);
            }}
            // onBlur={() => handleAddButtonClick()}
            // autoFocus
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                if (shapeId === e.target.value) {
                  handleAddButtonClick();
                } else if (searchKey !== "") {
                  handleShapeInput(searchKey);
                  // console.log("search");
                }
              }
            }}
          />

          <img
            src={search}
            alt="search"
            style={{ marginRight: "10px" }}
            // autoFocus
            onClick={() => {
              if (searchKey !== "") {
                handleShapeInput(searchKey);
                // console.log("search");
              }
            }}
          />
          <TopButtonContainer
            add={handleAddButtonClick}
            edit={handleEditButtonClick}
            del={handleDeleteButtonClick}
            access={permission}
          />
        </div>
      </div>
      <div
        className="table-container"
        style={{ maxHeight: "350px", overflowY: "hide" }}
      >
        {loading !== "pending" ? (
          <AGTable
            columns={columns}
            data={data}
            onSelectRow={handleRowSelect}
            selectedRow={selectedRowIndex}
            bbs={true}
            rowSelection={`${multiDelete === false ? "single" : "multiple"}`}
            deselector={deselector}
          />
        ) : (
          <div
            style={{
              height: "300px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <div
              className="circle-loader3"
              style={{ position: "relative", left: "0", top: "0" }}
            ></div>
          </div>
        )}

        <table className="generic-table">
          <thead className='stick'>
            <tr>
              <th className="table-header-1">
                <label htmlFor="bbsEntryBmId">Bar Mark Id: </label>
                <input id="bbsEntryBmId" type="text" style={{ width: '70px' }} autoFocus onChange={(e) =>
                  setEditData({ ...editData, barMarkId: e.target.value })
                } />
              </th>
              <th className="table-header-1">
                <label htmlFor="bbsEntryDia">Diameter: </label>
                <select
                  id="bbsEntryDia"
                  value={selectedPart.diameter}
                  style={{ width: '70px' }}
                  onChange={(e) =>
                    uSelectedPart({ ...selectedPart, diameter: e.target.value })
                  }
                >
                  <option hidden value="">select</option>
                  {diameterValues.map((value, index) => (
                    <option key={index} value={value}>
                      {" "}
                      {value}
                    </option>
                  ))}
                </select>
              </th>
              <th className="table-header-1">
                <label htmlFor="bbsEntryNoOfPieces">No of Pcs: </label>
                <input id="bbsEntryNoOfPieces" type="number" style={{ width: '70px' }} onChange={(e) =>
                  uSelectedPart({ ...selectedPart, noOfPieces: e.target.value })
                } />
              </th>
              <th className="table-header-1">
                <label htmlFor="bbsEntryShapeId">Shape Id: </label>
                <input
                  id="bbsEntryShapeId"
                  type="text"
                  style={{ width: '90px' }}
                  value={shapeIdInputValue}
                  onChange={(e) => {
                    setSearchKey(e.target.value);
                    setShapeIdInputValue(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {

                      handleShapeInput(searchKey, true);
                      // console.log("search");

                    }
                  }}
                />
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <div className="bottombbs">
        <div className="bottomMultiDel">
          {/* <input type="checkbox" id="multiDel"
                        checked={multiDelete === true}
                        onClick={(e) => setMultiDelete(!multiDelete)} />
                    <label htmlFor="multiDel">Multiple delete</label> */}
        </div>
        <Button label="Close" className="blue" link="order details" />
        {/* <Button label="Add Part Item" className="blue" onClick={() => {setIsAdding(!isAdding); setDeselector(deselector+1)}} disabled={bbsShapeId} /> */}
      </div>
      {isAdding === true ? (
        <BbsShapeSetting
          BBSDetailsProps={BBSDetailsProps}
          tempDis={isAdding}
          orderId={orderId}
          uTempDis={onClose}
          gradeValues={gradeValues}
          orderItemValues={orderItemValues}
          diameterValues={diameterValues}
          shapeD={JSON.parse(shDaBBS)}
          ordItems={orderItems}
          bbsShapeId={bbsShapeId}
          materials={materials}
          mag={mag}
          editData={editData}
          lastData={data[data.length - 1] || []}
          actualShape={JSON.parse(shDaBBS)}
        />
      ) : (
        ""
      )}
      {isEditing === true ? (
        <BbsShapeSetting
          BBSDetailsProps={BBSDetailsProps}
          tempDis={isEditing}
          orderId={orderId}
          uTempDis={onClose}
          gradeValues={gradeValues}
          orderItemValues={orderItemValues}
          diameterValues={diameterValues}
          shapeD={JSON.parse(shDaBBSEdit)}
          ordItems={orderItems}
          bbsShapeId={bbsShapeIdEdit}
          materials={materials}
          editData={editData}
          id={id}
          compAction={"update"}
          mag={mag}
          actualShape={JSON.parse(actualShape)}

        />
      ) : (
        ""
      )}
    </div>
  );
};

export default BBS;
//End of File
