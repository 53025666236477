import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrderDateComparisonData } from "../../redux-tookit/slices/orderDateComparisonSlice";
import GenericTable from "../../components/GenericTable1";
import "../../assets/styles/ReportPage.css";

import { fetchOrderDateComparision } from "../../redux-tookit/slices/dashboardSlice";
import { Line, Bar } from "react-chartjs-2";

const OrderDateComparison = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.orderDateComparison.columns);
  const originalData = useSelector(
    (state) => state.orderDateComparison.labeledData
  );
  const [data, setData] = useState(originalData);

  const graphdata = useSelector((state) => state.dashboard.labeledData);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [customernameFilter, setcustomernameFilter] = useState("");
  const [ordernoFilter, setordernoFilter] = useState("");

  const [xaxisOrderId, setxaxisOrderId] = useState([]);
  const [yaxisProductionLength, setyaxisProductionLength] = useState([]);
  const [yaxisProductionWeight, setyaxisProductionWeight] = useState([]);
  const [yaxisInventoryLength, setyaxisInventoryLength] = useState([]);
  const [yaxisInventoryWeight, setyaxisInventoryWeight] = useState([]);

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };

  const customernameValues = [
    ...new Set(originalData.map((item) => item.customername)),
  ];
  const ordernoValues = [...new Set(originalData.map((item) => item.orderno))];

  const applyFilters = () => {
    let filteredData = originalData;
    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.orderDate);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }
    if (customernameFilter) {
      filteredData = filteredData.filter((item) =>
        item.customername.includes(customernameFilter)
      );
    }
    if (ordernoFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderno.includes(ordernoFilter)
      );
    }

    setData(filteredData);
  };

  useEffect(() => {
    dispatch(fetchOrderDateComparisonData());
    dispatch(fetchOrderDateComparision());
  }, [dispatch]);

  useEffect(() => {
    setData(originalData);
    console.log(graphdata);
    const orderId = graphdata.map((item) => item.orderId);
    const productionLength = graphdata.map((item) => item.productionLength);
    const productionWeight = graphdata.map((item) => item.productionWeight);
    const inventoryLength = graphdata.map((item) => item.inventoryLength);
    const inventoryWeight = graphdata.map((item) => item.inventoryWeight);

    setxaxisOrderId(orderId);
    setyaxisProductionLength(productionLength);
    setyaxisProductionWeight(productionWeight);
    setyaxisInventoryLength(inventoryLength);
    setyaxisInventoryWeight(inventoryWeight);
  }, [graphdata]);

  useEffect(() => {
    applyFilters();
  }, [customernameFilter, ordernoFilter]);

  const Linedataset = {
    labels: xaxisOrderId,
    datasets: [
      {
        label: "ProductionLength",
        data: yaxisProductionLength,
        borderColor: "#8884d8",
        backgroundColor: "#8884d8",
        pointBorderColor: "#8884d8",
        borderWidth: 1.4,
        pointBackgroundColor: "#8884d8",
        barThickness: 10,
      },
      {
        label: "ProductionWeight",
        data: yaxisProductionWeight,
        borderColor: "#82ca9d",
        backgroundColor: "#82ca9d",
        pointBorderColor: "#82ca9d",
        borderWidth: 1.4,
        pointBackgroundColor: "#82ca9d",
        barThickness: 10,
      },
      {
        label: "InventoryLength",
        data: yaxisInventoryLength,
        borderColor: "#8884d8",
        backgroundColor: "#8884d8",
        pointBorderColor: "#8884d8",
        borderWidth: 1.4,
        pointBackgroundColor: "#8884d8",
        barThickness: 10,
      },
      {
        label: "InventoryWeight",
        data: yaxisInventoryWeight,
        borderColor: "#82ca9d",
        backgroundColor: "#82ca9d",
        pointBorderColor: "#82ca9d",
        borderWidth: 1.4,
        pointBackgroundColor: "#82ca9d",
        barThickness: 10,
      },
    ],
  };

  const Lineoptions = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.parsed.y;
            const soldToParty = graphdata[context.dataIndex].soldToParty;
            return `  ${label}, SoldToParty: ${soldToParty}`;
          },
        },
      },
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 12,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "OrderID",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "Length ,Weight",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
        ticks: {
          color: "black",
          beginAtZero: true,
        },
      },
    },
  };

  return (
    <div className="sdbr">
      {/* <div className='filter-card'>
        <div className="filter-BMWQ">

          <div className="row-1">
            <div className="filter">
              <label htmlFor="customernameFilter">Customer</label>
              <select
                id="customernameFilter"
                value={customernameFilter}
                onChange={(e) => setcustomernameFilter(e.target.value)}
              >
                <option value="">All</option>
                {customernameValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>

            <div className='DateCont'>
              <label className='DateHead'>Order Creation Date</label>
              <div className="DateFilt">
                <div className="filterD">
                  <label htmlFor="fromDateFilter">From</label>
                  <input
                    id="fromDateFilter"
                    type="date"
                    value={fromDateFilter}
                    onChange={(e) => setFromDateFilter(e.target.value)}
                  />
                </div>
                <div className="filterD">
                  <label htmlFor="toDateFilter">To</label>
                  <input
                    id="toDateFilter"
                    type="date"
                    value={toDateFilter}
                    onChange={(e) => setToDateFilter(e.target.value)}
                  />
                </div>
              </div>
            </div>

          </div>
          <div className="row-2">
            <div className="filter">
              <label htmlFor="ordernoFilter">Order Number</label>
              <select
                id="ordernoFilter"
                value={ordernoFilter}
                onChange={(e) => setordernoFilter(e.target.value)}
              >
                <option value="">All</option>
                {ordernoValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div> */}
      <div className="head">Order Date Comparison</div>
      <div className="charts">
        <Line
          data={Linedataset}
          height={100}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          options={Lineoptions}
        />
      </div>
      <br />
      <br />
      <div>
        <GenericTable
          columns={columns}
          data={data}
          onSelectRow={handleRowSelect}
          selectedRow={selectedRowIndex}
        />
      </div>
    </div>
  );
};

export default OrderDateComparison;
