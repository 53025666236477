// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-header th:first-child {
  border-radius: 10px 0px 0px 0px;
  border-left: 0px;
  border-top: 0px;
}

.table-header th:last-child {
  border-radius: 0px 10px 0px 0px;
  border-top: 0px;
  border-right: 0px;
}

.table-header td:first-child {
  border-radius: 0px 0px 0px 10px;
  border-left: 0px;
  border-bottom: 0px;
}

.table-header td:last-child {
  border-radius: 0px 0px 10px 0px;
  border-bottom: 0px;
  border-right: 0px;
}

.generic-table .CostT tr:last-child td:first-child,
.generic-table .CostT tr:last-child td:last-child {

  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.generic-table2 th,
.generic-table2 td {
  border: 2px solid #ddd;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  text-align: center;
}

.generic-table2 td {
  font-weight: 400;
}


.generic-table2 {
  border-collapse: collapse;
  justify-content: center;
  text-align: center;
  border-radius: 10px;
  width: 100%;
}

.cosTable-container{
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}

.costEd{
  padding: 1vw;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/Costing.css"],"names":[],"mappings":"AAAA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,+BAA+B;EAC/B,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,+BAA+B;EAC/B,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,+BAA+B;EAC/B,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;;;EAGE,yCAAyC;AAC3C;;AAEA;;EAEE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;;AAGA;EACE,yBAAyB;EACzB,uBAAuB;EACvB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,yCAAyC;EACzC,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd","sourcesContent":[".table-header th:first-child {\r\n  border-radius: 10px 0px 0px 0px;\r\n  border-left: 0px;\r\n  border-top: 0px;\r\n}\r\n\r\n.table-header th:last-child {\r\n  border-radius: 0px 10px 0px 0px;\r\n  border-top: 0px;\r\n  border-right: 0px;\r\n}\r\n\r\n.table-header td:first-child {\r\n  border-radius: 0px 0px 0px 10px;\r\n  border-left: 0px;\r\n  border-bottom: 0px;\r\n}\r\n\r\n.table-header td:last-child {\r\n  border-radius: 0px 0px 10px 0px;\r\n  border-bottom: 0px;\r\n  border-right: 0px;\r\n}\r\n\r\n.generic-table .CostT tr:last-child td:first-child,\r\n.generic-table .CostT tr:last-child td:last-child {\r\n\r\n  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);\r\n}\r\n\r\n.generic-table2 th,\r\n.generic-table2 td {\r\n  border: 2px solid #ddd;\r\n  padding: 10px;\r\n  padding-left: 20px;\r\n  padding-right: 20px;\r\n  text-align: center;\r\n}\r\n\r\n.generic-table2 td {\r\n  font-weight: 400;\r\n}\r\n\r\n\r\n.generic-table2 {\r\n  border-collapse: collapse;\r\n  justify-content: center;\r\n  text-align: center;\r\n  border-radius: 10px;\r\n  width: 100%;\r\n}\r\n\r\n.cosTable-container{\r\n  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);\r\n  border-radius: 10px;\r\n}\r\n\r\n.costEd{\r\n  padding: 1vw;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
