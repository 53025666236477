import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import Button from '../../../utils/Button';
import { fetchEquipments } from '../../../redux-tookit/slices/equipmentMasterSlice';
import { fetchEquipmentCategory } from '../../../redux-tookit/slices/equipmentCategorySlice';
import { fetchProcessType } from '../../../redux-tookit/slices/processTypeSlice';
import { fetchServiceCenterEquipments, postServiceCenterEquipments, updateServiceCenterEquipment } from '../../../redux-tookit/slices/serviceCenterEquipmentsSlice';
import { fetchFacilities } from '../../../redux-tookit/slices/facilityMasterSlice';

const AddServiceCenterEquipments = (props) => {
    const dispatch = useDispatch();
    const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData } = props
    const columns = useSelector((state) => state.serviceCenterEquipments.columns);
    const equipmentData = useSelector((state) => state.equipmentMaster.labeledData);
    const facilityData = useSelector((state) => state.facilityMaster.labeledData);
    const category = useSelector(state => state.equipmentCategory.labeledData)
    const processType = useSelector(state => state.processType.labeledData);

    const [newRow, setNewRow] = useState({});
    const columnKeys = Object.keys(columns);

    const handleInputChange = (header, e) => {
        const updatedData = JSON.parse(JSON.stringify(editData));
        updatedData[header] = e.target.value;
        setEditData(updatedData);
    }

    const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        dispatch(postServiceCenterEquipments({ ...newRow }))
            .then((res) => {
                if (res.payload) {
                    dispatch(fetchServiceCenterEquipments())
                }
            })
        setIsAdding(false);
        setNewRow({});
    };
    console.log(editData);

    const handleUpdate = async (e) => {

        e.preventDefault();

        const selectedEquipment = equipmentData.find(org => org.equipmentName === editData.equipmentName);
        const selectedfacility = facilityData.find(org => org.facilityName === editData.facilityName);
        const selectedCategory = category.find(data => data.equipmentCategory === editData.equipmentCategory);
        const selectedProcess = processType.find(data => data.processType === editData.processType)

        const id = editData.sceId;
        const type = {
            'equipmentName': editData.equipmentName,
            'facilityId': selectedfacility.facilityId,
            'equipmentType': editData.equipmentType,
            'processTypeId': selectedProcess.processTypeId,
            'equipmentCategoryId': selectedCategory.equipmentCategoryId,
            'dateOfEntry': editData.dateOfEntry,
            'efficiency': editData.efficiency * 1,
        };


        dispatch(updateServiceCenterEquipment({ id, type })).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchServiceCenterEquipments())
            }
        })
        setIsEditing(false);
    }



    useEffect(() => {
        dispatch(fetchEquipments());
        dispatch(fetchFacilities());
        dispatch(fetchEquipmentCategory());
        dispatch(fetchProcessType())
    }, [isAdding]);
    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isAdding &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Add Service Center Equipments</div>
                            <div className="table-container">
                                {columnKeys.map((column, key) => (
                                    column !== 'sceId' ?
                                        (column === 'equipmentCategory' ?
                                            (<div className="filter">
                                                <label>
                                                    {columns[column].label}
                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                <select
                                                    key={key}
                                                    id="equipment"
                                                    onChange={(e) => {
                                                        setNewRow({ ...newRow, ['equipmentCategoryId']: e.target.value });
                                                    }}
                                                    required
                                                >
                                                    <option value=''>Select</option>
                                                    {category.map((value, index) => (
                                                        <option value={value.equipmentCategoryId}>
                                                            {value.equipmentCategory}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>)
                                            :
                                            column === 'processType' ?
                                                (<div className="filter">
                                                    <label>
                                                        {columns[column].label}
                                                        {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                    </label>
                                                    <select
                                                        key={key}
                                                        id="equipment"
                                                        onChange={(e) => {
                                                            setNewRow({ ...newRow, ['processTypeId']: e.target.value });
                                                        }}
                                                        required
                                                    >
                                                        <option value=''>Select</option>
                                                        {processType.map((value, index) => (
                                                            <option value={value.processTypeId}>
                                                                {value.processType}
                                                            </option>
                                                        ))}
                                                    </select>
                                                </div>)
                                                :
                                                column === 'facilityName' ?
                                                    (<div className="filter">
                                                        <label>
                                                            {columns[column].label}
                                                            {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                        </label>
                                                        <select
                                                            key={key}
                                                            id="facility"
                                                            onChange={(e) => {
                                                                setNewRow({ ...newRow, ['facilityId']: e.target.value });
                                                            }}
                                                            required
                                                        >
                                                            <option value=''>Select</option>
                                                            {facilityData.map((value) => (
                                                                <option value={value.facilityId}>
                                                                    {value.facilityName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>) :
                                                    column === 'efficiency' ?
                                                        (<div className="filter">
                                                            <label>
                                                                {columns[column].label}
                                                                {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                            </label>
                                                            <input
                                                                key={key}
                                                                type={columns[column].type}
                                                                className='table-input'
                                                                value={newRow[column] || ''}
                                                                min={1}
                                                                max={100}
                                                                onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                                required={columns[column].isRequired ? true : false}
                                                            />
                                                        </div>) :
                                                        column === 'equipmentType' ?
                                                            (<div className="filter">
                                                                <label>
                                                                    {columns[column].label}
                                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                                </label>
                                                                <select
                                                                    key={key}
                                                                    name="equipmentType"
                                                                    id="equipmentType"
                                                                    required={columns[column].isRequired ? true : false}
                                                                    onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                                >
                                                                    <option value="" hidden>-Select-</option>
                                                                    <option value="Manual">Manual</option>
                                                                    <option value="Automatic">Automatic</option>
                                                                </select>
                                                            </div>) :
                                                            (<div className="filter">
                                                                <label>
                                                                    {columns[column].label}
                                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                                </label>
                                                                <input
                                                                    key={key}
                                                                    type={columns[column].type}
                                                                    className='table-input'
                                                                    value={newRow[column] || ''}
                                                                    onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                                    required={columns[column].isRequired ? true : false}
                                                                />
                                                            </div>)
                                        ) :
                                        ""
                                ))}

                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsAdding(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Edit Service Center Equipment</div>
                            <div className="table-container">
                                {Object.keys(columns).map((key) => (columns[key].editable === true ?
                                    (key === 'equipmentCategory' ?
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                            <label>
                                                {columns[key].label}
                                                {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                            </label>
                                            <select
                                                id="equipment"
                                                value={editData[key]}
                                                onChange={(e) => {
                                                    handleInputChange('equipmentCategoryId', e);
                                                }}
                                                required={columns[key].isRequired ? true : false}

                                            >

                                                {category.map((value, index) => (
                                                    <option key={index} value={value.equipmentCategoryId}>
                                                        {value.equipmentCategory}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>) :
                                        key === 'processType' ?
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[key].label}
                                                    {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                <select
                                                    id="equipment"
                                                    value={editData[key]}
                                                    required={columns[key].isRequired ? true : false}
                                                    onChange={(e) => {
                                                        handleInputChange('processTypeId', e);
                                                    }}
                                                >

                                                    {processType.map((value, index) => (
                                                        <option key={index} value={value.processTypeId}>
                                                            {value.processType}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>) :
                                            key === 'equipmentType' ?
                                                (<div className="filter">
                                                    <label>
                                                        {columns[key].label}
                                                        {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                    </label>
                                                    <select
                                                        value={editData[key]}
                                                        name="equipmentType"
                                                        required={columns[key].isRequired ? true : false}
                                                        onChange={(e) => handleInputChange(key, e)}
                                                        id="equipmentType"
                                                    >
                                                        <option value="" hidden>-Select-</option>
                                                        <option value="Manual">Manual</option>
                                                        <option value="Automatic">Automatic</option>

                                                    </select>
                                                </div>) :
                                                (key === 'facilityName' ?
                                                    (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                        <label>
                                                            {columns[key].label}
                                                            {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                        </label>
                                                        <select
                                                            id="facility"
                                                            value={editData[key]}
                                                            required={columns[key].isRequired ? true : false}
                                                            onChange={(e) => {
                                                                handleInputChange('facilityId', e);
                                                            }}
                                                        >

                                                            {facilityData.map((value, index) => (
                                                                <option key={index} value={value.facilityId}>
                                                                    {value.facilityName}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>) :
                                                    (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                        <label>
                                                            {columns[key].label}
                                                            {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                        </label>
                                                        <input
                                                            type={columns[key].type}
                                                            className='table-input'
                                                            value={editData[key]}
                                                            required={columns[key].isRequired ? true : false}
                                                            onChange={(e) => handleInputChange(key, e)}
                                                            pattern={columns[key].pattern}
                                                        /></div>))) :
                                    ""
                                ))}
                            </div>
                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>
            }
            </form>
        </div>
    )
}

export default AddServiceCenterEquipments