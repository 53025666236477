import { useEffect, useRef, useState } from 'react';
import * as XLSX from "xlsx";
import AddNewShape from '../../pages/master/Shape/AddNewShape';
import { useDispatch } from 'react-redux';
import { postOldData } from '../../redux-tookit/slices/dataMigration';
import { fetchShapes } from '../../redux-tookit/slices/shapeSlice';


const DataConverter = () => {


    const dispatch = useDispatch();

    
    const [excelJson, setExcelJson] = useState([])

    const [shape, setShape] = useState([]);

    const [tShapeProp, setTShapeProp] = useState([]);

    const [orginX, setOrginX] = useState(100);

    const [orginY, setOrginY] = useState(147);

    const [zoom1, setZoom1] = useState('')

    const [bbsCanvasRef, setBbsCanvasRef] = useState()


    console.log(shape);
    ////////////////////////////////////////////////////


    const bbs = false, saveInit = ''

    const canvasRef = useRef(null);
    const ctxRef = useRef(null);
    const fontSize = 20
    let count = 0;
    const [eff, setEff] = useState(0);

    let centerX = 0;
    let centerY = 0;

    let currentX = orginX;
    let currentY = orginY;
    let mF = 1

    let startAngle = 0, endAngle = 0, arcAngle = Math.PI;
    let direction = 0, pie = Math.PI;
    let endX = currentX, endY = currentY, x = 1, length = 100, textCount = 1, textNo;

    const [l1, setl1] = useState(100);
    const [l2, setl2] = useState(100);
    const [minX, setMinX] = useState(100);
    const [minY, setMinY] = useState(147);
    const [maxX, setMaxX] = useState(100);
    const [maxY, setMaxY] = useState(147);

    useEffect(()=> {
        dispatch(fetchShapes()).then(res => {
            if(res.payload){
                setShape(res.payload.shapes)
            }
        })
    },[])


    useEffect(() => {

        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        ctxRef.current = ctx;
        ctx.lineWidth = 3;
        ctx.font = `bold ${fontSize}px Arial`;

        setBbsCanvasRef(canvas)
    }, [eff]);

    // Excel converter

    const excelToJson = (e) => {
        return new Promise((resolve, reject) => {

            const reader = new FileReader();
            reader.onload = (event) => {
                try {
                    const data = event.target.result;
                    const workbook = XLSX.read(data, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0]; // Assuming the data is in the first sheet
                    const worksheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                    resolve(jsonData);
                    console.log(jsonData);
                } catch (error) {
                    console.log(`${error}`)
                    e.target.value = null
                }
            };
            reader.onerror = (error) => {
                reject(error);
            };
            reader.readAsBinaryString(e.target.files[0]);
        });
    }


    const handleFileUpload = (e) => {

        excelToJson(e)
            .then((jsonData) => {
                console.log(jsonData); // Do something with the JSON data
                if (jsonData.length === 0) {
                    console.log('Excel is empty.')
                    e.target.value = null
                }
                setExcelJson(jsonData);
                // setIsConverter(true)
            })
            .catch((error) => {
                console.error('Error converting Excel to JSON:', error);
            });
    }

    //Drawing functions

    const captCheck = (value) => {
        if (value) {
            if (value === 'y') {
                return 'Yes'
            } else if (value === 'n') {
                return 'No'
            }
        } else return ''
    }

    const couplerConverter = (value) => {
        if (value === 'FullThread') return 'Full Thread'
        else if (value === 'HalfThread') return 'Half Thread'
        else if (value === 'CouplerB') return 'Coupler B'
        else return value
    }

    const converter = () => {
        const dataArray = excelJson.slice(1, 17810);//17810 , 17818, 17873
        let newDataArray = []
        let shapeId = '';
        let bends = 0;
        let bendCount = 0;
        let arcCh = false;
        let category = '';
        let imgProp = [];
        let threads = 0;

        dataArray.forEach((data, index) => {
            if (data[1] !== shapeId) {
                if (index !== 0) {

                    let ShapeCat = '';

                    if (bendCount > 4) {
                        ShapeCat = `multi bend ${arcCh === true ? 'with' : 'without'} arc`
                    } else { ShapeCat = (`${bendCount} bend ${arcCh === true ? 'with' : 'without'} arc`) }

                    newDataArray.push({
                        shapeId: shapeId,
                        category: ShapeCat,
                        imageProperties: JSON.stringify(imgProp),
                        image: '',
                        noOfBends: bends,
                        magnifications: '',
                        threads: threads
                    })

                    ShapeCat = '';
                    imgProp = [];
                    bends = 0;
                    bendCount = 0;
                    arcCh = false;
                    threads = 0;
                }
            }

            if (data[2] === 'Arc') {
                arcCh = true;
                if (data[14] === 360 || data[14] === -360) {
                    bends += 6;
                } else if (data[14] === -180 || data[14] === 180) {
                    bends += 7;
                }
            }
            if (data[2] === 'Angle' && data[1] * 1 !== 1) {
                bends = bends + 1;
                bendCount += 1
            }
            if (data[2] === 'Coupler') {
                threads++;
            }
            
            imgProp.push({
                Geometry: data[2],
                Type: data[2] === 'Angle' ? data[10] === 'Clock' ? 'Clockwise' : 'Anticlock' : couplerConverter(data[10]),
                Length1: data[13] || 0,
                Capt1: captCheck(data[3]) || '',
                Match1: data[15] || '',
                Angle: data[14] || '',
                Capt2: captCheck(data[4]) || '',
                Match2: data[16] || 0,
                Length2: data[18] || 0,
                Capt3: captCheck(data[5]) || '',
                Match3: data[17] || ''
            })

            shapeId = data[1];
        })

        console.log(dataArray);
        console.log(newDataArray);
        const finalData = imageCreator(newDataArray)

        // setZoom1(finalData[50].magnifications)
        // console.log(finalData, zoom1, finalData[50].shapeId);
        // setShape(JSON.parse(finalData[50].imageProperties))

        const newRow = finalData

        // dispatch(postOldData(newRow));

    }

    const imageCreator = (data) => {
        let tempData = data
        tempData.forEach((el, index) => {
            const properties = el.imageProperties;
            const mag = magFinder(JSON.parse(properties))

            const dataURL = draw(JSON.parse(properties), `50,100,${mag}`)
            tempData[index].image = dataURL;
            tempData[index].magnifications = `50,100,${mag}`
        })
        return tempData
    }

    const magFinder = (properties) => {
        let length = 0;

        properties.forEach(el => {
            if (el.Geometry === 'Length') length += el.Length1;

        })
        return 300 / length;
    }

    const clearCanvas = (mag) => {
        const [oX, oY, zoom] = mag.split(',')

        const ctx = ctxRef.current;
        ctx.clearRect(0, 0, 462, 294);

        currentX = oX * 1;
        currentY = oY * 1;
        direction = 0; textCount = 1;
        length = 100;

        count++;
    };

    const draw = (drawingElements, mag) => {
        const [oX, oY, zoom] = mag.split(',')

        clearCanvas(mag);
        currentX = oX * 1;
        currentY = oY * 1;
        mF = zoom * 1;
        let arcNo = 0


        drawingElements.map((i, k) => {

            i.Geometry === "Length" ? (
                i.Type === 'Actual' ? drawLine(i.Length1, 0, `${i.Capt1}-${i.Match1}`) :
                    i.Type === 'Normal' ? drawLine(i.Length1, 1, `${i.Capt1}-${i.Match1}`) :
                        i.Type === 'Offset' ? drawLine(i.Length1, 2, `${i.Capt1}-${i.Match1}`) :
                            drawOfNor(i.Length1, i.Length2, `${i.Capt1}-${i.Match1}`, mag)
            ) : i.Geometry === "Arc" ? (
                i.Type === 'Rad+Sw_Angle' ? drawCurve(i.Length1, i.Angle * Math.PI / 180, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:R:${i.Length1},S_A:${i.Angle}`, ++arcNo) :
                    i.Type === 'Rad+ArcLength' ? drawCurve(i.Length1, i.Length2 / i.Length1, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:R:${i.Length1},A:${i.Angle}`, ++arcNo) :
                        i.Type === 'Dia+ArcLength' ? drawCurve(i.Length1 / 2, i.Length2 / i.Length1, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:D:${i.Length1},A:${i.Angle}`, ++arcNo) :
                            i.Type === 'Dia+Sw_Angle' ? drawCurve(i.Length1 / 2, i.Angle * Math.PI / 180, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:D:${i.Length1},S_A:${i.Angle}`, ++arcNo) :
                                drawCurve((((i.Length1 * i.Length1) / 4) + i.Length2 * i.Length2) / (2 * i.Length2), 2 * Math.PI, `${i.Capt1}-${i.Match1}-${i.Geometry}`, `${i.Match1}:C:${i.Length1},N:${i.Angle}`, ++arcNo)
            ) : i.Geometry === "Angle" ? (k === 0 ? (i.Type === 'Clockwise' ? drawCurve(0, i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match2}-${i.Geometry}`) : drawCurve(0, -i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match2}-${i.Geometry}`)) :
                (i.Type === 'Clockwise' ? drawCurve(20, i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match2}-${i.Geometry}`) : drawCurve(20, -i.Angle * Math.PI / 180, `${i.Capt2}-${i.Match2}-${i.Geometry}`))
            ) : i.Type === 'Half Thread' ?
                drawCoupler(15, 3) :
                i.Type === 'Full Thread' ?
                    drawCoupler(15, 5) :
                    drawCoupler(2.5, 10)
            return 0;

        })

        const canvas = canvasRef.current;

        const dataURL = canvas.toDataURL('image/png');

        return dataURL
    }



    const drawLine = (l, lineCase, CapLet) => {

        // const [oX, oY, mF] = mag.split(',')
        let [CapUse, Letter] = CapLet.split('-');

        const ctx = ctxRef.current;
        // let ogLength = l;
        l *= mF;

        if (lineCase === 0) {
            length = l;
        } else
            if (lineCase === 1) {
                (length = l / Math.sin(-direction));
                dashDraw(l, currentX, currentY, currentX, currentY + l / l * l);
            } else {
                (length = l / Math.cos(-direction));
                dashDraw(l, currentX, currentY, currentX + l / l * l, currentY)
            }

        endX = currentX * 1 + length * Math.cos(direction);
        endY = currentY * 1 - length * Math.sin(direction);

        ctx.beginPath();
        ctx.moveTo(currentX, currentY);
        ctx.lineTo(endX, endY);
        ctx.stroke();
        direction % (2 * pie) < 0 && direction % (2 * pie) >= -pie ? x = -1 : x = 1;
        const midX = (currentX + endX) / 2;
        const midY = (currentY + endY) / 2;
        const textX = midX + 25 / (1 + (1 - x) / 20) * Math.cos(((1 - x) / 2 * pie + direction - pie / 2));
        const textY = midY - 25 / (1 + (1 - x) / 20) * Math.sin(((1 - x) / 2 * pie + direction - pie / 2));

        if (CapUse === 'Yes') {
            if (Letter === '') {

                textNo = String.fromCharCode(64 + textCount);

            }
            else 
            // if (bbs === false) 
                textNo = Letter;
            // else if (saveInit === false) textNo = ogLength + ":" + Letter;
            // else textNo = ogLength;
            ctx.fillStyle = 'Black';
            ctx.fillText(textNo, textX, textY);
        }

        textCount++;


        currentX = endX;
        currentY = endY;

        


        // console.log({ endX, endY })
        count++;

    };



    const drawCoupler = (sS, n) => {
        const ctx = ctxRef.current;
        for (let i = 0; i < n; i++) {
            let x1 = currentX + 10 * Math.cos(direction - pie / 2);
            let y1 = currentY - 10 * Math.sin(direction - pie / 2);
            let x2 = currentX - 10 * Math.cos(direction - pie / 2);
            let y2 = currentY + 10 * Math.sin(direction - pie / 2);
            ctx.beginPath();
            ctx.moveTo(x1, y1);
            ctx.lineTo(x2, y2);
            ctx.stroke();

            if (i === n - 1) {
                break;
            }

            endX = currentX + sS * Math.cos(direction);
            endY = currentY - sS * Math.sin(direction);

            ctx.moveTo(currentX, currentY);
            ctx.lineTo(endX, endY);
            ctx.stroke();
            currentX = endX;
            currentY = endY;

        }



        count++;
    };

    const drawCurve = (r, ang, CapLet, data, arcNo) => {
        // const [oX, oY, mF] = mag.split(',')
        let [CapUse, Letter, Geom] = CapLet.split('-');
        const ctx = ctxRef.current;
        let radius = r * mF, i = 0;
        ang < 0 ? x = -1 : x = 1;
        radius *= x;
        arcAngle = ang;
        centerX = currentX + radius * Math.cos(direction - pie / 2);
        centerY = currentY - radius * Math.sin(direction - pie / 2);
        startAngle = -x * (x * direction + pie / 2);
        endAngle = startAngle + arcAngle;
        radius *= x;

        const endX = centerX + radius * Math.cos(endAngle);
        const endY = centerY + radius * Math.sin(endAngle);

        ctx.beginPath();
        ctx.arc(centerX, centerY, radius, startAngle, endAngle, x === -1);
        ctx.stroke();
        ctx.fillStyle = 'blue';

        if (CapUse === 'Yes') {
            if (Letter === '' && Letter === undefined) { textNo = String.fromCharCode(64 + textCount); }
            else textNo = Letter
        }
        if (Geom === 'Arc') { ctx.fillStyle = 'blue'; }
        else { ctx.fillStyle = 'red'; }

        CapUse === 'Yes' ? ctx.fillText(textNo, centerX, centerY) : i++;

        if (CapUse === 'Yes' && Geom === 'Arc') {
            ctx.fillText(data, 210, arcNo * fontSize)
        }

        currentX = endX;
        currentY = endY;
        direction -= arcAngle;
        textCount++;

        if (x === 1) { startAngle += 2 * pie; endAngle += 2 * pie; }

        if (startAngle <= 0 && 0 <= endAngle) { if (maxY < centerY + radius) setMaxX(centerX + radius); }
        if (startAngle <= pie / 2 && pie / 2 <= endAngle) { if (maxX < centerX + radius) { setMaxX(centerX + radius); } }
        if (startAngle <= pie && pie <= endAngle) { if (minX > centerX - radius) setMinX(centerX - radius); }
        if (startAngle <= (3 * pie / 2) && (3 * pie / 2) <= endAngle) { if (minY > centerY - radius) setMinY(centerY - radius); }



        count++;
    }

    const drawOfNor = (ll1, ll2, CapLet) => {

        let [CapUse, Letter] = CapLet.split('-');
        setl1(ll1 * mF);
        setl2(ll2 * mF);


        const ctx = ctxRef.current;

        direction = -(Math.atan(l2 / l1));

        dashDraw(ll1, currentX, currentY, currentX + l1 / l1 * l1, currentY);

        dashDraw(ll2, currentX + l1 / l1 * l1, currentY, currentX + l1 / l1 * l1, currentY + l2 / l2 * l2);

        ctx.beginPath();
        ctx.moveTo(currentX, currentY);
        ctx.lineTo(currentX + l1 / l1 * l1, currentY + l2 / l2 * l2);
        ctx.stroke();

        if (CapUse === 'Yes') {
            if (Letter === '') { textNo = String.fromCharCode(64 + textCount); }
            else textNo = Letter
        }
        let midX = currentX + l1 / 2;
        let midY = currentY + l2 / 2;
        x = 1;
        let textX = midX + 30 * Math.cos((x * direction - pie / 2));
        let textY = midY - 30 * Math.sin(((1 - x) / 2 * pie + direction - pie / 2));
        ctx.fillStyle = 'Black';
        ctx.fillText(textNo, textX, textY);

        currentX += l1; endX = currentX;
        currentY += l2; endY = currentY;
        if (endX < minX) { setMinX(endX); }
        if (endY < minY) { setMinY(endY); }
        if (endX > maxX) { setMaxX(endX); }
        if (endY > maxY) { setMaxY(endY); }

    }

    const dashDraw = (dLength, x1, y1, x2, y2) => {
        const ctx = ctxRef.current;

        ctx.setLineDash([5, 5]);
        ctx.beginPath();
        ctx.moveTo(x1 - 1 + 1, y1 - 1);
        ctx.lineTo(x2 - 1 + 1, y2 - 1 + 1);
        ctx.stroke();
        ctx.setLineDash([]);
        let midX = (x1 + x2) / 2, midY = (y1 + y2) / 2;
        if (y1 === y2) {
            midY -= 10;
        } else {
            midX += 10;
        }
        ctx.fillStyle = 'Black';
        ctx.fillText(dLength, midX, midY);
    }

    console.log(shape);
    console.log(excelJson);
    return (
        <div className="App">
            <label htmlFor='excelUpload'></label>
            <input type="file" accept='.xlsx' onChange={(e) => handleFileUpload(e)} />
            {(shape.length !== 0 && zoom1 !== '') && <AddNewShape initialShape={shape} mag={zoom1} />}
            <button onClick={converter}>Do it!</button>
            {shape.length === 0 && <div style={{ display: 'none' }}>

                <canvas ref={canvasRef} width="400" height="295" style={{ width: (bbs === true ? '250px' : '') }}></canvas><br />

            </div>}
            
        </div>
    );
}

export default DataConverter
