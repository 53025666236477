import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoadingList } from "../../redux-tookit/slices/loadingListSlice";
import { fetchOrderLLRelationData } from "../../redux-tookit/slices/orderLLRelationSlice";
import TableComponent from "../../components/TableComponent2";
import { Line, Bar } from "react-chartjs-2";

const Order_LL_Relation = () => {
  const [xaxisorderId, setxaxisorderId] = useState([]);
  const [yaxisweightBridgeWeight, setyaxisweightBridgeWeight] = useState([]);
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.orderLLRelation.columns);
  const data = useSelector((state) => state.orderLLRelation.labeledData);
  const graphdata = useSelector((state) => state.loadingList.labeledData);

  useEffect(() => {
    // Fetch data when the component mounts
    dispatch(fetchOrderLLRelationData());
    dispatch(fetchLoadingList()); // Specify the page name
  }, [dispatch]);

  useEffect(() => {
    console.log(graphdata);

    const orderId = graphdata.map((item) => item.orderId);
    const weightBridgeWeight = graphdata.map((item) => item.weightBridgeWeight);

    setxaxisorderId(orderId);
    setyaxisweightBridgeWeight(weightBridgeWeight);
  }, [graphdata]);

  const dataset = {
    labels: xaxisorderId,
    datasets: [
      {
        label: "WeightBridgeWeight",
        data: yaxisweightBridgeWeight,
        borderColor: "orange",
        backgroundColor: "orange",
        pointBorderColor: "orange",
        borderWidth: 2,
        pointBackgroundColor: "orange",
        barThickness: 15,
      },
    ],
  };

  const optionsForcoil = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            var label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            label += context.parsed.y;
            const diameter = graphdata[context.dataIndex].diameter;
            return `  ${label}`;
          },
          afterLabel: function (context) {
            return (
              "trailerNo: " +
              graphdata[context.dataIndex].trailerNo +
              ", " +
              "totalBundles: " +
              graphdata[context.dataIndex].totalBundles +
              ", " +
              "totalRods: " +
              graphdata[context.dataIndex].totalRods +
              ", " +
              "totalWeight: " +
              graphdata[context.dataIndex].totalWeight
            );
          },
        },
      },
      title: {
        fontSize: 50,
        display: false,
        font: { size: 20 },
      },
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 1,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "OrderID",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "WeightBridgeWeight",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
      },
    },
  };
  return (
    <div className="sdbr">
      <div className="head">Loadinglist to Order</div>

      <div className="charts">
        <Bar
          // className={hmiCss.formGroup2}
          data={dataset}
          options={optionsForcoil}
          height={400}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        />
      </div>
      <br />
      <br />

      <TableComponent columns={columns} data={data} />
    </div>
  );
};

export default Order_LL_Relation;
