import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/API";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  columns: {},
  labeledData: [],
  column2: {},
  labeledData2: [],
  loading: "idle",
  error: null,
};

export const fetchProdRecordOrderWise = createAsyncThunk(
  "data/fetchProdRecordOrderWise",
  async () => {
    try {
      const response = await api.listProdRecordOrderWise();
      const json = response.data;

      const labeledData = json.rows;

      return { labeledData };
    } catch (error) {
      throw error;
    }
  }
);

const productionRecordedSlice = createSlice({
  name: "productionRecorded",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProdRecordOrderWise.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.columns2 = action.payload.columns;
        state.labeledData2 = action.payload.labeledData;
      })
      .addCase(fetchProdRecordOrderWise.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      });
  },
});

export default productionRecordedSlice.reducer;
//End of File
