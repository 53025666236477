import { useContext } from "react"
import { UserAccessContext } from "../../../App"
import CreateSmartFabLL from "./CreateSmartFabLL";
import CreateReadyBuildLL from "./CreateReadyBuildLL";

const CreateLoadingDetails = () => {
    const productName = useContext(UserAccessContext).productName;
    return(
        productName === 'smartFab'? <CreateSmartFabLL/> : <CreateReadyBuildLL/>
    )
}

export default CreateLoadingDetails
