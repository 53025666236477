
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GenericTable from '../../components/GenericTable';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import '../../assets/styles/MaxRebarPerCycle.css'
import addnew from '../../assets/images/button-icons/Vector.svg';
import edit from '../../assets/images/button-icons/edit.svg';
import excel from '../../assets/images/button-icons/excel.svg';
import refresh from '../../assets/images/button-icons/Refresh.svg';
import del from '../../assets/images/button-icons/delete.svg';
import ExcelDownloadButton from '../../components/ExcelDownloadButton';


const MaxRebarPerCycle = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.maxRebarPerCycle.columns);
  const originalData = useSelector((state) => state.sdbr.maxRebarPerCycle.data);
  const [data, setData] = useState(originalData);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const [machineNameFilter, setMachineNameFilter] = useState('');
  const [gradeFilter, setGradeFilter] = useState('');
  const [diameterFilter, setdiameterFilter] = useState('');


  const machineNameValues = [...new Set(originalData.map(item => item.machineName))];
  const gradeValues = [...new Set(originalData.map(item => item.grade))];
  const diameterValues = [...new Set(originalData.map(item => item.diameter))];

  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };

  const handleRefresh = () => {
    setMachineNameFilter('');
    setGradeFilter('');
    setdiameterFilter('');
};

  const applyFilters = () => {
    let filteredData = originalData;


    if (machineNameFilter) {
      filteredData = filteredData.filter((item) =>
        item.machineName.includes(machineNameFilter)
      );
    }

    if (gradeFilter) {
      filteredData = filteredData.filter((item) =>
        item.grade.includes(gradeFilter)
      );
    }

    if (diameterFilter) {
      filteredData = filteredData.filter((item) =>
        item.diameter.includes(diameterFilter)
      );
    }

    setData(filteredData);
  }

  useEffect(() => {
    dispatch(fetchDataFromApi('maxRebarPerCycle'));
    setData(originalData);
  }, [dispatch, originalData]);

  useEffect(() => {
    applyFilters();
  }, [machineNameFilter, gradeFilter, diameterFilter])
  return (
    <div className='sdbr'>
      <div className="filter-card" >
        <div className="MaxFilt">
          <div className="filter">
            <label htmlFor="machineNameFilter">Machine Name</label>
            <select
              id="machineNameFilter"
              value={machineNameFilter}
              onChange={(e) => setMachineNameFilter(e.target.value)}
            >
              <option value="">All</option>
              {machineNameValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label htmlFor="gradeFilter">Grade</label>
            <select
              id="gradeFilter"
              value={gradeFilter}
              onChange={(e) => setGradeFilter(e.target.value)}
            >
              <option value="">All</option>
              {gradeValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label htmlFor="diameterFilter">Diameter</label>
            <select
              id="diameterFilter"
              value={diameterFilter}
              onChange={(e) => setdiameterFilter(e.target.value)}
            >
              <option value="">All</option>
              {diameterValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>

      </div>
      <div className='head'>Max Rebar Per Cycle
        <div className="top-button-container">
          <img src={addnew} alt="add" />
          <img src={edit} alt="edit" />
          <img src={del} alt="delete" />
          <img src={refresh} alt="refresh" onClick={handleRefresh}/>
          <ExcelDownloadButton csvData={data} fileName='MaxRebarList' />
        </div>
      </div>

      <GenericTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
    </div>
  );
};

export default MaxRebarPerCycle;