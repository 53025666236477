import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API'; 

const initialState = {
    columns: {},
    labeledData: [],
    loading: 'idle',
    error: null,
};

export const fetchDiaWiseRoute = createAsyncThunk('data/fetchDiaWiseRoute', async () => {
    try {
        const response = await api.listDiaWiseRoute();
        const json = response.data;

        const columnLabels = {
            routeId: { label: "Route Id", type: 'text', isRequired: true ,pattern:'.*'},
            diameter: { label: "Diameter", type: 'number', isRequired: true ,pattern:'.*'},
            route: { label: "Route Desc", type: 'text', isRequired: true ,pattern:'.*'}
            // Add more as needed
        };

        const selectedColumns = ['routeId','diameter','route'];

        const columns = {};
        selectedColumns.forEach((columnName) => {
            columns[columnName] = columnLabels[columnName];
        });

        const labeledData = json.rows.map((row) => {
            const rowData = {};
            selectedColumns.forEach((columnName) => {
                rowData[columnName] = row[columnName];
            });
            return rowData;
        });

        return { columns, labeledData };
    } catch (error) {
        throw error;
    }
});
export const postDiaWiseRoute = createAsyncThunk('data/postDiaWiseRoute', async (newRow) => {
    try {

        const response = await api.addDiaWiseRoute(newRow);
        const data = response.data;
        console.log('Success');
        return data;
    } catch (error) {
        throw error;
    }
});
export const deleteDiaWiseRoute = createAsyncThunk('data/deleteDiaWiseRoute', async (id) => {
    try {
      await api.deleteDiaWiseRoute(id);
      return id;
    } catch (error) {
      throw error;
    }
  });
const diaWiseRouteSlice = createSlice({
    name: 'diaWiseRoutes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchDiaWiseRoute.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchDiaWiseRoute.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.columns = action.payload.columns;
                state.labeledData = action.payload.labeledData;
            })
            .addCase(fetchDiaWiseRoute.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(postDiaWiseRoute.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(postDiaWiseRoute.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.labeledData.push(action.payload);
            })
            .addCase(postDiaWiseRoute.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(deleteDiaWiseRoute.pending, (state) => {
                state.loading = 'pending';
              })
              .addCase(deleteDiaWiseRoute.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                const deletedId = action.payload;
                state.labeledData=state.labeledData.filter((data) => data.equipmentId !== deletedId);
              })
              .addCase(deleteDiaWiseRoute.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
              });
    },
});

export default diaWiseRouteSlice.reducer;
//End of File