import React, { useState, useEffect } from "react";
import * as api from "../../../services/API";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
const CustomerWiseOrder = () => {
  const [customerWiseOrders, setCustomerWiseOrders] = useState([]);
  const [allCustomers, setAllCustomers] = useState([]);
  const [
    regionWiseServiceCenterDataForCustomer,
    setRegionWiseServiceCenterDataForCustomer,
  ] = useState([]);
  const [regionForCustomer, setRegionForCustomer] = useState("All");
  const [
    serviceCenterNameForCustomerWise,
    setServiceCenterNameForCustomerWise,
  ] = useState("All");
  const [serviceCenterListData, setServiceCenterListData] = useState([]);
  const [customerOrderWiseFilter, setCustomerOrderWiseFilter] = useState("All");
  const regions = ["North", "South", "East", "West"];
  const roleName = localStorage.getItem("roleName");
  const regionName = localStorage.getItem("region");
  const getServiceCenterList = async () => {
    await api
      .listServiceCenter()
      .then((result) => {
        setServiceCenterListData(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getServiceCenterList();
  }, []);
  console.log(customerWiseOrders, "customerWiseOrders");
  useEffect(() => {
    setServiceCenterNameForCustomerWise("All");
    setCustomerOrderWiseFilter("All");
    getAppMetricsByRegions();
  }, [regionForCustomer]);
  const getAppMetricsByRegions = async () => {
    let regionWise = "";
    if (roleName === "superadmin" && regionForCustomer !== "All") {
      regionWise += `?region=${regionForCustomer}`;
    } else {
      regionWise += `?region=${regionName}`;
    }
    await api
      .listServiceCenterByRegion(regionWise)
      .then((result) => {
        if (result?.data?.rows) {
          setRegionWiseServiceCenterDataForCustomer(result?.data?.rows);
        } else {
          setRegionWiseServiceCenterDataForCustomer([]);
          setServiceCenterNameForCustomerWise("All");
          setCustomerOrderWiseFilter("All");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getCustomers = async () => {
    await api
      .listCustomers()
      .then((result) => {
        setAllCustomers(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getCustomers();
  }, []);
  useEffect(() => {
    setCustomerOrderWiseFilter("All");
  }, [serviceCenterNameForCustomerWise]);
  const getCustomerProductionOrder = async (serviceCenterId) => {
    let queryString = "";
    if (regionForCustomer !== "All") {
      queryString += `?region=${regionForCustomer}&`;
    }
    if (serviceCenterId) {
      queryString += `${
        regionForCustomer === "All"
          ? `?serviceCenterId=${serviceCenterId}&`
          : `serviceCenterId=${serviceCenterId}&`
      }`;
    }

    try {
      const result = await api.customerWiseOrders(queryString);
      if (result?.data?.length > 0) {
        let graphData = [];
        if (
          customerOrderWiseFilter === "All" &&
          serviceCenterNameForCustomerWise
        ) {
          graphData = result.data;
        } else {
          result.data.forEach((customerData) => {
            if (customerData.customerName === customerOrderWiseFilter) {
              graphData.push(customerData);
            }
          });
        }

        if (graphData.length > 0) {
          setCustomerWiseOrders(graphData);
        } else {
          setCustomerWiseOrders([]);
        }
      } else {
        setCustomerWiseOrders([]);
        // toast.error("No Data Found");
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    const serviceCenter = regionWiseServiceCenterDataForCustomer.filter(
      (item) => item.name === serviceCenterNameForCustomerWise
    );
    const serviceDataForAllRegionsByName = serviceCenterListData.filter(
      (item) => item.name === serviceCenterNameForCustomerWise
    );

    if (serviceCenter.length > 0) {
      getCustomerProductionOrder(serviceCenter[0]?.serviceCenterId);
    }

    if (serviceDataForAllRegionsByName.length > 0) {
      getCustomerProductionOrder(
        serviceDataForAllRegionsByName[0]?.serviceCenterId
      );
    }
    if (serviceCenterNameForCustomerWise === "All") {
      getCustomerProductionOrder();
    }
  }, [
    serviceCenterNameForCustomerWise,
    regionForCustomer,
    customerOrderWiseFilter,
  ]);

  const chartDataForAllCustomers = {
    labels: [
      "Total Quantity",
      "Finished Quantity",
      "Balance Quantity",
      "Dispatched Quantity",
    ],
    datasets: [
      {
        label: "Total Quantity ",
        borderColor: "#3480eb",
        backgroundColor: ["#3480eb", "#18BC27", "#FF3131", "#F6BF1D"],
        barThickness: 35,
        spanGaps: true,
        data: [
          customerWiseOrders.reduce((acc, item) => acc + item.totalQuantity, 0),
          customerWiseOrders.reduce(
            (acc, item) => acc + item.finishedQuantity,
            0
          ),
          customerWiseOrders.reduce(
            (acc, item) => acc + item.balanceQuantity,
            0
          ),
          customerWiseOrders.reduce(
            (acc, item) => acc + item.dispatchedQuantity,
            0
          ),
        ].map((value) => parseFloat(value.toFixed(3))),
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
      },
      y: {
        type: "linear",
        position: "left",
        title: {
          display: true,
          text: "Weight (T)",
        },
        stacked: true,
        beginAtZero: true,
      },
    },
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: true,
        align: "top",
        anchor: "end",
        color: "black",
        font: {
          weight: "bold",
        },
        formatter: function (value, context) {
          return value;
        },
      },
      title: {
        display: true,
      },
    },
  };

  const columnDefs = [
    {
      headerName: "SL.No",
      minWidth: 100,
      maxWidth: 100,
      valueGetter: "node.rowIndex + 1",
      cellClass: "sln-cell",
    },
    {
      headerName: "Total Quantity (T)",
      field: "totalQuantity",
      minWidth: 250,
      valueFormatter: (params) => params.value.toFixed(3),
    },
    {
      headerName: "Finished Quantity (T)",
      field: "finishedQuantity",
      minWidth: 250,
      valueFormatter: (params) => params.value.toFixed(3),
    },
    {
      headerName: "Balance Quantity (T)",
      field: "balanceQuantity",
      minWidth: 250,
      valueFormatter: (params) => params.value.toFixed(3),
    },

    {
      headerName: " Dispatched Quantity (T)",
      field: "dispatchedQuantity",
      minWidth: 250,
      valueFormatter: (params) => params.value.toFixed(3),
    },
  ];

  return (
    <div>
      <div className="head">Customer Wise Orders</div>

      <div
        className="filter-card"
        style={{ margin: "20px 10px", padding: "20px 20px" }}
      >
        <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
          {roleName === "superadmin" ? (
            <>
              <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                Regions
              </label>
              <select
                name="grade"
                id="dia-total-gradeFil"
                style={{ width: "fit-content" }}
                onChange={(e) => setRegionForCustomer(e.target.value)}
              >
                <option value="All">All</option>
                {regions.map((region, index) => (
                  <option key={index} value={region}>
                    {region}
                  </option>
                ))}
              </select>
            </>
          ) : (
            roleName !== "admin" &&
            roleName !== "salesManager" && (
              <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                Region:<></>{" "}
                {roleName !== "admin" &&
                  regionName.charAt(0).toUpperCase() + regionName.slice(1)}
              </label>
            )
          )}

          {roleName !== "admin" && (
            <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
              Service Center Name
            </label>
          )}
          {roleName !== "admin" && (
            <select
              value={serviceCenterNameForCustomerWise}
              name="grade"
              id="dia-total-gradeFil"
              style={{ width: "fit-content" }}
              onChange={(e) =>
                setServiceCenterNameForCustomerWise(e.target.value)
              }
            >
              <option value="All">All</option>
              {[
                ...new Set(
                  regionForCustomer === "All"
                    ? serviceCenterListData.map((item) => item.name)
                    : regionWiseServiceCenterDataForCustomer.map(
                        (item) => item.name
                      )
                ),
              ].map((data, index) => (
                <option key={index} value={data}>
                  {data}
                </option>
              ))}
            </select>
          )}

          <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
            Customer Name
          </label>
          <select
            value={customerOrderWiseFilter}
            name="grade"
            id="dia-total-gradeFil"
            style={{ width: "fit-content" }}
            onChange={(e) => setCustomerOrderWiseFilter(e.target.value)}
          >
            <option value="All">All</option>
            {[
              ...new Set(
                serviceCenterNameForCustomerWise === "All"
                  ? allCustomers.map((item) => item.customerName)
                  : customerWiseOrders.map((item) => item.customerName)
              ),
            ].map((data, index) => (
              <option key={index} value={data}>
                {data}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="chart-box">
        <Bar
          data={chartDataForAllCustomers}
          options={options}
          height={400}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        />
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: 490, width: "100%", marginTop: "50px" }}
      >
        <AgGridReact rowData={customerWiseOrders} columnDefs={columnDefs} />
      </div>
    </div>
  );
};

export default CustomerWiseOrder;
