import axios from "axios";

const baseURL = process.env.REACT_APP_BASE_URL;
const api = axios.create({
    baseURL,
});
console.log(baseURL);
const headerGetter = () => {

    const token = sessionStorage.getItem('token');
    const header = { Authorization: `Bearer ${token}` }
    // console.log('T', header);
    return header
}


// const headers = { Authorization: `Bearer ${sessionStorage.getItem('token')}` };


export const login = (newRow) => api.post('/auth/login', newRow);


//CUSTOMERS
export const listCustomers = (params="") => api.get(`/customers/list${params}`, { headers: headerGetter() });
export const addCustomer = (newRow) => api.post('/customers/add', newRow, { headers: headerGetter() });
export const updateCustomer = (id, updatedData) => api.put(`/customers/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteCustomer = (id) => api.delete(`/customers/delete/${id}`, { headers: headerGetter() });


//PROCESS TYPE
export const listProcessType = () => api.get('/equipmentProcessType/list', { headers: headerGetter() });
export const addProcessType = (newRow) => api.post('/equipmentProcesstype/add', newRow, { headers: headerGetter() });
export const updateProcessType = (id, updatedData) => api.put(`/equipmentProcesstype/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteProcessType = (id) => api.delete(`/equipmentProcesstype/delete/${id}`, { headers: headerGetter() });

export const listProcessTypeSF = () => api.get('/smartFabProcessType/list', { headers: headerGetter() });
export const addProcessTypeSF = (newRow) => api.post('/smartFabProcessType/add', newRow, { headers: headerGetter() });
export const updateProcessTypeSF = (id, updatedData) => api.put(`/smartFabProcessType/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteProcessTypeSF = (id) => api.delete(`/smartFabProcessType/delete/${id}`, { headers: headerGetter() });


//EQUIPMENT CATEGORY
export const listEquipmentCategory = () => api.get('/equipmentCategory/list', { headers: headerGetter() });
export const addEquipmentCategory = (newRow) => api.post('/equipmentCategory/add', newRow, { headers: headerGetter() });
export const updateEquipmentCategory = (id, updatedData) => api.put(`/equipmentCategory/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteEquipmentCategory = (id) => api.delete(`/equipmentCategory/delete/${id}`, { headers: headerGetter() });

export const listEquipmentCategorySF = () => api.get('/smartFabEquipmentCategory/list', { headers: headerGetter() });
export const addEquipmentCategorySF = (newRow) => api.post('/smartFabEquipmentCategory/add', newRow, { headers: headerGetter() });
export const updateEquipmentCategorySF = (id, updatedData) => api.put(`/smartFabEquipmentCategory/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteEquipmentCategorySF = (id) => api.delete(`/smartFabEquipmentCategory/delete/${id}`, { headers: headerGetter() });


//ISSUES
export const cubeIssueList = () => api.get('/cubeIssue/list', { headers: headerGetter() });
export const addCubeIssue = (newRow) => api.post('/cubeIssue/add', newRow, { headers: headerGetter() });
export const delCubeIssues = (id) => api.delete(`/cubeIssue/delete/${id}`, { headers: headerGetter() });
export const updateCubeIssue = (id, updatedData) => api.put(`/cubeIssue/update/${id}`, updatedData, { headers: headerGetter() });


//EQUIPMENT MASTER
export const listEquipments = () => api.get('/equipmentMaster/list', { headers: headerGetter() });
export const addEquipments = (newRow) => api.post('/equipmentMaster/add', newRow, { headers: headerGetter() });
export const updateEquipment = (id, updatedData) => api.put(`/equipmentMaster/update/${id}`, updatedData, { headers: headerGetter() });
export const delEquipments = (id) => api.delete(`/equipmentMaster/delete/${id}`, { headers: headerGetter() });


//ORGANIZATION MASTER
export const listOrganizations = () => api.get('/organizationMaster/list', { headers: headerGetter() });
export const addOrganizations = (newRow) => api.post('/organizationMaster/add', newRow, { headers: headerGetter() });
export const updateOrganization = (id, type) => api.put(`/organizationMaster/update/${id}`, type, { headers: headerGetter() });
export const deleteOrganization = (id) => api.delete(`/organizationMaster/delete/${id}`, { headers: headerGetter() });


//PARTNER MASTER
export const listPartners = () => api.get('/partnerMaster/list', { headers: headerGetter() });
export const addPartners = (newRow) => api.post('/partnerMaster/add', newRow, { headers: headerGetter() });
export const updatePartner = (id, type) => api.put(`/partnerMaster/update/${id}`, type, { headers: headerGetter() });
export const deletePartner = (id) => api.delete(`/partnerMaster/delete/${id}`, { headers: headerGetter() });


//PRODUCT MASTER
export const listProducts = () => api.get('/productMaster/list', { headers: headerGetter() });
export const addProducts = (newRow) => api.post('/productMaster/add', newRow, { headers: headerGetter() });
export const updateProduct = (id, updatedData) => api.put(`/productMaster/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteProduct = (id) => api.delete(`/productMaster/delete/${id}`, { headers: headerGetter() });


export const listProductType = () => api.get('/smartFabProductType/list', { headers: headerGetter() });
export const addProductType = (newRow) => api.post('/smartFabProductType/add', newRow, { headers: headerGetter() });
export const updateProductType = (id, updatedData) => api.put(`/smartFabProductType/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteProductType = (id) => api.delete(`/smartFabProductType/delete/${id}`, { headers: headerGetter() });


//GRADE MASTER
export const listGrades = () => api.get(`/gradeMaster/list`, { headers: headerGetter() });
export const addGrades = (newRow) => api.post('/gradeMaster/add', newRow, { headers: headerGetter() });
export const updateGrade = (id, updatedData) => api.put(`/gradeMaster/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteGrade = (id) => api.delete(`/gradeMaster/delete/${id}`, { headers: headerGetter() });

export const listStockType = () => api.get(`/masterStockType/list`, { headers: headerGetter() });

export const listGradesSF = () => api.get(`/smartFabGradeDetails/list`, { headers: headerGetter() });
export const addGradesSF = (newRow) => api.post('/smartFabGradeDetails/add', newRow, { headers: headerGetter() });
export const updateGradeSF = (id, updatedData) => api.put(`/smartFabGradeDetails/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteGradeSF = (id) => api.delete(`/smartFabGradeDetails/delete/${id}`, { headers: headerGetter() });



//FACILITY MASTER
export const listFacilities = () => api.get('/facilityMaster/list', { headers: headerGetter() });
export const addFacilities = (newRow) => api.post('/facilityMaster/add', newRow, { headers: headerGetter() });
export const updateFacility = (id, updatedData) => api.put(`/facilityMaster/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteFacility = (id) => api.delete(`/facilityMaster/delete/${id}`, { headers: headerGetter() });


//SERVICE CENTER
export const listServiceCenter = () => api.get('/serviceCenters/list', { headers: headerGetter() });
export const listServiceCenterByRegion = (params="") => api.get(`/serviceCenters/list${params}`, { headers: headerGetter() });
export const addServiceCenter = (newRow) => api.post('/serviceCenters/add', newRow, { headers: headerGetter() });
export const updateServiceCenter = (id, updatedData) => api.put(`/serviceCenters/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteServiceCenter = (id) => api.delete(`/serviceCenters/delete/${id}`, { headers: headerGetter() });


//DIA WISE ROUTE
export const listDiaWiseRoute = () => api.get('/diawiseroutes/list', { headers: headerGetter() });
export const addDiaWiseRoute = (newRow) => api.post('/diawiseroutes/add', newRow, { headers: headerGetter() });
export const deleteDiaWiseRoute = (id) => api.delete(`/diaWiseRoutes/delete/${id}`, { headers: headerGetter() });


//SERVICE CENTER EQUIPMENTS
export const listServiceCenterEquipments = () => api.get('/serviceCenterEquipment/list', { headers: headerGetter() });
export const addServiceCenterEquipments = (newRow) => api.post('/serviceCenterEquipment/add', newRow, { headers: headerGetter() });
export const deleteServiceCenterEquipment = (id) => api.delete(`/serviceCenterEquipment/delete/${id}`, { headers: headerGetter() });
export const updateServiceCenterEquipment = (id, updatedData) => api.put(`/serviceCenterEquipment/update/${id}`, updatedData, { headers: headerGetter() });

//TMP RATE
export const listTpmRate = () => api.get('/tpmRate/list', { headers: headerGetter() });
export const addTpmRate = (newRow) => api.post('/tpmRate/add', newRow, { headers: headerGetter() });
export const deleteTpmRate = (id) => api.delete(`/tpmRate/delete/${id}`, { headers: headerGetter() });


//RM RECEIPT
export const listRmInventory = () => api.get('/rmreceipt/list', { headers: headerGetter() });
export const addRmInventory = (newRow) => api.post('/rmreceipt/add', newRow, { headers: headerGetter() });
export const updateRmInventory = (id, updatedData) => api.put(`/rmreceipt/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteRmInventory = (id) => api.delete(`/rmreceipt/delete/${id}`, { headers: headerGetter() });
export const convertRmInventory = (newRow) => api.put('rmreceipt/convertMLToCL', newRow, { headers: headerGetter() });
export const addMultipleInventory = (newRow) => api.post('rmreceipt/addMultiple', newRow, { headers: headerGetter() });
export const inventoryUpload = (newRow) => api.post('inventory/add', newRow, { headers: headerGetter() });

export const listRmInventorySF = () => api.get('/smartFabRmReceipt/list', { headers: headerGetter() });
export const addRmInventorySF = (newRow) => api.post('/smartFabRmReceipt/add', newRow, { headers: headerGetter() });
export const updateRmInventorySF = (id, updatedData) => api.put(`/smartFabRmReceipt/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteRmInventorySF = (id) => api.delete(`/smartFabRmReceipt/delete/${id}`, { headers: headerGetter() });
export const addMultipleInventorySF = (newRow) => api.post('smartFabRmReceipt/addMultiple', newRow, { headers: headerGetter() });


//INVENTORY
export const listInventory = () => api.get('/inventory/list', { headers: headerGetter() });
export const putInventory = (id, updatedData) => api.put(`/inventory/update/${id}`, updatedData, { headers: headerGetter() });

export const listInventorySF = () => api.get('/smartFabInventory/list', { headers: headerGetter() });
export const putInventorySF = (id, updatedData) => api.put(`/smartFabInventory/update/${id}`, updatedData, { headers: headerGetter() });
export const inventoryUploadSF = (newRow) => api.post('smartFabInventory/add', newRow, { headers: headerGetter() });


//PROCESS CENTER
export const listProcessCenter = () => api.get('/processCenter/list', { headers: headerGetter() });
export const addProcessCenter = (newRow) => api.post('/processCenter/add', newRow, { headers: headerGetter() });
export const updateProcessCenter = (id, updatedData) => api.put(`/processCenter/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteProcessCenter = (id) => api.delete(`/processCenter/delete/${id}`, { headers: headerGetter() });


//CUSTOMER PARTIES
export const listCustomerParties = () => api.get('/customerParties/list', { headers: headerGetter() });
export const addCustomerParties = (newRow) => api.post('/customerParties/add', newRow, { headers: headerGetter() });
export const updateCustomerParties = (id, updatedData) => api.put(`/customerParties/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteCustomerParties = (id) => api.delete(`/customerParties/delete/${id}`, { headers: headerGetter() });


//SERVICE CENTER ORDER
export const listOrder = (params) => api.get(`/serviceCenterOrder/list${params}`, { headers: headerGetter() });
export const addOrder = (newRow) => api.post('/orders/add', newRow, { headers: headerGetter() });
export const updateOrder = (id, updatedData) => api.put(`/orders/update/${id}`, updatedData, { headers: headerGetter() });

export const listOrderSF = (params) => api.get(`/smartFabOrders/list${params}`, { headers: headerGetter() });
export const addOrderSF = (newRow) => api.post('/smartFabOrders/add', newRow, { headers: headerGetter() });
export const updateOrderSF = (id, updatedData) => api.put(`/smartFabOrders/update/${id}`, updatedData, { headers: headerGetter() });

//ORDER ITEMS
export const listOrderItems = () => api.get('/orderItems/list', { headers: headerGetter() });
export const listOrderItems2 = (id) => api.get(`/orderItems/list?orderId=${id}`, { headers: headerGetter() });
export const addOrderItems = (newRow) => api.post('/orderItems/add', newRow, { headers: headerGetter() });
export const updateOrderItems = (id, updatedData) => api.put(`/orderItems/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteOrderItems = (id) => api.delete(`/orderItems/delete/${id}`, { headers: headerGetter() });
export const copyOrderItems = (items) => api.post('/orderItems/addMultiple', items, { headers: headerGetter() });


//PART ITEMS
export const partItemById = (id) => api.get(`/partItems/listOrderItem?orderItemId=${id}`, { headers: headerGetter() });
export const listPartItems = (params='') => api.get(`/partItems/list${params}`, { headers: headerGetter() });
export const listPartItems2 = (id) => api.get(`/partItems/list?orderItemId=${id}`, { headers: headerGetter() });
export const addPartItems = (newRow) => api.post('/partItems/add', newRow, { headers: headerGetter() });
export const updatePartItems = (id, updatedData) => api.put(`/partItems/update/${id}`, updatedData, { headers: headerGetter() });
export const deletePartItems = (id) => api.delete(`/partItems/delete/${id}`, { headers: headerGetter() });
export const addExcelBbs = ({newRow, cleanTags}) => api.post(`/partItems/upload${cleanTags?'?type=bbsDelete':''}`, newRow, { headers: headerGetter() });

export const updateBmRow = ( updatedData) => api.put(`/partItems/bmRow`, updatedData, { headers: headerGetter() });

//PRODUCTION PARAMETERS
export const listProductionParameters = (params='') => api.get(`/smartFabProductionRecording/list${params}`, { headers: headerGetter() });
export const addProductionParameters = (newRow) => api.post('/smartFabProductionRecording/add', newRow, { headers: headerGetter() });
export const updateProductionParameters = (id, updatedData) => api.put(`/smartFabProductionRecording/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteProductionParameters = (id) => api.delete(`/smartFabProductionRecording/delete/${id}`, { headers: headerGetter() });
export const addSmartFabExcelBbs = ({newRow}) => api.post(`/smartFabProductionRecording/upload`, newRow, { headers: headerGetter() });


export const confirmProductionSF = (updatedData) => api.put(`/smartFabProductionRecording/confirm`, updatedData, { headers: headerGetter() });


//SDBR
export const listSdbr = () => api.get('/sdbr/list', { headers: headerGetter() });
export const runSdbr = (newRow) => api.put(`/sdbr/runSdbr`, newRow, { headers: headerGetter() });
export const confirmPO = (id, _) => api.post(`/sdbr/confirmPO/${id}`, _, { headers: headerGetter() });
export const unConfirmPO = (newRow) => api.post(`/sdbr/unConfirmPO`, newRow, { headers: headerGetter() });


//SHAPES
export const listShape = (params) => api.get(`/shapes/list${params}`, { headers: headerGetter() });
export const addShape = (newShape) => api.post('/shapes/add', newShape, { headers: headerGetter() });
export const updateShapes = (id, updatedData) => api.put(`/shapes/update/${id}`, updatedData, { headers: headerGetter() });
export const deleteShape = (id) => api.delete(`/shapes/delete/${id}`, { headers: headerGetter() });


//FAV SHAPES
export const listFavShape = () => api.get('/favShapes/list', { headers: headerGetter() });
export const addFavShape = (newShape) => api.post('/favShapes/add', newShape, { headers: headerGetter() });
export const deleteFavShape = (id) => api.delete(`/favShapes/delete/${id}`, { headers: headerGetter() });


export const listSegment = () => api.get('/customerSegment/list', { headers: headerGetter() });


export const barMarkWiseQty = () => api.get('/partItems/list', { headers: headerGetter() });
export const orderDateComparison = () => api.get('/partItems/list', { headers: headerGetter() });
export const orderLLRelation = () => api.get('/partItems/list', { headers: headerGetter() });
export const orderStatusHistory = () => api.get('/partItems/list', { headers: headerGetter() });
export const orderWiseProgress = () => api.get('/partItems/list', { headers: headerGetter() });


export const graphlistinventry = () => api.get('/inventory/list', { headers: headerGetter() });


export const listTempPlan = () => api.get('/plan/listPlanOptimization', { headers: headerGetter() });

//LOADING LIST
export const addLoadingList = (newRow) => api.post('/loadinglist/addLL', newRow, { headers: headerGetter() });
export const listLoadingList = () => api.get('/loadinglist/listLL', { headers: headerGetter() });
export const listFgPartItems = (params) => api.get(`/partItems/list?partItemStatus=FG${params}`, { headers: headerGetter() });
export const listBbsBundles = (id = null, key) => api.get(`/loadinglist/listBundle${id === null ? '' : `${id}`}`, { headers: headerGetter() });
export const listBundles = (id) => api.get(`/loadinglist/listBundle?partItemId=${id}&productionStatus=1`, { headers: headerGetter() });
export const updateLoadingList = (id, updatedData) => api.put(`/loadinglist/update/${id}`, updatedData, { headers: headerGetter() });
export const dispatchLoadingList = (id, _) => api.put(`/loadinglist/gatePassPrint/${id}`, _, { headers: headerGetter() });

export const addLoadingListSF = (newRow) => api.post('/smartFabLoadingList/addLL', newRow, { headers: headerGetter() });
export const listLoadingListSF = () => api.get('/smartFabLoadingList/listLL', { headers: headerGetter() });
export const updateLoadingListSF = (id, updatedData) => api.put(`/smartFabLoadingList/update/${id}`, updatedData, { headers: headerGetter() });
export const dispatchLoadingListSF = (id, _) => api.put(`/smartFabLoadingList/gatePassPrint/${id}`, _, { headers: headerGetter() });
//above two lines api might need to be changed

//NEW PLAN
export const addPlan = (newRow) => api.post('/plan/finaloptimization', newRow, { headers: headerGetter() });
export const listPlanReadyOrder = (params='') => api.get(`/plan/newplanlist${params}`, { headers: headerGetter() });
export const listCuttingSeq = (id) => api.get(`/cuttingSequence/list?planId=${id}`, { headers: headerGetter() });
export const listDashBoardApp = (params) => api.get(`/dashboards/appMetrics${params}`, { headers: headerGetter() });
export const listScrapDefinition = () => api.get('/scrapDefination/list', { headers: headerGetter() });
export const updateScrapDefinition = (id, updatedData) => api.put(`scrapDefination/update/${id}`, updatedData, { headers: headerGetter() });

export const listDailyProductionByServiceIdAndRegion= (params="") => api.get(`/dashboards/listDailyProduction${params}`,{ headers: headerGetter() });
export const listDiaWiseQuantityByRegion = (params="") => api.get(`/dashboards/diaWiseQuantity${params}`, { headers: headerGetter() });

//CONFIRM PRODUCTION
// export const confirmProduction = (newRow) => api.put('/masterPlan/updatePartItem', newRow, { headers: headerGetter() });
export const confirmProduction = (newRow) => api.put('/masterPlan/updatefg', newRow, { headers: headerGetter() });


export const listTempRecord = (params = '') => api.get(`/plan/listPlanOPtimization?planStatus=1&${params}`, { headers: headerGetter() });




export const listProdPartItems = () => api.get('/partItems/list?partitemStatus=Processing', { headers: headerGetter() });


//PRESALES
export const runPresales = (newRow) => api.post('/plan/presalespricing', newRow, { headers: headerGetter() });
export const listPresales = () => api.get('/plan/presalesdetails', { headers: headerGetter() });
export const listPricingMaster = () => api.get('/preSales/serviceCenter/list?serviceCenterId=1', { headers: headerGetter() });
export const listOrgMasterPrice = () => api.get('/preSales/tataSteel/list', { headers: headerGetter() });
export const updatePresalesMaster = (id, updatedData) => api.put(`/preSales/serviceCenter/update/${id}`, updatedData, { headers: headerGetter() });
export const updateOrgPresalesMaster = (id, updatedData) => api.put(`/preSales/tataSteel/update/1`, updatedData, { headers: headerGetter() });


//DASHBOARDS
export const listProdHistDiaWise = () => api.get('/dashboards/productionHistory', { headers: headerGetter() });
export const listProdHistDiaWiseByServiceId = (id) => api.get(`/dashboards/productionHistory?serviceCenterId=${id}`, { headers: headerGetter() });
export const listProdRecordOrderWise = (params="") => api.get(`/dashboards/listOrdersProduction${params}`, { headers: headerGetter() });
export const listOrderProgress = () => api.get('/dashboards/orderProgress', { headers: headerGetter() });
export const listOrderDateComparision = () => api.get('/serviceCenterOrder/list', { headers: headerGetter() });
export const listDailyProduction = () => api.get('/dashboards/listDailyProduction', { headers: headerGetter() });
export const listLengthWiseQty = () => api.get('/dashboards/lengthWiseQuantity', { headers: headerGetter() });
export const listDiaWiseOrderQty = () => api.get('/dashboards/diaWiseQuantity', { headers: headerGetter() });
export const listOrderloadinglist = () => api.get('/dashboards/orderToLL', { headers: headerGetter() });
export const customerWiseOrders = (params="") => api.get(`/dashboards/customerWiseOrders${params}`, { headers: headerGetter() });
export const customerWiseOrdersByServiceCenterId = (id) => api.get(`/dashboards/customerWiseOrders?serviceCenterId=${id}`, { headers: headerGetter() });
export const listDashboardInventary = (params="") => api.get(`/dashboards/listInventory${params}`, { headers: headerGetter() });

//OPEN PLAN
export const uFreezePlan = (id, _) => api.put(`/masterPlan/update/${id}`, _, { headers: headerGetter() })
export const listOpenPlan = () => api.get('/plan/listPlanOPtimization', { headers: headerGetter() });

//PERMISSIONS
export const listPagePermissions = () => api.get('/permissions/managePermissions', { headers: headerGetter() });
export const listRoleWisePagePermissions = () => api.get('/permissions/getPermissions', { headers: headerGetter() });
export const updatePermissions = (data) => api.put('/permissions/update', data, { headers: headerGetter() });


//USERS
export const listUsers = () => api.get('/users/list', { headers: headerGetter() });
export const addUser = (newRow) => api.post('/users/add', newRow, { headers: headerGetter() });
export const updateUser = (id, data) => api.put(`/users/update/${id}`, data, { headers: headerGetter() });


//PASSWORD
export const putPassword = (updatedData) => api.put(`/users/updatePassword`, updatedData, { headers: headerGetter() });

//MIGRATE SHAPES
export const exportShapes = (newRow) => api.post('/shapes/upload', newRow, { headers: headerGetter() });

//CLUSTER INVENTORY
export const listClusterInventory = (params = '?type=region') => api.get(`/inventory/list${params}`, { headers: headerGetter() });
export const addClusterInventory = (id, data) => api.put(`/inventory/updateFreeInventory/${id}`, data, { headers: headerGetter() });
export const lockTheClusterInventory = (data) => api.put(`/inventory/lock`, data, { headers: headerGetter() });
export const releaseClusterInventory = (id, data) => api.put(`/inventory/releaseLockedInventory/${id}`, data, { headers: headerGetter() });


// UPLOAD AND DOWNLOAD PDFS

export const getBbsPdf = (params = '') => api.get(`/files/list${params}`, { headers: headerGetter() });
export const postGenPdf = (data) => api.post(`/files/add`, data, { headers: headerGetter() });


//End of File
