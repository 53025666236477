// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.charts1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    /* border: solid 3px rgba(54, 162, 235, 1); */
    border-radius: 4px;
    margin-top: 60px;
    height: 400px;
  }
  
  .chart-box1 {
    border: 1px solid #ddd;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/reports/RouteLoad.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;IAC9B,SAAS;IACT,6CAA6C;IAC7C,kBAAkB;IAClB,gBAAgB;IAChB,aAAa;EACf;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,uCAAuC;EACzC","sourcesContent":[".charts1 {\r\n    display: grid;\r\n    grid-template-columns: 1fr 1fr;\r\n    gap: 20px;\r\n    /* border: solid 3px rgba(54, 162, 235, 1); */\r\n    border-radius: 4px;\r\n    margin-top: 60px;\r\n    height: 400px;\r\n  }\r\n  \r\n  .chart-box1 {\r\n    border: 1px solid #ddd;\r\n    padding: 15px;\r\n    margin-bottom: 20px;\r\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\r\n  }\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
