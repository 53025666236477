import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchDataFromApi } from "../../redux-tookit/slices/sdbrSlice";
import GenericTable from "../../components/GenericTable";
import "../../assets/styles/Summary.css";
import { fetchOrderProgress } from "../../redux-tookit/slices/dashboardSlice";
import { Line, Bar } from "react-chartjs-2";

const OrderProgress = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.OrderProgress.columns);
  const originalData = useSelector((state) => state.sdbr.OrderProgress.data);
  const [data, setData] = useState(originalData);

  const graphdata = useSelector((state) => state.dashboard.labeledData);

  // State variables for filters
  const [customernameFilter, setcustomernameFilter] = useState("");
  const [fromDateFilter, setFromDateFilter] = useState("");
  const [toDateFilter, setToDateFilter] = useState("");
  const [xaxisOrderId, setxaxisOrderId] = useState([]);
  const [yaxisDispatchedGoods, setyaxisDispatchedGoods] = useState([]);
  const [yaxisFinishedGoods, setyaxisFinishedGoods] = useState([]);
  const [yaxisProcessingGoods, setyaxisProcessingGoods] = useState([]);

  // Extract unique values for each filter
  const customernameValues = [
    ...new Set(originalData.map((item) => item.customername)),
  ];

  const applyFilters = () => {
    // Apply filters to the data
    let filteredData = originalData;
    // Date range filtering logic
    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.orderDate);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }
    if (customernameFilter) {
      filteredData = filteredData.filter((item) =>
        item.customername.includes(customernameFilter)
      );
    }

    setData(filteredData);
  };

  useEffect(() => {
    // Fetch data when the component mounts
    dispatch(fetchDataFromApi("OrderProgress")); // Specify the page name
    dispatch(fetchOrderProgress()); // Specify the page name
    setData(originalData);
  }, [dispatch, originalData]);

  useEffect(() => {
    console.log(graphdata);
    const orderId = graphdata.map((item) => item.orderId);
    const dispatchedGoods = graphdata.map((item) => item.dispatchedGoods);
    const finishedGoods = graphdata.map((item) => item.finishedGoods);
    const processingGoods = graphdata.map((item) => item.processingGoods);

    setxaxisOrderId(orderId);
    setyaxisDispatchedGoods(dispatchedGoods);
    setyaxisFinishedGoods(finishedGoods);
    setyaxisProcessingGoods(processingGoods);
  }, [graphdata]);

  const dataset = {
    labels: xaxisOrderId,
    datasets: [
      {
        label: "DispatchedGoods",
        data: yaxisDispatchedGoods,
        borderColor: "yellow",
        backgroundColor: "yellow",
        pointBorderColor: "yellow",
        borderWidth: 2,
        pointBackgroundColor: "yellow",
        barThickness: 5,
      },
      {
        label: "FinishedGoods",
        data: yaxisFinishedGoods,
        borderColor: "green",
        backgroundColor: "green",
        pointBorderColor: "green",
        borderWidth: 2,
        pointBackgroundColor: "green",
        barThickness: 5,
      },
      {
        label: "ProcessingGoods",
        data: yaxisProcessingGoods,
        borderColor: "orange",
        backgroundColor: "orange",
        pointBorderColor: "orange",
        borderWidth: 2,
        pointBackgroundColor: "orange",
        barThickness: 5,
      },
    ],
  };

  const optionsForcoil = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        fontSize: 50,
        display: false,
        font: { size: 20 },
      },
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 1,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "OrderID",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "Total Goods Count",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
      },
    },
  };

  return (
    <div className="sdbr">
      {/* <div className="filter-card">
        <div className="filterPH">
          <div className="filter">
            <label htmlFor="customernameFilter">customer name</label>
            <select
              id="customernameFilter"
              value={customernameFilter}
              onChange={(e) => setcustomernameFilter(e.target.value)}
            >
              <option value="">All</option>
              {customernameValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>

          <div className="DateCont">
            <label className="DateHead">Production Plan Month</label>
            <div className="DateFilt">
              <div className="filterD">
                <label htmlFor="fromDateFilter">From </label>
                <input
                  id="fromDateFilter"
                  type="date"
                  value={fromDateFilter}
                  onChange={(e) => setFromDateFilter(e.target.value)}
                />
              </div>
              <div className="filterD">
                <label htmlFor="toDateFilter">To </label>
                <input
                  id="toDateFilter"
                  type="date"
                  value={toDateFilter}
                  onChange={(e) => setToDateFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="head">Order Progress</div>
      <div className="charts">
        <Bar
          // className={hmiCss.formGroup2}
          data={dataset}
          options={optionsForcoil}
          height={400}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        />
      </div>
      <br />
      <br />
      {/* <div>
        <GenericTable columns={columns} data={data} />
      </div> */}
    </div>
  );
};

export default OrderProgress;
