import React, {
    useCallback,
    useMemo,
    useRef,
    useState,
    useEffect,
    useLayoutEffect,
} from "react";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional theme CSS
import "../../assets/styles/AGTable.css";

const BBSAGTable = (props) => {
    const {
        columns = {},
        data = [],
        rowSelection = "single",
        onSelectRow,
        bbs = false,
        deselector,
        height = 270,
        checkbox = true,
    } = props;
    let rowHeight = 25;
    {
        bbs === true ? (rowHeight = 100) : (rowHeight = 40);
    }
    const gridRef = useRef();
    const gridApiRef = useRef(null);
    const [columnDef, setcolumnDef] = useState(null);
    // const [rowData, setRowData] = useState(data);
    const [gridApi, setGridApi] = useState(null);
    // const [gridColumnApi, setGridColumnApi] = useState(null);
    const columnKeys = Object.keys(columns);
    const [chData, uChData] = useState([]);

    const [autoSizing, setAutoSizing] = useState(false);

    const onGridReady = (params) => {
        gridApiRef.current = params.api;

        setGridApi(params.api);
        // setGridColumnApi(params.columnApi);
    };

    const deSelectAll = () => {
        try {
            const selectedNodes = gridApiRef.current.getSelectedNodes();
            selectedNodes.forEach((node) => node.setSelected(false));
        } catch (error) {}
    };

    useLayoutEffect(() => {
        if (deselector > 0) {
            deSelectAll();
        }
    }, [deselector]);

    const defaultColDef = useMemo(() => {
        return {
            initialWidth: 80,
            sortable: true,
            // enableCellChangeFlash: true,
            //   cellDataType: false,
            //   filter: true,
            wrapText: true,
            wrapHeaderText: true,
            // autoHeight: true,
            //   autoHeaderHeight: true,
            // flex: 1,
            //   maxWidth: 350,
            //   resizable: true,
        };
    }, []);

    const dateTimeConverter = (dateTime) => {
        try {
            const [date, time] = dateTime.split("T");
            const [year, month, day] = date.split("-");
            const [nonTime, amPm, noData] = time.split(" ");
            const [hour, min, sec] = nonTime.split(":");

            const output =
                day +
                "-" +
                month +
                "-" +
                year +
                " " +
                hour +
                ":" +
                min +
                " " +
                amPm;
            // console.log(output)
            return output;
        } catch (error) {
            console.info(error);
        }
    };

    const bufferToBase64 = (buffer) => {
        try {
            // console.log(buffer);

            const byteArray = new Uint8Array(buffer);
            const decoder = new TextDecoder("utf-8");

            let result = "";
            for (let i = 0; i < byteArray.length; i += 1024) {
                const chunk = byteArray.subarray(i, i + 1024);
                result += decoder.decode(chunk);
            }
            console.log("this ran");
            // console.log(result);
            return result;
        } catch (error) {
            console.log(error);
        }
    };

    const changeValues = () => {
        let newColumns = [
            {
                colId: "0",
                field: "slno",
                headerName: "Sl.No",
                headerCheckboxSelection:
                    rowSelection === "multiple" ? true : false,
                checkboxSelection: checkbox,
                resizable: false,
                filter: false,
                lockPosition: "left",
                cellClass: "locked-col",
            },
        ];
        let newData = [];
        columnKeys.map((data, index) => {
            // The column will get hidden if there is "id" in any CASE in the column key.
            // ex: "width" will be hidden because it has "id" in it unless it is explicitly defined to ignore that column key.

            if (
                (!data.toLowerCase().includes("id") ||
                    data.toLowerCase().includes("width") ||
                    data.toLowerCase().includes("shapeid") ||
                    data.toLowerCase().includes("barmarkid") ||
                    data.toLowerCase().includes("combid")) &&
                !data.toLowerCase().includes("customertype")
            ) {
                let obj = {
                    colId: "",
                    field: "",
                    headerName: "",
                    checkboxSelection: false,
                    wrapText: true,
                    // autoHeight: true,
                };
                if (data === "image") {
                    obj = {
                        ...obj,
                        width: 170,
                        initialWidth: 170,
                        // fixedWidth: 170,
                        autoSize: false,
                        cellRenderer: (params) => {
                            // console.log(params);
                            return (
                                <img
                                    src={
                                        String.fromCharCode.apply(
                                            null,
                                            new Uint8Array(
                                                params?.data?.image?.data
                                            )
                                        ) || ""
                                    }
                                    style={{ height: "100px" }}
                                    alt="No Logo"
                                />
                            );
                        },
                    };
                } else if (data === "imageUrl") {
                    obj = {
                        ...obj,
                        cellRenderer: (params) => {
                            // console.log(params);
                            return (
                                <img
                                    src={
                                        bufferToBase64(
                                            params?.data?.imageUrl?.data
                                        ) || ""
                                    }
                                    style={{
                                        height: "110px",
                                        border: "1px solid black",
                                    }}
                                    alt="No Logo"
                                />
                            );
                        },
                    };
                } else if (data === "grossWeightTs") {
                    obj = {
                        ...obj,
                        cellRenderer: (params) => {
                            // console.log(params);

                            return dateTimeConverter(params.data.grossWeightTs);
                        },
                    };
                } else if (data === "unloadedTs") {
                    obj = {
                        ...obj,
                        cellRenderer: (params) => {
                            // console.log(params);
                            if (params.data.unloadedTs)
                                return dateTimeConverter(
                                    params.data.unloadedTs
                                );
                            else return params.data.unloadedTs;
                        },
                    };
                } else if (
                    data === "dateOfEntry" ||
                    data === "orderDate" ||
                    data === "planDate" ||
                    data === "gatePassPrintDate" ||
                    data === "customerExpectedDeliveryDate" ||
                    data === "deliveryDate" ||
                    data === "poDate"
                ) {
                    obj = {
                        ...obj,
                        cellRenderer: (params) => {
                            // console.log(params);
                            if (params.data[data]) {
                                try {
                                    let [dat] =
                                        params.data[data].split("T") ||
                                        params.data[data];
                                    let [year, month, day] = dat.split("-");

                                    return `${day}-${month}-${year}`;
                                } catch (error) {
                                    console.info(error);
                                }
                            } else return params.data[data];
                        },
                    };
                } else obj.colId = index;

                obj.field = data;
                obj.headerName = columns[data].label;

                newColumns.push(obj);
            }
        });

        data.map((data, index) => {
            let obj = { slno: index + 1 };

            newData[index] = { ...obj, ...data };
        });
        uChData(newData);
        setcolumnDef(newColumns);
        console.log(data);
    };

    const autoSizeAll = useCallback(() => {
        try {
            if (gridRef.current.columnApi) {
                const allColumnIds = [];
                gridRef.current.api.getColumns().forEach((column) => {
                    allColumnIds.push(column.getId());
                });
                gridRef.current.api.autoSizeColumns(allColumnIds, false);
            }
        } catch (error) {}
        setAutoSizing(false);
    }, []);

    const autoSizeStrategy = useMemo(() => {
        return {
            type: "fitGridWidth",
            // defaultMinWidth: 100
            // columnLimits: [
            //     {
            //         colId: 'country',
            //         minWidth: 900
            //     }
            // ]
        };
    }, []);

    // useEffect(() => {
    //     if (chData) {
    //         setAutoSizing(true);
    //         const resizeTimeout = setTimeout(() => {
    //             autoSizeAll();
    //         }, 100);

    //         return () => clearTimeout(resizeTimeout);
    //         // setAutoSizing(false)
    //     }
    // }, [columnDef, chData]);

    const onSelectionChanged = () => {
        const selectedNodes = gridApi.getSelectedNodes();
        const selectedRowIndexes = selectedNodes.map(
            (node) => node.data.slno - 1
        ); //FOR MULTIPLE ROW ALSO
        if (rowSelection === "single") {
            if (selectedRowIndexes[0] === undefined) {
                onSelectRow(null);
            } else onSelectRow(selectedRowIndexes[0]);
        } else onSelectRow(selectedRowIndexes);
    };

    const rowStyle = { background: "white" };

    const getRowStyle = (params) => {
        //console.log("@@@", params.node);
        if (
            params.node.data.tierWeight === null ||
            (params.node.data.tierWeight === 0 &&
                params.node.data.grossWeight !== 0)
        ) {
            return { color: "red" };
        }
    };

    useEffect(() => {
        // uChData(data);
        // setcolumnDef(columns);

        changeValues();
    }, [
        // columns,
        data,
        rowSelection,
    ]);

    return (
        <div
            className="table-container"
            style={{
                marginBottom: "0",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
            }}
        >
            {autoSizing && (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "absolute",
                        backgroundColor: "#fff",
                        zIndex: "100",
                        height: "100%",
                        width: "100%",
                    }}
                >
                    <div
                        className="circle-loader2"
                        style={{ position: "relative" }}
                    ></div>
                </div>
            )}
            <div
                className="ag-theme-alpine"
                style={{ height: height, width: "100%" }}
            >
                <AgGridReact
                    ref={gridRef}
                    rowStyle={rowStyle}
                    getRowStyle={getRowStyle}
                    onGridReady={onGridReady}
                    rowMultiSelectWithClick={true}
                    rowData={chData}
                    columnDefs={columnDef}
                    animateRows={true}
                    rowSelection={rowSelection}
                    defaultColDef={defaultColDef}
                    rowHeight={rowHeight}
                    onSelectionChanged={onSelectionChanged}
                    autoSizeStrategy={autoSizeStrategy}
                    enableCellTextSelection={true}
                    ensureDomOrder={true}
                    headerHeight={70}
                    // debug
                />
            </div>
        </div>
    );
};

export default BBSAGTable;
