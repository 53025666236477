import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../../../assets/styles/Production.css';
import Button from '../../../utils/Button'
import { dispatchLoadingList, fetchLoadingList } from '../../../redux-tookit/slices/loadingListSlice';
import AGTable from '../../../components/AGTable';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import refresh from '../../../assets/images/button-icons/Refresh.svg';
import { fetchUserAccess } from '../../../redux-tookit/slices/userAccessSlice';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
import FilterComponent from '../../../components/FilterComponent';


const LoadingDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const columns = useSelector((state) => state.loadingList.columns);
  const originalData = useSelector((state) => state.loadingList.labeledData);
  const [data, setData] = useState(originalData);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null)

  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  const [dispatchStatusFilter, setDispatchStatusFilter] = useState('Not dispatched')
  const dispatchStatusValues = [...new Set(originalData.map(item => item.gatePassPrintStatus))]

  const [permission, setPermission] = useState({});
  useEffect(() => {
    dispatch(fetchUserAccess())
      .then(res => {
        if (res.payload) {
          if (res.payload.navbarNames.Transaction["Loading Details"][0]) {
            setPermission(res.payload.navbarNames.Transaction["Loading Details"][0])
          }
        }
      })
  }, [])

  const applyFilters = () => {
    let filteredData = originalData;
    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.gatePassPrintDate);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }

    if(dispatchStatusFilter){
      filteredData = filteredData.filter((item) => 
        item.gatePassPrintStatus === dispatchStatusFilter
      )
    }
    


    setData(filteredData);
  }


  const handleRowSelect = (rowIndex) => {
    if (rowIndex !== undefined) {
      setSelectedRowIndex(rowIndex);

    } else setSelectedRowIndex(null)
  };

  const handleEdit = () => {
    if (selectedRowIndex !== null) {
      console.log(data[selectedRowIndex]);
      if(data[selectedRowIndex].gatePassPrintStatus !== 'dispatched'){
        console.log(data[selectedRowIndex].gatePassPrintStatus);
        const loadingListId = data[selectedRowIndex].loadingListId
      navigate('/update loading details', { state: { loadingListId: loadingListId, updateData: data[selectedRowIndex], orderIds: data[selectedRowIndex].orderId } })
      } else {
        toast.error('Dispatched loading list cannot be edited')
      }
      
    } else toast.error('No rows selected. Please select a row and try again')
  }

  const handleGatePassPrint = async () => {

    const status = data[selectedRowIndex].gatePassPrintStatus;
    if (status !== 'dispatched') {
      const result = window.confirm('Loading List will be marked as Dispatched. Do you want to Continue?');
      if (result) {
        const id = data[selectedRowIndex].loadingListId; // PUT ID HERE
        console.log(data[selectedRowIndex]);
        dispatch(dispatchLoadingList(id))
        navigate('/gate pass print', { state: { data: data[selectedRowIndex] } })
      }
    } else {
      const id = data[selectedRowIndex].loadingListId; // PUT ID HERE
      console.log(data[selectedRowIndex]);
      dispatch(dispatchLoadingList(id))
      navigate('/gate pass print', { state: { data: data[selectedRowIndex] } })
    }
  }

  const handlePrintLoadingList = () => {
    navigate('/print loading list', { state: { data: data[selectedRowIndex] } })
  }

  const handleRefresh = () => {
    setFromDateFilter('');
    setToDateFilter('');
  }

  useEffect(() => {

    dispatch(fetchLoadingList());

  }, [dispatch]);

  useEffect(() => {
    applyFilters();
    handleRefresh();
  }, [originalData])

  useEffect(() => {
    applyFilters();
  }, [fromDateFilter, toDateFilter, dispatchStatusFilter]);

  return (
    <div className='sdbr'>
      <div className='filter-card'>
        <div className="filter" style={{ flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
          <label htmlFor="planStatus">Dispatch Status</label>
          <select name="planStatus" id="planStatus" value={dispatchStatusFilter} style={{ width: 'fit-content' }} onChange={(e) => setDispatchStatusFilter(e.target.value)}>
            <option value="">All</option>
            {/* {dispatchStatusValues.map(data => (

              <option value={data}>{data}</option>

            ))} */}
            <option value={'Not dispatched'}>Not dispatched</option>
            <option value={'dispatched'}>dispatched</option>
          </select>
        </div>
        <div className="filter-bottom" style={{ justifyContent: 'space-between' }}>


          <div className="DateCont">
            <label className='DateHead'>Gate pass print date</label>

            <div className="DateFilt">

              <div className="filterD">
                <label htmlFor="fromDateFilter">From </label>
                <input
                  id="fromDateFilter"
                  type="date"
                  value={fromDateFilter}
                  onChange={(e) => setFromDateFilter(e.target.value)}
                />
              </div>
              <div className="filterD">
                <label htmlFor="toDateFilter">To</label>
                <input
                  id="toDateFilter"
                  type="date"
                  value={toDateFilter}
                  onChange={(e) => setToDateFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
            <img src={refresh} alt="refresh" onClick={handleRefresh} title='Refresh filters' />
          </div>
        </div>

      </div>

      <div className="head">Loading Details
        <div className="top-button-container">

          <TopButtonContainer
            add={() => {
              navigate(`/create loading details`)
            }}
            edit={() => {
              handleEdit()
            }}
            access={permission} accessOverride={[1, 1, 0]} />
        </div>
      </div>

      <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />

      <div className="bottom-button-container">
        {/* <Button label="Create" className="blue" link='create loading details' /> */}
        {/* <Button label="Edit" className="blue" onClick={handleEdit} disabled={selectedRowIndex}/> */}
        {/* <Button label="Show" className="blue" /> */}
        <Button label="Gate Pass Print" className="blue" onClick={handleGatePassPrint} disabled={selectedRowIndex} />
        {/* <Button label="Summary" className="blue" /> */}
        <Button label="Print Loading List" className="blue" onClick={handlePrintLoadingList} disabled={selectedRowIndex} />

      </div>

    </div>
  )
}

export default LoadingDetails
//End of File