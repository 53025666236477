import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import TableComponent from '../../components/TableComponent';
import { useNavigate, useParams } from 'react-router-dom';
import FilterComponent from '../../components/FilterComponent';
import excel from '../../assets/images/button-icons/excel.svg';
import Button from '../../utils/Button';
import '../../assets/styles/OrderSummary.css';

const OrderSummary = () => {
    const dispatch=useDispatch();
    const{orderId}=useParams();
    const navigate=useNavigate();
    const originalData=useSelector((state)=>state.sdbr.orderSummary.data);
    const columns=useSelector((state)=>state.sdbr.orderSummary.columns);
    const data=originalData.filter((item) => item.orderId === orderId);
    const filteredColumns = columns.filter((column) => column.key !== 'orderId'&&column.key!=='element');
    const [filteredData,setFilteredData]=useState(data);

    const[elementFilter,setElementFilter]=useState('');

    const elementValues = [...new Set(data.map(item => item.element))];

    const applyFilter = () =>{
        let filteredData1 = data;

        if (elementFilter) {
            filteredData1 = filteredData1.filter((item) =>
                item.element.includes(elementFilter)
            );
        }
        setFilteredData(filteredData1);
    }

    useEffect(()=>{
        dispatch(fetchDataFromApi("orderSummary"));
    },[dispatch]);
    useEffect(()=>{
        applyFilter();
    },[elementFilter,data]);
    console.log(filteredData);
  return (
    <div className="sdbr">
        <div className="head">Order Summary</div>
        <div className='ordIdFilter'>
            <div>
                Order Id
                <input type="text" readOnly value={orderId} />
            </div>
            <div>
                <FilterComponent
                    label="Element Name"
                    filterValue={elementFilter}
                    options={elementValues}
                    onFilterChange={setElementFilter}
                />
            </div>
            
        </div>
        <div className='excel'>
            <img src={excel} alt='' width={17} height={18}/>
        </div>
        <TableComponent  columns={filteredColumns} data={filteredData}/>
        <div className="buttons">
        <Button label="Print" className="blue" />
        <Button label="Exit" className="blue" onClick={()=>navigate('/order details')}/>
        </div>
    </div>
    
  )
}

export default OrderSummary;
//End of File
