import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchRoleWiseAccess, fetchUserAccess, updateUserAccess } from '../../../redux-tookit/slices/userAccessSlice';
import './Access.css'
import AccessTable from './AccessTable';
import Button from '../../../utils/Button'
import {  toast } from 'react-toastify';
import { fetchServiceCenter } from '../../../redux-tookit/slices/serviceCenterSlice';

const AccessSettings = () => {
    const dispatch = useDispatch();
    const data = useSelector(state => state.userAccess.rawJson)
    const serviceCenterData = useSelector(state => state.serviceCenter.labeledData)
    const [accessData, setAccessData] = useState([]);
    const [editData, setEditData] = useState([])
    const [roleValue, setRoleValue] = useState('');
    const [scId, setScId] = useState('');



    useEffect(() => {
        dispatch(fetchRoleWiseAccess())
            .then(res => console.log(res)) //Change the variable to be passed to get all the rolewise access. Also change the Slice
        dispatch(fetchServiceCenter())
    }, [])



    console.log(accessData);

    const handleRoleChange = ( value, scId ) => {

        data.forEach(element => {
            if (element.roleName === value) {

                if(scId!=='') {
                  setAccessData(element.Modules.filter(item => item.serviceCenterId == scId))  
                } else {
                    setAccessData([])
                    setScId('')
                    setRoleValue('')
                }
                
            }
        });

        setRoleValue(value);
        setScId(scId)
        if (value === '') {
            setAccessData([])
        }

    }

    

    const handleSave = () => {
        dispatch(updateUserAccess(editData))
            .then((res) => {

                if (res.payload) {
                    toast.success('User access updated successfully')
                    dispatch(fetchRoleWiseAccess())
                    dispatch(fetchUserAccess())
                }
            })
        handleRoleChange('','')
    }



    return (
        <div className='sdbr'>
            <div className="head">Access Settings</div>
            <div className="filter-card" style={{ flexDirection: 'row', justifyContent: 'space-between', width: '100%', boxSizing: 'border-box', gap:'50px' }}>
                <div className="filter" style={{ alignItems: 'center', gap: '2vh', width: 'fit-content' }}>
                    <label htmlFor="Role">Role</label>
                    <select name="Role" id="Role" style={{width:'fit-content'}} value={roleValue} onChange={(e) => handleRoleChange(e.target.value, '')}>

                        <option value="">-Select-</option>
                        {data.map(data => (
                            data.roleName !== 'superadmin'?
                            <option value={data.roleName}>{data.roleName}</option>:''
                        ))}

                    </select>
                </div>
                <div className="filter" style={{ alignItems: 'center', gap: '2vh', width: 'fit-content' }}>
                    <label htmlFor="scId">Service Center</label>
                    <select name="scId" id="scId" style={{width:'fit-content'}} value={scId} onChange={(e) => {handleRoleChange(roleValue, e.target.value);setScId(e.target.value)}}>

                        <option value="">-Select-</option>
                        {serviceCenterData.map(data => (
                            <option value={data.serviceCenterId}>{data.name}</option>
                        ))}

                    </select>
                </div>

                <div className="filter" style={{ justifyContent: 'flex-end' }}>
                    <Button className='blue' onClick={() => handleSave()} label='Save' disabled={editData} message='No changes made'/>
                </div>

            </div>
            <AccessTable accessData={accessData} editData={editData} setEditData={setEditData} />
        </div>
    )
}

export default AccessSettings
