import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/API";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  columns: {},
  labeledData: [],
  column2: {},
  labeledData2: [],
  loading: "idle",
  error: null,
};

export const fetchDiaWiseOrderQty = createAsyncThunk(
    "data/fetchDiaWiseOrderQty",
    async () => {
      try {
        let response = {};
        response = await api.listDiaWiseOrderQty();
  
        const json = response.data;
        const columnLabels = {
          productionWeight: { label: "Production Weight" },
          productionLength: { label: "Production Length" },
          gradeName: { label: "Grade Name" },
          diameter: { label: "Diameter" },
        };
  
        const selectedColumns = [
          "diameter",
          "productionWeight",
          "productionLength",
          "gradeName",
        ];
  
        const columns = {};
        
        selectedColumns.forEach((columnName) => {
          columns[columnName] = columnLabels[columnName];
        });
  
        const labeledData = json.rows;
  
        return { columns, labeledData };
      } catch (error) {
        throw error;
      }
    }
  );

const diaWiseQualitySlice = createSlice({
  name: "diaWiseQualitySlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchDiaWiseOrderQty.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        // state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchDiaWiseOrderQty.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      });
  },
});

export default diaWiseQualitySlice.reducer;
//End of File
