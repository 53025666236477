import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchProductionParameters } from '../../../../redux-tookit/slices/smartFab/productionParameterSlice';
import { fetchOrderSF } from '../../../../redux-tookit/slices/orderSlice';
import ParamsPdfChooser from './ParamsPdfChooser';
import { ToastContainer } from 'react-toastify';
import { fetchServiceCenter } from '../../../../redux-tookit/slices/serviceCenterSlice';
import { Document, PDFViewer } from '@react-pdf/renderer';
import Button from '../../../../utils/Button';
import Loader from '../../../../components/Loader/Loader';
import { fetchCustomerParties } from '../../../../redux-tookit/slices/customerPartiesSlice';


const ParamsPdfViewer = () => {

  const { state } = useLocation();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const loading = useSelector(state => state.productionParameter.loading)

  const orderInfo = useSelector(state => state.order.labeledData)[0]

  const parties = useSelector(state => state.customerParties.labeledData).filter(item => item.customerPartiesId * 1 === state.partiesId * 1)[0]


  const [parameters, setParameters] = useState([])

  const [loading1, uLoading1] = useState(true);


  // const diaValues = [...new Set(partItem.map(item => item.diameter))].sort((a, b) => a - b)


  const serviceCenter1 = useSelector(state => state.serviceCenter.labeledData).filter((sc) => sc.imageUrl)[0] || ''


  useEffect(() => {

    let params = `?orderId=${state.orderId}`

    dispatch(fetchProductionParameters(params))
      .then(res => {
        console.log(res.payload.labeledData);
        setParameters(res.payload.labeledData)
      })
    dispatch(fetchOrderSF(`?orderId=${state.orderId}`)).then(res => { console.log(res.payload.labeledData); })

    dispatch(fetchCustomerParties())

    dispatch(fetchServiceCenter())
  }, [])

  const bufferToBase64 = (buffer) => {
    try {
      // console.log(buffer);

      const byteArray = new Uint8Array(buffer);
      const decoder = new TextDecoder('utf-8');

      let result = '';
      for (let i = 0; i < byteArray.length; i += 1024) {
        const chunk = byteArray.subarray(i, i + 1024);
        result += decoder.decode(chunk);
      }
      console.log('this ran');
      // console.log(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  const handleBackButtonClick = () => {
    navigate(`/production parameters/${orderInfo.orderId}`, { state: { combId: orderInfo.combId, orderId: orderInfo.orderId } })  
  }

  const serviceCenterLogo = bufferToBase64(serviceCenter1?.imageUrl?.data) || null;

  return (
    <div style={{ height: '100%' }}>
      <ToastContainer />
      {loading !== 'fulfilled' && loading1 ? <Loader /> :
        <div style={{ border: '2px solid black', height: '90%' }}>
          {parameters.length !== 0 && orderInfo !== undefined && parties !== undefined ?
            <PDFViewer style={{ height: '100%', width: '100%' }}>
              {<Document onRender={() => { uLoading1(false) }} >
                <ParamsPdfChooser parameters={parameters} orderInfo={orderInfo} type={state.type} serviceCenterLogo={serviceCenterLogo} parties={parties} />
              </Document>
              }
            </PDFViewer>
            : ""
          }
        </div>
      }
      <Button label='Back' className='blue' onClick={() =>handleBackButtonClick()} />
    </div>
  )
}

export default ParamsPdfViewer