import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { postPartners, updatePartner } from '../../../redux-tookit/slices/partnerSlice';
import Button from '../../../utils/Button';
import validator from 'validator'
import {  toast } from 'react-toastify';


const AddPartner = (props) => {
    const dispatch = useDispatch();
    const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData } = props;
    const columns = useSelector((state) => state.partner.columns);
    const organizationData = useSelector((state) => state.organization.labeledData);
    const [newRow, setNewRow] = useState({});
    const columnKeys = Object.keys(columns);
    const [emailError, setEmailError] = useState('')

    const handleInputChange = (header, e) => {
        const updatedData = JSON.parse(JSON.stringify(editData));
        updatedData[header] = e.target.value;
        setEditData(updatedData);
    }

    const onChangeHandle = (column, e, act) => {
        let match = false
        if (column === 'pinCode') {
            if (e.target.value.match(/^[1-9]{0,1}\d{0,5}$/)) match = true
        } else if (column === 'phone') {
            if (e.target.value.match(/^\d{0,10}$/)) match = true
        } else if (column === 'fax') {
            if (e.target.value.match(/^\d{0,12}$/)) match = true
        } else {
            match = true
        }
        if (match === true) {
            if (act === 'add') {
                setNewRow({ ...newRow, [column]: e.target.value })
            } else setEditData({ ...editData, [column]: e.target.value })
        }

    }

    const handleUpdate = async (e) => {

        e.preventDefault();
        if (validator.isEmail(editData.email)) {

            const selectedOrganization = organizationData.find(org => org.organizationName === editData.organizationName);
            const id = editData.partnerId;
            const type = {
                'name': editData.name,
                'organizationId': selectedOrganization.organizationId,
                'phone': editData.phone,
                'email': editData.email,
                'address': editData.address
            };
            console.log(id);
            console.log(type);
            //console.log(editData);
            dispatch(updatePartner({ id, type }))
            setIsEditing(false);
        } else {
            toast.error('Enter a valid email')
        }

    }

    const handleSaveButtonClick = async (e) => {

        e.preventDefault();
        if (validator.isEmail(newRow.email)) {
            dispatch(postPartners({ ...newRow }));
            console.log(newRow);
            setIsAdding(false);
            setNewRow({});
        } else {
            toast.error('Enter a valid email')
        }
    };
    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isAdding &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Add Partner</div>
                            <div className="table-container">
                                {columnKeys.map((column) => (
                                    column !== 'partnerId' ?
                                        (column === 'organizationName' ?
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[column].label}
                                                    {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                </label>
                                                <select
                                                    id="organizationId"
                                                    onChange={(e) => {
                                                        setNewRow({ ...newRow, ['organizationId']: e.target.value });
                                                    }}
                                                    required
                                                >
                                                    <option value="">Select</option>
                                                    {organizationData.map((value, index) => (
                                                        <option key={index} value={value.organizationId}>
                                                            {value.organizationName}
                                                        </option>
                                                    ))}
                                                </select></div>) :
                                            column === 'email' ?
                                                (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                    <label>
                                                        {columns[column].label}
                                                        {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                    </label>
                                                    <input
                                                        key={column}
                                                        type={columns[column].type}
                                                        className='table-input'
                                                        value={newRow[column] || ''}
                                                        onChange={(e) => onChangeHandle(column, e, 'add')}
                                                        required={columns[column].isRequired ? true : false}
                                                        pattern={columns[column].pattern}
                                                    /></div>) :
                                                (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                    <label>
                                                        {columns[column].label}
                                                        {columns[column].isRequired && <span style={{ color: 'red' }}>*</span>}
                                                    </label>
                                                    <input
                                                        key={column}
                                                        type={columns[column].type}
                                                        className='table-input'
                                                        value={newRow[column] || ''}
                                                        onChange={(e) => onChangeHandle(column, e, 'add')}
                                                        required={columns[column].isRequired ? true : false}
                                                        pattern={columns[column].pattern}
                                                    /></div>)) : ""
                                )
                                )
                                }
                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => { setIsAdding(false); setNewRow({}) }} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>

                </div>}
            </form >
            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Edit Partner</div>
                            <div className="table-container">
                                {Object.keys(columns).map((key) => (columns[key].editable === true ?
                                    (key === 'organizationName' ?
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                            <label>
                                                {columns[key].label}
                                                {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                            </label>
                                            <select
                                                id="organizationId"
                                                value={editData[key]}
                                                type={columns[key].type}
                                                pattern={columns[key].pattern}
                                                onChange={(e) => {
                                                    handleInputChange(key, e);
                                                }}
                                            >

                                                {organizationData.map((value, index) => (
                                                    <option key={index} value={value.organizationName}>
                                                        {value.organizationName}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>) :
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                            <label>
                                                {columns[key].label}
                                                {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>}
                                            </label>
                                            <input
                                                type={columns[key].type}
                                                className='table-input'
                                                value={editData[key]}
                                                onChange={(e) => handleInputChange(key, e)}
                                                required={columns[key].isRequired ? true : false}
                                                pattern={columns[key].pattern}
                                            /></div>)) :
                                    ""
                                ))}
                            </div>
                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>
            }
            </form>
        </div>
    )
}

export default AddPartner