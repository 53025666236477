import React from 'react'
import { ReactComponent as AddnewIcon } from '../../assets/images/button-icons/Vector.svg';
import EditIcon from '../../assets/images/button-icons/edit.svg';
import { ReactComponent as DelIcon } from '../../assets/images/button-icons/delete.svg';

const TopButtonContainer = (props) => {
    const { add, edit, del, access, accessOverride = [1, 1, 1] } = props;
    // const access =  {
    //         "permissionId": 2,
    //         "writePermission": true,
    //         "readPermission": true,
    //         "updatePermission": true,
    //         "deletePermission": false
    //     }

    // console.log(access);

    const handleDelete = () => {
        // if (selectedRow !== undefined && selectedRow !== null) {
            const result = window.confirm('Are you sure you want to delete the selected row?');
            if (result) {
                del()
            }
        // } else {}

    }

    return (
        <>
            {(access.writePermission && accessOverride[0] === 1) && <AddnewIcon title='add' id='add'
                onClick={add}
            />
            }

            {(access.updatePermission && accessOverride[1] === 1) && <img src={EditIcon} title='edit' id='edit' alt='edit'
                onClick={edit}
            />
            }

            {(access.deletePermission && accessOverride[2] === 1) && <DelIcon style={{ paddingLeft: '10px' }} title='delete' id='delete'
                onClick={() => handleDelete()}
            />
            }
        </>
    )
}

export default TopButtonContainer
