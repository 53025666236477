import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import * as api from "../../services/API";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resetState } from "./loginSlice";

const initialState = {
  columns: {},
  labeledData: [],
  loading: "idle",
  fetcher: true,
  error: null,
};

export const fetchServiceCenter = createAsyncThunk(
  "data/fetchServiceCenter",
  async () => {
    try {
      const response = await api.listServiceCenter();
      const json = response.data;
      const columnLabels = {
        serviceCenterId: {
          label: "Service Center ID",
          type: "number",
          isRequired: true,
          pattern: ".*",
          editable: false,
        },

        PartnerName: {
          label: "Partner Name",
          type: "text",
          isRequired: true,
          pattern: ".*",
          editable: true,
        },
        name: {
          label: "Service Center Name",
          type: "text",
          isRequired: true,
          pattern: ".*",
          editable: true,
        },
        address: {
          label: "Address",
          type: "text",
          isRequired: true,
          pattern: ".*",
          editable: true,
        },
        monthlyCapacity: {
          label: "Monthly Capacity(T)",
          type: "number",
          isRequired: true,
          pattern: ".*",
          editable: true,
        },
        phoneNo: {
          label: "Phone No.",
          type: "tel",
          isRequired: true,
          pattern: "[0-9]{10}",
          editable: true,
        },
        email: {
          label: "Email",
          type: "email",
          isRequired: true,
          pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
          editable: true,
        },
        imageUrl: { label: "Service Center Logo" },
        region: {
          label: "Region",
          type: "text",
          isRequired: true,
          pattern: ".*",
          editable: true,
        },
        // Add more as needed
      };
      const selectedColumns = [
        "serviceCenterId",
        "name",
        "PartnerName",
        "address",
        "monthlyCapacity",
        "phoneNo",
        "email",
        "region",
        "imageUrl",
      ];
      const columns = {};
      selectedColumns.forEach((columnName) => {
        columns[columnName] = columnLabels[columnName];
      });
      const labeledData = json.rows.map((row) => {
        const rowData = {};
        selectedColumns.forEach((columnName) => {
          rowData[columnName] = row[columnName];
        });
        return rowData;
      });

      return { columns, labeledData };
    } catch (error) {
      throw error;
    }
  }
);
export const postServiceCenter = createAsyncThunk(
  "data/postServiceCenter",
  async (newRow) => {
    try {
      const response = await api.addServiceCenter(newRow);
      const data = response.data;
      console.log("Success");
      return data;
    } catch (error) {
      throw error;
    }
  }
);
export const updateServiceCenter = createAsyncThunk(
  "data/updateServiceCenter",
  async ({ id, type }) => {
    try {
      console.log(id);
      const response = await api.updateServiceCenter(id, type);
      const data = response.data;
      console.log("Update Success");
      return { data, id, type };
    } catch (error) {
      throw error;
    }
  }
);
export const deleteServiceCenter = createAsyncThunk(
  "data/deleteServiceCenter",
  async (id) => {
    try {
      await api.deleteServiceCenter(id);
      return id;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const serviceCenterSlice = createSlice({
  name: "serviceCenter",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(resetState, () => initialState)
      .addCase(fetchServiceCenter.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(fetchServiceCenter.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchServiceCenter.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
      })
      .addCase(postServiceCenter.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(postServiceCenter.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        // state.labeledData.push(action.payload);
        toast.success("Service Center added successfully!");
      })
      .addCase(postServiceCenter.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
        toast.error("Failed to add Service Center");
      })
      .addCase(updateServiceCenter.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(updateServiceCenter.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        state.fetcher = !state.fetcher;

        const index = state.labeledData.find(
          (item) => item.serviceCenterId === action.payload.id
        );
        if (index) {
          const updatedItem = {
            serviceCenterId: action.payload.id,
            ...action.payload.type,
          };
          console.log(updatedItem);
          state.labeledData = state.labeledData.map((item) => {
            if (item.serviceCenterId === action.payload.id) {
              return updatedItem;
            }
            return item;
          });
        }
        toast.success("Service Center updated sucessfully");
      })
      .addCase(updateServiceCenter.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
        toast.error("Failed to update Service Center");
      })
      .addCase(deleteServiceCenter.pending, (state) => {
        state.loading = "pending";
      })
      .addCase(deleteServiceCenter.fulfilled, (state, action) => {
        state.loading = "fulfilled";
        const deletedId = action.payload;
        state.labeledData = state.labeledData.filter(
          (data) => data.serviceCenterId !== deletedId
        );
        toast.success("Service Center deleted sucessfully");
      })
      .addCase(deleteServiceCenter.rejected, (state, action) => {
        state.loading = "rejected";
        state.error = action.error.message;
        toast.error(state.error);
      });
  },
});

export default serviceCenterSlice.reducer;
//End of File
