import React, { useContext, useState } from 'react'
import CreateLoadingListPDF from './CreateLoadingListPDF'
import { PDFViewer } from '@react-pdf/renderer'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { fetchPartItems, fetchPartItems2 } from '../../../../../redux-tookit/slices/partItemsSlice'
import { fetchBundles } from '../../../../../redux-tookit/slices/loadingListSlice'
import Loader from '../../../../../components/Loader/Loader'
import { fetchServiceCenter } from '../../../../../redux-tookit/slices/serviceCenterSlice'
import SmFabLoadingListPdf from './SmFabLoadingListPdf'
import { UserAccessContext } from '../../../../../App'
import { fetchProductionParameters } from '../../../../../redux-tookit/slices/smartFab/productionParameterSlice'
import Button from '../../../../../utils/Button'

const PrintLoadingList = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const listDetails = state?.data;
  const bundleData = useSelector(state => state.loadingList.labeledData4)

  const productName = useContext(UserAccessContext).productName

  const serviceCenter1 = useSelector(state => state.serviceCenter.labeledData).filter((sc) => sc.imageUrl)[0] || ''

  const parameters = useSelector(state => state.productionParameter.labeledData)

  const navigate = useNavigate();

  const bufferToBase64 = (buffer) => {
    try {
      // console.log(buffer);

      const byteArray = new Uint8Array(buffer);
      const decoder = new TextDecoder('utf-8');

      let result = '';
      for (let i = 0; i < byteArray.length; i += 1024) {
        const chunk = byteArray.subarray(i, i + 1024);
        result += decoder.decode(chunk);
      }
      console.log('this ran');
      // console.log(result);
      return result;
    } catch (error) {
      console.log(error);
    }
  }

  const serviceCenterLogo = bufferToBase64(serviceCenter1?.imageUrl?.data);

  const [loading, setLoading] = useState(false)

  const [partItems, setPartItems] = useState([])

  const [partItemIds, setPartItemIds] = useState([])

  console.log({ bundleData, partItems });

  const getAllPartItems = (partIds) => {
    const orderIds = JSON.parse(`[${listDetails.orderId}]`).sort((a, b) => a - b)
    let tempPartItems = []


    console.log(orderIds);

    orderIds.forEach((ele, index) => {
      dispatch(fetchPartItems(`?orderId=${ele}`))
        .then((res) => {
          if (res.payload) {

            const tempData = res?.payload?.labeledData
            tempPartItems = [...tempPartItems, ...tempData]

            if (orderIds.length === index + 1) {
              setPartItems(() => {
                let tempPartArray = tempPartItems.filter(item => partIds.includes(item.partItemId))
                tempPartArray.sort((a, b) => a.orderItemId - b.orderItemId).forEach((rowData, index) => {
                  let normalCount = 0, balanceQty = 0
                  bundleData.filter(item => item.partItemId === rowData.partItemId).forEach(bundle => {
                    if (bundle.noOfPieces === rowData.bundleQuantity) {
                      normalCount++;
                    } else {
                      balanceQty = bundle.noOfPieces
                    }
                  })
                  tempPartArray[index] = { ...rowData, "bundles": `${normalCount}x(${rowData.bundleQuantity}) ${balanceQty !== 0 ? `+ ${balanceQty}` : ''}` }
                })
                return tempPartArray
              })
              setLoading(false)
            }

          }
        })
    });

  }



  useEffect(() => {

    if (productName === 'smartFab') {
      dispatch(fetchProductionParameters())
    } else {
      dispatch(fetchBundles(`?bundleIds=${listDetails.bundleId}`))
    }



    dispatch(fetchServiceCenter())


  }, [])



  useEffect(() => {

    if (productName === 'smartFab') {

    } else {
      let partIds = []
      bundleData.forEach(ele => {
        partIds.push(ele.partItemId)
      });

      getAllPartItems(partIds)

      setPartItemIds(partIds)
    }



    // dispatch(fetchPartItems(`?partItemIds=${partItemIds}`))
  }, [bundleData])

  const handleBackButtonClick = () => {
    navigate(`/loading details`)  
  }

  return (
    <div style={{ height: '100%', flexDirection: 'column' }}>
      {loading ? <Loader /> :

        <PDFViewer style={{ width: '100%', height: '90%' }}>

          {
            productName === 'smartFab' ?
              (parameters.length !== 0 ?

                <SmFabLoadingListPdf
                  serviceCenterLogo={serviceCenterLogo}
                  listDetails={listDetails}
                  parameters={parameters}
                /> : '')
              :
              (partItems.length !== 0 ?

                <CreateLoadingListPDF
                  serviceCenterLogo={serviceCenterLogo}
                  listDetails={listDetails}
                  partItems={partItems}
                  bundleData={bundleData}
                /> : '')
          }

        </PDFViewer>

      }

      <Button label='Back' className='blue' onClick={() => handleBackButtonClick()} />


    </div>
  )
}

export default PrintLoadingList
