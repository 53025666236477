import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import '../../assets/styles/ReportPage.css'
import ProdUploadTable from '../../components/ProdUploadTable';
import ProdUploadTable2 from '../../components/ProdUploadTable2';

const ProductionUploading = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.ProductionRecNotUploaded.columns);
  const originalData = useSelector((state) => state.sdbr.ProductionRecNotUploaded.data);
  const [data, setData] = useState(originalData);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const stLocTo= ["CAB1", "REPL", "R001", "F001"];
  const [stLocToCheck, uStLocToCheck] = useState("R001");
  const stLocFrom= ["CAB1", "REPL", "R001", "F001"];
  const [stLocFromCheck, uStLocFromCheck] = useState("CAB1");

  const columns2 = useSelector((state) => state.sdbr.ProductionRecNotUploaded2.columns);
  const data2 = useSelector((state) => state.sdbr.ProductionRecNotUploaded2.data);


  const [diaFilter, setdiaFilter] = useState('');
  const [planIdFilter, setplanIdFilter] = useState('');
  const [orderIdFilter, setorderIdFilter] = useState('');


  const diaValues = [...new Set(originalData.map(item => item.dia))];
  const planIdValues = [...new Set(originalData.map(item => item.planId))];
  const orderIdValues = [...new Set(originalData.map(item => item.orderId))];


  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
  };


  const applyFilters = () => {

    let filteredData = originalData;

    if (diaFilter) {
      filteredData = filteredData.filter((item) =>
        item.dia.includes(diaFilter)
      );
    }
    if (planIdFilter) {
      filteredData = filteredData.filter((item) =>
        item.planId.includes(planIdFilter)
      );
    }
    if (orderIdFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderId.includes(orderIdFilter)
      );
    }


    setData(filteredData);
  }
  
  useEffect(() => {

    dispatch(fetchDataFromApi('ProductionRecNotUploaded'));
    dispatch(fetchDataFromApi('ProductionRecNotUploaded2'));

    setData(originalData);
  }, [dispatch, originalData]);

  useEffect(() => {

    applyFilters();
  }, [diaFilter, planIdFilter, orderIdFilter]);


  return (
    <div className='sdbr'>
      <div className='filter-card'>
        <div className="Filter-OrdFeat">
          <div className="filter">
            <label htmlFor="planIdFilter">Plan Id</label>
            <select
              id="planIdFilter"
              value={planIdFilter}
              onChange={(e) => setplanIdFilter(e.target.value)}
            >
              <option value="">All</option>
              {planIdValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          <div className="filter">
            <label htmlFor="diaFilter">Diameter</label>
            <select
              id="diaFilter"
              value={diaFilter}
              onChange={(e) => setdiaFilter(e.target.value)}
            >
              <option value="">All</option>
              {diaValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
          
          <div className="filter">
            <label htmlFor="orderIdFilter">Order Id</label>
            <select
              id="orderIdFilter"
              value={orderIdFilter}
              onChange={(e) => setorderIdFilter(e.target.value)}
            >
              <option value="">All</option>
              {orderIdValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
       <div className='head'>Production Recorded and not Uploaded</div>
       <ProdUploadTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex}/>
       <ProdUploadTable2 columns={columns2} data={data2}/>

       <div className="filter-card">
        <div className='ProdUpPad'>
        <span className="scrapCheck">
          <label htmlFor='scrapDef' className='label2'>
            Storage location To:
          </label>
          {stLocTo.map((value, index) => (
            <span>
              <input
                type="radio"
                name="stLocTo"
                checked={stLocToCheck === value}
                onChange={() => uStLocToCheck(value)}
                id={`radio${index}`}
              />
              <label htmlFor={`radio${index}`} className="custom-radio-scrap">{value}</label>
            </span>
          ))}
        </span>
        
        <span className="scrapCheck">
          <label htmlFor='scrapDef' className='label2'>
            Storage location From:
          </label>
          {stLocFrom.map((value, index) => (
            <span>
              <input
                type="radio"
                name="scrapDef"
                value='All'
                checked={stLocFromCheck === value}
                onChange={() => uStLocFromCheck(value)}
                id={`radio${index}`}
              />
              <label htmlFor={`radio${index}`} className="custom-radio-scrap">{value}</label>
            </span>
          ))}
        </span>
        </div>
       
        <div className='filter-layoutProdUp'>
          <div><b>Total Tag wise Selected Qnty:</b>0</div>
          <div><b>Balance FG Qnty(T):</b>0.0</div>
          <div><b>Total FG Qnty(T):</b>0</div>
          <div><b>Total Qnty Entered:</b>0</div>
          <div><b>Total FG Qnty(T):</b>0</div>
        </div>
      </div>

    </div>
  )
}

export default ProductionUploading
//End of File
