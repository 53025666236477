import React, { useState, useEffect, useCallback } from 'react';
import '../../../assets/styles/StockDetails.css'
import { useDispatch, useSelector } from 'react-redux';
import FilterComponent from '../../../components/FilterComponent';
import refresh from '../../../assets/images/button-icons/Refresh.svg';
import { deleteRmInventory, fetchInventory, fetchRmInventory } from '../../../redux-tookit/slices/inventorySlice';
import AGTable from '../../../components/AGTable';
import AddStockDetails from './AddStockDetails';
import {  toast } from 'react-toastify';
import { fetchGrades } from '../../../redux-tookit/slices/gradeMasterSlice';
// import ExcelDownloadButton from '../../../components/ExcelDownloadButton';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';
import { fetchUserAccess } from '../../../redux-tookit/slices/userAccessSlice';
import ExcelDownloadButton1 from '../../../components/ExcelDownloadButton1';

const StockDetails = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.inventory.columns);
  const originalData = useSelector((state) => state.inventory.labeledData);
  const [editData, setEditData] = useState([]);

  const [data, setData] = useState([]);

  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [gradeFilter, setGradeFilter] = useState('');
  const [diameterFilter, setdiameterFilter] = useState('');
  const [stockTypeFilter, setStockTypeFilter] = useState('');
  const [materialFilter, setMaterialFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');

  const [gradeValues, setGradeValues] = useState([]);
  const [diameterValues, setDiameterValues] = useState([]);
  const [stockTypeValues, setStockTypeValues] = useState([]);
  const [materialValues, setMaterialValues] = useState([]);
  const [deselector, setDeselector] = useState(0)

  useEffect(() => {
    setGradeValues([...new Set(originalData.map(item => item.gradeName))]);
    setDiameterValues([...new Set(originalData.map(item => item.diameter))].sort((a, b) => a - b));
    setStockTypeValues([...new Set(originalData.map(item => item.materialType))]);
    setMaterialValues([...new Set(originalData.map(item => item.material))]);
    setData(originalData);
  }, [originalData]);

  const [permission, setPermission] = useState({});

  useEffect(() => {
    dispatch(fetchUserAccess())
      .then(res => {
        try {
          if (res.payload) {
            if (res.payload.navbarNames.Transaction["Stock Details"][0]) {
              setPermission(res.payload.navbarNames.Transaction["Stock Details"][0])
            }
          }
        } catch (error) {
          toast.error(`${error.message}`)
        }
      })
  }, [dispatch])


  const handleRowSelect = (rowIndex) => {
    setSelectedRowIndex(rowIndex);
    console.log(rowIndex);
  };

  const handleRefresh = () => {
    setGradeFilter('');
    setdiameterFilter('');
    setStockTypeFilter('');
    setMaterialFilter('');
    setFromDateFilter('');
    setToDateFilter('');
  }

  const handleDeleteButtonClick = async () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
      const id = originalData[selectedRowIndex].rmReceiptId;
      console.log(id);
      handleRefresh()
      dispatch(deleteRmInventory(id));
    } else toast.error("No rows selected, Please select a row and try again");
    setSelectedRowIndex(null);
  };

  const applyFilters = useCallback(() => {
    let filteredData = originalData;

    if (gradeFilter) {
      filteredData = filteredData.filter((item) =>
        item.gradeName === gradeFilter
      );
    }

    if (diameterFilter) {
      filteredData = filteredData.filter((item) =>
        `${item.diameter}` === `${diameterFilter}`
      );
    }

    if (stockTypeFilter) {
      filteredData = filteredData.filter((item) =>
        item.materialType === stockTypeFilter
      );
    }

    if (materialFilter) {
      filteredData = filteredData.filter((item) =>
        item.material === materialFilter
      );
    }
    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const [d] = item.grossWeightTs.split('T')
        const orderDate = new Date(d);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }

    setData(filteredData);
  },[gradeFilter, diameterFilter, stockTypeFilter, materialFilter, fromDateFilter, toDateFilter, originalData])

  console.log(originalData);

  const handleEditButtonClick = () => {
    if (selectedRowIndex !== null && selectedRowIndex !== undefined && selectedRowIndex!=='A') {
      setIsEditing(true);

      handleRefresh()
      let tempD = {}
      const dateString = data[selectedRowIndex].grossWeightTs;

      // Split the date string and remove the AM/PM part
      const [datePart, timePart] = dateString.split('T');
      const [hourPart, minutePart] = timePart.split(':');
      const isPM = timePart.includes('PM');

      // Adjust the hour if it's PM
      const adjustedHour = isPM ? (parseInt(hourPart, 10) % 12) + 12 : hourPart;

      const formattedDate = `${datePart}T${adjustedHour}:${minutePart}`;

      // Now `formattedDate` will be in the format 'YYYY-MM-DDTHH:mm'
      console.log(formattedDate);
      tempD = { ...tempD, grossWeightTs: formattedDate }
      if (data[selectedRowIndex].unloadedTs !== null) {
        const dateString = data[selectedRowIndex].unloadedTs
        const [datePart, timePart] = dateString.split('T');
        const [hourPart, minutePart] = timePart.split(':');
        const isPM = timePart.includes('PM');

        // Adjust the hour if it's PM
        const adjustedHour = isPM ? (parseInt(hourPart, 10) % 12) + 12 : hourPart;

        const formattedDate = `${datePart}T${adjustedHour}:${minutePart}`;

        tempD = { ...tempD, unloadedTs: formattedDate }
      }

      setEditData({ ...data[selectedRowIndex], ...tempD });
    }
    else toast.error("No rows selected, Please select a row and try again");

    setDeselector(deselector + 1);
    setSelectedRowIndex(null);
  };

  const handleAddButtonClick = () => {
    setIsAdding(true);
    handleRefresh()
    setDeselector(deselector + 1);
  };

  useEffect(() => {

    dispatch(fetchRmInventory()).then(() => dispatch(fetchGrades()));
    dispatch(fetchInventory())

  }, [dispatch]);


  useEffect(() => {
    applyFilters();
  }, [gradeFilter, diameterFilter, stockTypeFilter, materialFilter, fromDateFilter, toDateFilter, applyFilters]);

  return (
    <div >
      <div className="Filter-drop" >
        <div className="filter-card stockDetails">
          <div className="filter-layout" style={{ position: 'relative' }}>
            <FilterComponent
              label="Grade"
              filterValue={gradeFilter}
              options={gradeValues}
              onFilterChange={setGradeFilter}
            />
            <FilterComponent
              label="Diameter"
              filterValue={diameterFilter}
              options={diameterValues}
              onFilterChange={setdiameterFilter}
            />
            <FilterComponent
              label="Stock Type"
              filterValue={stockTypeFilter}
              options={stockTypeValues}
              onFilterChange={setStockTypeFilter}
            />
            <FilterComponent
              label="Material"
              filterValue={materialFilter}
              options={materialValues}
              onFilterChange={setMaterialFilter}
            />

            <div className="filter-bottom" style={{ alignItems: 'end', margin: '2px' }}>
              <div className='DateCont'>
                {/* <label className='DateHead'>Stock entry Date</label> */}
                <div className="DateFilt">
                  <div className="filterD">
                    <label htmlFor="fromDateFilter">From</label>
                    <input
                      id="fromDateFilter"
                      type="date"
                      value={fromDateFilter}
                      onChange={(e) => setFromDateFilter(e.target.value)}
                    />
                  </div>
                  <div className="filterD">
                    <label htmlFor="toDateFilter">To</label>
                    <input
                      id="toDateFilter"
                      type="date"
                      value={toDateFilter}
                      onChange={(e) => setToDateFilter(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>


            <div style={{ position: 'absolute', bottom: '0', right: '0' }}>

              <img src={refresh} alt="refresh" onClick={handleRefresh} title='Refresh filters' />

            </div>
          </div>



        </div>
      </div>

      <div className='head'>Stock Record
        <div className="top-button-container">
          {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} title='Add'/>
          <img src={edit} alt="edit" onClick={handleEditButtonClick} title='Edit'/>
          <img src={del} alt="delete" onClick={handleDeleteButtonClick} title='Delete'/> */}
          <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission} />


          <ExcelDownloadButton1 csvData={data} columns={columns} fileName='InventoryList' />
        </div>
      </div>

      <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} deselector={deselector} />
      {isAdding === true || isEditing === true ?
        <AddStockDetails isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} onSelectRow={handleRowSelect} />
        : ""}
      {/* <div className="bottom-button-container">
        <Button label="Download/Upload of Stock" className="blue" />
      </div> */}

    </div>

  )
}

export default StockDetails
//End of File