import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CuttingSequence from "./CuttingSequence";
import { PDFViewer } from "@react-pdf/renderer";
import Button from "../../../utils/Button";

const PdfContainer = () => {
    const location = useLocation();

    const navigate = useNavigate();

    const planId = location.state?.planCombId;

    const sequence = location.state?.result;

    const offcutT = location.state?.offcutT;

    const offcutP = location.state?.offcutP;

    const scrapT = location.state?.scrapT;

    const scrapP = location.state?.scrapP;

    return (
        <div style={{ border: "2px solid black", height: "100%" }}>
            <PDFViewer style={{ height: "90%", width: "100%" }}>
                <CuttingSequence
                    sequence={sequence}
                    planId={planId}
                    offcutT={offcutT}
                    offcutP={offcutP}
                    scrapT={scrapT}
                    scrapP={scrapP}
                />
            </PDFViewer>
            <div style={{padding:'0 1rem'}}>
                <Button
                    label="Back"
                    className="blue"
                    onClick={() => navigate("/plan details")}
                />
            </div>
        </div>
    );
};

export default PdfContainer;
