import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const initialState = {
    columns: {},
    labeledData: [],
    loading: 'idle',
    error: null,
    errorData: []
};


export const downloadGenPdf = createAsyncThunk('data/downloadGenPdf', async (params) => {
    try {
        const response = await api.getBbsPdf(params);
        const json = response.data.rows;
        
        return { json };
    } catch (error) {
        console.log(error);
        throw error;
    }
});

export const uploadGenPdf = createAsyncThunk('data/uploadGenPdf', async (type) => {

    try {
        const response = await api.postGenPdf(type);
        const data = response.data;
        console.log('Success');
        return data;
    } catch (error) {
        throw error;
    }
});



const bbsPdfGenerationSlice = createSlice({
    name: 'bbsPdfGenerationSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
        .addCase(downloadGenPdf.pending, (state) => {
            state.loading = 'pending';
        })
        .addCase(downloadGenPdf.fulfilled, (state, action) => {
            state.loading = 'fulfilled';

        })
        .addCase(downloadGenPdf.rejected, (state, action) => {
            state.loading = 'rejected';
        })

        .addCase(uploadGenPdf.pending, (state) => {
            state.loading = 'pending';
        })
        .addCase(uploadGenPdf.fulfilled, (state, action) => {
            state.loading = 'fulfilled';
            state.fetcher = !state.fetcher;
            console.log(action.payload)
            toast.success('Generated successfully')
        })
        .addCase(uploadGenPdf.rejected, (state, action) => {
            state.loading = 'rejected';
            state.error = action.error.message;
            toast.error('Failed to generate Pdf')
        })
            
    }
})

export default bbsPdfGenerationSlice.reducer
//End of File