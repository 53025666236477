import React, { useEffect, useState } from 'react'
import '../../../assets/styles/ShapeCardGenerator.css'
import Zoom from '../../../assets/images/icons/enlarge.svg'
import Exit from '../../../assets/images/icons/disappear.svg'
import heartImg from '../../../assets/images/icons/heart-regular.svg'


const ShapeCardGenerator = (props) => {
  const { shapeId, shapeURL, selectedShape, setSelectShape, favId = null, setSelFavId } = props;
  const [shapeDisplay, setShapeDisply] = useState(false);

  const url = String.fromCharCode.apply(null, new Uint8Array(shapeURL.data));
  const [tempEscape, setTempEscape] = useState(false)
  // console.log(url);
  const handleShapeSelection = () => {
    setTempEscape(true)
    setSelectShape(shapeId)
    // console.log({favId});
    if (favId !== null){
      setSelFavId(favId)
    }
      
  }

  const handleScrollIntoView = () => {
    // console.log(tempEscape);
    const element = document.getElementById('shapeContainer active');
    element?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    })
  }

  useEffect(() => {
    // console.log(tempEscape);
    if (shapeId === selectedShape && tempEscape !== true)
      handleScrollIntoView()
    setTempEscape(false)
  }, [selectedShape])

  return (
    <div className={`shapeContainer ${shapeId === selectedShape ? 'active' : ''}`} onClick={() => handleShapeSelection()} id={`shapeContainer ${shapeId === selectedShape ? 'active' : ''}`}>
      {shapeId === selectedShape ? <input style={{ display: 'none' }} type="text" autoFocus /> : ''}
      <div className="shapeId">{shapeId}</div>
      <div className="shapeImg">
        <img src={url} alt="shape1" />
      </div>
      <div className="shapeEnlarge">
        <img src={Zoom} alt="zoom" onClick={() => setShapeDisply(true)} />
        {shapeDisplay && (<div><div className="shapeZoom" >
          <img src={url} alt="shape2" />
          <img src={Exit} alt="Exit" className="zoom2" onClick={() => setShapeDisply(false)} />
        </div>
          <div className="bg-dark" onClick={() => setShapeDisply(false)}></div></div>)}
      </div>
      {/* <div className="fav-img">
        <img src={heartImg} alt="" />
      </div> */}
    </div>
  )
}

export default ShapeCardGenerator;