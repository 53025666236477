import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'


const initialState = {
    columns: {},
    labeledData: [],
    loading: 'idle',
    error: null,
    errorData: []
};


export const fetchClusterInventory = createAsyncThunk('data/fetchClusterInventory', async (params) => {
    try {
        const response = await api.listClusterInventory(params);
        const json = response.data.rows;

        const columnLabels = {
            inventoryId: { label: 'inventoryId' },
            name: { label: 'Service Center' },
            gradeName: { label: 'Grade' },
            diameter: { label: 'Diameter' },
            length: { label: 'Length' },
            stockType: { label: 'Stock Type' },
            freeInventoryWeight: { label: 'Available weight(T)' },
            freeInventoryPieces: { label: 'Available pieces' },
            lockedWeight:{ label: 'Weight(T)' },
            lockedPieces: { label: 'Pieces' },
            inventorylockId: {label: 'inventorylockId'},
            lockedServicecenter:{ label: 'Locked by'}

            // Add more as needed
        };

        // const tempo = {
        //     "inventoryId": 1256,
        //     "name": "Pasa Readybuild centre",
        //     "gradeName": "IS1786 Fe500D",
        //     "diameter": 8,
        //     "length": 931,
        //     "stockType": "OFFCUT",
        //     "freeInventoryWeight": 0.03,
        //     "freeInventoryPieces": 17
        //   }



        let selectedColumns = [
            'inventoryId',
            params==='?type=locked'?'lockedServicecenter':'name',
            'gradeName',
            'diameter',
            'length',
            'stockType',
            'inventorylockId',
            params==='?type=locked'?'lockedWeight':'freeInventoryWeight',
            params==='?type=locked'?'lockedPieces':'freeInventoryPieces'
        ]

        const columns = {};

        selectedColumns.forEach((columnName) => {
            columns[columnName] = columnLabels[columnName];
        });

        const labeledData = json.map((row) => {
            const rowData = {};
            selectedColumns.forEach((columnName) => {
                rowData[columnName] = row[columnName];
            });
            return rowData;
        });

        console.log(labeledData);
        
        return { columns, labeledData };
    } catch (error) {
        console.log(error);
        
        throw error;
    }
});

export const postClusterInventory = createAsyncThunk('data/postClusterInventory', async ({ id, type }) => {

    try {
        const response = await api.addClusterInventory(id, type);
        const data = response.data;
        console.log('Success');
        return data;
    } catch (error) {
        throw error;
    }
});

export const lockClusterInventory = createAsyncThunk('data/lockClusterInventory', async ({ type }) => {
    try {
        // console.log(id);
        const response = await api.lockTheClusterInventory(type);
        const data = response.data;
        console.log('Success');
        return data;    
    } catch (error) {
        throw error;
    }
});


export const releaseClusterInventory = createAsyncThunk('data/releaseClusterInventory', async ({ id, type }) => {
    try {
        // console.log(id);
        const response = await api.releaseClusterInventory(id, type);
        const data = response.data;
        console.log('Success');
        return data;    
    } catch (error) {
        throw error;
    }
});


const clusterInventorySlice = createSlice({
    name: 'clusterInventory',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchClusterInventory.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(fetchClusterInventory.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.columns = action.payload.columns;
                state.labeledData = action.payload.labeledData;
            })
            .addCase(fetchClusterInventory.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })

            .addCase(postClusterInventory.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(postClusterInventory.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.fetcher = !state.fetcher;
                console.log(action.payload)
                toast.success('Stock Added successfully')
            })
            .addCase(postClusterInventory.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                toast.error('Failed to add stock')
            })

            .addCase(lockClusterInventory.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(lockClusterInventory.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                console.log('Success');
                state.fetcher = !state.fetcher;
                toast.success('Stock locked successfully');
            })
            .addCase(lockClusterInventory.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                console.log('failed')
                toast.error('Failed to lock stock')
            })

            .addCase(releaseClusterInventory.pending, (state) => {
                state.loading = 'pending';
            })
            .addCase(releaseClusterInventory.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                console.log('Success');
                state.fetcher = !state.fetcher;
                toast.success('Inventory released successfully');
            })
            .addCase(releaseClusterInventory.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                console.log('failed')
                toast.error('Failed to release stock')
            })
    }
})

export default clusterInventorySlice.reducer
//End of File