import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Button from '../../../utils/Button';
import excel from '../../../assets/images/button-icons/excel.svg';
import refresh from '../../../assets/images/button-icons/Refresh.svg';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import '../../../assets/styles/CustomerDetails.css'
import { deleteCustomer, fetchCustomers, postCustomers } from '../../../redux-tookit/slices/customerSlice';
import GenericTable1 from '../../../components/GenericTable1';
import ExcelDownloadButton1 from '../../../components/ExcelDownloadButton1';
import AddCustomer from './AddCustomer';
import { toast } from 'react-toastify';
import AGTable from '../../../components/AGTable';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';

const CustomerDetails = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const permission = useState(location.state?.permission);
    const originalData = useSelector((state) => state.data.labeledData);
    const columns = useSelector((state) => state.data.columns);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [data, setData] = useState(originalData);
    const [editData, setEditData] = useState([]);
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [newRow, setNewRow] = useState({});

    const handleRowSelect = (rowIndex) => {
        setSelectedRowIndex(rowIndex);
    };
    console.log(originalData);

    const handleEditButtonClick = () => {
        if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
            setIsEditing(true);
            setEditData(data[selectedRowIndex]);
            setcustomernameFilter('');
            setcustomertypeFilter('');
            setcustomeridFilter('');
        } else toast.error("No rows selected, Please select a row and try again"); setSelectedRowIndex(null);
    };

    const [customernameFilter, setcustomernameFilter] = useState('');
    const [customertypeFilter, setcustomertypeFilter] = useState('');
    const [customeridFilter, setcustomeridFilter] = useState('');

    const [customernameValues, setcustomernameValues] = useState([]);
    const [customertypeValues, setcustomertypeValues] = useState([]);
    const [customeridValues, setcustomeridValues] = useState([]);
    const columnKeys = Object.keys(columns);

    const applyFilters = () => {

        let filteredData = originalData;

        if (customernameFilter) {
            filteredData = filteredData.filter((item) =>
                item.customerName.includes(customernameFilter)
            );
        }
        if (customertypeFilter) {
            filteredData = filteredData.filter((item) =>
                item.customerType.includes(customertypeFilter)
            );
        }
        if (customeridFilter) {
            filteredData = filteredData.filter((item) =>
                item.customerId == customeridFilter
            );
        }


        setData(filteredData);
    }
    const handleAddButtonClick = () => {
        setIsAdding(true);
        setNewRow({});
        setcustomernameFilter('');
        setcustomertypeFilter('');
        setcustomeridFilter('');
    };



    useEffect(() => {

        setTimeout(() => {
            dispatch(fetchCustomers());
        }, 1000)

    }, [dispatch, isAdding, isEditing]);

    useEffect(() => {
        setcustomeridValues([...new Set(originalData.map(item => item.customerId))]);
        setcustomernameValues([...new Set(originalData.map(item => item.customerName))]);
        setcustomertypeValues([...new Set(originalData.map(item => item.customerType))]);
        setData(originalData);
    }, [originalData]);

    const handleDeleteButtonClick = async () => {
        if (selectedRowIndex) {
            const id = data[selectedRowIndex].customerId;
            console.log(id);
            dispatch(deleteCustomer(id));
            setcustomernameFilter('');
            setcustomertypeFilter('');
            setcustomeridFilter('');
        }
        setSelectedRowIndex(null);

    };

    const handleRefresh = () => {
        setcustomernameFilter('');
        setcustomertypeFilter('');
        setcustomeridFilter('');
    };

    useEffect(() => {

        applyFilters();
    }, [customernameFilter, customertypeFilter, customeridFilter]);

    return (
        <div className='sdbr'>

            <div className='filter-card' >
                <div className="Cus-Detail1" style={{ position: 'relative' }}>
                    <div className="filter" style={{ width: '30%' }}>
                        <label htmlFor="customernameFilter">Customer Name</label>
                        <select
                            id="customernameFilter"
                            value={customernameFilter}
                            onChange={(e) => setcustomernameFilter(e.target.value)}
                        >
                            <option value="">All</option>
                            {customernameValues.map((value, index) => (
                                <option key={index} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* <div className="filter">
                        <label htmlFor="customertypeFilter">Customer Type</label>
                        <select
                            id="customertypeFilter"
                            value={customertypeFilter}
                            onChange={(e) => setcustomertypeFilter(e.target.value)}
                        >
                            <option value="">All</option>
                            {customertypeValues.map((value, index) => (
                                <option key={index} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div> */}

                    <img src={refresh} alt="refresh" onClick={handleRefresh} style={{ width: '20px', position: 'absolute', bottom: '0', right: '0' }} />
                </div>

            </div>
            {/* <div className="filter-card">
                <div className="Cus-Detail2">
                    <div className="filter">
                        <label htmlFor="customeridFilter">Customer Id</label>
                        <select
                            id="customeridFilter"
                            value={customeridFilter}
                            onChange={(e) => setcustomeridFilter(e.target.value)}
                        >
                            <option value="">All</option>
                            {customeridValues.map((value, index) => (
                                <option key={index} value={value}>
                                    {value}
                                </option>
                            ))}
                        </select>
                    </div>
                    <Button className='blue' label='Download in Cube' />
                </div>
            </div> */}

            <div className='head'>Customer Details
                <div className="top-button-container">
                    {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
                    <img src={edit} alt="edit" onClick={handleEditButtonClick}/>
                    <img src={del} alt="delete" onClick={handleDeleteButtonClick}/> */}
                    <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]} />

                    <ExcelDownloadButton1 csvData={data} fileName='CustomerList' columns={columns} />
                </div>
            </div>
            <div>
                <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
            </div>
            <AddCustomer isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} emailList={originalData.map(item => item.email)} />



        </div>
    );
}

export default CustomerDetails;