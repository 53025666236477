// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update-field-container{
    display: flex;
    flex-direction: column;
    width: fit-content;
    flex-basis: calc(33% - 30px);
    min-width: max-content;
    margin-right: 20px;
}

.update-field-container label{
    margin-left: 16px;
    margin-bottom: 4px;
    font-weight: 400;
}

.update-field-container input{
    border: 1px solid grey; 
    height: 40px;
    box-sizing: border-box;
    border-radius: 50px;
    padding: 15px 15px;
    width: 250px;

}

.update-password-main-container{
    display: flex;
    flex-wrap: wrap;
    
    
}`, "",{"version":3,"sources":["webpack://./src/pages/Settings/Password/updatePassword.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,4BAA4B;IAC5B,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,sBAAsB;IACtB,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;IACnB,kBAAkB;IAClB,YAAY;;AAEhB;;AAEA;IACI,aAAa;IACb,eAAe;;;AAGnB","sourcesContent":[".update-field-container{\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: fit-content;\r\n    flex-basis: calc(33% - 30px);\r\n    min-width: max-content;\r\n    margin-right: 20px;\r\n}\r\n\r\n.update-field-container label{\r\n    margin-left: 16px;\r\n    margin-bottom: 4px;\r\n    font-weight: 400;\r\n}\r\n\r\n.update-field-container input{\r\n    border: 1px solid grey; \r\n    height: 40px;\r\n    box-sizing: border-box;\r\n    border-radius: 50px;\r\n    padding: 15px 15px;\r\n    width: 250px;\r\n\r\n}\r\n\r\n.update-password-main-container{\r\n    display: flex;\r\n    flex-wrap: wrap;\r\n    \r\n    \r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
