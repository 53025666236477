import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUser } from '../../../redux-tookit/slices/userSlice';
import AGTable from '../../../components/Tables/AGTableOpenPlan';
import { ReactComponent as AddIcon } from '../../../assets/images/button-icons/Vector.svg'
import AddUser from './AddUser';
import { fetchServiceCenter } from '../../../redux-tookit/slices/serviceCenterSlice';
import { fetchRoleWiseAccess } from '../../../redux-tookit/slices/userAccessSlice';
import EditIcon from '../../../assets/images/button-icons/edit.svg';
import { toast } from 'react-toastify';

const UserMaster = () => {
    const dispatch = useDispatch();
    const userData = useSelector(state => state.user.labeledData);
    const columns = useSelector(state => state.user.columns);
    const scData = useSelector(state => state.serviceCenter.labeledData);
    const role = useSelector(state => state.userAccess.rawJson).map(item => { return { roleId: item.roleId, roleName: item.roleName } });
    const [isAdding, setIsAdding] = useState(false)
    const [isEditing, setIsEditing] = useState(false)
    const emailList = userData.map(item => item.userEmail);
    const userNoList = userData.map(item => item.userNo);
    const [editData, setEditData] = useState([])

    const [selectedRow, setSelectedRow] = useState(null)

    useEffect(() => {
        dispatch(fetchUser());
        dispatch(fetchServiceCenter());
        dispatch(fetchRoleWiseAccess());
    }, [])

    const handleRowSelection = (value) => {
        if(value === undefined){
            setSelectedRow(null)
        } else setSelectedRow(value)
    }

    const handleEditButtonClick  = () => {
        if(selectedRow !== null) {
            setEditData(userData[selectedRow])
            setIsEditing(true)
            console.log(userData[selectedRow]);
        } else toast.error('No rows selected. Please select a row ')
    }

    console.log(selectedRow);
    return (
        <div className='sdbr'>
            <div className="head">User Master
                <div className="top-button-container">
                    <AddIcon onClick={() => setIsAdding(true)} />
                    <img src={EditIcon} onClick={() => handleEditButtonClick()}/>
                </div>
            </div>
            <AGTable columns={columns} data={userData} onSelectRow={handleRowSelection}/>

            {isAdding && <AddUser isAdding={isAdding} setIsAdding={setIsAdding} role={role} scData={scData} emailList={emailList} userNoList={userNoList} />}

            {isEditing && <AddUser isEditing={isEditing} setIsEditing={setIsEditing} role={role} scData={scData} emailList={emailList} userNoList={userNoList} editData={editData} action='edit'/>}

        </div>
    )
}

export default UserMaster
