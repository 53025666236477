// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stockRadioButtons {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.top-button-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: medium;
    font-weight: normal;
    color: #000;
    cursor: pointer;

}

.top-button-container img {
    width: 20px;
    height: 20px;
    margin-left: 1vw;
}

.filter-layout .filter {
    display: flex;
    flex-direction: column;
}

.stockRadioButtons {
    padding-bottom: 1vw;
}

.button-container2 {
    padding: 0 1vw;
    display: flex;
    width: 100%;
    justify-content: right;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/StockDetails.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb;;AAEA;IACI,4DAA4D;IAC5D,iBAAiB;IACjB,mBAAmB;IACnB,WAAW;IACX,eAAe;;AAEnB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,aAAa;IACb,WAAW;IACX,sBAAsB;AAC1B","sourcesContent":[".stockRadioButtons {\r\n    display: flex;\r\n    flex-direction: row;\r\n    gap: 10px;\r\n}\r\n\r\n.top-button-container {\r\n    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;\r\n    font-size: medium;\r\n    font-weight: normal;\r\n    color: #000;\r\n    cursor: pointer;\r\n\r\n}\r\n\r\n.top-button-container img {\r\n    width: 20px;\r\n    height: 20px;\r\n    margin-left: 1vw;\r\n}\r\n\r\n.filter-layout .filter {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n\r\n.stockRadioButtons {\r\n    padding-bottom: 1vw;\r\n}\r\n\r\n.button-container2 {\r\n    padding: 0 1vw;\r\n    display: flex;\r\n    width: 100%;\r\n    justify-content: right;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
