import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { updateInventory } from '../../../redux-tookit/slices/inventorySlice';
import Button from '../../../utils/Button';

const Addinventory = (props) => {

    const { isEditing, setIsEditing, editData, setEditData } = props;

    const dispatch = useDispatch();

    const gradeMasterData = useSelector((state) => state.gradeMaster.labeledData);


    const sampleData = {
        "gradeId": 1,
        "weight": 10,
        "length": 1000,
        "totalPieces": 10,
        "stockType": "STRAIGHT LENGTH"
    }

    console.log(gradeMasterData);
    console.log(editData);



    const handleSaveButtonClick = async (e) => {
        e.preventDefault();

        let type = {};

        const id = editData.inventoryId;

        Object.keys(sampleData).forEach((value) => {
            type = { ...type, [value]: editData[value] }
        })

        dispatch(updateInventory({ id, type }))
    }

    const valuChange = (e) => {
        setEditData({...editData, [e.target.name]: e.target.value})
    }

    
    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container" style={{ width: '70%', position: 'absolute', right: '2%' }}><div className="head">Add Stock</div>
                            <div className="table-container">
                                <div className="filter">
                                    <label htmlFor="stockType">Stock Type</label>
                                    <select name="stockType" id="stockType" value={editData.stockType} onChange={e => valuChange(e)}>
                                        <option value="">-Select-</option>
                                        <option value="COIL">COIL</option>
                                        <option value="STRAIGHT LENGTH">STRAIGHT LENGTH</option>
                                        <option value="FINISHED GOODS">FINISHED GOODS</option>
                                        <option value="SHORT LENGTH">SHORT LENGTH</option>
                                        <option value="OFFCUT">OFFCUT</option>
                                        <option value="CUSTOMIZED LENGTH">CUSTOMIZED LENGTH</option>
                                    </select>
                                </div>

                                <div className="filter">
                                    <label htmlFor="grade">Grade</label>
                                    <select name="gradeName" id="grade" value={editData.gradeName} onChange={e => valuChange(e)}>
                                        <option value="">-Select-</option>

                                        {[...new Set(gradeMasterData
                                        // .filter(item => item.materialType === editData.stockType)
                                        .map(item => item.gradeName))].sort((a, b) => a - b)
                                            .map(value => (
                                                <option value={value}>{value}</option>
                                            ))
                                        }
                                    </select>
                                </div>

                                <div className="filter">
                                    <label htmlFor="diameter">Diameter</label>
                                    <select name="diameter" id="diameter" value={editData.diameter} onChange={e => valuChange(e)}>
                                        <option value="">-Select-</option>
                                        {[...new Set(gradeMasterData.filter(item => item.gradeName === editData.gradeName).map(item => item.diameter))].sort((a, b) => a - b)
                                            .map((rowData) => (
                                                <option value={rowData}>
                                                    {rowData}
                                                </option>
                                            ))}
                                    </select>
                                </div>


                                <div className="filter">
                                    <label htmlFor="material">Material</label>
                                    <select name="gradeId" id="material" value={editData.gradeId} required onChange={e => valuChange(e)}>
                                        <option value="">-Select-</option>
                                        {[...new Set(gradeMasterData)].filter(item => 
                                        item.gradeName === editData.gradeName &&
                                        item.diameter*1 === editData.diameter*1 
                                        // && item.materialType === editData.stockType
                                        ).sort((a, b) => a.material - b.material)
                                            .map((rowData, index) => (
                                                <option value={rowData.gradeId}>
                                                    {rowData.material}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                <div className="filter">
                                    <label htmlFor="weight">Weight</label>
                                    <input type="number" name='weight'  className='table-input' value={editData.weight} onChange={e => valuChange(e)}/>
                                </div>
                                <div className="filter">
                                    <label htmlFor="length">Length</label>
                                    <input type="number" name='length' className='table-input' value={editData.length} min={0} onChange={e => valuChange(e)}/>
                                </div>
                                <div className="filter">
                                    <label htmlFor="totalPieces">Total pieces</label>
                                    <input type="number" name='totalPieces' className='table-input' value={editData.totalPieces} min={0} onChange={e => valuChange(e)}/>
                                </div>
                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => { setIsEditing(false); }} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
        </div>
    )
}

export default Addinventory
