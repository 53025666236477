import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import { resetState } from './loginSlice';
 
const initialState = {
  columns: {},
  labeledData: [],
  loading: 'idle',
  error: null,
};
 
export const fetchProcessCenter = createAsyncThunk('data/fetchProcessCenter', async () => {
  try {
 
    const response = await api.listProcessCenter();
    const json = response.data;
 
    const columnLabels = {
      processCenterId:{label:'Process Center ID',type:'number',editable:false,isRequired:false} ,
      processCenterName: {label:'Process Center Name',type:'text',editable:true,isRequired:true},
      name: {label:'Service Center Name',type:'text',editable:true,isRequired:true},
      // Add more as needed
    };
 
    const selectedColumns = ['processCenterId', 'processCenterName','name'];
 
    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });
 
    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });
 
    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});
export const postProcessCenter = createAsyncThunk('data/postprocessCenter', async (newRow) => {
  try {
 
    const response = await api.addProcessCenter(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});
export const updateProcessCenter = createAsyncThunk('data/updateprocessCenter', async ({id,type}) => {
  try {
    console.log(type);
    const response = await api.updateProcessCenter(id,type);
   
    const data = response.data;
    console.log('Update Success');
    return {data, id, type};
  } catch (error) {
    throw error;
  }
});
 
export const deleteProcessCenter = createAsyncThunk('data/deleteprocessCenter', async (id) => {
  try {
     await api.deleteProcessCenter(id);
    console.log('Success');
    return id;
  } catch (error) {
    throw error.response.data;
  }
});
const processCenterSlice = createSlice({
  name: 'processCenter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    .addCase(resetState, () => initialState)
      .addCase(fetchProcessCenter.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(fetchProcessCenter.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchProcessCenter.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(postProcessCenter.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(postProcessCenter.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.labeledData.push(action.payload);
        toast.success('Process Center added successfully!');
      })
      .addCase(postProcessCenter.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to add process center');
      })
      .addCase(updateProcessCenter.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(updateProcessCenter.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
       
        const index=state.labeledData.find(item=>item.processCenterId===action.payload.id);
        if(index){
          const updatedItem={...index,processCenterName:action.payload.type.name};
          state.labeledData=state.labeledData.map(item=>{
            if(item.processCenterId===action.payload.id){
              return updatedItem;
            }
            return item;
          })
          toast.success('Process Center updated successfully!');
        }
        //state.labeledData.push(action.payload);
      })
      .addCase(updateProcessCenter.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error('Failed to update process center');
      })
      .addCase(deleteProcessCenter.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(deleteProcessCenter.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        const deletedId = action.payload;
        state.labeledData=state.labeledData.filter((processCenter) => processCenter.processCenterId !== deletedId);
        toast.success('Process Center deleted successfully!');
      })
      .addCase(deleteProcessCenter.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
        toast.error(state.error);
      });
  },
});
 
export default processCenterSlice.reducer;
//End of File