// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-layout3 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 5px 20px;
}

.filter-layout3 .filter {
  display: flex;
  flex-direction: column;
  justify-content: left;
  width: fit-content;
  
}

.GraDet2 {
  display: flex;
  padding: 0vw 0;
  align-items:flex-end;
  justify-content: space-around;
  padding-right: 1.5vw;
}

.GraDet2 .filter {
  display: flex;
  flex-direction: column;
}

.GraDet2 .filter input {
  padding: 8px;
  margin-top: 4px;
  border: 2px solid #d9d9d9;
  border-radius: 4px;
}

.GraDet2 .filter input,
.GraDet2 .filter select{
  width: 65%;
}`, "",{"version":3,"sources":["webpack://./src/assets/styles/GradeDetails.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kCAAkC;EAClC,2BAA2B;EAC3B,aAAa;AACf;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,qBAAqB;EACrB,kBAAkB;;AAEpB;;AAEA;EACE,aAAa;EACb,cAAc;EACd,oBAAoB;EACpB,6BAA6B;EAC7B,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;;EAEE,UAAU;AACZ","sourcesContent":[".filter-layout3 {\r\n  display: grid;\r\n  grid-template-columns: 1fr 1fr 1fr;\r\n  grid-template-rows: 1fr 1fr;\r\n  gap: 5px 20px;\r\n}\r\n\r\n.filter-layout3 .filter {\r\n  display: flex;\r\n  flex-direction: column;\r\n  justify-content: left;\r\n  width: fit-content;\r\n  \r\n}\r\n\r\n.GraDet2 {\r\n  display: flex;\r\n  padding: 0vw 0;\r\n  align-items:flex-end;\r\n  justify-content: space-around;\r\n  padding-right: 1.5vw;\r\n}\r\n\r\n.GraDet2 .filter {\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.GraDet2 .filter input {\r\n  padding: 8px;\r\n  margin-top: 4px;\r\n  border: 2px solid #d9d9d9;\r\n  border-radius: 4px;\r\n}\r\n\r\n.GraDet2 .filter input,\r\n.GraDet2 .filter select{\r\n  width: 65%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
