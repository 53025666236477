import React, { useEffect, useState } from 'react';
import '../assets/styles/GenericTable.css';
import '../assets/styles/SDBRTable.css'
import { useDispatch, useSelector } from 'react-redux';
import { fetchOrder, updateOrder } from '../redux-tookit/slices/orderSlice'
import deselect from '../assets/images/button-icons/Deselect.svg'
import { dateChanger } from './reusableFunctions';

const SDBRTable = ({ columns = {}, data = [], onSelectRow, selectedRow, setSelectedRowIndex }) => {

  const dispatch = useDispatch();
  const [cellData, setCellData] = useState(data);
  const orderList = useSelector(state => state.order.labeledData)
  // const orderColumn = useSelector(state => state.order.columns)
  let totalV = [0, 0, 0];

  console.log(data);

  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`
  }

  const handleInputChange = (index, header, value) => {

    // setCellData([...cellData, ...newData]);

    setCellData((prev) => {
      const newData = [...prev];
      newData[index] = { ...newData[index], customerExpectedDeliveryDate: value };
      console.log(newData);
      return newData;

    });

  };

  data.forEach(e => {
    totalV[0] += e.productWeight;
    totalV[1] += e.coilRouteDuration_SDBR;
    totalV[2] += e.rebarRouteDuration_SDBR;
  });

  // const changeDateFormat = (date) => {
  //   const [year, month, day] = date.split('-')
  //   return `${day}-${month}-${year}`
  // }

  const onBlurHandle = (index, header, value) => {

    let type = { flag: '1' }
    let id = data[index].orderId;

    console.log(orderList);

    orderList.forEach((data, index) => {
      if (data.orderId * 1 === id * 1) {

        Object.keys(orderList[0]).forEach(item => {
          if (item === 'customerExpectedDeliveryDate' ||
            item === 'customerPartiesId' ||
            item === 'customerSegmentId' ||
            item === 'deliveryDate' ||
            item === 'diffFactor' ||
            item === 'flag' ||
            item === 'offers' ||
            item === 'orderRemarks' ||
            item === 'orderType' ||
            item === 'poDate' ||
            item === 'sapId') {
            type = { ...type, [item]: orderList[index][item] }
          }
        })

      }
    })

    type = { ...type, [header]: value }




    dispatch(updateOrder({ id, type }))
    console.log(type, id)
    
    // customerExpectedDeliveryDate: "2023-12-09",
    // customerPartiesId: 1,
    // customerSegmentId: 13,
    // deliveryDate: "2023-11-12",
    // diffFactor: "None",
    // flag: "1",
    // offers: "project",
    // orderRemarks: "",
    // orderType: "normal",
    // poDate: "2023-12-09",
    // sapId: "ase271n990"
  }

  useEffect(() => {
    dispatch(fetchOrder());
  }, [dispatch])

  useEffect(() => {
    setCellData(data)
  }, [data])

  return (
    <div className="table-container">
      <table className="generic-table">
        <thead className='stick'>
          <tr  >

            <td className="table-header-1" style={{ border: '0px' }} onClick={() => setSelectedRowIndex([])}>

              <img src={deselect} alt="deselect" />
              
            </td>
            {Object.keys(columns).map((column) => (
              column !== 'orderId' ?
                <th className="table-header-1">
                  {columns[column]?.label}
                </th> : ''
            ))}
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? <tr> <td colSpan="15">No Rows To Show</td> </tr> : ''}
          {cellData.map((row, rowIndex) => (
            <tr key={rowIndex}  className={`data-row ${selectedRow.includes(rowIndex) ? 'active' : ''}`}>
              <td onClick={() => onSelectRow(rowIndex)}>
                <input
                  type="checkbox"
                  name="rowSelect"
                  value={rowIndex}
                  checked={selectedRow.includes(rowIndex)}
                  id={`radio${rowIndex}`}
                  readOnly
                />
                <label htmlFor={`radio${rowIndex}`} className="custom-checkbox" ></label>
              </td>

              {Object.keys(columns).map((column) => (
                column === 'customerExpectedDeliveryDate' && selectedRow.includes(rowIndex) ?
                  <td key={column}>
                    <input
                      type="date"
                      onClick={() => {if(!selectedRow.includes(rowIndex))onSelectRow(rowIndex)}}
                      value={row[column]}
                      min={getCurrentDate()}
                      onChange={(e) => handleInputChange(rowIndex, column, e.target.value)}
                      onBlur={(e) => onBlurHandle(rowIndex, column, e.target.value)}
                      style={{ width:'fit-content', border: '1px solid #d7d7d7', borderRadius: '5px', height:'100%' }}
                    />
                  </td>
                  : column === 'deliveryDate' || column === 'customerExpectedDeliveryDate' || column === 'productionStartDate' || column === 'productionEndDate' || column === 'poDate' ?
                    <td key={column} onClick={() => onSelectRow(rowIndex)}>
                      {dateChanger(row[column])}
                    </td>
                    :
                    column === 'sdbrstatus' ?
                      <td key={column} style={{ backgroundColor: `${row[column].toLowerCase() === 'completed' ? '#2cdd10' : 'red'}` }} onClick={() => onSelectRow(rowIndex)}></td>
                      : column !== 'orderId' ?
                        <td key={column} onClick={() => onSelectRow(rowIndex)}>{row[column]}</td> : ''
              ))}
            </tr>
          ))}
          <tr className='table-header-1'
            style={{ position: 'sticky', bottom: '0' }}
          >
            <th colSpan="5"></th>
            {totalV.map((e, index) =>
               <th>{Math.floor(e * 1000) / 1000 }</th> 
            )}
            <th colSpan="9"></th>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default SDBRTable;