import React from 'react';
import * as FileSaver from 'file-saver';
import excel from '../assets/images/button-icons/excel.svg';
import * as XLSX from 'xlsx-color';

const ExcelDownloadButton1 = ({ csvData, fileName, columns }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';


    const exportToCSV = () => {

        console.log(columns)


        const filteredColumns = Object.keys(columns).filter(key => !key.toLowerCase().includes("id"))
        const filteredData = csvData.map(item => {
            let newData = {}
            filteredColumns.forEach(key => {
                newData = { ...newData, [key]: item[key] }
            })
            return newData
        })

        const ws = XLSX.utils.json_to_sheet(filteredData);

        const columnslabel = filteredColumns.map((keys) => columns[keys].label);
        console.log(columnslabel);
        console.log(csvData)
        columnslabel.forEach((cell, index) => {
            ws[XLSX.utils.encode_cell(
                { r: 0, c: index })] = {
                v: cell, s: {
                    fill: {
                        fgColor: { rgb: '878bfa' },
                        patternType: 'solid'
                    },
                    font:
                    {
                        color: { rgb: 'FFFFFFFF' },
                        bold: true,
                        underline: true,
                        name: 'Calibri'
                    }
                }
            };
        });

        const data1 = filteredData.map(rowObj =>
            Object.values(rowObj)
        )

        data1.push(columnslabel);
        ws['!cols'] = fitToColumn(data1);

        function fitToColumn(data1) {
            return data1[0].map((a, i) => ({ wch: Math.max(...data1.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
        }
        //        ws['!cols'] = wscols;
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, JSON.stringify(fileName) + fileExtension);
    }

    return (
        <img src={excel} alt="excel" onClick={exportToCSV} title='Download excel' />
    );
};

export default ExcelDownloadButton1;