// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SmFabPopup {
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    z-index: 9;
    transition: background-color 1s;
    box-sizing: border-box;
}

.SmFabPopup .content-area {
    background-color: White;
    border-radius: 15px;
    padding: 40px;
    margin: 100px;

}


.SmFabPopup .content-area form {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    max-height: 50vh;
    overflow-y: scroll;
    border: 1px dashed #d5d5d5;
    border-radius: 5px;
    padding: 20px;
}

.SmFabPopup .formField {
    display: flex;
    flex-direction: column;
    flex-basis: calc(33.33% - 30px);
}

.SmFabPopup .formField label{
padding: 3px;
}

.SmFabPopup .formField input,
.SmFabPopup .formField select {
    border: 2px solid #d5d5d5;
    border-radius: 5px;
    padding: 5px;
}


.SmFabPopup .content-area .bottom-button{
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 20px;
    padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/transaction/ProdutionParams/productionParams.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,uBAAuB;IACvB,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,oCAAoC;IACpC,UAAU;IACV,+BAA+B;IAC/B,sBAAsB;AAC1B;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,aAAa;;AAEjB;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,SAAS;IACT,gBAAgB;IAChB,kBAAkB;IAClB,0BAA0B;IAC1B,kBAAkB;IAClB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,+BAA+B;AACnC;;AAEA;AACA,YAAY;AACZ;;AAEA;;IAEI,yBAAyB;IACzB,kBAAkB;IAClB,YAAY;AAChB;;;AAGA;IACI,aAAa;IACb,WAAW;IACX,uBAAuB;IACvB,aAAa;IACb,iBAAiB;AACrB","sourcesContent":[".SmFabPopup {\r\n    display: flex;\r\n    position: absolute;\r\n    align-items: center;\r\n    justify-content: center;\r\n    top: 0;\r\n    left: 0;\r\n    width: 100vw;\r\n    height: 100vh;\r\n    background-color: rgba(0, 0, 0, 0.1);\r\n    z-index: 9;\r\n    transition: background-color 1s;\r\n    box-sizing: border-box;\r\n}\r\n\r\n.SmFabPopup .content-area {\r\n    background-color: White;\r\n    border-radius: 15px;\r\n    padding: 40px;\r\n    margin: 100px;\r\n\r\n}\r\n\r\n\r\n.SmFabPopup .content-area form {\r\n    display: flex;\r\n    flex-direction: row;\r\n    flex-wrap: wrap;\r\n    gap: 20px;\r\n    max-height: 50vh;\r\n    overflow-y: scroll;\r\n    border: 1px dashed #d5d5d5;\r\n    border-radius: 5px;\r\n    padding: 20px;\r\n}\r\n\r\n.SmFabPopup .formField {\r\n    display: flex;\r\n    flex-direction: column;\r\n    flex-basis: calc(33.33% - 30px);\r\n}\r\n\r\n.SmFabPopup .formField label{\r\npadding: 3px;\r\n}\r\n\r\n.SmFabPopup .formField input,\r\n.SmFabPopup .formField select {\r\n    border: 2px solid #d5d5d5;\r\n    border-radius: 5px;\r\n    padding: 5px;\r\n}\r\n\r\n\r\n.SmFabPopup .content-area .bottom-button{\r\n    display: flex;\r\n    width: 100%;\r\n    justify-content: center;\r\n    padding: 20px;\r\n    padding-bottom: 0;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
