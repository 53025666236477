import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { fetchServiceCenter } from '../../../redux-tookit/slices/serviceCenterSlice';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchProcessCenter, postProcessCenter } from '../../../redux-tookit/slices/processCenterSlice';
import Button from '../../../utils/Button';
import { fetchFacilities, postFacilities, updateFacility } from '../../../redux-tookit/slices/facilityMasterSlice';

const AddFacility = (props) => {
    const dispatch = useDispatch();
    const columns = useSelector((state) => state.facilityMaster.columns);
    const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData } = props;
    const processCenterData = useSelector((state) => state.processCenter.labeledData); const columnKeys = Object.keys(columns);
    const [newRow, setNewRow] = useState({});

    const handleSaveButtonClick = async (e) => {
        e.preventDefault();
        dispatch(postFacilities({ ...newRow })).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchFacilities())
            }
        })

        console.log(newRow);
        setIsAdding(false);
        setNewRow({});
    };
    const handleInputChange = (header, e) => {
        const updatedData = JSON.parse(JSON.stringify(editData));
        updatedData[header] = e.target.value;
        setEditData(updatedData);
    }
    const handleUpdate = async (e) => {

        e.preventDefault();
        const selectedProcessCenter = processCenterData.find(v => v.processCenterName === editData.processCenterName);
        const id = editData.facilityId;
        const type = {
            'processCenterId': selectedProcessCenter.processCenterId,
            'facilityName': editData.facilityName,
            'dimensions': editData.dimensions,
        };
        console.log(id);
        console.log(type);
        //console.log(editData);
        dispatch(updateFacility({ id, type })).then((res) => {
            if (res.payload !== undefined) {
                dispatch(fetchFacilities())
            }
        })
        setIsEditing(false);
    }


    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isAdding &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Add Facility</div>
                            <div className="table-container">


                                {columnKeys.map((column) => (
                                    column !== 'facilityId' ?
                                        (column === 'processCenterName' ?
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[column].label}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <select
                                                    id="processCenterName"
                                                    onChange={(e) => {
                                                        setNewRow({ ...newRow, ['processCenterId']: e.target.value });
                                                    }}
                                                >
                                                    <option>Select</option>
                                                    {processCenterData.map((value, index) => (
                                                        <option key={index} value={value.processCenterId}>
                                                            {value.processCenterName}
                                                        </option>
                                                    ))}
                                                </select></div>) :
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[column].label}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    key={column}
                                                    type={columns[column].type}
                                                    className='table-input'
                                                    value={newRow[column] || ''}
                                                    onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                                    required={columns[column].isRequired ? true : false}


                                                /></div>
                                            )
                                        ) :
                                        ""
                                ))}

                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => { setIsAdding(false); setNewRow({}) }} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Edit Facility</div>
                            <div className="table-container">


                                {columnKeys.map((column) => (
                                    columns[column].editable === true ?
                                        (column === 'processCenterName' ?
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[column].label}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <select
                                                    id="processCenterName"
                                                    value={editData[column]}
                                                    onChange={(e) => {
                                                        handleInputChange(column, e);
                                                    }}
                                                >
                                                    {processCenterData.map((value, index) => (
                                                        <option key={index} value={value.processCenterName}>
                                                            {value.processCenterName}
                                                        </option>
                                                    ))}
                                                </select></div>) :
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>
                                                    {columns[column].label}
                                                    <span style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input
                                                    key={column}
                                                    type={columns[column].type}
                                                    className='table-input'
                                                    value={editData[column]}
                                                    onChange={(e) => handleInputChange(column, e)}
                                                    required={columns[column].isRequired ? true : false}


                                                /></div>
                                            )
                                        ) :
                                        ""
                                ))}

                            </div>

                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => { setIsEditing(false); setNewRow({}) }} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>}
            </form>
        </div>
    )
}

export default AddFacility