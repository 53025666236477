import React, { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import '../../assets/styles/Production.css';
import Button from '../../utils/Button'
import AGTable from '../../components/AGTable';
import GenericTable1 from '../../components/GenericTable1';
import { fetchTempRecord, fetchTempRecordOrder, fetchTempRecordOrderItem, fetchTempRecordPartItem, postConfirmProduction } from '../../redux-tookit/slices/tempProdRecordSlice';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
import { fetchBundles } from '../../redux-tookit/slices/loadingListSlice';
import { fetchPartItems } from '../../redux-tookit/slices/partItemsSlice';


const ProductionRecording = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const data = useSelector((state) => state.productionRecording.data);
  const planColumns = useSelector((state) => state.tempProdRecord.columns);
  const ogPlanData = useSelector((state) => state.tempProdRecord.labeledData);
  const [inputMode, setInputMode] = useState('fromList');
  const [loading, setLoading] = useState('loaded')

  const [selectedRowIndex1, setSelectedRowIndex1] = useState(null);
  const [selectedRowIndex2, setSelectedRowIndex2] = useState(null);
  const [selectedRowIndex3, setSelectedRowIndex3] = useState(null);
  const [selectedRowIndex, setSelectedRowIndex] = useState([]);
  const [selectedRowIndex4, setSelectedRowIndex4] = useState(null);
  const [elementMultiSelect, setElMultiSelect] = useState(false);
  const [tagMultiSelect, setTagMultiSelect] = useState(false);


  const [order, setOrder] = useState([]);
  const [orderItem, setOrderItem] = useState([]);
  const [partItem, setPartItem] = useState([]);



  const orderColumns = useSelector((state) => state.tempProdRecord.columns2);
  const orderData = useSelector((state) => state.tempProdRecord.labeledData2);


  const orderItemColumns = useSelector((state) => state.tempProdRecord.columns3);
  const orderItemData = useSelector((state) => state.tempProdRecord.labeledData3).filter((item) => item.noOfTags * 1 !== 0);


  const partItemColumns = useSelector((state) => state.partItems.columns);
  const partItemData = useSelector((state) => state.partItems.labeledData);



  const [bundleColumns, setBundleColumns] = useState([])
  const [bundleData, setBundleData] = useState([])




  const [scannedPartItem, setScannedPartItem] = useState([])

  const [scanValue, setScanValue] = useState('')

  const [prodRecordFilter, setProdRecordFilter] = useState('');


  const [planData, setPlanData] = useState(ogPlanData);

  const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  // Function to get today's date
  const getTodayDate = () => {
    return new Date();
  };

  // Function to get date one month back
  const getOneMonthBackDate = () => {
    const date = new Date();
    date.setMonth(date.getMonth() - 1);
    return date;
  };

  const [fromDateFilter, setFromDateFilter] = useState(formatDate(getOneMonthBackDate()));
  const [toDateFilter, setToDateFilter] = useState(formatDate(getTodayDate()));


  const applyFilters = () => {
    let filteredData = ogPlanData;

    console.log(prodRecordFilter, ogPlanData);
    if (prodRecordFilter) {
      if(prodRecordFilter === '0'){
        filteredData = filteredData.filter((item) =>
        `${item.productionStatus}` === `0`
      )
      } else {
        
          filteredData = filteredData.filter((item) =>
          `${item.productionStatus}` !== `0`
        )
      }
      
    }
    setPlanData(filteredData);
  };

  useEffect(() => {

    applyFilters();

  }, [prodRecordFilter, ogPlanData])


  const handlePlanSelection = (rowIndex) => {
    if (rowIndex !== null) {
      if (planData[rowIndex].productionStatus !== 0) {
        console.log(rowIndex);
        const orderIds = JSON.parse(`[${planData[rowIndex].orderId}]`)
        setSelectedRowIndex1(rowIndex)
        setSelectedRowIndex2(null)
        setSelectedRowIndex3(null)
        setSelectedRowIndex(null)
        setOrderItem([])
        setPartItem([])
        setBundleData([])
        setOrder(orderData.filter(item => orderIds.includes(item.orderId)));
        console.log(orderData);
      } else {
        setOrder([])
        setOrderItem([])
        setPartItem([])
        setBundleData([])
      }

    } else {
      setOrder([])
      setOrderItem([])
      setPartItem([])
      setBundleData([])
    }
  };

  const handleOrderSelection = (rowIndex) => {
    if (rowIndex !== null) {
      setOrderItem(orderItemData.filter(item => item.orderId * 1 === order[rowIndex].orderId * 1));
      dispatch(fetchPartItems(`?orderId=${order[rowIndex].orderId}&partitemStatus=Processing`)).then((res) => {
        if (res.payload) {
          if (res.payload.labeledData.length === 0) {
            toast.error('Production Completed for selected order')
            setOrderItem([])
            setBundleData([])
            setSelectedRowIndex2()
            return
          }

        }
      })
      setSelectedRowIndex2(rowIndex)

    } else {
      setSelectedRowIndex2(rowIndex)
      setOrderItem([])
    }
  };

  const handleOrderItemSelection = (rowIndex) => {
    if (rowIndex !== null) {
      if (elementMultiSelect === false) {
        setBundleData([])
        setPartItem(partItemData.filter(item => item.orderItemId * 1 === orderItem[rowIndex].orderItemId * 1));
      }

    } else {
      setPartItem([])
    }
    setSelectedRowIndex3(rowIndex)
  };

  const handlePartItemSelection = (rowIndex) => {
    if (rowIndex !== null) {
      if (tagMultiSelect === false) {
        dispatch(fetchBundles(`?partItemId=${partItem[rowIndex].partItemId}`))
          .then((res) => {
            console.log(res.payload);
            if (res.payload !== undefined) {
              setBundleColumns(res.payload.columns)
              setBundleData(res.payload.labeledData)
            }
          })
      } else {

      }


      setSelectedRowIndex(rowIndex);
    } else {
      setSelectedRowIndex(null);
    }
  };

  const handleBundleSelection = (rowIndex) => {
    if (rowIndex !== null) {
      setSelectedRowIndex4(rowIndex);
    }
  };

  const handleInputModeChange = (value) => {
    setInputMode(value)
    setSelectedRowIndex1(null)
    setSelectedRowIndex2(null)
    setSelectedRowIndex3(null)
    setSelectedRowIndex(null)
    setOrder([])
    setOrderItem([])
    setPartItem([])
  }

  const removeItems = (arr, indices) => {
    let newArray = [...arr];
    indices.sort((a, b) => b - a);
    indices.forEach(index => newArray.splice(index, 1));
    return newArray;
  }

  const handlePartItemDeletion = () => {
    if (selectedRowIndex !== null) {
      setPartItem(prev => removeItems(prev, selectedRowIndex))
    }
    setSelectedRowIndex([])
  }

  const handleScannerInput = (data) => {
    if (data) {
      try {
        const [extraData, ids] = data.split('SCANNER INPUT:');
        const [partItemId, bundleId, orderId] = ids.split(',')
        const partItemIds = partItem.map(item => item.partItemId)
        console.log(partItemId);
        if (!partItemIds.includes(1 * partItemId)) {
          setPartItem((prev) => {
            return [...prev, ...partItemData.filter(item => `${item.partItemId}` === partItemId)]
          })
        } else {
          toast.error('Part Item already added')
        }

        console.log(partItemData.filter(item => `${item.partItemId}` === partItemId));
      } catch (error) {

      }


    }
    // setScanValue(data)
  }

  const handleConfirmProduction = async () => {
    let bundleIds = []
    if (selectedRowIndex4.length !== 0) {
      selectedRowIndex4.forEach(index => {
        bundleIds = [...bundleIds, bundleData[index].bundleId]
      })
      console.log(bundleIds);
      const newRow = { bunldeId: bundleIds }
      setLoading('loading')
      dispatch(postConfirmProduction(newRow))
        .then(res => {
          setLoading('loaded')
          dispatch(fetchTempRecordOrder())
          dispatch(fetchTempRecordOrderItem())
          // dispatch(fetchTempRecordPartItem())
          setSelectedRowIndex1(null)
          setSelectedRowIndex2(null)
          setSelectedRowIndex3(null)
          setSelectedRowIndex(null)
          setOrder([])
          setOrderItem([])
          setPartItem([])
          setBundleData([])
        })
    }
  }



  useEffect(() => {

    // dispatch(fetchDetails()).then((res)=>{dispatch(fetchPlans(res.payload.json));dispatch(fetchOrders(res.payload.json))})
    // .then((res)=>dispatch(fetchOrders(res.payload.json)));

    dispatch(fetchTempRecordOrder())
    dispatch(fetchTempRecordOrderItem())

    // dispatch(fetchTempRecordPartItem())

  }, [dispatch]);

   useEffect(() => {

    dispatch(fetchTempRecord(`eDate=${toDateFilter}&sDate=${fromDateFilter}`))

  }, [dispatch, toDateFilter, fromDateFilter]);

  console.log('jhjhj', partItem);

  console.log(scannedPartItem);


  const handleEleMultiSelect = (value) => {
    setElMultiSelect(value)
    setTagMultiSelect(false)
    console.log(order[selectedRowIndex2]);

    if (value === true && order[selectedRowIndex2]) {
      dispatch(fetchBundles(`?productionStatus=0&orderId=${order[selectedRowIndex2].orderId}`))
        .then((res) => {
          console.log(res.payload);
          if (res.payload !== undefined) {
            setBundleColumns(res.payload.columns)
            setBundleData(res.payload.labeledData)
          }
        })
    }
  }

  const handleEleConfirm = () => {
    console.log('ELE CONFIRM');
    let eleIds = []
    let tagIds = []
    let bundleIds = []

    if (selectedRowIndex3.length !== 0 && bundleData.length !== 0) {
      selectedRowIndex3.forEach(rowIndex => {
        eleIds = [...eleIds, orderItem[rowIndex].orderItemId]
      })

      partItemData.forEach(data => {
        if (eleIds.includes(data.orderItemId)) {
          tagIds = [...tagIds, data.partItemId]
        }
      })

      bundleData.forEach(data => {
        if (tagIds.includes(data.partItemId))
          bundleIds = [...bundleIds, data.bundleId]
      })
      console.log(bundleIds, tagIds, eleIds);
      const newRow = { bunldeId: bundleIds }
      if (bundleIds.length !== 0) {
        setLoading('loading')
        dispatch(postConfirmProduction(newRow))
          .then(res => {
            setLoading('loaded')
            dispatch(fetchTempRecordOrder())
            dispatch(fetchTempRecordOrderItem())
            // dispatch(fetchTempRecordPartItem())

            setSelectedRowIndex1(null)
            setSelectedRowIndex2(null)
            setSelectedRowIndex3(null)
            setSelectedRowIndex(null)
            setOrder([])
            setOrderItem([])
            setPartItem([])
            // setBundleData([])
          })
      } else {
        toast.error('No bundles in the selected elements')
      }

    }
  }

  const handleTagMultiSelect = (value) => {
    setTagMultiSelect(value)
    if (value === true && order[selectedRowIndex2]) {
      dispatch(fetchBundles(`?productionStatus=0&orderId=${order[selectedRowIndex2].orderId}`))
        .then((res) => {
          console.log(res.payload);
          if (res.payload !== undefined) {
            setBundleColumns(res.payload.columns)
            setBundleData(res.payload.labeledData)
          }
        })
    }
  }

  const handleTagConfirm = () => {
    let tagIds = []


    let bundleIds = []
    if (selectedRowIndex.length !== 0) {



      selectedRowIndex.forEach((rowIndex) => {
        tagIds = [...tagIds, partItem[rowIndex].partItemId]
      })

      bundleData.forEach(data => {
        if (tagIds.includes(data.partItemId))
          bundleIds = [...bundleIds, data.bundleId]
      })

      console.log(bundleIds, tagIds, bundleData);
      const newRow = { bunldeId: bundleIds }
      if (bundleIds.length !== 0) {

        setLoading('loading')
        dispatch(postConfirmProduction(newRow))
          .then(res => {
            setLoading('loaded')
            dispatch(fetchTempRecordOrder())
            dispatch(fetchTempRecordOrderItem())
            // dispatch(fetchTempRecordPartItem())

            setSelectedRowIndex1(null)
            setSelectedRowIndex2(null)
            setSelectedRowIndex3(null)
            setSelectedRowIndex(null)
            setOrder([])
            setOrderItem([])
            setPartItem([])
            // setBundleData([])
          })

      } else {
        toast.error('No bundles in the selected Tags')
      }

    }
  }

  console.log(bundleData);




  return (
    <div className='sdbr'>
      {loading === 'loading' ? <Loader /> :
        <>
          <div className="head">Production Recording</div>
          <div className="filter-card">
            <div className="wOrder">


              <input
                type="radio"
                id='fromScanner'
                value="fromScanner"
                checked={inputMode === "fromScanner"}
                onChange={(e) => handleInputModeChange(e.target.value)}
              />
              <label htmlFor='fromScanner'>Add from scanner</label>


              <input
                type="radio"
                id='fromList'
                value="fromList"
                checked={inputMode === "fromList"}
                onChange={(e) => handleInputModeChange(e.target.value)}
              />
              <label htmlFor='fromList'>Add from list</label>

            </div>
          </div>


          {inputMode === 'fromList' ?
            <>

              <div className="filter-card">
                <div className="filter-layout" style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-start" }}>
                  <div className="filter" style={{ flexDirection: 'row', alignItems: 'center', gap: '5px', width: 'fit-content' }}>
                    <label htmlFor="planStatus">Production Status</label>
                    <select name="planStatus" id="planStatus" value={prodRecordFilter} style={{ width: 'fit-content' }} onChange={(e) => { setProdRecordFilter(e.target.value) }}>
                      <option value="">All</option>
                      <option value="0">Completed</option>
                      <option value="1">Incomplete</option>
                    </select>
                  </div>


                  <div className='DateCont'>
                    <label className='DateHead'>Order Delivery Date</label>
                    <div className="DateFilt">
                      <div className="filterD">
                        <label htmlFor="fromDateFilter">From</label>
                        <input
                          id="fromDateFilter"
                          type="date"
                          value={fromDateFilter}
                          onChange={(e) => setFromDateFilter(e.target.value)}
                        />
                      </div>
                      <div className="filterD">
                        <label htmlFor="toDateFilter">To</label>
                        <input
                          id="toDateFilter"
                          type="date"
                          value={toDateFilter}
                          onChange={(e) => setToDateFilter(e.target.value)}
                        />
                      </div>
                    </div>

                  </div>

                </div>
              </div>

              <div className="head">Plan Details</div>
              <AGTable columns={planColumns} data={planData} onSelectRow={handlePlanSelection} selectedRow={selectedRowIndex1} />

              <div className="head">Order Details</div>
              <AGTable columns={orderColumns} data={order} onSelectRow={handleOrderSelection} selectedRow={selectedRowIndex2} />

              <div className="head">Elements
                <div className="bottomMultiDel" style={{ fontSize: '15px', color: 'black', alignContent: 'flex-end' }}>
                  <input type="checkbox" id="elmultiSel"
                    checked={elementMultiSelect === true}
                    onClick={(e) => { handleEleMultiSelect(!elementMultiSelect) }} />
                  <label htmlFor="elmultiSel">Multiple Select</label>
                </div>
              </div>
              <AGTable columns={orderItemColumns} data={orderItem} onSelectRow={handleOrderItemSelection} selectedRow={selectedRowIndex3} rowSelection={`${elementMultiSelect === true ? 'multiple' : 'single'}`} />



              {elementMultiSelect === false ?
                <>
                  <div className="head">Tags
                    <div className="bottomMultiDel" style={{ fontSize: '15px', color: 'black', alignContent: 'flex-end' }}>
                      <input type="checkbox" id="tagmultiSel"
                        checked={tagMultiSelect === true}
                        onClick={(e) => { handleTagMultiSelect(!tagMultiSelect) }} />
                      <label htmlFor="tagmultiSel">Multiple Select</label>
                    </div>
                  </div>
                  <AGTable columns={partItemColumns} data={partItem} bbs={true} onSelectRow={handlePartItemSelection} selectedRow={selectedRowIndex} rowSelection={`${tagMultiSelect === true ? 'multiple' : 'single'}`} />
                </>
                :
                <div className="bottom-button-container">
                  <Button label='Confirm Production' className='green' onClick={handleEleConfirm} disabled={selectedRowIndex3} />
                </div>
              }

              {tagMultiSelect === true ?
                <div className="bottom-button-container">
                  <Button label='Confirm Production' className='green' onClick={handleTagConfirm} disabled={selectedRowIndex} />
                </div> : ''
              }



              {tagMultiSelect === false && elementMultiSelect === false ?
                <>
                  <div className="head">Bundles</div>
                  <AGTable columns={bundleColumns} data={bundleData} onSelectRow={handleBundleSelection} selectedRow={selectedRowIndex4} rowSelection={'multiple'} />



                  <div className="bottom-button-container">
                    <Button label='Confirm Production' className='green' onClick={handleConfirmProduction} disabled={selectedRowIndex4} />
                  </div>

                </> : ''}
            </>

            :

            <>
              <div className="filter-card">
                <div className="filter" style={{ flexDirection: 'column', width: 'fit-content' }}>

                  <label htmlFor="scannerInput">Scanner Input </label>
                  <input

                    type="text"
                    value={scanValue}
                    onChange={(e) => handleScannerInput(e.target.value)}
                    id='scannerInput'
                    autoFocus
                  />

                </div>
              </div>

              <div className="head">Tag List
                <div className="bottom-button-container">
                  <Button label="Remove selected Tag" className="blue" onClick={() => handlePartItemDeletion()} disabled={selectedRowIndex} />
                </div>
              </div>
              <AGTable columns={partItemColumns} data={partItem} onSelectRow={handlePartItemSelection} selectedRow={selectedRowIndex} rowSelection={'multiple'} />
              <div className="bottom-button-container">
                <Button label='Confirm Production' className='green' onClick={handleConfirmProduction} disabled={selectedRowIndex} />
              </div>
            </>
          }

        </>
      }
    </div>
  )
}

export default ProductionRecording