import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux';
import { logout } from '../redux-tookit/slices/loginSlice'
import { useNavigate } from 'react-router-dom';

const FalseLoginHandle = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate()

    useEffect(() => {

        const result = window.confirm('Do you want to go to the login page?');

        if (result) {

            handleLogout();
        } else {
            window.history.back();
        }



    }, []); // Run effect only once on component mount

    const handleLogout = () => {
        
        dispatch(logout());
        navigate('/')
        alert('Logged out...');
    }

    return (
        <div>

        </div>
    )
}

export default FalseLoginHandle
