import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../utils/Button';
import { postOrderItems, updateOrderItems } from '../../../redux-tookit/slices/orderItemsSlice';
import { toast } from 'react-toastify';

const AddElements = (props) => {
    const dispatch = useDispatch();
    const { isAdding, setIsAdding, isEditing, setIsEditing, editData, setEditData, orderId } = props
    const [newRow, setNewRow] = useState({});
    const columns = useSelector((state) => state.orderItems.columns);


    // const handleInputChange = (header, e) => {
    //     const updatedData = JSON.parse(JSON.stringify(editData));
    //     updatedData[header] = e.target.value;
    //     setEditData(updatedData);
    // }

    const handleSaveButtonClick = async (e) => {

        e.preventDefault();

        const newRow1 = { ...newRow, tagPrintStatus: "Not Printed", orderItemStatus: "processing", orderId: orderId * 1 }
        dispatch(postOrderItems({ ...newRow1 }));
        console.log(newRow1);
        setIsAdding(false);
        setNewRow({});

    };

    const onChangeHandle = (value, act) => {
        let match = false

        if (value.match(/^[1-9]{0,1}\d{0,3}$/)) match = true

        if (match === true) {
            if (act === 'add') {
                setNewRow({ ...newRow, noOfSets: value })
            } else setEditData({ ...editData, noOfSets: value })
        }

    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        if (editData.noOfSets === 0) {
            toast.error("No of Sets cannot be 0");
            return
        }
        let type = {}
        Object.keys(columns).filter(item => item !== 'orderItemId').map((column) => {
            type = { ...type, [column]: editData[column] }
        })
        const id = editData.orderItemId;
        console.log(type);

        dispatch(updateOrderItems({ id, type }))
        setIsEditing(false);

    }

    return (
        <div>
            <form onSubmit={handleSaveButtonClick}>{isAdding &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Add Element</div>
                            <div className="table-container">
                                {Object.keys(columns).map((column) => (
                                    column === 'orderItemName' ?
                                        (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                            <label>{columns[column].label}</label>
                                            <input
                                                key={column}
                                                type={columns[column].type}
                                                className='table-input'
                                                value={newRow[column] || ''}
                                                required={true}
                                                onChange={(e) => setNewRow({ ...newRow, [column]: e.target.value })}
                                            />
                                        </div>) :
                                        column === 'noOfSets' ?
                                            (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                <label>{columns[column].label}</label>
                                                <input
                                                    key={column}
                                                    type='number'
                                                    className='table-input'
                                                    min={1}
                                                    max={1000}
                                                    value={newRow[column] || ''}
                                                    required={true}
                                                    onKeyDown={(e) => { if (e.key === 'e') e.preventDefault() }}
                                                    onChange={(e) => { onChangeHandle(e.target.value, 'add') }}
                                                />
                                            </div>) : ""
                                ))}
                            </div>
                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => { setIsAdding(false); setNewRow({}) }} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>
            }
            </form>
            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container"><div className="head">Edit Element</div>
                            <div className="table-container">
                                {Object.keys(columns).map((key) => (
                                    columns[key].editable === true ?
                                        (key === 'orderItemName' ?
                                            (

                                                <div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                    <label>{columns[key].label}</label>
                                                    <input
                                                        type={columns[key].type}
                                                        className='table-input'
                                                        value={editData[key]}
                                                        required={true}
                                                        onChange={(e) => { setEditData({ ...editData, orderItemName: e.target.value }) }}
                                                    /></div>
                                            ) :

                                            key === 'noOfSets' ?
                                                (

                                                    <div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                                        <label>{columns[key].label}</label>
                                                        <input
                                                            type='number'
                                                            className='table-input'
                                                            value={editData[key]}
                                                            min={1}
                                                            max={1000}
                                                            required={true}
                                                            onKeyDown={(e) => { if (e.key === 'e') e.preventDefault() }}
                                                            onChange={(e) => setEditData({ ...editData, noOfSets: e.target.value })}
                                                        /></div>
                                                ) : "") : ""
                                ))}
                            </div>
                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>
            }
            </form>
        </div>
    )
}

export default AddElements
//End of File