import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import addnew from '../../../assets/images/button-icons/Vector.svg';
import edit from '../../../assets/images/button-icons/edit.svg';
import del from '../../../assets/images/button-icons/delete.svg';
import { deleteServiceCenterEquipment, fetchServiceCenterEquipments } from '../../../redux-tookit/slices/serviceCenterEquipmentsSlice';
import AGTable from '../../../components/AGTable';
import AddServiceCenterEquipments from './AddServiceCenterEquipments';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../../components/UserAccess/TopButtonContainer';



const ServiceCenterEquipments = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const permission = useState(location.state?.permission);
    const columns = useSelector((state) => state.serviceCenterEquipments.columns);
    const data = useSelector((state) => state.serviceCenterEquipments.labeledData);
    const [isAdding, setIsAdding] = useState(false);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [editData, setEditData] = useState([]);

    const handleRowSelect = (rowIndex) => {
        setSelectedRowIndex(rowIndex);
    };

    console.log(data)
    const handleAddButtonClick = () => {
        setIsAdding(true);
    };
    const handleEditButtonClick = () => {
        if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
            setIsEditing(true);
            const [d] = data[selectedRowIndex].dateOfEntry.split('T')
            setEditData({ ...data[selectedRowIndex], dateOfEntry: d });
        } else {
            toast.error("No rows selected, Please select a row and try again");
            setSelectedRowIndex(null);
        }
    };

    const handleDeleteButtonClick = async () => {
        if (selectedRowIndex !== null && selectedRowIndex !== undefined) {
            const id = data[selectedRowIndex].sceId;
            console.log(id);
            dispatch(deleteServiceCenterEquipment(id));
        }
        else toast.error("No rows selected, Please select a row and try again");
        setSelectedRowIndex(null);
    };

    useEffect(() => {
        dispatch(fetchServiceCenterEquipments());
    }, [dispatch, isAdding, isEditing]);

    return (
        <div className='sdbr'>
            <div className='head'>Service Center Equipments
                <div className="top-button-container">
                    {/* <img src={addnew} alt="add" onClick={handleAddButtonClick} />
                    <img src={edit} alt="edit" onClick={handleEditButtonClick}/>
                    <img src={del} alt="delete" onClick={handleDeleteButtonClick}/> */}
                    <TopButtonContainer add={handleAddButtonClick} edit={handleEditButtonClick} del={handleDeleteButtonClick} access={permission[0][0]} />

                </div>
            </div>

            <AGTable columns={columns} data={data} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} />
            {(isAdding || isEditing) &&
                <AddServiceCenterEquipments isAdding={isAdding} setIsAdding={setIsAdding} isEditing={isEditing} setIsEditing={setIsEditing} editData={editData} setEditData={setEditData} />
            }

        </div >
    );
};

export default ServiceCenterEquipments;