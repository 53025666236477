import React from 'react';
import * as FileSaver from 'file-saver';
import excel from '../assets/images/button-icons/excel.svg';
import * as XLSX from 'xlsx-color';
 
const ExcelDownloadButton = ({ csvData, fileName}) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    // const columnslabel=Object.keys(columns).map((keys)=>columns[keys].label);
    // console.log(columnslabel);
    const exportToCSV = () => {
       
        console.log(csvData)
        const arr=['soldToPartyId',
                    'billToPartyId',
                    'productionDate',
                    'diffFactor',
                    'shipToPartyId',
                    'customerPartiesId',
                    'customerSegmentId'
                ] ;
                let newdata=csvData.map((item)=>
                    {
                    return Object.keys(item).filter((objkey)=>!arr.includes(objkey)).reduce((newobj,key)=>{
                            newobj[key]=item[key];
                            return newobj
                        },{})
                    })
                    console.log(newdata)
                    const ws = XLSX.utils.json_to_sheet(newdata);
        var Heading = [
                ["SL.No", "Order Id", "Order Remarks","Sap Id",
                "Sold to Party",
                "Sold to Party address","Customer Expected Delivery Date",
                "Delivery Date","Production Weight","Inventory Weight","BBS Remarks",
                "BBS Status","Po Date","Order Type",
                "Total No of Tags","Bill to Party","Ship To Party","Address","Customer Segment Name",
                "CreatedTs"
                ]
        ];
       

       
        XLSX.utils.sheet_add_aoa(ws, Heading,{ origin: 'A1' });
       
        console.log(csvData);

        Heading[0].forEach((cell, index) => {
            ws[XLSX.utils.encode_cell(
                { r: 0, c: index })] = { v: cell, s: { 
                    fill: { fgColor: { rgb: '878bfa' }, 
                    patternType: 'solid' }, 
                    font: 
                    { color: { rgb: 'FFFFFFFF' }, 
                    bold: true ,
                    underline:true,
                    name: 'Calibri'
                    } 
                    } 
                };
        });

        

       
        
        const data1 = newdata.map(rowObj => 
            Object.values(rowObj)
        )
        const data2 = newdata.map(rowObj => 
            Object.keys(rowObj)
        )
        data1.push(Heading[0]);
        ws['!cols'] = fitToColumn(data1);
        
        function fitToColumn(data1) {
            return data1[0].map((a, i) => ({ wch: Math.max(...data1.map(a2 => a2[i] ? a2[i].toString().length : 0)) }));
        }
       

//        ws['!cols'] = wscols;
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, JSON.stringify(fileName) + fileExtension);
    }
 
    return (
        <img src={excel} alt="excel" onClick={exportToCSV} title='Download excel'/>
    );
};
 
export default ExcelDownloadButton;