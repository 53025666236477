import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import { toast } from 'react-toastify';

const initialState = {
  columns: {},
  labeledData: [],
  loading: 'idle',
  columns2: {},
  labeledData2: [],
  columns3: {},
  labeledData3: [],
  error: null,
};

export const fetchPricing = createAsyncThunk('data/fetchPricing', async () => {
  try {
    const response = await api.listPresales();
    const json = response.data;

    const columnLabels = {

      orderId: { label: "Order Id", type: 'number' },
      ortderItemId: { label: "Order Item Id", type: 'text' },
      partItemId: { label: "Part Item Id", type: 'text' },
      totalDelta: { label: "Total Delta", type: 'tel' },
      valueCreation: { label: "Value Creation", type: 'email' },
      recommendedDelta: { label: "Recommended Delta", type: 'text' }

    };

    const selectedColumns = ['orderId', 'totalDelta', 'valueCreation', 'recommendedDelta'];

    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});
export const runPresales = createAsyncThunk('data/runPresales', async (newRow) => {
  try {

    const response = await api.runPresales(newRow);
    const data = response.data;
    console.log('Success');
    return data;
  } catch (error) {
    throw error;
  }
});

export const fetchPricingMaster = createAsyncThunk('data/fetchPricingMaster', async () => {
  try {
    const response = await api.listPricingMaster();
    const json = response.data;
    console.log(json.rows);

    const columnLabels = {

      serviceCenterId: { label: "Service Center Id", type: 'number' },
      deltaForCutting: { label: "Delta for cutting", type: 'number' },
      deltaForBending: { label: "Delta for bending", type: 'number' },
      deltaForOffcut: { label: "Delta for offcut", type: 'number' },
      deltaForScrap: { label: "Delta for scrap", type: 'number' },
      costOfUsingCL: { label: "Cost of using CL", type: 'number' },
      labourSaving: { label: "Labour Saving", type: 'number' },
      timeSaving: { label: "Time Saving", type: 'number' },
      workingCapital: { label: "Working Capital", type: 'number' },
      
    };


    const selectedColumns = [
      'serviceCenterId',
      'deltaForCutting',
      'deltaForBending',
      'deltaForOffcut',
      'deltaForScrap',
      'costOfUsingCL',
      'labourSaving',
      'timeSaving',
      'workingCapital'
    ];


    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});

export const fetchOrgPricingMaster = createAsyncThunk('data/fetchOrgPricingMaster', async () => {
  try {
    const response = await api.listOrgMasterPrice();
    const json = response.data;
    console.log(json.rows);

    const columnLabels = {
      primeLengthPrice: { label: "Prime length price" }
    };

    const selectedColumns = ['primeLengthPrice'];

    const columns = {};
    selectedColumns.forEach((columnName) => {
      columns[columnName] = columnLabels[columnName];
    });

    const labeledData = json.rows.map((row) => {
      const rowData = {};
      selectedColumns.forEach((columnName) => {
        rowData[columnName] = row[columnName];
      });
      return rowData;
    });

    return { columns, labeledData };
  } catch (error) {
    throw error;
  }
});

//   {
//     "serviceCenterId": 1,
//     "deltaForCutting": 10,
//     "deltaForBending": 5,
//     "deltaForOffcut": 0.5,
//     "deltaForScrap": 0.25,
//     "costOfUsingCL": 1200,
//     "labourSaving": 3000,
//     "timeSaving": 1000,
//     "workingCapital": 300,
//     "createdBy": "Admin",
//     "createdTs": "2024-02-23T19:36:10.000Z",
//     "updatedBy": null,
//     "updatedTs": null
// }

export const updatePricingMaster = createAsyncThunk('data/updatePricingMaster', async ({ id, type }) => {
  try {
    console.log(type, id);
    const response = await api.updatePresalesMaster(id, type);
    const data = response.data;
    console.log('Update Success');
    return { data, id, type };
  } catch (error) {
    if(error.response.data.message)
		{toast.error(`${error.response.data.message}`)}
    throw error;
  }
});

export const updateOrgPricingMaster = createAsyncThunk('data/updatePricingMaster', async ({ id, type }) => {
  try {
    console.log(type, id);
    const response = await api.updateOrgPresalesMaster(id, type);
    const data = response.data;
    console.log('Update Success');
    return { data, id, type };
  } catch (error) {
    if(error.response.data.message)
		{toast.error(`${error.response.data.message}`)}
    throw error;
  }
});

const presalesSlice = createSlice({
  name: 'presales',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPricing.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(fetchPricing.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns = action.payload.columns;
        state.labeledData = action.payload.labeledData;
      })
      .addCase(fetchPricing.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })

      .addCase(runPresales.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(runPresales.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.labeledData.push(action.payload);

      })
      .addCase(runPresales.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchPricingMaster.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(fetchPricingMaster.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns2 = action.payload.columns;
        state.labeledData2 = action.payload.labeledData;
      })
      .addCase(fetchPricingMaster.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      .addCase(fetchOrgPricingMaster.pending, (state) => {
        state.loading = 'pending';

      })
      .addCase(fetchOrgPricingMaster.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.columns3 = action.payload.columns;
        state.labeledData3 = action.payload.labeledData;
      })
      .addCase(fetchOrgPricingMaster.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
  },
});

export default presalesSlice.reducer;