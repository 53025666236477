import React, { useState } from 'react';
import '../assets/styles/GenericTable.css';

const GenericTable = ({ columns, data, onSelectRow, selectedRow }) => {

  const[cellData,setCellData] = useState(null);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const handleInputChange = (index, header, event) => {
    const newData = [...data];
    newData[index][header] = event.target.value;
    setCellData(newData);
    setShouldSubmit(true);
  };

  
  
  return (
    <div className="table-container">
      <table className="generic-table">
        <thead className='stick'>
          <tr>
            <th key="select" className="table-header">
            </th>
            {columns.map((column) => (
              <th key={column.key} className="table-header">
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody >
          {data.map((row, rowIndex) => (
            <tr key={rowIndex} className='t-data'>
              <td>
                <input
                  type="radio"
                  name="rowSelect"
                  value={rowIndex}
                  checked={selectedRow === rowIndex}
                  onChange={() => onSelectRow(rowIndex)}
                  id={`radio${rowIndex}`}
                />
                <label htmlFor={`radio${rowIndex}`} className="custom-radio"></label>
              </td>
              {columns.map((column) => (
                column.editable===true&&selectedRow===rowIndex?
                <td key={column.key}>
                  <input type="text" value={row[column.key]} onChange={(event)=> handleInputChange(rowIndex, column.key ,event)} style={{ width: '50px' }} />
                </td>:
                <td key={column.key}>{row[column.key]}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
   </div>
  );
};

export default GenericTable;