import { BorderAll } from '@mui/icons-material';
import React, { useEffect, useRef, useState } from 'react'

import springShape from '../../assets/shapes/spring.jpg'
import up from '../../assets/images/icons/canvas/seek-top.svg'
import down from '../../assets/images/icons/canvas/seek-down.svg'
import left from '../../assets/images/icons/canvas/seek-left.svg'
import right from '../../assets/images/icons/canvas/seek-right.svg'
import dial from '../../assets/images/icons/canvas/seek-dial.svg'
import plus from '../../assets/images/icons/canvas/magnifying-glass-plus.svg'
import minus from '../../assets/images/icons/canvas/magnifying-glass-minus.svg'
import { useDispatch } from 'react-redux';
import { postShapes } from '../../redux-tookit/slices/shapeSlice';
import Button from '../../utils/Button';

const SpringUploadFile = ({ bbs = false, converter = false }) => {

    const canvasRef = useRef(null);
    const ctxRef = useRef(null);

    const dispatch = useDispatch();

    let originX = 120;
    let originY = 40;
    let mF = 0.4;
    const [fontSize, setFontSize] = useState(25)

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        // ctx.scale(1, 1);

        ctxRef.current = ctx;
        ctx.lineWidth = 5;
        ctx.font = `bold ${fontSize}px Aria`

        draw()
    }, []);

    console.log("canvas");

    const draw = () => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const img = new Image();
        img.src = springShape;
        img.onload = function () {

            //350 x 520 -> 2/5 -> 140 x 208

            ctx.clearRect(0, 0, 400, 300);
            ctx.drawImage(img, originX, originY, mF * 350, mF * 520);
            ctx.lineWidth = 5;
            ctx.font = `bold ${fontSize}px Arial`;
            ctx.fillStyle = 'Blue';
            ctx.fillText('A', originX - 20, originY + 520 * mF / 4 + (fontSize / 5 * 4));
            ctx.fillText('B', originX + 350 * mF / 2, originY + 520 * mF + (fontSize / 5 * 4));
            ctx.fillText('C', originX + 350 * mF, originY + 520 * mF / 4 + (fontSize / 5 * 4));

            console.log("876");
        };

        img.onerror = function () {
            console.error('Error loading image:', img.src);
            // Provide fallback behavior or display an error message
        };
    }



    const panButtons = (choice) => {
        if (choice === 1) {
            originY = (originY - 20)
        } else if (choice === 2) {
            originX = (originX - 20)
        } else if (choice === 3) {
            originX = (originX + 20)
        } else if (choice === 4) {
            originY = (originY + 20)
        } else if (choice === 5) {
            mF = (mF * 5 / 4)
        } else if (choice === 6) {
            mF = (mF * 4 / 5)
        }
        draw()
    }

    const handleShapeUpload = () => {
        const canvas = canvasRef.current;

        const dataURL = canvas.toDataURL('image/png');

        const properties = [
            { Geometry: "Spring", Match1: "A", Length1: "100" },
            { Geometry: "Spring", Match1: "B", Length1: "200" },
            { Geometry: "Spring", Match1: "C", Length1: "300" }
        ]
        // Geometry: 'helical spring', Length1:'100', Length2: '200', Length3: '300'
        const shapesObj = {
            shapeId: '086',
            category: 'Special',
            imageProperties: JSON.stringify(properties),
            image: dataURL,
            noOfBends: 0,
            magnifications: `${originX},${originY},${mF}`,
            threads: 0
        }

        dispatch(postShapes(shapesObj))
    }



    return (
        <div className="canvas-container">

            {/* <button onClick={ }>
                Upload
            </button> */}

            {/* <input type="file" onChange={(e) => handleFileUpload(e)} /> */}
            <div className="canvas-row" >
                <canvas
                    ref={canvasRef}
                    width="400" height="295"
                    style={{ border: '1px solid black' }}
                />
            </div>
            <div className="canvasDrawControls" style={{ marginLeft: (bbs === true ? '1.5vw' : '') }}>

                <div className="dial">
                    <img src={dial} alt="" className='dialImg' />
                    <div className="dial-buttons">
                        <div className="11"></div>
                        <div className="B" onClick={() => panButtons(1)}><img src={up} alt="" /></div>
                        <div className="13"></div>
                        <div className="B" onClick={() => panButtons(2)}><img src={left} alt="" /></div>
                        <div className="B" onClick={() => { }}>
                            {/* <img src={auto} alt="" /> */}
                        </div>
                        <div className="B" onClick={() => panButtons(3)}><img src={right} alt="" /></div>
                        <div className="31"></div>
                        <div className="B" onClick={() => panButtons(4)}><img src={down} alt="" /></div>
                        <div className="33"></div>
                    </div>
                </div>

                <div className="zoom-icon">
                    <div title='Zoom in' className="plus" onClick={() => panButtons(5)}>
                        <img src={plus} alt="" />
                    </div>
                    <div title='Zoom out' className="minus" onClick={() => panButtons(6)}>
                        <img src={minus} alt="" />
                    </div>
                </div>

                <div className="canvasDrawButtons" style={{ display: (bbs === true || converter === true ? 'none' : '') }}>

                    {/* <button title='Angle' className='circleButton B' onClick={() => addCurve(20, Math.PI / 2, 'Clockwise')} disabled={isAngleButtonDisabled}><img style={{ transform: 'rotateZ(180deg)' }} src={Angle} alt="" /></button> */}

                </div>
                <div>
                    <div className="" style={{ display: 'flex', flexDirection: 'column' }}>
                        <label htmlFor="fontSize">Font Size </label>
                        <input id="fontSize" type="number" value={fontSize} min={10} max={25} onChange={(e) => setFontSize(e.target.value)} style={{ border: '2px solid #d2d2d2', padding: '4px', borderRadius: '7px', width: '50px' }} />
                    </div>
                </div>

            </div>
            <Button label='Upload' className='blue' onClick={() => handleShapeUpload()} />
        </div>
    )
}

export default SpringUploadFile
