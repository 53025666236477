import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import * as api from '../../services/API';
import 'react-toastify/dist/ReactToastify.css'
 
const initialState = {
    result: [],
};
 
export const fetchCuttingSeq = createAsyncThunk('data/fetchCuttingSeq', async (id) => {
    try {
      // Fetch data using Axios
      const response = await api.listCuttingSeq(id);
      const result = response.data.rows;
     console.log(result);
      return { result};
     
    } catch (error) {
      throw error;
    }
  });

const cuttingSeqSlice = createSlice({
    name: 'cutSeq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchCuttingSeq.pending, (state) => {
                state.loading = 'pending';
                console.log('1')
            })
            .addCase(fetchCuttingSeq.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.matUsed=action.payload;
                console.log('2')
                console.log(action.payload)
            })
            .addCase(fetchCuttingSeq.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
                console.log('3')
                
            })
            
    },
});
 
export default cuttingSeqSlice.reducer;
//End of File