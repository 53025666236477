import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrderWiseProgressData } from '../../redux-tookit/slices/orderWiseProgressSlice';
import GenericTable from '../../components/GenericTable';
import AGTable from '../../components/AGTable';

const OrderWiseProgress = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.orderWiseProgress.columns);
  const originalData = useSelector((state) => state.orderWiseProgress.labeledData);
  const [data, setData] = useState(originalData);


  // State variables for filters
  const [customernameFilter, setcustomernameFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');

  // Extract unique values for each filter
  const customernameValues = [...new Set(originalData.map(item => item.customername))];

  const applyFilters = () => {
    // Apply filters to the data
    let filteredData = originalData;
    // Date range filtering logic
    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.orderDate);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }
    if (customernameFilter) {
      filteredData = filteredData.filter((item) =>
        item.customername.includes(customernameFilter)
      );
    }



    setData(filteredData);
  }



  useEffect(() => {
    // Fetch data when the component mounts
    dispatch(fetchOrderWiseProgressData());
  }, [dispatch]);

  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  useEffect(() => {
    // Apply filters whenever any of the filter values change
    applyFilters();
  }, [customernameFilter]);


  return (
    <div className='sdbr'>
      <div className='filter-card'>
        <div className="filter-OSH">
          <div className="filter">
            <label htmlFor="customernameFilter">customer name</label>
            <select
              id="customernameFilter"
              value={customernameFilter}
              onChange={(e) => setcustomernameFilter(e.target.value)}
            >
              <option value="">All</option>
              {customernameValues.map((value, index) => (
                <option key={index} value={value}>
                  {value}
                </option>
              ))}
            </select>
          </div>

          <div className='DateCont'>
            <label className='DateHead'>Production Plan Month</label>
            <div className="DateFilt">
              <div className="filterD">
                <label htmlFor="fromDateFilter">From</label>
                <input
                  id="fromDateFilter"
                  type="date"
                  value={fromDateFilter}
                  onChange={(e) => setFromDateFilter(e.target.value)}
                />
              </div>
              <div className="filterD">
                <label htmlFor="toDateFilter">To</label>
                <input
                  id="toDateFilter"
                  type="date"
                  value={toDateFilter}
                  onChange={(e) => setToDateFilter(e.target.value)}
                />
              </div>
            </div>
          </div>

        </div>
        

          
        </div>
        <div className='head'>Order Wise Progress</div>
        <div>
      </div>
      <AGTable columns={columns} data={data} />
    </div>
  );
};

export default OrderWiseProgress;