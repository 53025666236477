import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
 
import 'react-toastify/dist/ReactToastify.css'
import axios from 'axios';
import { toast } from 'react-toastify';
 
const initialState = {
    data:[],
    planColumns: {},
    plan: [],
    loading: 'idle',
    fetcher: true,
    error: null,
};


// export const fetchDetails = createAsyncThunk('data/fetchDetails', async () => {
//     const response = await axios.get('http://13.234.248.118:5300/masterPlan/list');
//     const json = response.data.rows;
//     return {json};
// });



export const fetchPlans =  createAsyncThunk('data/fetchPlans', async (data) => {
    try {
       
      const labeledData = data.map((row) => ({

            planId:row.PlanDetails[0].planId,
            totalScrapPercent:row.PlanDetails[0].totalScrapPercent,
            totalOffcutPercent:row.PlanDetails[0].totalOffcutPercent,
            totalCycles:row.PlanDetails[0].totalCycles,
            totalCuts:row.PlanDetails[0].totalCuts

      }));
      const columns = {
        planId: { label: "Plan ID"},
        totalScrapPercent: { label: "Total Scrap(%)"},
        totalOffcutPercent: { label: "Total Offcut(%)"},
        totalCycles: { label: "Total Cycles"},
        totalCuts: { label: "Total Cuts"},
        // Add more as needed
    };
 
      return { columns, labeledData };
       
    } catch (error) {
        if(error.response.data.message)
		{toast.error(`${error.response.data.message}`)}
        throw error;
    }
});



export const fetchOrders =  createAsyncThunk('data/fetchOrders', async (data) => {
    try {
        console.log(data);
      const labeledData = data.PlanDetails[0].Orders.map((row) => ({
            orderId:row.orderId,
            totalScrapPercent:row.PlanDetails[0].totalScrapPercent,
            totalOffcutPercent:row.PlanDetails[0].totalOffcutPercent,
            totalCycles:row.PlanDetails[0].totalCycles,
            totalCuts:row.PlanDetails[0].totalCuts
      }));
      const columns = {
        planId: { label: "Plan ID"},
        totalScrapPercent: { label: "Total Scrap(%)"},
        totalOffcutPercent: { label: "Total Offcut(%)"},
        totalCycles: { label: "Total Cycles"},
        totalCuts: { label: "Total Cuts"},
        // Add more as needed
    };
 
      return { columns, labeledData };
       
    } catch (error) {
        throw error;
    }
});

const productionRecordingSlice = createSlice({
    name: 'productionRecording',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
 
            // .addCase(fetchDetails.fulfilled, (state, action) => {
            //     state.loading = 'fulfilled';
            //     state.data = action.payload.json;
            // })
            // .addCase(fetchDetails.rejected, (state, action) => {
            //     state.loading = 'rejected';
            //     state.error = action.error.message;
            // })
            .addCase(fetchPlans.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.planColumns=action.payload.columns;
                state.plan = action.payload.labeledData;
            })
            .addCase(fetchPlans.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
            .addCase(fetchOrders.fulfilled, (state, action) => {
                state.loading = 'fulfilled';
                state.columns = action.payload.columns
                state.labeledData = action.payload.labeledData
            })
            .addCase(fetchOrders.rejected, (state, action) => {
                state.loading = 'rejected';
                state.error = action.error.message;
            })
           
    },
});
 
export default productionRecordingSlice.reducer;