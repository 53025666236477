import React, { useEffect, useState } from 'react'
import Button from '../../../utils/Button'
import '../../../assets/styles/TableComponent.css'
import CustomBundleTable from './CustomBundleTable';

const CustomInput = (props) => {
    const { columns = {}, data = [], setIsAdding, setSelectedBundleIds, setSelectedBundleData } = props

    const [insideData, setInsideData] = useState(data);

    const [selectedRowIndex, setSelectedRowIndex] = useState(null)

    const columnLabels = {

        combId: { label: "Order Id" },
        // partItemId: { label: "Part Item Id " },
        orderItemName: {label: "Element Name"},
        noOfPieces: { label: "No of Pieces" },
        weight: { label: "Weight" }

    }

    const handleAddButtonClick = () => {
        const selBundleIds = []
        selectedRowIndex.forEach((rowIndex) => {
            selBundleIds.push(insideData[rowIndex].bundleId)
        })
        let finalIds = []
        setSelectedBundleIds((prev) => {
            finalIds = [...prev, ...selBundleIds]
            return [...prev, ...selBundleIds]
        })
        setIsAdding(false)

        setSelectedBundleData( prev => {
            return [
                ...prev.filter(item => finalIds.includes(item.bundleId)),
                ...data.filter(item => finalIds.includes(item.bundleId))
            ]
        }
            
        )
    }

    const columnKeys = Object.keys(columnLabels);

    
    console.log(insideData, columnLabels);


    const handleRowSelect = (rowIndex) => {
        if (rowIndex !== undefined) {

            setSelectedRowIndex(rowIndex);

        } else setSelectedRowIndex(null)
    };

    useEffect(() => {
        setInsideData(data)
    }, [data])

    return (<div>
        <form>
            <div className="AddContainer" >
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: 'fit-content', backgroundColor: '#fff', borderRadius: '10px', paddingBottom: '1vw' }}>
                    <CustomBundleTable data={insideData} columns={columnLabels} onSelectRow={handleRowSelect} selectedRow={selectedRowIndex} rowSelection='multiple' />
                    <div className="bottom-button-container">
                        <Button label='Add' className='blue' onClick={() => handleAddButtonClick()} disabled={selectedRowIndex} />
                        <Button label='Close' className='blue' onClick={() => setIsAdding(false)} />
                    </div>
                </div>
            </div>
            <div className="bg-dark"></div>
        </form>
    </div>
    )
}

export default CustomInput
