// loginSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const getNavSelOption = () => {
    const navSelOpt = sessionStorage.getItem('navSelectedOption');
    return (navSelOpt)
}

const getNavExpOption = () => {
    const navExpOpt = sessionStorage.getItem('navExpandedOption');
    return (navExpOpt)
}

const initialState = {

    navSelectedOption: getNavSelOption(),
    navExpandedOption: getNavExpOption()
};

export const getNavOptions = createAsyncThunk('data/setNavOptions', async () => {
    try {
        console.log('ooo');
        const expOpt = sessionStorage.getItem('navExpandedOption')
        const selOpt = sessionStorage.getItem('navSelectedOption')
        console.log('eqr',expOpt,selOpt);
        return {expOpt,selOpt};
    } catch (error) {
        throw error;
    }
});

export const setNavOptions = createAsyncThunk('data/setNavOptions', async (options) => {
    try {
        const expOpt = options.expOpt;
        const selOpt = options.selOpt;

        if (expOpt !== '') {
            sessionStorage.setItem('navExpandedOption', expOpt)
        }
        if (selOpt!==''){
            sessionStorage.setItem('navSelectedOption',selOpt)
        }
        return {expOpt,selOpt};
    } catch (error) {
        throw error;
    }
});



const navbarSlice = createSlice({
    name: 'navbar',
    initialState,
    reducers: {},
    extraReducer: (builder) => {
        builder
        .addCase(setNavOptions.fulfilled, (state, action) => {
            if(action.selOpt!=='')
            state.navSelectedOption = action.selOpt
        if( action.expOpt!=='')
            state.navExpandedOption = action.expOpt
          })
          .addCase(getNavOptions.fulfilled, (state, action) => {
            state.navSelectedOption = action.selOpt
            state.navExpandedOption = action.expOpt
          })
    }
});

export default navbarSlice.reducer;
//End of File