import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import GenericTable from '../../components/GenericTable';
import edit from '../../assets/images/button-icons/edit.svg';
import addnew from '../../assets/images/button-icons/Vector.svg';
import '../../assets/styles/Costing.css'
import { fetchOrgPricingMaster, fetchPricingMaster, updateOrgPricingMaster, updatePricingMaster } from '../../redux-tookit/slices/presalesSlice';
import AGTable from '../../components/AGTable';
import Button from '../../utils/Button';
import { toast } from 'react-toastify';
import { useLocation } from 'react-router-dom';
import TopButtonContainer from '../../components/UserAccess/TopButtonContainer';



const PresalesMaster = () => {
    const dispatch = useDispatch();
    const location = useLocation()
    const permission = useState(location.state?.permission);
    const columns = useSelector((state) => state.presales.columns2);
    //   const columns2 = useSelector((state) => state.presales.columns); 
    const data = useSelector((state) => state.presales.labeledData2);
    //   const originalData2 = useSelector((state) => state.presales.data);
    //   const [data2, setData2] = useState(originalData2);
    const [selectedRowIndex, setSelectedRowIndex] = useState(null);

    const orgMasterData = useSelector(state => state.presales.labeledData3)
    const orgmasterColumns = useSelector(state => state.presales.columns3)

    const [isEditing1, setIsEditing1] = useState(false)
    const [isEditing2, setIsEditing2] = useState(false)
    const roleName = sessionStorage.getItem('roleName')


    const handleRowSelect = (rowIndex) => {
        setSelectedRowIndex(rowIndex);
    };

    const handleEdit1 = () => {

    }

    useEffect(() => {

        dispatch(fetchPricingMaster());
        dispatch(fetchOrgPricingMaster())

    }, [dispatch]);


    return (
        <div>
            <div className="sdbr">
                <div className="head" >Pricing Master
                    <div className="top-button-container" >
                        {/* <img src={edit} alt="edit" onClick={() => setIsEditing2(true)} /> */}
                        <TopButtonContainer edit={() => setIsEditing2(true)} access={permission[0][0]} accessOverride={roleName === 'superadmin' ? [0, 1, 0] : [0, 0, 0]} />

                    </div>
                </div>

                <div style={{ width: 'fit-content', boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)', borderRadius: '10px', overflow: 'hidden' }}>
                    <PreSalesTable columns={orgmasterColumns} data={orgMasterData} />
                </div>
                <div className="head" style={{ justifyContent: "right" }}>
                    <div className="top-button-container">
                        {/* <img src={edit} alt="edit" onClick={() => setIsEditing1(true)} /> */}
                        <TopButtonContainer edit={() => setIsEditing1(true)} access={permission[0][0]} accessOverride={[0, 1, 0]} />

                    </div>
                </div>

                <div className="table-container" >
                    <PreSalesTable columns={columns} data={data} />
                </div>


                {isEditing1 &&
                    <EditPopup columns={columns} data={data[0]} isEditing={isEditing1} setIsEditing={setIsEditing1} editType='serviceCenter' />
                }
                {isEditing2 &&
                    <EditPopup columns={orgmasterColumns} data={orgMasterData[0]} isEditing={isEditing2} setIsEditing={setIsEditing2} editType='organisation' />
                }
            </div>
        </div>
    )
}

const PreSalesTable = (props) => {
    const { columns, data } = props;
    return (
        <table className='generic-table' >
            <thead >
                <tr className='table-header'>
                    {Object.keys(columns).map((data) => (
                        <th>{columns[data].label}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {data.map((data) => (
                    <tr>
                        {Object.keys(columns).map((key) => (
                            <td>
                                {data[key]}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
        </table>
    )
}

const EditPopup = (props) => {
    const dispatch = useDispatch()
    const { columns, data, isEditing, setIsEditing, editType } = props
    const [editData, setEditData] = useState(data)
    const handleInputChange = (key, value) => {
        setEditData({ ...editData, [key]: value })
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        const id = 1
        const type = editData;
        console.log(type, id);
        editType === 'serviceCenter' ?
            dispatch(updatePricingMaster({ id, type })).then((res) => {
                if (res.payload !== undefined) {
                    toast.success(`${res.payload.data.message}`)
                    dispatch(fetchPricingMaster())
                }
            }) :
            dispatch(updateOrgPricingMaster({ id, type })).then((res) => {
                if (res.payload !== undefined) {
                    toast.success(`${res.payload.data.message}`)
                    dispatch(fetchOrgPricingMaster())
                }
            })
        setIsEditing(false);
    }

    return (
        <div>
            <form onSubmit={handleUpdate}>{isEditing &&
                <div>
                    <div className="AddContainer" >
                        <div className="form-container" style={{ width: '40%' }}><div className="head">Edit Pricing Master</div>
                            <div className="table-container" style={{ maxHeight: '500px', overflow: 'auto' }}>
                                {Object.keys(columns).map((key) => (key !== 'serviceCenterId' ?
                                    (<div className='filter' style={{ display: 'flex', flexDirection: 'column', gap: '4px', width: '10vw' }}>
                                        <label>
                                            {columns[key].label}
                                            {/* {columns[key].isRequired && <span style={{ color: 'red' }}>*</span>} */}
                                        </label>
                                        <input
                                            type='number'
                                            className='table-input'
                                            min={0}
                                            step="any"
                                            value={editData[key]}
                                            onChange={(e) => handleInputChange(key, e.target.value)}
                                        /></div>) : "")
                                )
                                }
                            </div>
                            <div className="bottom-button-container">
                                <Button type='submit' label='Save' className='blue' />
                                <Button onClick={() => setIsEditing(false)} label='Cancel' className='blue' />
                            </div>
                        </div>
                    </div>
                    <div className="bg-dark"></div>
                </div>
            }
            </form>
        </div>
    )
}

export default PresalesMaster