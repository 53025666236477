import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchOrderStatusHistoryData } from '../../redux-tookit/slices/orderStatusHistorySlice';
import GenericTable from '../../components/GenericTable';
import '../../assets/styles/ReportPage.css'
import AGTable from '../../components/AGTable';

const OrderStatusHistory = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.orderStatusHistory.columns);
  const originalData = useSelector((state) => state.orderStatusHistory.labeledData);
  const [data, setData] = useState(originalData);

 
  // State variables for filters
  const [orderidFilter, setorderidFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');
  

   // Extract unique values for each filter
   const orderidValues = [...new Set(originalData.map(item => item.orderid))];
  

  const applyFilters = () => {
    // Apply filters to the data
    let filteredData = originalData;
     // Date range filtering logic
     if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.orderDate);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }
    if (orderidFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderid.includes(orderidFilter)
      );
    }
    
    
    setData(filteredData);
  }


  useEffect(() => {
    // Fetch data when the component mounts
    dispatch(fetchOrderStatusHistoryData());
  }, [dispatch]);

  useEffect(() => {
    setData(originalData);
  }, [originalData]);

  useEffect(() => {
    // Apply filters whenever any of the filter values change
    applyFilters();
  }, [orderidFilter]);


  return (
    <div className='sdbr'>
      <div className='filter-card'>
<div className="filter-OSH">
      <div className="filter">
          <label htmlFor="orderidFilter">Order Id</label>
          <select
            id="orderidFilter"
            value={orderidFilter}
            onChange={(e) => setorderidFilter(e.target.value)}
          >
            <option value="">All</option>
            {orderidValues.map((value, index) => (
              <option key={index} value={value}>
                {value}
              </option>
            ))}
          </select>
        </div>
      <div className="filterD">
      
          <label htmlFor="fromDateFilter">From </label>
          <input
            id="fromDateFilter"
            type="date"
            value={fromDateFilter}
            onChange={(e) => setFromDateFilter(e.target.value)}
          />
        </div>
        <div className="filterD">
          <label htmlFor="toDateFilter">To </label>
          <input
            id="toDateFilter"
            type="date"
            value={toDateFilter}
            onChange={(e) => setToDateFilter(e.target.value)}
          />
        </div>
</div>
      
      </div>
      <div className='head'>Order Status History</div>
      <div>
         
      <AGTable columns={columns} data={data}  />
      </div>
      
    </div>
  );
};

export default OrderStatusHistory;