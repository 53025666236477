// import React, { useEffect, useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
// import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
// import TableComponent from '../../components/TableComponent';
// import '../../assets/styles/ReportPage.css'

// const DiaWiseBalanceOrder = () => {
//   const dispatch = useDispatch();
//   const columns = useSelector((state) => state.sdbr.DiaWiseBalanceOrder.columns);
//   const originalData = useSelector((state) => state.sdbr.DiaWiseBalanceOrder.data);
//   const [data, setData] = useState(originalData);

//   const [fromDateFilter, setFromDateFilter] = useState('');
//   const [toDateFilter, setToDateFilter] = useState('');
//   const [customernameFilter, setcustomernameFilter] = useState('');
//   const [orderdescriptionFilter, setorderdescriptionFilter] = useState('');
//   const [saporderidFilter, setsaporderidFilter] = useState('');

//   const customernameValues = [...new Set(originalData.map(item => item.customername))];
//   const orderdescriptionValues = [...new Set(originalData.map(item => item.orderdescription))];
//   const saporderidValues = [...new Set(originalData.map(item => item.saporderid))];

//   const applyFilters = () => {
//     let filteredData = originalData;

//     if (fromDateFilter && toDateFilter) {
//       const fromDate = new Date(fromDateFilter);
//       const toDate = new Date(toDateFilter);

//       filteredData = filteredData.filter((item) => {
//         const orderDate = new Date(item.orderDate);
//         return orderDate >= fromDate && orderDate <= toDate;
//       });
//     }
//     if (customernameFilter) {
//       filteredData = filteredData.filter((item) =>
//         item.customername.includes(customernameFilter)
//       );
//     }
//     if (orderdescriptionFilter) {
//       filteredData = filteredData.filter((item) =>
//         item.orderdescription.includes(orderdescriptionFilter)
//       );
//     }
//     if (saporderidFilter) {
//       filteredData = filteredData.filter((item) =>
//         item.saporderid.includes(saporderidFilter)
//       );
//     }

//     setData(filteredData);
//   }

//   useEffect(() => {
//     dispatch(fetchDataFromApi('DiaWiseBalanceOrder'));
//     setData(originalData);
//   }, [dispatch, originalData]);

//   useEffect(() => {

//     applyFilters();
//   }, [customernameFilter, orderdescriptionFilter, saporderidFilter]);

//   return (
//     <div className='sdbr'>
//       <div className='filter-card'>
//         <div className="filter-layoutRP">
//           <div className="filter">
//             <label htmlFor="customernameFilter">customer name</label>
//             <select
//               id="customernameFilter"
//               value={customernameFilter}
//               onChange={(e) => setcustomernameFilter(e.target.value)}
//             >
//               <option value="">All</option>
//               {customernameValues.map((value, index) => (
//                 <option key={index} value={value}>
//                   {value}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div className='DateCont'>
//             <label className='DateHead'>Production Plan Month</label>
//             <div className="DateFilt">
//               <div className="filterD">

//                 <label htmlFor="fromDateFilter">From </label>
//                 <input
//                   id="fromDateFilter"
//                   type="date"
//                   value={fromDateFilter}
//                   onChange={(e) => setFromDateFilter(e.target.value)}
//                 />
//               </div>
//               <div className="filterD">
//                 <label htmlFor="toDateFilter">To </label>
//                 <input
//                   id="toDateFilter"
//                   type="date"
//                   value={toDateFilter}
//                   onChange={(e) => setToDateFilter(e.target.value)}
//                 />
//               </div>
//             </div>
//           </div>

//           <div className="filter">
//             <label htmlFor="orderdescriptionFilter">Order Description</label>
//             <select
//               id="orderdescriptionFilter"
//               value={orderdescriptionFilter}
//               onChange={(e) => setorderdescriptionFilter(e.target.value)}
//             >
//               <option value="">All</option>
//               {orderdescriptionValues.map((value, index) => (
//                 <option key={index} value={value}>
//                   {value}
//                 </option>
//               ))}
//             </select>
//           </div>

//           <div className="filter">
//             <label htmlFor="saporderidFilter">SAP Order Id</label>
//             <select
//               id="saporderidFilter"
//               value={saporderidFilter}
//               onChange={(e) => setsaporderidFilter(e.target.value)}
//             >
//               <option value="">All</option>
//               {saporderidValues.map((value, index) => (
//                 <option key={index} value={value}>
//                   {value}
//                 </option>
//               ))}
//             </select>
//           </div>
//         </div>
//       </div>
//       <div className='head'>Dia Wise Balance Order</div>
//       <div>

//         <TableComponent columns={columns} data={data} />

//       </div>

//     </div>
//   );
// };

// export default DiaWiseBalanceOrder
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/styles/Summary.css";
import { Bar } from "react-chartjs-2";
import { toast } from "react-toastify";
import * as api from "../../services/API";
import { AgGridReact } from "ag-grid-react";
import { fetchDiaWiseOrderQty } from "../../redux-tookit/slices/diaWiseQuantitySlice";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";

const DiaWiseBalanceOrder = () => {
  const [xaxisDiawiseDiameter, setxaxisDiawiseDiameter] = useState([]);
  const [yaxisDiawiseorderQuantity, setyaxisDiawiseorderQuantity] = useState(
    []
  );
  const [yaxisDiawisedeliveredQuantity, setyaxisDiawisedeliveredQuantity] =
    useState([]);
  const [yaxisDiawisebalanceQuantity, setyaxisDiawisebalanceQuantity] =
    useState([]);
  const prodDiawiseorderqty = useSelector(
    (state) => state.diawiseQuantity.labeledData
  );
  const [region, setRegion] = useState("All");

  const [dailyWiseData, setDailyWiseData] = useState([]);
  const [serviceCenterListData, setServiceCenterListData] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [
    regionWiseSeviceCenterDataForDailyWise,
    setRegionWiseSeviceCenterDataForDailyWise,
  ] = useState([]);
  const dispatch = useDispatch();
  const [dailyWiseName, setDailyWiseName] = useState("All");
  const regions = ["North", "South", "East", "West"];
  const roleName = localStorage.getItem("roleName");
  const regionName = localStorage.getItem("region");
  const getServiceCenterList = async () => {
    await api
      .listServiceCenter()
      .then((result) => {
        setServiceCenterListData(result?.data?.rows);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getServiceCenterList();
  }, []);
  useEffect(() => {
    if (region !== "All") {
      getDailyWiseByRegions();
      // setDailyWiseName("All");
    }
  }, [region, dailyWiseName, prodDiawiseorderqty, startDate, endDate]);
  const getDailyWiseByRegions = async () => {
    let regionWise = "";
    if (roleName === "superadmin" && region !== "All") {
      regionWise = `?region=${region}`;
    } else {
      regionWise = `?region=${regionName}`;
    }
    await api
      .listServiceCenterByRegion(regionWise)
      .then((result) => {
        setRegionWiseSeviceCenterDataForDailyWise(result?.data?.rows);
        // setDailyWiseName("All");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const serviceData = regionWiseSeviceCenterDataForDailyWise.filter(
      (item) => item.name === dailyWiseName
    );
    const serviceDataForAllRegionsByName = serviceCenterListData.filter(
      (item) => item.name === dailyWiseName
    );

    if (serviceData.length > 0 || serviceDataForAllRegionsByName.length > 0) {
      getDailyWiseQuantityByServiceId(
        serviceData[0]?.serviceCenterId ||
          serviceDataForAllRegionsByName[0]?.serviceCenterId
      );
    }
    if (serviceDataForAllRegionsByName.length > 0) {
      getDailyWiseQuantityByServiceId(
        serviceDataForAllRegionsByName[0]?.serviceCenterId
      );
    }
    if (region === "All" && dailyWiseName === "All") {
      getDailyWiseQuantityByServiceId();
    }
    if (region !== "All" && dailyWiseName === "All") {
      getDailyWiseQuantityByServiceId();
    }
  }, [dailyWiseName, region, serviceCenterListData]);
  console.log(dailyWiseName, "dailyWiseName");
  const getDailyWiseQuantityByServiceId = async (serviceCenterId) => {
    let queryString = "";
    if (region !== "All") {
      queryString += `?region=${region}&`;
    }
    if (serviceCenterId) {
      queryString += `${
        region === "All"
          ? `?serviceCenterId=${serviceCenterId}&`
          : `serviceCenterId=${serviceCenterId}&`
      }`;
    }

    await api
      .listDiaWiseQuantityByRegion(queryString)
      .then((result) => {
        if (result?.data?.rows.length > 0) {
          setDailyWiseData(result?.data?.rows);
          console.log(result?.data?.rows, "result?.data?.rows");
        } else {
          setDailyWiseData([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    const diameter =
      region !== ""
        ? dailyWiseData.map((item) => item.diameter)
        : prodDiawiseorderqty.map((item) => item.diameter);

    const orderQuantity =
      region !== ""
        ? dailyWiseData.map((item) => item.orderQuantity)
        : prodDiawiseorderqty.map((item) => item.orderQuantity);

    const deliveredQuantity =
      region !== ""
        ? dailyWiseData.map((item) => item.deliveredQuantity)
        : prodDiawiseorderqty.map((item) => item.deliveredQuantity);

    const balanceQuantity =
      region !== ""
        ? dailyWiseData.map((item) => item.balanceQuantity)
        : prodDiawiseorderqty.map((item) => item.balanceQuantity);

    setxaxisDiawiseDiameter(diameter);
    setyaxisDiawiseorderQuantity(orderQuantity);
    setyaxisDiawisedeliveredQuantity(deliveredQuantity);
    setyaxisDiawisebalanceQuantity(balanceQuantity);
  }, [prodDiawiseorderqty, dailyWiseData, region]);

  useEffect(() => {
    dispatch(fetchDiaWiseOrderQty());
  }, [dispatch]);

  const diawisedataset = {
    labels: [...new Set(xaxisDiawiseDiameter)].sort((a, b) => a - b),
    datasets: [
      {
        label: "OrderQuantity",
        data: yaxisDiawiseorderQuantity,
        borderColor: "yellow",
        backgroundColor: "yellow",
        pointBorderColor: "yellow",
        borderWidth: 2,
        pointBackgroundColor: "yellow",
        barThickness: 19,
      },
      {
        label: "DeliveredQuantity",
        data: yaxisDiawisedeliveredQuantity,
        borderColor: "green",
        backgroundColor: "green",
        pointBorderColor: "green",
        borderWidth: 2,
        pointBackgroundColor: "green",
        barThickness: 20,
      },
      {
        label: "BalanceQuantity",
        data: yaxisDiawisebalanceQuantity,
        borderColor: "orange",
        backgroundColor: "orange",
        pointBorderColor: "orange",
        borderWidth: 2,
        pointBackgroundColor: "orange",
        barThickness: 20,
      },
    ],
  };

  const diawiseoptions = {
    interaction: {
      intersect: false,
      mode: "index",
    },
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        fontSize: 50,
        display: false,
        font: { size: 20 },
      },
      datalabels: {
        display: false,
        anchor: "end",
        align: "bottom",
        color: "black",
        formatter: (value) => value,
        font: {
          size: 1,
        },
      },
      legend: {
        display: true,
        position: "top",
      },
    },
    scales: {
      x: {
        grid: { display: true },
        title: {
          display: true,
          text: "Diameter (mm)",
          color: "grey",
          font: { size: 16, weight: "bold" },
        },
      },
      y: {
        grid: { display: true },
        title: {
          display: true,
          text: "Quantity (T)",
          color: "grey",
          font: { size: 14, weight: "bold" },
        },
      },
    },
  };
  return (
    <div className="sdbr">
      <div className="head">Production History</div>

      <div className="head">Dia Wise quantity</div>
      {roleName !== "admin" && (
        <div
          className="filter-card"
          style={{
            margin: "20px 10px",
            padding: "20px 20px",
            // display: "flex",
            // flexDirection: "row",
          }}
        >
          <div className="filter" style={{ alignItems: "center", gap: "10px" }}>
            {roleName !== "salesManager" && roleName !== "admin" ? (
              <>
                {roleName === "superadmin" ? (
                  <>
                    {" "}
                    <label
                      htmlFor="dia-total-gradeFil"
                      style={{ color: "black" }}
                    >
                      Regions
                    </label>
                    <select
                      name="grade"
                      id="dia-total-gradeFil"
                      style={{ width: "fit-content" }}
                      onChange={(e) => setRegion(e.target.value)}
                    >
                      <option value="All">All</option>
                      {regions.map((region, index) => (
                        <option key={index} value={region}>
                          {region}
                        </option>
                      ))}
                    </select>
                  </>
                ) : (
                  <label
                    htmlFor="dia-total-gradeFil"
                    style={{ color: "black" }}
                  >
                    Region: <></>
                    {regionName.charAt(0).toUpperCase() + regionName.slice(1)}
                  </label>
                )}
              </>
            ) : (
              ""
            )}

            {roleName !== "admin" && (
              <div
                className="filter"
                style={{ alignItems: "center", gap: "10px" }}
              >
                <label htmlFor="dia-total-gradeFil" style={{ color: "black" }}>
                  Service Center :
                </label>
                <select
                  value={dailyWiseName}
                  name="grade"
                  id="dia-total-gradeFil"
                  style={{ width: "fit-content" }}
                  onChange={(e) => setDailyWiseName(e.target.value)}
                >
                  <option value="All">All</option>
                  {[
                    ...new Set(
                      region === "All"
                        ? serviceCenterListData.map((item) => item.name)
                        : regionWiseSeviceCenterDataForDailyWise.map(
                            (item) => item.name
                          )
                    ),
                  ].map((data) => (
                    <option value={data}>{data}</option>
                  ))}
                </select>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="chart-box">
        <Bar
          data={diawisedataset}
          options={diawiseoptions}
          height={400}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        />
      </div>
    </div>
  );
};

export default DiaWiseBalanceOrder;
