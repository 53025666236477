import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchDataFromApi } from '../../redux-tookit/slices/sdbrSlice';
import TableComponent from '../../components/TableComponent';

const LengthWiseProdQty = () => {
  const dispatch = useDispatch();
  const columns = useSelector((state) => state.sdbr.LengthWiseProdQty.columns);
  const originalData = useSelector((state) => state.sdbr.LengthWiseProdQty.data);
  const [data, setData] = useState(originalData);

  const [customerFilter, setcustomerFilter] = useState('');
  const [saporderidFilter, setsaporderidFilter] = useState('');
  const [diaFilter, setdiaFilter] = useState('');
  const [orderstatusFilter, setorderstatusFilter] = useState('');
  const [ordernumberFilter, setordernumberFilter] = useState('');
  const [fromDateFilter, setFromDateFilter] = useState('');
  const [toDateFilter, setToDateFilter] = useState('');

  const customerValues = [...new Set(originalData.map(item => item.customer))];
  const saporderidValues = [...new Set(originalData.map(item => item.saporderid))];
  const diaValues = [...new Set(originalData.map(item => item.dia))];
  const orderstatusValues = [...new Set(originalData.map(item => item.orderstatus))];
  const ordernumberValues = [...new Set(originalData.map(item => item.ordernumber))];

  const applyFilters = () => {
    let filteredData = originalData;

    if (customerFilter) {
      filteredData = filteredData.filter((item) =>
        item.customer.includes(customerFilter)
      );
    }
    if (saporderidFilter) {
      filteredData = filteredData.filter((item) =>
        item.saporderid.includes(saporderidFilter)
      );
    }
    if (diaFilter) {
      filteredData = filteredData.filter((item) =>
        item.dia.includes(diaFilter)
      );
    }
    if (orderstatusFilter) {
      filteredData = filteredData.filter((item) =>
        item.orderstatus.includes(orderstatusFilter)
      );
    }
    if (ordernumberFilter) {
      filteredData = filteredData.filter((item) =>
        item.ordernumber.includes(ordernumberFilter)
      );
    }
    if (fromDateFilter && toDateFilter) {
      const fromDate = new Date(fromDateFilter);
      const toDate = new Date(toDateFilter);

      filteredData = filteredData.filter((item) => {
        const orderDate = new Date(item.orderDate);
        return orderDate >= fromDate && orderDate <= toDate;
      });
    }

    setData(filteredData);
  }

  useEffect(() => {
    dispatch(fetchDataFromApi('LengthWiseProdQty'));
    setData(originalData);
  }, [dispatch, originalData]);

  useEffect(() => {

    applyFilters();
  }, [customerFilter, saporderidFilter, diaFilter, orderstatusFilter, ordernumberFilter]);


  return (
    <div className='sdbr'>
      <div className='filter-card'>

        <div className="filter-layout-LWPQ">
          <div className="filter-grid-2-2">
            <div className="filter">
              <label htmlFor="customerFilter">Customer</label>
              <select
                id="customerFilter"
                value={customerFilter}
                onChange={(e) => setcustomerFilter(e.target.value)}
              >
                <option value="">All</option>
                {customerValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className='filterD'>
              <div className="filter">
                <label htmlFor="saporderidFilter">SAP Order Id</label>
                <select
                  id="saporderidFilter"
                  value={saporderidFilter}
                  onChange={(e) => setsaporderidFilter(e.target.value)}
                >
                  <option value="">All</option>
                  {saporderidValues.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <div className="filter">
                <label htmlFor="diaFilter">Diameter</label>
                <select
                  id="diaFilter"
                  value={diaFilter}
                  onChange={(e) => setdiaFilter(e.target.value)}
                >
                  <option value="">All</option>
                  {diaValues.map((value, index) => (
                    <option key={index} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="filter">
              <label htmlFor="orderstatusFilter">Order Status</label>
              <select
                id="orderstatusFilter"
                value={orderstatusFilter}
                onChange={(e) => setorderstatusFilter(e.target.value)}
              >
                <option value="">All</option>
                {orderstatusValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter">
              <label htmlFor="ordernumberFilter">Order Number</label>
              <select
                id="ordernumberFilter"
                value={ordernumberFilter}
                onChange={(e) => setordernumberFilter(e.target.value)}
              >
                <option value="">All</option>
                {ordernumberValues.map((value, index) => (
                  <option key={index} value={value}>
                    {value}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className='DateFilt'>
          <div className="filterD">
            <label htmlFor="fromDateFilter">From </label>
            <input
              id="fromDateFilter"
              type="date"
              value={fromDateFilter}
              onChange={(e) => setFromDateFilter(e.target.value)}
            />
          </div>
          <div className="filterD">
            <label htmlFor="toDateFilter">To</label>
            <input
              id="toDateFilter"
              type="date"
              value={toDateFilter}
              onChange={(e) => setToDateFilter(e.target.value)}
            />
          </div>
          </div>
        </div>

      </div>
      <div className='head'>Length Wise Prod Qty</div>
      <div>

        <TableComponent columns={columns} data={data} />
      </div>

    </div>
  );
};

export default LengthWiseProdQty